import React from 'react';
import { makeStyles } from '@material-ui/core';
import BoxPagina from 'shared/components/box/BoxPagina';
import ContainerIndicadores from 'features/indicadores/views/ContainerIndicadores';
import spacingInPixels from 'shared/functions/materialUtils';

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.cores.branco,
    borderRadius: spacingInPixels(theme, 2),
    height: '100%',
    padding: `${spacingInPixels(theme, 4.5)} ${spacingInPixels(theme, 4.25)}`,
  },
}));

const Indicadores = (): JSX.Element => {
  const classes = useStyles();

  return (
    <BoxPagina component="section" className={classes.container}>
      <ContainerIndicadores />
    </BoxPagina>
  );
};

export default Indicadores;
