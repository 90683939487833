import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { refreshToken } from '../../features/login/api/loginManager';

import Login from '../../features/login';

import { PATH_PAGINA_INICIAL } from '../constants/pathConstants';

interface LoginRouteProps {
  path: string;
}

const LoginRoute = ({ path }: LoginRouteProps): JSX.Element => {
  const [possuiAcesso, setPossuiAcesso] = useState(false);
  const [validacaoAcessoRealizada, setValidacaoAcessoRealizada] = useState(
    false,
  );

  useEffect(() => {
    const tratarRefreshToken = async () => {
      await refreshToken()
        .then(() => {
          setPossuiAcesso(true);
          setValidacaoAcessoRealizada(true);
        })
        .catch(() => {
          setPossuiAcesso(false);
          setValidacaoAcessoRealizada(true);
        });
    };

    if (!validacaoAcessoRealizada) {
      tratarRefreshToken();
    }
  }, [validacaoAcessoRealizada]);

  return (
    <>
      {validacaoAcessoRealizada && (
        <Route
          exact
          path={path}
          render={({ location }) =>
            possuiAcesso ? (
              <Redirect
                to={{
                  pathname: PATH_PAGINA_INICIAL,
                  state: { from: location },
                }}
              />
            ) : (
              <Login />
            )
          }
        />
      )}
    </>
  );
};

export default LoginRoute;
