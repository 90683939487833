import React, { createRef, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import Input from '@material-ui/core/Input';
import { withStyles } from '@material-ui/core';

import { delay } from 'lodash';
import { OrigemNegociacao } from 'features/informacoesNegociacao/api/informacoesNegociacaoApiTypes';
import { RootState } from '../../../store/reducer';

import { scrollFinalChat } from '../utils/chatNegociacao';

import ChatBalao from './ChatBalao';
import ChatResposta from './ChatResposta';
import ChatRodape from './ChatRodape';

import SkeletonNegociador from './ChatSkeleton/SkeletonNegociador';
import SkeletonMotorista from './ChatSkeleton/SkeletonMotorista';
import TemporizadorFreteTabelado from './TemporizadorFreteTabelado';

const BoxChatCorpo = withStyles(() => ({
  root: {
    overflow: 'auto',
    height: '100%',
    maxHeight: '100%',
    paddingBottom: 100,
  },
}))(Box);

const BoxChatRodape = withStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: 2,
    backgroundColor: theme.palette.cores.branco,
    boxShadow: '6px 0px 11px -2px #9E9E9E',
    zIndex: 1,
    width: '54.2%',
  },
}))(Box);

const ChatCorpo = (): JSX.Element => {
  const scrollRef = createRef<HTMLInputElement>();

  const { chatMensagens, chatAberto, chatCarregando } = useSelector(
    (state: RootState) => state.chatNegociacaoReducer,
  );
  const { negociacao, propostasAplicativo } = useSelector(
    (state: RootState) => state.informacoesNegociacaoReducer,
  );

  useEffect(() => {
    delay(() => scrollFinalChat(scrollRef.current, chatAberto), 500);
  }, [chatAberto, chatMensagens, scrollRef]);

  return (
    <BoxChatCorpo>
      {chatCarregando ? (
        <>
          <SkeletonNegociador />
          <SkeletonMotorista />
        </>
      ) : (
        <>
          {negociacao?.origemNegociacao === OrigemNegociacao.freteTabelado && (
            <TemporizadorFreteTabelado
              negociacao={negociacao}
              valorProposta={propostasAplicativo[0]?.propostaAtual}
            />
          )}
          {chatMensagens.map((mensagem) => (
            <ChatBalao mensagem={mensagem} key={mensagem.dataEnvio} />
          ))}
          <ChatResposta />
        </>
      )}
      <Input
        style={{
          visibility: 'hidden',
          display: 'block',
          height: 0,
          width: 0,
        }}
        ref={scrollRef}
      />
      <BoxChatRodape>
        <ChatRodape />
      </BoxChatRodape>
    </BoxChatCorpo>
  );
};

export default ChatCorpo;
