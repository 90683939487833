import { makeStyles } from '@material-ui/core';

const botaoPropostaStyles = makeStyles((theme) => ({
  botao: {
    borderRadius: 4,
    backgroundColor: ({ aceitar }: { aceitar: boolean }) =>
      aceitar
        ? theme.palette.cores.verde[400]
        : theme.palette.cores.vermelho[600],
    '&:hover': {
      backgroundColor: ({ aceitar }: { aceitar: boolean }) =>
        aceitar
          ? theme.palette.cores.verde[700]
          : theme.palette.cores.vermelho[700],
    },
  },
  icone: {
    color: theme.palette.cores.branco,
  },
  desabilitado: {
    backgroundColor: theme.palette.cores.cinza[500],
  },
}));

export default botaoPropostaStyles;
