enum PropostaStatus {
  AGUARDANDO = 0,
  CONTRAPROPOSTA = 1,
  CONCLUIDA = 2,
  RECUSA_NEGOCIADOR = 3,
  ACEITE_MOTORISTA = 4,
  RECUSA_MOTORISTA = 5,
  PENDENTE_SUPERVISOR = 6,
  AGUARDANDO_ACEITE_SUPERVISOR = 7,
  Indisponivel = 8,
}

export type PropostaStatusType = PropostaStatus;

export const verificarPropostaConcluida = (
  status: PropostaStatusType,
): boolean => PropostaStatus.CONCLUIDA === status;

export const verificarPropostaAceiteMotorista = (
  status: PropostaStatusType,
): boolean => PropostaStatus.ACEITE_MOTORISTA === status;

export const verificarPropostaRecusada = (
  status: PropostaStatusType,
): boolean =>
  [PropostaStatus.RECUSA_NEGOCIADOR, PropostaStatus.RECUSA_MOTORISTA].includes(
    status,
  );

export const verificarPropostaFechada = (status: PropostaStatusType): boolean =>
  verificarPropostaConcluida(status) || verificarPropostaRecusada(status);

export const verificarPropostaPendenteSupervisor = (
  status: PropostaStatusType,
): boolean => PropostaStatus.PENDENTE_SUPERVISOR === status;

export default PropostaStatus;
