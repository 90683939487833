import React from 'react';
import CelulaConteudoTexto from 'shared/components/tabelaDemandas/CelulaConteudoTexto';
import CelulaNegociador from 'shared/components/tabelaDemandas/CelulaNegociador';
import CelulaStatusPrioridade from 'shared/components/tabelaDemandas/CelulaStatusPrioridade';
import { formatarDataBrasileira } from 'shared/functions/dataUtils';
import { formatarCycleTime } from 'shared/functions/IntervaloTempo';
import { useStyles } from 'features/detalhesDemandasDiaria/styles/styleCorpoTabela';
import { ConteudoDemandas } from 'features/detalhesDemandasDiaria/types/tabelaTypes';
import { estilosTabelaDemandasNaoIniciadas } from '../../styles/styleTabela';

const ConteudoTabelaDemandasNaoIniciadas = ({
  demandas,
}: ConteudoDemandas): JSX.Element => {
  const classes = useStyles({ altura: false });
  const { gridTabela } = estilosTabelaDemandasNaoIniciadas();

  return (
    <tbody className={classes.corpoTabela}>
      {demandas.map((demanda) => (
        <tr className={gridTabela} key={`${demanda.romaneio}`}>
          <CelulaStatusPrioridade prioridadeCarga={demanda.prioridadeCarga} />
          <CelulaConteudoTexto
            conteudo={formatarDataBrasileira(
              new Date(demanda.dataCarregamento),
            )}
          />
          <CelulaNegociador nomeNegociador={demanda.nomeNegociador} />
          <CelulaConteudoTexto
            conteudo={`${formatarCycleTime(demanda?.leadTime)}`}
          />
          <CelulaConteudoTexto conteudo={demanda.nomeFilial} />
          <CelulaConteudoTexto conteudo={demanda.nomeCidade} />
          <CelulaConteudoTexto
            conteudo={demanda.quantidadeEntregas.toLocaleString('pt-br')}
          />
          <CelulaConteudoTexto conteudo={demanda.romaneio} />
        </tr>
      ))}
    </tbody>
  );
};

export default ConteudoTabelaDemandasNaoIniciadas;
