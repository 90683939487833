import React from 'react';

import Box from '@material-ui/core/Box';

import { Negociacao } from '../api/minhasNegociacoesApiTypes';

import FiltrosNegociacao from './FiltrosNegociacao';
import TituloNumerado from '../../../shared/components/titulo/Titulo';

export type TituloMinhasNegociacoesProps = {
  negociacoes: Negociacao[];
};

const TituloMinhasNegociacoes = ({
  negociacoes,
}: TituloMinhasNegociacoesProps): JSX.Element => (
  <Box display="flex" alignItems="center" justifyContent="space-between">
    <TituloNumerado
      titulo="Minhas negociações"
      quantidade={negociacoes.length}
    />
    <FiltrosNegociacao negociacoes={negociacoes} />
  </Box>
);

export default TituloMinhasNegociacoes;
