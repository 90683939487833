import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, makeStyles, Typography } from '@material-ui/core';
import IconeFindInPage from '../../../assets/icones/IconeFindInPage';
import spacingInPixels from '../../../shared/functions/materialUtils';
import { PATH_MINHAS_NEGOCIACOES } from '../../../shared/constants/pathConstants';
import BotaoRubik from '../../../shared/components/button/BotaoRubik';

const useStyles = makeStyles((theme) => ({
  conteudoCentralizado: {
    position: 'fixed',
    top: '0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  boxCircular: {
    backgroundColor: theme.palette.cores.cinza[300],
    borderRadius: '80px',
    padding: '27px',
  },
  texto: {
    display: 'block',
    fontWeight: 400,
    fontSize: '22px',
    margin: spacingInPixels(theme, 3),
    textAlign: 'center',
    width: '368px',
  },
  botao: {
    width: spacingInPixels(theme, 24),
    fontSize: spacingInPixels(theme, 2),
    fontWeight: 400,
  },
}));
const PaginaNaoEncontrada = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Box className={classes.conteudoCentralizado}>
      <Box className={classes.boxCircular}>
        <IconeFindInPage />
      </Box>
      <Typography className={classes.texto}>
        Ops, não encontramos essa página, tente novamente!
      </Typography>
      <BotaoRubik
        className={classes.botao}
        variant="contained"
        color="primary"
        onClick={() => history.push(PATH_MINHAS_NEGOCIACOES)}
      >
        Ir para o início
      </BotaoRubik>
    </Box>
  );
};

export default PaginaNaoEncontrada;
