import React, { ReactNode } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionSummary from './AccordionSummary';

type HeaderAccordionProps = {
  children: ReactNode;
  background?: string;
  expandIconColor?: string;
  styles?: string;
  exibirIconeExpansao?: boolean;
  iconPosition?: 'iconStart' | 'iconEnd';
  iconEdge?: 'start' | 'end' | false;
  classesProp?: Record<string, unknown>;
};

const useStyles = makeStyles((theme) => ({
  iconColor: {
    color: theme.palette.primary.main,
  },
  iconStart: {
    flexDirection: 'row-reverse',
  },
  iconEnd: {
    flexDirection: 'row',
  },
}));

const HeaderAccordion = ({
  children,
  background,
  styles,
  expandIconColor,
  exibirIconeExpansao = true,
  iconPosition = 'iconStart',
  iconEdge,
  classesProp,
}: HeaderAccordionProps): JSX.Element => {
  const classes = useStyles();

  return (
    <AccordionSummary
      className={` ${styles} ${classes[iconPosition]}`}
      style={{ backgroundColor: background }}
      expandIcon={
        exibirIconeExpansao && (
          <ExpandMoreIcon className={expandIconColor || classes.iconColor} />
        )
      }
      IconButtonProps={{ edge: iconEdge }}
      aria-label="Expandir"
      order={1}
      classes={classesProp}
    >
      {children}
    </AccordionSummary>
  );
};

HeaderAccordion.defaultProps = {
  background: undefined,
  expandIconColor: undefined,
  styles: undefined,
  exibirIconeExpansao: true,
  iconPosition: 'iconStart',
  iconEdge: 'start',
  classesProp: {},
};

export default HeaderAccordion;
