import React, { useCallback, useEffect, useState } from 'react';
import { DetalhesDemandaDiaria } from 'features/detalhesDemandasDiaria/api/detalhesDemandasDiariaApiTypes';
import { useDispatch } from 'react-redux';
import { buscarDetalhesNegociacoesRecusadas } from 'features/detalhesDemandasDiaria/utils/detalhesDemandas';
import { useLoading } from 'shared/customHooks/useLoading';
import { titulosPagina } from 'features/detalhesDemandasDiaria/constants/TitulosPagina';
import TituloPagina from 'features/detalhesDemandasDiaria/components/TituloPagina';
import TabelaNegociacoesRecusadas from './TabelaNegociacoesRecusadas';
import { useStyles } from '../../styles/styleContainerDetalhes';

const ContainerNegociacoesRecusadas = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [negociacoes, setNegociacoes] = useState<DetalhesDemandaDiaria[]>([]);
  const [erro, setErro] = useState<boolean>(false);

  const buscaNegociacoesCallback = useCallback(
    async () =>
      buscarDetalhesNegociacoesRecusadas(setNegociacoes, setErro)(dispatch),
    [setNegociacoes, dispatch],
  );

  const [carregando, buscarNegociacoesLoading] = useLoading(
    buscaNegociacoesCallback,
  );

  useEffect(() => {
    buscarNegociacoesLoading();
  }, [dispatch, buscarNegociacoesLoading]);

  return (
    <section className={classes.container}>
      <TituloPagina
        tituloPaginaConfig={titulosPagina.demandasRecusadas}
        quantidadeItens={negociacoes.length}
        carregando={carregando}
      />
      <TabelaNegociacoesRecusadas
        carregando={carregando}
        negociacoes={negociacoes}
        erro={erro}
      />
    </section>
  );
};

export default ContainerNegociacoesRecusadas;
