/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable radix */
import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, Typography } from '@material-ui/core';
import {
  DataGrid,
  GridColDef,
  GridEditCellProps,
  ptBR,
} from '@material-ui/data-grid';
import { CloudUpload } from '@material-ui/icons';
import { useStyles } from 'features/configuracoes/styles/freteTabeladoRegioes';
import { useDispatch } from 'react-redux';
import {
  atualizarCidades,
  buscarCidades,
  buscarEstados,
  buscarFreteTabeladoMacros,
  cadastrarCidades,
} from 'features/configuracoes/utils/configuracoes';
import { Pagination } from '@material-ui/lab';
import {
  BuscarCidades,
  BuscarCidadesResponse,
  EstadosResponse,
  FreteTabeladoRegiao,
} from '../api/configuracoesApiTypes';
import UploadCidadesMacro from './UploadCidadesMacro';

const TabelaCidades = (): JSX.Element => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [rows, setRows] = useState<BuscarCidadesResponse>();
  const [dadosCidades, setDadosCidades] = useState<BuscarCidadesResponse>();
  const [originalRows, setOriginalRows] = useState<BuscarCidadesResponse>();
  const [linhasModificadas, setLinhasModificadas] = useState<number[]>([]);
  const [estadosDados, setEstadosDados] = useState<EstadosResponse[]>([]);
  const [dadosMacros, setDadosMacros] = useState<FreteTabeladoRegiao[]>([]);

  const [uploadPlanilha, setUploadPlanilha] = useState(false);
  const [salvarAtivado, setSalvarAtivado] = useState(false);
  const [editarAtivo, setEditarAtivo] = useState(true);
  const [validacaoDados, setValidacaoDados] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [filterCodigoIbge, setFilterCodigoIbge] = useState<
    string | undefined
  >();
  const [filterDescricao, setFilterDescricao] = useState<string | undefined>();

  useEffect(() => {
    buscarCidades(
      setDadosCidades,
      String(paginaAtual),
      filterCodigoIbge,
      filterDescricao,
    );
    buscarEstados(setEstadosDados, dispatch);
    buscarFreteTabeladoMacros(setDadosMacros, dispatch);
    setSalvarAtivado(false);
    setEditarAtivo(true);
    setValidacaoDados(true);
  }, [dispatch, filterCodigoIbge, filterDescricao, paginaAtual]);

  const buscarDados = () => {
    buscarCidades(
      setDadosCidades,
      String(paginaAtual),
      filterCodigoIbge,
      filterDescricao,
    );
    setSalvarAtivado(false);
    setEditarAtivo(true);
    setValidacaoDados(true);
  };

  useEffect(() => {
    if (dadosCidades) {
      const newRows: BuscarCidadesResponse = {
        cidades: dadosCidades.cidades.map((row, index) => ({
          ...row,
          id: index + 1,
        })),
        paginaAtual: dadosCidades.paginaAtual,
        totalDePaginas: dadosCidades.totalDePaginas,
      };

      setRows(newRows);
      setOriginalRows(dadosCidades); // Salva uma cópia dos dados originais
    }
  }, [dadosCidades]);

  const handleCellEditCommit = (params: GridEditCellProps) => {
    const { id, field, value } = params;

    setRows((prevRows) => {
      if (!prevRows) return prevRows;

      const updatedCidades = prevRows.cidades.map((row) =>
        row.id === id ? { ...row, [field]: value } : row,
      );

      return {
        ...prevRows,
        cidades: updatedCidades,
      };
    });

    // Adiciona o ID da linha modificada ao estado de linhas modificadas
    if (!linhasModificadas.includes(id)) {
      setLinhasModificadas([...linhasModificadas, id]);
    }
  };

  useEffect(() => {
    if (salvarAtivado) {
      const linhasNovas = rows?.cidades.filter((row) => {
        return (
          typeof row.codigo !== 'undefined' &&
          typeof row.codigoIbge !== 'undefined' &&
          typeof row.estadoId !== 'undefined' &&
          typeof row.id !== 'undefined' &&
          typeof row.nomeCidade !== 'undefined'
        );
      });

      if (
        !linhasNovas ||
        linhasNovas.length === 0 ||
        linhasNovas.some(
          (item) =>
            item.nomeCidade === '' ||
            item.estado === '' ||
            item.codigoIbge === '' ||
            // eslint-disable-next-line no-restricted-globals
            isNaN(item.codigo),
        )
      ) {
        setValidacaoDados(true);
      } else {
        setValidacaoDados(false);
      }
    }
  }, [salvarAtivado, rows]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPaginaAtual(value);
  };

  const handleSalvarClick = () => {
    if (salvarAtivado && rows) {
      const linhasNovas = rows.cidades.filter((row) => {
        return (
          typeof row.id === 'undefined' ||
          (row.id && originalRows && row.id > originalRows.cidades.length)
        );
      });

      linhasNovas.forEach(async (linha) => {
        await cadastrarCidades(
          {
            codigo: linha.codigo,
            nome: linha.nomeCidade,
            idEstado: linha.estadoId,
            codigoIbge: String(linha.codigoIbge),
            macroFilialId: linha.macroId,
          },
          dispatch,
        );
      });
    } else {
      const linhasParaSalvar = rows?.cidades.filter((row) => {
        if (originalRows === undefined) {
          return false; // Não há linhas originais, então não há modificações
        }
        // Encontre a linha correspondente na lista original
        const originalRow = originalRows.cidades.find(
          (original) => original.idCidade === row.idCidade,
        );

        if (originalRow) {
          return (
            originalRow.codigoIbge !== row.codigoIbge ||
            originalRow.nomeCidade !== row.nomeCidade ||
            originalRow.macroId !== row.macroId ||
            originalRow.estadoId !== row.estadoId
          );
        }

        return true;
      });

      if (linhasParaSalvar) {
        linhasParaSalvar.forEach(async (linha) => {
          await atualizarCidades(
            {
              idCidade: linha.idCidade,
              codigoIbge: String(linha.codigoIbge),
              codigo: linha.codigo,
              nome: linha.nomeCidade,
              idEstado: linha.estadoId,
              macroFilialIdMacroFilial: linha.macroId,
            },
            dispatch,
          );
        });
      }
    }
  };

  const handleEstadoChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    rowId: number,
    field: string,
  ): void => {
    const newStateValue: string = event.target.value;
    const estadoId: number | undefined = parseInt(
      event.target.options[event.target.selectedIndex].getAttribute(
        'data-id',
      ) || '',
    );

    setRows((prevRows: BuscarCidadesResponse | undefined) => {
      if (!prevRows) return prevRows;

      const updatedCidades = prevRows.cidades.map((cidade: BuscarCidades) =>
        cidade.id === rowId
          ? {
              ...cidade,
              [field]: newStateValue,
              estadoId: estadoId !== null ? estadoId : undefined,
            }
          : cidade,
      );

      return {
        ...prevRows,
        cidades: updatedCidades,
      };
    });
  };

  const handleMacroChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    rowId: number,
    field: string,
  ): void => {
    const newDescricaoMacroValue: string = event.target.value;
    const macroId: number | undefined = parseInt(
      event.target.options[event.target.selectedIndex].getAttribute(
        'data-id',
      ) || '',
    );

    setRows((prevRows: BuscarCidadesResponse | undefined) => {
      if (!prevRows) return prevRows;

      const updatedCidades = prevRows.cidades.map((cidade: BuscarCidades) =>
        cidade.id === rowId
          ? {
              ...cidade,
              [field]: newDescricaoMacroValue,
              macroId: macroId !== null ? macroId : undefined,
            }
          : cidade,
      );

      return {
        ...prevRows,
        cidades: updatedCidades,
      };
    });
  };

  const generateColumns = (isNewRow: boolean): GridColDef[] => {
    return [
      {
        field: 'descricaoMacro',
        headerName: 'Macro filial',
        width: 200,
        editable: false,
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => {
          const { id, value, row } = params;
          return (
            <select
              value={value === null ? '' : value || row.descricaoMacro}
              onChange={(e) =>
                handleMacroChange(e, parseInt(id as string), 'descricaoMacro')
              }
              className={classes.selectedMacro}
            >
              {row.macroId === null && <option value=""> </option>}
              <option value="">{row.descricaoMacro}</option>
              {dadosMacros.map((macro) => (
                <option
                  key={macro.idMacroFilial}
                  value={macro.idMacroFilial}
                  data-id={macro.idMacroFilial}
                  selected={value === macro.idMacroFilial}
                >
                  {macro.descricao}
                </option>
              ))}
            </select>
          );
        },
      },
      {
        field: 'codigo',
        headerName: 'Codigo',
        width: 100,
        sortable: false,
        filterable: false,
        align: 'center',
        headerAlign: 'center',
        editable: isNewRow, // Define a propriedade editable com base em isNewRow
        type: 'number',
        disableColumnMenu: true,
        valueFormatter: (params) => {
          if (params.value && typeof params.value === 'number') {
            return params.value.toString().replace('.', '');
          }
          return params.value;
        },
      },
      {
        field: 'codigoIbge',
        headerName: 'Codigo IBGE',
        width: 120,
        sortable: false,
        filterable: false,
        align: 'center',
        headerAlign: 'center',
        editable: isNewRow, // Define a propriedade editable com base em isNewRow
        disableColumnMenu: true,
        type: 'number',
        valueFormatter: (params) => {
          if (params.value && typeof params.value === 'number') {
            return params.value.toString().replace('.', '');
          }
          return params.value;
        },
      },
      {
        field: 'nomeCidade',
        headerName: 'Cidade',
        width: 200,
        sortable: false,
        filterable: false,
        align: 'center',
        headerAlign: 'center',
        editable: isNewRow,
        disableColumnMenu: true,
      },
      {
        field: 'estado',
        headerName: 'Estado',
        width: 140,
        sortable: false,
        filterable: false,
        align: 'center',
        headerAlign: 'center',
        editable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          const { id, value, row } = params;

          return (
            <select
              value={value === null ? '' : value || row.estado}
              onChange={(e) =>
                handleEstadoChange(e, parseInt(id as string), 'estado')
              }
              className={classes.selected}
            >
              {row.estado === null && <option value=""> </option>}
              <option value="">{row.estado}</option>
              {estadosDados.map((estado) => (
                <option
                  key={estado.idEstado}
                  value={estado.idEstado}
                  data-id={estado.idEstado}
                  selected={value === estado.idEstado}
                >
                  {estado.sigla}
                </option>
              ))}
            </select>
          );
        },
      },
    ];
  };
  const generateColumnsCallback = (isNewRow: boolean) => () => {
    return generateColumns(isNewRow);
  };

  const [columns, setColumns] = useState<GridColDef[]>(
    generateColumnsCallback(true),
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const generateColumnsCallback = (isNewRow: boolean) => () => {
      return generateColumns(isNewRow);
    };

    if (dadosMacros) {
      setColumns(generateColumnsCallback(true));
    } else {
      setColumns(generateColumnsCallback(false));
    }
  }, [dadosMacros]);

  const handleSearchKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === 'Enter') {
      const newSearchText = searchText.trim();
      const searchTextWithSpaces = newSearchText.replace(/\s+/g, ' ');
      if (newSearchText === '') {
        // Se o campo de pesquisa estiver vazio, limpe ambos os filtros
        setFilterCodigoIbge(undefined);
        setFilterDescricao(undefined);
      } else if (/^\s*\d+\s*$/.test(newSearchText)) {
        // Se o texto de pesquisa contiver apenas dígitos, defina o filtro de código IBGE
        setFilterCodigoIbge(searchTextWithSpaces.trim());
        setFilterDescricao(undefined);
      } else {
        // Caso contrário, defina o filtro de descrição
        setFilterCodigoIbge(undefined);
        setFilterDescricao(searchTextWithSpaces);
      }
      buscarDados();
    }
  };

  const handleSearchTextChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchText(event.target.value);
  };

  const handleAddRow = () => {
    const newRow: BuscarCidades = {
      id: rows && rows.cidades ? rows.cidades.length + 1 : 1,
      descricaoMacro: '',
      codigo: 0,
      codigoIbge: '',
      nomeCidade: '',
      estado: '',
    };

    const newRows: BuscarCidadesResponse = {
      cidades: rows && rows.cidades ? [newRow, ...rows.cidades] : [newRow],
      paginaAtual: rows?.paginaAtual ?? 1,
      totalDePaginas: rows?.totalDePaginas ?? 1,
    };

    setRows(newRows);
    setColumns(generateColumns(true));
    setEditarAtivo(false);
    setSalvarAtivado(true);
  };

  const handleUploadBoolPlanilha = () => {
    setUploadPlanilha(!uploadPlanilha);
  };

  const CustomFooter = () => (
    <>
      <Box className={classes.customFooterContainer}>
        <Box
          className="MuiDataGrid-selectedRowCount"
          style={{ display: 'none' }}
        />
        <Button
          size="small"
          className={classes.addButton}
          variant="contained"
          onClick={handleAddRow}
          disabled={salvarAtivado}
        >
          Add nova linha
        </Button>
        <Pagination
          count={rows ? rows.totalDePaginas : 1}
          page={paginaAtual}
          onChange={handlePageChange}
        />
      </Box>
      <Box className={classes.customButtons}>
        <Button
          size="small"
          className={classes.addButtonUpload}
          color="primary"
          variant="text"
          onClick={handleUploadBoolPlanilha}
        >
          <CloudUpload style={{ marginRight: '5px', fontSize: '18px' }} />
          <Typography style={{ paddingTop: '2px', fontSize: '14px' }}>
            Upload Planilha
          </Typography>
        </Button>
        <Box>
          <Button
            style={{ marginRight: '25px' }}
            color="primary"
            variant="text"
            onClick={buscarDados}
          >
            LIMPAR
          </Button>
          {editarAtivo ? (
            <Button
              color="primary"
              variant="contained"
              onClick={handleSalvarClick}
            >
              ATUALIZAR
            </Button>
          ) : (
            <Button
              disabled={validacaoDados}
              color="primary"
              variant="contained"
              onClick={handleSalvarClick}
            >
              SALVAR
            </Button>
          )}
        </Box>
      </Box>
    </>
  );

  return (
    <Box style={{ height: '60vh' }}>
      {uploadPlanilha ? (
        <>
          <UploadCidadesMacro
            handleUploadBoolPlanilha={handleUploadBoolPlanilha}
          />
        </>
      ) : (
        <>
          <TextField
            variant="outlined"
            label="Procurar"
            value={searchText}
            onChange={handleSearchTextChange}
            onKeyDown={handleSearchKeyDown}
            size="small"
            style={{ margin: '20px 0', width: '100%' }}
          />

          <DataGrid
            rows={
              rows?.cidades ? rows.cidades.map((cidade) => ({ ...cidade })) : []
            }
            columns={columns}
            onCellEditCommit={handleCellEditCommit}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            localeText={ptBR.props.MuiDataGrid.localeText}
            disableSelectionOnClick
            components={{ Footer: CustomFooter }}
          />
        </>
      )}
    </Box>
  );
};

export default TabelaCidades;
