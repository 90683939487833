import React, { useState } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';

import { Box, TableCell, TableRow, Typography } from '@material-ui/core';
import { ColaboradoresNegociadores } from 'features/configuracoes/api/configuracoesApiTypes';
import { Skeleton } from '@material-ui/lab';
import BoxWhiteCustomScrollTable from '../../../shared/components/box/BoxWhiteCustomScrollTable';
import TrocarNegociadorDrawer from './TrocarNegociadorDrawer';

import { Negociacao } from '../api/minhasNegociacoesApiTypes';

import TabelaTitulo from './TabelaTitulo';
import TabelaItem from './TabelaItem';
import { ordenarListaNegociacoes } from '../utils/listaNegociacoes';

export type TabelaMinhasNegociacoesProps = {
  negociacoes: Negociacao[];
  onAmbienteAssistido: boolean;
  closeDrawerDemandasAndNegociador: () => Promise<void>;
  openDrawerDemandasAndNegociador: () => Promise<void>;
  selectColaboradorDrawer: ColaboradoresNegociadores[];
  demandasNegociador: () => Promise<void>;
  dadosValidacao?: boolean;
};

const TabelaMinhasNegociacoes = ({
  negociacoes,
  onAmbienteAssistido,
  selectColaboradorDrawer,
  closeDrawerDemandasAndNegociador,
  openDrawerDemandasAndNegociador,
  demandasNegociador,
  dadosValidacao,
}: TabelaMinhasNegociacoesProps): JSX.Element => {
  const [filtroData, setFiltroData] = useState<boolean>(false);
  const negociacoesOrdenadas = ordenarListaNegociacoes(negociacoes);
  const [countValues, setCountValues] = useState<number>(0);
  const [checkboxValues, setCheckboxValues] = useState<{
    [key: number]: boolean;
  }>({});

  const compararDatasCarregamento = (a: Negociacao, b: Negociacao) => {
    const dataA: number = new Date(a.dataCarregamento).getTime();
    const dataB: number = new Date(b.dataCarregamento).getTime();

    if (filtroData) {
      return dataA - dataB;
    }
    return dataB - dataA;
  };

  negociacoesOrdenadas.sort(compararDatasCarregamento);

  const handleCheckboxChange = (id: number, value: boolean) => {
    setCheckboxValues((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));

    setCountValues((prevCount) => prevCount + (value ? 1 : -1));
  };

  const handleLimparCheckBoxLocal = async () => {
    await closeDrawerDemandasAndNegociador();
    setCountValues(0);
    setCheckboxValues({});
  };

  const handleTrocarNegociador = async () => {
    await openDrawerDemandasAndNegociador();
  };

  const finalizarTransferencia = async () => {
    await demandasNegociador();
    setCheckboxValues({});
    setCountValues(0);
  };

  const skeletonRows = [...Array(14)].map((_) => (
    <TableRow key={`Negociacao-${_}`}>
      <TableCell align="left">
        <Skeleton
          width={70}
          animation="wave"
          height={22}
          style={{ borderRadius: '8px' }}
        />
      </TableCell>
      <TableCell align="left">
        <Skeleton
          width={120}
          animation="wave"
          height={22}
          style={{ borderRadius: '8px' }}
        />
      </TableCell>
      <TableCell align="left">
        <Skeleton
          width={120}
          animation="wave"
          height={22}
          style={{ borderRadius: '8px' }}
        />
      </TableCell>
      <TableCell align="left">
        <Skeleton
          width={140}
          animation="wave"
          height={22}
          style={{ borderRadius: '8px' }}
        />
      </TableCell>
      <TableCell align="left">
        <Skeleton
          width={55}
          animation="wave"
          height={22}
          style={{ borderRadius: '8px' }}
        />
      </TableCell>
      <TableCell align="right">
        <Skeleton
          width={55}
          animation="wave"
          height={22}
          style={{ borderRadius: '8px' }}
        />
      </TableCell>
      <TableCell align="left">
        <Skeleton
          width={55}
          animation="wave"
          height={22}
          style={{ borderRadius: '8px' }}
        />
      </TableCell>
      <TableCell align="left">
        <Skeleton
          width={60}
          animation="wave"
          height={22}
          style={{ borderRadius: '8px' }}
        />
      </TableCell>
      <TableCell align="left">
        <Skeleton
          width={55}
          animation="wave"
          height={22}
          style={{ borderRadius: '8px' }}
        />
      </TableCell>
      <TableCell align="left">
        <Skeleton
          width={65}
          animation="wave"
          height={22}
          style={{ borderRadius: '8px' }}
        />
      </TableCell>
      <TableCell align="left">
        <Skeleton
          width={65}
          animation="wave"
          height={22}
          style={{ borderRadius: '8px' }}
        />
      </TableCell>
      <TableCell align="left">
        <Skeleton
          width={65}
          animation="wave"
          height={22}
          style={{ borderRadius: '8px' }}
        />
      </TableCell>
      <TableCell align="left">
        <Skeleton
          width={170}
          animation="wave"
          height={22}
          style={{ borderRadius: '8px' }}
        />
      </TableCell>
    </TableRow>
  ));

  return (
    <BoxWhiteCustomScrollTable>
      <TableContainer>
        <Table size="small" aria-label="Tabela - Minhas Negociações">
          <TableHead>
            <TabelaTitulo
              filtroData={filtroData}
              setFiltroData={setFiltroData}
            />
          </TableHead>
          {negociacoesOrdenadas?.length > 0 && (
            <TableBody>
              {negociacoesOrdenadas.map((negociacao) => (
                <TabelaItem
                  key={`Negociacao-${negociacao.id}`}
                  checkboxValue={checkboxValues[negociacao.id]}
                  onCheckboxChange={(value) =>
                    handleCheckboxChange(negociacao.id, value)
                  }
                  negociacao={negociacao}
                  onAmbienteAssistido={onAmbienteAssistido}
                  selectColaboradorDrawer={selectColaboradorDrawer}
                />
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {dadosValidacao &&
        (!negociacoesOrdenadas || negociacoesOrdenadas.length <= 0) &&
        skeletonRows}
      {(!negociacoesOrdenadas || negociacoesOrdenadas.length <= 0) && (
        <Box
          style={{
            display: 'flex',
            height: '530px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              src="/images/minhasNegociacoes/icone-dropbox.svg"
              alt="Filial"
            />
            <Typography
              style={{ color: '#757575', fontWeight: 400, fontSize: '20px' }}
            >
              Você não tem nenhuma
            </Typography>
            <Typography
              style={{ color: '#757575', fontWeight: 400, fontSize: '20px' }}
            >
              negociação aberta no momento
            </Typography>
          </Box>
        </Box>
      )}

      <TrocarNegociadorDrawer
        negociacoesIdTransferencia={checkboxValues}
        openDrawer={onAmbienteAssistido}
        hasDemanda={countValues > 0}
        closeDrawerDemandasAndNegociador={handleLimparCheckBoxLocal}
        handleTrocarNegociador={handleTrocarNegociador}
        selectColaboradorDrawer={selectColaboradorDrawer}
        finalizarTransferencia={finalizarTransferencia}
      />
    </BoxWhiteCustomScrollTable>
  );
};

export default TabelaMinhasNegociacoes;
