import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/styles';

import scrollbarConstructor from '../../functions/scrollbarConstructor';

export default withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.cores.branco,
    ...scrollbarConstructor('MuiTableContainer-root', theme),
  },
}))(Box);
