import React from 'react';

import { Box, Grid, makeStyles, useTheme } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import {
  caminhoIconeDolarBranco,
  caminhoIconeDolarCinza,
} from 'shared/constants/CaminhoRecursos';
import { PropostaNegociacao } from '../../../api/informacoesNegociacaoApiTypes';
import { formatarNumeroParaStringMonetariaComSimbolo } from '../../../../../shared/functions/dinheiroUtils';
import Texto from '../listaDisponiveis/TextoItemLista';
import { converterDataApiParaDataChat } from '../../../../chatNegociacao/utils/dataHora';
import VeiculoInfo from '../components/VeiculoInfo';

const useStyles = makeStyles((theme) => ({
  texto: {
    color: ({ propostaSelecionada }: { propostaSelecionada: boolean }) =>
      propostaSelecionada ? theme.palette.cores.branco : '',
    fontSize: '16px',
    lineHeight: '19px',
  },
  textoHorario: {
    marginLeft: 5,
    fontWeight: 400,
  },
}));

const PropostaInformacoes = ({
  proposta,
  propostaSelecionada,
}: {
  proposta: PropostaNegociacao;
  propostaSelecionada: boolean;
}): JSX.Element => {
  const classes = useStyles({ propostaSelecionada });
  const theme = useTheme();

  return (
    <Grid container>
      <Grid item xs={5}>
        <Box marginLeft="6px">
          <VeiculoInfo
            nomeVeiculo={proposta.tipoVeiculo}
            propostaSelecionada={propostaSelecionada}
          />
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box display="flex" alignItems="center">
          <img
            src={
              propostaSelecionada
                ? caminhoIconeDolarBranco
                : caminhoIconeDolarCinza
            }
            alt="Ícone Dólar"
          />
          <Texto className={classes.texto}>
            {formatarNumeroParaStringMonetariaComSimbolo(
              proposta.propostaAtual,
            )}
          </Texto>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          marginRight={1}
        >
          <AccessTimeIcon
            style={{
              color: propostaSelecionada
                ? theme.palette.cores.branco
                : theme.palette.cores.cinza[600],
            }}
          />
          <Texto className={`${classes.texto} ${classes.textoHorario}`}>
            {converterDataApiParaDataChat(
              proposta.dataUltimaMensagemProposta as string,
            )}
          </Texto>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PropostaInformacoes;
