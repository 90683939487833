import React from 'react';
import { makeStyles } from '@material-ui/core';
import { DemandaNegociador } from 'features/dashboards/negociadores/api/listagemNegociadoresApiTypes';
import { formatarDataHoraSLA } from 'features/dashboards/utils/modalDetalhes';
import Porcentagem from 'shared/components/porcentagem/Porcentagem';
import prioridadeCargaStyle from 'features/dashboards/styles/prioridadeCargaStyle';
import { tratarValorMonetario } from 'shared/functions/dinheiroUtils';
import spacingInPixels from 'shared/functions/materialUtils';
import { formatarCycleTime } from 'shared/functions/IntervaloTempo';
import { tratarValorPorcentagem } from 'shared/functions/porcentagemUtils';
import CustomTooltip from 'shared/components/tooltip/CustomTooltip';

const useStyles = makeStyles((theme) => ({
  texto: {
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: spacingInPixels(theme, 2),
    lineHeight: spacingInPixels(theme, 2.5),
    letterSpacing: '0.4px',
    color: theme.palette.cores.cinza[600],
    maxWidth: '95%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  badgePorcentagem: {
    margin: 0,
    borderRadius: spacingInPixels(theme, 2),
    padding: `${spacingInPixels(theme, 0.5)}`,
    display: 'flex',
    alignItems: 'center',
  },
  textoPorcentagem: {
    fontSize: 10,
    lineHeight: 'initial',
  },
  indicadorPrioridade: {
    width: '15px',
    height: '15px',
    borderRadius: '50%',
    display: 'inline-block',
  },
}));

export interface ItemTabelaDemandasNegociadorProps {
  demanda: DemandaNegociador;
  className?: string;
  isAutomatizado: boolean;
}

const ItemTabelaDemandasNegociador = ({
  demanda,
  className,
  isAutomatizado,
}: ItemTabelaDemandasNegociadorProps): JSX.Element => {
  const classes = useStyles();
  const estilosPrioridade = prioridadeCargaStyle();

  const dataSlaFormatada = formatarDataHoraSLA(demanda.sla);
  const quantidadeEntregas = demanda.quantidadeEntregas.toLocaleString('pt-BR');
  const cycleTimeFormatado = formatarCycleTime(demanda?.cycleTime);
  const quantidadePedidosAprovacao = demanda.quantidadePedidosAprovacao?.toLocaleString(
    'pt-BR',
  );
  return (
    <>
      <article className={className}>
        <div
          className={`${estilosPrioridade[demanda.prioridadeCarga]} ${
            classes.indicadorPrioridade
          }`}
        />
      </article>

      <CustomTooltip title={dataSlaFormatada}>
        <article className={className}>
          <span className={classes.texto}>
            {formatarDataHoraSLA(demanda.sla)}
          </span>
        </article>
      </CustomTooltip>

      <CustomTooltip title={demanda.nomeFilial}>
        <article className={className}>
          <span className={classes.texto}>{demanda.nomeFilial}</span>
        </article>
      </CustomTooltip>

      <CustomTooltip title={quantidadeEntregas}>
        <article className={className}>
          <span className={classes.texto}>{quantidadeEntregas}</span>
        </article>
      </CustomTooltip>

      <CustomTooltip
        title={`${tratarValorMonetario(
          demanda?.ultimoValorProposta,
        )}    ${tratarValorPorcentagem(demanda?.savingValorProposta)} Target`}
      >
        <>
          <article className={className}>
            {!isAutomatizado && (
              <span className={classes.texto}>
                {tratarValorMonetario(demanda?.ultimoValorProposta)}
              </span>
            )}

            {demanda?.savingValorProposta && (
              <Porcentagem
                styles={classes.badgePorcentagem}
                stylesPorcentagem={classes.textoPorcentagem}
                stylesComplemento={classes.textoPorcentagem}
                porcentagem={tratarValorPorcentagem(
                  demanda?.savingValorProposta,
                )}
                complemento="Target"
              />
            )}
          </article>
        </>
      </CustomTooltip>

      <CustomTooltip
        title={`${tratarValorMonetario(
          demanda.valorMeta,
        )}    ${tratarValorPorcentagem(demanda.savingValorMeta)} Target`}
      >
        <article className={className}>
          <span className={classes.texto}>
            {tratarValorMonetario(demanda.valorMeta)}
          </span>
          {demanda?.savingValorMeta && (
            <Porcentagem
              styles={classes.badgePorcentagem}
              stylesPorcentagem={classes.textoPorcentagem}
              stylesComplemento={classes.textoPorcentagem}
              porcentagem={tratarValorPorcentagem(demanda.savingValorMeta)}
              complemento="Target"
            />
          )}
        </article>
      </CustomTooltip>

      <CustomTooltip title={cycleTimeFormatado}>
        <article className={className} title={cycleTimeFormatado}>
          <span className={classes.texto}>{cycleTimeFormatado}</span>
        </article>
      </CustomTooltip>

      <CustomTooltip title={quantidadePedidosAprovacao || ''}>
        <article className={className}>
          <span className={classes.texto}>{quantidadePedidosAprovacao}</span>
        </article>
      </CustomTooltip>

      <CustomTooltip title={demanda.nomeCidade}>
        <article className={className}>
          <span className={classes.texto}>{demanda.nomeCidade}</span>
        </article>
      </CustomTooltip>

      <CustomTooltip title={demanda.romaneio}>
        <article className={className}>
          <span className={classes.texto}>{demanda.romaneio}</span>
        </article>
      </CustomTooltip>
    </>
  );
};

export default ItemTabelaDemandasNegociador;
