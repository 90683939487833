import React from 'react';
import CustomTooltip from '../tooltip/CustomTooltip';
import estilosTabela from './styles/estilosTabela';
import { DadosCabecalho } from './types/TabelaTypes';

export interface ItemCabecalhoProps {
  dadosCabecalho: DadosCabecalho;
}

const ItemCabecalho = ({ dadosCabecalho }: ItemCabecalhoProps): JSX.Element => {
  const { fonteCabecalho, celulaCabecalho } = estilosTabela();

  return (
    <th
      className={`${fonteCabecalho} ${celulaCabecalho}`}
      style={{ display: 'flex' }}
    >
      <CustomTooltip title={dadosCabecalho?.descricao || ''}>
        <div>{dadosCabecalho?.conteudo && <>{dadosCabecalho.conteudo}</>}</div>
      </CustomTooltip>
    </th>
  );
};

export default ItemCabecalho;
