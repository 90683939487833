import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  makeStyles,
  Typography,
  withStyles,
  Grid,
} from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';

import { RootState } from 'store/reducer';
import { formatarNumeroParaStringMonetariaComSimbolo } from 'shared/functions/dinheiroUtils';
import Porcentagem from 'shared/components/porcentagem/Porcentagem';
import { Rubik } from 'shared/constants/font';
import { exibirValorRelacionado } from 'shared/functions/valorRelacionadoUtils';
import TipoValorContraproposta from 'features/chatNegociacao/constants/TipoValorContraproposta';

import BotaoIcone from 'shared/components/button/BotaoIcone';
import { abrirSnackbarErro } from 'shared/components/snackbar/redux/snackbarAction';
import { calcularPorcentagem } from './functions/valoresCardUtils';

const CampoValor = withStyles((theme) => ({
  root: {
    fontSize: 13,
    fontWeight: theme.typography.fontWeightBold,
    fontFamily: Rubik,
  },
}))(Typography);

const useStyles = makeStyles((theme) => ({
  container: {
    color: ({ frotaPropria }: { frotaPropria?: boolean }) =>
      frotaPropria
        ? theme.palette.cores.cinza[900]
        : theme.palette.cores.azulMartins[500],
    padding: '0 32px',
    height: '100%',
  },
  label: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: Rubik,
  },
  labelTitulo: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightMedium,
    fontFamily: Rubik,
  },
  porcentagem: {
    height: 25,
  },
}));

type ValoresEstaticosProps = {
  frotaPropria?: boolean;
  abrilModalEditarValores?: () => void;
};

const ValoresEstaticos = ({
  frotaPropria,
  abrilModalEditarValores,
}: ValoresEstaticosProps): JSX.Element => {
  const classes = useStyles({ frotaPropria });
  const dispatch = useDispatch();

  const { negociacao, propostas, propostasAplicativo } = useSelector(
    (state: RootState) => state.informacoesNegociacaoReducer,
  );

  const ApresentarComponenteSugestaoRobo = Boolean(
    negociacao?.sugestaoValorInicialRobo,
  );

  const [valor, setValor] = useState<number | undefined>();

  const caminhoIconeCinza = '/images/detalhesCarga/icone-editar-cinza.svg';
  const caminhoIconeAzul = '/images/detalhesCarga/icone-editar.svg';
  const mensagemErroPropostasEnviadas =
    'O valor inicial de proposta não pode ser alterado quando uma proposta já tiver sido enviada.';
  const mensagemErroValorFrotaPropriaZerado =
    'O valor inicial de proposta não pode ser alterado caso o valor do target não tenha sido informado.';
  const desabilitado =
    propostas.length > 0 ||
    propostasAplicativo.length > 0 ||
    !negociacao?.valorFrotaPropria ||
    ApresentarComponenteSugestaoRobo;

  const abrirSnackbar = (mensagem: string) => {
    dispatch(
      abrirSnackbarErro({
        mensagem,
      }),
    );
  };

  const validarEdicaoValores = (): boolean => {
    if (propostas.length > 0 || propostasAplicativo.length > 0) {
      abrirSnackbar(mensagemErroPropostasEnviadas);
      return false;
    }

    if (!negociacao?.valorFrotaPropria) {
      abrirSnackbar(mensagemErroValorFrotaPropriaZerado);
      return false;
    }

    return true;
  };

  const abrirModal = () => {
    if (!validarEdicaoValores()) return;

    if (abrilModalEditarValores) {
      abrilModalEditarValores();
    }
  };

  useEffect(() => {
    setValor(
      frotaPropria
        ? negociacao?.valorFrotaPropria
        : negociacao?.valorNegociacao,
    );
  }, [negociacao, frotaPropria]);

  return (
    <Grid
      container
      justify="space-between"
      alignItems="center"
      className={classes.container}
    >
      <Grid item>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography className={classes.labelTitulo}>
              {frotaPropria ? '' : 'Valores Iniciais'}
            </Typography>
            <Typography className={classes.labelTitulo}>
              {frotaPropria ? 'Target' : 'Negociação'}
            </Typography>
          </Box>
          <ArrowForwardIos style={{ height: 12, width: 12, marginLeft: 20 }} />
        </Box>
      </Grid>
      <Grid item>
        <Typography className={classes.labelTitulo}>Proposta</Typography>
        <CampoValor>
          {/* {formatarNumeroParaStringMonetariaComSimbolo(valor)} */}
          {formatarNumeroParaStringMonetariaComSimbolo(
            ApresentarComponenteSugestaoRobo
              ? negociacao?.sugestaoValorInicialRobo
              : valor,
          )}
        </CampoValor>
      </Grid>
      <Grid item>
        <Typography className={classes.label}>Por Km</Typography>
        <CampoValor>
          R${' '}
          {exibirValorRelacionado(
            TipoValorContraproposta.PorKm,
            negociacao?.sugestaoValorInicialRobo
              ? negociacao?.sugestaoValorInicialRobo
              : valor,
            negociacao?.carga.quilometragem,
          )}
        </CampoValor>
      </Grid>
      <Grid item>
        <Typography className={classes.label}>Por Kg</Typography>
        <CampoValor>
          R${' '}
          {exibirValorRelacionado(
            TipoValorContraproposta.PorPeso,
            negociacao?.sugestaoValorInicialRobo
              ? negociacao?.sugestaoValorInicialRobo
              : valor,
            negociacao?.carga.peso,
          )}
        </CampoValor>
      </Grid>
      <Grid item>
        <Typography className={classes.label}>Por entrega</Typography>
        <CampoValor>
          R${' '}
          {exibirValorRelacionado(
            TipoValorContraproposta.PorEntrega,
            negociacao?.sugestaoValorInicialRobo
              ? negociacao?.sugestaoValorInicialRobo
              : valor,
            negociacao?.carga.entregas,
          )}
        </CampoValor>
      </Grid>
      {!frotaPropria && (
        <Grid item>
          <Box display="flex" alignItems="center">
            <Porcentagem
              porcentagem={calcularPorcentagem(
                negociacao?.valorNegociacao,
                negociacao?.valorFrotaPropria,
              )}
              complemento="Target"
              styles={classes.porcentagem}
            />
            <BotaoIcone
              onClick={abrirModal}
              descricao=""
              desabilitado={desabilitado}
            >
              <img
                src={desabilitado ? caminhoIconeCinza : caminhoIconeAzul}
                alt="Icone abrir modal"
              />
            </BotaoIcone>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

ValoresEstaticos.defaultProps = {
  frotaPropria: false,
  abrilModalEditarValores: undefined,
};

export default ValoresEstaticos;
