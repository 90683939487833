import { createStyles, withStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export type DadoSkeleton = {
  width?: string | number;
  borderRadius: number;
};

interface DadoSkeletonProps {
  borderradius: number;
}

export default withStyles(() =>
  createStyles({
    root: (props: DadoSkeletonProps) => ({
      borderRadius: `${props.borderradius}px`,
    }),
    text: {
      transform: 'scale(1, 1)',
    },
  }),
)(Skeleton);
