import { makeStyles, Theme } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';

export const estilosTabelaDemandasNaoIniciadas = makeStyles((theme: Theme) => ({
  gridTabela: {
    display: 'grid',
    gridTemplateColumns: '0.5fr 1.5fr 2fr 1.5fr 2fr 2fr  1fr 2fr',
    textAlign: 'left',
    gap: spacingInPixels(theme, 1),
    marginBottom: spacingInPixels(theme, 1.5),
  },
}));

export const estilosTabelaNegociacoesRecusadas = makeStyles((theme: Theme) => ({
  gridTabela: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    textAlign: 'left',
    gap: spacingInPixels(theme, 1),
    marginBottom: spacingInPixels(theme, 1.5),
  },
}));

export const estilosTabelaDemandasDoDia = makeStyles((theme: Theme) => ({
  gridTabela: {
    display: 'grid',
    gridTemplateColumns:
      '0.5fr 1.5fr 2fr 2.5fr 1fr 1.75fr 1.75fr 2.25fr 2.25fr 2fr 2fr',
    textAlign: 'left',
    gap: spacingInPixels(theme, 1),
    marginBottom: spacingInPixels(theme, 1.5),
  },
}));

export const estilosTabelaDemandasPendentes = makeStyles((theme: Theme) => ({
  gridTabela: {
    display: 'grid',
    gridTemplateColumns:
      '0.5fr 1.5fr 2.25fr 2.5fr 1fr 2.5fr 2.5fr 1.25fr 1fr 2fr 2.75fr',
    textAlign: 'left',
    gap: spacingInPixels(theme, 1),
    marginBottom: spacingInPixels(theme, 1.5),
  },
}));

export const estilosTabelaDemandasConcluidas = makeStyles((theme: Theme) => ({
  gridTabela: {
    display: 'grid',
    gridTemplateColumns: '2.5fr 2fr 2fr 2fr 1fr 2fr 1.5fr',
    textAlign: 'left',
    gap: spacingInPixels(theme, 1),
    paddingLeft: spacingInPixels(theme, 2),
    alignItems: 'center',
  },
}));

export const useStyle = makeStyles(() => ({
  boxMax: {
    padding: '5px 6px',
    display: 'flex',
    borderRadius: 4,
  },
}));
