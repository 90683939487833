import { Box } from '@material-ui/core';
import ValorProposta from 'features/detalhesAprovacaoSupervisor/views/aprovacaoNegociacao/ValorProposta';
import React, { useMemo, useState } from 'react';
import BadgeCronometro from 'shared/components/cronometro/BadgeCronometro';
import { calcularPorcentagemInteira } from 'shared/functions/porcentagemUtils';
import ValorContraProposta from '../../../chatNegociacao/views/ChatBalao/ValorContraProposta';
import { Carga, Negociacao } from '../../api/detalhesAprovacaoSupervisorTypes';
import CalcularPorcentagem from './CalcularPorcentagem';
import SkeletonDetalhesProposta from './SkeletonDetalhesProposta';
import { BoxCardColum, CardCinza, TypographyValor } from './styles';

export type CardDetalhesPropostaProps = {
  valorProposta?: number;
  dataHoraPedidoAprovacao?: string;
  aprovacaoNegociacao?: boolean;
  valorSugeridoRobo?: boolean;
  carga?: Carga;
  negociacao?: Negociacao;
  carregando: boolean;
};

const CardDetalhesProposta = ({
  valorProposta,
  dataHoraPedidoAprovacao,
  carga,
  negociacao,
  carregando,
  aprovacaoNegociacao,
  valorSugeridoRobo,
}: CardDetalhesPropostaProps): JSX.Element => {
  const [valorPorEntrega, setValorPorEntrega] = useState(0);
  const [valorPorKg, setValorPorKg] = useState(0);
  const [valorPorKm, setValorPorKm] = useState(0);
  const [valorPorMerc, setValorPorMerc] = useState('-');

  const handleExibicaoValorProposta = () =>
    aprovacaoNegociacao ? (
      <ValorProposta
        valorProposta={valorProposta}
        valorSugeridoRobo={valorSugeridoRobo}
      />
    ) : (
      <ValorContraProposta valorContraproposta={valorProposta} />
    );

  useMemo(() => {
    if (valorProposta && carga) {
      setValorPorEntrega(
        Math.round(
          valorProposta /
            (carga?.quantidadeEntregasPF + carga?.quantidadeEntregasPJ),
        ),
      );
      setValorPorKg(Math.round(valorProposta / carga?.peso));
      setValorPorKm(Math.round(valorProposta / carga?.distancia));
      setValorPorMerc(
        carga?.valorMercadoria > 0
          ? ((valorProposta * 100) / carga?.valorMercadoria).toFixed(1)
          : '-',
      );
    }
  }, [carga, valorProposta]);

  return (
    <BoxCardColum>
      {carregando ? (
        <SkeletonDetalhesProposta />
      ) : (
        <>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            width="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            <TypographyValor>{handleExibicaoValorProposta()}</TypographyValor>

            <Box display="flex" justifyContent="flex-end">
              <CalcularPorcentagem
                porcentagem={calcularPorcentagemInteira(
                  valorProposta,
                  negociacao?.valorInicialSugeridoRobo
                    ? negociacao?.valorInicialSugeridoRobo
                    : negociacao?.valorFrotaPropria,
                )}
                texto={`${calcularPorcentagemInteira(
                  valorProposta,
                  negociacao?.valorInicialSugeridoRobo
                    ? negociacao?.valorInicialSugeridoRobo
                    : negociacao?.valorFrotaPropria,
                )}% Target`}
              />
              <CalcularPorcentagem
                porcentagem={calcularPorcentagemInteira(
                  valorProposta,
                  negociacao?.valorFrotaTerceiro,
                )}
                texto={`${calcularPorcentagemInteira(
                  valorProposta,
                  negociacao?.valorFrotaTerceiro,
                )}% FT`}
              />
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            width="100%"
            justifyContent="start"
            alignItems="center"
            flexWrap="wrap"
          >
            <CardCinza>
              <ValorContraProposta valorContraproposta={valorPorEntrega} /> por
              entrega
            </CardCinza>
            <CardCinza>
              <ValorContraProposta valorContraproposta={valorPorKg} /> por Kg
            </CardCinza>
            <CardCinza>
              <ValorContraProposta valorContraproposta={valorPorKm} />
              /Km
            </CardCinza>
            <CardCinza>{valorPorMerc}% merc.</CardCinza>
            <BadgeCronometro dataSolicitacaoAnalise={dataHoraPedidoAprovacao} />
          </Box>
        </>
      )}
    </BoxCardColum>
  );
};

export default CardDetalhesProposta;
