import { makeStyles } from '@material-ui/core';
import { Roboto, Rubik } from 'shared/constants/font';
import spacingInPixels from 'shared/functions/materialUtils';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
  coluna1: {
    flex: 1,
    borderLeft: '1px solid #E0E0E0',
    borderRight: '1px solid #E0E0E0',
    padding: theme.spacing(3),
  },
  coluna2: {
    flex: 1.2,
    borderRight: '1px solid #E0E0E0',
    padding: theme.spacing(3),
  },
  tituloInput: {
    fontFamily: Roboto,
    fontSize: '16px',
    color: '#616161',
    marginBottom: '10px',
  },
  autoComplete: {
    maxWidth: spacingInPixels(theme, 112),
    marginBottom: '70px',
    display: 'flex',
    '& .MuiAutocomplete-popupIndicator': {
      transform: 'none',
      height: spacingInPixels(theme, 3),
      width: spacingInPixels(theme, 4),
    },
    '& .MuiAutocomplete-clearIndicator': {
      display: 'none',
    },
    '& .MuiAutocomplete-tag': {
      backgroundColor: theme.palette.cores.azul[600],
      color: '#fff',
      fontWeight: 400,
    },
    '& .MuiSvgIcon-root': {
      color: '#fff',
    },
  },
  groupFieldTime: {
    display: 'flex',
    width: '85%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  groupField: {
    display: 'flex',
    width: '80%',
  },
  groupField3: {
    display: 'flex',
    width: '80%',
    marginTop: '71px',
  },
  autoCompleteCheck: {
    '& .MuiChip-root.MuiAutocomplete-tag.MuiChip-deletable': {
      backgroundColor: theme.palette.cores.azul[600],
    },
    '& .MuiSvgIcon-root.MuiChip-deleteIcon': {
      color: '#FFF',
    },
    '& .MuiChip-root.MuiAutocomplete-tag.MuiChip-deletable .MuiChip-label': {
      color: '#FFF',
    },
  },
  tituloBlock: {
    fontFamily: Roboto,
    fontSize: '16px',
    textAlign: 'center',
    color: '#BDBDBD',
  },
  containerListFiliais: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    gap: '10px',
  },
  containerChips: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
  },
  chipFilial: {
    backgroundColor: theme.palette.cores.cinza[400],
    color: '#212121',

    '&:hover': {
      backgroundColor: theme.palette.cores.azul[600],
      color: '#FFF',
    },

    borderRadius: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px 15px',
    cursor: 'pointer',
  },
  textoChipFilial: {
    fontFamily: Roboto,
    fontSize: '14px',
    fontWeight: 400,
    textAlign: 'center',
  },
  buttonSalvar: {
    backgroundColor: theme.palette.cores.azul[600],
    color: '#fff',
    fontFamily: Rubik,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '36px',
    letterSpacing: '1.25px',
    textAlign: 'center',
    width: '92px',
    height: '36px',
    '&:hover': {
      backgroundColor: theme.palette.cores.azul[700],
      color: '#FFF',
    },
  },
  buttonLimpar: {
    color: theme.palette.cores.azul[600],
    fontFamily: Rubik,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '36px',
    letterSpacing: '1.25px',
    textAlign: 'center',
  },
  buttonApagar: {
    color: '#FF5252',
    fontFamily: Rubik,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '36px',
    letterSpacing: '1.25px',
    textAlign: 'center',
  },
  iconCheck: {
    color: '#00BFA5',
    width: '23px',
    height: '23px',
  },
  AdornmentH: {
    position: 'absolute',
    left: '32px',
  },
  inputTypography: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#8C8C8C',
  },
  inputNumber: {
    height: '56px',
    width: '140px',
    fontSize: '14px',
    fontWeight: 600,
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
      appearance: 'textfield',
    },
  },
  arrowTime: {
    color: theme.palette.cores.azul[700],
    margin: '0 25px',
  },
}));
