import { Theme, makeStyles } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';

export const useStyles = makeStyles((theme: Theme) => ({
  containerTimelineIndicadores: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '4fr 6fr',
    gridTemplateRows: 'min-content',
    justifyContent: 'space-between',
    gridGap: spacingInPixels(theme, 2),
  },
  containerCircularProgress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: spacingInPixels(theme, 15),
  },
}));
