/* eslint-disable react/no-children-prop */
import { useHistory } from 'react-router-dom';
import React from 'react';
import { Box } from '@material-ui/core';
import Titulo from 'shared/components/titulo/Titulo';
import IconeDolar from 'assets/icones/IconeDolar';
import IconeCheck from 'assets/icones/IconeCheck';
import IconeReprovar from 'assets/icones/IconeReprovar';
import theme from 'theme';
import BotaoOutlineIconeTexto from '../components/Botoes/BotaoOutlineIconeTexto';
import BotaoIconeTexto from '../components/Botoes/BotaoIconeTexto';
import ValorContraProposta from '../../chatNegociacao/views/ChatBalao/ValorContraProposta';
import SkeletonBotoesCabecalho from '../components/skeletons/SkeletonBotoesCabecalho';

export interface Props {
  onOpenReprovar: () => void;
  onOpenPropor: () => void;
  onOpenConfirmar: () => void;
  valorProposta?: number;
  carregando: boolean;
}

const Cabecalho = ({
  onOpenReprovar,
  onOpenPropor,
  onOpenConfirmar,
  valorProposta = 0,
  carregando,
}: Props): JSX.Element => {
  const history = useHistory();

  return (
    <Box
      display="flex"
      flexDirection="row"
      height="40px"
      alignItems="center"
      flexWrap="wrap"
      justifyContent="space-between"
    >
      <Box minWidth="300px">
        <Titulo
          titulo="Detalhes do pedido"
          possuiBotaoVoltar
          voltar={() => history.goBack()}
        />
      </Box>

      {carregando ? (
        <SkeletonBotoesCabecalho quantidade={3} />
      ) : (
        <Box display="flex" flexWrap="wrap">
          <BotaoOutlineIconeTexto
            color={theme.palette.cores.vermelho[400]}
            icon={<IconeReprovar />}
            onClick={onOpenReprovar}
          >
            Reprovar
          </BotaoOutlineIconeTexto>
          <BotaoOutlineIconeTexto
            color={theme.palette.cores.azulMartins[500]}
            icon={<IconeDolar />}
            onClick={onOpenPropor}
          >
            Propor nova meta
          </BotaoOutlineIconeTexto>
          <BotaoIconeTexto
            color={theme.palette.cores.branco}
            backgroundColor={theme.palette.cores.verde[400]}
            icon={<IconeCheck />}
            onClick={onOpenConfirmar}
          >
            Aprovar <ValorContraProposta valorContraproposta={valorProposta} />
          </BotaoIconeTexto>
        </Box>
      )}
    </Box>
  );
};
export default Cabecalho;
