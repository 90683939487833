import React from 'react';
import { Box } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Rubik } from 'shared/constants/font';
import IconeArrowCircleDown from 'assets/icones/IconeArrowCircleDown';
import IconeArrowCircleUp from 'assets/icones/IconeArrowCircleUp';
import theme from 'theme';
import { Filial, Carga } from '../../api/detalhesAprovacaoSupervisorTypes';
import { TypographyPorcentagem } from '../DetalhesProposta/CorTexto';

const BoxRota = withStyles(() => ({
  root: {
    padding: '10px',
    backgroundColor: theme.palette.background.default,
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))(Box);

const TypographyFilial = withStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'start',
    fontFamily: Rubik,
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 500,
    color: theme.palette.cores.cinza[900],
  },
}))(Typography);

export const TypographyTexto = withStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'start',
    fontFamily: Rubik,
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 400,
    color: theme.palette.cores.cinza[900],
  },
}))(Typography);

export const TypographyTextoOpaco = withStyles(() => ({
  root: {
    fontFamily: Rubik,
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 400,
    color: theme.palette.cores.cinza[900],
    opacity: 0.5,
  },
}))(Typography);

export type BoxInfoFilialProps = {
  filial?: Filial;
  carga?: Carga;
};

const BoxInfoFilial = ({ filial, carga }: BoxInfoFilialProps): JSX.Element => {
  const CorTextoPorcentagem = (porcentagem: number): string =>
    porcentagem >= 0
      ? `${theme.palette.cores.verde[400]}`
      : `${theme.palette.cores.vermelho[400]}`;

  const savingFilial = filial?.savingFilial || 0;

  return (
    <BoxRota
      display="flex"
      flexDirection="row"
      width="100%"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box maxWidth="40%">
        <TypographyFilial>{carga?.nomeFilialDestino || '-'}</TypographyFilial>
        <TypographyTexto>Filial</TypographyTexto>
      </Box>
      <BoxRota maxWidth="60%">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="end"
          alignItems="end"
          padding="10px"
        >
          <TypographyPorcentagem corTexto={CorTextoPorcentagem(savingFilial)}>
            {savingFilial.toLocaleString('pt-BR', {
              maximumFractionDigits: 2,
            })}
            %
          </TypographyPorcentagem>

          <TypographyTextoOpaco>Saving últimos 3 dias </TypographyTextoOpaco>
        </Box>
        {savingFilial >= 0 ? (
          <IconeArrowCircleDown fill={CorTextoPorcentagem(savingFilial)} />
        ) : (
          <IconeArrowCircleUp fill={CorTextoPorcentagem(savingFilial)} />
        )}
      </BoxRota>
    </BoxRota>
  );
};

export default BoxInfoFilial;
