import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
  2: {
    backgroundColor: theme.palette.cores.vermelho[400],
  },
  1: {
    backgroundColor: theme.palette.cores.mostarda[700],
  },
  0: {
    backgroundColor: theme.palette.cores.verde[500],
  },
}));
