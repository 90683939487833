import React, { createRef, useEffect, useState } from 'react';

import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core';
import TextField from './TextField';

import {
  converterPorcentagemDecimalParaNumero,
  converterPorcentagemInteiraParaNumero,
  obterPorcentagemDecimal,
  obterPorcentagemInteira,
} from '../../functions/porcentagemUtils';

const RealAdornment = withStyles((theme) => ({
  root: {
    '& p': {
      color: theme.palette.text.primary,
    },
  },
}))(InputAdornment);

export type InputPorcentagemProps = {
  id: string;
  value: number | undefined;
  onChange: (novoValor: number | undefined) => void;
  precisaoDecimal?: boolean;
  label?: string;
  error?: boolean;
  helperText?: string;
  className?: string;
  size?: 'medium' | 'small';
  fullWidth?: boolean;
  disabled?: boolean;
};

const InputPorcentagem = ({
  id,
  value,
  onChange,
  precisaoDecimal,
  label,
  error,
  helperText,
  className,
  size,
  fullWidth,
  disabled,
}: InputPorcentagemProps): JSX.Element => {
  const inputRef = createRef<HTMLInputElement>();

  const [valorInput, setValorInput] = useState<string>('');

  useEffect(() => {
    if (value === undefined) {
      setValorInput('');
    } else {
      const novoValorPorcentagem = precisaoDecimal
        ? obterPorcentagemDecimal(value)
        : obterPorcentagemInteira(value);

      setValorInput(novoValorPorcentagem);
    }
  }, [value, precisaoDecimal]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const novoValor = event.target.value;
    const novoValorNumerico = precisaoDecimal
      ? converterPorcentagemDecimalParaNumero(novoValor)
      : converterPorcentagemInteiraParaNumero(novoValor);

    onChange(novoValorNumerico || undefined);
  };

  return (
    <TextField
      autoComplete="off"
      disabled={disabled}
      fullWidth={fullWidth}
      variant="outlined"
      error={error}
      label={label}
      placeholder={precisaoDecimal ? '000,00%' : '000%'}
      helperText={helperText}
      value={valorInput}
      onChange={handleChange}
      className={className}
      size={size}
      InputProps={{
        id,
        ref: inputRef,
        inputProps: { maxLength: precisaoDecimal ? 6 : 3 },
        endAdornment: valorInput !== '' && (
          <RealAdornment position="end">%</RealAdornment>
        ),
      }}
      InputLabelProps={{
        htmlFor: id,
      }}
    />
  );
};

InputPorcentagem.defaultProps = {
  label: undefined,
  helperText: undefined,
  error: false,
  className: undefined,
  size: undefined,
};

export default InputPorcentagem;
