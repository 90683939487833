import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import {
  adicionarIdCarga,
  atualizarOrigemCarga,
} from 'features/listaCargas/redux/listaCargasAction';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useTheme, withStyles } from '@material-ui/core';

import { RootState } from 'store/reducer';
import BoxDataRelogioHora from '../../../../shared/components/box/BoxDataRelogioHora';
import { converterDataApi } from '../../../../shared/functions/dataUtils';
import { Rubik } from '../../../../shared/constants/font';

import { NotificacaoProps } from './Notificacao';
import { onClickNotificacao } from '../../utils/notificacaoConteudo';

const TypographyTitulo = withStyles({
  root: {
    fontFamily: Rubik,
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 500,
    wordBreak: 'break-word',
  },
})(Typography);

const TypographySubtitulo = withStyles({
  root: {
    fontFamily: Rubik,
    fontStyle: 'italic',
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: 400,
    wordBreak: 'break-word',
  },
})(Typography);

const TypographyTexto = withStyles({
  root: {
    fontFamily: Rubik,
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 400,
    marginTop: '12px',
    marginBottom: '12px',
    wordBreak: 'break-word',
  },
})(Typography);

const NotificacaoConteudo = ({
  notificacao,
}: NotificacaoProps): JSX.Element => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const { dados } = useSelector((reducer: RootState) => reducer.loginReducer);

  const onClick = async (): Promise<void> => {
    if (notificacao.complemento) {
      const savedData = localStorage.getItem('selectedColaborador');
      const newDataArray = savedData ? JSON.parse(savedData) : [];
      const idCarga = notificacao.complemento?.idsCargas[0];

      const existingEntryIndex = newDataArray.findIndex(
        (entry: { id: number }) => entry.id === idCarga,
      );

      if (existingEntryIndex !== -1) {
        newDataArray[existingEntryIndex].selectValue =
          dados?.usuario.dadosPermissao.idColaborador;
      } else {
        newDataArray.push({
          id: idCarga,
          selectValue: dados?.usuario.dadosPermissao.idColaborador,
        });
      }

      localStorage.setItem('selectedColaborador', JSON.stringify(newDataArray));

      notificacao.complemento.idsCargas.forEach((element) => {
        dispatch(adicionarIdCarga(element));
      });
      dispatch(atualizarOrigemCarga(notificacao.complemento.origemCarga));
    }
    await onClickNotificacao(notificacao, history.push, dispatch);
  };

  return (
    <Box
      width="100%"
      paddingRight="12px"
      paddingLeft="9px"
      paddingY="12px"
      style={{ cursor: 'pointer' }}
      onClick={onClick}
    >
      <TypographyTitulo>{notificacao.titulo}</TypographyTitulo>
      <TypographySubtitulo>{notificacao.subtitulo}</TypographySubtitulo>
      <TypographyTexto>{notificacao.texto}</TypographyTexto>
      <BoxDataRelogioHora
        data={converterDataApi(notificacao.dataCriacao)}
        fontSize="12px"
        imgMargin="4px"
        width="100%"
        justifyContent="flex-end"
        fontWeight={400}
        fontFamily={Rubik}
        color={theme.palette.cores.cinza[700]}
      />
    </Box>
  );
};

export default NotificacaoConteudo;
