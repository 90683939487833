import React from 'react';
import { useStyles } from 'features/configuracoes/styles/styleEsquemaColaborador';
import {
  Box,
  Chip,
  FormControl,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import theme from 'theme';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import { ColaboradoresNegociadores } from 'features/configuracoes/api/configuracoesApiTypes';
import { KeyboardArrowRight } from '@material-ui/icons';
import { removeAccents } from 'features/configuracoes/utils/inputIntervaloTempo';

interface PropsConteudoEsquemaColaborador {
  diasSelecionados: DiasSelecionados;
  horarioFim: string;
  horarioInicio: string;
  erroHorario: boolean;
  handleChangeHorarioFim: (e: { target: { value: string } }) => void;
  handleChangeHorarioInicio: (e: { target: { value: string } }) => void;
  dadosColaboradores: ColaboradoresNegociadores | undefined;
  selectedColaborador: ColaboradoresNegociadores[];
  handleAutocompleteChange: (
    event: React.ChangeEvent<unknown>,
    newValue: ColaboradoresNegociadores[] | [],
  ) => void;
  handleDiaSelecionado: (dia: string) => void;
}

type DiasSelecionados = {
  segunda: boolean;
  terca: boolean;
  quarta: boolean;
  quinta: boolean;
  sexta: boolean;
  sabado: boolean;
  domingo: boolean;
};
const ConteudoEditEsquemaColaborador = ({
  diasSelecionados,
  horarioFim,
  horarioInicio,
  erroHorario,
  dadosColaboradores,
  selectedColaborador,
  handleChangeHorarioFim,
  handleChangeHorarioInicio,
  handleAutocompleteChange,
  handleDiaSelecionado,
}: PropsConteudoEsquemaColaborador): JSX.Element => {
  const classes = useStyles();
  const diasDaSemana = [
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sábado',
    'Domingo',
  ];

  return (
    <Box>
      <FormControl className={classes.groupField}>
        <Autocomplete
          multiple
          options={Array.isArray(dadosColaboradores) ? dadosColaboradores : []}
          getOptionLabel={(option) => option.nome}
          value={selectedColaborador}
          onChange={handleAutocompleteChange}
          className={classes.autoComplete}
          popupIcon={
            <SearchRoundedIcon
              style={{ color: theme.palette.cores.cinza[500] }}
            />
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Selecione o colaborador"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                style: { paddingRight: 0 },
                endAdornment: <>{params.InputProps.endAdornment}</>,
              }}
            />
          )}
        />
      </FormControl>
      <FormControl className={classes.groupFieldTime}>
        <TextField
          id="outlined-start-adornment"
          type="string"
          label="Horário de inicio"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          className={classes.inputNumber}
          onChange={handleChangeHorarioInicio}
          value={horarioInicio}
          error={
            (horarioInicio >= horarioFim &&
              horarioInicio !== '' &&
              horarioFim !== '') ||
            erroHorario
          }
          helperText={
            (horarioInicio >= horarioFim &&
              horarioInicio !== '' &&
              horarioFim !== '') ||
            erroHorario ? (
              <div style={{ width: '500px', position: 'absolute', left: '0' }}>
                Horários errados ou
                <br />
                acima de &quot;23h 59&quot;
              </div>
            ) : (
              ''
            )
          }
        />
        <KeyboardArrowRight className={classes.arrowTime} />
        <TextField
          id="outlined-start-adornment"
          variant="outlined"
          type="string"
          label="Horário de saída"
          value={horarioFim}
          onChange={handleChangeHorarioFim}
          className={classes.inputNumber}
          InputLabelProps={{ shrink: true }}
          error={
            (horarioInicio >= horarioFim &&
              horarioInicio !== '' &&
              horarioFim !== '') ||
            erroHorario
          }
        />
      </FormControl>
      <FormControl className={classes.groupField3}>
        <Typography className={classes.tituloInput}>
          Quais os dias da semana:
        </Typography>
        <Box display="flex" flexWrap="wrap">
          {diasDaSemana.map((dia) => (
            <Chip
              key={dia}
              label={dia}
              clickable
              onClick={() => handleDiaSelecionado(dia)}
              style={{
                margin: '0 8px 8px 0',
                backgroundColor: diasSelecionados[
                  removeAccents(dia.toLowerCase()) as keyof DiasSelecionados
                ]
                  ? '#2979FF'
                  : '',
                color: diasSelecionados[
                  removeAccents(dia.toLowerCase()) as keyof DiasSelecionados
                ]
                  ? '#fff'
                  : '#757575',
              }}
            />
          ))}
        </Box>
      </FormControl>
    </Box>
  );
};

export default ConteudoEditEsquemaColaborador;
