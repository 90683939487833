import React from 'react';

import { useTheme, withStyles } from '@material-ui/core/styles';
import MuiAvatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';

import { useHistory } from 'react-router';
import ButtonBase from '@material-ui/core/ButtonBase';

import { Motorista } from '../api/negociacoesAutomatizadasApiTypes';

import {
  obterIniciaisMotorista,
  obterTituloTooltip,
} from '../utils/listaMotoristas';

export type AvatarMotoristaConteudoProps = {
  motorista: Motorista;
  idNegociacao: number;
  avatarPadrao: boolean;
};

const Avatar = withStyles((theme) => ({
  root: {
    width: '28px',
    height: '28px',
    fontSize: '12px',
    color: theme.palette.cores.cinza[900],
  },
}))(MuiAvatar);

const LinkButtonMotorista = withStyles(() => ({
  root: {
    fontSize: '12px',
    fontWeight: 500,
    paddingLeft: '4px',
    textDecoration: 'none',
    marginRight: '-20px',
    cursor: 'default',
  },
}))(ButtonBase);

const AvatarMotoristaConteudo = ({
  motorista,
  idNegociacao,
  avatarPadrao,
}: AvatarMotoristaConteudoProps): JSX.Element => {
  const history = useHistory();
  const theme = useTheme();

  const onClickMotorista = (motoristas: Motorista) => {
    history.push(
      `/negociacoes-automatizada/${idNegociacao}?idProposta=${motoristas.idProposta}`,
    );
  };

  const avatarCor = avatarPadrao
    ? theme.palette.cores.azul[100]
    : theme.palette.cores.cinza[300];

  return (
    <Tooltip
      title={obterTituloTooltip(motorista.nome, motorista.valorProposta)}
    >
      <LinkButtonMotorista
        onClick={() => {
          return;
          onClickMotorista(motorista);
        }}
      >
        <Avatar style={{ backgroundColor: avatarCor }}>
          {obterIniciaisMotorista(motorista.nome)}
        </Avatar>
      </LinkButtonMotorista>
    </Tooltip>
  );
};

export default AvatarMotoristaConteudo;
