import { Typography, withStyles } from '@material-ui/core';

export const TypographyPadrao = withStyles((theme) => ({
  root: {
    fontSize: '14px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontStyle: 'normal',
    color: theme.palette.cores.cinza[900],
  },
}))(Typography);
