import { Box, Divider } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import BadgesValorMedia from '../../components/badges/BadgesValorMedia';

import InformacoesMotorista from '../../components/informacoesPersona/InformacoesMotorista';
import ViagensVazias from '../../components/ViagensVazias';
import useStylesDadosMotoristaRota from '../../styles/dadosMotoristaRota';
import {
  Indicador,
  Motorista,
} from '../../api/detalhesAprovacaoSupervisorTypes';
import SubtituloDetalhesSupervisor from '../../components/SubtituloDetalhesSupervisor';
import MenuDiasCalculo from '../../components/MenuDiasCalculo';
import SkeletonMotoristaRota from './SkeletonMotoristaRota';

export type ViagensVaziasProps = {
  motorista?: Motorista;
  valorProposta?: number;
  carregando: boolean;
};

const DadosMotorista = ({
  motorista,
  valorProposta = 0,
  carregando,
}: ViagensVaziasProps): JSX.Element => {
  const classes = useStylesDadosMotoristaRota();

  const [indicador, setIndicador] = useState<Indicador | undefined>(
    motorista?.indicadoresMotorista[motorista?.indicadoresMotorista.length - 1],
  );

  useEffect(() => {
    setIndicador(
      motorista?.indicadoresMotorista[
        motorista?.indicadoresMotorista.length - 1
      ],
    );
  }, [motorista, carregando]);

  if (carregando) return <SkeletonMotoristaRota />;

  return motorista?.indicadoresMotorista?.length ? (
    <>
      <Box className={classes.boxSpaceBetween}>
        <SubtituloDetalhesSupervisor>
          Dados do motorista nesta rota
        </SubtituloDetalhesSupervisor>
        <MenuDiasCalculo
          indicadores={motorista?.indicadoresMotorista || []}
          onClick={setIndicador}
        />
      </Box>
      <Box className={classes.container}>
        <InformacoesMotorista
          nome={motorista?.nome}
          tipoVeiculo={motorista?.tipoVeiculo}
          viagensRotasSimilares={
            indicador?.quantidadeViagensRotasSimilares || 0
          }
        />
        <Divider orientation="vertical" />
        <BadgesValorMedia indicador={indicador} valorProposta={valorProposta} />
      </Box>
    </>
  ) : (
    <Box>
      <Box className={classes.container}>
        <InformacoesMotorista
          nome={motorista?.nome}
          tipoVeiculo={motorista?.tipoVeiculo}
          viagensRotasSimilares={0}
        />
        <Divider orientation="vertical" />
        <ViagensVazias texto="Esse motorista não possui viagens o suficiente para a base do cálculo" />
      </Box>
    </Box>
  );
};

export default DadosMotorista;
