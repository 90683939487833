import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';

import { makeStyles } from '@material-ui/core/styles';
import BotaoVermelho from 'shared/components/button/BotaoRubikVermelho';

import { Rubik } from 'shared/constants/font';
import { RootState } from 'store/reducer';
import DisablingTooltip from '../../../../../shared/components/tooltip/DisablingTooltip';
import { abrirModalCancelarNegociacao } from '../redux/motoristasCardAction';

export const textoBotaoCancelar = 'Cancelar negociação';

const useStyles = makeStyles(() => ({
  botao: {
    fontFamily: Rubik,
    fontSize: '16px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const CancelarNegociacaoButton = (): JSX.Element => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const onClick = () => {
    dispatch(abrirModalCancelarNegociacao());
  };

  const { colaboradorAssistido } = useSelector(
    (state: RootState) => state.minhasNegociacoesReducer,
  );

  return (
    <DisablingTooltip title={textoBotaoCancelar}>
      <BotaoVermelho
        aria-label={textoBotaoCancelar}
        className={classes.botao}
        startIcon={<ClearRoundedIcon />}
        onClick={onClick}
        disabled={colaboradorAssistido.length > 0}
      >
        {textoBotaoCancelar}
      </BotaoVermelho>
    </DisablingTooltip>
  );
};

export default CancelarNegociacaoButton;
