import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { makeStyles, useTheme } from '@material-ui/core';
import LabelGrafico from 'features/indicadores/components/LabelGrafico';

export const useStyles = makeStyles(() => ({
  container: {
    minHeight: 300,
    width: '100%',

    '& > canvas': {
      maxHeight: 300,
    },
  },
  labelContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '32px',
    marginTop: '24px',
  },
}));

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

interface GraficoAtendimentoProps {
  periodo: string[];
  negociacoesAtendidasDentroPrazo: number[];
  negociacoesAtendidasForaPrazo: number[];
}

const GraficoAtendimento = ({
  periodo,
  negociacoesAtendidasDentroPrazo,
  negociacoesAtendidasForaPrazo,
}: GraficoAtendimentoProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();

  const indicadorAtendidosNoPrazo = {
    cor: theme.palette.cores.azulMartins[500],
    texto: 'Atendidas no Prazo',
  };

  const indicadorAtendidosForaPrazo = {
    cor: theme.palette.cores.cinza[400],
    texto: 'Fora do Prazo',
  };

  const dadosIndicadores = [
    indicadorAtendidosNoPrazo,
    indicadorAtendidosForaPrazo,
  ];

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
      },
    },
  };

  const data = {
    labels: periodo,
    datasets: [
      {
        label: indicadorAtendidosNoPrazo.texto,
        data: negociacoesAtendidasDentroPrazo,
        borderColor: indicadorAtendidosNoPrazo.cor,
        backgroundColor: indicadorAtendidosNoPrazo.cor,
        borderRadius: 12,
      },
      {
        label: indicadorAtendidosForaPrazo.texto,
        data: negociacoesAtendidasForaPrazo,
        borderColor: indicadorAtendidosForaPrazo.cor,
        backgroundColor: indicadorAtendidosForaPrazo.cor,
        borderRadius: 12,
      },
    ],
  };

  return (
    <section className={classes.container}>
      <Bar options={options} data={data} />
      <div className={classes.labelContainer}>
        {dadosIndicadores.map((indicador) => (
          <LabelGrafico cor={indicador.cor as string} texto={indicador.texto} />
        ))}
      </div>
    </section>
  );
};

export default GraficoAtendimento;
