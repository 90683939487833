import React, { createRef, useEffect, useState } from 'react';
import TextField from 'shared/components/input/TextField';
import { obterApenasNumeros } from 'shared/functions/stringUtils';
import {
  converterStringParaIntervalo,
  converterIntervaloParaString,
} from '../utils/inputIntervaloTempo';
import { InputIntervaloTempoProps } from '../types/componentsTypes';

const InputIntervaloTempo = ({
  id,
  value,
  onChange,
  placeholder,
  label,
  error,
  helperText,
  className,
  size,
  fullWidth,
  disabled,
}: InputIntervaloTempoProps): JSX.Element => {
  const inputRef = createRef<HTMLInputElement>();

  const [valorInput, setValorInput] = useState<string>('');

  useEffect(() => {
    if (value === undefined) {
      setValorInput('');
    } else {
      const novoValorInput = converterIntervaloParaString(value);
      setValorInput(novoValorInput);
    }
  }, [value]);

  const handleChange = (novoValor: string) => {
    try {
      const novoValorIntervalo = converterStringParaIntervalo(novoValor);
      onChange(novoValorIntervalo);
    } catch (e) {
      // O item não será atualizado
    }
  };

  const handleKey = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Backspace') {
      const numerosInput = obterApenasNumeros(valorInput).substring(0, 3);
      handleChange(numerosInput);
    }
  };

  return (
    <TextField
      autoComplete="off"
      disabled={disabled}
      fullWidth={fullWidth}
      variant="outlined"
      error={error}
      label={label}
      placeholder={placeholder}
      helperText={helperText}
      value={valorInput}
      onChange={(e) => handleChange(e.target.value)}
      className={className}
      size={size}
      onKeyUp={handleKey}
      InputProps={{
        id,
        ref: inputRef,
        inputProps: { maxLength: 10 },
      }}
      InputLabelProps={{
        htmlFor: id,
      }}
    />
  );
};

export default InputIntervaloTempo;
