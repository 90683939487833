import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';

import { ArrowUpward } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import TableRow from '../../../shared/components/table/TableRowHeightSpacing6';
import { spacingInPixels } from '../../../shared/functions/materialUtils';
import TableCell from '../../../shared/components/table/TableCellCustomPadding';

const BoxItemTitulo = withStyles((theme) => ({
  root: {
    '& img': {
      marginRight: spacingInPixels(theme, 1),
    },
    '& span': {
      fontWeight: 500,
      color: theme.palette.primary.main,
    },
  },
}))(Box);

type TabelaTituloProps = {
  setFiltroData: (value: boolean) => void;
  filtroData: boolean;
};

const TabelaTitulo = ({
  setFiltroData,
  filtroData,
}: TabelaTituloProps): JSX.Element => {
  return (
    <TableRow>
      <TableCell>
        <BoxItemTitulo display="flex" alignItems="center">
          <img src="/images/minhasNegociacoes/icone-carga.svg" alt="ID" />
          <span>ID</span>
        </BoxItemTitulo>
      </TableCell>
      <TableCell align="left">
        <BoxItemTitulo display="flex" alignItems="center">
          <img
            src="/images/minhasNegociacoes/icone-calendar.svg"
            alt="Filial"
          />
          <span>Data</span>
          <Button
            style={{ color: '#2B52DD', minWidth: 'auto' }}
            onClick={() => setFiltroData(!filtroData)}
          >
            {filtroData ? <ArrowUpward /> : <ArrowDownwardIcon />}
          </Button>
        </BoxItemTitulo>
      </TableCell>
      <TableCell align="left">
        <BoxItemTitulo display="flex" alignItems="center">
          <img src="/images/minhasNegociacoes/icone-filial.svg" alt="Filial" />
          <span>Filial</span>
        </BoxItemTitulo>
      </TableCell>
      <TableCell align="left">
        <BoxItemTitulo display="flex" alignItems="center">
          <img
            src="/images/minhasNegociacoes/icone-filial.svg"
            alt="Cidade Principal"
          />
          <span>Cidade Principal</span>
        </BoxItemTitulo>
      </TableCell>
      <TableCell align="center">
        <Tooltip title="Entregas">
          <Box display="flex" alignItems="center" justifyContent="center">
            <img src="/images/detalhesCarga/icone-dropbox.svg" alt="Entregas" />
          </Box>
        </Tooltip>
      </TableCell>
      <TableCell align="center">
        <Tooltip title="Peso (kg)">
          <Box display="flex" alignItems="center" justifyContent="center">
            <img src="/images/detalhesCarga/icone-peso.svg" alt="Peso (kg)" />
          </Box>
        </Tooltip>
      </TableCell>
      <TableCell align="center">
        <Tooltip title="Volume (m³)">
          <Box display="flex" alignItems="center" justifyContent="center">
            <img
              src="/images/detalhesCarga/icone-volume.svg"
              alt="Volume (m³)"
            />
          </Box>
        </Tooltip>
      </TableCell>
      <TableCell align="center">
        <Tooltip title="Quilometragem (km)">
          <Box display="flex" alignItems="center" justifyContent="center">
            <img
              src="/images/detalhesCarga/icone-quilometragem.svg"
              alt="Quilometragem (km)"
            />
          </Box>
        </Tooltip>
      </TableCell>
      <TableCell align="center">
        <Tooltip title="Agregados envolvidos">
          <Box display="flex" alignItems="center" justifyContent="center">
            <img
              src="/images/informacoesNegociacao/icone-motorista-azul.svg"
              alt="Agregados envolvidos"
            />
          </Box>
        </Tooltip>
      </TableCell>
      <TableCell align="left">
        <BoxItemTitulo
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <img src="/images/minhasNegociacoes/icone-status.svg" alt="Status" />
          <span>Status</span>
        </BoxItemTitulo>
      </TableCell>
    </TableRow>
  );
};

export default TabelaTitulo;
