import { useTheme } from '@material-ui/core';
import * as React from 'react';
import { SVGProps } from 'react';

const IconeEnviar = ({
  fill,
  width = 21,
  height = 20,
  ...outros
}: SVGProps<SVGSVGElement>): JSX.Element => {
  const theme = useTheme();

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...outros}
    >
      <path
        d="M18.84 7.32013L4.84 0.320128C4.28749 0.0451374 3.66362 -0.0527612 3.05344 0.03978C2.44326 0.132321 1.87646 0.410798 1.43033 0.837244C0.984207 1.26369 0.680456 1.81735 0.560496 2.42274C0.440536 3.02813 0.510205 3.65578 0.760003 4.22013L3.16 9.59013C3.21446 9.71996 3.24251 9.85934 3.24251 10.0001C3.24251 10.1409 3.21446 10.2803 3.16 10.4101L0.760003 15.7801C0.556703 16.2368 0.470759 16.7371 0.50998 17.2355C0.549202 17.7339 0.712345 18.2145 0.984585 18.6338C1.25682 19.0531 1.62953 19.3977 2.06883 19.6363C2.50812 19.875 3.00009 20 3.5 20.0001C3.96823 19.9955 4.42949 19.8861 4.85 19.6801L18.85 12.6801C19.3466 12.4303 19.764 12.0474 20.0557 11.5742C20.3474 11.101 20.5018 10.556 20.5018 10.0001C20.5018 9.44424 20.3474 8.89928 20.0557 8.42605C19.764 7.95282 19.3466 7.56994 18.85 7.32013H18.84ZM17.95 10.8901L3.95 17.8901C3.76617 17.9784 3.55973 18.0084 3.35839 17.976C3.15705 17.9436 2.97041 17.8504 2.82352 17.709C2.67662 17.5675 2.57648 17.3845 2.53653 17.1846C2.49658 16.9846 2.51873 16.7772 2.6 16.5901L4.99 11.2201C5.02094 11.1484 5.04766 11.075 5.07 11.0001H11.96C12.2252 11.0001 12.4796 10.8948 12.6671 10.7072C12.8546 10.5197 12.96 10.2653 12.96 10.0001C12.96 9.73491 12.8546 9.48056 12.6671 9.29302C12.4796 9.10549 12.2252 9.00013 11.96 9.00013H5.07C5.04766 8.9253 5.02094 8.85184 4.99 8.78013L2.6 3.41013C2.51873 3.22309 2.49658 3.01568 2.53653 2.8157C2.57648 2.61572 2.67662 2.43273 2.82352 2.29128C2.97041 2.14982 3.15705 2.05666 3.35839 2.02428C3.55973 1.9919 3.76617 2.02186 3.95 2.11013L17.95 9.11013C18.1138 9.19405 18.2513 9.32154 18.3473 9.47857C18.4433 9.63561 18.494 9.81608 18.494 10.0001C18.494 10.1842 18.4433 10.3647 18.3473 10.5217C18.2513 10.6787 18.1138 10.8062 17.95 10.8901V10.8901Z"
        fill={fill || theme.palette.cores.cinzaAzul[50]}
      />
    </svg>
  );
};

export default IconeEnviar;
