import React from 'react';

import Box from '@material-ui/core/Box';

import { useStyles } from '../../styles/chatBalao';
import ChatHora from './ChatHora';
import { ChatBalaoProps } from './ChatBalao';
import MensagemNegociador from './MensagemNegociador';
import ChatBalaoValores from './ChatBalaoValores';
import TipoMensagemProposta from '../../../../shared/constants/TipoMensagemProposta';
import ChatInformativoSolicitacaoAprovacao from '../chatInformativo/ChatInformativoSolicitacaoAprovacao';
import ChatInformativoSolicitacaoAprovacaoMotorista from '../chatInformativo/ChatInformativoSolicitacaoAprovacaoMotorista';

const ChatBalaoNegociador = ({ mensagem }: ChatBalaoProps): JSX.Element => {
  const classes = useStyles();
  const VerificarMostrarBalaoValores =
    mensagem.tipo === TipoMensagemProposta.Contraproposta ||
    mensagem.tipo === TipoMensagemProposta.ReinicioNegociacao ||
    mensagem.tipo === TipoMensagemProposta.Inicial;

  const verificarTipoMensagemImagem =
    mensagem.tipo === TipoMensagemProposta.Arquivo;

  const verificarTipoMensagemSolicitarAprovacaoSupervisor =
    mensagem.tipo === TipoMensagemProposta.AvaliacaoSupervisor ||
    mensagem.tipo === TipoMensagemProposta.AprovacaoConclusaoSupervisor;

  return (
    <Box display="flex" flexDirection="column">
      {verificarTipoMensagemSolicitarAprovacaoSupervisor ? (
        <Box>
          <ChatInformativoSolicitacaoAprovacaoMotorista
            data={mensagem.dataEnvio}
          />

          <Box display="flex" justifyContent="flex-end">
            <ChatInformativoSolicitacaoAprovacao
              valor={mensagem.valorContraproposta}
              data={mensagem.dataEnvio}
              tipo={mensagem.tipo}
            />
          </Box>
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="flex-end">
          <Box
            className={[
              classes.balao,
              classes.balaoNegociador,
              verificarTipoMensagemImagem ? classes.balaoImagem : '',
            ].join(' ')}
          >
            <MensagemNegociador mensagem={mensagem} />
            <ChatHora data={mensagem.dataEnvio} />
          </Box>
          {VerificarMostrarBalaoValores && (
            <ChatBalaoValores valor={mensagem.valorContraproposta} />
          )}
        </Box>
      )}
    </Box>
  );
};

export default ChatBalaoNegociador;
