import React, { CSSProperties } from 'react';
import IconeCirculoExclamacao from 'assets/icones/IconeCirculoExclamacao';
import IconeCaminhao from 'assets/icones/IconeCaminhao';
import IconeSetaCirculo from 'assets/icones/IconeSetaCirculo';
import IconeAvatar from 'assets/icones/IconeAvatar';
import IconeGrafico from 'assets/icones/IconeGrafico';
import RemetenteMensagemProposta from 'shared/constants/RemetenteMensagemProposta';
import TipoMensagemProposta from 'shared/constants/TipoMensagemProposta';
import theme from '../../../../theme';
import { EventoProposta } from '../../api/detalhesAprovacaoSupervisorTypes';

type ItemCard = {
  tipo: TipoMensagemProposta;
  remetente: RemetenteMensagemProposta;
  icone: React.ReactElement;
  texto: string;
  cor: CSSProperties['color'];
};

const ITEM_MENSAGEM_INICIAL: ItemCard = {
  tipo: TipoMensagemProposta.Inicial,
  remetente: RemetenteMensagemProposta.Negociador,
  texto: 'Proposta inicial',
  icone: <IconeAvatar fill={`${theme.palette.cores.azulMartins[500]}60`} />,
  cor: theme.palette.cores.azulMartins[500],
};

const ITEM_CONTRAPROPOSTA_MOTORISTA: ItemCard = {
  tipo: TipoMensagemProposta.Contraproposta,
  remetente: RemetenteMensagemProposta.Motorista,
  texto: 'Motorista',
  icone: <IconeCaminhao fill={`${theme.palette.cores.azulPiscina[700]}60`} />,
  cor: theme.palette.cores.azulPiscina[700],
};

const ITEM_ACEITE_MOTORISTA: ItemCard = {
  tipo: TipoMensagemProposta.Aceite,
  remetente: RemetenteMensagemProposta.Motorista,
  texto: 'Aceite Motorista',
  icone: <IconeCaminhao fill={`${theme.palette.cores.azulPiscina[700]}60`} />,
  cor: theme.palette.cores.azulPiscina[700],
};

const ITEM_CONTRAPROPOSTA_NEGOCIADOR: ItemCard = {
  tipo: TipoMensagemProposta.Contraproposta,
  remetente: RemetenteMensagemProposta.Negociador,
  texto: 'Negociador',
  icone: <IconeAvatar fill={`${theme.palette.cores.azulMartins[500]}60`} />,
  cor: theme.palette.cores.azulMartins[500],
};

const ITEM_ACEITE_NEGOCIADOR: ItemCard = {
  tipo: TipoMensagemProposta.AprovacaoConclusaoSupervisor,
  remetente: RemetenteMensagemProposta.Negociador,
  texto: 'Aceite negociador',
  icone: <IconeAvatar fill={`${theme.palette.cores.verde[500]}60`} />,
  cor: theme.palette.cores.verde[500],
};

const ITEM_AVALIACAO_SUPERVISOR: ItemCard = {
  tipo: TipoMensagemProposta.AvaliacaoSupervisor,
  remetente: RemetenteMensagemProposta.Negociador,
  texto: 'Enviado aprovação',
  icone: (
    <IconeSetaCirculo
      fill={`${theme.palette.cores.laranja[700]}60`}
      width={21}
      height={21}
    />
  ),
  cor: theme.palette.cores.laranja[700],
};

const ITEM_RECUSA_SUPERVISOR: ItemCard = {
  tipo: TipoMensagemProposta.RecusaSupervisor,
  remetente: RemetenteMensagemProposta.Supervisor,
  texto: 'Aprovação recusada',
  icone: (
    <IconeCirculoExclamacao fill={`${theme.palette.cores.vermelho[500]}60`} />
  ),
  cor: theme.palette.cores.vermelho[500],
};

const ITEM_ACEITE_SUPERVISOR: ItemCard = {
  tipo: TipoMensagemProposta.AceiteSupervisor,
  remetente: RemetenteMensagemProposta.Supervisor,
  texto: 'Aprovação',
  icone: <IconeGrafico fill={`${theme.palette.cores.verde[500]}60`} />,
  cor: theme.palette.cores.verde[500],
};

const ITEM_ACEITE_NEGOCIACAO_SUPERVISOR: ItemCard = {
  tipo: TipoMensagemProposta.Aceite,
  remetente: RemetenteMensagemProposta.Supervisor,
  texto: 'Aceite supervisor',
  icone: <IconeAvatar fill={`${theme.palette.cores.verde[700]}60`} />,
  cor: theme.palette.cores.verde[700],
};

const ITEM_NOVA_META_SUPERVISOR: ItemCard = {
  tipo: TipoMensagemProposta.NovaMetaSupervisor,
  remetente: RemetenteMensagemProposta.Supervisor,
  texto: 'Nova meta proposta',
  icone: <IconeGrafico fill={`${theme.palette.cores.verde[500]}60`} />,
  cor: theme.palette.cores.verde[500],
};

export const ItensCards: ItemCard[] = [
  ITEM_MENSAGEM_INICIAL,
  ITEM_CONTRAPROPOSTA_MOTORISTA,
  ITEM_ACEITE_MOTORISTA,
  ITEM_CONTRAPROPOSTA_NEGOCIADOR,
  ITEM_ACEITE_NEGOCIADOR,
  ITEM_AVALIACAO_SUPERVISOR,
  ITEM_RECUSA_SUPERVISOR,
  ITEM_ACEITE_SUPERVISOR,
  ITEM_ACEITE_NEGOCIACAO_SUPERVISOR,
  ITEM_NOVA_META_SUPERVISOR,
];

export const obterItemCard = (evento: EventoProposta): ItemCard | undefined =>
  ItensCards.find(
    (item) =>
      item.tipo === evento.tipoMensagemProposta &&
      item.remetente === evento.remetente,
  );
