import { Grid, Tooltip } from '@material-ui/core';
import React, { ReactNode } from 'react';
import useStyles from '../styles/chatBalao';

export type ChatBalaoInformativoProps = {
  icone: JSX.Element;
  children?: ReactNode;
  className?: string;
};

const ChatBalaoInformativo = ({
  className,
  icone,
  children,
}: ChatBalaoInformativoProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Grid
      container
      alignItems="center"
      className={[classes.balao, classes.balaoIndicativo, className || ''].join(
        ' ',
      )}
    >
      <Grid item xs={1}>
        <Tooltip title="Estas informações estão disponíveis apenas para os negociadores, os motoristas não conseguem visualizá-las.">
          {icone}
        </Tooltip>
      </Grid>
      <Grid item xs={11}>
        {children}
      </Grid>
    </Grid>
  );
};

export default ChatBalaoInformativo;
