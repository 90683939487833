import React from 'react';

import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { useTheme, withStyles } from '@material-ui/core/styles';

import { spacingInPixels } from '../../../shared/functions/materialUtils';
import NegociacaoStatus, {
  NegociacaoStatusType,
  verificarNegociacaoConcluida,
} from '../../../shared/constants/NegociacaoStatus';

export type StatusNegociacao = {
  codigo: NegociacaoStatusType;
  texto: string;
  componente: JSX.Element;
};

const BoxStatus = withStyles((theme) => ({
  root: {
    height: spacingInPixels(theme, 4),
    width: spacingInPixels(theme, 16),
    minWidth: spacingInPixels(theme, 16),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '7px',
    color: 'white',
  },
}))(Box);

const Texto = withStyles({
  root: {
    fontSize: '12px',
    fontWeight: 500,
  },
})(Typography);

const NOVA_NEGOCIACAO: StatusNegociacao = {
  codigo: NegociacaoStatus.NovaNegociacao,
  texto: 'Nova negociação',
  componente: (
    <BoxStatus bgcolor="cores.azul.200">
      <Texto>Nova negociação</Texto>
    </BoxStatus>
  ),
};

const EM_ANDAMENTO: StatusNegociacao = {
  codigo: NegociacaoStatus.EmAndamento,
  texto: 'Em andamento',
  componente: (
    <BoxStatus bgcolor="cores.lilas.300">
      <Texto>Em andamento</Texto>
    </BoxStatus>
  ),
};

const CANCELADA: StatusNegociacao = {
  codigo: NegociacaoStatus.Cancelada,
  texto: 'Recusado',
  componente: (
    <BoxStatus bgcolor="cores.vermelho.400">
      <Texto>Recusado</Texto>
    </BoxStatus>
  ),
};

const AGUARDAR_GESTAO: StatusNegociacao = {
  codigo: NegociacaoStatus.AguardarGestao,
  texto: 'Aguardar gestão',
  componente: (
    <BoxStatus bgcolor="cores.mostarda.400">
      <Texto>Aguardar gestão</Texto>
    </BoxStatus>
  ),
};

const CONCLUIDA: StatusNegociacao = {
  codigo: NegociacaoStatus.Concluida,
  texto: 'Concluída',
  componente: (
    <BoxStatus bgcolor="cores.verdeClaro.300">
      <Texto>Concluída</Texto>
    </BoxStatus>
  ),
};

const TEMPO_EXPIRADO: StatusNegociacao = {
  codigo: NegociacaoStatus.TempoExpirado,
  texto: 'Concluída',
  componente: (
    <BoxStatus bgcolor="cores.laranjaForte.400">
      <Texto>Tempo expirando</Texto>
    </BoxStatus>
  ),
};

export const StatusNegociacaoConstants: StatusNegociacao[] = [
  NOVA_NEGOCIACAO,
  EM_ANDAMENTO,
  AGUARDAR_GESTAO,
  CANCELADA,
  CONCLUIDA,
  TEMPO_EXPIRADO,
];

const BoxImg = withStyles((theme) => ({
  root: {
    '& img': {
      height: spacingInPixels(theme, 3),
      width: spacingInPixels(theme, 3),
      marginRight: spacingInPixels(theme, -2),
    },
  },
}))(Box);

type BoxStatusNegociacaoProps = {
  codigoStatus: NegociacaoStatusType;
  isNegociacaoUrgente?: boolean;
};

const BoxStatusNegociacao = ({
  codigoStatus,
  isNegociacaoUrgente,
}: BoxStatusNegociacaoProps): JSX.Element => {
  const theme = useTheme();

  const statusNegociacao = StatusNegociacaoConstants.find(
    (status) => codigoStatus === status.codigo,
  );

  return statusNegociacao ? (
    <Box display="flex" alignItems="center" width="100%">
      {statusNegociacao.componente}
      {isNegociacaoUrgente &&
        !verificarNegociacaoConcluida(statusNegociacao.codigo) && (
          <BoxImg
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            minWidth={spacingInPixels(theme, 2)}
          >
            <Tooltip title="Negociação Urgente">
              <img
                src="/images/minhasNegociacoes/icone-urgente.svg"
                alt="Negociação Urgente"
              />
            </Tooltip>
          </BoxImg>
        )}
    </Box>
  ) : (
    <></>
  );
};

BoxStatusNegociacao.defaultProps = {
  isNegociacaoUrgente: false,
};

export default BoxStatusNegociacao;
