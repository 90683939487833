import React from 'react';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import spacingInPixels from '../../../../shared/functions/materialUtils';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    fontFamily: 'Rubik',
    fontStyle: 'normal',
  },
  tituloMetrica: {
    fontWeight: 700,
    fontSize: spacingInPixels(theme, 1.5),
    lineHeight: spacingInPixels(theme, 2.5),
    letterSpacing: '0.4px',
    color: theme.palette.cores.cinza[600],
    width: '70%',
    '@media (max-width: 1200px)': {
      width: '80%',
    },
    '@media (max-width: 1000px)': {
      width: '90%',
    },
  },
  conteudoMetrica: {
    fontWeight: 500,
    fontSize: spacingInPixels(theme, 2),
    lineHeight: spacingInPixels(theme, 4),
    color: theme.palette.cores.cinza[800],
  },
}));

export interface DetalhesMetricasProps {
  titulo: string;
  conteudo: string;
  carregando?: boolean;
}

const DetalhesMetricas = ({
  titulo,
  conteudo,
  carregando = false,
}: DetalhesMetricasProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Grid container direction="column" className={classes.container}>
      <Grid item className={classes.tituloMetrica}>
        {titulo}
      </Grid>
      <Grid item className={classes.conteudoMetrica}>
        {carregando ? <Skeleton width="50%" height={32} /> : <>{conteudo}</>}
      </Grid>
    </Grid>
  );
};

export default DetalhesMetricas;
