import React, { useEffect, useState } from 'react';

import { Typography, makeStyles } from '@material-ui/core';

import { obterCorPorcentagem } from '../../functions/porcentagemUtils';
import { porcentagemStylesFundo } from '../../styles/porcentagemStyles';
import { TipoCorPorcentagem } from '../../constants/TipoCorPorcentagem';
import { converterParaDecimal } from '../../functions/numberUtils';

const useStyles = makeStyles((theme) => ({
  campoPorcentagem: {
    color: theme.palette.cores.branco,
    padding: theme.spacing(0.5, 1),
    borderRadius: 4,
    margin: theme.spacing(0, 2),

    display: 'flex',
    alignItems: 'center',
  },
  textoPorcentagem: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 14,
    marginRight: 4,
  },
  textoComplemento: {
    fontSize: 13,
    fontWeight: 400,
  },
}));

type PorcentagemProps = {
  styles?: string;
  stylesPorcentagem?: string;
  stylesComplemento?: string;
  porcentagem: string;
  complemento?: string;
};

const Porcentagem = ({
  styles,
  stylesPorcentagem,
  stylesComplemento,
  porcentagem,
  complemento,
}: PorcentagemProps): JSX.Element => {
  const [cor, setCor] = useState<TipoCorPorcentagem>(TipoCorPorcentagem.CINZA);

  const classes = useStyles();
  const estilosPorcentagem = porcentagemStylesFundo();

  useEffect(() => {
    const valor = converterParaDecimal(porcentagem);
    setCor(obterCorPorcentagem(valor));
  }, [porcentagem]);

  return (
    <Typography
      className={`${classes.campoPorcentagem} ${estilosPorcentagem[cor]} ${styles}`}
    >
      <Typography
        component="span"
        className={`${classes.textoPorcentagem} ${stylesPorcentagem}`}
      >
        {porcentagem}{' '}
      </Typography>
      <Typography
        component="span"
        className={`${classes.textoComplemento} ${stylesComplemento}`}
      >
        {complemento}
      </Typography>
    </Typography>
  );
};

Porcentagem.defaultProps = {
  styles: undefined,
  stylesPorcentagem: undefined,
  stylesComplemento: undefined,
  complemento: '',
};

export default Porcentagem;
