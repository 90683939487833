import { createAction } from '@reduxjs/toolkit';
import { PaginaType } from 'shared/constants/Pagina';
import { DadosUsuarioResponse } from '../api/loginApiTypes';

export const atualizarDadosUsuario = createAction<DadosUsuarioResponse | null>(
  'loginAction/ATUALIZAR_DADOS_USUARIO',
);

export const removerDadosUsuario = createAction<void>(
  'loginAction/REMOVER_DADOS_USUARIO',
);

export const atualizarPaginasAcesso = createAction<PaginaType[] | null>(
  'loginAction/ATUALIZAR_DADOS_PAGINA_ACESSO_USUARIO',
);
