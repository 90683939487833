/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useState } from 'react';
import {
  isUndefined,
  isEqual,
  isObject,
  isArray,
  xorWith,
  isEmpty,
  size,
} from 'lodash';
import useDebounce from './useDebounce';

const useMutationObserver = <T>(start: T) => {
  const [initial, setInitial] = useState<T>(start);
  const [current, setCurrent] = useState<T>(start);
  const [isChanged, setIsChanged] = useState(false);

  const debounce = useDebounce(setIsChanged, 300);

  useEffect(() => {
    if (isUndefined(initial) && isUndefined(current)) {
      debounce(false);
    }

    if (isObject(initial) && isObject(current)) {
      const hasChanges = !isEqual(initial, current);
      debounce(hasChanges);
    }

    if (isArray(initial) && isArray(current)) {
      const hasSameSize = size(initial) === size(current);
      const hasChanges = !isEmpty(xorWith(initial, current, isEqual));
      debounce(hasChanges || !hasSameSize);
    }
  }, [initial, current, debounce]);

  return {
    values: current,
    setValues: setCurrent,
    setInitial,
    initial,
    isChanged,
  };
};

export default useMutationObserver;
