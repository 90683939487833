enum TipoProposta {
  PROPOSTA_INICIAL = 0,
  CONTRAPROPOSTA = 1,
  PROPOSTA_FINAL = 2,
  RETOMAR_PROPOSTA = 3,
}

export type TipoPropostaType = TipoProposta;

export default TipoProposta;
