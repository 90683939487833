import { Box } from '@material-ui/core';
import React from 'react';
import CustomTooltip from 'shared/components/tooltip/CustomTooltip';
import { IconeTariafarioProps } from 'features/detalhesDemandasDiaria/types/tabelaTypes';
import { useStyle } from '../../styles/styleTabela';
import { DicionarioTarifario } from '../../constants/IconeTariafario';

const IconeTariafario = ({ status }: IconeTariafarioProps): JSX.Element => {
  const classes = useStyle();

  return (
    <CustomTooltip
      title={DicionarioTarifario[status].title}
      placement="top"
      fontSize={14}
      arrow
    >
      <Box
        className={classes.boxMax}
        style={{
          backgroundColor: DicionarioTarifario[status].backgroundColor,
        }}
      >
        {DicionarioTarifario[status].children}
      </Box>
    </CustomTooltip>
  );
};

export default IconeTariafario;
