import { NotificacaoType } from '../types/notificacoes';
import { OPCOES_NOTIFICATION } from '../constants/mensagensNotificacoes';
import {
  criarNotificacao,
  permissaoNotificacaoConcedida,
  permissaoNotificacaoDefault,
  requisitarPermissaoNotificacao,
} from '../../../shared/webApi/notificacaoUtils';
import {
  abrirEmNovaAba,
  atualizarNotificacoesTitulo,
  documentoEmFoco,
} from '../../../shared/webApi/windowUtils';
import { playAudio } from '../../../shared/webApi/audioUtils';

const logoMartins = '../../../../images/logos/logo-martins-192x192.png';

const somNotificacao = '/sounds/shared/notificacao-push.mp3';

const mostrarNotificacao = (notificacao: NotificacaoType): void => {
  criarNotificacao({
    titulo: notificacao.titulo || '',
    texto: notificacao.texto || '',
    icone: logoMartins,
    onClickFuncao: () => abrirEmNovaAba(notificacao.link),
  });
};

const mostrarNotificacoes = (): void => {
  criarNotificacao({
    titulo: OPCOES_NOTIFICATION.title || '',
    texto: OPCOES_NOTIFICATION.body || '',
    icone: logoMartins,
    onClickFuncao: () => abrirEmNovaAba(OPCOES_NOTIFICATION.link),
  });
};

const mostrarNotificacaoPush = (
  notificacoes: NotificacaoType[],
  quantidadeNovasNotificacoes: number,
): void => {
  if (!documentoEmFoco()) {
    if (quantidadeNovasNotificacoes === 1) {
      mostrarNotificacao(notificacoes[0]);
    } else {
      mostrarNotificacoes();
    }
  }
  playAudio(somNotificacao);
};

const solicitarPermissao = (
  notificacoes: NotificacaoType[],
  quantidadeNovasNotificacoes: number,
): void => {
  if (requisitarPermissaoNotificacao()) {
    mostrarNotificacaoPush(notificacoes, quantidadeNovasNotificacoes);
  }
};

const validaNotificao = (
  notificacoes: NotificacaoType[],
  quantidadeNovasNotificacoes: number,
): void => {
  if (permissaoNotificacaoConcedida()) {
    mostrarNotificacaoPush(notificacoes, quantidadeNovasNotificacoes);
  } else if (permissaoNotificacaoDefault()) {
    solicitarPermissao(notificacoes, quantidadeNovasNotificacoes);
  }
};

export const notificarBrowser = (
  notificacoes: NotificacaoType[],
  quantidadeNovasNotificacoes: number,
  quantidadeNaoLidas: number,
): void => {
  if (quantidadeNovasNotificacoes > 0) {
    validaNotificao(notificacoes, quantidadeNovasNotificacoes);
  }

  atualizarNotificacoesTitulo(quantidadeNaoLidas);
};
