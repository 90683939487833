import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import HeaderAccordion from '../../../shared/components/accordion/HeaderAccordion';
import LabelHeaderAccordion from '../../../shared/components/accordion/LabelHeaderAccordion';
import formatarZerosIniciais from '../../../shared/functions/numberUtils';

const useStyles = makeStyles((theme) => ({
  headerAccordion: {
    maxHeight: '32px',
    backgroundColor: theme.palette.background.default,
    borderBottom: `1px solid ${theme.palette.cores.cinza[500]}`,
  },
  gridHeaderAccordion: {
    display: 'flex',
    alignItems: 'center',
  },
  tipoPessoa: {
    marginLeft: '5px',
    color: theme.palette.cores.cinza[700],
    fontWeight: 500,
    fontSize: '13px',
  },
  cidades: {
    '@media(max-Width: 900px)': {
      marginLeft: '20px',
    },
  },
}));

type EntregaCidadeHeaderAccordionProps = {
  entregas: number;
  cidade: string;
  peso: number;
  volume: number;
  bairroPJ: number;
  bairroPF: number;
};

const EntregaCidadeHeaderAccordion = ({
  entregas,
  cidade,
  peso,
  volume,
  bairroPJ,
  bairroPF,
}: EntregaCidadeHeaderAccordionProps): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles();

  const estiloTexto = {
    tituloCor: String(theme.palette.text.primary),
    fontWeight: 600,
  };

  const estiloMedidas = {
    tituloCor: String(theme.palette.text.primary),
    fontWeight: 700,
  };

  const formatarEntregas = (): string =>
    entregas === 0 ? '--' : formatarZerosIniciais(entregas, 2);

  return (
    <HeaderAccordion styles={classes.headerAccordion}>
      <Grid container spacing={2}>
        <Grid item className={classes.gridHeaderAccordion} xs={2}>
          <LabelHeaderAccordion
            titulo={formatarEntregas()}
            estilo={estiloTexto}
          />
          <Typography variant="h6" className={classes.tipoPessoa}>
            {`(${bairroPJ}PJ / ${bairroPF}PF)`}
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.cidades}>
          <LabelHeaderAccordion titulo={cidade} estilo={estiloTexto} />
        </Grid>
        <Grid item xs={2} sm={2}>
          <LabelHeaderAccordion
            titulo={peso.toLocaleString('pt-br')}
            estilo={estiloMedidas}
          />
        </Grid>
        <Grid item xs={2} sm={1}>
          <LabelHeaderAccordion
            titulo={volume.toLocaleString('pt-br')}
            estilo={estiloMedidas}
          />
        </Grid>
      </Grid>
    </HeaderAccordion>
  );
};

export default EntregaCidadeHeaderAccordion;
