import { ConexaoAPI } from '../../../shared/classes/conexaoAPI';

import {
  Negociacao,
  ResponseNegociacoes,
  ResponseTranferenciaNegociador,
} from './negociacoesAutomatizadasApiTypes';

export const obterNegociacoesFreteTabelado = async (
  pagina: string,
  total?: string,
): Promise<{ totalPaginas: number; negociacoes: Negociacao[] }> => {
  const url = `${process.env.REACT_APP_URL_API_BASE}/negociacoes?isFreteTabelado=true&pagina=${pagina}&quantidadeItemPorPagina=${total}`;
  const conexao = new ConexaoAPI(url, 'get');
  const conectar = conexao.gerarFuncaoConectarRefreshToken<ResponseNegociacoes>();
  const resposta = await conectar();

  const negociacoesComFreteTabelado = resposta.data.negociacoes.map(
    (negociacao) => ({
      ...negociacao,
      freteTabelado: true,
    }),
  );

  return {
    totalPaginas: resposta.data.totalPaginas,
    negociacoes: negociacoesComFreteTabelado,
  };
};

export const obterNegociacoesNegociadores = async (
  idUsuarioMaster: string,
): Promise<ResponseNegociacoes> => {
  const url = `${process.env.REACT_APP_URL_API_BASE}/negociacoes?usuarioMaster=${idUsuarioMaster}`;
  const conexao = new ConexaoAPI(url, 'get');
  const conectar = conexao.gerarFuncaoConectarRefreshToken<ResponseNegociacoes>();
  const resposta = await conectar();
  return resposta.data;
};

export const transferirNegociacoes = async (
  idNegociacao: number,
  idNegociador: number | undefined,
): Promise<ResponseTranferenciaNegociador> => {
  const url = `${process.env.REACT_APP_URL_API_BASE}/negociacoes/reatribuir-negociacao/${idNegociacao}/negociador/${idNegociador}`;
  const conexao = new ConexaoAPI(url, 'put');
  const conectar = conexao.gerarFuncaoConectarRefreshToken<ResponseTranferenciaNegociador>();
  const resposta = await conectar();

  return resposta.data;
};
