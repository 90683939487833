import { createAction } from '@reduxjs/toolkit';
import { DetalhesFilialState } from './detalhesFilialReducer';

export const listarDetalhesFilial = createAction<DetalhesFilialState>(
  'listarDetalhesFilialAction/LISTAR_DETALHES_FILIAL',
);

export const limparDetalhesFilial = createAction<DetalhesFilialState>(
  'limparDetalhesFilialAction/LIMPAR_DETALHES_FILIAL',
);
