export const sliderMarks = [
  {
    value: 0,
    label: '0',
  },
  { value: 10 },
  { value: 20 },
  { value: 30 },
  { value: 40 },
  { value: 50 },
  { value: 60 },
  { value: 70 },
  { value: 80 },
  { value: 90 },
  {
    value: 100,
    label: '100',
  },
  { value: 110 },
  { value: 120 },
  { value: 130 },
  { value: 140 },
  { value: 150 },
  { value: 160 },
  { value: 170 },
  { value: 180 },
  { value: 190 },
  {
    value: 200,
    label: '200',
  },
];
