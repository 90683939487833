import React from 'react';
import { ChipProps, Chip, makeStyles, useTheme } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';
import IconeOlho from 'assets/icones/IconeOlho';
import { useHistory } from 'react-router-dom';
import { PATH_DEMANDAS_ANDAMENTO } from 'shared/constants/pathConstants';

const useStyles = makeStyles((theme) => ({
  botao: {
    padding: `${spacingInPixels(theme, 0.8)} ${spacingInPixels(theme, 1.2)}`,
    marginRight: spacingInPixels(theme, 2),
    cursor: 'pointer',

    color: theme.palette.cores.branco,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: spacingInPixels(theme, 1.5),
    lineHeight: spacingInPixels(theme, 1.5),

    '&:hover': {
      backgroundColor: theme.palette.cores.azulMartins[700],
    },
  },
}));

const ChipDemandasEmAndamento = ({
  hidden,
  ...props
}: ChipProps): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  if (hidden) return <></>;

  return (
    <Chip
      size="medium"
      label="Ver todas em andamento"
      color="primary"
      icon={
        <IconeOlho
          width={16}
          height={14}
          viewBox="0 0 11 9"
          fill={theme.palette.cores.branco}
        />
      }
      className={classes.botao}
      onClick={() => history.push(PATH_DEMANDAS_ANDAMENTO)}
      {...props}
    />
  );
};

export default ChipDemandasEmAndamento;
