import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import { makeStyles } from '@material-ui/core';

import Botao from 'shared/components/button/BotaoRubik';
import BotaoVermelho from 'shared/components/button/BotaoRubikVermelho';
import spacingInPixels from 'shared/functions/materialUtils';
import {
  verificarContraproposta,
  verificarMensagemAceite,
} from 'shared/constants/TipoMensagemProposta';

import { RootState } from 'store/reducer';
import { Aceitar, Recusar } from '../../../redux/chatNegociacaoReducer';
import { atualizarExibicaoRespostaNegociador } from '../../../redux/chatNegociacaoAction';

import TypographyChat from '../../TypographyChat';
import BotaoContraproposta from './BotaoContraproposta';

import {
  exibirBotaoAceitar,
  exibirBotaoPedirAprovacao,
} from '../../../utils/chatResposta';
import PedirAprovacao from './PedidoAprovacao';

const useStyles = makeStyles((theme) => ({
  botoes: {
    display: 'flex',
    justifyContent: 'center',
    gap: 10,
    marginTop: spacingInPixels(theme, 2),
  },
  botao: {
    width: 200,
    borderRadius: spacingInPixels(theme, 3),
  },
  botaoAceitarGreguim: {
    flexGrow: 2,
    maxWidth: 300,
  },
  botaoRecusar: {
    minWidth: 152,
    borderRadius: spacingInPixels(theme, 3),
  },
  titulo: {
    fontSize: '16px',
    fontWeight: 600,
    color: theme.palette.cores.cinza[700],
  },
}));

const BotoesResposta = (): JSX.Element => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const chatNegociacaoReducer = useSelector(
    (state: RootState) => state.chatNegociacaoReducer,
  );

  const informacoesNegociacaoReducer = useSelector(
    (state: RootState) => state.informacoesNegociacaoReducer,
  );

  const exibirBotaoAceitarGreguim = useMemo(
    () => informacoesNegociacaoReducer?.negociacao?.sugestaoValorInicialRobo,
    [informacoesNegociacaoReducer],
  );

  const valorContrapropostaAtual = useMemo(() => {
    const {
      propostas,
      propostasAplicativo,
      idPropostaComFoco,
    } = informacoesNegociacaoReducer;
    return propostas
      .concat(propostasAplicativo)
      .find((propostaNegociacao) => idPropostaComFoco === propostaNegociacao.id)
      ?.propostaAtual;
  }, [informacoesNegociacaoReducer]);

  const verificarMensagemAceiteOuContraProposta =
    verificarContraproposta(chatNegociacaoReducer.mensagemReferencia?.tipo) ||
    verificarMensagemAceite(chatNegociacaoReducer.mensagemReferencia?.tipo);

  const apresentarPedidoAprovacao = useMemo(
    () =>
      verificarMensagemAceiteOuContraProposta &&
      exibirBotaoPedirAprovacao({
        sugestaoValorInicialRobo: informacoesNegociacaoReducer.negociacao
          ?.valorNovaMeta
          ? informacoesNegociacaoReducer.negociacao?.valorNovaMeta
          : exibirBotaoAceitarGreguim,
        valorFrotaPropria: informacoesNegociacaoReducer.negociacao
          ?.valorNovaMeta
          ? informacoesNegociacaoReducer.negociacao?.valorNovaMeta
          : exibirBotaoAceitarGreguim ||
            chatNegociacaoReducer.chatValorFrotaPropria,
        valorContraproposta: valorContrapropostaAtual,
        valorNovaMeta: informacoesNegociacaoReducer.negociacao?.valorNovaMeta,
      }),
    [
      chatNegociacaoReducer.chatValorFrotaPropria,
      informacoesNegociacaoReducer.negociacao?.sugestaoValorInicialRobo,
      informacoesNegociacaoReducer.negociacao?.valorNovaMeta,
      valorContrapropostaAtual,
      verificarMensagemAceiteOuContraProposta,
    ],
  );

  const onClickAceitar = (): void => {
    dispatch(atualizarExibicaoRespostaNegociador(Aceitar));
  };

  const onClickRecusar = (): void => {
    dispatch(atualizarExibicaoRespostaNegociador(Recusar));
  };

  const apresentarBotaoAceitar =
    verificarMensagemAceiteOuContraProposta &&
    exibirBotaoAceitar({
      sugestaoValorInicialRobo: informacoesNegociacaoReducer.negociacao
        ?.valorNovaMeta
        ? informacoesNegociacaoReducer.negociacao?.valorNovaMeta
        : exibirBotaoAceitarGreguim,
      valorContraproposta: valorContrapropostaAtual,
      valorFrotaPropria: informacoesNegociacaoReducer.negociacao?.valorNovaMeta
        ? informacoesNegociacaoReducer.negociacao?.valorNovaMeta
        : exibirBotaoAceitarGreguim ||
          chatNegociacaoReducer.chatValorFrotaPropria,
      valorNovaMeta: informacoesNegociacaoReducer.negociacao?.valorNovaMeta,
    });

  return (
    <Box textAlign="center">
      <TypographyChat className={classes.titulo}>
        O que você gostaria de fazer?
      </TypographyChat>
      <Box className={classes.botoes}>
        {apresentarPedidoAprovacao && (
          <>
            <PedirAprovacao
              estiloBotao={classes.botao}
              valorContrapropostaAtual={valorContrapropostaAtual}
            />
            <BotaoContraproposta className={classes.botao} />
          </>
        )}

        {apresentarBotaoAceitar && (
          <Botao
            className={`${classes.botao}`}
            variant="contained"
            color="primary"
            startIcon={<CheckRoundedIcon />}
            onClick={onClickAceitar}
          >
            Aceitar
          </Botao>
        )}

        <BotaoVermelho
          className={classes.botaoRecusar}
          variant="outlined"
          startIcon={<ClearRoundedIcon />}
          onClick={onClickRecusar}
        >
          Recusar
        </BotaoVermelho>
      </Box>
    </Box>
  );
};

export default BotoesResposta;
