import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import {
  Box,
  Checkbox,
  IconButton,
  Tooltip,
  TooltipProps,
} from '@material-ui/core';
import { NegociacaoStatus } from 'shared/constants/NegociacaoStatus';
import Temporizador from 'shared/components/temporizador/Temporizador';
import { InfoRounded } from '@material-ui/icons';
import EstadoAtualProposta from 'features/minhasNegociacoes/constants/EstadoAtualProposta';

import { format } from 'date-fns';
import ListaMotoristasEnvolvidos from './ListaMotoristasEnvolvidos';
import LinkButton from '../../../shared/components/button/LinkButton';
import TableRow from '../../../shared/components/table/TableRowHeightSpacing6';
import TableCell from '../../../shared/components/table/TableCellCustomPadding';
import { obterCodigoFormatado } from '../../../shared/functions/romaneioUtils';
import CustomWidthTooltip from '../../../shared/components/tooltip/CustomWidthTooltip';

import { Negociacao } from '../api/negociacoesAutomatizadasApiTypes';

import BoxStatusNegociacao from './BoxStatusNegociacao';

export type TabelaItemProps = {
  negociacao: Negociacao;
  checkboxValue: boolean;
  onCheckboxChange: (checked: boolean) => void;
};
interface CustomTooltipProps extends TooltipProps {
  customMinWidth?: string;
}

const LinkButtonCellBold = withStyles({
  root: {
    fontSize: '16px',
    fontWeight: 700,
  },
})(LinkButton);

const TableCellBold = withStyles({
  root: {
    fontSize: '16px',
    fontWeight: 700,
  },
})(TableCell);

const TableCellSmall = withStyles({
  root: {
    fontSize: '14px',
    fontWeight: 500,
  },
})(TableCell);

const CustomTooltip = withStyles(() => ({
  tooltip: (props: CustomTooltipProps) => ({
    backgroundColor: '#212121',
    color: 'white',
    fontSize: '14px',
    fontWeight: 400,
    whiteSpace: 'nowrap',
    minWidth: props.customMinWidth || '',
  }),
  arrow: {
    color: '#212121',
  },
}))(Tooltip);

const CustomIconButton = withStyles(() => ({
  root: () => ({
    color: '#616161',
    padding: '0',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: 'white',
    },
  }),
}))(IconButton);

const TabelaItem = ({
  negociacao,
  checkboxValue,
  onCheckboxChange,
}: TabelaItemProps): JSX.Element => {
  const history = useHistory();
  const [widthText, setWidthText] = useState('');

  const dataFormatada = format(
    new Date(negociacao.dataCarregamento),
    'dd/MM/yyyy',
  );

  const handleLinkClick = () => {
    const estadoPersonalizado = {
      origemFreteTabelado: true,
      idFilial: negociacao.filialId,
    };

    history.push({
      pathname: `/negociacoes-automatizada/${negociacao.id}`,
      state: estadoPersonalizado,
    });
  };

  useEffect(() => {
    if (
      negociacao?.isTempoExpirado &&
      negociacao.motoristasEnvolvidos.length <= 0
    ) {
      setWidthText('310px');
    }
  }, [negociacao, setWidthText]);

  const generateTooltipContent = (negociacaoParam: Negociacao) => {
    if (negociacaoParam.motoristasEnvolvidos.length <= 0) {
      return 'Não há motorista compatível com essa carga';
    }
    if (
      negociacaoParam?.motoristasEnvolvidos.some(
        (motorista) => motorista.status === EstadoAtualProposta.RECUSADA,
      )
    ) {
      return 'Recusado por um ou mais motorista';
    }
    if (negociacaoParam?.isTempoExpirado) {
      return 'O tempo de negociação foi expirado';
    }
    return '';
  };

  return (
    <TableRow>
      <TableCellBold align="left">
        <Checkbox
          color="primary"
          onChange={(event) => onCheckboxChange(event.target.checked)}
          checked={checkboxValue || false}
          disabled={negociacao.status === NegociacaoStatus.Concluida}
        />
        <CustomWidthTooltip
          title={`Ver detalhamento da negociação ${obterCodigoFormatado(
            negociacao.romaneioCarga,
          )}`}
        >
          <LinkButtonCellBold onClick={handleLinkClick}>
            {obterCodigoFormatado(negociacao.romaneioCarga, 1)}
          </LinkButtonCellBold>
        </CustomWidthTooltip>
      </TableCellBold>
      <TableCellSmall align="center">{dataFormatada}</TableCellSmall>
      <TableCellSmall align="left">{negociacao.filial}</TableCellSmall>
      <TableCellSmall align="left">{negociacao.cidade}</TableCellSmall>
      <TableCellSmall align="center">
        {negociacao.quantidadeEntregas}
      </TableCellSmall>
      <TableCellSmall align="center">
        {negociacao.peso.toLocaleString()}
      </TableCellSmall>
      <TableCellSmall align="center">
        {negociacao.volume.toLocaleString()}
      </TableCellSmall>
      <TableCellSmall align="center">
        {negociacao.distancia.toLocaleString()}
      </TableCellSmall>
      <TableCell align="center" style={{ paddingRight: '25px' }}>
        {negociacao.motoristasEnvolvidos.length > 0 && (
          <ListaMotoristasEnvolvidos negociacao={negociacao} />
        )}
      </TableCell>
      <TableCell align="left" style={{ paddingRight: '5px' }}>
        <BoxStatusNegociacao
          codigoStatus={negociacao.status}
          isNegociacaoUrgente={negociacao.urgente}
        />
      </TableCell>
      <TableCellSmall align="center">
        <Box display="flex" alignItems="center">
          <Box paddingRight="8px">
            <Temporizador
              tempo={negociacao?.tempoParaExpirarNegociacoesEmMinutos}
              tempoData={negociacao?.dataCarregamento}
              tabelaFreteTabelado
              validacao={
                negociacao?.status === NegociacaoStatus.Concluida ||
                negociacao?.status === NegociacaoStatus.NovaNegociacao
                  ? false
                  : negociacao?.isTempoExpirado
              }
            />
          </Box>
          {!negociacao?.isTempoExpirado ||
            (negociacao?.status !== NegociacaoStatus.Concluida && (
              <CustomTooltip
                placement="left"
                title={generateTooltipContent(negociacao)}
                customMinWidth={widthText}
                arrow
              >
                <CustomIconButton>
                  <InfoRounded />
                </CustomIconButton>
              </CustomTooltip>
            ))}
        </Box>
      </TableCellSmall>
    </TableRow>
  );
};

export default TabelaItem;
