import { Theme, makeStyles } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';

export const useStyles = makeStyles((theme: Theme) => ({
  texto: {
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 400,
  },
  tarafarioCadastrado: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '80px',
    height: spacingInPixels(theme, 3),
    margin: `${spacingInPixels(theme, 0.65)} 0`,
    background: theme.palette.cores.azulMartins[500],
    borderRadius: spacingInPixels(theme, 0.5),

    '& span': {
      color: theme.palette.cores.branco,
      fontSize: spacingInPixels(theme, 1.5),
      lineHeight: spacingInPixels(theme, 1.5),
      verticalAlign: 'middle',
    },
  },
  formInserir: {
    display: 'flex',
    alignItems: 'center',

    height: spacingInPixels(theme, 4.25),
    color: theme.palette.cores.cinza[700],
    fontSize: spacingInPixels(theme, 2),
    lineHeight: spacingInPixels(theme, 3),
    letterSpacing: '0.5px',

    '& span.MuiButtonBase-root.MuiIconButton-root.MuiCheckbox-root': {
      padding: spacingInPixels(theme, 1.25),
    },

    '& span > svg.MuiSvgIcon-root': {
      width: spacingInPixels(theme, 2.25),
      height: spacingInPixels(theme, 2.25),
    },

    '& div.MuiCircularProgress-root.MuiCircularProgress-indeterminate': {
      marginRight: spacingInPixels(theme, 1.5),
      marginLeft: spacingInPixels(theme, 1.25),
    },
  },
}));
