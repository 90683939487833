import { createMuiTheme } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';
import { Rubik } from './shared/constants/font';

type Cor = {
  '50': React.CSSProperties['color'];
  '100': React.CSSProperties['color'];
  '200': React.CSSProperties['color'];
  '300': React.CSSProperties['color'];
  '400': React.CSSProperties['color'];
  '450': React.CSSProperties['color'];
  '500': React.CSSProperties['color'];
  '600': React.CSSProperties['color'];
  '700': React.CSSProperties['color'];
  '800': React.CSSProperties['color'];
  '900': React.CSSProperties['color'];
};

type Cores = {
  amarelo: Pick<Cor, '100' | '200' | '400' | '700' | '800' | '900'>;
  azul: Pick<Cor, '50' | '100' | '200' | '400' | '500' | '600' | '700'>;
  azulMartins: Omit<Cor, '300' | '450' | '600' | '800'>;
  azulPiscina: Pick<
    Cor,
    '50' | '100' | '200' | '300' | '400' | '500' | '600' | '700' | '900'
  >;
  branco: React.CSSProperties['color'];
  cinza: Omit<Cor, '450'>;
  laranja: Pick<Cor, '100' | '200' | '300' | '400' | '700' | '900'>;
  laranjaForte: Pick<Cor, '400' | '500' | '700'>;
  lilas: Pick<Cor, '50' | '200' | '300' | '400' | '700' | '800'>;
  mostarda: Pick<Cor, '200' | '400' | '500' | '700' | '800'>;
  verde: Pick<Cor, '200' | '300' | '400' | '500' | '700' | '800'>;
  verdeClaro: Pick<Cor, '100' | '200' | '300' | '400' | '500'>;
  vermelho: Pick<
    Cor,
    '100' | '200' | '400' | '450' | '500' | '600' | '700' | '800'
  >;
  roxo: Pick<Cor, '100' | '200'>;
  cinzaAzul: Pick<Cor, '50'>;
};

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    cores: Cores;
  }
  interface PaletteOptions {
    cores: Cores;
  }
}

export default createMuiTheme(
  {
    palette: {
      primary: {
        main: '#2B52DD',
      },
      secondary: {
        main: '#004282',
      },
      error: {
        main: '#E73A33',
      },
      warning: {
        main: '#FBC436',
      },
      success: {
        main: '#3EB96F',
      },
      info: {
        main: '#5BA2FF',
      },
      background: {
        default: '#F4F6FC',
      },
      text: {
        primary: '#2B2C32',
        secondary: '#6D7384',
      },
      cores: {
        amarelo: {
          '100': '#FEF3D7',
          '200': '#FEF69F',
          '400': '#FBEC5B',
          '700': '#FBC436',
          '800': '#F9AB2E',
          '900': '#D98B0E',
        },
        azul: {
          '50': '#E3F2FD',
          '100': '#B6D5FF',
          '200': '#86BAFF',
          '400': '#428CF4',
          '500': '#2979FF',
          '600': '#1976D2',
          '700': '#3959C8',
        },
        azulMartins: {
          '50': '#DDE0EC',
          '100': '#D6DBFF',
          '200': '#9FAAEF',
          '400': '#536DE4',
          '500': '#2B52DD',
          '700': '#173EC5',
          '900': '#004282',
        },
        azulPiscina: {
          '50': '#E0F7FA',
          '100': '#B7E3EC',
          '200': '#8ED1DF',
          '300': '#4FB4C9',
          '400': '#00ACC1',
          '500': '#2196F3',
          '600': '#90A4AE',
          '700': '#368595',
          '900': '#546E7A',
        },
        branco: '#FFFFFF',
        cinza: {
          '50': '#FAFAFA',
          '100': '#F5F5F9',
          '200': '#F9F9F9',
          '300': '#EBECF4',
          '400': '#C9CAD2',
          '500': '#ACADB4',
          '600': '#82838A',
          '700': '#6D6E75',
          '800': '#424242',
          '900': '#2B2C32',
        },
        laranja: {
          '100': '#FFF3E0',
          '200': '#FFCC9A',
          '300': '#FFB876',
          '400': '#FEA961',
          '700': '#F08249',
          '900': '#FB8C00',
        },
        laranjaForte: {
          '400': '#EF6C00',
          '500': '#FF5722',
          '700': '#DD2C00',
        },
        lilas: {
          '50': '#F3E5F5',
          '200': '#CEABFF',
          '300': '#BB86FF',
          '400': '#A967FD',
          '700': '#7E3BE4',
          '800': '#7B1FA2',
        },
        mostarda: {
          '200': '#FEE089',
          '400': '#FECA3D',
          '500': '#FDC22E',
          '700': '#FCA226',
          '800': '#FF9100',
        },
        verde: {
          '200': '#94D5AA',
          '300': '#66C588',
          '400': '#3EB96F',
          '500': '#4CAF50',
          '700': '#008C41',
          '800': '#15b09a',
        },
        verdeClaro: {
          '100': '#E8F5E9',
          '200': '#B0E8B1',
          '300': '#8EDF91',
          '400': '#73D677',
          '500': '#47AA49',
        },
        vermelho: {
          '100': '#FFEBEE',
          '200': '#F09A99',
          '400': '#F1534E',
          '450': '#F44336',
          '500': '#D50000',
          '600': '#E73A33',
          '700': '#D52F2D',
          '800': '#C62828',
        },
        roxo: {
          '100': '#9FAAEF',
          '200': '#5C6BC0',
        },
        cinzaAzul: {
          '50': '#ECEFF1',
        },
      },
    },
    typography: {
      fontFamily: Rubik,
      body1: {
        fontWeight: 500,
        fontSize: '16px',
      },
      h4: {
        fontWeight: 500,
        fontSize: '22px',
        lineHeight: '32px',
      },
      h5: {
        fontFamily: Rubik,
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '24px',
      },
      h6: {
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '24px',
      },
    },
  },
  ptBR,
);
