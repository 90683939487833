import { Dispatch } from 'redux';

import { Theme } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import {
  atualizarNegociacaoOperation,
  atualizarPropostasOperation,
} from 'features/informacoesNegociacao/redux/informacoesNegociacaoOperation';
import {
  OrigemCargaType,
  verificarCargaProd,
} from '../../../shared/constants/OrigemCarga';

import { calcularPorcentagem } from '../../../shared/functions/numberUtils';

import {
  atualizarMensagensOperation,
  atualizarMensagensOperationFreteTabelado,
} from '../redux/chatNegociacaoOperation';
import { smoothScroll } from '../../../shared/functions/scrollUtils';

export const MilissegundosAtualizacaoMensagens = 10000;

export const scrollFinalChat = (
  referenciaAtual: HTMLInputElement | null,
  aberto: boolean,
): void => {
  if (aberto) {
    smoothScroll(referenciaAtual);
  }
};

export const criarPollingMensagens = (
  dispatch: Dispatch,
  chatAberto: boolean,
  chatIdNegociacao: number | undefined,
  chatIdProposta: number | undefined,
  motivoRecusa: number | undefined,
  idUsuarioMaster?: number | null,
): number | undefined => {
  return !chatAberto
    ? undefined
    : Number(
        setInterval(async () => {
          await atualizarMensagensOperation(
            chatIdNegociacao,
            chatIdProposta,
            motivoRecusa,
            idUsuarioMaster,
          )(dispatch);
          if (chatIdNegociacao) {
            await atualizarNegociacaoOperation(
              chatIdNegociacao,
              idUsuarioMaster,
            )(dispatch);
            atualizarPropostasOperation(
              chatIdNegociacao,
              idUsuarioMaster,
            )(dispatch);
          }
        }, MilissegundosAtualizacaoMensagens),
      );
};

export const atualizarMensagensChatAberto = async (
  dispatch: Dispatch,
  chatAberto: boolean,
  chatIdNegociacao: number | undefined,
  chatIdProposta: number | undefined,
  motivoRecusa: number | undefined,
  idUsuarioMaster?: number | null,
): Promise<void> => {
  if (chatAberto) {
    await atualizarMensagensOperation(
      chatIdNegociacao,
      chatIdProposta,
      motivoRecusa,
      idUsuarioMaster,
    )(dispatch);
  }
};

export const criarPollingMensagensFreteTabelado = (
  dispatch: Dispatch,
  chatAberto: boolean,
  chatIdNegociacao: number | undefined,
  chatIdProposta: number | undefined,
  motivoRecusa: number | undefined,
): number | undefined => {
  return !chatAberto
    ? undefined
    : Number(
        setInterval(async () => {
          await atualizarMensagensOperationFreteTabelado(
            chatIdNegociacao,
            chatIdProposta,

            motivoRecusa,
          )(dispatch);
          if (chatIdNegociacao) {
            await atualizarNegociacaoOperation(chatIdNegociacao)(dispatch);
            atualizarPropostasOperation(chatIdNegociacao)(dispatch);
          }
        }, MilissegundosAtualizacaoMensagens),
      );
};

export const atualizarMensagensChatAbertoFreteTabelado = async (
  dispatch: Dispatch,
  chatAberto: boolean,
  chatIdNegociacao: number | undefined,
  chatIdProposta: number | undefined,
  motivoRecusa: number | undefined,
): Promise<void> => {
  if (chatAberto) {
    await atualizarMensagensOperationFreteTabelado(
      chatIdNegociacao,
      chatIdProposta,
      motivoRecusa,
    )(dispatch);
  }
};

export const obterCorPorcentagem = (
  theme: Theme,
  valorProposta: number,
  valorFrotaPropria: number,
): CSSProperties['color'] => {
  const porcentagem = calcularPorcentagem(valorProposta, valorFrotaPropria);

  if (porcentagem >= 0 && porcentagem < 70) {
    return theme.palette.cores.verde[700];
  }

  if (porcentagem >= 70 && porcentagem < 85) {
    return theme.palette.cores.amarelo[900];
  }

  if (porcentagem >= 85) {
    return theme.palette.cores.vermelho[700];
  }

  return theme.palette.cores.cinza[500];
};

export const exibirRodape = (
  chatCarregando: boolean,
  origemCarga?: OrigemCargaType,
): boolean => !chatCarregando && verificarCargaProd(origemCarga);
