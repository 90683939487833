import React, { useState } from 'react';
import {
  Badge,
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from '@material-ui/core';

import spacingInPixels from '../../functions/materialUtils';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    width: 'fit-content',
    height: spacingInPixels(theme, 3.5),
    borderRadius: spacingInPixels(theme, 0.5),
    marginTop: spacingInPixels(theme, 1.25),
    border: `1px solid ${theme.palette.cores.cinza[300]}`,
    textAlign: 'center',
    backgroundColor: theme.palette.cores.branco,
    color: theme.palette.cores.cinza[900],
    cursor: 'pointer',

    '& [data-ativo=true]': {
      backgroundColor: theme.palette.cores.azul[500],
      color: theme.palette.cores.branco,
    },
    '& :last-child[data-ativo=true]': {
      borderBottomRightRadius: 'inherit',
      borderTopRightRadius: 'inherit',
    },
    '& :first-child[data-ativo=true]': {
      borderTopLeftRadius: 'inherit',
      borderBottomLeftRadius: 'inherit',
    },
  },
  aba: {
    position: 'relative',
    padding: `${spacingInPixels(theme, 0.25)} ${spacingInPixels(theme, 1.5)}`,
    minWidth: '8em',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: spacingInPixels(theme, 1.75),
    lineHeight: spacingInPixels(theme, 2.75),
  },
  '@media (max-width: 1315px)': {
    aba: {
      minWidth: '6em',
      fontSize: spacingInPixels(theme, 1.5),
      lineHeight: spacingInPixels(theme, 2.25),
    },
  },
  '@media (max-width: 1000px)': {
    aba: {
      minWidth: '4em',
      fontSize: spacingInPixels(theme, 1.65),
      lineHeight: spacingInPixels(theme, 2.5),
    },
  },
}));

interface StyledBadgeProps {
  notificacoesmaiorquelimite?: boolean | string | undefined;
}

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'unset',
    },
    badge: ({ notificacoesmaiorquelimite }: StyledBadgeProps) => ({
      padding: 'unset',
      color: theme.palette.cores.branco,
      fontWeight: 400,
      fontSize: notificacoesmaiorquelimite ? '9px' : '11px',
      backgroundColor: theme.palette.cores.vermelho[400],
    }),
  }),
)(Badge);

export type OpcaoSwitchAbaType = {
  id: number;
  valor: string;
  conteudoBadge: number | null;
  idCategoriaVeiculo?: number;
};

export interface SwitchAbaProps {
  idAbaInicial: number;
  abas: OpcaoSwitchAbaType[];
  onClickOpcaoSwitch: (opcaoClicada: number) => void;
}

const SwitchAba = ({
  idAbaInicial,
  abas,
  onClickOpcaoSwitch,
}: SwitchAbaProps): JSX.Element => {
  const classes = useStyles();
  const [abaSelecionada, setAbaSelecionada] = useState(idAbaInicial);

  const handleSelecaoOpcaoConteudo = (abaClicada: number) => {
    setAbaSelecionada(abaClicada);
    onClickOpcaoSwitch(abaClicada);
  };

  return (
    <div className={classes.container}>
      {abas.map((aba) => (
        <div
          data-ativo={aba.id === abaSelecionada}
          className={classes.aba}
          onClick={() => handleSelecaoOpcaoConteudo(aba.id)}
          role="button"
          aria-hidden="true"
          key={`switch-aba-${aba.id}-${aba.valor}`}
        >
          <span>{aba.valor}</span>
          <StyledBadge
            badgeContent={aba.conteudoBadge}
            notificacoesmaiorquelimite={
              (aba.conteudoBadge || 0) >= 100 ? 'true' : 'false'
            }
          />
        </div>
      ))}
    </div>
  );
};

export default SwitchAba;
