import { createAction } from '@reduxjs/toolkit';
import { PropostasSupervisorAgrupadas } from '../api/aprovacoesSupervisorApiTypes';

export const listarAprovacoesSupervisor = createAction<PropostasSupervisorAgrupadas>(
  'aprovacoesSupervisorAction/LISTAR_APROVACOES_SUPERVISOR',
);

export const removerAprovacaoSupervisor = createAction<number>(
  'removerAprovacaoSupervisorAction/REMOVER_APROVACAO_SUPERVISOR',
);
