import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

import { RootState } from 'store/reducer';
import spacingInPixels from '../../../../../shared/functions/materialUtils';

import {
  MotoristaNegociacao,
  OrigemNegociacao,
} from '../../../api/informacoesNegociacaoApiTypes';

import TipoProposta from '../../../types/TipoProposta';
import { PropostaAEnviar } from '../../../types/PropostaAEnviar';

import { abrirModalEnviarProposta } from '../redux/motoristasCardAction';
import {
  caminhoIconeEnviarMensagem,
  caminhoIconeEnviarMensagemCinza,
  caminhoIconeWhatsappAzulGrande,
  caminhoIconeWhatsappCinzaGrande,
} from '../../../../../shared/constants/CaminhoRecursos';
import { abrirSnackbarErro } from '../../../../../shared/components/snackbar/redux/snackbarAction';

type EnviarPropostaButtonProps = {
  motorista: MotoristaNegociacao;
  negociacaoSemValores: boolean;
  motoristaTemProposta: boolean;
  origemFrete: OrigemNegociacao | undefined;
};

const Button = withStyles((theme) => ({
  root: {
    padding: spacingInPixels(theme, 0.75),
    '& .MuiSvgIcon-root': {
      height: '0.7em',
      width: '0.7em',
    },
  },
}))(IconButton);

const EnviarPropostaButton = ({
  motorista,
  motoristaTemProposta,
  negociacaoSemValores,
  origemFrete,
}: EnviarPropostaButtonProps): JSX.Element => {
  const dispatch = useDispatch();

  const { ativandoAmbienteAssistido } = useSelector(
    (state: RootState) => state.minhasNegociacoesReducer,
  );

  const desabilitado =
    negociacaoSemValores ||
    motoristaTemProposta ||
    ativandoAmbienteAssistido ||
    origemFrete === OrigemNegociacao.freteTabelado;

  const ApresentarSnackBarErro = (mensagem: string) =>
    dispatch(
      abrirSnackbarErro({
        mensagem,
      }),
    );

  const abrirModal = () => {
    if (origemFrete === OrigemNegociacao.freteTabelado) {
      ApresentarSnackBarErro('Origem frete tabelado.');
      return;
    }

    if (ativandoAmbienteAssistido) {
      ApresentarSnackBarErro('Você está em um ambiente assistido.');
      return;
    }

    if (negociacaoSemValores) {
      ApresentarSnackBarErro(
        'Não é possível enviar propostas se os valores da negociação não tiverem sido informados.',
      );
      return;
    }

    if (motoristaTemProposta) {
      ApresentarSnackBarErro(
        'Já foi iniciada uma negociação com esse motorista.',
      );
      return;
    }

    const proposta: PropostaAEnviar = {
      tipoProposta: TipoProposta.PROPOSTA_INICIAL,
      motorista,
    };

    dispatch(abrirModalEnviarProposta(proposta));
  };

  const obterCaminhoImagemIcone = (): string => {
    if (motorista.usuarioApp)
      return desabilitado
        ? caminhoIconeEnviarMensagemCinza
        : caminhoIconeEnviarMensagem;

    return desabilitado
      ? caminhoIconeWhatsappCinzaGrande
      : caminhoIconeWhatsappAzulGrande;
  };

  return (
    <Button aria-label="Enviar Proposta" onClick={abrirModal}>
      <img src={obterCaminhoImagemIcone()} alt="Icone enviar mensagem" />
    </Button>
  );
};

export default EnviarPropostaButton;
