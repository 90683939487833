import { formatarDataApi } from '../../../shared/functions/dataUtils';
import * as acompanhamentoNegociacoesService from './acompanhamentoNegociacoesService';

const montarQueryString = (queryStringArray: string[]) => {
  return queryStringArray.length > 0 ? `?${queryStringArray.join('&')}` : '';
};

export const getPlanilha = async (
  dataInicio: Date | null,
  dataFim: Date | null,
): Promise<void> => {
  const queryStringArray: string[] = [];

  if (dataInicio) {
    queryStringArray.push(`dataInicial=${formatarDataApi(dataInicio)}`);
  }

  if (dataFim) {
    queryStringArray.push(`dataFinal=${formatarDataApi(dataFim)}`);
  }

  await acompanhamentoNegociacoesService.getPlanilha(
    montarQueryString(queryStringArray),
  );
};

export default getPlanilha;
