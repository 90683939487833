import { ConexaoAPI } from '../../../shared/classes/conexaoAPI';
import {
  CargaResponse,
  MotoristasResponse,
  CriarNegociacoesRequest,
  CriarNegociacoesResponse,
} from './minhasCargasApiTypes';

const URL_BASE = process.env.REACT_APP_URL_API_BASE;

export const obterCarga = async (idCarga: number): Promise<CargaResponse> => {
  const URL = `${URL_BASE}/cargas/${idCarga}`;
  const conexao = new ConexaoAPI(URL, 'get');
  const conectar = conexao.gerarFuncaoConectarRefreshToken<CargaResponse>();
  const resposta = await conectar();
  return resposta.data;
};

export const obterMotoristas = async (
  idFilial: number,
): Promise<MotoristasResponse> => {
  const URL = `${URL_BASE}/motoristas?idFilial=${idFilial}`;
  const conexao = new ConexaoAPI(URL, 'get');
  const conectar = conexao.gerarFuncaoConectarRefreshToken<MotoristasResponse>();
  const resposta = await conectar();
  return resposta.data;
};

export const criarNegociacoes = async (
  body: CriarNegociacoesRequest,
): Promise<CriarNegociacoesResponse[]> => {
  const URL_CRIAR_NEGOCIACOES = `${URL_BASE}/negociacoes`;
  const conexao = new ConexaoAPI(URL_CRIAR_NEGOCIACOES, 'post');
  conexao.adicionarBody(body);
  const conectar = conexao.gerarFuncaoConectarRefreshToken<
    CriarNegociacoesResponse[]
  >();
  const resposta = await conectar();
  return resposta.data;
};

export const obterCargaFreteTabelado = async (
  idCarga: number,
): Promise<CargaResponse> => {
  const URL = `${URL_BASE}/cargas/detalhes-carga/${idCarga}`;
  const conexao = new ConexaoAPI(URL, 'get');
  const conectar = conexao.gerarFuncaoConectarRefreshToken<CargaResponse>();
  const resposta = await conectar();
  return resposta.data;
};
