import { Box, Divider } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CustomSkeleton from 'shared/components/skeleton/CustomSkeleton';
import {
  Carga,
  Indicador,
  Rota,
} from '../../api/detalhesAprovacaoSupervisorTypes';
import BadgesValorMedia from '../../components/badges/BadgesValorMedia';

import InformacoesRota from '../../components/InformacoesRota';
import MenuDiasCalculo from '../../components/MenuDiasCalculo';
import SubtituloDetalhesSupervisor from '../../components/SubtituloDetalhesSupervisor';
import ViagensVazias from '../../components/ViagensVazias';
import useStylesDadosMotoristaRota from '../../styles/dadosMotoristaRota';
import SkeletonMotoristaRota from './SkeletonMotoristaRota';

export type ViagensVaziasProps = {
  carga?: Carga;
  rota?: Rota;
  valorProposta: number;
  carregando: boolean;
};

const DadosRota = ({
  carga,
  rota,
  valorProposta,
  carregando,
}: ViagensVaziasProps): JSX.Element => {
  const classes = useStylesDadosMotoristaRota();

  const [indicador, setIndicador] = useState<Indicador | undefined>(
    rota?.indicadoresRota[rota?.indicadoresRota.length - 1],
  );

  useEffect(() => {
    setIndicador(rota?.indicadoresRota[rota?.indicadoresRota.length - 1]);
  }, [rota, carregando]);

  if (carregando)
    return (
      <>
        <div style={{ marginBottom: '8px' }}>
          <CustomSkeleton
            width="30%"
            height={24}
            borderradius={16}
            key="skeleton-label-dados-rota"
          />
        </div>
        <SkeletonMotoristaRota />
      </>
    );

  return rota?.indicadoresRota?.length ? (
    <>
      <Box className={classes.boxSpaceBetween}>
        <SubtituloDetalhesSupervisor>Dados da Rota</SubtituloDetalhesSupervisor>

        <MenuDiasCalculo
          indicadores={rota?.indicadoresRota || []}
          onClick={setIndicador}
        />
      </Box>
      <Box className={classes.container}>
        <InformacoesRota
          cidadePrincipal={carga?.cidadePrincipal}
          filialDestino={carga?.nomeFilialDestino}
        />
        <Divider orientation="vertical" />
        <BadgesValorMedia indicador={indicador} valorProposta={valorProposta} />
      </Box>
    </>
  ) : (
    <Box className={classes.container}>
      <InformacoesRota
        cidadePrincipal={carga?.cidadePrincipal}
        filialDestino={carga?.nomeFilialDestino}
      />
      <Divider orientation="vertical" />
      <ViagensVazias texto="Essa rota não possui viagens o suficiente para a base do cálculo" />
    </Box>
  );
};

export default DadosRota;
