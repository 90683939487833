import React from 'react';
import { useSelector } from 'react-redux';
import TypographyChat from '../TypographyChat';
import { RootState } from '../../../../store/reducer';
import TipoMensagemProposta from '../../../../shared/constants/TipoMensagemProposta';
import RespostaRecusa from './RespostaRecusa';
import { Mensagem } from '../../api/chatNegociacaoApiTypes';

export type ChatMensagemProps = {
  mensagem: Mensagem;
};

const RespostaAposAcaoNegociador = (): JSX.Element => {
  const { mensagemReferencia } = useSelector(
    (state: RootState) => state.chatNegociacaoReducer,
  );

  switch (mensagemReferencia?.tipo) {
    case TipoMensagemProposta.Aceite:
      return (
        <TypographyChat>
          <b>Sua negociação foi concluída com sucesso!</b>
        </TypographyChat>
      );
    case TipoMensagemProposta.AprovacaoConclusaoSupervisor:
      return (
        <TypographyChat>
          Aguarde, o supervisor aprovará a proposta assim que possível.
        </TypographyChat>
      );
    case TipoMensagemProposta.Recusa:
      return <RespostaRecusa />;
    default:
      return (
        <TypographyChat>
          Aguarde, o motorista entrará em contato assim que possível.
        </TypographyChat>
      );
  }
};

export default RespostaAposAcaoNegociador;
