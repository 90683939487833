import { createAction } from '@reduxjs/toolkit';
import { ColaboradoresNegociadores } from 'features/configuracoes/api/configuracoesApiTypes';
import { Negociacao } from '../api/minhasNegociacoesApiTypes';

export const atualizarFiltroStatus = createAction<string[]>(
  'minhasNegociacoesAction/ATUALIZAR_FILTRO_STATUS',
);

export const atualizarFiltroFilial = createAction<string[]>(
  'minhasNegociacoesAction/ATUALIZAR_FILTRO_FILIAL',
);

export const limparDadosMinhasNegociacoes = createAction<void>(
  'minhasNegociacoesAction/LIMPAR_DADOS_MINHAS_NEGOCIACOES',
);

export const atualizarNegociacoesUsuarioMaster = createAction<Negociacao[]>(
  'minhasNegociacoesAction/ATUALIZAR_NEGOCIACOES_USUARIO_MASTER',
);

export const ativarAmbienteAssistido = createAction<boolean>(
  'minhasNegociacoesAction/ATIVAR_AMBIENTE_ASSISTIDO',
);

export const atualizarDadosColaboradorAssistido = createAction<
  ColaboradoresNegociadores[]
>('minhasNegociacoesAction/DADOS_COLABORADOR_ASSISTIDO');

export const dispararBuscaNegociacaoColaboradores = createAction<boolean>(
  'minhasNegociacoesAction/DISPARAR_BUSCA_NEGOCIACOES_COLABORADORES',
);
