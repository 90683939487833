import React, { ReactNode, useState } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import spacingInPixels from 'shared/functions/materialUtils';
import { ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import { OpcaoSelectCustomizado } from '../types/PainelIndicadoresTypes';

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '10em',
    },
    input: {
      '& div.MuiListItemText-root': {
        margin: 0,
      },

      '& div.MuiListItemText-root > span': {
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: spacingInPixels(theme, 1.75),
        lineHeight: spacingInPixels(theme, 1.75),
        color: theme.palette.cores.cinza[700],
        display: 'inline-block',
        verticalAlign: 'baseline',
      },

      '& div.MuiListItemIcon-root': {
        height: spacingInPixels(theme, 2),
      },

      '& div.MuiListItemIcon-root > svg': {
        verticalAlign: 'baseline',
      },

      borderRadius: 4,
      position: 'relative',
      backgroundColor: `${theme.palette.cores.cinza[700]}1A`,
      border: `1px solid ${theme.palette.cores.cinza[400]}`,
      padding: '8px 0px 9px 10px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:focus': {
        borderRadius: spacingInPixels(theme, 0.5),
        backgroundColor: `${theme.palette.cores.cinza[700]}1A`,
      },
      '&:hover': {
        backgroundColor: `${theme.palette.cores.cinza[700]}33`,
        border: `1px solid ${theme.palette.cores.cinza[400]}`,
      },
    },
  }),
)(InputBase);

const CustomMenuItem = withStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: `0 ${spacingInPixels(theme, 1)}`,
      padding: spacingInPixels(theme, 0.5),

      borderBottom: `1px solid ${theme.palette.cores.cinza[300]}`,
      '&[data-last-child=true]': {
        borderBottom: 'none',
      },

      '& div.MuiListItemText-root > span': {
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: spacingInPixels(theme, 1.5),
        lineHeight: spacingInPixels(theme, 1.75),
        color: theme.palette.cores.cinza[900],
      },

      '& div.MuiListItemIcon-root': {
        display: 'none',
      },
    },
  }),
)(MenuItem);

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '20% 80%',
    gap: spacingInPixels(theme, 1),
    alignItems: 'center',
  },
  formulario: {
    '& div.MuiSelect-select.Mui-disabled': {
      '&:hover': {
        backgroundColor: `${theme.palette.cores.cinza[700]}1A`,
      },
    },

    '& div.MuiInputBase-input.Mui-disabled': {
      opacity: 0.7,
    },
  },
}));

export interface SelectCustomizadoProps {
  valorInicial: string;
  opcoes: OpcaoSelectCustomizado[];
  icone: ReactNode;
  onChangeEvent: (opcao: string) => void;
  estiloContainer?: string;
  desabilitado?: boolean;
}

const SelectCustomizado = ({
  valorInicial,
  opcoes,
  icone,
  onChangeEvent,
  estiloContainer,
  desabilitado,
}: SelectCustomizadoProps): JSX.Element => {
  const classes = useStyles();
  const [valor, setValor] = useState(valorInicial);
  const handleChange = (valorSelecionado: string) => {
    setValor(valorSelecionado);
    onChangeEvent(valorSelecionado);
  };

  return (
    <FormControl className={classes.formulario}>
      <Select
        id="select-customizado"
        value={valor}
        disabled={desabilitado}
        onChange={(event) => handleChange(event.target.value as string)}
        input={<BootstrapInput />}
      >
        {opcoes.map((opcao, index) => (
          <CustomMenuItem
            value={opcao.valor}
            data-last-child={index === opcoes?.length - 1}
            key={opcao.valor}
            disabled={opcao.desabilitado}
          >
            <div className={`${classes.container} ${estiloContainer}`}>
              <ListItemIcon>{icone}</ListItemIcon>
              <ListItemText primary={opcao.descricao} />
            </div>
          </CustomMenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectCustomizado;
