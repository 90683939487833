import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { useState, useEffect, useCallback } from 'react';
import { ClassNameMap } from '@material-ui/styles/withStyles';
import BoxScroll from 'shared/components/box/BoxScroll';
import IndicadoresMotoristaRota from 'features/detalhesAprovacaoSupervisor/views/indicadoresMotoristaRota/IndicadoresMotoristaRota';
import TipoMensagemProposta from '../../constants/TipoMensagemProposta';
import spacingInPixels from '../../functions/materialUtils';
import { EventoProposta } from '../../../features/detalhesAprovacaoSupervisor/api/detalhesAprovacaoSupervisorTypes';
import BadgeValoresRelacionados from '../../../features/detalhesAprovacaoSupervisor/components/badges/BadgeValoresRelacionados';
import TimelineEventos from '../../../features/detalhesAprovacaoSupervisor/components/timeline/TimelineEventos';
import { obterQuantidadeEventos } from '../../../features/detalhesAprovacaoSupervisor/utils/timelineEventos';
import SkeletonCabecalhoLinhaDoTempo from './SkeletonCabecalhoLinhaDoTempo';
import SkeletonLinhaDoTempo from './SkeletonLinhaDoTempo';

export type LinhaDoTempoProps = {
  historico?: EventoProposta[];
  valorFrotaPropria: number;
  estilo?: ClassNameMap<'containerEstilo' | 'texto'>;
  carregando?: boolean;
};

interface StyleCustomProps {
  maxheight: string;
}

const useStyles = makeStyles<Theme, StyleCustomProps>((theme) => ({
  container: {
    backgroundColor: theme.palette.cores.branco,
    borderRadius: spacingInPixels(theme, 1),
    boxShadow: '0px 1px 2px 0px rgb(0 0 0 / 20%)',
    borderBottom: `1px solid ${theme.palette.cores.cinza[600]}`,
    maxHeight: ({ maxheight }) => maxheight,
  },

  containerBadges: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: spacingInPixels(theme, 1),
    paddingInline: spacingInPixels(theme, 3),
  },
  texto: {
    fontSize: '16px',
    fontWeight: 400,
    color: theme.palette.cores.cinza[900],
    padding: spacingInPixels(theme, 2),
    marginLeft: 12,
  },
  ContainerBox: {
    maxHeight: 'calc(100% - 90px)',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.cores.azul[600],
      borderRadius: '8px',
    },
  },
}));

const LinhaDoTempo = ({
  historico = [],
  valorFrotaPropria,
  estilo,
  carregando,
}: LinhaDoTempoProps): JSX.Element => {
  const [alturaMaximaContainer, setAlturaMaximaContainer] = useState('unset');
  const classes = useStyles({ maxheight: alturaMaximaContainer });

  const formatarTextoContrapropostas = (): string => {
    const quantidade = obterQuantidadeEventos(
      historico,
      TipoMensagemProposta.Contraproposta,
    );

    return `${quantidade} contraproposta${quantidade > 1 ? 's' : ''}`;
  };

  const formatarTextoInteracoes = (): string => {
    const quantidade = historico?.length;

    return `${quantidade} intera${quantidade > 1 ? 'ções' : 'ção'}`;
  };

  const formatarTextoAvaliacaoSupervisor = (): string => {
    const quantidade = obterQuantidadeEventos(
      historico,
      TipoMensagemProposta.AvaliacaoSupervisor,
    );

    return `${quantidade}x
    enviado para aprovação`;
  };

  const obterAlturaMaxima = useCallback(() => {
    const containerJustificativa = window.document.getElementsByClassName(
      IndicadoresMotoristaRota.name,
    );
    const maxHeight = containerJustificativa
      ? `${containerJustificativa[0]?.getBoundingClientRect().height}px`
      : 'unset';
    setAlturaMaximaContainer(maxHeight);
  }, []);

  useEffect(() => {
    if (!carregando) {
      obterAlturaMaxima();
    }
  }, [carregando, obterAlturaMaxima]);

  return (
    <Box className={estilo?.containerEstilo || classes.container}>
      {carregando ? (
        <>
          <SkeletonCabecalhoLinhaDoTempo />
          <SkeletonLinhaDoTempo />
        </>
      ) : (
        <>
          <Typography className={estilo?.texto || classes.texto}>
            Linha do tempo da negociação
          </Typography>

          <Box className={classes.containerBadges}>
            <BadgeValoresRelacionados>
              {formatarTextoContrapropostas()}
            </BadgeValoresRelacionados>
            <BadgeValoresRelacionados>
              {formatarTextoInteracoes()}
            </BadgeValoresRelacionados>
            <BadgeValoresRelacionados>
              {formatarTextoAvaliacaoSupervisor()}
            </BadgeValoresRelacionados>
          </Box>
          <BoxScroll className={classes.ContainerBox}>
            <TimelineEventos
              historico={historico}
              valorFrotaPropria={valorFrotaPropria}
            />
          </BoxScroll>
        </>
      )}
    </Box>
  );
};

export default LinhaDoTempo;
