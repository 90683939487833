import React from 'react';
import { makeStyles } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';
import CustomSkeleton from 'shared/components/skeleton/CustomSkeleton';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacingInPixels(theme, 3),
  },
  tabela: {
    display: 'flex',
    gap: spacingInPixels(theme, 2.5),
  },
}));

type SkeletonContainerGenericoProps = {
  apresentarPrimeiraBarra: boolean;
  apresentarFooterBarras: boolean;
};

const SkeletonContainerGenerico = ({
  apresentarPrimeiraBarra = false,
  apresentarFooterBarras = false,
}: SkeletonContainerGenericoProps): JSX.Element => {
  const classes = useStyles();

  return (
    <section className={classes.container}>
      {apresentarPrimeiraBarra && (
        <CustomSkeleton borderradius={16} width="40%" height={34} />
      )}

      <CustomSkeleton borderradius={16} width="100%" height={264} />
      {apresentarFooterBarras && (
        <div className={classes.tabela}>
          {new Array(5).fill(null).map((_, index) => (
            <CustomSkeleton
              key={`skeleton-tabela-${index + 1}`}
              borderradius={16}
              width="20%"
              height={75}
            />
          ))}
        </div>
      )}
    </section>
  );
};

export default SkeletonContainerGenerico;
