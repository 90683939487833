export enum StatusDemandaDiariaDashboard {
  DEMANDAS_DO_DIA = 'demandas-do-dia',
  PENDENTES = 'pendentes',
  EM_NEGOCIACAO = 'em-negociacao',
  RECUSADAS = 'recusadas',
  NAO_INICIADAS = 'nao-iniciadas',
  EM_APROVACAO = 'em-aprovacao',
  NEGOCIADAS = 'negociadas',
}

export enum ChaveOrdenacaoDetalhesDemandas {
  FILIAL = 'FILIAL',
  NUMERO_ENTREGAS = 'NUMERO_ENTREGAS',
  STATUS = 'STATUS',
  NEGOCIADOR = 'NEGOCIADOR',
  DATA_HORA = 'DATA_HORA',
}

const MENSAGEM_ERRO_LISTAGEM_DEMANDAS =
  'Ops algo aconteceu, por algum motivo não conseguimos listar as demandas.';
const MENSAGEM_LISTAGEM_DEMANDAS_VAZIA =
  'Ops, não há demandas para esse tipo de status!';
const MENSAGEM_ERRO_TARIFAR_DEMANDA =
  'Não foi possível inserir tarifário para a negociação!';
const MENSAGEM_SUCESSO_TARIFAR_DEMANDA =
  'Tarifário inserido com sucesso para a negociação.';

export {
  MENSAGEM_ERRO_LISTAGEM_DEMANDAS,
  MENSAGEM_LISTAGEM_DEMANDAS_VAZIA,
  MENSAGEM_ERRO_TARIFAR_DEMANDA,
  MENSAGEM_SUCESSO_TARIFAR_DEMANDA,
};
