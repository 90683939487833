import { PrioridadeCargaNegociacao } from 'features/dashboards/negociadores/api/listagemNegociadoresApiTypes';
import { IntervaloTempo } from 'shared/types/apiTypes';

export enum StatusDemandaEnum {
  Cancelada,
  Concluida,
  EmNegociacao,
  EmAprovacao,
  NaoIniciada,
}

export type DetalhesDemandaDiaria = {
  idNegociacao?: number;
  nomeNegociador: string;
  nomeFilial: string;
  dataCarregamento: string;
  romaneio: string;
  prioridadeCarga: PrioridadeCargaNegociacao;
  quantidadeEntregas: number;
  valorUltimaContraproposta?: number;
  savingUltimaContraproposta?: number;
  valorMeta?: number;
  savingValorMeta?: number;
  cycleTime?: IntervaloTempo;
  leadTime?: IntervaloTempo;
  quantidadePedidosAprovacao?: number;
  nomeCidade: string;
  status: StatusDemandaEnum;
  motivoRecusa?: number;
  valorConclusao?: number;
  tarifarioCadastrado?: boolean;
  savingValorConclusao?: number;
  dataCriacao?: Date;
  dataConclusao?: Date;
};

export type DetalhesTotalDemandasDoDia = {
  idNegociacao: number;
  nomeNegociador: string;
  nomeFilial: string;
  dataCarregamento: string;
  romaneio: string;
  prioridadeCarga: PrioridadeCargaNegociacao;
  quantidadeEntregas: number;
  valorUltimaContraproposta: number;
  savingUltimaContraproposta: number;
  valorMeta: number;
  savingValorMeta: number;
  cycleTime: IntervaloTempo;
  leadTime: IntervaloTempo;
  quantidadePedidosAprovacao: number;
  nomeCidade: string;
  status: StatusDemandaEnum;
};

export type DetalhesDemandaAndamento = {
  idNegociacao: number;
  nomeNegociador: string;
  nomeFilial: string;
  sla: string;
  romaneio: string;
  prioridadeCarga: PrioridadeCargaNegociacao;
  quantidadeEntregas: number;
  valorMenorContraproposta?: number;
  savingValorMenorContraproposta?: number;
  valorMeta: number;
  savingValorMeta: number;
  cycleTime?: IntervaloTempo;
  quantidadePedidosAprovacao: number;
  nomeCidade: string;
};

export type DetalhesDemandaNaoIniciada = {
  nomeNegociador: string;
  nomeFilial: string;
  sla: string;
  romaneio: string;
  prioridadeCarga: PrioridadeCargaNegociacao;
  quantidadeEntregas: number;
  leadTime?: IntervaloTempo;
  nomeCidade: string;
};

export type DetalhesNegociacaoRecusada = {
  idNegociacao: number;
  nomeNegociador: string;
  nomeFilial: string;
  romaneio: string;
  nomeCidade: string;
  motivoRecusa: number;
};

export type DetalhesDemandaConcluida = {
  idNegociacao: number;
  nomeFilial: string;
  nomeNegociador: string;
  romaneio: string;
  valorConclusao: number;
  nomeCidade: string;
  tarifarioCadastrado: boolean;
  savingValorConclusao: number;
  dataCriacao: string;
  dataConclusao: string;
};

export type DetalhesDemandaEmAprovacao = {
  idNegociacao: number;
  nomeNegociador: string;
  nomeFilial: string;
  sla: string;
  romaneio: string;
  prioridadeCarga: PrioridadeCargaNegociacao;
  quantidadeEntregas: number;
  valorMenorContraproposta: number;
  savingValorMenorContraproposta: number;
  valorMeta: number;
  savingValorMeta: number;
  cycleTime: IntervaloTempo;
  quantidadePedidosAprovacao: number;
  nomeCidade: string;
  leadTime: IntervaloTempo;
};

export type DetalhesTotalDemandasDoDiaResponse = {
  demandasDiaria: DetalhesTotalDemandasDoDia[];
};

export type DetalhesDemandasAndamentoResponse = {
  negociacoes: DetalhesDemandaAndamento[];
};

export type DetalhesDemandasNaoIniciadasResponse = {
  negociacoes: DetalhesDemandaAndamento[];
};

export type DetalhesNegociacoesRecusadasResponse = {
  negociacoes: DetalhesNegociacaoRecusada[];
};

export type DetalhesDemandasConcluidasResponse = {
  negociacoes: DetalhesDemandaConcluida[];
  totalPaginas: number;
};

export type DetalhesDemandasEmAprovacaoResponse = {
  negociacoes: DetalhesDemandaEmAprovacao[];
};
