import { makeStyles } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';

const gridTabelaDemandasAndamentoStyle = makeStyles((theme) => ({
  gridContainer: {
    display: 'grid',
    gridTemplateColumns:
      '200px 200px 150px 180px 110px 130px 110px 150px 110px 90px 80px 150px 130px 80px 110px 100px 80px 90px 110px 80px 200px',
    width: 'fit-content',
    maxWidth: '2600px',
    minHeight: spacingInPixels(theme, 7),
    maxHeight: `calc(100vh - 190px - ${spacingInPixels(theme, 7)})`,
    padding: `0px 0px 0px 8px`,

    overflowX: 'hidden',
    overflowY: 'scroll',

    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.cores.azul[600],
      borderRadius: '8px',
    },
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
    padding: `${spacingInPixels(theme, 1.5)} 0`,
    gap: spacingInPixels(theme, 0.5),
    overflow: 'hidden',
    borderBottom: `1px solid ${theme.palette.cores.cinza[400]}`,
  },
}));

export default gridTabelaDemandasAndamentoStyle;
