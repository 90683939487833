import * as detalhesAprovacaoSupervisorService from './detalhesAprovacaoSupervisorService';
import { Mensagem } from '../../chatNegociacao/api/chatNegociacaoApiTypes';
import { DetalhesAprovacaoSupervisorResponse } from './detalhesAprovacaoSupervisorTypes';

export const aprovarPropostaSupervisor = async (
  idNegociacao: number,
  idProposta: number,
): Promise<Mensagem> => {
  return detalhesAprovacaoSupervisorService.aprovarPropostaSupervisor(
    idNegociacao,
    idProposta,
  );
};

export const reprovarPropostaSupervisor = async (
  idNegociacao: number,
  idProposta: number,
  body: { justificativa: string },
): Promise<Mensagem> => {
  return detalhesAprovacaoSupervisorService.reprovarPropostaSupervisor(
    idNegociacao,
    idProposta,
    body,
  );
};

export const proporNovaMetaPropostaSupervisor = async (
  idNegociacao: number,
  idProposta: number,
  body: { valorNovaMeta: number; justificativa: string },
): Promise<Mensagem> => {
  return detalhesAprovacaoSupervisorService.proporNovaMetaPropostaSupervisor(
    idNegociacao,
    idProposta,
    body,
  );
};

export const obterDetalhesPropostaSupervisor = async (
  idNegociacao: number,
  idProposta: number,
): Promise<DetalhesAprovacaoSupervisorResponse> => {
  return detalhesAprovacaoSupervisorService.obterDetalhesPropostaSupervisor(
    idNegociacao,
    idProposta,
  );
};
