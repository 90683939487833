import { useTheme } from '@material-ui/core/styles';
import * as React from 'react';
import { SVGProps } from 'react';

const IconeFindInPage = ({
  fill,
  ...outros
}: SVGProps<SVGSVGElement>): JSX.Element => {
  const theme = useTheme();
  return (
    <svg width={80} height={80} fill="none" {...outros}>
      <path
        d="M40 49.917c1.833 0 3.375-.625 4.625-1.875S46.5 45.25 46.5 43.417c0-1.834-.625-3.375-1.875-4.625S41.833 36.917 40 36.917c-1.833 0-3.375.625-4.625 1.875S33.5 41.583 33.5 43.417c0 1.833.625 3.375 1.875 4.625s2.792 1.875 4.625 1.875Zm-20.917 25.5c-2.166 0-4.014-.764-5.541-2.292-1.528-1.528-2.292-3.375-2.292-5.542V12.417c0-2.167.764-4.028 2.292-5.584C15.069 5.278 16.917 4.5 19.083 4.5h27.5c1.167 0 2.278.236 3.334.708a7.148 7.148 0 0 1 2.666 2.042l14.25 16.25a7.452 7.452 0 0 1 1.5 2.375c.334.861.5 1.764.5 2.708v39.584l-17.583-17.5c.667-.89 1.194-1.917 1.583-3.084.39-1.166.584-2.555.584-4.166 0-3.778-1.292-6.959-3.875-9.542C46.958 31.292 43.778 30 40 30c-3.778 0-6.944 1.292-9.5 3.875s-3.833 5.764-3.833 9.542c0 3.833 1.277 7.014 3.833 9.541 2.556 2.528 5.722 3.792 9.5 3.792 1.556 0 2.889-.125 4-.375 1.111-.25 2.222-.736 3.333-1.458l18.084 18.166c-1.223.834-2.625 1.43-4.209 1.792-1.583.361-2.986.542-4.208.542H19.083Z"
        fill={fill || theme.palette.cores.cinza[500]}
      />
    </svg>
  );
};

export default IconeFindInPage;
