import React from 'react';
import {
  Badge,
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from '@material-ui/core';
import { descricaoFiltroNotificacao } from 'features/dashboards/constants/Constants';
import spacingInPixels from 'shared/functions/materialUtils';
import {
  SkeletonFiltro,
  StyledChip,
} from 'shared/components/filters/chip/ComponentesFiltroChip';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    gap: spacingInPixels(theme, 1.5),
    height: spacingInPixels(theme, 3),
    '& [data-ativo=true] div': {
      color: theme.palette.cores.branco,
      backgroundColor: theme.palette.cores.azul[600],
    },
  },
}));

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      padding: 'unset',
      color: theme.palette.cores.branco,
      fontWeight: 400,
      fontSize: '11px',
      backgroundColor: theme.palette.cores.vermelho[400],
    },
  }),
)(Badge);

export type FiltroNotificacao = {
  label: descricaoFiltroNotificacao;
  quantidade: number | null;
};

export interface FiltrosNotificacoesProps {
  carregando: boolean;
  filtroAtual: string;
  filtros: FiltroNotificacao[];
  onClickFiltro: (filtroSelecionado: descricaoFiltroNotificacao) => void;
}

const FiltrosNotificacoes = ({
  carregando,
  filtroAtual,
  filtros,
  onClickFiltro,
}: FiltrosNotificacoesProps): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {carregando ? (
        <SkeletonFiltro quantidade={4} />
      ) : (
        filtros?.map((filtro) => (
          <div
            data-ativo={filtro.label === filtroAtual}
            key={`filtro-notificacao-${filtro.label}`}
          >
            <StyledBadge badgeContent={filtro.quantidade}>
              <StyledChip
                label={filtro.label}
                onClick={() => onClickFiltro(filtro.label)}
              />
            </StyledBadge>
          </div>
        ))
      )}
    </div>
  );
};

export default FiltrosNotificacoes;
