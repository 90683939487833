import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';
import {
  SkeletonFiltro,
  StyledChip,
} from 'shared/components/filters/chip/ComponentesFiltroChip';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    gap: spacingInPixels(theme, 1),
    overflowX: 'auto',
    paddingBottom: spacingInPixels(theme, 0.5),
    height: 'inherit',
    '& [data-ativo=true] div': {
      color: theme.palette.cores.branco,
      backgroundColor: theme.palette.cores.azul[600],
    },
    '&::-webkit-scrollbar': {
      height: spacingInPixels(theme, 0.75),
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      background: `${theme.palette.cores.cinza[500]}60`,
      borderRadius: spacingInPixels(theme, 1),
    },
  },
}));

interface FiltrosDemandasProps {
  carregando: boolean;
  filtroAtual: string;
  filtros: string[];
  onClickFiltro: (filtroSelecionado: string) => void;
}

const FiltrosDemandas = ({
  carregando,
  filtroAtual,
  filtros,
  onClickFiltro,
}: FiltrosDemandasProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {carregando ? (
        <SkeletonFiltro quantidade={6} />
      ) : (
        filtros?.map((filtro) => (
          <div
            data-ativo={filtro === filtroAtual}
            key={`filtro-demanda-${filtro}`}
          >
            <StyledChip
              label={`${filtro}`}
              onClick={() => onClickFiltro(filtro)}
            />
          </div>
        ))
      )}
    </div>
  );
};

export default FiltrosDemandas;
