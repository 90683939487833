import { Box, withStyles } from '@material-ui/core';

export const BoxEsquerda = withStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    alignItems: 'flex-start',
    gap: '4px',
  },
}))(Box);

export const BoxDireita = withStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    alignItems: 'flex-start',
    gap: '4px',
  },
}))(Box);
