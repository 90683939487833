import { AxiosResponse } from 'axios';

export const baixarArquivoRespostaEndpoint = (
  resposta: AxiosResponse,
  nomeArquivoPadrao: string,
): void => {
  const type = resposta.headers['content-type'];
  const blob = new Blob([resposta.data], { type });
  const link = document.createElement('a');
  const contentDisposition = resposta.headers['content-disposition'];

  let nomeArquivo = nomeArquivoPadrao;
  if (contentDisposition) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);
    if (matches != null && matches[1]) {
      nomeArquivo = matches[1].replace(/['"]/g, '');
    }
  }
  link.href = window.URL.createObjectURL(blob);
  link.download = nomeArquivo;
  link.click();
};
