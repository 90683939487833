import React, { useEffect, useState } from 'react';
import { Box, Button, TextField } from '@material-ui/core';
import {
  DataGrid,
  GridColDef,
  GridEditCellProps,
  ptBR,
} from '@material-ui/data-grid';
import { useStyles } from 'features/configuracoes/styles/freteTabeladoRegioes';
import { useDispatch } from 'react-redux';
import {
  buscarFreteTabeladoMacros,
  atualizarMacroFiliais,
} from 'features/configuracoes/utils/configuracoes';
import { FreteTabeladoRegiao } from '../api/configuracoesApiTypes';

const TabelaMacro = (): JSX.Element => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [rows, setRows] = useState<FreteTabeladoRegiao[]>([]);
  const [searchText, setSearchText] = useState('');
  const [dadosMacros, setDadosMacros] = useState<FreteTabeladoRegiao[]>([]);
  const [linhasModificadas, setLinhasModificadas] = useState<number[]>([]);

  useEffect(() => {
    buscarFreteTabeladoMacros(setDadosMacros, dispatch);
  }, [dispatch]);

  const buscarDados = () => {
    buscarFreteTabeladoMacros(setDadosMacros, dispatch);
  };

  useEffect(() => {
    setRows(dadosMacros.map((row) => ({ ...row, id: row.idMacroFilial })));
    // setOriginalRows([...dadosMacros]); // Salva uma cópia dos dados originais
  }, [dadosMacros]);

  const handleCellEditCommit = (params: GridEditCellProps) => {
    const { id, field, value } = params;
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === id ? { ...row, [field]: value } : row)),
    );

    // Adiciona o ID da linha modificada ao estado de linhas modificadas
    if (!linhasModificadas.includes(id)) {
      setLinhasModificadas([...linhasModificadas, id]);
    }
  };

  const handleSalvarClick = () => {
    // Filtra apenas as linhas que foram modificadas
    const linhasParaSalvar = rows.filter((row) =>
      linhasModificadas.includes(row.id as number),
    );

    // Agora você pode enviar as linhas para salvar para o backend

    linhasParaSalvar.forEach(async (linha) => {
      try {
        await atualizarMacroFiliais(
          {
            macroId: linha.idMacroFilial,
            descricao: linha.descricao,
            filialId: linha.idFilial,
          },
          dispatch,
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Erro ao cadastrar linha:', error);
      }
    });
  };

  const [columns] = useState<GridColDef[]>([
    {
      field: 'idMacroFilial',
      headerName: 'Id Macro',
      width: 100,
      sortable: false,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      editable: false,
      type: 'number',
      disableColumnMenu: true,
    },
    {
      field: 'descricao',
      headerName: 'Descrição',
      width: 250,
      sortable: false,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      editable: true,
      disableColumnMenu: true,
    },
    {
      field: 'descricaoFilial',
      headerName: 'Nome Filial',
      width: 250,
      sortable: false,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      editable: false,
      disableColumnMenu: true,
    },
  ]);

  const handleSearchTextChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchText(event.target.value);
  };

  const filteredRows = rows.filter((row) =>
    row.descricao.toLowerCase().includes(searchText.toLowerCase()),
  );

  const CustomFooter = () => (
    <>
      <Box className={classes.customFooterContainer}>
        <Box
          className="MuiDataGrid-selectedRowCount"
          style={{ display: 'none' }}
        />
      </Box>
      <Box className={classes.customButtons}>
        <Button
          color="primary"
          variant="text"
          onClick={buscarDados}
          style={{ paddingRight: '25px' }}
        >
          LIMPAR
        </Button>
        <Button color="primary" variant="contained" onClick={handleSalvarClick}>
          ATUALIZAR
        </Button>
      </Box>
    </>
  );

  return (
    <Box style={{ height: '50vh' }}>
      <TextField
        variant="outlined"
        label="Procurar"
        value={searchText}
        onChange={handleSearchTextChange}
        size="small"
        style={{ margin: '20px 0', width: '100%' }}
      />
      <DataGrid
        rows={filteredRows}
        columns={columns}
        onCellEditCommit={handleCellEditCommit}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        localeText={ptBR.props.MuiDataGrid.localeText}
        disableSelectionOnClick
      />
      <CustomFooter />
    </Box>
  );
};

export default TabelaMacro;
