import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

type TypographyContadorCaracteresProps = {
  limiteCaracteres: number;
  valorTexto: string | undefined;
};

const useStyles = makeStyles((theme) => ({
  texto: {
    fontSize: '12px',
    fontWeight: 400,
  },
  textoValido: { color: theme.palette.cores.cinza[700] },
  textoInvalido: { color: theme.palette.cores.vermelho[600] },
}));

const handleCaracteresRestantes = (
  limiteCaracteres: number,
  tamanhoTexto = 0,
) => limiteCaracteres - tamanhoTexto;

const TypographyContadorCaracteres = ({
  limiteCaracteres,
  valorTexto,
}: TypographyContadorCaracteresProps): JSX.Element => {
  const classes = useStyles();
  const estiloValidezTexto =
    handleCaracteresRestantes(limiteCaracteres, valorTexto?.length) > 0
      ? classes.textoValido
      : classes.textoInvalido;
  return (
    <Typography
      align="right"
      className={`${classes.texto} ${estiloValidezTexto}`}
    >
      Caracteres restantes:
      {handleCaracteresRestantes(limiteCaracteres, valorTexto?.length)}
    </Typography>
  );
};

export default TypographyContadorCaracteres;
