import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/MenuRounded';

import spacingInPixels from '../../functions/materialUtils';

type HamburgerProps = {
  toggleMenu: (event: React.KeyboardEvent | React.MouseEvent) => void;
};

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.cores.cinza[700],
  },
  root: {
    '&.MuiButton-root': {
      minWidth: spacingInPixels(theme, 2.5),
      marginRight: spacingInPixels(theme, 0.5),
      borderRadius: spacingInPixels(theme, 5),
      '&:hover': {
        background: theme.palette.cores.cinza[200],
      },
    },
  },
}));

const Hamburger = ({ toggleMenu }: HamburgerProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Button
      id="hamburger"
      className={classes.root}
      onClick={toggleMenu}
      disableRipple
    >
      <MenuIcon className={classes.icon} />
    </Button>
  );
};

export default Hamburger;
