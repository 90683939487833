import UtilConstants from '../constants/utilConstants';

export type StringOpcional = string | null | undefined;

export const possuiEspaco = (texto: string): boolean => {
  const valor = texto;
  return valor.trim().includes(UtilConstants.ESPACO);
};

export const possuiCaracteresEspeciais = (texto: string): boolean => {
  const valor = texto;
  return /[^A-Za-z0-9]/.test(valor);
};

export const estaVazio = (texto: string): boolean => {
  return texto === UtilConstants.VAZIO;
};

export const estaEmBranco = (entrada: StringOpcional): boolean =>
  entrada === null || entrada === undefined || entrada.trim() === '';

export const removerEspacosECaracteresEspeciais = (
  texto: StringOpcional,
): string => texto?.replace(/[^A-Z0-9]+/gi, '') || '';

export const temApenasNumeros = (value: StringOpcional): boolean => {
  return !estaEmBranco(value) && !Number.isNaN(Number(value));
};

export const obterApenasNumeros = (valor: string): string =>
  valor.replace(/\D/g, '');

export const removerAcentuacao = (valor: string): string =>
  valor.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
