import React, { useMemo, useState } from 'react';
import { DetalhesDemandaDiaria } from 'features/detalhesDemandasDiaria/api/detalhesDemandasDiariaApiTypes';
import { ordenarDemandas } from 'features/detalhesDemandasDiaria/utils/detalhesDemandas';
import { ChaveOrdenacaoDetalhesDemandas } from 'features/detalhesDemandasDiaria/constants/Constants';

import PaginaVaziaIndicadores from 'shared/components/paginaVaziaIndicadores/PaginaVaziaIndicadores';
import { TabelaProps } from 'features/detalhesDemandasDiaria/types/tabelaTypes';
import ConteudoTabelaDemandasDoDia from './ConteudoTabelaDemandasDoDia';
import CabecalhoTabelaDetalhesDemandas from '../../components/tabelaDetalhesDemandas/CabecalhoTabelaDetalhesDemandas';
import { estilosTabelaDemandasDoDia } from '../../styles/styleTabela';
import SkeletonTabelaDetalhesDemandas from '../../components/tabelaDetalhesDemandas/SkeletonTabelaDetalhesDemandas';
import { useStyles } from '../../styles/styleParaTabelas.ts';
import {
  dadosSkeleton,
  dadosItensCabecalho,
} from '../../constants/TabelaDemandasDoDia';

const TabelaDemandasDoDia = ({
  demandas,
  carregando,
  erro,
}: TabelaProps): JSX.Element => {
  const classes = useStyles();
  const { gridTabela } = estilosTabelaDemandasDoDia();

  const [demandasOrdenadas, setDemandasOrdenadas] = useState<
    DetalhesDemandaDiaria[]
  >([]);

  useMemo(() => {
    setDemandasOrdenadas([...demandas]);
  }, [demandas]);

  const handleClickOrdenacao = (chave: string, ordenacaoCrescente: boolean) => {
    setDemandasOrdenadas(
      ordenarDemandas(
        demandasOrdenadas,
        chave as ChaveOrdenacaoDetalhesDemandas,
        ordenacaoCrescente,
      ),
    );
  };

  if (demandasOrdenadas.length === 0 && !carregando)
    return <PaginaVaziaIndicadores erro={erro} />;

  return (
    <table className={classes.container}>
      <thead>
        <CabecalhoTabelaDetalhesDemandas
          onClickOrdenacao={handleClickOrdenacao}
          estiloCabecalho={gridTabela}
          dadosItensCabecalho={dadosItensCabecalho}
        />
      </thead>
      {carregando ? (
        <SkeletonTabelaDetalhesDemandas
          dadosSkeleton={dadosSkeleton}
          estiloLinha={gridTabela}
        />
      ) : (
        <ConteudoTabelaDemandasDoDia demandas={demandasOrdenadas} />
      )}
    </table>
  );
};

export default TabelaDemandasDoDia;
