import { isValid } from 'date-fns';

export const definirDataInterna = (data: Date | null): Date | null => {
  if (!isValid(data)) {
    return null;
  }
  return data;
};

export const selecionarData = (
  data: Date | null,
  setDataInterna: (d: Date | null) => void,
  setDataLimite: (d: Date | null) => void,
  onDataChange: (d: Date | null) => void,
): void => {
  setDataInterna(data);
  if (data === null || isValid(data)) {
    onDataChange(data);
    setDataLimite(data);
  } else {
    setDataLimite(null);
  }
};

export const limparDatasSelecionadas = (parametros: {
  dataInicio: Date | null;
  dataFim: Date | null;
  onDataInicioChange: (d: Date | null) => void;
  onDataFimChange: (d: Date | null) => void;
  setDataFimMinima: (d: Date | null) => void;
  setDataInicioMaxima: (d: Date | null) => void;
  setDataInicioInterna: (d: Date | null) => void;
  setDataFimInterna: (d: Date | null) => void;
}): void => {
  if (parametros.dataInicio !== null || parametros.dataFim !== null) {
    parametros.onDataInicioChange(null);
    parametros.onDataFimChange(null);
    parametros.setDataFimMinima(null);
    parametros.setDataInicioMaxima(null);
    parametros.setDataInicioInterna(null);
    parametros.setDataFimInterna(null);
  }
};
