import React from 'react';
import { useSelector } from 'react-redux';

import { InfoOutlined } from '@material-ui/icons';

import BadgesValor from '../../../../shared/components/badgesValor/BadgesValor';
import TipoValorContraproposta from '../../constants/TipoValorContraproposta';
import { RootState } from '../../../../store/reducer';
import ChatBalaoInformativo from '../../components/ChatBalaoInformativo';

type ChatBalaoValoresProps = {
  valor?: number;
};

const ChatBalaoValores = ({ valor }: ChatBalaoValoresProps): JSX.Element => {
  const { negociacao } = useSelector(
    (state: RootState) => state.informacoesNegociacaoReducer,
  );

  return (
    <ChatBalaoInformativo icone={<InfoOutlined color="primary" />}>
      <BadgesValor
        valor={valor}
        tipoValor={TipoValorContraproposta.ValorProposto}
        valorFreteTerceiro={negociacao?.valorFreteTerceiro || 0}
        valorFrotaPropria={
          negociacao?.sugestaoValorInicialRobo
            ? negociacao?.sugestaoValorInicialRobo
            : negociacao?.valorFrotaPropria || 0
        }
        valorEntregas={negociacao?.carga.entregas || 0}
        valorMercadoria={negociacao?.carga.valor || 0}
        valorPeso={negociacao?.carga.peso || 0}
        valorQuilometragem={negociacao?.carga.quilometragem || 0}
        justificar="flex-start"
        condensado
      />
    </ChatBalaoInformativo>
  );
};

ChatBalaoValores.defaultProps = {
  valor: 0,
};

export default ChatBalaoValores;
