import { Dispatch } from 'redux';
import { buscarListaAprovacoes } from '../redux/aprovacoesSupervisorOperation';

export const MilissegundosAtualizacaoPropostas = 10000;

export const buscarPropostasSupervisor = (
  setCarregando: (carregando: boolean) => void,
  dispatch: Dispatch,
): void => {
  buscarListaAprovacoes(setCarregando)(dispatch);
};

export const criarPollingPropostasSupervisor = (
  setCarregando: (carregando: boolean) => void,
  dispatch: Dispatch,
): number =>
  Number(
    setInterval(async () => {
      buscarPropostasSupervisor(setCarregando, dispatch);
    }, MilissegundosAtualizacaoPropostas),
  );
