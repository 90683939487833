import React from 'react';

import Box from '@material-ui/core/Box';
import { ChatBalaoProps } from './ChatBalao';

import MensagemSupervisor from './MensagemSupervisor/MensagemSupervisor';

const ChatBalaoSupervisor = ({ mensagem }: ChatBalaoProps): JSX.Element => (
  <Box display="flex" justifyContent="flex-end">
    <MensagemSupervisor mensagem={mensagem} />
  </Box>
);

export default ChatBalaoSupervisor;
