import { PrioridadeCarga, Demandas } from 'features/dashboards/constants/Types';
import StatusDemandaEnum from 'shared/constants/StatusDemandaEnum';
import { IntervaloTempo } from 'shared/types/apiTypes';

type DemandaColaborador = {
  nomeNegociador: string;
  idNegociador: number;
  dataHoraDesdePrimeiroAcesso?: string;
  prioridadeCarga: PrioridadeCarga;
  negociacoesConcluidasPorHora: number;
  quantidadeAlertasDemanda: number;
  demandas: Demandas;
  status: StatusDemandaEnum;
};

type DemandasColaboradorResponse = {
  negociadores: DemandaColaborador[];
};

enum NegociacaoTipoAlerta {
  naoVista,
  semAprovacao,
  alertaVencimento,
}

enum PrioridadeCargaNegociacao {
  baixa,
  media,
  maxima,
}

type Demanda = {
  romaneio: string;
  nomeFilial: string;
  nomeCidade: string;
  sla: string;
  prioridadeCarga: PrioridadeCargaNegociacao;
  categorias?: NegociacaoTipoAlerta[];
  status?: StatusDemandaEnum;
};

type DemandaNegociador = Demanda & {
  quantidadeEntregas: number;
  ultimoValorProposta?: number;
  savingValorProposta?: number;
  valorMeta: number;
  savingValorMeta?: number;
  cycleTime?: IntervaloTempo;
  quantidadePedidosAprovacao?: number;
};

type DemandasNegociadorResponse = {
  tempoLimiteSemVisualizarNegociacao: IntervaloTempo;
  tempoLimiteSemAprovarNegociacao: IntervaloTempo;
  demandas: DemandaNegociador[];
};

export { PrioridadeCargaNegociacao, NegociacaoTipoAlerta };

export type {
  DemandaColaborador,
  Demandas,
  PrioridadeCarga,
  DemandasColaboradorResponse,
  Demanda,
  DemandaNegociador,
  DemandasNegociadorResponse,
};
