import { TextoSimples } from 'features/configuracoes/styles';
import React, { useState } from 'react';
import Accordion from 'shared/components/accordion/Accordion';
import AccordionDetails from 'shared/components/accordion/AccordionDetails';
import HeaderAccordion from 'shared/components/accordion/HeaderAccordion';
import { FormControlLabel, Switch } from '@material-ui/core';
import Header from './Header';
import { useStyles } from '../styles/secaoConfiguracao';
import { SecaoConfiguracaoProps } from '../types';

const SecaoConfiguracao = ({
  titulo,
  subtitulo,
  children,
  switchFreteTabeladoAtivar,
  switchChecked,
  setSwitchChecked,
}: SecaoConfiguracaoProps): JSX.Element => {
  const classes = useStyles();
  const [expandido, setExpandido] = useState(false);

  const toggleEstadoAccordion = (
    event: React.ChangeEvent<unknown>,
    isExpanded: boolean,
  ) => {
    if (event.target instanceof HTMLInputElement) {
      return;
    }

    setExpandido(isExpanded);
  };

  return (
    <Accordion
      className={classes.accordion}
      expanded={expandido}
      onChange={toggleEstadoAccordion}
      classes={{
        root: classes.raizAccordion,
      }}
    >
      <HeaderAccordion
        expandIconColor={classes.iconeAccordion}
        iconPosition="iconEnd"
        iconEdge="end"
      >
        <div>
          <Header titulo={titulo} className={classes.titulo} />
          <TextoSimples>
            {subtitulo}
            {switchFreteTabeladoAtivar && (
              <FormControlLabel
                style={{ marginLeft: '15px' }}
                control={
                  <Switch
                    checked={switchChecked}
                    color="primary"
                    onChange={(event) =>
                      setSwitchChecked && setSwitchChecked(event.target.checked)
                    }
                  />
                }
                label={
                  <span
                    style={{
                      display: 'flex',
                      color: '#676767',
                      fontWeight: 400,
                    }}
                  >
                    Frete Tabelado{' '}
                    {switchChecked === true ? 'Ativado' : 'Inativo'}
                  </span>
                }
              />
            )}
          </TextoSimples>
        </div>
      </HeaderAccordion>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default SecaoConfiguracao;
