import React, { useCallback, useEffect, useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import TabContext from '@material-ui/lab/TabContext';
import { useDispatch, useSelector } from 'react-redux';
import { orderBy } from 'lodash';
import { buscarDadosPermissaoUsuario } from 'features/login/redux/loginOperation';
import Titulo from '../../../shared/components/titulo/Titulo';
import CheckboxAba from '../components/CheckboxAba';
import Abas from '../constants/AbasPropostaSupervisor';
import PanelPendente from './panelsProposta/PanelPendente';
import PanelReprovados from './panelsProposta/PanelReprovados';
import PanelAprovadosNovaMeta from './panelsProposta/PanelAprovadosNovaMeta';
import PanelCarregando from './panelsProposta/PanelCarregando';
import { RootState } from '../../../store/reducer';
import {
  buscarPropostasSupervisor,
  criarPollingPropostasSupervisor,
} from '../utils/aprovacoesSupervisor';
import { PropostaSupervisorAprovados } from '../types';

const useStyles = makeStyles(() => ({
  containerCheckbox: {
    display: 'flex',
    width: '100%',
  },
}));

const AprovacoesSupervisor = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const propostas = useSelector(
    (reducer: RootState) => reducer.aprovacoesSupervisorReducer,
  );
  const [aba, setAba] = useState(Abas.Carregando);
  const [carregando, setCarregando] = useState<boolean>(true);

  useEffect(() => {
    buscarDadosPermissaoUsuario()(dispatch);
  }, [dispatch]);

  const unificarCardsAprovadoseNovaMeta = useCallback(() => {
    const aux1 = [
      ...propostas.propostasAprovadas,
      ...propostas.propostasAprovadasGreguim,
    ].map((p) => ({
      ...p,
      novaMeta: false,
    }));
    const aux2 = propostas.propostasNovaMeta.map((p) => ({
      ...p,
      novaMeta: true,
    }));
    return orderBy([...aux1, ...aux2], (o) => o.dataSolicitacaoAnalise, [
      'desc',
    ]) as PropostaSupervisorAprovados[];
  }, [
    propostas.propostasAprovadas,
    propostas.propostasAprovadasGreguim,
    propostas.propostasNovaMeta,
  ]);

  useEffect(() => {
    buscarPropostasSupervisor(setCarregando, dispatch);

    const pooling = criarPollingPropostasSupervisor(setCarregando, dispatch);

    return () => {
      clearInterval(pooling);
    };
  }, [dispatch]);

  useEffect(() => {
    if (
      (!carregando || propostas.propostasPendentes.length > 0) &&
      aba === Abas.Carregando
    )
      setAba(Abas.Pendentes);
  }, [carregando, propostas, aba]);

  return (
    <>
      <Titulo titulo="Pedidos de aprovação" />
      <Box className={classes.containerCheckbox}>
        <CheckboxAba
          texto="Pendentes"
          selecionado={aba === Abas.Pendentes}
          onClick={() => setAba(Abas.Pendentes)}
        />
        <CheckboxAba
          texto="Aprovados"
          selecionado={aba === Abas.Aprovados}
          onClick={() => setAba(Abas.Aprovados)}
        />
        <CheckboxAba
          texto="Reprovados"
          selecionado={aba === Abas.Reprovados}
          onClick={() => setAba(Abas.Reprovados)}
        />
      </Box>
      <TabContext value={aba}>
        {carregando && propostas.propostasPendentes.length === 0 ? (
          <PanelCarregando />
        ) : (
          <>
            <PanelPendente propostas={propostas.propostasPendentes} />
            <PanelReprovados propostas={propostas.propostasReprovadas} />
            <PanelAprovadosNovaMeta
              propostasAprovadas={unificarCardsAprovadoseNovaMeta()}
            />
          </>
        )}
      </TabContext>
    </>
  );
};

export default AprovacoesSupervisor;
