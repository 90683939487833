import { makeStyles } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';

export const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    '& > *': {
      marginLeft: spacingInPixels(theme, 2),
    },
  },
  agrupador: {
    background: theme.palette.cores.cinza[300],
    position: 'absolute',
    height: '100%',
    width: 3,
    left: 0,
    margin: 0,
  },
}));
