import React, { useEffect, useState } from 'react';
import { CategoriaVeiculo } from 'features/configuracoes/api/configuracoesApiTypes';
import { useStyles } from 'features/configuracoes/styles/stylesVeiculos';
import {
  atualizarCategoriaVeiculoFreteTabelado,
  buscarCategoriaVeiculosFreteTabelado,
} from 'features/configuracoes/utils/configuracoes';
import { useDispatch } from 'react-redux';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  Typography,
} from '@material-ui/core';

const ConteudoVeiculos = ({
  veiculoSelecionado,
  setDadosVeiculos,
}: {
  veiculoSelecionado: CategoriaVeiculo;
  setDadosVeiculos: React.Dispatch<React.SetStateAction<CategoriaVeiculo[]>>;
}): JSX.Element => {
  const classes = useStyles({ sliderTamanho: true });
  const dispatch = useDispatch();
  const [valorVolumetria, setValorVolumetria] = useState<number | string>(
    veiculoSelecionado?.volumetria || 0,
  );
  const [valorMinimo, setValorMinimo] = useState<number | string>(
    veiculoSelecionado?.capacidadeMinimaPeso || 0,
  );
  const [valorMaximo, setValorMaximo] = useState<number | string>(
    veiculoSelecionado?.capacidadeMaximaPeso || 0,
  );
  const [validacao, setValidacao] = useState<{
    minimo: boolean;
    maximo: boolean;
    volumetria: boolean;
  }>({
    minimo: false,
    maximo: false,
    volumetria: false,
  });
  useEffect(() => {
    if (veiculoSelecionado) {
      setValorMinimo(veiculoSelecionado?.capacidadeMinimaPeso || 0);
      setValorMaximo(veiculoSelecionado?.capacidadeMaximaPeso || 0);
      setValorVolumetria(veiculoSelecionado?.volumetria || 0);
      setValidacao({ minimo: false, maximo: false, volumetria: false });
    }
  }, [veiculoSelecionado]);

  const limparValores = () => {
    buscarCategoriaVeiculosFreteTabelado(setDadosVeiculos, dispatch);
  };

  const atualizarValores = () => {
    atualizarCategoriaVeiculoFreteTabelado(
      {
        ...veiculoSelecionado,
        capacidadeMinimaPeso: Number(valorMinimo),
        capacidadeMaximaPeso: Number(valorMaximo),
        volumetria: Number(valorVolumetria),
      },
      dispatch,
    );
  };

  const handleChangeMinimo = (e: { target: { value: string } }) => {
    const minimo = e.target.value;
    const numeroMinimo = minimo.replace(/[^0-9.]/g, '');
    const numeroMinimoSemVirgula = numeroMinimo.replace(/,/g, '');

    if (Number(numeroMinimo) >= Number(valorMaximo)) {
      setValidacao(() => ({ minimo: true, maximo: false, volumetria: false }));
    } else {
      setValidacao(() => ({ minimo: false, maximo: false, volumetria: false }));
    }

    setValorMinimo(parseInt(numeroMinimoSemVirgula, 10));
  };

  const handleChangeMaximo = (e: { target: { value: string } }) => {
    const maximo = e.target.value;
    const numeroMaximo = maximo.replace(/[^0-9]/g, '');
    const numeroMaximoSemVirgula = numeroMaximo.replace(/,/g, '');
    if (
      Number(valorMinimo) >= Number(numeroMaximo) ||
      Number(numeroMaximo) > 15000
    ) {
      setValidacao(() => ({ minimo: false, maximo: true, volumetria: false }));
    } else {
      setValidacao(() => ({ minimo: false, maximo: false, volumetria: false }));
    }

    setValorMaximo(parseInt(numeroMaximoSemVirgula, 10));
  };

  const handleChangeVolumetria = (e: { target: { value: string } }) => {
    const volumetria = e.target.value;
    const numeroVolumetria = volumetria.replace(/[^0-9.]/g, '');
    const volumetriaSemVirgula = numeroVolumetria.replace(/,/g, '');
    if (Number(numeroVolumetria) === 0 || Number(numeroVolumetria) > 100) {
      setValidacao(() => ({ minimo: false, maximo: false, volumetria: true }));
    } else {
      setValidacao(() => ({ minimo: false, maximo: false, volumetria: false }));
    }
    setValorVolumetria(parseInt(volumetriaSemVirgula, 10));
  };

  const handleLimpar = () => {
    limparValores();
  };

  const handleAtualizar = () => {
    atualizarValores();
  };

  return (
    <Box>
      <Typography className={`${classes.infoCard}`}>
        Informe a capacidade para configurar o peso em cada categoria de véiculo
      </Typography>
      <Box className={classes.sliderBox}>
        <Box className={classes.formControl}>
          <FormControl className={classes.BoxMinMax}>
            <FormHelperText
              error={validacao.minimo}
              className={classes.TextHelper}
              id="minimo"
            >
              Mínimo
            </FormHelperText>
            <OutlinedInput
              id="minimo"
              value={valorMinimo}
              error={validacao.minimo}
              className={classes.inputNumber}
              type="number"
              onChange={handleChangeMinimo}
              endAdornment={<InputAdornment position="end">Kg</InputAdornment>}
            />
          </FormControl>
          <Box className={classes.hrMinMax} />
          <FormControl
            className={classes.BoxMinMax}
            style={{ margin: '0 -35px 0 4px' }}
          >
            <FormHelperText
              error={validacao.maximo}
              className={classes.TextHelper}
              id="maximo"
            >
              Máximo<span className={classes.spanMax}> (15000)</span>
            </FormHelperText>
            <OutlinedInput
              id="maximo"
              value={valorMaximo}
              error={validacao.maximo}
              className={classes.inputNumber}
              type="number"
              onChange={handleChangeMaximo}
              endAdornment={<InputAdornment position="end">Kg</InputAdornment>}
            />
          </FormControl>
          <FormControl>
            <FormHelperText
              error={validacao.volumetria}
              className={classes.TextHelper}
              id="volumetria"
            >
              Volumetria
            </FormHelperText>
            <OutlinedInput
              id="volumetria"
              value={valorVolumetria}
              error={validacao.volumetria}
              className={classes.inputNumber}
              type="number"
              onChange={handleChangeVolumetria}
              endAdornment={<InputAdornment position="end">m³</InputAdornment>}
            />
          </FormControl>
        </Box>
      </Box>
      <Box className={classes.botoes}>
        <Button color="primary" variant="text" onClick={handleLimpar}>
          LIMPAR
        </Button>
        <Button
          disabled={
            validacao.maximo || validacao.minimo || validacao.volumetria
          }
          color="primary"
          variant="contained"
          onClick={handleAtualizar}
        >
          SALVAR
        </Button>
      </Box>
    </Box>
  );
};

export default ConteudoVeiculos;
