import { IconButton, Typography, withStyles } from '@material-ui/core';

import { shade } from 'polished';

interface BotaoOutlineIconeTextoStylesProps {
  corTexto?: string;
  corBorda?: string;
}

interface BotaoIconeTextoStylesProps {
  corTexto?: string;
  corFundo?: string;
}

export const EstiloBotaoOutlineIconeTexto = withStyles(() => ({
  root: (props: BotaoOutlineIconeTextoStylesProps) => ({
    borderRadius: 50,
    textTransform: 'none',
    fontSize: '16px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontStyle: 'normal',
    color: props.corTexto,
    borderColor: props.corBorda,
    border: '1px',
    borderStyle: 'solid',
    backgroundColor: 'transparent',
    height: '40px',
    margin: '0px 5px',
    padding: '10px 16px 10px 22px',
  }),
}))(IconButton);

export const EstiloBotaoIconeTexto = withStyles(() => ({
  root: (props: BotaoIconeTextoStylesProps) => ({
    borderRadius: 50,
    textTransform: 'none',
    fontSize: '16px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontStyle: 'normal',
    color: props.corTexto,
    backgroundColor: props.corFundo,
    height: '40px',
    margin: '0px 5px',
    padding: '10px 16px 10px 22px',
    '&:hover': {
      backgroundColor: shade(0.2, props.corFundo || '#fff'),
    },
  }),
}))(IconButton);

export const LabelBotao = withStyles(() => ({
  root: {
    marginLeft: '10px',
  },
}))(Typography);
