import { SkeletonTabelaDetalhesDemandasProps } from 'features/detalhesDemandasDiaria/types/tabelaTypes';
import React from 'react';
import CustomSkeleton from 'shared/components/skeleton/CustomSkeleton';

const SkeletonTabelaDetalhesDemandas = ({
  dadosSkeleton,
  estiloLinha,
}: SkeletonTabelaDetalhesDemandasProps): JSX.Element => (
  <tbody>
    {new Array(10).fill(null).map((_, indexLinha) => (
      <tr className={estiloLinha} key={`skeleton-linha-${indexLinha + 1}`}>
        {dadosSkeleton.map(({ borderRadius, width }, indexColuna) => (
          <td
            key={`skeleton-linha-${indexLinha + 1}-coluna-${indexColuna + 1}`}
          >
            <CustomSkeleton borderradius={borderRadius} width={width} />
          </td>
        ))}
      </tr>
    ))}
  </tbody>
);

export default SkeletonTabelaDetalhesDemandas;
