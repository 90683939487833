import { makeStyles } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';

export const useStyles = makeStyles((theme) => ({
  botaoPlanilha: {
    width: '20%',
    minWidth: spacingInPixels(theme, 25),
    maxWidth: spacingInPixels(theme, 50),
    height: spacingInPixels(theme, 6),
    fontSize: '16px',
    float: 'right',
    marginTop: spacingInPixels(theme, 3),
    marginBottom: spacingInPixels(theme, 3),
    borderRadius: 24,
    color: theme.palette.cores.branco,
    background: theme.palette.cores.azul[600],
    boxShadow: 'none',

    '&:hover': {
      background: theme.palette.cores.azul[600],
      filter: 'brightness(0.8)',
    },
  },
}));
