import React from 'react';

import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core';
import DropdownData from '../dropdown/dropdownData';
import spacingInPixels from '../../functions/materialUtils';

export type FiltroDataProps = {
  texto: string;
  titulo: string;
  dataInicio: Date | null;
  dataFim: Date | null;
  onDataInicioChange: (dataInicio: Date | null) => void;
  onDataFimChange: (dataFim: Date | null) => void;
};

const FiltroData = ({
  texto,
  titulo,
  dataInicio,
  dataFim,
  onDataInicioChange,
  onDataFimChange,
}: FiltroDataProps): JSX.Element => {
  const theme = useTheme();
  return (
    <Box display="flex">
      <DropdownData
        id="data"
        texto={texto}
        titulo={titulo}
        dataInicio={dataInicio}
        dataFim={dataFim}
        onDataInicioChange={onDataInicioChange}
        onDataFimChange={onDataFimChange}
        marginLeft={spacingInPixels(theme, 5)}
      />
    </Box>
  );
};

export default FiltroData;
