import React from 'react';

import Box from '@material-ui/core/Box';

import { PropostaNegociacao } from '../../../api/informacoesNegociacaoApiTypes';

import AceitarPropostaButton from './AceitarPropostaButton';
import RecusarPropostaButton from './RecusarPropostaButton';
import Texto from '../listaDisponiveis/TextoItemLista';

type ColunaControlePropostaProps = {
  proposta: PropostaNegociacao;
};

const ColunaControleProposta = ({
  proposta,
}: ColunaControlePropostaProps): JSX.Element => {
  return (
    <Box
      display="flex"
      height="100%"
      flexDirection="column"
      alignItems="center"
    >
      <Texto>Aceitar?</Texto>
      <Box display="flex" width="100%" justifyContent="space-evenly">
        <AceitarPropostaButton proposta={proposta} />
        <RecusarPropostaButton proposta={proposta} />
      </Box>
    </Box>
  );
};

export default ColunaControleProposta;
