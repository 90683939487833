import React from 'react';

import { InfoOutlined } from '@material-ui/icons';
import { makeStyles, Typography } from '@material-ui/core';

import { Rubik } from '../../../../shared/constants/font';
import { converterDataApiParaDataChat } from '../../utils/dataHora';
import ChatBalaoInformativo from '../../components/ChatBalaoInformativo';
import spacingInPixels from '../../../../shared/functions/materialUtils';

type ChatInformativoSolicitacaoAprovacaoMotoristaProps = {
  data: string;
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: 420,
    padding: spacingInPixels(theme, 1),
  },
  textoAviso: {
    fontFamily: Rubik,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '17px',
  },
}));

const ChatInformativoSolicitacaoAprovacaoMotorista = ({
  data,
}: ChatInformativoSolicitacaoAprovacaoMotoristaProps): JSX.Element => {
  const classes = useStyles();

  return (
    <ChatBalaoInformativo
      className={classes.container}
      icone={<InfoOutlined color="primary" />}
    >
      <Typography className={classes.textoAviso}>
        O motorista foi informado de que o valor necessita de aprovação da
        supervisão às {converterDataApiParaDataChat(data)}.
      </Typography>
    </ChatBalaoInformativo>
  );
};

export default ChatInformativoSolicitacaoAprovacaoMotorista;
