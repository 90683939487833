import queryString from 'query-string';
import { ConexaoAPI } from '../../../shared/classes/conexaoAPI';

import { baixarArquivoRespostaEndpoint } from '../../../shared/functions/arquivoUtils';

import { ResponseNegociacoes } from './minhasNegociacoesApiTypes';

export const obterNegociacoes = async (): Promise<ResponseNegociacoes> => {
  const url = `${process.env.REACT_APP_URL_API_BASE}/negociacoes`;
  const conexao = new ConexaoAPI(url, 'get');
  const conectar = conexao.gerarFuncaoConectarRefreshToken<ResponseNegociacoes>();
  const resposta = await conectar();

  return resposta.data;
};

export const getPlanilha = async (ids: number[]): Promise<void> => {
  const searchParams = queryString.stringify({ ids });
  const URL_BUSCAR_DADOS_NEGOCIACAO = `${process.env.REACT_APP_URL_API_BASE}/negociacoes/planilhas/negociador?${searchParams}`;
  const nomeArquivoPadrao = 'Arquivo.xlsx';

  const conexao = new ConexaoAPI(URL_BUSCAR_DADOS_NEGOCIACAO, 'get');
  conexao.adicionarResponseTypeArrayBuffer();
  const conectar = conexao.gerarFuncaoConectarRefreshToken();
  const resposta = await conectar();

  baixarArquivoRespostaEndpoint(resposta, nomeArquivoPadrao);
};
