import { Divider, makeStyles } from '@material-ui/core';
import React from 'react';
import CustomSkeleton from 'shared/components/skeleton/CustomSkeleton';
import spacingInPixels from 'shared/functions/materialUtils';

const useStyles = makeStyles((theme) => ({
  containerSkeletonBigNumber: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacingInPixels(theme, 0.5),
    padding: spacingInPixels(theme, 0.5),
  },
}));

const SkeletonBigNumberAlerta = (): JSX.Element => {
  const classes = useStyles();
  return (
    <section className={classes.containerSkeletonBigNumber}>
      <CustomSkeleton borderradius={4} width="40%" height={24} />
      <CustomSkeleton borderradius={4} width="65%" height={18} />
    </section>
  );
};

const SkeletonQuadroBigNumbersAlerta = (): JSX.Element => (
  <>
    <SkeletonBigNumberAlerta />
    <Divider />
    <SkeletonBigNumberAlerta />
    <Divider />
    <SkeletonBigNumberAlerta />
  </>
);

export default SkeletonQuadroBigNumbersAlerta;
