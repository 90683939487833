import React from 'react';

import RemetenteMensagemProposta from '../../../../shared/constants/RemetenteMensagemProposta';

import { Mensagem } from '../../api/chatNegociacaoApiTypes';

import ChatBalaoMotorista from './ChatBalaoMotorista';
import ChatBalaoNegociador from './ChatBalaoNegociador';
import ChatBalaoSupervisor from './ChatBalaoSupervisor';

export type ChatBalaoProps = {
  mensagem: Mensagem;
};

const ChatBalao = ({ mensagem }: ChatBalaoProps): JSX.Element => {
  switch (mensagem.remetente) {
    case RemetenteMensagemProposta.Motorista:
      return <ChatBalaoMotorista mensagem={mensagem} />;
    case RemetenteMensagemProposta.Supervisor:
      return <ChatBalaoSupervisor mensagem={mensagem} />;
    case RemetenteMensagemProposta.Negociador:
    default:
      return <ChatBalaoNegociador mensagem={mensagem} />;
  }
};

export default ChatBalao;
