import { makeStyles } from '@material-ui/core';
import spacingInPixels from '../../../shared/functions/materialUtils';

export const useStylesDadosMotoristaRota = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '2fr 1px 3fr',
    gridTemplateRows: 'auto',
    gridGap: spacingInPixels(theme, 1),
  },
  boxSpaceBetween: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export default useStylesDadosMotoristaRota;
