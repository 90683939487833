import React, { useState, useEffect } from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import { makeStyles } from '@material-ui/core/styles';

import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/reducer';

import MotoristasTab from './listaDisponiveis/MotoristasTab';
import PropostasTab from './listaEmNegociacao/PropostasTab';

import EnviarPropostaControle from './EnviarPropostaControle';
import RecusarPropostaDialog from './RecusarPropostaDialog';
import ReatribuirNegociacaoDialog from './ReatribuirNegociacaoDialog';
import BoxScroll from '../../../../shared/components/box/BoxScroll';
import Abas from './constants/AbasMotoristas';
import CabecalhoAbasCard from './CabecalhoAbasCard';
import CancelarNegociacaoMenu from './CancelarNegociacaoMenu';

const useStyles = makeStyles((theme) => ({
  container: {
    height: 'inherit',
    maxHeight: 'inherit',
    overflow: 'auto',
    padding: '16px 24px 4px 24px',
  },
  painel: {
    padding: 0,
    paddingTop: theme.spacing(2),
    width: '100%',
  },
  tituloNegociacaoConcluida: {
    fontSize: '14px',
    fontWeight: 700,
  },
  boxFlex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const MotoristasAbasCard = (): JSX.Element => {
  const classes = useStyles();

  const [aba, setAba] = useState(Abas.Disponiveis);
  const [mostrarAba, setMostrarAba] = useState<boolean>(true);
  const [valorPesquisa, setValorPesquisa] = useState<string>('');

  const { negociacaoConcluida, idPropostaComFoco, negociacao } = useSelector(
    (state: RootState) => state.informacoesNegociacaoReducer,
  );

  const limparBarraPesquisa = () => {
    setMostrarAba(true);
    setValorPesquisa('');
  };

  useEffect(() => {
    if (negociacaoConcluida || idPropostaComFoco !== undefined) {
      setAba(Abas.EmNegociacao);
    }
  }, [negociacaoConcluida, idPropostaComFoco]);

  return (
    <BoxScroll>
      <Box className={classes.container}>
        <TabContext value={aba}>
          {negociacaoConcluida ? (
            <Box className={classes.boxFlex}>
              <Typography
                color="primary"
                className={classes.tituloNegociacaoConcluida}
              >
                Negociação Fechada
              </Typography>
              <CancelarNegociacaoMenu />
            </Box>
          ) : (
            <CabecalhoAbasCard
              aba={aba}
              mostrarAba={mostrarAba}
              valorPesquisa={valorPesquisa}
              setAba={setAba}
              setMostrarAba={setMostrarAba}
              setValorPesquisa={setValorPesquisa}
              negociacao={negociacao}
            />
          )}

          <TabPanel value={Abas.Disponiveis} className={classes.painel}>
            <MotoristasTab />
          </TabPanel>
          <TabPanel value={Abas.EmNegociacao} className={classes.painel}>
            <PropostasTab />
          </TabPanel>
        </TabContext>
        <EnviarPropostaControle limparBarraPesquisa={limparBarraPesquisa} />
        <RecusarPropostaDialog />
        <ReatribuirNegociacaoDialog />
      </Box>
    </BoxScroll>
  );
};

export default MotoristasAbasCard;
