import Box from '@material-ui/core/Box';
import { Theme, withStyles } from '@material-ui/core/styles';

import { spacingInPixels } from '../../functions/materialUtils';

export default withStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'calc(10%)',
      marginRight: 'calc(3%)',
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: 'calc(7%)',
      marginRight: 'calc(5%)',
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: 'calc(5%)',
      marginRight: 'calc(1%)',
    },
    marginBottom: spacingInPixels(theme, 6),
    marginTop: spacingInPixels(theme, 3),
    marginLeft: spacingInPixels(theme, 3),
  },
}))(Box);
