import { makeStyles } from '@material-ui/core';
import { Rubik } from '../../../shared/constants/font';
import spacingInPixels from '../../../shared/functions/materialUtils';

export const useStyles = makeStyles((theme) => ({
  containerImagemChat: {
    position: 'relative',
    width: '100%',
    height: '200px',
    padding: 0,
    border: `2px solid ${theme.palette.cores.cinza[100]}`,
    borderRadius: '5px',
    marginBottom: 10,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  botaoLimparImagem: {
    position: 'absolute',
    top: 5,
    right: 5,
    width: '13px',
    height: '16px',
    minWidth: '5px',
    borderRadius: '50%',
    backgroundColor: `${theme.palette.cores.azulMartins[500]}`,
  },
  iconeExcluirImagem: {
    color: theme.palette.cores.branco,
    width: '15px',
    height: '15px',
  },
  imagemChat: {
    overflow: 'hidden',
    width: '100%',
  },
  chatRodape: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '16px',
  },
  switchListaClientes: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  campoTextoLivre: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  textoListaClientes: {
    fontFamily: Rubik,
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 400,
    marginLeft: '8px',
    color: `${theme.palette.cores.azulMartins[700]}`,
  },
  iconeMaisAcoesChat: {
    borderRadius: 4,
    height: spacingInPixels(theme, 5),
    width: spacingInPixels(theme, 5),
    marginTop: spacingInPixels(theme, 0.5),
  },
  textoContraproposta: {
    fontWeight: 400,
  },
  badge: {
    marginTop: '-18px',
    marginRight: '16px',
    position: 'absolute',
  },
}));

export default useStyles;
