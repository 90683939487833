import React, { useState } from 'react';
import {
  Badge,
  Box,
  Fab,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import IconeChat from 'assets/icones/IconeChat';
import IconeChatAutomatizada from 'assets/icones/IconeChatAutomatizada';
import IconeTaskCheck from 'assets/icones/IconeTaskCheck';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducer';

import { OrigemNegociacao } from 'features/informacoesNegociacao/api/informacoesNegociacaoApiTypes';
import { Rubik } from '../../../shared/constants/font';
import { DrawerInformacoesAdicionais } from './drawer/DrawerInformacoesAdicionais';
import TemporizadorFreteTabelado from './TemporizadorFreteTabelado';

const useStyles = makeStyles((theme) => ({
  texto: {
    fontFamily: Rubik,
    fontWeight: 400,
    color: theme.palette.cores.cinza[500],
    textAlign: 'center',
    maxWidth: 375,
  },
  buttonInformation: {
    cursor: 'pointer',
    borderColor: 'transparent',
    borderRadius: '50%',
    backgroundColor: 'white',
    marginLeft: '24px',
    marginTop: '-24px',
    width: '57px',
    height: '57px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    '&:hover': {
      boxShadow: 'rgba(0, 0, 0, 0.1)',
      backgroundColor: 'rgba(0, 0, 0, 0.001)',
    },
    '&:active': {
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    },
  },
  badge: {
    marginTop: '-18px',
    marginRight: '16px',
    position: 'absolute',
  },
  contadorFreteTabelado: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#E8F5E9',
    padding: '8px 24px',
    margin: '0px 0px 0px 0.4px',
    height: '64px',
    boxShadow: '0px 2px 4px 0px #E0E0E0',
  },
  boxFreteTabelado: {
    display: 'flex',
    color: '#757575',
  },
  boxTemporizador: {
    display: 'flex',
    alignItems: 'center',
    width: '77px',
    height: '34px',
    borderRadius: '4px',
    background: '#2E7D32',
  },
  textDinheiro: {
    fontSize: '14px',
    color: '#008C41',
    fontWeight: 500,
    marginLeft: '2px',
  },
}));

const ChatSemConversaSelecionada = (): JSX.Element => {
  const classes = useStyles();
  const [visibleDrawer, setVisibleDrawer] = useState(false);

  const { negociacao, propostasAplicativo } = useSelector(
    (state: RootState) => state.informacoesNegociacaoReducer,
  );

  const handleDrawerClick = () => {
    if (!(negociacao?.nomeMotoristaIndicado || negociacao?.observacoes)) return;
    setVisibleDrawer(!visibleDrawer);
  };

  return (
    <Box height="81%">
      {negociacao?.origemNegociacao === OrigemNegociacao.freteTabelado && (
        <TemporizadorFreteTabelado
          negociacao={negociacao}
          valorProposta={propostasAplicativo[0]?.propostaAtual}
        />
      )}
      {negociacao?.origemNegociacao === OrigemNegociacao.freteTabelado ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          height="90%"
          gridGap={5}
        >
          <IconeChatAutomatizada />
          <Typography className={classes.texto} variant="h5">
            Essa é uma negociação automatizada.
          </Typography>
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          height="100%"
          gridGap={5}
        >
          <IconeChat />
          <Typography className={classes.texto} variant="h5">
            Selecione um motorista em negociação para visualizar a conversa
          </Typography>
        </Box>
      )}
      {negociacao?.origemNegociacao === OrigemNegociacao.negociador && (
        <Tooltip
          title={
            !(negociacao?.nomeMotoristaIndicado || negociacao?.observacoes)
              ? 'Nenhuma Informação'
              : 'Informações adicionais'
          }
          placement="right"
          arrow
        >
          <Fab
            onClick={handleDrawerClick}
            className={classes.buttonInformation}
            disableRipple
          >
            {(negociacao?.nomeMotoristaIndicado || negociacao?.observacoes) && (
              <Badge variant="dot" color="error" className={classes.badge} />
            )}
            <IconeTaskCheck
              fill={
                !(negociacao?.nomeMotoristaIndicado || negociacao?.observacoes)
                  ? '#82838a86'
                  : '#82838A'
              }
            />
          </Fab>
        </Tooltip>
      )}
      <DrawerInformacoesAdicionais
        negociacoes={negociacao}
        openDrawer={visibleDrawer}
        onCloseOpenDrawer={handleDrawerClick}
      />
    </Box>
  );
};

export default ChatSemConversaSelecionada;
