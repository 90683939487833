export type FiltroStatusType = {
  id: number;
  valor: string;
};

enum FiltroStatus {
  todos,
  emAndamento,
  concluidos,
  tempoExpirado,
  cancelados,
}

export const OpcoesFiltroStatus: FiltroStatusType[] = [
  {
    id: FiltroStatus.todos,
    valor: 'Todos',
  },
  {
    id: FiltroStatus.emAndamento,
    valor: 'Em andamento',
  },
  {
    id: FiltroStatus.tempoExpirado,
    valor: 'Tempo expirando',
  },
  {
    id: FiltroStatus.concluidos,
    valor: 'Concluídas',
  },
  {
    id: FiltroStatus.cancelados,
    valor: 'Recusados',
  },
];

export default FiltroStatus;
