import { createReducer } from '@reduxjs/toolkit';
import { Demanda } from '../../negociadores/api/listagemNegociadoresApiTypes';
import { listarDemandasNegociador } from './listagemDemandasNegociadorActions';

export type ListagemDemandasNegociadorState = {
  demandasNegociadorPorFilial: Map<string, Demanda[]>;
  notificacoesNegociadorPorCategoria: Map<string, Demanda[]>;
  erro: boolean;
};

const estadoInicial: Readonly<ListagemDemandasNegociadorState> = {
  demandasNegociadorPorFilial: new Map(),
  notificacoesNegociadorPorCategoria: new Map(),
  erro: false,
};

const listagemDemandasNegociadorReducer = createReducer(
  estadoInicial,
  (builder) => {
    builder
      .addCase(listarDemandasNegociador, (_state, action) => action.payload)
      .addDefaultCase((state) => state);
  },
);

export default listagemDemandasNegociadorReducer;
