import { obterApenasNumeros } from './stringUtils';

export const formatarNumeroParaStringMonetaria = (
  numero: number | undefined,
): string => {
  const numeroString = numero?.toString() || '0';

  if (numeroString.length === 1) {
    return `0,0${numeroString}`;
  }

  if (numeroString.length === 2) {
    return `0,${numeroString}`;
  }

  const reais = numeroString.substr(0, numeroString.length - 2);
  const centavos = numeroString.substring(numeroString.length - 2);
  return `${Number(reais).toLocaleString('pt-br')},${centavos}`;
};

export const formatarNumeroParaStringMonetariaComSimbolo = (
  numero: number | undefined,
): string => `R$ ${formatarNumeroParaStringMonetaria(numero)}`;

export const converterStringMonetariaParaNumero = (
  valorMonetario: string,
): number => {
  const numerosValorMonetario = obterApenasNumeros(valorMonetario);

  return Number(numerosValorMonetario);
};

export const tratarValorMonetario = (valor?: number): string =>
  valor !== undefined && valor !== null && valor >= 0
    ? formatarNumeroParaStringMonetariaComSimbolo(valor)
    : '--';

const tratarSegundaUnidade = (segundaUnidade: string): string => {
  const restoSegundaUnidade = Number(segundaUnidade) % 100;
  return restoSegundaUnidade === 0 ? segundaUnidade[0] : segundaUnidade;
};

export const formatarValorDecimalParaStringMonetaria = (
  valor: number,
): string => {
  if (!valor) {
    return 'R$ 0,00';
  }

  return `R$ ${valor.toLocaleString('pt-BR', {
    style: 'decimal',
    minimumFractionDigits: 2,
  })}`;
};

export const formatarValorMonetarioSimplificado = (valor?: number): string => {
  const UM_MILHAO = 1000000;
  const UM_MIL = 1000;
  if (valor === undefined) return 'R$ --';
  const valorMonetario = formatarValorDecimalParaStringMonetaria(valor);
  if (valor > UM_MILHAO) {
    const [unidadeMilhao, unidadeMilhares] = valorMonetario.split('.');
    return `${unidadeMilhao}.${tratarSegundaUnidade(unidadeMilhares)}M`;
  }
  if (valor > UM_MIL) {
    const [unidadeMilhares, unidadeCentenas] = valorMonetario.split('.');
    const [unidadeCentenasFormatada] = unidadeCentenas.split(',');
    return `${unidadeMilhares}.${tratarSegundaUnidade(
      unidadeCentenasFormatada,
    )}k`;
  }
  return valorMonetario;
};
