import { useTheme } from '@material-ui/core';
import * as React from 'react';
import { SVGProps } from 'react';

const IconeArquivoBarraDiagonal = ({
  fill,
  width,
  height,
  ...outros
}: SVGProps<SVGSVGElement>): JSX.Element => {
  const theme = useTheme();

  return (
    <svg
      width={width || 22}
      height={height || 22}
      viewBox="0 0 22 22"
      fill="none"
      {...outros}
    >
      <path
        d="m20.71 19.29-18-18a1.004 1.004 0 0 0-1.42 1.42L3 4.41V18a3 3 0 0 0 3 3h10a3 3 0 0 0 2.39-1.2l.9.91a.999.999 0 0 0 1.42 0 1 1 0 0 0 0-1.42ZM16 19H6a1 1 0 0 1-1-1V6.41l11.93 11.93A1 1 0 0 1 16 19ZM7.66 3H11v3a3 3 0 0 0 3 3h3v3.34a1 1 0 1 0 2 0V7.94a1.307 1.307 0 0 0-.06-.27v-.09a1.07 1.07 0 0 0-.19-.28l-6-6a1.071 1.071 0 0 0-.28-.19h-.09L12.06 1h-4.4a1 1 0 0 0 0 2ZM13 4.41 15.59 7H14a1 1 0 0 1-1-1V4.41Z"
        fill={fill || theme.palette.cores.azul[700]}
      />
    </svg>
  );
};

export default IconeArquivoBarraDiagonal;
