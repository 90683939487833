import { makeStyles } from '@material-ui/core';

export const useStylesPanelProposta = makeStyles((theme) => ({
  panelContainer: {
    paddingLeft: 0,
  },
  gridConteiner: {
    width: '100%',
  },
  boxIcone: {
    backgroundColor: theme.palette.cores.cinza[300],
    borderRadius: '100%',
    padding: 16,
  },
  loading: {
    color: theme.palette.cores.azulMartins[500],
  },
  panelContainerLoading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default useStylesPanelProposta;
