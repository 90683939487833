import { Dispatch } from 'redux';
import { aceitarProposta, aceitarPropostaGreguim } from './chatResposta';

export const onClickBotaoAceitarProposta = async (
  chatIdNegociacao: number | undefined,
  chatIdProposta: number | undefined,
  setEnviando: (erro: boolean) => void,
  dispatch: Dispatch,
): Promise<void> => {
  await aceitarProposta({
    chatIdNegociacao,
    chatIdProposta,
    setEnviando,
    dispatch,
  });
};

export const onClickBotaoAceitarPropostaEPedirAprovacao = async (
  chatIdNegociacao: number | undefined,
  chatIdProposta: number | undefined,
  setEnviando: (erro: boolean) => void,
  dispatch: Dispatch,
): Promise<void> => {
  await aceitarPropostaGreguim({
    chatIdNegociacao,
    chatIdProposta,
    setEnviando,
    dispatch,
  });
};
