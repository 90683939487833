import React from 'react';
import { useHistory } from 'react-router-dom';
import { Divider, makeStyles, useTheme } from '@material-ui/core';
import IconeCaixa from 'assets/icones/IconeCaixa';
import IconeExclamacaoCirculo from 'assets/icones/IconeExclamacaoCirculo';
import { DemandaDiaria as DemandaDiariaType } from 'features/demandaDiaria/api/demandaDiariaApiTypes';
import ConteudoBigNumberComplemento from 'shared/components/bigNumber/ConteudoBigNumberComplemento';
import spacingInPixels from 'shared/functions/materialUtils';
import { PATH_DETALHES_DEMANDAS_DIARIA } from 'shared/constants/pathConstants';
import { StatusDemandaDiariaDashboard } from 'features/detalhesDemandasDiaria/constants/Constants';
import SkeletonQuadroBigNumbersDivididos from '../skeletons/SkeletonQuadroBigNumbersDivididos';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '47% 2% 47%',
    gap: spacingInPixels(theme, 2),
    border: `1px solid transparent`,
    backgroundColor: theme.palette.cores.branco,
    padding: spacingInPixels(theme, 1.5),
    borderRadius: spacingInPixels(theme, 1),
    boxShadow: '0px 1px 6px 0px rgba(0, 0, 0, 0.09)',

    cursor: 'pointer',
    '&:hover': {
      boxShadow: 'none',
      borderColor: `${theme.palette.cores.azul[400]}`,
    },
  },
  divider: {
    height: 'auto',
  },
  areaClicavel: {
    border: 'none',
    backgroundColor: 'inherit',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: 'none',
    },
  },
}));

export interface QuadroBigNumbersDivididosProps {
  demandasDiarias?: DemandaDiariaType;
  carregando?: boolean;
}

const QuadroBigNumbersDivididos = ({
  demandasDiarias,
  carregando,
}: QuadroBigNumbersDivididosProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const handleClick = (tipoDemanda: StatusDemandaDiariaDashboard) => {
    history.push(
      PATH_DETALHES_DEMANDAS_DIARIA.replace(':tipoDemanda', tipoDemanda),
    );
  };

  if (carregando) return <SkeletonQuadroBigNumbersDivididos />;

  return (
    <section className={classes.container}>
      <button
        onClick={() =>
          handleClick(StatusDemandaDiariaDashboard.DEMANDAS_DO_DIA)
        }
        type="button"
        className={classes.areaClicavel}
      >
        <ConteudoBigNumberComplemento
          icone={<IconeCaixa width={15} height={15} />}
          cor={theme.palette.cores.azul[500]}
          numero={demandasDiarias?.demandaTotal}
          descricao="Total de demandas do dia"
          complemento="Soma das demandas pendentes e por status"
        />
      </button>

      <Divider orientation="vertical" className={classes.divider} />

      <button
        onClick={() => handleClick(StatusDemandaDiariaDashboard.PENDENTES)}
        type="button"
        className={classes.areaClicavel}
      >
        <ConteudoBigNumberComplemento
          icone={<IconeExclamacaoCirculo width={15} height={15} />}
          cor={theme.palette.cores.vermelho[450]}
          numero={demandasDiarias?.atrasada}
          descricao="Demandas pendentes"
          complemento="Demandas que restaram de outros dias"
        />
      </button>
    </section>
  );
};

export default QuadroBigNumbersDivididos;
