import React from 'react';
import { ChatBalaoProps } from '../ChatBalao';
import TypographyChat from '../../TypographyChat';
import ValorContraProposta from '../ValorContraProposta';

const AceiteNegociador = ({ mensagem }: ChatBalaoProps): JSX.Element => (
  <TypographyChat>
    Fechado! Vamos fechar o frete por{' '}
    <ValorContraProposta valorContraproposta={mensagem.valorContraproposta} />.
  </TypographyChat>
);

export default AceiteNegociador;
