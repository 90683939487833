import { makeStyles } from '@material-ui/core';
import theme from 'theme';

export const useStyles = makeStyles(() => ({
  minuteText: {
    fontSize: '16px',
    color: theme.palette.cores.vermelho[500],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 15,
  },
  iconColor: {
    color: theme.palette.cores.vermelho[500],
    position: 'absolute',
    left: '50%',
    marginLeft: '-2px',
    top: '50%',
    marginTop: '-6px',
  },
  inIconColor: {
    position: 'absolute',
    color: theme.palette.cores.cinza[500],
    left: '50%',
    marginLeft: '-2px',
    top: '50%',
    marginTop: '-6px',
  },
}));
