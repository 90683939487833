import React from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  makeStyles,
  Typography,
  Dialog,
} from '@material-ui/core';
import { SaveOutlined } from '@material-ui/icons';

import { useDispatch, useSelector } from 'react-redux';

import DialogTitle from '../../../../shared/components/modal/DialogTitle';
import { RootState } from '../../../../store/reducer';
import {
  atualizarNegociacaoOperation,
  atualizarValoresOperation,
} from '../../redux/informacoesNegociacaoOperation';
import EditarValorProposto from './EditarValorProposto';

type ModalEditarValoresProps = {
  modalAberto: boolean;
  fecharModal: () => void;
};

const useStyles = makeStyles((theme) => ({
  modal: {
    width: 464,
    height: 352,
    padding: theme.spacing(0, 1, 1, 1),
  },
  botaoSalvar: {
    borderRadius: 24,
    backgroundColor: theme.palette.cores.azulMartins[500],
    color: theme.palette.cores.branco,
    padding: theme.spacing(1, 2),
    textTransform: 'capitalize',
  },
  titulo: {
    color: theme.palette.cores.azulMartins[500],
  },
}));

const ModalEditarValores = ({
  modalAberto,
  fecharModal,
}: ModalEditarValoresProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { idNegociacao } = useSelector(
    (state: RootState) => state.informacoesNegociacaoReducer,
  );

  const {
    valorNegociacao,
    valorFrotaPropria,
    valorFreteTerceiro,
  } = useSelector((state: RootState) => state.valoresCardReducer);

  const salvar = async () => {
    await atualizarValoresOperation(idNegociacao, {
      valorNegociacao,
      valorFreteTerceiro,
      valorFrotaPropria,
    })(dispatch);
    atualizarNegociacaoOperation(idNegociacao)(dispatch);
    fecharModal();
  };

  return (
    <Dialog
      open={modalAberto}
      onClose={fecharModal}
      classes={{ paper: classes.modal }}
    >
      <DialogTitle onClose={fecharModal}>
        <Typography className={classes.titulo}>
          Editar valores iniciais para a negociação
        </Typography>
      </DialogTitle>
      <DialogContent>
        <EditarValorProposto />
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.botaoSalvar}
          disabled={!valorFrotaPropria}
          onClick={salvar}
        >
          <SaveOutlined style={{ marginRight: 8 }} />
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalEditarValores;
