import { makeStyles } from '@material-ui/core';
import { Rubik } from 'shared/constants/font';

export const useStyles = makeStyles(() => ({
  textoAviso: {
    fontFamily: Rubik,
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '23px',
    textAlign: 'center',
    marginTop: '16px',
    '& b': {
      fontWeight: 700,
      fontFamily: Rubik,
      fontSize: '26px',
    },
  },
  textoAtencao: {
    fontFamily: Rubik,
    fontSize: '32px',
    fontWeight: 500,
    lineHeight: '38px',
    marginTop: '16px',
  },
}));
