import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import SwitchAba from 'shared/components/aba/SwitchAba';
import { buscarDadosPermissaoUsuario } from 'features/login/redux/loginOperation';
import {
  DadosPermissaoColaborador,
  ParametrosTempo,
} from '../api/configuracoesApiTypes';
import AbasConfiguracoes, {
  OpcoesAbasConfiguracoes,
} from '../constants/AbasConfiguracoes';
import {
  buscarParametrosTempo,
  buscarPermissaoColaboradores,
} from '../utils/configuracoes';
import ConfiguracoesSistema from './configuracoesSistema/ConfiguracoesSistema';
import { useStyles } from '../styles/configuracoes';
import ConfiguracoesPerfil from './configuracoesPerfil/ConfiguracoesPerfil';

const Configuracoes = (): JSX.Element => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [colaboradores, setColaboradores] = useState<
    DadosPermissaoColaborador[]
  >([]);
  const [parametrosTempo, setParametrosTempo] = useState<ParametrosTempo>();
  const [abaSelecionada, setAbaSelecionada] = useState<AbasConfiguracoes>(
    AbasConfiguracoes.Perfil,
  );

  useEffect(() => {
    buscarDadosPermissaoUsuario()(dispatch);
  }, [dispatch]);

  useEffect(() => {
    buscarPermissaoColaboradores(setColaboradores, dispatch);
    buscarParametrosTempo(setParametrosTempo, dispatch);
  }, [dispatch]);

  return (
    <section className={classes.container}>
      <SwitchAba
        abas={OpcoesAbasConfiguracoes}
        idAbaInicial={AbasConfiguracoes.Perfil}
        onClickOpcaoSwitch={(aba) => {
          setAbaSelecionada(aba);
        }}
      />

      {abaSelecionada === AbasConfiguracoes.Perfil ? (
        <ConfiguracoesPerfil
          colaboradores={colaboradores}
          setColaboradores={setColaboradores}
        />
      ) : (
        <ConfiguracoesSistema
          parametrosTempo={parametrosTempo}
          setParametrosTempo={setParametrosTempo}
        />
      )}
    </section>
  );
};
export default Configuracoes;
