import React from 'react';
import CustomTooltip from '../tooltip/CustomTooltip';
import estilosTabela from './styles/estilosTabela';

export interface CelulaConteudoTextoProps {
  conteudo?: string;
}

const CelulaConteudoTexto = ({
  conteudo,
}: CelulaConteudoTextoProps): JSX.Element => {
  const { fonteConteudo } = estilosTabela();

  const conteudoValidado = conteudo || '--';

  return (
    <CustomTooltip title={conteudoValidado}>
      <td className={fonteConteudo}>{conteudoValidado}</td>
    </CustomTooltip>
  );
};

export default CelulaConteudoTexto;
