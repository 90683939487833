import { Dispatch } from 'redux';
import {
  formatarNumeroParaStringMonetariaComSimbolo,
  formatarValorMonetarioSimplificado,
} from 'shared/functions/dinheiroUtils';
import { converterValorEmDecimal } from 'shared/functions/numberUtils';
import { tratarValorPorcentagem } from 'shared/functions/porcentagemUtils';
import { IndicadorNegociacao } from '../api/indicadoresApiTypes';
import { obterIndicadoresPorPeriodo } from '../api/indicadoresManager';
import {
  ChaveIndicador,
  DadosOpcaoIndicador,
  opcoesIndicadores,
  PeriodoFiltroIndicadores,
  TipoValorIndicador,
} from '../constants/Constants';
import {
  atualizarIndicadoresSelecionados,
  resetarIndicadoresSelecionados,
} from '../redux/evolucaoDeCargas/evolucaoDeCargasOperation';
import {
  FormatoDadosGrafico,
  OpcaoSelectCustomizado,
} from '../types/PainelIndicadoresTypes';

export const getValoresPeriodo = (
  dadosIndicadores: IndicadorNegociacao[],
): string[] =>
  dadosIndicadores.map((dadosIndicador) => dadosIndicador?.periodo) || [];

export const formatarValoresDadosEvolucaoDeCargas = (
  valoresIndicadores: IndicadorNegociacao[],
): IndicadorNegociacao[] =>
  [...valoresIndicadores].map((indicador) => ({
    ...indicador,
    distancia: converterValorEmDecimal(indicador.distancia),
    peso: converterValorEmDecimal(indicador.peso),
    pesoPorEntrega: converterValorEmDecimal(indicador.pesoPorEntrega),
    despesaFrete: converterValorEmDecimal(indicador.despesaFrete),
    faturamento: converterValorEmDecimal(indicador.faturamento),
    ticketMedio: converterValorEmDecimal(indicador.ticketMedio),
    percentualRelativoBruto: converterValorEmDecimal(
      indicador.percentualRelativoBruto,
    ),
    valorAgregado: converterValorEmDecimal(indicador.valorAgregado),
    valorPorEntrega: converterValorEmDecimal(indicador.valorPorEntrega),
    valorPorPeso: converterValorEmDecimal(indicador.valorPorPeso),
    valorPorDistancia: converterValorEmDecimal(indicador.valorPorDistancia),
  }));

export const buscarDadosEvolucaoDeCargas = async (
  setDadosIndicadores: (indicadores: IndicadorNegociacao[]) => void,
  setValoresPeriodo: (periodo: string[]) => void,
  setErro: (erro: boolean) => void,
  periodo: PeriodoFiltroIndicadores,
): Promise<void> =>
  obterIndicadoresPorPeriodo(periodo)
    .then((response) => {
      setDadosIndicadores(formatarValoresDadosEvolucaoDeCargas(response));
      setValoresPeriodo(getValoresPeriodo(response));
      setErro(false);
    })
    .catch(() => {
      setErro(true);
    });

export const getListaSeletorIndicadores = (
  opcaoSelecionada: string,
): OpcaoSelectCustomizado[] =>
  Object.entries(opcoesIndicadores).map((opcao) => ({
    valor: opcao[1].chave,
    descricao: opcao[1].descricao,
    desabilitado: opcaoSelecionada === opcao[1].chave,
  }));

export const formatarDadoExibicaoDeAcordoComTipo = (
  tipoValor: TipoValorIndicador,
  dado: number,
  simplificarValorMonetario = false,
): string => {
  const dadoPadronizado = dado.toLocaleString('pt-BR');
  switch (tipoValor) {
    case TipoValorIndicador.DISTANCIA:
      return `${dadoPadronizado} Km`;
    case TipoValorIndicador.PESO:
      return `${dadoPadronizado} Kg`;
    case TipoValorIndicador.PORCENTAGEM:
      return tratarValorPorcentagem(dado);
    case TipoValorIndicador.MONETARIO:
      return simplificarValorMonetario
        ? formatarValorMonetarioSimplificado(dado)
        : formatarNumeroParaStringMonetariaComSimbolo(dado * 100);
    case TipoValorIndicador.UNIDADE:
    default:
      return dadoPadronizado;
  }
};

export const getDadosIndicador = (
  dadosIndicadores: IndicadorNegociacao[],
  chaveIndicador: string,
): number[] => {
  if (!dadosIndicadores.length) return [];
  const indexAtributoIndicador = Object.keys(dadosIndicadores[0]).findIndex(
    (key) => key === chaveIndicador,
  );
  return [...dadosIndicadores].map(
    (item) => Object.values(item)?.[indexAtributoIndicador],
  );
};

export const handleConfiguracaoFormatoDasEscalas = (
  tipoValor: TipoValorIndicador,
): FormatoDadosGrafico => {
  let style: 'currency' | 'percent' | 'unit' | 'decimal';
  let unit: 'kilometer' | 'kilogram' | undefined;
  switch (tipoValor) {
    case TipoValorIndicador.MONETARIO:
      style = 'currency';
      break;
    case TipoValorIndicador.PORCENTAGEM:
      style = 'percent';
      break;
    case TipoValorIndicador.DISTANCIA:
      style = 'unit';
      unit = 'kilometer';
      break;
    case TipoValorIndicador.PESO:
      style = 'unit';
      unit = 'kilogram';
      break;
    case TipoValorIndicador.UNIDADE:
    default:
      style = 'decimal';
      break;
  }

  return {
    currency: 'BRL',
    style,
    unit,
  };
};

export const getDadosOpcaoIndicadorPorChave = (
  chaveIndicador: ChaveIndicador,
): DadosOpcaoIndicador => {
  const [descricao, chave, tipoValor] = Object.values(
    opcoesIndicadores[chaveIndicador],
  );
  return {
    chave: chave as string,
    descricao: descricao as string,
    tipoValor: tipoValor as TipoValorIndicador,
  };
};

export const atualizarIndicadores = (
  indicador1: DadosOpcaoIndicador,
  indicador2: DadosOpcaoIndicador,
  dispatch: Dispatch,
): void => {
  atualizarIndicadoresSelecionados(indicador1, indicador2)(dispatch);
};

export const resetarIndicadores = (dispatch: Dispatch): void => {
  resetarIndicadoresSelecionados(dispatch);
};
