import { EnvioMensagemRequest } from '../api/chatNegociacaoApiTypes';

export const converterRequestParaFormData = (
  request: EnvioMensagemRequest,
): FormData => {
  const formRequest = new FormData();

  if (request.arquivo) formRequest.append('arquivo', request.arquivo);

  if (request.motivoRecusa)
    formRequest.append('motivoRecusa', request.motivoRecusa.toString());

  if (request.texto) formRequest.append('texto', request.texto);

  if (request.tipo) formRequest.append('tipo', request.tipo.toString());

  if (request.valorContraproposta)
    formRequest.append(
      'valorContraproposta',
      request.valorContraproposta.toString(),
    );

  return formRequest;
};
