import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';

import { spacingInPixels } from '../../functions/materialUtils';

export default withStyles((theme) => ({
  root: {
    paddingRight: spacingInPixels(theme, 1),
    paddingLeft: spacingInPixels(theme, 1),
  },
}))(TableCell);
