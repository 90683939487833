import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Rubik } from '../../../../shared/constants/font';
import theme from '../../../../theme';

export const BoxCardColum = withStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '33%',
    borderWidth: '1px',
    borderRightStyle: 'solid',
    borderColor: theme.palette.grey[100],
    padding: '0px 8px',
    minHeight: '120px',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))(Box);

export const BoxRota = withStyles(() => ({
  root: {
    padding: '10px',
    backgroundColor: theme.palette.cores.cinza[50],
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))(Box);

export const CardCinza = withStyles(() => ({
  root: {
    fontFamily: Rubik,
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 450,
    color: theme.palette.cores.cinza[900],
    textAlign: 'center',
    backgroundColor: theme.palette.background.default,
    borderRadius: 50,
    padding: '8px 16px 8px 16px',
    height: '33px',
    margin: '3px 2px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))(Typography);

export const TypographyValor = withStyles(() => ({
  root: {
    fontSize: '24px',
    fontFamily: Rubik,
    fontStyle: 'normal',
    lineHeight: '28px',
    fontWeight: 400,
  },
}))(Typography);

export const FundoLaranja = withStyles(() => ({
  root: {
    fontFamily: Rubik,
    fontStyle: 'normal',
    fontSize: '13px',
    lineHeight: '17px',
    fontWeight: 400,
    color: theme.palette.cores.cinza[900],
    textAlign: 'center',
    backgroundColor: theme.palette.cores.laranja[200],
    borderRadius: 50,
    padding: '5px 16px 10px 16px',
    height: '30px',
    margin: '5px 0px',
  },
}))(Typography);

export const TypographyTextoOpaco = withStyles(() => ({
  root: {
    fontFamily: Rubik,
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 400,
    color: theme.palette.cores.cinza[900],
    opacity: 0.5,
  },
}))(Typography);

export const TypographyTexto = withStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'start',
    fontFamily: Rubik,
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 400,
    color: theme.palette.cores.cinza[900],
  },
}))(Typography);

export const TypographyFilial = withStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'start',
    fontFamily: Rubik,
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 500,
    color: theme.palette.cores.cinza[900],
  },
}))(Typography);

export const TypographyTextoCentral = withStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'start',
    fontFamily: Rubik,
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 400,
    color: theme.palette.cores.cinza[900],
    padding: '10px 1px',
    alignItems: 'center',
  },
}))(Typography);
