import React from 'react';
import CelulaNegociador from 'shared/components/tabelaDemandas/CelulaNegociador';
import CelulaConteudoTexto from 'shared/components/tabelaDemandas/CelulaConteudoTexto';
import CelulaConteudoTextoComPorcentagem from 'shared/components/tabelaDemandas/CelulaConteudoTextoComPorcentagem';
import { tratarValorMonetario } from 'shared/functions/dinheiroUtils';
import { useStyles } from 'features/detalhesDemandasDiaria/styles/styleCorpoTabela';
import { tratarValorPorcentagem } from 'shared/functions/porcentagemUtils';
import { PartialConteudoDemandas } from 'features/detalhesDemandasDiaria/types/tabelaTypes';
import CelulaConteudoDataHora from 'shared/components/tabelaDemandas/CelulaConteudoDataHora';
import { estilosTabelaDemandasConcluidas } from '../../styles/styleTabela';
import ConteudoTarifario from './ConteudoTarifario';

const ConteudoTabelaDemandasConcluidas = ({
  demandas,
}: PartialConteudoDemandas): JSX.Element => {
  const classes = useStyles({ altura: true });
  const { gridTabela } = estilosTabelaDemandasConcluidas();
  const nomeRobo = 'R.A.N.A. - Robo Agregados de Negociações Automatizadas';
  return (
    <tbody className={classes.corpoTabela}>
      {demandas.map((demanda) => (
        <tr
          className={gridTabela}
          key={`${demanda.idNegociacao}-${demanda.romaneio}`}
        >
          <CelulaConteudoTexto conteudo={demanda.nomeFilial || '--'} />
          <CelulaNegociador nomeNegociador={demanda.nomeNegociador || '--'} />
          <CelulaConteudoTexto conteudo={demanda.romaneio || '--'} />
          {demanda.nomeNegociador === nomeRobo ? (
            <CelulaConteudoTextoComPorcentagem
              texto={tratarValorMonetario(demanda?.valorConclusao)}
            />
          ) : (
            <CelulaConteudoTextoComPorcentagem
              texto={tratarValorMonetario(demanda?.valorConclusao)}
              porcentagem={
                demanda?.savingValorConclusao
                  ? tratarValorPorcentagem(demanda?.savingValorConclusao)
                  : undefined
              }
            />
          )}
          <CelulaConteudoTexto conteudo={demanda.nomeCidade || '--'} />
          <CelulaConteudoDataHora conteudo={demanda.dataConclusao} />
          <td>
            <ConteudoTarifario
              idNegociacao={demanda.idNegociacao || 0}
              tarifarioCadastrado={Boolean(demanda?.tarifarioCadastrado)}
            />
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default ConteudoTabelaDemandasConcluidas;
