import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Box, makeStyles } from '@material-ui/core';

import { RootState } from '../../../../../store/reducer';

import BoxScroll from '../../../../../shared/components/box/BoxScroll';

import { MotoristaNegociacao } from '../../../api/informacoesNegociacaoApiTypes';

import MensagemPesquisaSemResultados from '../MensagemPesquisaSemResultados';

import MotoristaGrid from './MotoristaGrid';
import MensagemListaVazia from '../MensagemListaVazia';
import msgConstants from '../../../../../shared/constants/msgConstants';
import SkeletonListaMotoristas from './skeletons/SkeletonListaMotoristas';
import { removerAcentuacao } from '../../../../../shared/functions/stringUtils';

const motoristasPorPagina = 10;

const useStyles = makeStyles(() => ({
  containerMotoristas: {
    overflow: 'auto',
    height: 'calc(100vh - 320px)',
  },
}));

const MotoristasTab = (): JSX.Element => {
  const { motoristas } = useSelector(
    (state: RootState) => state.informacoesNegociacaoReducer,
  );

  const [motoristasFiltrados, setMotoristasFiltrados] = useState<
    MotoristaNegociacao[]
  >([]);

  const [motoristasApresentacao, setMotoristasApresentacao] = useState<
    MotoristaNegociacao[]
  >([]);

  const { pesquisa } = useSelector(
    (state: RootState) => state.motoristasCardReducer,
  );

  const [paginaAtual, setPaginaAtual] = useState(1);

  const classes = useStyles({
    listaVazia: !motoristas || motoristas.length === 0,
    pesquisaVazia: motoristasFiltrados.length === 0 && Boolean(pesquisa),
  });

  const adicionarMotoristaListaExibicao = (
    totalPaginasApresentar: number,
    motoristasFiltradosAuxiliar: MotoristaNegociacao[],
  ) => {
    const totalMotoristasApresentados =
      totalPaginasApresentar * motoristasPorPagina;
    setPaginaAtual(totalPaginasApresentar);
    setMotoristasApresentacao(
      motoristasFiltradosAuxiliar.slice(
        0,
        Math.min(
          motoristasFiltradosAuxiliar.length,
          totalMotoristasApresentados,
        ),
      ),
    );
  };

  const adicionarMotoristaListaExibicaoCallback = useCallback(
    adicionarMotoristaListaExibicao,
    [],
  );

  useEffect(() => {
    const motoristaFiltradosAuxiliar =
      motoristas?.filter((item) =>
        removerAcentuacao(item.nome.toLocaleLowerCase()).includes(
          removerAcentuacao(pesquisa.toLocaleLowerCase()),
        ),
      ) || new Array<MotoristaNegociacao>();
    setMotoristasFiltrados(motoristaFiltradosAuxiliar);

    adicionarMotoristaListaExibicaoCallback(1, motoristaFiltradosAuxiliar);
  }, [motoristas, pesquisa, adicionarMotoristaListaExibicaoCallback]);

  const atingiuFinalScroll = (
    scrollTop: number,
    containerHeight: number,
    scrollHeight: number,
  ) => ((scrollTop + containerHeight) / scrollHeight) * 100 === 100;

  const existemMotoristasParaApresentar =
    motoristasFiltrados.length > motoristasApresentacao.length;

  const scrollHandler = (event: React.UIEvent<HTMLDivElement>) => {
    const containerHeight = event.currentTarget.clientHeight;
    const { scrollHeight, scrollTop } = event.currentTarget;

    if (
      atingiuFinalScroll(scrollTop, containerHeight, scrollHeight) &&
      existemMotoristasParaApresentar
    )
      adicionarMotoristaListaExibicao(paginaAtual + 1, motoristasFiltrados);
  };

  const motoristasApresentados = useMemo(() => {
    return motoristasApresentacao.map((motorista, index) => (
      <MotoristaGrid
        key={motorista.id}
        sequencia={index + 1}
        motorista={motorista}
      />
    ));
  }, [motoristasApresentacao]);

  return (
    <>
      <BoxScroll>
        {motoristas === undefined ? (
          <SkeletonListaMotoristas />
        ) : (
          <Box className={classes.containerMotoristas} onScroll={scrollHandler}>
            {motoristas.length === 0 && (
              <MensagemListaVazia
                titulo={msgConstants.LISTA_MOTORISTAS_VAZIA}
              />
            )}

            {motoristasApresentados}
            {motoristasFiltrados.length === 0 && pesquisa && (
              <MensagemPesquisaSemResultados />
            )}
          </Box>
        )}
      </BoxScroll>
    </>
  );
};

export default MotoristasTab;
