import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import PainelPadrao from 'features/indicadores/components/PainelPadrao';
import { IndicadorNegociacao } from 'features/indicadores/api/indicadoresApiTypes';
import { useLoading } from 'shared/customHooks/useLoading';
import { buscarDadosEvolucaoDeCargas } from 'features/indicadores/utils/evolucaoDeCargas';
import { PeriodoFiltroIndicadores } from 'features/indicadores/constants/Constants';
import ConteudoEvolucaoDeCargas from './ConteudoEvolucaoDeCargas';
import SkeletonContainerGenerico from '../../../components/SkeletonContainerGenerico';

export const useStyles = makeStyles(() => ({
  container: {},
}));

const ContainerEvolucaoDeCargas = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [dadosIndicadores, setDadosIndicadores] = useState<
    IndicadorNegociacao[]
  >([]);
  const [erro, setErro] = useState(false);
  const [peridoAtual, setPeridoAtual] = useState(PeriodoFiltroIndicadores.WEEK);
  const [valoresPeriodo, setValoresPeriodo] = useState<string[]>([]);

  const handleChangePeriodo = (periodo: string | number) => {
    setPeridoAtual(periodo as PeriodoFiltroIndicadores);
  };

  const buscaDemandasCallback = useCallback(
    async () =>
      buscarDadosEvolucaoDeCargas(
        setDadosIndicadores,
        setValoresPeriodo,
        setErro,
        peridoAtual,
      ),
    [peridoAtual],
  );

  const [carregando, buscarDemandasLoading] = useLoading(buscaDemandasCallback);

  const handleClickTentarNovamente = () => {
    buscarDemandasLoading();
  };

  useEffect(() => {
    buscarDemandasLoading();
  }, [dispatch, buscarDemandasLoading]);

  return (
    <section className={classes.container}>
      <PainelPadrao
        titulo="Evolução de Cargas"
        onChangePeriodo={handleChangePeriodo}
      >
        {carregando ? (
          <SkeletonContainerGenerico
            apresentarPrimeiraBarra
            apresentarFooterBarras
          />
        ) : (
          <ConteudoEvolucaoDeCargas
            erro={erro}
            onClickTentarNovamente={handleClickTentarNovamente}
            dadosIndicadores={dadosIndicadores}
            valoresPeriodo={valoresPeriodo}
          />
        )}
      </PainelPadrao>
    </section>
  );
};

export default ContainerEvolucaoDeCargas;
