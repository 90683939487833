import spacingInPixels from 'shared/functions/materialUtils';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  caixaUpload: {
    borderRadius: '3px',
    backgroundColor: theme.palette.cores.branco,
    border: `2px dashed ${theme.palette.cores.azulMartins[700]}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: spacingInPixels(theme, 5),
    gap: spacingInPixels(theme, 3),
    minHeight: '45vh',
  },
  iconeBackup: {
    color: theme.palette.cores.azul[100],
    width: '35%',
    height: '35%',
  },
  iconeTask: {
    width: '35%',
    height: '35%',
  },
  texto: {
    color: theme.palette.cores.cinza[500],
  },
  nomeArquivo: {
    color: theme.palette.cores.cinza[900],
  },
  botaoBuscar: {
    width: '15%',
    minWidth: spacingInPixels(theme, 25),
    maxWidth: spacingInPixels(theme, 50),
    height: spacingInPixels(theme, 6),
    fontSize: '16px',
    float: 'right',
    textTransform: 'none',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    color: theme.palette.cores.azulMartins[500],
    transition: 'filter 0.5s ease-in-out',
    '&:hover': {
      color: theme.palette.cores.azulMartins[500],
      backgroundColor: 'transparent',
      filter: 'brightness(0.7)',
      boxShadow: 'none',
    },
  },
  labelInputArquivo: {
    cursor: 'pointer',
  },
}));
