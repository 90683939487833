import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import TableRow from '../../../shared/components/table/TableRowHeightSpacing6';
import { spacingInPixels } from '../../../shared/functions/materialUtils';
import TableCell from '../../../shared/components/table/TableCellCustomPadding';

const BoxItemTitulo = withStyles((theme) => ({
  root: {
    '& img': {
      marginRight: spacingInPixels(theme, 1),
    },
    '& span': {
      fontWeight: 500,
      color: theme.palette.primary.main,
    },
  },
}))(Box);

const TabelaTitulo = (): JSX.Element => {
  return (
    <TableRow>
      <TableCell>
        <BoxItemTitulo display="flex" alignItems="center">
          <img src="/images/minhasNegociacoes/icone-document.svg" alt="ID" />
          <span>CPF</span>
        </BoxItemTitulo>
      </TableCell>
      <TableCell align="left">
        <BoxItemTitulo display="flex" alignItems="center">
          <img
            src="/images/informacoesNegociacao/icone-motorista-azul.svg"
            alt="Filial"
          />
          <span>Motoristas</span>
        </BoxItemTitulo>
      </TableCell>
      <TableCell align="center" style={{ width: '13%' }}>
        <BoxItemTitulo
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <img
            src="/images/minhasNegociacoes/icone-codigo-martins.svg"
            alt="Status"
          />
          <span>Codigo Martins</span>
        </BoxItemTitulo>
      </TableCell>
      <TableCell align="left">
        <BoxItemTitulo display="flex" alignItems="center">
          <img
            src="/images/minhasNegociacoes/icone-phone.svg"
            alt="Cidade Principal"
          />
          <span>Telefone</span>
        </BoxItemTitulo>
      </TableCell>
      <TableCell align="center">
        <BoxItemTitulo display="flex" alignItems="center">
          <img src="/images/minhasNegociacoes/icone-filial.svg" alt="Status" />
          <span>Filial</span>
        </BoxItemTitulo>
      </TableCell>
      <TableCell align="center">
        <BoxItemTitulo
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <img src="/images/minhasNegociacoes/icone-car.svg" alt="Status" />
          <span>Veículo</span>
        </BoxItemTitulo>
      </TableCell>
      <TableCell align="center">
        <BoxItemTitulo
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <img src="/images/minhasNegociacoes/icone-status.svg" alt="Status" />
          <span>Placa</span>
        </BoxItemTitulo>
      </TableCell>
      <TableCell align="center" style={{ width: '13%' }}>
        <BoxItemTitulo
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <img
            src="/images/minhasNegociacoes/icone-circle-check.svg"
            alt="Cidade Principal"
          />
          <span>Frete Tabelado</span>
        </BoxItemTitulo>
      </TableCell>
      <TableCell align="center">
        <BoxItemTitulo
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <img
            src="/images/minhasNegociacoes/icone-check-quadrado.svg"
            alt="Cidade Principal"
          />
          <span>Status</span>
        </BoxItemTitulo>
      </TableCell>
    </TableRow>
  );
};

export default TabelaTitulo;
