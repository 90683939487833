import React from 'react';
import { DadoSkeleton } from 'shared/components/skeleton/CustomSkeleton';
import IconeCaixa from 'assets/icones/IconeCaixa';
import IconeRelogio from 'assets/icones/IconeRelogio';
import { DadosCabecalho } from 'shared/components/tabelaDemandas/types/TabelaTypes';
import theme from 'theme';
import { ChaveOrdenacaoDetalhesDemandas } from './Constants';

export const dadosSkeletonDemandasNaoIniciadas: DadoSkeleton[] = [
  { borderRadius: 50, width: 20 },
  { borderRadius: 4, width: '80%' },
  { borderRadius: 4, width: '90%' },
  { borderRadius: 4, width: '50%' },
  { borderRadius: 4, width: '90%' },
  { borderRadius: 4, width: '70%' },
  { borderRadius: 4, width: '60%' },
  { borderRadius: 4, width: '80%' },
];

export const dadosItensCabecalhoDemandasNaoIniciadas: DadosCabecalho[] = [
  { conteudo: '', descricao: '' },
  { conteudo: 'SLA', descricao: 'SLA' },
  {
    conteudo: 'Negociador',
    descricao: 'Negociador',
    possuiOrdenacao: true,
    chave: ChaveOrdenacaoDetalhesDemandas.NEGOCIADOR,
  },
  {
    conteudo: (
      <div style={{ paddingLeft: '1em' }}>
        <IconeRelogio
          width={18}
          height={18}
          fill={theme.palette.cores.cinza[800]}
        />
      </div>
    ),
    descricao: 'Lead time',
  },
  {
    conteudo: 'Filial',
    descricao: 'Filial',
    possuiOrdenacao: true,
    chave: ChaveOrdenacaoDetalhesDemandas.FILIAL,
  },
  { conteudo: 'Cidade', descricao: 'Cidade' },
  {
    conteudo: (
      <IconeCaixa
        width={18}
        height={18}
        fill={theme.palette.cores.cinza[800]}
      />
    ),
    descricao: 'Número de entregas',
    possuiOrdenacao: true,
    chave: ChaveOrdenacaoDetalhesDemandas.NUMERO_ENTREGAS,
  },
  { conteudo: 'Romaneio', descricao: 'Romaneio' },
];
