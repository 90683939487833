import { Box, Typography, withStyles } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';

export const TextoSimples = withStyles(() => ({
  root: {
    fontFamily: 'Roboto',
    fontSize: '16px',
  },
}))(Typography);

export const SubTitulo = withStyles(() => ({
  root: {
    fontFamily: 'Rubik',
    fontSize: '16px',
  },
}))(Typography);

export const ContainerBotoes = withStyles(() => ({
  root: {
    display: `flex`,
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    gap: '32px',
  },
}))(Box);

export const ContainerPermissoes = withStyles((theme) => ({
  root: {
    paddingTop: `32px`,
    display: `flex`,
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: `16px`,
    maxWidth: spacingInPixels(theme, 53),
  },
}))(Box);

export const ContainerInferior = withStyles(() => ({
  root: {
    display: `flex`,
    minHeight: `416px`,
    paddingTop: `32px`,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}))(Box);

export const ContainerSuperior = withStyles((theme) => ({
  root: {
    display: `flex`,
    minHeight: `176px`,
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 30,
    paddingTop: spacingInPixels(theme, 4),
  },
}))(Box);

export const ContainerChip = withStyles(() => ({
  root: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '8px',
  },
}))(Box);

export const ContainerInputIntervaloTempo = withStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'fle',
    gap: '16px',
  },
}))(Box);

export const DescricaoInputIntervaloTempo = withStyles(() => ({
  root: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 400,
  },
}))(Typography);
