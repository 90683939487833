import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import IconeFileDownload from 'assets/icones/IconeFileDownload';
import { CircularProgress, Typography } from '@material-ui/core';
import { Rubik } from 'shared/constants/font';
import { buscarDadosPermissaoUsuario } from 'features/login/redux/loginOperation';
import Titulo from '../../../shared/components/titulo/Titulo';
import BoxPagina from '../../../shared/components/box/BoxPagina';
import TituloAcompanhamentoNegociacoes from './TituloAcompanhamentoNegociacoes';
import Button from '../../../shared/components/button/ButtonTextTransformNone';

import { getPlanilha as getPlanilhaManager } from '../api/acompanhamentoNegociacoesManager';

import spacingInPixels from '../../../shared/functions/materialUtils';
import { limparDados } from '../redux/acompanhamentoNegociacoesAction';
import { abrirSnackbarErro } from '../../../shared/components/snackbar/redux/snackbarAction';

import { RootState } from '../../../store/reducer';
import DisablingTooltip from '../../../shared/components/tooltip/DisablingTooltip';

const useStyles = makeStyles((theme) => ({
  botaoPlanilha: {
    minWidth: spacingInPixels(theme, 22),
    maxWidth: spacingInPixels(theme, 50),
    height: spacingInPixels(theme, 5),
    fontSize: '16px',
    marginTop: spacingInPixels(theme, 3),
    marginBottom: spacingInPixels(theme, 3),
    borderRadius: '30px',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10%',
    flexDirection: 'column',
  },
  textoDescricaoContainer: {
    width: '30%',
    textAlign: 'center',
  },
  textoDescricao: {
    fontFamily: Rubik,
    fontWeight: 400,
    fontSize: 16,
  },
}));

const AcompanhamentoNegociacoes = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { dataFim, dataInicio, dataInicioValida, dataFimValida } = useSelector(
    (reducer: RootState) => reducer.acompanhamentoNegociacoesReducer,
  );

  useEffect(() => {
    buscarDadosPermissaoUsuario()(dispatch);
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(limparDados());
    };
  }, [dispatch]);

  const dispararSnackbarErro = (erro: string) => {
    dispatch(
      abrirSnackbarErro({
        mensagem: erro,
      }),
    );
  };

  const getPlanilha = async () => {
    setLoading(true);
    await getPlanilhaManager(dataInicio, dataFim).catch((erro: Error) => {
      dispararSnackbarErro(erro.message);
    });
    setLoading(false);
  };

  return (
    <BoxPagina component="section">
      <TituloAcompanhamentoNegociacoes />
      <div className={classes.container}>
        <IconeFileDownload width={120} height={120} fill="#BBDEFB" />
        <Titulo titulo="Download das negociações" />
        <div className={classes.textoDescricaoContainer}>
          <Typography className={classes.textoDescricao}>
            Acompanhe suas negociações através da planilha. Clique para fazer
            download
          </Typography>
        </div>
        {!loading ? (
          <>
            <DisablingTooltip
              disabled={!dataFimValida || !dataInicioValida}
              title="Fazer download"
            >
              <Button
                variant="contained"
                aria-label="Fazer download"
                color="primary"
                disabled={!dataFimValida || !dataInicioValida}
                onClick={getPlanilha}
                className={classes.botaoPlanilha}
                type="submit"
              >
                Fazer download
              </Button>
            </DisablingTooltip>
          </>
        ) : (
          <CircularProgress
            style={{ width: '25px', height: '25px', marginTop: '20px' }}
            color="secondary"
          />
        )}
      </div>
    </BoxPagina>
  );
};

export default AcompanhamentoNegociacoes;
