import { Dispatch } from 'redux';
import { fecharNotificacoes as fecharNotificacoesAction } from '../redux/notificacoesAction';

import {
  obterPaginaNotificacoes,
  obterPrimeiraPaginaNotificacoes,
} from '../redux/notificacoesOperation';

export const MilissegundosAtualizacaoNotificacoes = 10000;

export const criarPollingNotificacoes = (dispatch: Dispatch): number => {
  obterPaginaNotificacoes(1)(dispatch);

  return Number(
    setInterval(async () => {
      await obterPrimeiraPaginaNotificacoes()(dispatch);
    }, MilissegundosAtualizacaoNotificacoes),
  );
};

export const fecharNotificacoes = (dispatch: Dispatch): void => {
  dispatch(fecharNotificacoesAction());
  obterPaginaNotificacoes(1)(dispatch);
};
