import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducer';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';
import {
  descricaoFiltroNotificacao,
  MENSAGEM_ERRO_LISTAGEM_NOTIFICACOES,
  MENSAGEM_LISTAGEM_NOTIFICACOES_VAZIA,
} from 'features/dashboards/constants/Constants';
import FiltrosNotificacoes, { FiltroNotificacao } from './FiltrosNotificacoes';
import TabelaNegociacoes from '../TabelaNegociacoes';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: spacingInPixels(theme, 2.5),
  },
  filtros: {
    width: '100%',
    height: spacingInPixels(theme, 3.5),
    '@media (max-height: 700px)': {
      position: 'sticky',
      top: 0,
    },
    marginBottom: spacingInPixels(theme, 4.5),
  },
}));

const estiloTabelaDemandas = { height: 'calc(90vh - 270px)' };

interface ContainerListagemNotificacoesProps {
  carregando: boolean;
}

const ContainerListagemNotificacoes = ({
  carregando,
}: ContainerListagemNotificacoesProps): JSX.Element => {
  const classes = useStyles();
  const { notificacoesNegociadorPorCategoria, erro } = useSelector(
    (reducer: RootState) => reducer.listagemDemandasNegociadorReducer,
  );

  const [filtroAtual, setFiltroAtual] = useState(
    descricaoFiltroNotificacao.todas,
  );
  const [notificacoes, setNotificacoes] = useState(
    notificacoesNegociadorPorCategoria?.get(descricaoFiltroNotificacao.todas),
  );

  const filtros = useMemo(() => {
    const dadosFiltros: FiltroNotificacao[] = [];

    notificacoesNegociadorPorCategoria?.forEach((demandas, label) => {
      const filtro = {
        label: label as descricaoFiltroNotificacao,
        quantidade:
          label !== descricaoFiltroNotificacao.todas ? demandas.length : null,
      };
      dadosFiltros.push(filtro);
    });
    return dadosFiltros;
  }, [notificacoesNegociadorPorCategoria]);

  useMemo(() => {
    setNotificacoes(
      notificacoesNegociadorPorCategoria?.get(descricaoFiltroNotificacao.todas),
    );
  }, [notificacoesNegociadorPorCategoria]);

  const handleClickSelecaoFiltro = (
    filtroSelecionado: descricaoFiltroNotificacao,
  ) => {
    setFiltroAtual(filtroSelecionado);
    setNotificacoes(
      notificacoesNegociadorPorCategoria?.get(filtroSelecionado) || [],
    );
  };

  return (
    <Grid container direction="column" className={classes.container}>
      <Grid item className={classes.filtros}>
        <FiltrosNotificacoes
          carregando={carregando}
          filtroAtual={filtroAtual}
          filtros={filtros}
          onClickFiltro={handleClickSelecaoFiltro}
        />
      </Grid>
      <Grid item>
        <TabelaNegociacoes
          carregando={carregando}
          demandas={notificacoes}
          estilo={estiloTabelaDemandas}
          mensagemErro={
            erro
              ? MENSAGEM_ERRO_LISTAGEM_NOTIFICACOES
              : MENSAGEM_LISTAGEM_NOTIFICACOES_VAZIA
          }
        />
      </Grid>
    </Grid>
  );
};

export default ContainerListagemNotificacoes;
