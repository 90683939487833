import React, { ReactElement } from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core';

export type DisablingTooltipProps = {
  maxWidth?: string;
  title?: string;
  children: ReactElement;
};

const CustomWidthTooltip = ({
  title,
  children,
  maxWidth = '584px',
}: DisablingTooltipProps): JSX.Element => {
  const useStyles = makeStyles({
    tooltip: {
      maxWidth,
    },
  });

  const classes = useStyles();

  return (
    <Tooltip title={title || ''} classes={{ tooltip: classes.tooltip }}>
      {children}
    </Tooltip>
  );
};

export default CustomWidthTooltip;
