import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';
import { IndicadorNegociacao } from 'features/indicadores/api/indicadoresApiTypes';
import { opcoesIndicadores } from 'features/indicadores/constants/Constants';
import {
  formatarValorExibicaoIndicadorDeAcordoComTipo,
  getValoresIndicador,
} from 'features/indicadores/utils/indicadoresMacros';
import CustomTooltip from 'shared/components/tooltip/CustomTooltip';

interface TabelaCssProps {
  numeroColunas: number;
}

export const useStyles = makeStyles<Theme, TabelaCssProps>((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacingInPixels(theme, 2.5),
    minHeight: 428,
    minWidth: ({ numeroColunas }) =>
      numeroColunas > 8 ? numeroColunas * 120 : 1124,
    marginTop: spacingInPixels(theme, 3),
  },
  gridTabela: {
    display: 'grid',
    gridTemplateColumns: ({ numeroColunas }) =>
      numeroColunas > 8
        ? `10% repeat(${numeroColunas}, ${90 / numeroColunas}%)`
        : `15% repeat(${numeroColunas}, ${85 / numeroColunas}%)`,
  },
  cabecalho: {
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontSize: spacingInPixels(theme, 1.75),
    lineHeight: spacingInPixels(theme, 1.75),
    letterSpacing: '-0.2px',
    textAlign: 'left',
  },
  textoCabecalhoIndicador: {
    fontWeight: 500,
  },
  textoCabecalhoPeriodos: {
    fontWeight: 600,
    color: theme.palette.cores.azulMartins[500],
  },
  linhaConteudoTabela: {
    '& tr': {
      padding: `${spacingInPixels(theme, 1.5)} 0`,
      borderBottom: `solid 0.5px ${theme.palette.cores.cinza[400]}`,
    },
    '& tr:last-child': {
      borderBottom: 'none',
    },
  },
  textoConteudoTabela: {
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: spacingInPixels(theme, 1.75),
    lineHeight: spacingInPixels(theme, 1.5),
    letterSpacing: '-0.2px',
    color: theme.palette.cores.cinza[800],
  },
}));

interface TabelaIndicadoresMacrosProps {
  valoresPeriodo: string[];
  dadosIndicadores: IndicadorNegociacao[];
}

const TabelaIndicadoresMacros = ({
  valoresPeriodo,
  dadosIndicadores,
}: TabelaIndicadoresMacrosProps): JSX.Element => {
  const classes = useStyles({ numeroColunas: valoresPeriodo.length });
  return (
    <table className={classes.container}>
      <thead>
        <tr className={`${classes.gridTabela} ${classes.cabecalho}`}>
          <th className={classes.textoCabecalhoIndicador}>Indicador</th>
          {valoresPeriodo.map((periodo, index) => (
            <th
              key={`${periodo}-col-${index + 1}`}
              className={classes.textoCabecalhoPeriodos}
            >
              {periodo}
            </th>
          ))}
        </tr>
      </thead>
      <tbody
        className={`${classes.textoConteudoTabela} ${classes.linhaConteudoTabela}`}
      >
        {Object.values(opcoesIndicadores).map((indicador) => {
          const valoresIndicador = getValoresIndicador(
            indicador,
            dadosIndicadores,
          );
          return (
            <tr key={indicador.chave} className={`${classes.gridTabela}`}>
              <td>{indicador.descricao}</td>
              {valoresIndicador.map((valor, index) => (
                <td key={`coluna-${index + 1}-${indicador.chave}`}>
                  <CustomTooltip
                    title={formatarValorExibicaoIndicadorDeAcordoComTipo(
                      indicador.tipoValor,
                      valor,
                    )}
                    placement="top-start"
                    arrow
                  >
                    <span>
                      {formatarValorExibicaoIndicadorDeAcordoComTipo(
                        indicador.tipoValor,
                        valor,
                        true,
                      )}
                    </span>
                  </CustomTooltip>
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default TabelaIndicadoresMacros;
