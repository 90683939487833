import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import IconeChatNegado from 'assets/icones/IconeChatNegado';
import { Rubik } from '../../../../shared/constants/font';

type MensagemListaVaziaProps = {
  titulo: string;
  subtitulo?: string;
};

const useStyles = makeStyles((theme) => ({
  texto: {
    color: theme.palette.cores.cinza[500],
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: Rubik,
    maxWidth: 375,
    textAlign: 'center',
  },
}));

const MensagemListaVazia = ({
  titulo,
  subtitulo,
}: MensagemListaVaziaProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      width="100%"
      height="100%"
    >
      <Box marginBottom="24px">
        <IconeChatNegado />
      </Box>
      <Box marginBottom="24px">
        <Typography className={classes.texto} variant="h5">
          {titulo}
        </Typography>
      </Box>
      <Box>
        <Typography className={classes.texto} variant="h5">
          {subtitulo}
        </Typography>
      </Box>
    </Box>
  );
};

MensagemListaVazia.defaultProps = {
  subtitulo: '',
};

export default MensagemListaVazia;
