import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import { RootState } from 'store/reducer';
import RemetenteMensagemProposta from 'shared/constants/RemetenteMensagemProposta';
import PropostaStatus, {
  verificarPropostaPendenteSupervisor,
  verificarPropostaRecusada,
} from 'shared/constants/PropostaStatus';
import TipoMensagemProposta from 'shared/constants/TipoMensagemProposta';
import { OrigemNegociacao } from 'features/informacoesNegociacao/api/informacoesNegociacaoApiTypes';
import ModalComum from 'shared/components/modalComum/ModalComum';
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { Star } from '@material-ui/icons';
import { salvarAvaliacaoAgregado } from 'features/chatNegociacao/api/chatNegociacaoManager';
import { AvaliarAgregadoRequest } from 'features/chatNegociacao/api/chatNegociacaoApiTypes';
import {
  abrirSnackbarAviso,
  abrirSnackbarErro,
  abrirSnackbarSucesso,
} from 'shared/components/snackbar/redux/snackbarAction';
import RespostaAposAcaoNegociador from './RespostaAposAcaoNegociador';
import RespostaAposAcaoMotorista from './RespostaAposAcaoMotorista';
import { ocultarCaixaResposta } from '../../utils/chatResposta';
import useStyles from '../../styles/chatResposta';
import ReabrirNegociacao from './ReabrirNegociacao';
import { Recusar } from '../../redux/chatNegociacaoReducer';
import RespostaAposAcaoSupervisor from './RespostaAposAcaoSupervisor';
import RecusaProposta from './RespostaContraproposta/RecusaProposta';

const MotivoScore = [
  { chave: 0, valor: 'Demora Para Responder' },
  { chave: 1, valor: 'Não Atende Ligações' },
  { chave: 2, valor: 'Agregado Rude' },
  { chave: 3, valor: 'Agregado Cordial' },
  { chave: 4, valor: 'Não Responde Pelo App' },
  { chave: 5, valor: 'Negociação Fácil' },
  { chave: 6, valor: 'Negociação Difícil' },
];

const ChatResposta = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [abriModal, setAbrirModal] = useState(false);
  const [score, setScore] = useState(0);
  const [hoverValue, setHoverValue] = useState(0);
  const [motivo, setMotivo] = useState<number | null>();

  const {
    mensagemReferencia,
    chatPropostaStatus,
    chatExibicaoRespostaNegociador,
  } = useSelector(
    ({ chatNegociacaoReducer }: RootState) => chatNegociacaoReducer,
  );

  const {
    negociacaoConcluida,
    negociacao,
    idPropostaComFoco,
    propostasAplicativo,
  } = useSelector(
    ({ informacoesNegociacaoReducer }: RootState) =>
      informacoesNegociacaoReducer,
  );

  const { ativandoAmbienteAssistido } = useSelector(
    (state: RootState) => state.minhasNegociacoesReducer,
  );

  if (ativandoAmbienteAssistido) {
    return <></>;
  }

  const exibirCardReaberturaContraproposta =
    chatExibicaoRespostaNegociador === Recusar &&
    chatPropostaStatus === PropostaStatus.RECUSA_NEGOCIADOR;

  if (
    chatExibicaoRespostaNegociador === Recusar &&
    chatPropostaStatus !== PropostaStatus.RECUSA_NEGOCIADOR
  ) {
    return (
      <Box className={classes.boxResposta}>
        <Box className={classes.boxRespostaInterno}>
          <RecusaProposta />
        </Box>
      </Box>
    );
  }

  const retorneEstilo = () => {
    switch (mensagemReferencia?.tipo) {
      case TipoMensagemProposta.Aceite:
        if (negociacaoConcluida)
          return `${classes.boxRespostaInterno} ${classes.boxAceite}`;
        return classes.boxRespostaInterno;
      case TipoMensagemProposta.Recusa:
        return `${classes.boxRespostaInterno} ${classes.boxRecusa}`;
      default:
        return classes.boxRespostaInterno;
    }
  };

  if (
    ocultarCaixaResposta(mensagemReferencia?.tipo) ||
    mensagemReferencia?.tipo === TipoMensagemProposta.AvaliacaoSupervisor ||
    verificarPropostaPendenteSupervisor(chatPropostaStatus)
  ) {
    return <></>;
  }

  const montarComponentes = () => {
    if (
      mensagemReferencia?.remetente === RemetenteMensagemProposta.Supervisor &&
      negociacaoConcluida
    ) {
      return <RespostaAposAcaoNegociador />;
    }

    switch (mensagemReferencia?.remetente) {
      case RemetenteMensagemProposta.Motorista:
        return <RespostaAposAcaoMotorista />;
      case RemetenteMensagemProposta.Supervisor:
        return <RespostaAposAcaoSupervisor />;
      case RemetenteMensagemProposta.Negociador:
      default:
        return <RespostaAposAcaoNegociador />;
    }
  };

  if (
    negociacao?.origemNegociacao === OrigemNegociacao.freteTabelado &&
    mensagemReferencia?.tipo === TipoMensagemProposta.Aceite
  ) {
    return (
      <Box className={classes.boxRespostaFreteTabelado}>
        <Box
          className={`${classes.boxRespostaInterno} ${classes.boxAceiteFreteTabelado}`}
        >
          <RespostaAposAcaoNegociador />
        </Box>
      </Box>
    );
  }
  if (
    negociacao?.origemNegociacao === OrigemNegociacao.freteTabelado &&
    mensagemReferencia?.tipo === TipoMensagemProposta.Recusa
  ) {
    return <></>;
  }

  const handleMouseOver = (value: number) => {
    setHoverValue(value);
  };

  const handleMouseLeave = () => {
    setHoverValue(0);
  };

  const handleClick = (value: number) => {
    setScore(value);
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setMotivo(event.target.value as number);
  };

  const handleEnviarAvaliacao = async (): Promise<void> => {
    const proposta = propostasAplicativo.find(
      (item) => item.id === idPropostaComFoco,
    );

    if (motivo && score && idPropostaComFoco && proposta) {
      const request: AvaliarAgregadoRequest = {
        propostaId: idPropostaComFoco,
        motoristaId: proposta.idMotorista,
        score,
        motivoScore: motivo,
      };

      try {
        await salvarAvaliacaoAgregado(request);
        dispatch(
          abrirSnackbarSucesso({ mensagem: 'Avaliação enviada com sucesso' }),
        );
        setScore(0);
        setMotivo(null);
        setAbrirModal(false);
      } catch (error) {
        dispatch(abrirSnackbarErro({ mensagem: 'Erro ao enviar avaliação' }));
      }
    } else {
      dispatch(
        abrirSnackbarAviso({ mensagem: 'Motivo e Score são obrigatórios' }),
      );
    }
  };

  const renderStars = () => {
    const stars = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <>
          <IconButton
            onMouseOver={() => handleMouseOver(i)}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleClick(i)}
            size="small"
          >
            {i <= (hoverValue || score) ? (
              <Star style={{ color: 'yellow', fontSize: '38px' }} />
            ) : (
              <Star style={{ fontSize: '38px' }} />
            )}
          </IconButton>
        </>,
      );
    }
    return stars;
  };

  return (
    <>
      <Box className={classes.boxResposta}>
        <Box className={retorneEstilo()}>{montarComponentes()}</Box>
      </Box>
      {!negociacaoConcluida &&
        verificarPropostaRecusada(chatPropostaStatus) &&
        !exibirCardReaberturaContraproposta &&
        React.createElement(ReabrirNegociacao)}
      {(mensagemReferencia?.tipo === TipoMensagemProposta.Recusa ||
        mensagemReferencia?.tipo === TipoMensagemProposta.Aceite) && (
        <Box
          className={classes.boxRespostaBotao}
          onClick={() => setAbrirModal(true)}
        >
          <Box className={classes.botaoAvaliacao}>Avaliar agregado</Box>
        </Box>
      )}
      <ModalComum
        titulo="Avaliação do agregado"
        conteudo={
          <Box style={{ marginTop: '-15px', marginLeft: '-10px' }}>
            <FormControl fullWidth variant="outlined" margin="normal">
              <InputLabel id="motivo-select-label">Motivo</InputLabel>
              <Select
                labelId="motivo-select-label"
                id="motivo-select"
                value={motivo}
                onChange={handleChange}
                label="Motivo"
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 48 * 4.5 + 8,
                      width: 250,
                    },
                  },
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                {MotivoScore.map((motivoScoreAgregado) => (
                  <MenuItem
                    key={motivoScoreAgregado.chave}
                    value={motivoScoreAgregado.chave}
                  >
                    {motivoScoreAgregado.valor}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography
              style={{
                marginTop: '10px',
                color: '#212121',
                fontWeight: 400,
              }}
            >
              Dê uma nota para esse agregado:
            </Typography>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                padding: '2px',
                marginBottom: '10px',
              }}
            >
              {renderStars()}
            </Box>
            <Button
              style={{ float: 'right', marginBottom: '10px' }}
              variant="contained"
              color="primary"
              onClick={handleEnviarAvaliacao}
              disabled={motivo === undefined && score === 0}
            >
              Avaliar
            </Button>
          </Box>
        }
        modalAberto={abriModal}
        fecharModal={() => setAbrirModal(false)}
        exibirBotaoFechar
      />
    </>
  );
};

export default ChatResposta;
