import React from 'react';

import Badge from '@material-ui/core/Badge';
import ButtonBase from '@material-ui/core/ButtonBase';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded';
import ExpandLessIcon from '@material-ui/icons/ExpandLessRounded';
import Typography from '@material-ui/core/Typography';
import { withStyles, useTheme } from '@material-ui/core';

import { spacingInPixels } from '../../functions/materialUtils';
import DisablingTooltip from '../tooltip/DisablingTooltip';

export type ButtonDropdownProps = {
  'aria-describedby': string | undefined;
  texto: string;
  titulo: string;
  invisible: boolean;
  open?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const Button = withStyles((theme) => ({
  root: {
    '& span': {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: spacingInPixels(theme, 2),
    },
  },
}))(ButtonBase);

const ButtonDropdown = ({
  texto,
  titulo,
  open,
  invisible,
  'aria-describedby': ariaDescribedby,
  onClick,
}: ButtonDropdownProps): JSX.Element => {
  const theme = useTheme();
  return (
    <DisablingTooltip title={titulo}>
      <Button aria-describedby={ariaDescribedby} onClick={onClick}>
        <Badge color="primary" variant="dot" invisible={invisible}>
          <Typography
            component="span"
            style={{
              paddingRight: spacingInPixels(theme, 1),
              color: open
                ? theme.palette.primary.main
                : theme.palette.text.secondary,
            }}
          >
            {texto}
          </Typography>
        </Badge>
        {open ? (
          <ExpandLessIcon color="primary" fontSize="large" />
        ) : (
          <ExpandMoreIcon
            fontSize="large"
            style={{
              color: theme.palette.cores.cinza[500],
            }}
          />
        )}
      </Button>
    </DisablingTooltip>
  );
};

export default ButtonDropdown;
