import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import spacingInPixels from '../../functions/materialUtils';
import IconePreenchido from '../icons/IconePreenchido';

export type BigNumberSimplesProps = {
  icone: React.ReactElement;
  cor?: string;
  numero?: number;
  descricao: string;
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
    gridTemplateRows: 'repeat(2,min-content)',
    gridColumnGap: spacingInPixels(theme, 1),
    placeItems: 'center',
    backgroundColor: theme.palette.cores.branco,
    padding: spacingInPixels(theme, 1),
    borderRadius: spacingInPixels(theme, 1),
    boxShadow: '0px 1px 6px 0px rgba(0, 0, 0, 0.09)',
  },
  numero: {
    fontWeight: 500,
    fontSize: 16,
    color: theme.palette.cores.cinza[800],
    gridColumnStart: 2,
    justifySelf: 'start',
  },
  descricao: {
    fontWeight: 400,
    fontSize: 12,
    color: theme.palette.cores.cinza[700],
    letterSpacing: '0.4px',
    gridColumnStart: 2,
    justifySelf: 'start',
  },
}));

const BigNumberSimples = ({
  icone,
  cor,
  numero,
  descricao,
}: BigNumberSimplesProps): JSX.Element => {
  const classes = useStyles();
  return (
    <article className={classes.container}>
      <IconePreenchido icone={icone} cor={cor} />
      <Typography className={classes.numero}>
        {numero?.toLocaleString() || '--'}
      </Typography>
      <Typography className={classes.descricao}>{descricao}</Typography>
    </article>
  );
};

export default BigNumberSimples;
