import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  boxTemporizador: {
    display: 'flex',
    alignItems: 'center',
    width: '77px',
    height: '34px',
    borderRadius: '4px',
    background: '#2E7D32',
  },
  boxTemporizadorVermelho: {
    display: 'flex',
    alignItems: 'center',
    width: '77px',
    height: '34px',
    borderRadius: '4px',
    background: '#ff4c4c',
  },
  boxContentTemporizador: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '10px',
    padding: '7px',
  },
  boxContentTemporizadorInvisivel: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '10px',
    padding: '7px 7px 7px 0',
  },
  textInvisivel: {
    color: '#F44336',
    fontSize: '15px',
    fontWeight: 500,
  },
  textTemporizador: {
    color: '#ffffff',
    fontSize: '12px',
    fontWeight: 500,
  },
  textDinheiro: {
    fontSize: '14px',
    color: '#008C41',
    fontWeight: 500,
    marginLeft: '2px',
  },
}));
