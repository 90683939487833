export const HEADER_ALTERNATIVO_INFORMACOES_NEGOCIACAO = [
  {
    titulo: 'Voltar',
    link: '/minhas-negociacoes',
    boataoVoltar: true,
  },
  {
    titulo: 'Minhas negociações',
    link: '/portal/minhas-negociacoes',
  },
  { titulo: 'Painel de negociação', link: '', textoOn: true },
];
