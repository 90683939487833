import { Box, Divider } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ConfiguracoesSistemaProps } from 'features/configuracoes/types';
import SwitchAcesso from 'shared/components/switch/SwitchAcesso';
import {
  buscarParametrosTempo,
  salvarParametrosTempo,
} from 'features/configuracoes/utils/configuracoes';
import { IntervaloTempo } from 'shared/types/apiTypes';
import Header from '../../components/Header';
import SecaoConfiguracao from '../../components/SecaoConfiguracao';
import { TextoSimples } from '../../styles';
import { useStyles } from '../../styles/configuracoesSistema';
import PercentualPropostaFilial from './percentualPropostaFilial/PercentualPropostaFilial';
import TorreControle from './torreControle/TorreControle';
import CategoriaVeiculos from './categoriaVeiculosFreteTabelado/CategoriaVeiculos';
import AtributosFreteTabeladoRegiao from './freteTabeladoRegiao/AtributosFreteTabeladoRegiao';
import ContainerEsquemaColaboradores from './esquemaColaboradores/ContainerEsquemaColaboradores';
import ConfiguracaoMacrofilial from './configuracaoMacrofilial/ConfiguracaoMacrofilial';
import ConfiguracaoFilialAlgoritmo from './configuracaoFilialAlgoritmo/ConfiguracaoFilialAlgoritmo';

const ConfiguracoesSistema = ({
  parametrosTempo,
  setParametrosTempo,
}: ConfiguracoesSistemaProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [sugestaoRoboHabilitada, setSugestaoRoboHabilitada] = useState(
    parametrosTempo?.sugestaoRoboHabilitada || false,
  );

  const [useFreteTabeladoHabilitado, setFreteTabeladoHabilitado] = useState(
    parametrosTempo?.isFreteTabeladoHabilitado || false,
  );

  const handleSalvarSugestaoRobo = (valor: boolean) => {
    salvarParametrosTempo(
      valor,
      useFreteTabeladoHabilitado,
      parametrosTempo?.tempoLimiteSemVisualizarNegociacao as IntervaloTempo,
      parametrosTempo?.tempoLimiteSemAprovacao as IntervaloTempo,
      parametrosTempo?.tempoLimiteCargaPrioridadeMaxima as IntervaloTempo,
      dispatch,
    );
    setSugestaoRoboHabilitada(valor);
    if (setParametrosTempo) {
      buscarParametrosTempo(setParametrosTempo, dispatch);
    }
  };

  const handleSalvarFreteTabelado = (valor: boolean) => {
    salvarParametrosTempo(
      sugestaoRoboHabilitada,
      valor,
      parametrosTempo?.tempoLimiteSemVisualizarNegociacao as IntervaloTempo,
      parametrosTempo?.tempoLimiteSemAprovacao as IntervaloTempo,
      parametrosTempo?.tempoLimiteCargaPrioridadeMaxima as IntervaloTempo,
      dispatch,
    );
    setFreteTabeladoHabilitado(valor);
    if (setParametrosTempo) {
      buscarParametrosTempo(setParametrosTempo, dispatch);
    }
  };

  return (
    <Box className={classes.container}>
      <Header titulo="Configurações do Sistema" />{' '}
      <TextoSimples>
        Acesso a configurações gerais do sistema de gestão de cargas.
      </TextoSimples>
      <Divider orientation="horizontal" />
      <Box className={classes.boxSecaoConfig}>
        <Box className={classes.boxFitContent}>
          <Header
            titulo="Habilitar sugestão do Algoritmo"
            className={classes.titulo}
          />
          <Box style={{ width: 'fit-content' }}>
            <SwitchAcesso
              titulo="Configuração para receber sugestão do Algoritmo"
              permissaoAcesso={sugestaoRoboHabilitada}
              setPermissaoAcesso={() =>
                handleSalvarSugestaoRobo(!sugestaoRoboHabilitada)
              }
            />
          </Box>
        </Box>
        <SecaoConfiguracao
          titulo="Configuração filial Algoritmo"
          subtitulo="Acesso para configurar permissão da susgestão de valores das filiais"
        >
          <ConfiguracaoFilialAlgoritmo />
        </SecaoConfiguracao>
        <SecaoConfiguracao
          titulo="Torre de Controle"
          subtitulo="Acesso a configuração geral da Torre de controle"
        >
          <TorreControle sugestaoRoboHabilitada={sugestaoRoboHabilitada} />
        </SecaoConfiguracao>
        <SecaoConfiguracao
          titulo="Percentual de proposta por filial"
          subtitulo="Acesso para configurar o percentual de proposta da negociação"
        >
          <PercentualPropostaFilial />
        </SecaoConfiguracao>
        <SecaoConfiguracao
          titulo="Configurar categoria de veículos"
          subtitulo="Acesso para configurar a capacidade das categoria dos veículos"
        >
          <CategoriaVeiculos />
        </SecaoConfiguracao>
        <SecaoConfiguracao
          titulo="Configurar frete tabelado por região"
          subtitulo="Acesso para configurar os atributos do frete tabelado"
          switchFreteTabeladoAtivar
          switchChecked={useFreteTabeladoHabilitado}
          setSwitchChecked={() =>
            handleSalvarFreteTabelado(!useFreteTabeladoHabilitado)
          }
        >
          <AtributosFreteTabeladoRegiao />
        </SecaoConfiguracao>
        <SecaoConfiguracao
          titulo="Configurar esquema de colaboradores"
          subtitulo="Acesso para configurar colaboradores para receber demanda automatizada"
        >
          <ContainerEsquemaColaboradores />
        </SecaoConfiguracao>
        <SecaoConfiguracao
          titulo="Configurar macro filial"
          subtitulo="Acesso para configurar macro filial e filiais"
        >
          <ConfiguracaoMacrofilial />
        </SecaoConfiguracao>
      </Box>
    </Box>
  );
};

export default ConfiguracoesSistema;
