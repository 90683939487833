import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { format, isValid, parseISO } from 'date-fns';

import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import {
  withStyles,
  useTheme,
  makeStyles,
  FormControl,
} from '@material-ui/core';

import { spacingInPixels } from '../../../../shared/functions/materialUtils';
import {
  formatarDataBrasileira,
  formatarHorasMinutosSegundos,
} from '../../../../shared/functions/dataUtils';
import { Carga, Motorista } from '../../../../shared/types/apiTypes';
import { obterRomaneioFormatado } from '../../../../shared/functions/romaneioUtils';
import CustomWidthTooltip from '../../../../shared/components/tooltip/CustomWidthTooltip';

import InputObservacao from './AccordionHeaderInputObservacao';
import InputDataHora from './AccordionHeaderInputDataHora';
import AutocompleteMotoristas from './AccordionHeaderAutocompleteMotoristas';
import {
  atualizarNovaNegociacao,
  removerNovaNegociacao,
} from '../../redux/minhasCargasActions';

const AccordionSummary = withStyles((theme) => ({
  root: {
    minHeight: spacingInPixels(theme, 4),
    maxHeight: spacingInPixels(theme, 4),
    '&.Mui-expanded': {
      minHeight: spacingInPixels(theme, 4),
    },
  },
  expandIcon: {
    order: -1,
    padding: '0px',
    marginRight: spacingInPixels(theme, 2),
    marginLeft: '0px',
  },
}))(MuiAccordionSummary);

const useStyles = makeStyles((theme) => ({
  id: {
    fontSize: '14px',
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  divDataHora: {
    '& .MuiOutlinedInput-input': {
      padding: 0,
      textAlign: 'center',
    },
    '& .MuiOutlinedInput-root': {
      height: spacingInPixels(theme, 3),
      width: spacingInPixels(theme, 11),
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: spacingInPixels(theme, 0.5),
    },
    '& .MuiInputAdornment-positionStart': {
      marginRight: 0,
    },
    '& .MuiIconButton-root': {
      padding: 0,
    },
    '& div:only-child': {
      marginRight: spacingInPixels(theme, 1),
    },
  },
}));

type AccordionHeaderProps = {
  carregando?: boolean;
  carga: Carga | null;
};

const AccordionHeader = ({
  carregando,
  carga,
}: AccordionHeaderProps): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [dataInvalida, setDataInvalida] = useState(false);
  const [observacao, setObservacao] = useState('');
  const [motoristaIndicado, setMotoristaIndicado] = useState<Motorista | null>(
    null,
  );
  const [dataCarregamento, setDataCarregamento] = useState<Date>(new Date());

  useEffect(() => {
    if (carga && carga.dataSolicitacao) {
      const parsedDate = parseISO(carga.dataSolicitacao);
      if (isValid(parsedDate)) {
        setDataCarregamento(parsedDate);
      } else {
        setDataCarregamento(new Date());
      }
    } else {
      setDataCarregamento(new Date());
    }
  }, [carga]);

  useEffect(() => {
    if (dataInvalida && carga?.id) {
      dispatch(removerNovaNegociacao(carga.id));
    }
  }, [carga?.id, dataInvalida, dispatch]);

  useEffect(() => {
    if (carga?.idMotoristaIndicado) {
      setMotoristaIndicado({
        id: carga?.idMotoristaIndicado || 0,
        nome: carga?.nomeMotoristaIndicado || '',
      });
    } else {
      setMotoristaIndicado(null);
    }
  }, [carga?.idMotoristaIndicado, carga?.nomeMotoristaIndicado]);

  useEffect(() => {
    const savedSelectedColaborador = localStorage.getItem(
      'selectedColaborador',
    );
    if (carga && dataCarregamento) {
      const idMotorista =
        motoristaIndicado?.id !== undefined
          ? (motoristaIndicado?.id as number)
          : undefined;

      let idNegociadorIndicadoValue = null;

      if (savedSelectedColaborador) {
        const dataArray = JSON.parse(savedSelectedColaborador);
        const matchingEntry = dataArray.find(
          (entry: { id: number }) => entry.id === carga.id,
        );

        if (matchingEntry) {
          idNegociadorIndicadoValue = matchingEntry.selectValue;
        }
      }

      dispatch(
        atualizarNovaNegociacao({
          observacoes: observacao,
          dataCarregamento: formatarDataBrasileira(dataCarregamento),
          horaCarregamento: formatarHorasMinutosSegundos(dataCarregamento),
          idCarga: carga.id,
          idMotoristaIndicado: idMotorista,
          idNegociadorIndicado: idNegociadorIndicadoValue,
        }),
      );
    }
  }, [carga, dataCarregamento, dispatch, motoristaIndicado?.id, observacao]);

  return (
    <AccordionSummary
      expandIcon={
        <ExpandMoreIcon style={{ color: theme.palette.cores.cinza[500] }} />
      }
      IconButtonProps={{ edge: 'start' }}
    >
      {carregando ? (
        <Box width="100%">
          <Tooltip title="Carregando">
            <LinearProgress color="primary" />
          </Tooltip>
        </Box>
      ) : (
        <FormControl
          aria-label={`carga-${carga?.romaneio}`}
          onClick={(event) => {
            event.stopPropagation();
          }}
          onFocus={(event) => {
            event.stopPropagation();
          }}
          fullWidth
        >
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Box
                display="flex"
                alignItems="center"
                height="100%"
                width="100%"
              >
                <CustomWidthTooltip title={carga?.romaneio}>
                  <Typography className={classes.id}>
                    {obterRomaneioFormatado(carga?.romaneio, 1)}
                  </Typography>
                </CustomWidthTooltip>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.id}>
                {carga?.dataCarregamento
                  ? format(new Date(carga.dataCarregamento), 'dd/MM/yyyy')
                  : ''}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <InputDataHora
                dataCarregamento={dataCarregamento}
                setDataCarregamento={setDataCarregamento}
                dataInvalida={dataInvalida}
                setDataInvalida={setDataInvalida}
              />
            </Grid>
            <Grid item xs={2}>
              <AutocompleteMotoristas
                carga={carga}
                motoristaIndicado={motoristaIndicado}
                setMotoristaIndicado={setMotoristaIndicado}
              />
            </Grid>
            <Grid item xs={2}>
              <InputObservacao carga={carga} setObservacao={setObservacao} />
            </Grid>
          </Grid>
        </FormControl>
      )}
    </AccordionSummary>
  );
};

AccordionHeader.defaultProps = {
  carregando: false,
};

export default AccordionHeader;
