import { DemandaFilial } from '../filiais/api/listagemFiliaisApiTypes';
import { buscarDemandaFilial } from '../filiais/api/listagemFiliaisManager';

const MilissegundosAtualizacaoDemandas = 60 * 1000;

export const buscarDadosFiliais = async (
  setListaDemandaFilial: (
    listaDemandaFilial: DemandaFilial[] | undefined,
  ) => void,
  setCarregando: (valor: boolean) => void,
): Promise<void> => {
  try {
    const listaDemandaFilialResponse = await buscarDemandaFilial();
    setListaDemandaFilial(listaDemandaFilialResponse);
  } catch {
    setListaDemandaFilial(undefined);
  } finally {
    setCarregando(false);
  }
};

export const criarPollingDadosFiliais = (
  setListaDemandaFilial: (
    listaDemandaFilial: DemandaFilial[] | undefined,
  ) => void,
  setCarregando: (valor: boolean) => void,
): number =>
  Number(
    setInterval(async () => {
      await buscarDadosFiliais(setListaDemandaFilial, setCarregando);
    }, MilissegundosAtualizacaoDemandas),
  );
