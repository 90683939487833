import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core';

import spacingInPixels from '../../../shared/functions/materialUtils';
import DropdownCheckbox from '../../../shared/components/dropdown/dropdownCheckbox';
import { DropdownCheckboxOption } from '../../../shared/components/dropdown/dropdownCheckbox/views/DropdownCheckbox';
import { removerEspacosECaracteresEspeciais } from '../../../shared/functions/stringUtils';
import {
  removerNumerosRepetidosReducer,
  removerPalavrasRepetidasReducer,
} from '../../../shared/functions/arrayUtils';

import { Negociacao } from '../api/minhasNegociacoesApiTypes';

import { StatusNegociacaoConstants } from './BoxStatusNegociacao';
import { RootState } from '../../../store/reducer';
import {
  atualizarFiltroFilial,
  atualizarFiltroStatus,
} from '../redux/minhasNegociacoesAction';

export type FiltrosNegociacaoProps = {
  negociacoes: Negociacao[];
};

const FiltrosNegociacao = ({
  negociacoes,
}: FiltrosNegociacaoProps): JSX.Element => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { statusSelecionados, filiaisSelecionadas } = useSelector(
    (state: RootState) => state.minhasNegociacoesReducer,
  );

  const [opcoesFilial, setOpcoesFilial] = useState<DropdownCheckboxOption[]>(
    [],
  );
  const [opcoesStatus, setOpcoesStatus] = useState<DropdownCheckboxOption[]>(
    [],
  );

  useEffect(() => {
    setOpcoesFilial(
      negociacoes
        .map((negociacao) => negociacao.filial)
        .reduce(removerPalavrasRepetidasReducer, [])
        .sort((a, b) => a.localeCompare(b))
        .map((filial) => ({
          label: filial,
          name: removerEspacosECaracteresEspeciais(filial),
        })),
    );
  }, [negociacoes]);

  useEffect(() => {
    const findDescricaoStatusNegociacao = (s: number): string =>
      StatusNegociacaoConstants.find(
        (statusNegociacao) => statusNegociacao.codigo === s,
      )?.texto || '';

    setOpcoesStatus(
      negociacoes
        .map((negociacao) => negociacao.status)
        .reduce(removerNumerosRepetidosReducer, [])
        .sort((a, b) => a - b)
        .map((s) => ({
          label: findDescricaoStatusNegociacao(s),
          name: String(s),
        })),
    );
  }, [negociacoes]);

  const selecionarFiliais = (opcoesSelecionadas: string[]) => {
    dispatch(atualizarFiltroFilial(opcoesSelecionadas));
  };

  const selecionarStatus = (opcoesSelecionadas: string[]) => {
    dispatch(atualizarFiltroStatus(opcoesSelecionadas));
  };

  return (
    <Box display="flex">
      <DropdownCheckbox
        id="filial"
        texto="Filial"
        titulo="Filial de destino"
        opcoes={opcoesFilial}
        opcoesSelecionadas={Array.from(filiaisSelecionadas)}
        onChange={selecionarFiliais}
      />
      <DropdownCheckbox
        id="status"
        texto="Status"
        titulo="Status da negociação"
        marginLeft={spacingInPixels(theme, 5)}
        opcoes={opcoesStatus}
        opcoesSelecionadas={Array.from(statusSelecionados)}
        onChange={selecionarStatus}
      />
    </Box>
  );
};

export default FiltrosNegociacao;
