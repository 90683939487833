import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import { PopoverOrigin } from '@material-ui/core/Popover';

import ButtonDropdown from '../../ButtonDropdown';
import PopoverDropdownData from './PopoverDropdownData';

export type DropdownCheckboxOption = {
  label: string;
  name: string;
};

export type DropdownCheckboxProps = {
  id: string;
  texto: string;
  titulo: string;
  marginLeft?: React.CSSProperties['marginLeft'];
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  dataInicio: Date | null;
  dataFim: Date | null;
  onDataInicioChange: (dataInicio: Date | null) => void;
  onDataFimChange: (dataFim: Date | null) => void;
};

const defaultAnchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
};

const defaultTransformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

const DropdownData = ({
  id,
  texto,
  titulo,
  marginLeft = 0,
  anchorOrigin = defaultAnchorOrigin,
  transformOrigin = defaultTransformOrigin,
  dataInicio,
  dataFim,
  onDataInicioChange,
  onDataFimChange,
}: DropdownCheckboxProps): JSX.Element => {
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(
    null,
  );

  const open = Boolean(anchorElement);
  const dropdownId = open ? id : undefined;

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  return (
    <Box marginLeft={marginLeft}>
      <ButtonDropdown
        texto={texto}
        titulo={titulo}
        open={open}
        aria-describedby={dropdownId}
        onClick={handleOpen}
        invisible={dataInicio === null && dataFim === null}
      />
      <PopoverDropdownData
        id={dropdownId}
        open={open}
        titulo={titulo}
        anchorElement={anchorElement}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onClose={handleClose}
        dataInicio={dataInicio}
        dataFim={dataFim}
        onDataInicioChange={onDataInicioChange}
        onDataFimChange={onDataFimChange}
      />
    </Box>
  );
};

export default DropdownData;
