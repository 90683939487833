import { ConexaoAPI } from 'shared/classes/conexaoAPI';
import { baixarArquivoRespostaEndpoint } from 'shared/functions/arquivoUtils';
import {
  NegociacaoAndamento,
  NegociacoesAndamentoResponse,
} from './demandasAndamentoApiTypes';

const obterUrlBuscarNegociacoesAndamento = (): string =>
  `${process.env.REACT_APP_URL_API_BASE}/negociacoes-em-andamento`;

const obterUrlExportarNegociacoesAndamento = (): string =>
  `${process.env.REACT_APP_URL_API_BASE}/negociacoes/planilhas/negociacoes-em-andamento`;

export const obterDemandasAndamento = async (): Promise<
  NegociacaoAndamento[]
> => {
  const url = obterUrlBuscarNegociacoesAndamento();
  const conexaoApi = new ConexaoAPI(url, 'get');
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<NegociacoesAndamentoResponse>();
  const response = await conectar();
  return response?.data?.negociacoesEmAndamento;
};

export const exportarDemandasAndamento = async (): Promise<void> => {
  const url = obterUrlExportarNegociacoesAndamento();
  const nomeArquivoPadrao = 'DemandasNegociacoesEmAndamento.xlsx';

  const conexao = new ConexaoAPI(url, 'get');
  conexao.adicionarResponseTypeArrayBuffer();
  const conectar = conexao.gerarFuncaoConectarRefreshToken();
  const resposta = await conectar();

  baixarArquivoRespostaEndpoint(resposta, nomeArquivoPadrao);
};
