import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';

export default withStyles((theme) => ({
  root: {
    '& .MuiDialog-paper': {
      minWidth: '60%',
      padding: theme.spacing(0, 3, 3, 3),
    },
  },
}))(Dialog);
