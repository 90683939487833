import React, { useMemo } from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import IconeCirculo from 'assets/icones/IconeCirculo';
import { PrioridadeCargaNegociacao } from 'features/dashboards/negociadores/api/listagemNegociadoresApiTypes';

const useStyles = makeStyles(() => ({
  status: {
    display: 'inline-block',
    '& > svg': {
      verticalAlign: 'middle',
    },
  },
}));

export interface CelulaStatusPrioridadeProps {
  prioridadeCarga: PrioridadeCargaNegociacao;
}

const CelulaStatusPrioridade = ({
  prioridadeCarga,
}: CelulaStatusPrioridadeProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();

  const corStatus = useMemo(() => {
    switch (prioridadeCarga) {
      case PrioridadeCargaNegociacao.baixa:
        return theme.palette.cores.verde[500];
      case PrioridadeCargaNegociacao.media:
        return theme.palette.cores.mostarda[800];
      case PrioridadeCargaNegociacao.maxima:
      default:
        return theme.palette.cores.vermelho[450];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <td className={classes.status}>
      <IconeCirculo viewBox="0 0 20 17" fill={corStatus} />
    </td>
  );
};

export default CelulaStatusPrioridade;
