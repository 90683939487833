import React from 'react';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import {
  Theme,
  makeStyles,
  withStyles,
  createStyles,
  useTheme,
} from '@material-ui/core/styles';
import IconeAndroid from 'assets/icones/IconeAndroid';
import IconeAvatar from '../../../../assets/icones/IconeAvatar';

interface StyledBadgeProps {
  corstatusconexao?: string;
}

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 'fit-content',
    },
    badge: (props: StyledBadgeProps) => ({
      backgroundColor: props.corstatusconexao,
      color: props.corstatusconexao,
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        content: '""',
      },
    }),
    anchorOriginBottomRightRectangle: {
      right: '4px',
      bottom: '4px',
    },
  }),
)(Badge);

interface StyledAvatarProps {
  tamanho?: number;
}

const StyledAvatar = withStyles((theme: Theme) =>
  createStyles({
    root: (props: StyledAvatarProps) => ({
      height: `${props.tamanho ? props.tamanho : 36}px`,
      width: `${props.tamanho ? props.tamanho : 36}px`,
      padding: `${props.tamanho ? '4px' : 'unset'}`,
    }),
    circular: {
      border: `1px solid ${theme.palette.cores.cinza[500]}`,
    },
    colorDefault: {
      backgroundColor: theme.palette.cores.cinza[200],
    },
  }),
)(Avatar);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }),
);

export interface AvatarComStatusConexaoProps {
  isOnline: boolean;
  tamanhoIcone?: number;
  isAutomatizado?: boolean;
}

const AvatarComStatusConexao = ({
  isOnline,
  tamanhoIcone,
  isAutomatizado,
}: AvatarComStatusConexaoProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();

  const corStatusConexao = isOnline
    ? theme.palette.cores.verde[700]
    : theme.palette.cores.cinza[600];

  return (
    <div className={classes.root}>
      <StyledBadge
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        variant="dot"
        corstatusconexao={corStatusConexao}
      >
        <StyledAvatar alt="Avatar" tamanho={tamanhoIcone} variant="circular">
          {isAutomatizado ? (
            <IconeAndroid
              width={tamanhoIcone || 24}
              height={tamanhoIcone || 24}
              fill={theme.palette.cores.cinza[700]}
            />
          ) : (
            <IconeAvatar
              width={tamanhoIcone || 26}
              height={tamanhoIcone || 26}
              fill={theme.palette.cores.cinza[600]}
            />
          )}
        </StyledAvatar>
      </StyledBadge>
    </div>
  );
};

export default AvatarComStatusConexao;
