import { useTheme } from '@material-ui/core/styles';
import * as React from 'react';
import { SVGProps } from 'react';

const IconeUploadArquivo = ({
  fill,
  ...outros
}: SVGProps<SVGSVGElement>): JSX.Element => {
  const theme = useTheme();
  return (
    <svg width={16} height={20} fill="none" {...outros}>
      <path
        d="M8.71 9.29a1 1 0 0 0-.33-.21 1 1 0 0 0-.76 0 1 1 0 0 0-.33.21l-2 2a1.004 1.004 0 0 0 1.42 1.42l.29-.3V15a1 1 0 1 0 2 0v-2.59l.29.3a1 1 0 0 0 1.639-.325 1 1 0 0 0-.219-1.095l-2-2ZM16 6.94a1.307 1.307 0 0 0-.06-.27v-.09a1.07 1.07 0 0 0-.19-.28l-6-6a1.07 1.07 0 0 0-.28-.19h-.1A1.1 1.1 0 0 0 9.06 0H3a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V6.94Zm-6-3.53L12.59 6H11a1 1 0 0 1-1-1V3.41ZM14 17a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h5v3a3 3 0 0 0 3 3h3v9Z"
        fill={fill || theme.palette.cores.azulMartins[500]}
      />
    </svg>
  );
};

export default IconeUploadArquivo;
