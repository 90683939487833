import { createAction } from '@reduxjs/toolkit';

import {
  Negociacao,
  PropostaNegociacao,
  MotoristaNegociacao,
  ValoresRequest,
} from '../api/informacoesNegociacaoApiTypes';

export const atualizarIdNegociacao = createAction<number>(
  'informacoesNegociacaoAction/ATUALIZAR_ID_NEGOCIACAO',
);

export const atualizarNegociacao = createAction<Negociacao>(
  'informacoesNegociacaoAction/ATUALIZAR_NEGOCIACAO',
);

export const atualizarValores = createAction<ValoresRequest>(
  'informacoesNegociacaoAction/ATUALIZAR_VALORES',
);

export const atualizarObservacao = createAction<string | undefined>(
  'informacoesNegociacaoAction/ATUALIZAR_OBSERVACAO',
);

export const atualizarPropostas = createAction<PropostaNegociacao[]>(
  'informacoesNegociacaoAction/ATUALIZAR_PROPOSTAS',
);

export const atualizarMotoristas = createAction<MotoristaNegociacao[]>(
  'informacoesNegociacaoAction/ATUALIZAR_MOTORISTAS',
);

export const atualizarIdPropostaComFoco = createAction<number | undefined>(
  'informacoesNegociacaoAction/ATUALIZAR_ID_PROPOSTA_COM_FOCO',
);

export const abrirModalExcluirObservacao = createAction<void>(
  'informacoesNegociacaoAction/ABRIR_MODAL_EXCLUIR_OBSERVACAO',
);

export const fecharModalExcluirObservacao = createAction<void>(
  'informacoesNegociacaoAction/FECHAR_MODAL_EXCLUIR_OBSERVACAO',
);

export const limparDados = createAction<void>(
  'informacoesNegociacaoAction/LIMPAR_DADOS',
);
