import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import LocalidadesHeaderAccordion from './LocalidadesHeaderAccordion';
import EntregaCidadeAccordion from './EntregaCidadeAccordion';

import { EntregaCidade } from '../api/detalhesCargaApiTypes';
import Accordion from '../../../shared/components/accordion/Accordion';

type LocalidadesAccordionProps = {
  entregas: EntregaCidade[];
  quantidadeEntregas: number;
};

const useStyles = makeStyles({
  scrollbar: {
    maxHeight: '64vh',
    overflow: 'auto',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      background: 'transparent',
    },
  },
});

const LocalidadesAccordion = ({
  entregas,
  quantidadeEntregas,
}: LocalidadesAccordionProps): JSX.Element => {
  const [expandir, setExpandir] = useState(false);
  const classes = useStyles();

  const expandirAccordion = () => {
    setExpandir(!expandir);
  };

  return (
    <>
      <Accordion onChange={expandirAccordion}>
        <LocalidadesHeaderAccordion entregas={quantidadeEntregas} />
      </Accordion>
      <Box className={classes.scrollbar}>
        {entregas.map((entrega) => (
          <EntregaCidadeAccordion
            key={entrega.cidade}
            entrega={entrega}
            expandirExterno={expandir}
          />
        ))}
      </Box>
    </>
  );
};

export default LocalidadesAccordion;
