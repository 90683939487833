import ButtonBase from '@material-ui/core/ButtonBase';
import { Theme, withStyles } from '@material-ui/core/styles';
import { Rubik } from '../../constants/font';

import { spacingInPixels } from '../../functions/materialUtils';

export default withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    fontSize: '14px',
    lineHeight: spacingInPixels(theme, 2),
    fontFamily: Rubik,
  },
}))(ButtonBase);
