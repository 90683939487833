import { Dispatch } from 'redux';

import { typeEnviarNovaPropostaOperation } from 'features/informacoesNegociacao/types/PropostaAEnviar';
import {
  abrirSnackbarErro,
  abrirSnackbarSucesso,
} from '../../../../../shared/components/snackbar/redux/snackbarAction';
import PropostaStatus, {
  PropostaStatusType,
  verificarPropostaConcluida,
} from '../../../../../shared/constants/PropostaStatus';
import msgConstants from '../../../../../shared/constants/msgConstants';

import {
  PROPOSTA_CRIADA_COM_SUCESSO,
  PROPOSTA_RECUSADA_COM_SUCESSO,
} from '../../../constants/mensagensInformacoesNegociacao';

import {
  atualizarProposta,
  criarProposta,
} from '../../../api/informacoesNegociacaoManager';
import {
  MotoristaNegociacao,
  Negociacao,
  PropostaNegociacao,
} from '../../../api/informacoesNegociacaoApiTypes';

import {
  atualizarMotoristasOperation,
  atualizarNegociacaoOperation,
  atualizarPropostasOperation,
} from '../../../redux/informacoesNegociacaoOperation';

import {
  enviarContrapropostaWhatsapp,
  enviarNovaPropostaWhatsapp,
  enviarPropostaFinalWhatsapp,
  retomarPropostaWhatsapp,
} from '../functions/enviarPropostaControleUtils';

import {
  abrirModalReatribuirNegociacao,
  fecharModaisRecusarProposta,
} from './motoristasCardAction';

const tratarErro = (error: Error) => (dispatch: Dispatch) => {
  dispatch(abrirSnackbarErro({ mensagem: error.message }));
};

const tratarSucesso = (mensagem = msgConstants.DADOS_SALVOS_SUCESSO) => (
  dispatch: Dispatch,
) => {
  dispatch(abrirSnackbarSucesso({ mensagem }));
};

export const recusarPropostaOperation = (
  idNegociacao: number,
  idProposta: number,
  motivoRecusa: number,
) => async (dispatch: Dispatch): Promise<void> => {
  await atualizarProposta(idNegociacao, idProposta, {
    motivoRecusa,
    status: PropostaStatus.RECUSA_NEGOCIADOR,
  })
    .then(async () => {
      dispatch(fecharModaisRecusarProposta());
      tratarSucesso(PROPOSTA_RECUSADA_COM_SUCESSO)(dispatch);
      await atualizarNegociacaoOperation(idNegociacao)(dispatch);
      await atualizarPropostasOperation(idNegociacao)(dispatch);
    })
    .catch((erro: Error) => {
      tratarErro(erro)(dispatch);
    });
};

export const recusarPropostaOuAbrirModalDeReatribuicao = (
  idNegociacao: number,
  idProposta: number,
  statusProposta: PropostaStatusType,
  motivoRecusa: number,
) => async (dispatch: Dispatch): Promise<void> => {
  if (verificarPropostaConcluida(statusProposta)) {
    dispatch(abrirModalReatribuirNegociacao());
  } else {
    await recusarPropostaOperation(
      idNegociacao,
      idProposta,
      motivoRecusa,
    )(dispatch);
  }
};

export const enviarNovaPropostaOperation = (
  idNegociacao: number,
  motorista: MotoristaNegociacao,
  negociacao: Negociacao,
  valorGreguimAlterado: number | null,
) => async (dispatch: Dispatch): Promise<typeEnviarNovaPropostaOperation> => {
  let novaPropostaData = <typeEnviarNovaPropostaOperation>{};
  await criarProposta(idNegociacao, {
    idMotorista: motorista.id,
    valorGreguimAlterado,
  })
    .then(async (data) => {
      if (!motorista.usuarioApp) {
        enviarNovaPropostaWhatsapp(motorista, negociacao);
      }

      tratarSucesso(PROPOSTA_CRIADA_COM_SUCESSO)(dispatch);
      await atualizarNegociacaoOperation(idNegociacao)(dispatch);
      await atualizarPropostasOperation(idNegociacao)(dispatch);
      await atualizarMotoristasOperation(
        negociacao?.carga.idFilialDestino || null,
        false,
      )(dispatch);
      novaPropostaData = data;
    })
    .catch((erro: Error) => {
      tratarErro(erro)(dispatch);
    });
  return novaPropostaData;
};

export const enviarContrapropostaOperation = (
  idNegociacao: number,
  proposta: PropostaNegociacao,
) => async (dispatch: Dispatch): Promise<void> => {
  await atualizarProposta(idNegociacao, proposta.id, {
    propostaNegociador: proposta.contrapropostaNegociador,
    propostaMotorista: undefined,
  })
    .then(async () => {
      enviarContrapropostaWhatsapp(proposta);
      await atualizarPropostasOperation(idNegociacao)(dispatch);
      tratarSucesso()(dispatch);
    })
    .catch((erro: Error) => {
      tratarErro(erro)(dispatch);
    });
};

export const retomarPropostaOperation = (
  idNegociacao: number,
  proposta: PropostaNegociacao,
  negociacao: Negociacao,
) => async (dispatch: Dispatch): Promise<void> => {
  await atualizarProposta(idNegociacao, proposta.id, {
    propostaNegociador: proposta.contrapropostaNegociador,
    propostaMotorista: undefined,
  })
    .then(async () => {
      retomarPropostaWhatsapp(proposta, negociacao);
      await atualizarPropostasOperation(idNegociacao)(dispatch);
      await atualizarNegociacaoOperation(idNegociacao)(dispatch);
      tratarSucesso()(dispatch);
    })
    .catch((erro: Error) => {
      tratarErro(erro)(dispatch);
    });
};

export const enviarPropostaFinalOperation = (
  idNegociacao: number,
  proposta: PropostaNegociacao,
  negociacao: Negociacao,
) => async (dispatch: Dispatch): Promise<void> => {
  await atualizarProposta(idNegociacao, proposta.id, {
    status: PropostaStatus.CONCLUIDA,
  })
    .then(async () => {
      enviarPropostaFinalWhatsapp(proposta, negociacao);
      await atualizarPropostasOperation(idNegociacao)(dispatch);
      await atualizarNegociacaoOperation(idNegociacao)(dispatch);
      tratarSucesso()(dispatch);
    })
    .catch((erro: Error) => {
      tratarErro(erro)(dispatch);
    });
};
