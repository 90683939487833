import { createAction } from '@reduxjs/toolkit';

import { PropostaNegociacao } from '../../../api/informacoesNegociacaoApiTypes';

import { PropostaAEnviar } from '../../../types/PropostaAEnviar';

export const abrirModalEnviarProposta = createAction<PropostaAEnviar>(
  'motoristasCardAction/ABRIR_MODAL_ENVIAR_PROPOSTA',
);

export const fecharModalEnviarProposta = createAction<void>(
  'motoristasCardAction/FECHAR_MODAL_ENVIAR_PROPOSTA',
);

export const abrirModalRecusarProposta = createAction<PropostaNegociacao>(
  'motoristasCardAction/ABRIR_MODAL_RECUSAR_PROPOSTA',
);

export const fecharModalRecusarProposta = createAction<void>(
  'motoristasCardAction/FECHAR_MODAL_RECUSAR_PROPOSTA',
);

export const abrirModalReatribuirNegociacao = createAction<void>(
  'motoristasCardAction/ABRIR_MODAL_REATRIBUIR_NEGOCIACAO',
);

export const fecharModaisRecusarProposta = createAction<void>(
  'motoristasCardAction/FECHAR_MODAIS_RECUSAR_PROPOSTA',
);

export const abrirModalCancelarNegociacao = createAction<void>(
  'motoristasCardAction/ABRIR_MODAL_CANCELAR_NEGOCIACAO',
);

export const fecharModalCancelarNegociacao = createAction<void>(
  'motoristasCardAction/FECHAR_MODAL_CANCELAR_NEGOCIACAO',
);

export const atualizarPesquisa = createAction<string>(
  'motoristasCardAction/ATUALIZAR_PESQUISA',
);

export const atualizarMotivoRecusarProposta = createAction<string>(
  'motoristasCardAction/ATUALIZAR_MOTIVO_RECUSAR_PROPOSTA',
);

export const limparDados = createAction<void>(
  'motoristasCardAction/LIMPAR_DADOS',
);
