import React from 'react';
import {
  Box,
  IconButton,
  makeStyles,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { ArrowDownward } from '@material-ui/icons';
import { Rubik } from '../../constants/font';

type BotaoIconeDownloadProps = {
  caminhoIcone: string;
  onClick: () => void;
  carregando: boolean;
};

const useStyles = makeStyles((theme) => ({
  iconeDownload: {
    position: 'absolute',
    marginLeft: 72,
    marginTop: 40,
    backgroundColor: theme.palette.cores.azulMartins[500],
    borderRadius: '100%',
    color: theme.palette.cores.branco,
    padding: 4,
    height: 30,
    width: 30,
  },
  loading: {
    color: theme.palette.cores.azulMartins[500],
  },
  texto: {
    fontFamily: Rubik,
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const BotaoIconeDownload = ({
  caminhoIcone,
  onClick,
  carregando,
}: BotaoIconeDownloadProps): JSX.Element => {
  const classes = useStyles();

  return carregando ? (
    <Box display="flex" alignItems="center" flexDirection="column">
      <Typography className={classes.texto}>Baixando...</Typography>
      <CircularProgress size="24px" className={classes.loading} />
    </Box>
  ) : (
    <IconButton onClick={onClick}>
      <img src={caminhoIcone} alt="Imagem do ícone de download" />
      <ArrowDownward className={classes.iconeDownload} />
    </IconButton>
  );
};

export default BotaoIconeDownload;
