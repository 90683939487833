import { useTheme } from '@material-ui/core';
import * as React from 'react';
import { SVGProps } from 'react';

const IconeGrafico = ({
  fill,
  width,
  height,
  ...outros
}: SVGProps<SVGSVGElement>): JSX.Element => {
  const theme = useTheme();
  return (
    <svg
      width={width || 20}
      height={height || 20}
      viewBox="0 0 14 14"
      fill="none"
      {...outros}
    >
      <path
        d="M3.66667 9.66659C3.93188 9.66659 4.18624 9.56123 4.37377 9.37369C4.56131 9.18616 4.66667 8.9318 4.66667 8.66659C4.66992 8.63333 4.66992 8.59984 4.66667 8.56659L6.52667 6.70659H6.68H6.83333L7.90667 7.77992C7.90667 7.77992 7.90667 7.81325 7.90667 7.83325C7.90667 8.09847 8.01202 8.35282 8.19956 8.54036C8.3871 8.7279 8.64145 8.83325 8.90667 8.83325C9.17188 8.83325 9.42624 8.7279 9.61377 8.54036C9.80131 8.35282 9.90667 8.09847 9.90667 7.83325V7.77992L12.3333 5.33325C12.5311 5.33325 12.7245 5.2746 12.8889 5.16472C13.0534 5.05484 13.1815 4.89866 13.2572 4.71594C13.3329 4.53321 13.3527 4.33214 13.3141 4.13816C13.2755 3.94418 13.1803 3.766 13.0404 3.62615C12.9006 3.48629 12.7224 3.39105 12.5284 3.35247C12.3344 3.31388 12.1334 3.33368 11.9507 3.40937C11.7679 3.48506 11.6117 3.61323 11.5019 3.77768C11.392 3.94213 11.3333 4.13547 11.3333 4.33325C11.3301 4.36651 11.3301 4.4 11.3333 4.43325L8.92667 6.83992H8.82L7.66667 5.66659C7.66667 5.40137 7.56131 5.14702 7.37377 4.95948C7.18624 4.77194 6.93188 4.66659 6.66667 4.66659C6.40145 4.66659 6.1471 4.77194 5.95956 4.95948C5.77202 5.14702 5.66667 5.40137 5.66667 5.66659L3.66667 7.66659C3.40145 7.66659 3.1471 7.77194 2.95956 7.95948C2.77202 8.14702 2.66667 8.40137 2.66667 8.66659C2.66667 8.9318 2.77202 9.18616 2.95956 9.37369C3.1471 9.56123 3.40145 9.66659 3.66667 9.66659ZM12.6667 12.3333H1.33333V0.999919C1.33333 0.823108 1.2631 0.653538 1.13807 0.528514C1.01305 0.40349 0.843478 0.333252 0.666667 0.333252C0.489856 0.333252 0.320286 0.40349 0.195262 0.528514C0.0702379 0.653538 0 0.823108 0 0.999919V12.9999C0 13.1767 0.0702379 13.3463 0.195262 13.4713C0.320286 13.5963 0.489856 13.6666 0.666667 13.6666H12.6667C12.8435 13.6666 13.013 13.5963 13.1381 13.4713C13.2631 13.3463 13.3333 13.1767 13.3333 12.9999C13.3333 12.8231 13.2631 12.6535 13.1381 12.5285C13.013 12.4035 12.8435 12.3333 12.6667 12.3333Z"
        fill={fill || theme.palette.cores.azulMartins[500]}
      />
    </svg>
  );
};

export default IconeGrafico;
