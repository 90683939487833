import { Dispatch } from 'redux';

import { abrirSnackbarErro } from '../../../shared/components/snackbar/redux/snackbarAction';
import {
  atualizarArquivo,
  atualizarCarregando,
  atualizarEstadoArquivoEnviado,
} from '../redux/uploadSimuladorAction';
import mensagensUploadSimulador from '../constants/mensagensUploadSimulador';
import uploadSimuladorManager from '../api/uploadSimuladorManager';
import { verificarExtensaoArquivo } from '../../../shared/functions/validacaoArquivoUtils';
import { TIPOS_UPLOAD_ACEITOS } from '../constants/validacaoSimulador';
import { typeAbasUpload } from '../types/tabUploads';

export const dipararSnackbarErro = (erro: string, dispatch: Dispatch): void => {
  dispatch(
    abrirSnackbarErro({
      mensagem: erro,
    }),
  );
};

export const verificarExtensaoArquivoXlsx = (tipo: string): boolean =>
  verificarExtensaoArquivo(tipo, TIPOS_UPLOAD_ACEITOS);

export const enviarArquivo = (
  arquivo: File | null,
  typeUpload: typeAbasUpload,
) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(atualizarCarregando(true));
  if (!arquivo) {
    dipararSnackbarErro(
      mensagensUploadSimulador.ERRO_ARQUIVO_INVALIDO,
      dispatch,
    );
  } else if (typeUpload === 'UploadDeCarga') {
    await uploadSimuladorManager
      .postCargas(arquivo)
      .then(() => {
        dispatch(atualizarEstadoArquivoEnviado(true));
      })
      .catch((erro: Error) => dipararSnackbarErro(erro.message, dispatch));
    dispatch(atualizarCarregando(false));
  } else {
    await uploadSimuladorManager
      .postPrecificacao(arquivo)
      .then((data) => {
        if (data.errors.length > 0) {
          dipararSnackbarErro(data.errors[0].erro, dispatch);
        } else {
          dispatch(atualizarEstadoArquivoEnviado(true));
        }
      })
      .catch((erro: Error) => dipararSnackbarErro(erro.message, dispatch));
    dispatch(atualizarCarregando(false));
  }
};

export const changeHandler = (
  evento: React.ChangeEvent<HTMLInputElement>,
  dispatch: Dispatch,
): void => {
  if (evento.target.files && evento.target.files.length > 0) {
    if (verificarExtensaoArquivoXlsx(evento.target.files[0].type)) {
      dispatch(atualizarArquivo(evento.target?.files[0]));
    } else {
      dipararSnackbarErro(
        mensagensUploadSimulador.ERRO_TIPO_ARQUIVO_INVALIDO,
        dispatch,
      );
    }
  } else {
    dispatch(atualizarArquivo(null));
  }
};
