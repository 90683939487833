import { makeStyles } from '@material-ui/core';
import React from 'react';
import ConteudoBigNumber from 'shared/components/bigNumber/ConteudoBigNumberAlerta';
import CustomTooltip from 'shared/components/tooltip/CustomTooltip';
import spacingInPixels from 'shared/functions/materialUtils';

const useStyles = makeStyles((theme) => ({
  container: {
    placeItems: 'center',
    cursor: 'pointer',
    minWidth: '18%',
    border: '1px solid transparent',
    backgroundColor: theme.palette.cores.branco,
    padding: spacingInPixels(theme, 1),
    borderRadius: spacingInPixels(theme, 1),
    boxShadow: '0px 1px 6px 0px rgba(0, 0, 0, 0.09)',

    '&:hover': {
      boxShadow: 'none',
      border: `1px solid ${theme.palette.cores.azul[400]}`,
    },
  },
}));

export interface BigNumberBordaProps {
  icone: React.ReactElement;
  cor?: string;
  numero?: number;
  descricao: string;
  selecionado?: boolean;
  onClick: () => void;
}

const BigNumberBorda = (props: BigNumberBordaProps): JSX.Element => {
  const classes = useStyles();
  const { descricao, onClick } = props;

  const conteudoProps = { ...props, onClick: undefined };
  return (
    <CustomTooltip title={descricao} placement="bottom">
      <button type="button" onClick={onClick} className={classes.container}>
        <ConteudoBigNumber {...conteudoProps} />
      </button>
    </CustomTooltip>
  );
};

export default BigNumberBorda;
