import React from 'react';

import IconeCasa from 'assets/icones/IconeCasa';
import IconeRoboAndroid from 'assets/icones/IconeRoboAndroid';
import IconeCaminhao from 'assets/icones/IconeCaminhao';
import IconeUploadArquivo from 'assets/icones/IconeUploadArquivo';
import IconeDownload from 'assets/icones/IconeDownload';
import IconeEngrenagem from 'assets/icones/IconeEngrenagem';
import IconeCorretoCirculo from 'assets/icones/IconeCorretoCirculo';
import IconeGrafico from 'assets/icones/IconeGrafico';
import IconeMotorista from 'assets/icones/IconeMotorista';

import MinhasNegociacoes from 'features/minhasNegociacoes';
import NegociacaoAutomatizadas from 'features/negociacoesAutomatizadas';
import ListaCargas from 'features/listaCargas';
import UploadSimulador from 'features/uploadSimulador';
import AcompanhamentoNegociacoes from 'features/acompanhamentoNegociacoes';
import DetalhesCarga from 'features/detalhesCarga';
import InformacoesNegociacao from 'features/informacoesNegociacao';
import MinhasCargas from 'features/minhasCargas';
import MotoristasVeiculos from 'features/motoristasVeiculos';

import AprovacoesSupervisor from 'pages/aprovacoesSupervisor/AprovacoesSupervisor';
import DetalhesAprovacaoSupervisor from 'pages/detalhesAprovacaoSupervisor/DetalhesAprovacaoSupervisor';
import PaginaDetalhesAprovacaoNegociacaoSupervisor from 'pages/detalhesAprovacaoNegociacaoSupervisor/PaginaDetalhesAprovacaoNegociacaoSupervisor';
import PaginaConfiguracoes from 'pages/configuracoes/PaginaConfiguracoes';
import PainelGestao from 'pages/painelGestao/PainelGestao';
import IconeGraficoPizza from 'assets/icones/IconeGraficoPizza';
import PaginaDemandasAndamento from 'pages/demandasAndamento/PaginaDemandasAndamento';
import PaginaDetalhesDemandasDiaria from 'pages/detalhesDemandasDiaria/PaginaDetalhesDemandasDiaria';
import Indicadores from 'pages/indicadores/Indicadores';

import { HEADER_ALTERNATIVO_INFORMACOES_NEGOCIACAO } from 'shared/constants/ObjetoHeaderAlternativo';
import { HEADER_ALTERNATIVO_INFORMACOES_NEGOCIACAO_AUTOMATIZADAS } from 'shared/constants/ObjetoHeaderAlternativoNegociacaoAutozatizadas';
import {
  PATH_LISTA_CARGAS,
  PATH_MINHAS_NEGOCIACOES,
  PATH_UPLOAD_SIMULADOR,
  PATH_ACOMPANHAMENTO_NEGOCIACOES,
  PATH_DETALHES_CARGA,
  PATH_INFORMACOES_NEGOCIACAO,
  PATH_MINHAS_CARGAS,
  PATH_APROVACOES_SUPERVISOR,
  PATH_DETALHES_APROVACAO_SUPERVISOR,
  PATH_CONFIGURACOES,
  PATH_PAINEL_GESTAO,
  PATH_DEMANDAS_ANDAMENTO,
  PATH_DETALHES_DEMANDAS_DIARIA,
  PATH_INDICADORES,
  PATH_DETALHES_APROVACAO_NEGOCIACAO_SUPERVISOR,
  PATH_NEGOCIACOES_AUTOMATIZADAS,
  PATH_INFORMACOES_NEGOCIACAO_AUTOMATIZADAS,
  PATH_DETALHES_CARGA_NEGOCIACAO_AUTOMATIAZADAS,
  PATH_MOTORISTAS_VEICULOS,
} from '../../constants/pathConstants';
import { Pagina, PaginaType } from '../../constants/Pagina';

type PaginaBaseInfo = {
  codigo: PaginaType;
  link: string;
};

export type PaginaItemMenu = PaginaBaseInfo & {
  texto: string;
  iconeMenu: JSX.Element;
};

export type CamposDoHeaderAlternativo = {
  titulo: string;
  link: string;
  textoOn?: boolean;
  boataoVoltar?: boolean;
};

export type PaginaRotaAcesso = PaginaBaseInfo & {
  componentePagina: JSX.Element;
  headerAlternativo?: CamposDoHeaderAlternativo[];
};

const MINHAS_NEGOCIACOES_PAGINA: PaginaRotaAcesso = {
  codigo: Pagina.MinhasNegociacoes,
  link: PATH_MINHAS_NEGOCIACOES,
  componentePagina: <MinhasNegociacoes />,
};

const NEGOCIACOES_AUTOMATIZADAS: PaginaRotaAcesso = {
  codigo: Pagina.NegociacoesAutomatizadas,
  link: PATH_NEGOCIACOES_AUTOMATIZADAS,
  componentePagina: <NegociacaoAutomatizadas />,
};

const MINHAS_NEGOCIACOES_ITEM_MENU: PaginaItemMenu = {
  codigo: Pagina.MinhasNegociacoes,
  link: PATH_MINHAS_NEGOCIACOES,
  texto: 'Minhas Negociações',
  iconeMenu: <IconeCasa />,
};

const NEGOCIACOES_AUTOMATIZADAS_ITEM_MENU: PaginaItemMenu = {
  codigo: Pagina.NegociacoesAutomatizadas,
  link: PATH_NEGOCIACOES_AUTOMATIZADAS,
  texto: 'Negociações Automatizadas',
  iconeMenu: <IconeRoboAndroid />,
};

const MOTORISTAS_VEICULOS: PaginaRotaAcesso = {
  codigo: Pagina.MotoristasVeiculos,
  link: PATH_MOTORISTAS_VEICULOS,
  componentePagina: <MotoristasVeiculos />,
};

const MOTORISTAS_VEICULOS_ITEM_MENU: PaginaItemMenu = {
  codigo: Pagina.MotoristasVeiculos,
  link: PATH_MOTORISTAS_VEICULOS,
  texto: 'Motoristas',
  iconeMenu: <IconeMotorista />,
};

const DISTRIBUICAO_CARGAS_PAGINA: PaginaRotaAcesso = {
  codigo: Pagina.ListaCargas,
  link: PATH_LISTA_CARGAS,
  componentePagina: <ListaCargas />,
};

const DISTRIBUICAO_CARGAS_ITEM_MENU: PaginaItemMenu = {
  codigo: Pagina.ListaCargas,
  link: PATH_LISTA_CARGAS,
  texto: 'Distribuição de Cargas',
  iconeMenu: <IconeCaminhao />,
};

const UPLOAD_SIMULADOR_PAGINA: PaginaRotaAcesso = {
  codigo: Pagina.UploadSimulador,
  link: PATH_UPLOAD_SIMULADOR,
  componentePagina: <UploadSimulador />,
};

const UPLOAD_SIMULADOR_ITEM_MENU: PaginaItemMenu = {
  codigo: Pagina.UploadSimulador,
  link: PATH_UPLOAD_SIMULADOR,
  texto: 'Upload do Simulador',
  iconeMenu: <IconeUploadArquivo />,
};

const ACOMPANHAMENTO_NEGOCIACOES_PAGINA: PaginaRotaAcesso = {
  codigo: Pagina.AcompanhamentoNegociacao,
  link: PATH_ACOMPANHAMENTO_NEGOCIACOES,
  componentePagina: <AcompanhamentoNegociacoes />,
};

const ACOMPANHAMENTO_NEGOCIACOES_ITEM_MENU: PaginaItemMenu = {
  codigo: Pagina.AcompanhamentoNegociacao,
  texto: 'Download do Acompanhamento',
  link: PATH_ACOMPANHAMENTO_NEGOCIACOES,
  iconeMenu: <IconeDownload />,
};

const DETALHES_CARGA_PAGINA: PaginaRotaAcesso = {
  codigo: Pagina.DetalhesCarga,
  link: PATH_DETALHES_CARGA,
  componentePagina: <DetalhesCarga />,
};

const DETALHES_CARGA_FRETE_TABELADO_PAGINA: PaginaRotaAcesso = {
  codigo: Pagina.DetalhesCarga,
  link: PATH_DETALHES_CARGA_NEGOCIACAO_AUTOMATIAZADAS,
  componentePagina: <DetalhesCarga />,
};

const INFORMACOES_NEGOCIACAO_PAGINA: PaginaRotaAcesso = {
  codigo: Pagina.InformacoesNegociacao,
  link: PATH_INFORMACOES_NEGOCIACAO,
  componentePagina: <InformacoesNegociacao />,
  headerAlternativo: HEADER_ALTERNATIVO_INFORMACOES_NEGOCIACAO,
};

const INFORMACOES_NEGOCIACAO_AUTOMATIZADAS_PAGINA: PaginaRotaAcesso = {
  codigo: Pagina.InformacoesNegociacaoAutomatizadas,
  link: PATH_INFORMACOES_NEGOCIACAO_AUTOMATIZADAS,
  componentePagina: <InformacoesNegociacao />,
  headerAlternativo: HEADER_ALTERNATIVO_INFORMACOES_NEGOCIACAO_AUTOMATIZADAS,
};

const MINHAS_CARGAS_PAGINA: PaginaRotaAcesso = {
  codigo: Pagina.MinhasCargas,
  link: PATH_MINHAS_CARGAS,
  componentePagina: <MinhasCargas />,
};

const APROVACOES_SUPERVISOR_ITEM_MENU: PaginaItemMenu = {
  codigo: Pagina.AprovacoesSupervisor,
  texto: 'Aprovações do Supervisor',
  link: PATH_APROVACOES_SUPERVISOR,
  iconeMenu: <IconeCorretoCirculo />,
};

const APROVACOES_SUPERVISOR_PAGINA: PaginaRotaAcesso = {
  codigo: Pagina.AprovacoesSupervisor,
  link: PATH_APROVACOES_SUPERVISOR,
  componentePagina: <AprovacoesSupervisor />,
};

const DETALHES_APROVACAO_SUPERVISOR_PAGINA: PaginaRotaAcesso = {
  codigo: Pagina.DetalhesAprovacaoSupervisor,
  link: PATH_DETALHES_APROVACAO_SUPERVISOR,
  componentePagina: <DetalhesAprovacaoSupervisor />,
};

const DETALHES_APROVACAO_NEGOCIACAO_SUPERVISOR_PAGINA: PaginaRotaAcesso = {
  codigo: Pagina.DetalhesAprovacaoSupervisor,
  link: PATH_DETALHES_APROVACAO_NEGOCIACAO_SUPERVISOR,
  componentePagina: <PaginaDetalhesAprovacaoNegociacaoSupervisor />,
};

const CONFIGURACOES_PAGINA: PaginaRotaAcesso = {
  codigo: Pagina.Configuracoes,
  link: PATH_CONFIGURACOES,
  componentePagina: <PaginaConfiguracoes />,
};

const CONFIGURACOES_ITEM_MENU: PaginaItemMenu = {
  codigo: Pagina.Configuracoes,
  texto: 'Configurações',
  link: PATH_CONFIGURACOES,
  iconeMenu: <IconeEngrenagem />,
};

const PAINEL_GESTAO_PAGINA: PaginaRotaAcesso = {
  codigo: Pagina.PainelGestao,
  link: PATH_PAINEL_GESTAO,
  componentePagina: <PainelGestao />,
};

const PAINEL_GESTAO_ITEM_MENU: PaginaItemMenu = {
  codigo: Pagina.PainelGestao,
  texto: 'Painel de Gestão',
  link: PATH_PAINEL_GESTAO,
  iconeMenu: <IconeGraficoPizza />,
};

const DEMANDAS_ANDAMENTO_PAGINA: PaginaRotaAcesso = {
  codigo: Pagina.DemandasAndamento,
  link: PATH_DEMANDAS_ANDAMENTO,
  componentePagina: <PaginaDemandasAndamento />,
};

const DETALHES_DEMANDAS_DIARIA_PAGINA: PaginaRotaAcesso = {
  codigo: Pagina.DetalhesDemandaDiaria,
  link: PATH_DETALHES_DEMANDAS_DIARIA,
  componentePagina: <PaginaDetalhesDemandasDiaria />,
};

const PAINEL_INDICADORES_PAGINA: PaginaRotaAcesso = {
  codigo: Pagina.Indicadores,
  link: PATH_INDICADORES,
  componentePagina: <Indicadores />,
};

const PAINEL_INDICADORES_ITEM_MENU: PaginaItemMenu = {
  codigo: Pagina.Indicadores,
  texto: 'Indicadores',
  link: PATH_INDICADORES,
  iconeMenu: <IconeGrafico />,
};

const paginasAcesso: PaginaRotaAcesso[] = [
  DETALHES_CARGA_PAGINA,
  DETALHES_CARGA_FRETE_TABELADO_PAGINA,
  NEGOCIACOES_AUTOMATIZADAS,
  INFORMACOES_NEGOCIACAO_AUTOMATIZADAS_PAGINA,
  INFORMACOES_NEGOCIACAO_PAGINA,
  MINHAS_NEGOCIACOES_PAGINA,
  DISTRIBUICAO_CARGAS_PAGINA,
  UPLOAD_SIMULADOR_PAGINA,
  ACOMPANHAMENTO_NEGOCIACOES_PAGINA,
  MINHAS_CARGAS_PAGINA,
  APROVACOES_SUPERVISOR_PAGINA,
  DETALHES_APROVACAO_SUPERVISOR_PAGINA,
  DETALHES_APROVACAO_NEGOCIACAO_SUPERVISOR_PAGINA,
  MOTORISTAS_VEICULOS,
  CONFIGURACOES_PAGINA,
  DEMANDAS_ANDAMENTO_PAGINA,
  DETALHES_DEMANDAS_DIARIA_PAGINA,
  PAINEL_GESTAO_PAGINA,
  PAINEL_INDICADORES_PAGINA,
];

const paginasMenu: PaginaItemMenu[] = [
  MINHAS_NEGOCIACOES_ITEM_MENU,
  NEGOCIACOES_AUTOMATIZADAS_ITEM_MENU,
  DISTRIBUICAO_CARGAS_ITEM_MENU,
  UPLOAD_SIMULADOR_ITEM_MENU,
  ACOMPANHAMENTO_NEGOCIACOES_ITEM_MENU,
  APROVACOES_SUPERVISOR_ITEM_MENU,
  PAINEL_GESTAO_ITEM_MENU,
  PAINEL_INDICADORES_ITEM_MENU,
  MOTORISTAS_VEICULOS_ITEM_MENU,
  CONFIGURACOES_ITEM_MENU,
];

export const obterItemsMenuAcesso = (
  paginasAcessoLiberado: PaginaType[],
): PaginaItemMenu[] =>
  paginasMenu.filter((item) => {
    return paginasAcessoLiberado.includes(item.codigo);
  });

export const obterPaginasAcesso = (): PaginaRotaAcesso[] => paginasAcesso;
