import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Send } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import { RootState } from '../../../../../store/reducer';

import InputMonetario from '../../../../../shared/components/input/InputMonetario';
import BotaoIcone from '../../../../../shared/components/button/BotaoIcone';
import spacingInPixels from '../../../../../shared/functions/materialUtils';
import msgConstants from '../../../../../shared/constants/msgConstants';
import { formatarNumeroParaStringMonetariaComSimbolo } from '../../../../../shared/functions/dinheiroUtils';
import {
  abrirSnackbarErro,
  abrirSnackbarSucesso,
} from '../../../../../shared/components/snackbar/redux/snackbarAction';
import PropostaStatus, {
  PropostaStatusType,
  verificarPropostaRecusada,
} from '../../../../../shared/constants/PropostaStatus';

import {
  AtualizarPropostaRequest,
  PropostaNegociacao,
} from '../../../api/informacoesNegociacaoApiTypes';
import { atualizarProposta } from '../../../api/informacoesNegociacaoService';

import { PropostaAEnviar } from '../../../types/PropostaAEnviar';
import TipoProposta from '../../../types/TipoProposta';

import { atualizarPropostasOperation } from '../../../redux/informacoesNegociacaoOperation';

import { calcularPorcentagem } from '../../valoresCard/functions/valoresCardUtils';

import { abrirModalEnviarProposta } from '../redux/motoristasCardAction';

import PorcentagemProposta from './PorcentagemProposta';

const useStyles = makeStyles((theme) => ({
  inputBox: {
    height: 40,
  },
  textoPorcentagem: {
    marginLeft: 0,
    marginRight: 0,
    minWidth: spacingInPixels(theme, 8),
    justifyContent: 'center',
  },
  textoValor: {
    fontWeight: 500,
  },
  root: {
    '& .MuiInputBase-root': {
      color: theme.palette.text.primary,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&:not(.Mui-error):not(.Mui-disabled):hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
    '& .MuiInputLabel-root': {
      '&:not(.Mui-error)': {
        color: theme.palette.primary.main,
      },
    },
    height: 40,
    width: 215,
    '& .MuiFormLabel-root': {
      fontSize: 14,
    },
  },
}));

type ColunaContrapropostaProps = {
  proposta: PropostaNegociacao;
  isNegociador?: boolean;
};

const CampoContraproposta = ({
  proposta,
  isNegociador,
}: ColunaContrapropostaProps): JSX.Element => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { idNegociacao, negociacao, negociacaoConcluida } = useSelector(
    (state: RootState) => state.informacoesNegociacaoReducer,
  );

  const [porcentagem, setPorcentagem] = useState('');
  const [valorCampo, setValorCampo] = useState<number | undefined>(undefined);

  useEffect(() => {
    setValorCampo(
      isNegociador
        ? proposta.contrapropostaNegociador || undefined
        : proposta.contrapropostaMotorista || undefined,
    );
  }, [
    isNegociador,
    proposta.contrapropostaMotorista,
    proposta.contrapropostaNegociador,
  ]);

  useEffect(() => {
    setPorcentagem(
      calcularPorcentagem(valorCampo, negociacao?.valorFrotaPropria),
    );
  }, [valorCampo, negociacao?.valorFrotaPropria]);

  const abrirModalContraproposta = () => {
    const tipoProposta = verificarPropostaRecusada(proposta.status)
      ? TipoProposta.RETOMAR_PROPOSTA
      : TipoProposta.CONTRAPROPOSTA;

    const propostaAEnviar: PropostaAEnviar = {
      proposta: {
        ...proposta,
        propostaAtual: valorCampo || 0,
        contrapropostaNegociador: valorCampo,
      },
      tipoProposta,
    };

    dispatch(abrirModalEnviarProposta(propostaAEnviar));
  };

  const salvarContrapropostaMotorista = async () => {
    const request = {
      propostaMotorista: valorCampo,
    } as AtualizarPropostaRequest;

    await atualizarProposta(idNegociacao, proposta.id, request)
      .then(async () => {
        await atualizarPropostasOperation(idNegociacao)(dispatch);
        dispatch(
          abrirSnackbarSucesso({ mensagem: msgConstants.DADOS_SALVOS_SUCESSO }),
        );
      })
      .catch((e: Error) => {
        dispatch(abrirSnackbarErro({ mensagem: e.message }));
      });
  };

  const verificarBotaoDesabilitado = (
    arrayPropostaStatusHabilitado: Array<PropostaStatusType>,
  ): boolean =>
    negociacaoConcluida ||
    !valorCampo ||
    !arrayPropostaStatusHabilitado.includes(proposta.status);

  const identificadorInputMonetario = isNegociador ? 'negociador' : 'motorista';

  return (
    <Grid container alignItems="center" justify="space-between">
      <Grid item>
        <Box className={classes.inputBox}>
          {negociacaoConcluida ? (
            <Typography className={classes.textoValor}>
              {formatarNumeroParaStringMonetariaComSimbolo(valorCampo)}
            </Typography>
          ) : (
            <InputMonetario
              id={`contraproposta-${identificadorInputMonetario}-${proposta.id}`}
              value={valorCampo}
              placeholder="R$ 0,00"
              className={classes.root}
              onChange={(novoValor) => {
                setValorCampo(novoValor);
              }}
              label={`Contraproposta ${identificadorInputMonetario}`}
            />
          )}
        </Box>
      </Grid>
      <Grid item>
        <PorcentagemProposta
          styles={classes.textoPorcentagem}
          porcentagem={porcentagem}
        />
      </Grid>
      <Grid item>
        {isNegociador ? (
          <BotaoIcone
            color="primary"
            descricao={`Enviar contraproposta para o motorista ${proposta.motorista}`}
            onClick={abrirModalContraproposta}
            tamanho="small"
            desabilitado={verificarBotaoDesabilitado([
              PropostaStatus.CONTRAPROPOSTA,
              PropostaStatus.RECUSA_NEGOCIADOR,
            ])}
          >
            <Send />
          </BotaoIcone>
        ) : (
          <BotaoIcone
            color="primary"
            descricao={`Salvar contraproposta do motorista ${proposta.motorista}`}
            onClick={salvarContrapropostaMotorista}
            tamanho="small"
            desabilitado={verificarBotaoDesabilitado([
              PropostaStatus.AGUARDANDO,
            ])}
          >
            <Send />
          </BotaoIcone>
        )}
      </Grid>
    </Grid>
  );
};

CampoContraproposta.defaultProps = {
  isNegociador: false,
};

export default CampoContraproposta;
