export const pegarIntervalo = (now: Date): number => {
  const currentMinute = now.getMinutes();
  let tempoAteProxima = 0;

  if (currentMinute < 30) {
    tempoAteProxima = (30 - currentMinute) * 60;
  } else {
    tempoAteProxima = (60 - currentMinute) * 60;
  }
  tempoAteProxima -= now.getSeconds();
  return tempoAteProxima;
};
