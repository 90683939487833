import React from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import TabsList from 'shared/components/tabs/TabsList';
import Tab from 'shared/components/tabs/Tab';
import {
  Negociacao,
  OrigemNegociacao,
} from 'features/informacoesNegociacao/api/informacoesNegociacaoApiTypes';
import CampoPesquisaMotoristas from './CampoPesquisaMotoristas';
import { RootState } from '../../../../store/reducer';
import { fecharChat } from '../../../chatNegociacao/redux/chatNegociacaoAction';
import ValorAprovado from './components/ValorAprovado';
import Abas from './constants/AbasMotoristas';
import { BoxDireita, BoxEsquerda } from './styles';
import CancelarNegociacaoMenu from './CancelarNegociacaoMenu';

type CabecalhoAbasCardProps = {
  aba: string;
  setAba: (aba: Abas) => void;
  mostrarAba: boolean;
  valorPesquisa: string;
  setMostrarAba: React.Dispatch<React.SetStateAction<boolean>>;
  setValorPesquisa: React.Dispatch<React.SetStateAction<string>>;
  negociacao: Negociacao | undefined;
};

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '8px',
    transition: 'width 0.3s linear',
  },
  containerValor: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '8px',
  },
  abas: {
    minHeight: 'inherit',
    maxHeight: 'inherit',
    height: 40,
    padding: '0 20px',
  },
  listaAbas: {
    height: 40,
  },
}));

const CabecalhoAbasCard = ({
  aba,
  mostrarAba,
  valorPesquisa,
  setAba,
  setMostrarAba,
  setValorPesquisa,
  negociacao,
}: CabecalhoAbasCardProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    idNegociacao,
    valorNovaMeta,
    quantidadeMotoristas,
    quantidadePropostas,
  } = useSelector((state: RootState) => ({
    idNegociacao: state.informacoesNegociacaoReducer.idNegociacao,
    valorNovaMeta: state.informacoesNegociacaoReducer.negociacao?.valorNovaMeta,
    quantidadeMotoristas:
      state.informacoesNegociacaoReducer.motoristas?.length || 0,
    quantidadePropostas:
      (state.informacoesNegociacaoReducer.propostas?.length || 0) +
      (state.informacoesNegociacaoReducer.propostasAplicativo?.length || 0),
  }));

  const atualizarUrl = (id: number) => {
    if (negociacao?.origemNegociacao === OrigemNegociacao.freteTabelado) {
      const estadoPersonalizado = {
        origemFreteTabelado: true,
        idFilial: negociacao?.carga.idFilialDestino,
      };
      history.push({
        pathname: `/negociacoes-automatizada/${id}`,
        state: estadoPersonalizado,
      });
    } else {
      history.push(`/minhas-negociacoes/${id}`, {
        origemFreteTabelado: false,
        idFilial: negociacao?.carga.idFilialDestino,
      });
    }
  };

  const mudarAba = (_event: React.ChangeEvent<unknown>, novaAba: Abas) => {
    setAba(novaAba);

    if (novaAba === Abas.Disponiveis) {
      dispatch(fecharChat());
      atualizarUrl(idNegociacao);
    }
  };

  return (
    <header
      className={
        valorNovaMeta === null ? classes.container : classes.containerValor
      }
    >
      <BoxEsquerda>
        <CampoPesquisaMotoristas
          mostrarAba={mostrarAba}
          valorPesquisa={valorPesquisa}
          setMostrarAba={setMostrarAba}
          setValorPesquisa={setValorPesquisa}
        />
        {mostrarAba && (
          <TabsList aba={aba} mudarAba={mudarAba} styles={classes.listaAbas}>
            <Tab
              label={`Disponíveis (${quantidadeMotoristas})`}
              value={Abas.Disponiveis}
              className={classes.abas}
            />
            <Tab
              label={`Em negociação (${quantidadePropostas})`}
              value={Abas.EmNegociacao}
              className={classes.abas}
            />
          </TabsList>
        )}
      </BoxEsquerda>

      {mostrarAba && (
        <BoxDireita>
          {valorNovaMeta && <ValorAprovado valorAprovado={valorNovaMeta} />}
          <CancelarNegociacaoMenu />
        </BoxDireita>
      )}
    </header>
  );
};

export default CabecalhoAbasCard;
