import { useTheme } from '@material-ui/core';
import * as React from 'react';
import { SVGProps } from 'react';

const IconeExclamacaoCirculo = ({
  fill,
  width,
  height,
  ...outros
}: SVGProps<SVGSVGElement>): JSX.Element => {
  const theme = useTheme();
  return (
    <svg
      width={width || 20}
      height={height || 20}
      viewBox="0 0 20 20"
      fill="none"
      {...outros}
    >
      <path
        d="M10 5a1 1 0 0 0-1 1v4a1 1 0 1 0 2 0V6a1 1 0 0 0-1-1Zm.92 8.62a.758.758 0 0 0-.09-.18l-.12-.15a.999.999 0 0 0-1.09-.21 1.15 1.15 0 0 0-.33.21 1 1 0 0 0-.21 1.09.9.9 0 0 0 .54.54.94.94 0 0 0 .76 0 .9.9 0 0 0 .54-.54c.051-.12.078-.25.08-.38a1.362 1.362 0 0 0 0-.2.639.639 0 0 0-.08-.18ZM10 0a10 10 0 1 0 0 20 10 10 0 0 0 0-20Zm0 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16Z"
        fill={fill || theme.palette.cores.laranja[700]}
      />
    </svg>
  );
};

export default IconeExclamacaoCirculo;
