import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, makeStyles } from '@material-ui/core';
import { obterPorcentagemFormatada } from 'shared/functions/numberUtils';
import BadgeCronometro from 'shared/components/cronometro/BadgeCronometro';
import Porcentagem from 'shared/components/porcentagem/Porcentagem';
import {
  PATH_DETALHES_APROVACAO_NEGOCIACAO_SUPERVISOR,
  PATH_DETALHES_APROVACAO_SUPERVISOR,
} from 'shared/constants/pathConstants';
import { PropostaSupervisor } from '../../api/aprovacoesSupervisorApiTypes';
import ValorProposta from '../../components/ValorProposta';
import NegociadorFilial from '../../components/NegociadorFilial';
import useStylesCardProposta from '../../styles/cardProposta';

import CardPropostaGreguimSugerido from './CardPropostaGreguimSugerido';

export type CardPropostaAprovadaType = {
  proposta: PropostaSupervisor;
};

export const useStyles = makeStyles(() => ({
  pointer: {
    cursor: 'pointer',
  },
}));

const CardPropostaPendente = ({
  proposta,
}: CardPropostaAprovadaType): JSX.Element => {
  const history = useHistory();

  const {
    valorProposta,
    valorFrotaPropria,
    nomeNegociador,
    cidadeFilial,
    dataSolicitacaoAnalise,
    idProposta,
    idNegociacao,
    cidadePrincipal,
    romaneio,
    valorPropostaRobo,
  } = proposta;

  const classes = useStylesCardProposta();
  const estilosLocais = useStyles();

  const urlDetalheAprovacaoSupervisor = (valorPropostaRobo
    ? PATH_DETALHES_APROVACAO_NEGOCIACAO_SUPERVISOR
    : PATH_DETALHES_APROVACAO_SUPERVISOR
  )
    .replace(':idProposta', idProposta.toString())
    .replace(':idNegociacao', idNegociacao.toString());

  return (
    <Box
      onClick={() => history.push(urlDetalheAprovacaoSupervisor)}
      className={`${classes.container} ${estilosLocais.pointer}`}
    >
      <Box className={classes.boxSpaceBetween}>
        <ValorProposta
          valor={valorProposta}
          propostaSugeridaPorGreguim={!!valorPropostaRobo}
        />

        <Porcentagem
          styles={classes.badgePorcentagem}
          porcentagem={obterPorcentagemFormatada(
            valorProposta,
            valorPropostaRobo || valorFrotaPropria,
          )}
          complemento="Target"
        />
      </Box>

      <NegociadorFilial
        negociador={nomeNegociador}
        filial={cidadeFilial}
        cidadePrincipal={cidadePrincipal}
        romaneio={romaneio}
      />
      <Box className={classes.boxIntervalo}>
        <BadgeCronometro dataSolicitacaoAnalise={dataSolicitacaoAnalise} />
        {valorPropostaRobo && <CardPropostaGreguimSugerido />}
      </Box>
    </Box>
  );
};

export default CardPropostaPendente;
