import { createReducer } from '@reduxjs/toolkit';
import { PropostaSupervisor } from '../api/aprovacoesSupervisorApiTypes';

import {
  listarAprovacoesSupervisor,
  removerAprovacaoSupervisor,
} from './aprovacoesSupervisorAction';

export type AprovacoesSupervisorState = {
  propostasPendentes: PropostaSupervisor[];
  propostasAprovadas: PropostaSupervisor[];
  propostasReprovadas: PropostaSupervisor[];
  propostasNovaMeta: PropostaSupervisor[];
  propostasRobo: PropostaSupervisor[];
  propostasAprovadasGreguim: PropostaSupervisor[];
};

const estadoInicial: Readonly<AprovacoesSupervisorState> = {
  propostasPendentes: [],
  propostasAprovadas: [],
  propostasReprovadas: [],
  propostasNovaMeta: [],
  propostasRobo: [],
  propostasAprovadasGreguim: [],
};

const ordenarPropostasSupervisor = (
  propostas: PropostaSupervisor[] | undefined,
): PropostaSupervisor[] => {
  if (!propostas) return [];
  propostas.sort((a, b) => {
    if (a.dataSolicitacaoAnalise > b.dataSolicitacaoAnalise) return 1;
    if (a.dataSolicitacaoAnalise < b.dataSolicitacaoAnalise) return -1;
    return 0;
  });

  return propostas;
};

const aprovacoesSupervisorReducer = createReducer(estadoInicial, (builder) => {
  builder
    .addCase(listarAprovacoesSupervisor, (_state, action) => ({
      propostasPendentes: ordenarPropostasSupervisor(
        action.payload?.propostasPendentes,
      ),
      propostasAprovadas: ordenarPropostasSupervisor(
        action.payload?.propostasAprovadas,
      ),
      propostasReprovadas: ordenarPropostasSupervisor(
        action.payload?.propostasReprovadas,
      ),
      propostasNovaMeta: ordenarPropostasSupervisor(
        action.payload?.propostasNovaMeta,
      ),
      propostasRobo: ordenarPropostasSupervisor(action.payload?.propostasRobo),
      propostasAprovadasGreguim: ordenarPropostasSupervisor(
        action.payload?.propostasAprovadasGreguim,
      ),
    }))
    .addCase(removerAprovacaoSupervisor, (state, action) => ({
      ...state,
      propostasPendentes: state.propostasPendentes.filter(
        (proposta) => proposta.idProposta !== action.payload,
      ),
    }))
    .addDefaultCase((state) => state);
});

export default aprovacoesSupervisorReducer;
