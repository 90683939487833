import { formatarNumeroParaStringMonetariaComSimbolo } from '../../../shared/functions/dinheiroUtils';
import { obterCodigoFormatado } from '../../../shared/functions/romaneioUtils';
import {
  formatarDistancia,
  formatarPeso,
  formatarVolume,
} from '../../../shared/functions/unidadeUtils';

import {
  MotoristaNegociacao,
  Negociacao,
  PropostaNegociacao,
} from '../api/informacoesNegociacaoApiTypes';

export const novaLinha = '%0a';
const empresa = 'SLIM';

const obterNumeroCidadesEntrega = (negociacao: Negociacao): string =>
  negociacao.carga.numeroCidadesEntrega === 0
    ? ''
    : `${novaLinha}Número de cidades: *${negociacao.carga.numeroCidadesEntrega}*`;

const obterCodigoCarga = (negociacao: Negociacao): string =>
  obterCodigoFormatado(negociacao.carga.romaneio);

export const criarMensagemEnviarProposta = (
  motorista: MotoristaNegociacao,
  negociacao: Negociacao,
): string => {
  return `Olá, *${
    motorista.nome
  }*, temos uma carga que pode ser do seu interesse.
  ${novaLinha} ${novaLinha}*Informações da carga:*
  ${novaLinha} ${novaLinha}Empresa: *${empresa}*
  ${novaLinha}ID: *${obterCodigoCarga(negociacao)}*
  ${novaLinha}Macro-região: *${negociacao.carga.macro}*
  ${obterNumeroCidadesEntrega(negociacao)}
  ${novaLinha}Número de entregas: *${negociacao.carga.entregas}*
  ${novaLinha}Quilometragem prevista: *${formatarDistancia(
    negociacao.carga.quilometragem,
  )}*
  ${novaLinha}Peso: *${formatarPeso(negociacao.carga.peso)}*
  ${novaLinha}Volume: *${formatarVolume(negociacao.carga.volume)}*
  ${novaLinha}Carregamento: *${negociacao.dataCarregamento}*
  ${novaLinha}Entrega final: *${
    negociacao.dataEntregaFinal == null ? '-' : negociacao.dataEntregaFinal
  }*
  ${novaLinha}Valor do frete: *${formatarNumeroParaStringMonetariaComSimbolo(
    negociacao.valorNegociacao,
  )}*
  ${novaLinha} ${novaLinha}Se tiver interesse nessa carga, favor responder essa mensagem o mais rápido possível. 
  ${novaLinha} ${novaLinha}Desde já agradecemos a sua atenção!`;
};

export const criarMensagemEnviarContraproposta = (
  proposta: PropostaNegociacao,
): string => {
  return `Então, ${
    proposta.motorista
  }, o que você acha de ${formatarNumeroParaStringMonetariaComSimbolo(
    proposta.propostaAtual,
  )}?`;
};

export const criarMensagemEnviarPropostaFinal = (
  proposta: PropostaNegociacao,
  negociacao: Negociacao,
): string => {
  return `*${proposta.motorista}*, negociação concluída!
  ${novaLinha} ${novaLinha}Empresa: *${empresa}*
  ${novaLinha}ID: *${obterCodigoCarga(negociacao)}*
  ${novaLinha}Valor do frete: *${formatarNumeroParaStringMonetariaComSimbolo(
    proposta.propostaAtual,
  )}*
  ${novaLinha}Carregamento: *${negociacao.dataCarregamento}*
  ${novaLinha} ${novaLinha}Favor entrar em contato para maiores esclarecimentos.
  ${novaLinha} ${novaLinha}Desde já agradecemos a sua atenção!`;
};

export const criarMensagemRetomarProposta = (
  proposta: PropostaNegociacao,
  negociacao: Negociacao,
): string => {
  return `Olá, *${
    proposta.motorista
  }*, queremos retomar a negociação da carga abaixo.
  ${novaLinha} ${novaLinha}*Informações da carga:*
  ${novaLinha} ${novaLinha}Empresa: *${empresa}*
  ${novaLinha}ID: *${obterCodigoCarga(negociacao)}*
  ${novaLinha}Macro-região: *${negociacao.carga.macro}*
  ${obterNumeroCidadesEntrega(negociacao)}
  ${novaLinha}Número de entregas: *${negociacao.carga.entregas}*
  ${novaLinha}Quilometragem prevista: *${formatarDistancia(
    negociacao.carga.quilometragem,
  )}*
  ${novaLinha}Peso: *${formatarPeso(negociacao.carga.peso)}*
  ${novaLinha}Volume: *${formatarVolume(negociacao.carga.volume)}*
  ${novaLinha}Carregamento: *${negociacao.dataCarregamento}*
  ${novaLinha}Entrega final: *${
    negociacao.dataEntregaFinal == null ? '-' : negociacao.dataEntregaFinal
  }*
  ${novaLinha}Valor do frete: *${formatarNumeroParaStringMonetariaComSimbolo(
    proposta.propostaAtual,
  )}*
  ${novaLinha} ${novaLinha}Você tem interesse em renegociar conosco? 
  ${novaLinha}Aguardo um retorno.`;
};

export const enviarMensagemWhatsApp = (
  contato: string,
  texto: string,
): void => {
  window.open(
    `https://api.whatsapp.com/send?phone=55${contato}&text=${texto}`,
    '_blank',
  );
};

export const verificarSeNegociacaoTemObservacao = (
  negociacao: Negociacao | undefined,
): boolean =>
  Boolean(
    negociacao &&
      negociacao.observacoes &&
      negociacao.observacoes.trim() !== '',
  );

export default {
  criarMensagemEnviarProposta,
  criarMensagemEnviarContraproposta,
  criarMensagemEnviarPropostaFinal,
  enviarMensagemWhatsApp,
  verificarSeNegociacaoTemObservacao,
  criarMensagemRetomarProposta,
};
