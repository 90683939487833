import React, { useEffect, useMemo, useState } from 'react';
import { RootState } from 'store/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';
import {
  DemandaFilial,
  MetricasFilial as MetricasFilialType,
} from 'features/dashboards/filiais/api/listagemFiliaisApiTypes';
import { Demanda } from 'features/dashboards/negociadores/api/listagemNegociadoresApiTypes';
import { buscarDetalhesFilial } from 'features/dashboards/utils/modalDetalhesFilial';
import MetricasDemandasFilial from 'features/dashboards/components/modalDetalhes/MetricasDemandasFilial';
import MetricasFilial from './MetricasFilial';
import { MetricasDemanda } from '../MetricasDemandas';
import TabelaNegociacoes from '../TabelaNegociacoes';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: `0 ${spacingInPixels(theme, 7)}`,
    height: 'calc(100% - 56px)',
  },
}));

const estiloContainerTabela = {
  height: 'calc(90vh - 390px)',
  marginBottom: '24px',
};

interface ConteudoDetalhesFilialProps {
  filial: DemandaFilial | undefined;
}

const ConteudoDetalhesFilial = ({
  filial,
}: ConteudoDetalhesFilialProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { metricas, negociacoes } = useSelector(
    (reducer: RootState) => reducer.detalhesFilialReducer,
  );

  const [carregando, setCarregando] = useState(false);
  const [negociacoesFilial, setNegociacoesFilial] = useState<
    Demanda[] | undefined
  >([]);
  const [metricasFilial, setMetricasFilial] = useState<
    MetricasFilialType | undefined
  >(undefined);

  const mensagemListaNegociacoesVazia = `Ops algo aconteceu, por algum motivo não conseguimos listar as negociações para a filial ${
    filial?.nomeFilial || ''
  }.`;

  const dadosDemandas: MetricasDemanda[] = [
    {
      chave: 'NaoIniciadas',
      valor: filial?.demandas?.naoIniciadas,
    },
    {
      chave: 'EmNegociacao',
      valor: filial?.demandas?.emNegociacao,
    },
    {
      chave: 'Negociadas',
      valor: filial?.demandas?.concluidas,
    },
    {
      chave: 'Aprovacao',
      valor: filial?.demandas?.emAprovacao,
    },
  ];

  useMemo(() => {
    setMetricasFilial(metricas);
    setNegociacoesFilial(negociacoes);
  }, [metricas, negociacoes]);

  useEffect(() => {
    buscarDetalhesFilial(
      dispatch,
      setCarregando,
      filial?.idFilial,
      filial?.nomeFilial,
    );
  }, [dispatch, filial]);

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      className={classes.container}
      spacing={4}
    >
      <Grid item>
        <MetricasFilial metricas={metricasFilial} carregando={carregando} />
      </Grid>
      <Grid item>
        <MetricasDemandasFilial metricasDemandas={dadosDemandas} />
      </Grid>
      <Grid item>
        <TabelaNegociacoes
          carregando={carregando}
          demandas={negociacoesFilial}
          estilo={estiloContainerTabela}
          mensagemErro={mensagemListaNegociacoesVazia}
        />
      </Grid>
    </Grid>
  );
};

export default ConteudoDetalhesFilial;
