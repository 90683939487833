import React from 'react';
import IconeCaixa from 'assets/icones/IconeCaixa';
import IconeRelogio from 'assets/icones/IconeRelogio';
import { ChaveOrdenacaoDetalhesDemandas } from 'features/detalhesDemandasDiaria/constants/Constants';
import { DadoSkeleton } from 'shared/components/skeleton/CustomSkeleton';
import { DadosCabecalho } from 'shared/components/tabelaDemandas/types/TabelaTypes';
import theme from 'theme';

export const dadosSkeleton: DadoSkeleton[] = [
  { borderRadius: 50, width: 20 },
  { borderRadius: 4, width: '80%' },
  { borderRadius: 4, width: '90%' },
  { borderRadius: 4, width: '90%' },
  { borderRadius: 4, width: '60%' },
  { borderRadius: 4, width: '90%' },
  { borderRadius: 4, width: '90%' },
  { borderRadius: 4, width: '70%' },
  { borderRadius: 4, width: '60%' },
  { borderRadius: 4, width: '70%' },
  { borderRadius: 4, width: '90%' },
];

export const dadosItensCabecalho: DadosCabecalho[] = [
  { conteudo: '', descricao: '' },
  { conteudo: 'SLA', descricao: 'SLA' },
  { conteudo: 'Negociador', descricao: 'Negociador' },
  {
    conteudo: 'Filial',
    descricao: 'Filial',
    possuiOrdenacao: true,
    chave: ChaveOrdenacaoDetalhesDemandas.FILIAL,
  },
  {
    conteudo: (
      <IconeCaixa
        width={18}
        height={18}
        fill={theme.palette.cores.cinza[800]}
      />
    ),
    descricao: 'Número de entregas',
    possuiOrdenacao: true,
    chave: ChaveOrdenacaoDetalhesDemandas.NUMERO_ENTREGAS,
  },
  {
    conteudo: (
      <IconeRelogio
        width={18}
        height={18}
        fill={theme.palette.cores.cinza[800]}
      />
    ),
    descricao: 'Lead time/Cycle time',
  },
  { conteudo: 'Cidade', descricao: 'Cidade' },
  { conteudo: 'Romaneio', descricao: 'Romaneio' },
  { conteudo: 'Última proposta', descricao: 'Última proposta' },
  { conteudo: 'Alvo', descricao: 'Alvo' },
  {
    conteudo: 'Status',
    descricao: 'Status',
    possuiOrdenacao: true,
    chave: ChaveOrdenacaoDetalhesDemandas.STATUS,
  },
];
