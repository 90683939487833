import {
  estaEmBranco,
  removerAcentuacao,
} from '../../../../../../shared/functions/stringUtils';
import { PropostaNegociacao } from '../../../../api/informacoesNegociacaoApiTypes';

export const filtrarPropostas = (
  propostas: PropostaNegociacao[],
  pesquisa: string,
): PropostaNegociacao[] =>
  propostas.filter((proposta) =>
    removerAcentuacao(proposta.motorista.toLocaleLowerCase()).includes(
      removerAcentuacao(pesquisa.toLocaleLowerCase()),
    ),
  );

export const exibirMensagemPesquisaSemResultados = (
  propostasFiltradas: PropostaNegociacao[],
  propostasFiltradasAplicativo: PropostaNegociacao[],
  pesquisa: string,
): boolean =>
  propostasFiltradas.length === 0 &&
  propostasFiltradasAplicativo.length === 0 &&
  !estaEmBranco(pesquisa);

export const validarPropostaFocada = (
  idPropostaComFoco: number | undefined,
  idProposta: number,
): boolean =>
  idPropostaComFoco !== undefined && idPropostaComFoco === idProposta;
