import React, { ReactNode } from 'react';

import IconButton from '@material-ui/core/IconButton';
import { PropTypes } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import spacingInPixels from '../../functions/materialUtils';
import DisablingTooltip from '../tooltip/DisablingTooltip';

export type TamanhoIcone = 'small' | 'medium';

export type BotaoIconeProps = {
  descricao: string;
  color?: PropTypes.Color;
  desabilitado?: boolean;
  tamanho?: TamanhoIcone;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  maxWidthTooltip?: string;
  children: ReactNode;
  classe?: string;
};

const IconButtonPaddingSpacing1 = withStyles((theme) => ({
  root: {
    padding: spacingInPixels(theme, 1),
  },
}))(IconButton);

const BotaoIcone = ({
  color,
  desabilitado = false,
  tamanho = 'medium',
  descricao,
  onClick,
  maxWidthTooltip,
  children,
  classe,
}: BotaoIconeProps): JSX.Element => {
  return (
    <DisablingTooltip
      maxWidth={maxWidthTooltip}
      disabled={desabilitado}
      title={descricao}
    >
      <IconButtonPaddingSpacing1
        aria-label={descricao}
        onClick={onClick}
        color={color}
        disabled={desabilitado}
        size={tamanho}
        className={classe}
      >
        {children}
      </IconButtonPaddingSpacing1>
    </DisablingTooltip>
  );
};

export default BotaoIcone;
