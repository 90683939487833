import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { textoRegras } from '../constants/texto';
import TextoRegras from '../components/TextoRegras';
import { useStyles } from '../styles/PaginaPoliticaDePrivacidade';

const PaginaPoliticaDePrivacidade = (): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.tituloCenter}>
        <Typography variant="h3" color="primary">
          Política de Privacidade - Motoristas de Agregados
        </Typography>
      </Box>
      <Box className={classes.centerPadding}>
        {textoRegras.map((texto) => (
          <Box className={classes.conteudoCentralizado}>
            <TextoRegras propsRegras={texto} />
          </Box>
        ))}
      </Box>
    </>
  );
};

export default PaginaPoliticaDePrivacidade;
