import InputIntervaloTempo from 'features/configuracoes/components/InputIntervaloTempo';
import { validarInputIntervalo } from 'features/configuracoes/utils/inputIntervaloTempo';
import React from 'react';
import { IntervaloTempo } from 'shared/types/apiTypes';
import { InputTempo2Props } from 'features/configuracoes/types';
import {
  ContainerInputIntervaloTempo,
  DescricaoInputIntervaloTempo,
} from '../../../styles';

const InputTempo = ({
  estadoTempo,
  setEstadoTempo,
  id,
  descricao,
  label,
  textoAjuda,
  estadoErroTempo,
  setEstadoTempoErro,
}: InputTempo2Props): JSX.Element => {
  const onChangeTempoInativo = (intervalo?: IntervaloTempo) => {
    setEstadoTempo(intervalo);
    setEstadoTempoErro(!validarInputIntervalo(intervalo));
  };

  return (
    <ContainerInputIntervaloTempo>
      <DescricaoInputIntervaloTempo>{descricao}</DescricaoInputIntervaloTempo>
      <InputIntervaloTempo
        id={id}
        value={estadoTempo}
        onChange={onChangeTempoInativo}
        label={label}
        error={estadoErroTempo}
        helperText={estadoErroTempo ? textoAjuda : undefined}
        size="medium"
      />
    </ContainerInputIntervaloTempo>
  );
};

export default InputTempo;
