import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@material-ui/core';
import { RootState } from 'store/reducer';
import { useQuery } from '../../../shared/functions/routeUtils';

import {
  atualizarNegociacaoOperation,
  atualizarPropostasOperation,
  atualizarMotoristasOperation,
} from '../redux/informacoesNegociacaoOperation';
import {
  atualizarIdPropostaComFoco,
  limparDados,
} from '../redux/informacoesNegociacaoAction';

import { limparDados as limparDadosValores } from './valoresCard/redux/valoresCardAction';

import { limparDados as limparDadosMotoristas } from './agregadosCard/redux/motoristasCardAction';

import InformacoesCarga from './informacoesCarga/InformacoesCarga';
import MotoristasCard from './agregadosCard/MotoristasCard';

interface RouteParams {
  id: string;
}
interface LocationState {
  origemFreteTabelado: string | null;
  idFilial: number | null;
}

const InformacoesNegociacao = (): JSX.Element => {
  const dispatch = useDispatch();
  const params = useParams<RouteParams>();
  const query = useQuery();
  const location = useLocation<LocationState>();
  const teste = params.id.split('=') || undefined;

  const idNegociacao = Number(params.id) || Number(params.id.split('?')[0]);
  const idProposta = Number(query.get('idProposta')) || Number(teste[1]);

  let origemFrete = false;
  let idFilial: number | null = null;

  if (location.state) {
    origemFrete = Boolean(location.state?.origemFreteTabelado);
    idFilial = location.state?.idFilial;
  }

  const { colaboradorAssistido } = useSelector(
    (state: RootState) => state.minhasNegociacoesReducer,
  );

  useEffect(() => {
    dispatch(atualizarIdPropostaComFoco(idProposta));
  });

  useEffect(() => {
    if (origemFrete) {
      atualizarNegociacaoOperation(
        idNegociacao,
        colaboradorAssistido[0]?.id,
      )(dispatch);
      atualizarPropostasOperation(
        idNegociacao,
        colaboradorAssistido[0]?.id,
      )(dispatch);
      atualizarMotoristasOperation(idFilial, true)(dispatch);
    } else {
      atualizarNegociacaoOperation(
        idNegociacao,
        colaboradorAssistido[0]?.id,
      )(dispatch);
      atualizarPropostasOperation(
        idNegociacao,
        colaboradorAssistido[0]?.id,
      )(dispatch);
      atualizarMotoristasOperation(idFilial, false)(dispatch);
    }

    return () => {
      dispatch(limparDados());
      dispatch(limparDadosValores());
      dispatch(limparDadosMotoristas());
    };
  }, [dispatch, idNegociacao, colaboradorAssistido, origemFrete, idFilial]);

  return (
    <Box component="section">
      <InformacoesCarga />
      <MotoristasCard />
    </Box>
  );
};

export default InformacoesNegociacao;
