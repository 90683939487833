import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Botao from 'shared/components/button/BotaoRubik';
import spacingInPixels from 'shared/functions/materialUtils';
import { RootState } from 'store/reducer';
import TypographyChat from '../../TypographyChat';
import BotaoCancelar from './BotaoCancelar';
import {
  onClickBotaoAceitarProposta,
  onClickBotaoAceitarPropostaEPedirAprovacao,
} from '../../../utils/aceiteProposta';

const AceiteProposta = (): JSX.Element => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const [enviando, setEnviando] = useState(false);

  const { chatIdNegociacao, chatIdProposta } = useSelector(
    (state: RootState) => state.chatNegociacaoReducer,
  );

  const informacoesNegociacaoReducer = useSelector(
    (state: RootState) => state.informacoesNegociacaoReducer,
  );

  const propostaComValorInicialGreguim = useMemo(
    () => informacoesNegociacaoReducer?.negociacao?.sugestaoValorInicialRobo,
    [informacoesNegociacaoReducer?.negociacao?.sugestaoValorInicialRobo],
  );

  const onClickAceitar = async (): Promise<void> => {
    await onClickBotaoAceitarProposta(
      chatIdNegociacao,
      chatIdProposta,
      setEnviando,
      dispatch,
    );
  };

  const onClickAceitarPropostaGreguim = async (): Promise<void> => {
    await onClickBotaoAceitarPropostaEPedirAprovacao(
      chatIdNegociacao,
      chatIdProposta,
      setEnviando,
      dispatch,
    );
  };

  const valorContrapropostaAtual = useMemo(() => {
    const {
      propostas,
      propostasAplicativo,
      idPropostaComFoco,
    } = informacoesNegociacaoReducer;
    return propostas
      .concat(propostasAplicativo)
      .find((propostaNegociacao) => idPropostaComFoco === propostaNegociacao.id)
      ?.propostaAtual;
  }, [informacoesNegociacaoReducer]);

  const validarAceiteGreguim = (): boolean =>
    !!propostaComValorInicialGreguim &&
    !!valorContrapropostaAtual &&
    propostaComValorInicialGreguim >= valorContrapropostaAtual;

  return (
    <Box textAlign="center">
      <Box marginBottom="16px">
        <TypographyChat>
          {`Você tem certeza que deseja aceitar essa proposta${
            validarAceiteGreguim()
              ? ' e enviar para aprovação do supervisor'
              : ''
          }?`}
        </TypographyChat>
      </Box>
      <Box
        marginTop={spacingInPixels(theme, 2)}
        marginBottom={spacingInPixels(theme, 1)}
      >
        {validarAceiteGreguim() ? (
          <Botao
            fullWidth
            variant="contained"
            color="primary"
            onClick={onClickAceitarPropostaGreguim}
            disabled={enviando}
            startIcon={
              enviando && <CircularProgress color="primary" size="16px" />
            }
          >
            Aceitar proposta e pedir aprovação
          </Botao>
        ) : (
          <Botao
            fullWidth
            variant="contained"
            color="primary"
            onClick={onClickAceitar}
            disabled={enviando}
            startIcon={
              enviando && <CircularProgress color="primary" size="16px" />
            }
          >
            Aceitar Proposta
          </Botao>
        )}
      </Box>
      <BotaoCancelar disabled={enviando} />
    </Box>
  );
};

export default AceiteProposta;
