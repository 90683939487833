import { makeStyles } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';

export const useStyles = makeStyles((theme) => ({
  sliderBox: {
    maxWidth: (sliderTamanho: { sliderTamanho?: boolean }) =>
      sliderTamanho ? spacingInPixels(theme, 77) : spacingInPixels(theme, 50),
    borderLeft: `${spacingInPixels(theme, 0.5)} solid`,
    borderColor: theme.palette.cores.cinza[300],
    paddingLeft: spacingInPixels(theme, 3),
    height: 120,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& .MuiSlider-valueLabel': {
      left: 'calc(-86% - 5px)',
      top: '-42px',

      '& > span': {
        width: 42,
        height: 42,
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: '9.5px 8px',
      fontSize: 14,
    },
    '& .MuiSlider-markLabel': {
      top: '20px',
    },
  },
  sliderStyle: { color: `${theme.palette.cores.azul[600]} !important` },
  boxSorriso: {
    display: 'flex',
    width: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    flexDirection: 'column',
    padding: '5vh 0 10vh 0',

    '& p': {
      maxWidth: 440,
      textAlign: 'center',
      fontWeight: 400,
      color: theme.palette.cores.cinza[500],
    },
  },
  infoCard: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '7px 16px 7px 16px',
    background: 'rgba(97, 97, 97, 0.9)',
    color: 'rgba(255, 255, 255, 1)',
    fontSize: 14,
    borderRadius: 4,
    fontWeight: 400,
    width: 'fit-content',
    marginTop: spacingInPixels(theme, 7),
    marginBottom: spacingInPixels(theme, 3),
  },
  formControl: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '-15px',
  },
  hrMinMax: {
    display: 'flex',
    height: '5px',
    borderRadius: '100px',
    marginTop: '35px',
    flexDirection: 'row',
    borderLeft: `${spacingInPixels(theme, 3)} solid`,
    borderColor: theme.palette.cores.cinza[300],
  },
  BoxMinMax: {
    marginRight: '-128px',
  },
  inputNumber: {
    marginRight: '2px',
    maxWidth: '44%',
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
      appearance: 'textfield',
    },
  },
  TextHelper: {
    color: 'black',
    fontWeight: 500,
  },
  spanMax: {
    color: theme.palette.cores.cinza[500],
    fontSize: '10px',
  },
  botoes: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '15px',
  },
}));
