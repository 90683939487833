import { PrioridadeCargaNegociacao } from 'features/dashboards/negociadores/api/listagemNegociadoresApiTypes';
import { Dispatch } from 'redux';
import {
  abrirSnackbarErro,
  abrirSnackbarSucesso,
} from 'shared/components/snackbar/redux/snackbarAction';
import { orderBy } from 'lodash';
import {
  DetalhesDemandaAndamento,
  DetalhesDemandaConcluida,
  DetalhesDemandaDiaria,
  DetalhesDemandaEmAprovacao,
  DetalhesDemandaNaoIniciada,
  DetalhesNegociacaoRecusada,
  StatusDemandaEnum,
} from '../api/detalhesDemandasDiariaApiTypes';
import {
  obterDetalhesDemandasAndamento,
  obterDetalhesDemandasDiaria,
  obterDetalhesDemandasNaoIniciadas,
  obterDetalhesDemandasConcluidas,
  inserirTarifarioDemandaConcluida,
  obterDetalhesNegociacoesRecusadas,
  obterDetalhesDemandasEmAprovacao,
  obterDetalhesDemandasTarfifarioNaoAtualizadas,
} from '../api/detalhesDemandasDiariaManager';
import {
  ChaveOrdenacaoDetalhesDemandas,
  MENSAGEM_ERRO_TARIFAR_DEMANDA,
  MENSAGEM_SUCESSO_TARIFAR_DEMANDA,
} from '../constants/Constants';

const tratarErro = (mensagem: string) => (dispatch: Dispatch) => {
  dispatch(abrirSnackbarErro({ mensagem }));
};

const tratarSucesso = (mensagem: string) => (dispatch: Dispatch) => {
  dispatch(abrirSnackbarSucesso({ mensagem }));
};

export const ordenarPorFilial = (
  demandas: DetalhesDemandaDiaria[],
  sentidoOrdenacao: number,
): DetalhesDemandaDiaria[] =>
  [...demandas].sort(
    (a, b) => a.nomeFilial.localeCompare(b.nomeFilial) * sentidoOrdenacao,
  );

export const ordenarPorNumeroEntregas = (
  demandas: DetalhesDemandaDiaria[],
  sentidoOrdenacao: number,
): DetalhesDemandaDiaria[] =>
  [...demandas].sort(
    (a, b) => (a.quantidadeEntregas - b.quantidadeEntregas) * sentidoOrdenacao,
  );

export const ordenarPorStatusDemanda = (
  demandas: DetalhesDemandaDiaria[],
  sentidoOrdenacao: number,
): DetalhesDemandaDiaria[] =>
  [...demandas].sort((a, b) => (a.status - b.status) * sentidoOrdenacao);

export const ordenarPorNomeNegociador = (
  demandas: DetalhesDemandaDiaria[],
  sentidoOrdenacao: number,
): DetalhesDemandaDiaria[] =>
  [...demandas].sort(
    (a, b) =>
      a.nomeNegociador.localeCompare(b.nomeNegociador) * sentidoOrdenacao,
  );

export const ordenarDemandas = (
  demandas: DetalhesDemandaDiaria[],
  chaveOrdenacao: ChaveOrdenacaoDetalhesDemandas,
  ordenacaoCrescente: boolean,
): DetalhesDemandaDiaria[] => {
  const SENTIDO_CRESCENTE = 1;
  const SENTIDO_DECRESCENTE = -1;
  const sentidoOrdenacao = ordenacaoCrescente
    ? SENTIDO_CRESCENTE
    : SENTIDO_DECRESCENTE;

  switch (chaveOrdenacao) {
    case ChaveOrdenacaoDetalhesDemandas.FILIAL:
      return ordenarPorFilial(demandas, sentidoOrdenacao);
    case ChaveOrdenacaoDetalhesDemandas.NUMERO_ENTREGAS:
      return ordenarPorNumeroEntregas(demandas, sentidoOrdenacao);
    case ChaveOrdenacaoDetalhesDemandas.STATUS:
      return ordenarPorStatusDemanda(demandas, sentidoOrdenacao);
    case ChaveOrdenacaoDetalhesDemandas.NEGOCIADOR:
      return ordenarPorNomeNegociador(demandas, sentidoOrdenacao);
    case ChaveOrdenacaoDetalhesDemandas.DATA_HORA:
      return orderBy(
        demandas,
        (o) => o.dataConclusao,
        !ordenacaoCrescente ? [] : ['desc'],
      );
    default:
      return demandas;
  }
};

export const ordenarPorPrioridadeCarga = (
  demandas: DetalhesDemandaDiaria[],
): DetalhesDemandaDiaria[] =>
  [...demandas].sort(
    (a, b) =>
      new Date(a.dataCarregamento).getTime() -
      new Date(b.dataCarregamento).getTime(),
  );

export const filtrarDemandasPendentes = (
  demandas: DetalhesDemandaDiaria[],
  dataBase: Date,
): DetalhesDemandaDiaria[] =>
  demandas.filter((demanda) => {
    const dataCarregamentoDemanda = new Date(demanda.dataCarregamento);
    dataCarregamentoDemanda.setHours(0, 0, 0, 0);
    return (
      dataCarregamentoDemanda.getTime() < dataBase.getTime() &&
      [
        StatusDemandaEnum.NaoIniciada,
        StatusDemandaEnum.EmNegociacao,
        StatusDemandaEnum.EmAprovacao,
      ].includes(demanda.status)
    );
  });

export const buscarDetalhesDemandas = (
  setDemandas: (demandas: DetalhesDemandaDiaria[]) => void,
  setErro: (erro: boolean) => void,
) => async (dispatch: Dispatch): Promise<void> =>
  obterDetalhesDemandasDiaria()
    .then((response) => {
      setDemandas(
        ordenarPorPrioridadeCarga(
          response.map((demanda) => ({ ...demanda } as DetalhesDemandaDiaria)),
        ),
      );
    })
    .catch((error) => {
      setErro(true);
      tratarErro(error.message)(dispatch);
    });

const mapearDemandaEmAprovacaoParaDemandaDiaria = (
  demanda: DetalhesDemandaEmAprovacao,
): DetalhesDemandaDiaria => {
  return {
    dataCarregamento: demanda.sla,
    valorUltimaContraproposta: demanda.valorMenorContraproposta,
    savingUltimaContraproposta: demanda.savingValorMenorContraproposta,
    idNegociacao: demanda.idNegociacao,
    nomeNegociador: demanda.nomeNegociador,
    nomeFilial: demanda.nomeFilial,
    romaneio: demanda.romaneio,
    prioridadeCarga: demanda.prioridadeCarga,
    quantidadeEntregas: demanda.quantidadeEntregas,
    valorMeta: demanda.valorMeta,
    savingValorMeta: demanda.savingValorMeta,
    cycleTime: demanda.cycleTime,
    quantidadePedidosAprovacao: demanda.quantidadePedidosAprovacao,
    nomeCidade: demanda.nomeCidade,
    leadTime: demanda.leadTime,
    status: StatusDemandaEnum.EmAprovacao,
  } as DetalhesDemandaDiaria;
};

export const buscarDetalhesDemandasEmAprovacao = (
  setDemandas: (demandas: DetalhesDemandaDiaria[]) => void,
  setErro: (erro: boolean) => void,
) => async (dispatch: Dispatch): Promise<void> =>
  obterDetalhesDemandasEmAprovacao()
    .then((response) => {
      setDemandas(
        ordenarPorPrioridadeCarga(
          response?.map((demanda) =>
            mapearDemandaEmAprovacaoParaDemandaDiaria(demanda),
          ) || [],
        ),
      );
    })
    .catch((error) => {
      setErro(true);
      tratarErro(error.message)(dispatch);
    });

const mapearDemandaAndamentoParaDemandaDiaria = (
  demanda: DetalhesDemandaAndamento,
): DetalhesDemandaDiaria => {
  return {
    dataCarregamento: demanda.sla,
    valorUltimaContraproposta: demanda.valorMenorContraproposta,
    savingUltimaContraproposta: demanda.savingValorMenorContraproposta,
    idNegociacao: demanda.idNegociacao,
    nomeNegociador: demanda.nomeNegociador,
    nomeFilial: demanda.nomeFilial,
    romaneio: demanda.romaneio,
    prioridadeCarga: demanda.prioridadeCarga,
    quantidadeEntregas: demanda.quantidadeEntregas,
    valorMeta: demanda.valorMeta,
    savingValorMeta: demanda.savingValorMeta,
    cycleTime: demanda.cycleTime,
    quantidadePedidosAprovacao: demanda.quantidadePedidosAprovacao,
    nomeCidade: demanda.nomeCidade,
    status: StatusDemandaEnum.EmNegociacao,
  } as DetalhesDemandaDiaria;
};

export const buscarDetalhesDemandasAndamento = (
  setDemandas: (demandas: DetalhesDemandaDiaria[]) => void,
  setErro: (erro: boolean) => void,
) => async (dispatch: Dispatch): Promise<void> =>
  obterDetalhesDemandasAndamento()
    .then((response) => {
      setDemandas(
        ordenarPorPrioridadeCarga(
          response.map((demanda) =>
            mapearDemandaAndamentoParaDemandaDiaria(demanda),
          ),
        ),
      );
    })
    .catch((error) => {
      setErro(true);
      tratarErro(error.message)(dispatch);
    });

const mapearDemandaConcluidaParaDemandaDiaria = (
  demanda: DetalhesDemandaConcluida,
): DetalhesDemandaDiaria => {
  return {
    idNegociacao: demanda.idNegociacao,
    nomeNegociador: demanda.nomeNegociador,
    nomeFilial: demanda.nomeFilial,
    romaneio: demanda.romaneio,
    nomeCidade: demanda.nomeCidade,
    valorConclusao: demanda.valorConclusao,
    tarifarioCadastrado: demanda.tarifarioCadastrado,
    savingValorConclusao: demanda.savingValorConclusao,
    dataCriacao: new Date(demanda.dataCriacao),
    dataConclusao: new Date(demanda.dataConclusao),
  } as DetalhesDemandaDiaria;
};

export const buscarDetalhesDemandasConcluidasEnaoAtualizadas = (
  setDemandas: (demandas: DetalhesDemandaDiaria[]) => void,
  setErro: (erro: boolean) => void,
  concluidasOUnaoAtualizadas: boolean,
  pagina?: number,
  setTotalPage?: (pagina: number) => void,
  setLoading?: (loading: boolean) => void,
) => (dispatch: Dispatch): void => {
  const fetchData = concluidasOUnaoAtualizadas
    ? obterDetalhesDemandasConcluidas()
    : obterDetalhesDemandasTarfifarioNaoAtualizadas(pagina);

  fetchData
    .then((response) => {
      const demandas = Array.isArray(response)
        ? response
        : response.negociacoes;

      if (setTotalPage && !Array.isArray(response)) {
        setTotalPage(response.totalPaginas);
      }

      setDemandas(
        demandas.map((demanda) =>
          mapearDemandaConcluidaParaDemandaDiaria(demanda),
        ),
      );
    })
    .catch((error) => {
      setErro(true);
      tratarErro(error.message)(dispatch);
    })
    .finally(() => {
      if (setLoading) {
        setLoading(false);
      }
    });
};

const mapearDemandaNaoIniciadaParaDemandaDiaria = (
  demanda: DetalhesDemandaNaoIniciada,
): DetalhesDemandaDiaria => {
  return {
    dataCarregamento: demanda.sla,
    nomeNegociador: demanda.nomeNegociador,
    nomeFilial: demanda.nomeFilial,
    romaneio: demanda.romaneio,
    prioridadeCarga: demanda.prioridadeCarga,
    quantidadeEntregas: demanda.quantidadeEntregas,
    nomeCidade: demanda.nomeCidade,
    leadTime: demanda.leadTime,
    status: StatusDemandaEnum.NaoIniciada,
  } as DetalhesDemandaDiaria;
};

export const buscarDetalhesDemandasNaoIniciadas = (
  setDemandas: (demandas: DetalhesDemandaDiaria[]) => void,
  setErro: (erro: boolean) => void,
) => async (dispatch: Dispatch): Promise<void> =>
  obterDetalhesDemandasNaoIniciadas()
    .then((response) => {
      setDemandas(
        ordenarPorPrioridadeCarga(
          response.map((demanda) =>
            mapearDemandaNaoIniciadaParaDemandaDiaria(demanda),
          ),
        ),
      );
    })
    .catch((error) => {
      setErro(true);
      tratarErro(error.message)(dispatch);
    });

export const inserirTarifario = (
  idNegociacao: number,
  setStatusTarifarioCadastrado: (erro: boolean) => void,
) => async (dispatch: Dispatch): Promise<void> =>
  inserirTarifarioDemandaConcluida(idNegociacao)
    .then(() => {
      setStatusTarifarioCadastrado(true);
      tratarSucesso(MENSAGEM_SUCESSO_TARIFAR_DEMANDA)(dispatch);
    })
    .catch(() => {
      setStatusTarifarioCadastrado(false);
      tratarErro(MENSAGEM_ERRO_TARIFAR_DEMANDA)(dispatch);
    });

const mapearNegociacaoRecusadaParaDemandaDiaria = (
  demanda: DetalhesNegociacaoRecusada,
): DetalhesDemandaDiaria => {
  return {
    ...demanda,
    dataCarregamento: '',
    status: StatusDemandaEnum.Cancelada,
    quantidadeEntregas: 0,
    prioridadeCarga: PrioridadeCargaNegociacao.baixa,
  } as DetalhesDemandaDiaria;
};

export const buscarDetalhesNegociacoesRecusadas = (
  setDemandas: (demandas: DetalhesDemandaDiaria[]) => void,
  setErro: (erro: boolean) => void,
) => async (dispatch: Dispatch): Promise<void> =>
  obterDetalhesNegociacoesRecusadas()
    .then((response) => {
      setDemandas(
        response.map((demanda) =>
          mapearNegociacaoRecusadaParaDemandaDiaria(demanda),
        ),
      );
    })
    .catch((error) => {
      setErro(true);
      tratarErro(error.message)(dispatch);
    });
