import React from 'react';
import { makeStyles } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';
import ContainerEvolucaoDeCargas from './evolucaoDeCargas/ContainerEvolucaoDeCargas';
import ContainerIndicadoresMacros from './indicadoresMacros/ContainerIndicadoresMacros';
import ContainerAtendimento from './atendimento/ContainerAtendimento';
import ContainerAlerta from './alerta/ContainerAlerta';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacingInPixels(theme, 4),
  },
}));

const ContainerConteudoGeral = (): JSX.Element => {
  const classes = useStyles();

  return (
    <section className={classes.container}>
      <ContainerEvolucaoDeCargas />
      <ContainerIndicadoresMacros />
      <ContainerAtendimento />
      <ContainerAlerta />
    </section>
  );
};

export default ContainerConteudoGeral;
