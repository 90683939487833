import { createReducer } from '@reduxjs/toolkit';
import { PropostaNegociacao } from '../../../api/informacoesNegociacaoApiTypes';
import { PropostaAEnviar } from '../../../types/PropostaAEnviar';

import {
  abrirModalEnviarProposta,
  fecharModalEnviarProposta,
  limparDados,
  abrirModalRecusarProposta,
  fecharModaisRecusarProposta,
  atualizarPesquisa,
  abrirModalCancelarNegociacao,
  fecharModalCancelarNegociacao,
  abrirModalReatribuirNegociacao,
  atualizarMotivoRecusarProposta,
  fecharModalRecusarProposta,
} from './motoristasCardAction';

export type MotoristasCardState = {
  modalEnviarPropostaAberto: boolean;
  modalReatribuirNegociacaoAberto: boolean;
  modalRecusarPropostaAberto: boolean;
  modalCancelarNegociacaoAberto: boolean;
  propostaAEnviar?: PropostaAEnviar;
  propostaARecusar?: PropostaNegociacao;
  pesquisa: string;
  motivoRecusarProposta: string;
};

export const estadoInicial: Readonly<MotoristasCardState> = {
  modalReatribuirNegociacaoAberto: false,
  modalEnviarPropostaAberto: false,
  modalRecusarPropostaAberto: false,
  modalCancelarNegociacaoAberto: false,
  propostaAEnviar: undefined,
  propostaARecusar: undefined,
  pesquisa: '',
  motivoRecusarProposta: '',
};

const motoristasCardReducer = createReducer(estadoInicial, (builder) => {
  builder
    .addCase(abrirModalEnviarProposta, (state, action) => ({
      ...state,
      modalEnviarPropostaAberto: true,
      propostaAEnviar: action.payload,
    }))
    .addCase(fecharModalEnviarProposta, (state) => ({
      ...state,
      modalEnviarPropostaAberto: false,
      propostaAEnviar: undefined,
    }))
    .addCase(abrirModalRecusarProposta, (state, action) => ({
      ...state,
      modalRecusarPropostaAberto: true,
      propostaARecusar: action.payload,
    }))
    .addCase(abrirModalReatribuirNegociacao, (state) => ({
      ...state,
      modalRecusarPropostaAberto: false,
      modalReatribuirNegociacaoAberto: true,
    }))
    .addCase(fecharModalRecusarProposta, (state) => ({
      ...state,
      modalRecusarPropostaAberto: false,
    }))
    .addCase(fecharModaisRecusarProposta, (state) => ({
      ...state,
      modalReatribuirNegociacaoAberto: false,
      modalRecusarPropostaAberto: false,
      propostaARecusar: undefined,
      motivoRecusarProposta: '',
    }))
    .addCase(abrirModalCancelarNegociacao, (state) => ({
      ...state,
      modalCancelarNegociacaoAberto: true,
    }))
    .addCase(fecharModalCancelarNegociacao, (state) => ({
      ...state,
      modalCancelarNegociacaoAberto: false,
    }))
    .addCase(atualizarPesquisa, (state, action) => ({
      ...state,
      pesquisa: action.payload,
    }))
    .addCase(atualizarMotivoRecusarProposta, (state, action) => ({
      ...state,
      motivoRecusarProposta: action.payload,
    }))
    .addCase(limparDados, () => estadoInicial)
    .addDefaultCase((state) => state);
});

export default motoristasCardReducer;
