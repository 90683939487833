import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core';

import spacingInPixels from '../../functions/materialUtils';
import { removerDropdownItensRepetidosReducer } from '../../functions/arrayUtils';

import DropdownCheckbox from '../dropdown/dropdownCheckbox';
import { DropdownCheckboxOption } from '../dropdown/dropdownCheckbox/views/DropdownCheckbox';

export type FiltroListaProps = {
  lista: DropdownCheckboxOption[];
  onChange: (opcoesSelecionadas: string[]) => void;
  opcoesSelecionadas: string[];
};

const FiltroLista = ({
  lista,
  onChange,
  opcoesSelecionadas,
}: FiltroListaProps): JSX.Element => {
  const [opcoes, setOpcoes] = useState<DropdownCheckboxOption[]>([]);
  const theme = useTheme();

  useEffect(() => {
    setOpcoes(
      lista
        .reduce(removerDropdownItensRepetidosReducer, [])
        .sort((a, b) => a.label.localeCompare(b.label)),
    );
  }, [lista]);

  return (
    <Box display="flex">
      <DropdownCheckbox
        id="filial"
        texto="Filial"
        titulo="Filial de destino"
        opcoes={opcoes}
        opcoesSelecionadas={opcoesSelecionadas}
        marginLeft={spacingInPixels(theme, 5)}
        onChange={onChange}
      />
    </Box>
  );
};

export default FiltroLista;
