import React, { useEffect } from 'react';
import BoxPagina from 'shared/components/box/BoxPagina';
import { Box, Typography, makeStyles } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';
import { useDispatch } from 'react-redux';
import { buscarDadosPermissaoUsuario } from 'features/login/redux/loginOperation';
import TabelaMotoristasVeiculos from './TabelaMotoristasVeiculos';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: spacingInPixels(theme, 6),
  },
  titulo: {
    marginBottom: spacingInPixels(theme, 3),
  },
}));

const MotoristasVeiculos = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    buscarDadosPermissaoUsuario()(dispatch);
  }, [dispatch]);

  return (
    <BoxPagina component="section" className={classes.content}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h4" color="primary" className={classes.titulo}>
          Motoristas e Veículos
        </Typography>
      </Box>
      <TabelaMotoristasVeiculos />
    </BoxPagina>
  );
};

export default MotoristasVeiculos;
