const VAZIO = '';

const ESPACO = ' ';

export const TAMANHO_MAXIMO_CARACTERES_STACK_ERRO = 700;

export const LIMITE_CARACTERES_TEXTO_LIVRE = 1000;

export enum TipoLog {
  ErroJs,
}

export default {
  VAZIO,
  ESPACO,
  TAMANHO_MAXIMO_CARACTERES_STACK_ERRO,
};
