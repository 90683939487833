import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { formatarNumeroParaStringMonetariaComSimbolo } from 'shared/functions/dinheiroUtils';
import IconeGraphicFreteTabelado from 'assets/icones/IconeGraphicFreteTabelado';
import Temporizador from 'shared/components/temporizador/Temporizador';
import { Negociacao } from 'features/informacoesNegociacao/api/informacoesNegociacaoApiTypes';
import NegociacaoStatus from 'shared/constants/NegociacaoStatus';

const useStyles = makeStyles(() => ({
  contadorFreteTabelado: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#E8F5E9',
    padding: '8px 24px',
    margin: '0px 0px 0px 0.4px',
    height: '64px',
    boxShadow: '0px 2px 4px 0px #E0E0E0',
  },
  boxFreteTabelado: {
    display: 'flex',
    color: '#757575',
  },
  boxTemporizador: {
    display: 'flex',
    alignItems: 'center',
    width: '77px',
    height: '34px',
    borderRadius: '4px',
    background: '#2E7D32',
  },
  textDinheiro: {
    fontSize: '14px',
    color: '#008C41',
    fontWeight: 500,
    marginLeft: '2px',
  },
}));

type TemporizadorFreteTabeladoProps = {
  negociacao: Negociacao | undefined;
  valorProposta: number | undefined;
};

const TemporizadorFreteTabelado = ({
  negociacao,
  valorProposta,
}: TemporizadorFreteTabeladoProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Box className={classes.contadorFreteTabelado}>
      <Box className={classes.boxFreteTabelado}>
        <IconeGraphicFreteTabelado style={{ marginRight: '16px' }} />
        Essa região possui Frete Tabelado de:
        <Typography className={classes.textDinheiro}>
          {formatarNumeroParaStringMonetariaComSimbolo(
            valorProposta || negociacao?.valorNegociacao,
          )}
        </Typography>
      </Box>
      <Box className={classes.boxTemporizador}>
        <Temporizador
          tempo={negociacao?.carga.tempoParaExpirarNegociacoesEmMinutos}
          tempoData={negociacao?.dataCarregamento}
          validacao={
            negociacao?.status === NegociacaoStatus.Concluida ||
            negociacao?.status === NegociacaoStatus.NovaNegociacao
              ? false
              : negociacao?.carga.isTempoExpirado
          }
        />
      </Box>
    </Box>
  );
};

export default TemporizadorFreteTabelado;
