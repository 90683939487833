import { useTheme } from '@material-ui/core/styles';
import * as React from 'react';
import { SVGProps } from 'react';

const IconeCaminhao = ({
  width = 22,
  height = 20,
  viewBox = '0 0 22 20',
  fill,
  ...outros
}: SVGProps<SVGSVGElement>): JSX.Element => {
  const theme = useTheme();

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      {...outros}
    >
      <path
        d="M0 10.5v5a1 1 0 0 0 1 1h1a3 3 0 0 0 6 0h6a3 3 0 0 0 6 0h1a1 1 0 0 0 1-1v-12a3 3 0 0 0-3-3h-9a3 3 0 0 0-3 3v2H5a3 3 0 0 0-2.4 1.2L.2 9.9a.61.61 0 0 0-.07.14l-.06.11a1 1 0 0 0-.07.35Zm16 6a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm-7-13a1 1 0 0 1 1-1h9a1 1 0 0 1 1 1v11h-.78a3 3 0 0 0-4.44 0H9v-11Zm-2 6H3l1.2-1.6a1 1 0 0 1 .8-.4h2v2Zm-3 7a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm-2-5h5v2.78a3 3 0 0 0-4.22.22H2v-3Z"
        fill={fill || theme.palette.cores.azulMartins[500]}
      />
    </svg>
  );
};

export default IconeCaminhao;
