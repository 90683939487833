import { useTheme } from '@material-ui/core';
import * as React from 'react';
import { SVGProps } from 'react';

const IconeGraficoPizza = ({
  fill,
  ...outros
}: SVGProps<SVGSVGElement>): JSX.Element => {
  const theme = useTheme();
  return (
    <svg width={20} height={20} fill="none" {...outros}>
      <path
        d="M17 10h-7V3c0-.6-.4-1-1-1-5 0-9 4-9 9s4 9 9 9 9-4 9-9c0-.6-.4-1-1-1Zm-7 7.9c-3.8.6-7.4-2.1-7.9-5.9C1.5 8.2 4.2 4.6 8 4.1V11c0 .6.4 1 1 1h6.9c-.4 3.1-2.8 5.5-5.9 5.9ZM13 0c-.6 0-1 .4-1 1v6c0 .6.4 1 1 1h6c.6 0 1-.4 1-1 0-3.9-3.1-7-7-7Zm1 6V2.1c2 .4 3.5 1.9 3.9 3.9H14Z"
        fill={fill || theme.palette.cores.azulMartins[500]}
      />
    </svg>
  );
};

export default IconeGraficoPizza;
