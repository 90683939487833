import { typeRegras } from '../types';

export const textoRegras: typeRegras[] = [
  {
    texto: [
      `Quando você utiliza de nossos serviços, está confiando a nós suas
  informações. Entendemos que isso é uma grande responsabilidade e a
  Martins Comércio e Serviço de Distribuição S.A. trabalha duro para
  proteger essas informações e colocar você no controle.`,
      `Esta Política de Privacidade destina-se a ajudar você a entender quais
  informações coletamos, porque as coletamos e como você pode atualizar,
  gerenciar e as excluir. Afinal, elas são suas informações. Queremos
  orientar, em termos claros para você, as bases legais que utilizamos,
  seus direitos e opções de escolha, assim como explicar como preservamos
  a sua privacidade.`,
      `A Política de Privacidade é complementar aos Termos de Uso do nosso
  site. Caso exista alguma contradição entre esta política e os nossos
  Termos de Uso, esta Política de Privacidade prevalecerá quando o assunto
  disser respeito ao tratamento de dados pessoais e privacidade, e os
  Termos de Uso prevalecerão em relação a outros assuntos relacionados ao
  site.`,
      `Os artigos abaixo foram embasados na Lei Geral de Proteção de Dados, que
pode ser lida na íntegra no portal do planalto.gov.br, e caso ainda
tenha dúvidas ao final da leitura, você poderá solucionar suas dúvidas
através dos canais que identificamos ao final desta Política.`,
      `E antes de começarmos a falar, vamos garantir que estamos na mesma
página. Ao final do documento colocamos um glossário, para você poder
consultar sempre que quiser e ter certeza sobre o que estamos falando.
Todas as definições que estão lá foram retiradas da Lei Geral de
Proteção de Dados.`,
    ],
  },
  {
    titulo: '1. Quais dados seus nós coletamos',
    texto: `É importante entender que coletamos dados de diferentes fontes, e cada um, com
    sua finalidade específica. Pra isso, vamos dividi-los em categorias para deixar tudo
    mais claro:`,
    regras: [
      {
        titulo: `1.1 Dados de navegação:`,
        texto: `O simples fato de você acessar o site já nos fornece alguns de seus dados. Dentre
        eles, nós tratamos os seguintes:
        `,
        regras: [
          {
            textoTituloNegrio: `IP – `,
            texto: `O seu endereço IP (Protocolo de Internet) é fornecido pelo simples fato de
          acessar a nossa plataforma online. Não se preocupe, não armazenamos este
          dado e, somente com ele, não é possível saber quem você é. Podemos, no
          máximo, ter uma vaga noção da sua localização geográfica. É importante
          realçar que a sua provedora de internet consegue relacionar o seu endereço
          IP com a sua pessoa.
          `,
          },
          {
            texto: `Navegador, modelo de aparelho, sistema operacional e cookies – Todos esses
          dados são anonimizados por nós, ou seja, não somos capazes de nenhuma
          forma relacionar estes dados com você. Eles são tratados apenas para as
          métricas da plataforma, para melhorarmos ao máximo a sua experiência.`,
          },
        ],
      },
      {
        titulo: `1.2 Dados pessoais:`,
        texto: `O seu cadastro nos fornece dados que armazenamos com muita cautela em nosso
      banco de dados. Para garantirmos integridade, adaptamos nossa plataforma com as
      melhores práticas de segurança da informação do mercado. Implementamos
      mecanismos antifraude, verificamos nossas conexões e, continuamente, estamos
      vigiando e monitorando os nossos sistemas para garantir sua segurança.`,
        regras: [
          {
            textoTituloNegrio: `Nome completo –`,
            texto: ` Coletamos o seu nome completo para personalizar a sua
        experiência. Além do mais, precisamos deste dado por questões jurídicas, para
        oficializar os nossos contratos e para registro de auditorias sobre as nossas
        transações.`,
          },
          {
            textoTituloNegrio: `CPF – `,
            texto: ` O seu CPF é um dado necessário para as nossas transações. Nossos
    contratos e quesitos legais operam com identificação única das partes: assim
    como você precisa do nosso CPNJ, nós precisamos te identificar judicialmente,
    por isso coletamos o seu CPF.
    `,
          },
          {
            textoTituloNegrio: `Telefone – `,
            texto: `O seu telefone é essencial para entramos em contato. Precisamos
  de manter a nossa parceria, e pra isso, vamos te contactar sempre que
  necessário. Não se preocupe, garantimos não utilizar seus meios de contato
  para qualquer tipo de mensagens não relacionadas com o nosso propósito.`,
          },
        ],
      },
      {
        titulo: `1.3 Demais dados do cadastro:`,
        texto: `Para finalizar, ainda possuímos outros dados seus, mas que não são relacionados
        diretamente com a sua pessoa. Todavia, mantemos a mesma disciplina e cautela no
        tratamento deles. `,
        regras: [
          {
            textoTituloNegrio: `Modelo do veículo - `,
            texto: `Para a nossa logística interna, precisamos saber qual o
        modelo do seu veículo, de modo a calcularmos as melhores propostas para
        você, visto a sua capacidade de carga.`,
          },
          {
            textoTituloNegrio: `Placa do veículo - `,
            texto: `Sua placa é necessária para nossos registros jurídicos,
        precisamos ter controle sobre a legalidade dos envolvidos em nossas
        parcerias. A placa do seu veículo é um identificador único para o sistema de
        controle de trânsito brasileiro, e por isso a utilizamos.`,
          },
          {
            textoTituloNegrio: `Chassi do veículo - `,
            texto: `O registro de chassi é utilizado, juntamente à placa do
        veículo, para consultar a condição de legalidade do veículo. A Martins se
        preocupa com a idoneidade dos nossos parceiros, e sempre deixamos tudo
        nos conformes com a lei.
        `,
          },
        ],
      },
    ],
  },
  {
    titulo: `2. Como tratamos os seus dados`,
    texto: [
      `Abaixo, vamos especificar como realizamos o tratamento dos seus dados. Para
entender melhor, sugerimos a leitura do significado de “tratamento”, como detalhado
no glossário ao final do documento.`,
      `Para deixarmos tudo claro, vamos explicar como realizamos o tratamento
internamente, ou seja, os seus dados com a própria Martins, e como são os
procedimentos e cuidados para o compartilhamento de dados com empresas
parceiras`,
    ],
    regras: [
      {
        titulo: `2.1 Como tratamos os seus dados internamente`,
        texto: [
          `Nós entendemos a sua preocupação em proteger os seus dados, e como empresa,
  nós compartilhamos esse sentimento com você, nosso parceiro. Para reforçar a ideia,
  a Martins se compromete com a sua segurança em nossos portais: estamos
  monitorando nossas conexões, nossos procedimentos de segurança e cada detalhe
  que pudermos alcançar. Todas as suas transações conosco serão sempre
  criptografadas, para garantir que seus dados não sejam espionados pela rede. `,
          `O armazenamento dos seus dados também está bem protegido. Nossos funcionários
  são orientados a se comportarem de acordo com as regências da Lei Geral de
  Proteção de Dados, para mantermos os seus dados onde eles devem ficar:
  guardados em nossos sistemas.`,
          `Todos os acessos são nominais e registrados em nossos relatórios. Garantimos que
  estamos vigiando, atentamente, quem e porque acessa os seus dados, e só liberamos
  os acessos em casos de necessidade comprovada.
  `,
          `A Martins se compromete a coletar somente o que mencionamos no primeiro item
  deste documento, assim como nos mantermos fiéis às suas finalidades mencionadas
  individualmente para cada item.`,
          `Para sermos sucintos, a Martins se compromete neste documento, acima de tudo, a
  seguir os princípios de boa-fé descritos na Lei Geral de Proteção de Dados. E
  podemos te assegurar que cada um dos seus dados foi estritamente coletado e
  tratado baseando-se na lei. Para contextualizar e facilitar sua compreensão, também
  adicionamos estes princípios ao final do glossário.`,
        ],
      },
      {
        titulo: `2.2 Como são nossas políticas de compartilhamento de dados`,
        texto: `A Martins acredita em parcerias para crescermos juntos. Assim como você é nosso
        parceiro, também contamos com o apoio de outras empresas. Cada uma das
        empresas as quais compartilhamos os seus dados, nos asseguramos de estarem de
        acordo coma a lei.
        `,
      },
    ],
  },
  {
    titulo: `3. Quais são os seus direitos `,
    texto: [
      `A qualquer momento, você tem todo direito de saber exatamente quais dados seus
  estamos tratando. Claro, também pode corrigi-los, caso estejam desatualizados,
  incorretos ou incompletos. Você pode demandar a portabilidade dos seus dados
  para outras empresas, caso queira migrar da sua parceria conosco, assim como
  solicitar a eliminação de dados pessoais a qualquer momento. Neste último caso,
  realçamos que caso os seus dados sejam necessários para pendências jurídicas,
  como auditorias e relatórios fiscais da Martins, nós teremos que mantê-los pelo
  menor tempo possível em nosso banco de dados.`,
      `Você também pode sempre nos questionar sobre quais instituições, públicas ou
  privadas, estamos compartilhando os seus dados, mesmo isso já sendo
  especificado neste documento. E claro, também pode pedir para apagarmos por
  completo todos os dados que sejam colhidos desnecessariamente ou não
  estejam de acordo com a lei vigente, mas nós da Martins nos comprometemos dia e
  noite para garantir que isso não seja necessário. Trabalhamos sempre dentro da lei!`,
      `Por fim, gostaríamos de realçar que você pode recusar esse documento. Isso é
  uma parceria, e deve partir de ambos os lados. Caso assim prefira, poderá recusar
  compartilhar seus dados conosco e não teremos o direito de tratá-los, todavia, não
  poderá mais acessar a nossa plataforma, já que seus dados são essenciais para
  tal. Também poderá revogar o seu consentimento posteriormente, mas isso
  resultará em algumas aplicações jurídicas sobre os seus dados. Não se preocupe,
  não há multa rescisória em relação a este documento, mas precisaremos manter
  alguns de seus dados, como já explicado, por questões jurídicas.`,
    ],
  },
  {
    titulo: `4. Por quanto tempo mantemos os seus dados conosco?`,
    texto: [
      `Seus dados são mantidos conosco somente durante o período que nossa parceria
      durar. Caso seus dados sejam mantidos após o término de nosso contrato, é por uma
      necessidade legal.
      `,
      `O governo pode demandar uma auditoria fiscal com a Martins, por exemplo. Para
      garantir que estamos todos de acordo a lei, será necessário manter os seus dados
      em nossos bancos de dados. É claro, pode ficar tranquilo, iremos anonimizar tudo o
      que for possível, encerraremos o compartilhamento dos dados restantes com
      empresas terceiras e apagaremos, definitivamente, todos eles, assim que as
      obrigações legais estiverem completas. O prazo destas obrigações legais é de 5 anos,
      renováveis por mais 5 anos.`,
    ],
  },
  {
    titulo: `5. Atualização da política de privacidade`,
    texto: `Assim como a nossa parceria está crescendo, a nossa plataforma também está. Essa
    política de privacidade possui uma regência temporal, ou seja, ela pode mudar. Esse
    documento é válido a partir de uma data de assinatura ou aceite em nossa plataforma,
    até que seja encerrado ou atualizado. Em qualquer um dos dois casos, você será
    notificado sobre nossa alteração e, em casos de modificações neste documento,
    poderá concordar com as novas políticas ou revogar o seu consentimento, como já
    informado.`,
  },
  {
    titulo: `6. Órgão de defesa dos seus direitos`,
    texto: `Caso você tenha alguma reclamação, ficaríamos felizes em tentar resolver. Caso
    nosso canal de atendimento não se mostre o suficiente, suas reclamações poderão
    ser enviadas para a ANPD, a Agência Nacional de Proteção de Dados, por meio da
    plataforma gov.br/anpd.`,
  },
  {
    titulo: `7. Meios de contato`,
    texto: [
      `Nós queremos te ouvir! Se possui alguma sugestão, crítica, dúvida ou comentário
    sobre o que acabou de ler, por favor, entre em contato conosco. Nossa equipe ficará
    contente em te ajudar ou somente escutar. Para facilitar nosso contato, vamos deixar
    esses dados para você: `,
      `Encarregado de tratamento dos dados: Dalmo Lopes Fernandes`,
      `E-mail: privacidade@martins.com.br`,
      `Esperamos o seu contato!`,
    ],
  },
  {
    titulo: `8. Glossário e princípios de boa-fé`,
    regras: [
      {
        titulo: `8.1 Descrição`,
        regras: [
          {
            textoTituloNegrio: `Dado Pessoal - `,
            texto: `Informação relacionada a pessoa natural identificada ou identificável.`,
          },
          {
            textoTituloNegrio: `Dado Pessoal Sensível - `,
            texto: `Dado pessoal sobre origem racial ou étnica, convicção religiosa, opinião
            política, filiação a sindicato ou a organização de caráter religioso, filosófico ou
            político, dado referente à saúde ou à vida sexual, dado genético ou
            biométrico, quando vinculado a uma pessoa natural`,
          },
          {
            textoTituloNegrio: `Dado Anonimizado - `,
            texto: `Dado relativo ao titular que não possa ser identificado, considerando a
            utilização de meios técnicos razoáveis e disponíveis na ocasião de seu
            tratamento`,
          },
          {
            textoTituloNegrio: `Banco de Dados - `,
            texto: `Conjunto estruturado de dados pessoais, estabelecido em um ou em vários
            locais, em suporte eletrônico ou físico.`,
          },
          {
            textoTituloNegrio: `Titular - `,
            texto: `Pessoa natural a quem se referem os dados pessoais que são objeto de
            tratamento.`,
          },
          {
            textoTituloNegrio: `Controlador - `,
            texto: `Pessoa natural ou jurídica, de direito público ou privado, a quem competem as
            decisões referentes ao tratamento de dados pessoais.`,
          },
          {
            textoTituloNegrio: `Operador - `,
            texto: `Pessoa natural ou jurídica, de direito público ou privado, que realiza o
            tratamento de dados pessoais em nome do controlador.`,
          },
          {
            textoTituloNegrio: `Encarregado - `,
            texto: `Pessoa indicada pelo controlador e operador para atuar como canal
            de comunicação entre o controlador, os titulares dos dados e a Autoridade
            Nacional de Proteção de Dados (ANPD).
            `,
          },
          {
            textoTituloNegrio: `Agentes de Tratamento - `,
            texto: `O controlador e o operador.`,
          },
          {
            textoTituloNegrio: `Tratamento - `,
            texto: `Toda operação realizada com dados pessoais, como as que se referem a
            coleta, produção, recepção, classificação, utilização, acesso, reprodução,
            transmissão, distribuição, processamento, arquivamento, armazenamento,
            eliminação, avaliação ou controle da informação, modificação, comunicação,
            transferência, difusão ou extração.`,
          },
          {
            textoTituloNegrio: `Anonimização - `,
            texto: `Utilização de meios técnicos razoáveis e disponíveis no momento do
            tratamento, por meio dos quais um dado perde a possibilidade de associação,
            direta ou indireta, a um indivíduo.`,
          },
          {
            textoTituloNegrio: `Consentimento - `,
            texto: `Manifestação livre, informada e inequívoca pela qual o titular concorda com o
            tratamento de seus dados pessoais para uma finalidade determinada.`,
          },
          {
            textoTituloNegrio: `Bloqueio - `,
            texto: `Suspensão temporária de qualquer operação de tratamento, mediante guarda
            do dado pessoal ou do banco de dados.`,
          },
          {
            textoTituloNegrio: `Eliminação - `,
            texto: `Exclusão de dado ou de conjunto de dados armazenados em banco de dados,
            independentemente do procedimento empregado.`,
          },
          {
            textoTituloNegrio: `Transferência Internacional de Dados - `,
            texto: `Transferência de dados pessoais para país estrangeiro ou organismo
            internacional do qual o país seja membro.`,
          },
          {
            textoTituloNegrio: `Uso compartilhado de dados - `,
            texto: `Comunicação, difusão, transferência internacional, interconexão de dados
            pessoais ou tratamento compartilhado de bancos de dados pessoais por
            órgãos e entidades públicos no cumprimento de suas competências legais, ou
            entre esses e entes privados, reciprocamente, com autorização específica,
            para uma ou mais modalidades de tratamento permitidas por esses entes
            públicos, ou entre entes privados.`,
          },
          {
            textoTituloNegrio: `Relatório de Impacto à Proteção de Dados Pessoais - `,
            texto: `Documentação do controlador que contém a descrição dos processos de
            tratamento de dados pessoais que podem gerar riscos às liberdades civis e aos
            direitos fundamentais, bem como medidas, salvaguardas e mecanismos de
            mitigação de risco.`,
          },
          {
            textoTituloNegrio: `Autoridade Nacional - `,
            texto: `Órgão da administração pública responsável por zelar, implementar e fiscalizar
            o cumprimento desta Lei em todo o território nacional.`,
          },
        ],
      },
      {
        titulo: `8.2 Princípios de boa-fé`,
        regras: [
          {
            textoTituloNegrio: `Finalidade - `,
            texto: `Realização do tratamento para propósitos legítimos, específicos, explícitos e
        informados ao titular, sem possibilidade de tratamento posterior de forma
        incompatível com essas finalidades.`,
          },
          {
            textoTituloNegrio: `Adequação - `,
            texto: `Compatibilidade do tratamento com as finalidades informadas ao titular, de
            acordo com o contexto do tratamento.`,
          },
          {
            textoTituloNegrio: `Necessidade - `,
            texto: `Limitação do tratamento ao mínimo necessário para a realização de suas
            finalidades, com abrangência dos dados pertinentes, proporcionais e não
            excessivos em relação às finalidades do tratamento de dados.`,
          },
          {
            textoTituloNegrio: `Livre Acesso - `,
            texto: `Garantia, aos titulares, de consulta facilitada e gratuita sobre a forma e a
            duração do tratamento, bem como sobre a integralidade de seus dados
            pessoais.`,
          },
          {
            textoTituloNegrio: `Qualidade dos Dados - `,
            texto: `Garantia, aos titulares, de exatidão, clareza, relevância e atualização dos
            dados, de acordo com a necessidade e para o cumprimento da finalidade de
            seu tratamento.`,
          },
          {
            textoTituloNegrio: `Transparência - `,
            texto: `Garantia, aos titulares, de informações claras, precisas e facilmente acessíveis
            sobre a realização do tratamento e os respectivos agentes de tratamento,
            observados os segredos comercial e industrial.`,
          },
          {
            textoTituloNegrio: `Segurança - `,
            texto: `Utilização de medidas técnicas e administrativas aptas a proteger os dados
            pessoais de acessos não autorizados e de situações acidentais ou
            ilícitas de destruição, perda, alteração, comunicação ou difusão.`,
          },
          {
            textoTituloNegrio: `Prevenção - `,
            texto: `Adoção de medidas para prevenir a ocorrência de danos em virtude do
            tratamento de dados pessoais.`,
          },
          {
            textoTituloNegrio: `Não Discriminação - `,
            texto: `Impossibilidade de realização do tratamento para fins discriminatórios ilícitos
            ou abusivos.`,
          },
          {
            textoTituloNegrio: `Responsabilização e Prestação de contas - `,
            texto: `Demonstração, pelo agente, da adoção de medidas eficazes e capazes de
            comprovar a observância e o cumprimento das normas de proteção de dados
            pessoais e, inclusive, da eficácia dessas medidas`,
          },
        ],
      },
    ],
  },
];
