import React from 'react';
import { titulosPagina } from 'features/detalhesDemandasDiaria/constants/TitulosPagina';
import TituloPagina from 'features/detalhesDemandasDiaria/components/TituloPagina';
import { ChaveOrdenacaoDetalhesDemandas } from 'features/detalhesDemandasDiaria/constants/Constants';
import { ContainerDemandasPendentesProps } from 'features/detalhesDemandasDiaria/types/containerTypes';
import TabelaDemandasPendentes from './TabelaDemandasPendentes';
import { useStyles } from '../../styles/styleContainerDetalhes';

const ContainerDemandasPendentes = ({
  demandas,
  carregando,
  erro,
}: ContainerDemandasPendentesProps): JSX.Element => {
  const classes = useStyles();

  return (
    <section className={classes.container}>
      <TituloPagina
        tituloPaginaConfig={titulosPagina.demandasPendentes}
        quantidadeItens={demandas.length}
        carregando={carregando}
      />
      <TabelaDemandasPendentes
        carregando={carregando}
        demandas={demandas}
        erro={erro}
        chavesOrdenacao={[
          ChaveOrdenacaoDetalhesDemandas.FILIAL,
          ChaveOrdenacaoDetalhesDemandas.NUMERO_ENTREGAS,
        ]}
      />
    </section>
  );
};

export default ContainerDemandasPendentes;
