import React from 'react';
import { Skeleton } from '@material-ui/lab';
import gridStyles from 'features/dashboards/styles/gridTabelaDemandasNegociadorStyle';

const SkeletonTabelaDemandasNegociador = (): JSX.Element => {
  const estilosGrid = gridStyles();
  const linhas = 4;
  const colunas = 9;
  return (
    <section className={estilosGrid.gridContainer}>
      {new Array(linhas * colunas).fill(null).map((_, index) => (
        <Skeleton
          width="90%"
          key={`skeleton-demanda-negociador-${index + 1}`}
          className={estilosGrid.gridItem}
        />
      ))}
    </section>
  );
};

export default SkeletonTabelaDemandasNegociador;
