import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';

import EmptyPage from 'shared/components/emptyPage/EmptyPage';
import IconeReprovadoCirculo from 'assets/icones/IconeReprovadoCirculo';
import { DemandaDiaria as DemandaDiariaType } from '../../api/demandaDiariaApiTypes';

import QuadroBigNumbers from './QuadroBigNumbers';
import QuadroBigNumbersDivididos from './QuadroBigNumbersDivididos';
import HeaderTotalDemandas from './HeaderTotalDemandas';

export interface QuadroTotalDemandasProps {
  demandasDiarias?: DemandaDiariaType;
  carregando?: boolean;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  textoStatusDemanda: {
    color: theme.palette.cores.azulMartins[700],
    fontWeight: 400,
    fontSize: 14,
  },
}));

const QuadroTotalDemandas = ({
  demandasDiarias,
  carregando,
}: QuadroTotalDemandasProps): JSX.Element => {
  const classes = useStyles();

  if (!carregando && !demandasDiarias)
    return (
      <section className={classes.container}>
        <HeaderTotalDemandas />
        <EmptyPage
          iconePreenchido
          icone={
            <IconeReprovadoCirculo
              viewBox="-5 0 32 22"
              width={60}
              height={60}
            />
          }
          texto="Ops algo aconteceu, por algum motivo não conseguimos listar as demandas do dia."
        />
      </section>
    );

  return (
    <section className={classes.container}>
      <HeaderTotalDemandas />
      <QuadroBigNumbersDivididos
        carregando={carregando}
        demandasDiarias={demandasDiarias}
      />

      <Typography className={classes.textoStatusDemanda}>
        Status das demandas
      </Typography>

      <QuadroBigNumbers
        carregando={carregando}
        demandasDiarias={demandasDiarias}
      />
    </section>
  );
};

export default QuadroTotalDemandas;
