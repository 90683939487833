import React from 'react';

import { NegociacaoAndamento } from 'features/demandasAndamento/api/demandasAndamentoApiTypes';

import EmptyPage from 'shared/components/emptyPage/EmptyPage';
import IconeArquivoBarraDiagonal from 'assets/icones/IconeArquivoBarraDiagonal';

import gridStyles from 'features/demandasAndamento/styles/gridTabelaDemandasAndamentoStyle';
import ItemTabelaDemandasAndamento from './ItemTabelaDemandasAndamento';
import SkeletonTabelaDemandasAndamento from './SkeletonTabelaDemandasAndamento';

export interface ConteudoTabelaDemandasAndamentoProps {
  demandas?: NegociacaoAndamento[];
  carregando: boolean;
}

const ConteudoTabelaDemandasAndamento = ({
  demandas,
  carregando,
}: ConteudoTabelaDemandasAndamentoProps): JSX.Element => {
  const gridStyle = gridStyles();

  if (carregando) return <SkeletonTabelaDemandasAndamento />;

  if (!demandas)
    return (
      <EmptyPage
        iconePreenchido
        texto="Ops algo aconteceu, por algum motivo não conseguimos listar as demandas das negociações"
      />
    );

  if (demandas.length === 0)
    return (
      <EmptyPage
        iconePreenchido
        icone={
          <IconeArquivoBarraDiagonal
            width={134}
            height={134}
            viewBox="-6 0 34 22"
          />
        }
        texto="Ops, não temos nenhuma informação para mostrar no momento, tente novamente mais tarde."
      />
    );

  return (
    <section className={gridStyle.gridContainer}>
      {demandas?.map((demanda) => (
        <ItemTabelaDemandasAndamento
          demanda={demanda}
          className={gridStyle.gridItem}
          key={`item-corpo-tabela-demandas-andamento-${demanda.idNegociacao}-${demanda.idProposta}`}
        />
      ))}
    </section>
  );
};

export default ConteudoTabelaDemandasAndamento;
