import React from 'react';

import Alert from '@material-ui/lab/Alert';
import { IconButton, Slide, SlideProps, Snackbar } from '@material-ui/core';

import CloseRounded from '@material-ui/icons/CloseRounded';

import { useTheme, withStyles } from '@material-ui/core/styles';

import IconeFeedback, { TipoIconeSnackbar } from './IconeFeedback';
import DisablingTooltip from '../tooltip/DisablingTooltip';

export type FeedbackSnackbarProps = {
  severity: TipoIconeSnackbar;
  mensagem: string;
  open?: boolean;
  autoHideDuration?: number;
  closeTitle?: string;
  onClose: () => void;
  alturaTela?: string;
};

type CorSnackbar = {
  tipo: TipoIconeSnackbar;
  cor?: string;
  corTextoMensagem?: string;
};

const Alerta = withStyles(() => ({
  root: {
    fontWeight: 600,
    '& .MuiAlert-message': {
      alignSelf: 'flex-end',
      fontWeight: 400,
    },
    borderRadius: '6px',
  },
}))(Alert);

const obterEstiloSnackBar = (
  tipo: TipoIconeSnackbar,
  ...cores: CorSnackbar[]
): React.CSSProperties => {
  const tipoSnackBar = cores.find((cor) => cor.tipo === tipo);

  return {
    backgroundColor: tipoSnackBar?.cor || '',
    color: tipoSnackBar?.corTextoMensagem || '',
  };
};

const FeedbackSnackbar = ({
  open,
  severity,
  mensagem,
  autoHideDuration = 6000,
  closeTitle = 'Fechar',
  onClose,
  alturaTela,
}: FeedbackSnackbarProps): JSX.Element => {
  const theme = useTheme();

  const ERRO: CorSnackbar = {
    tipo: 'error',
    cor: theme.palette.cores.vermelho[800],
    corTextoMensagem: theme.palette.cores.branco,
  };

  const AVISO: CorSnackbar = {
    tipo: 'warning',
    cor: theme.palette.cores.amarelo[100],
    corTextoMensagem: theme.palette.cores.cinza[900],
  };

  const SUCESSO: CorSnackbar = {
    tipo: 'success',
    cor: theme.palette.cores.verde[400],
    corTextoMensagem: theme.palette.cores.branco,
  };

  type TransitionProps = Omit<SlideProps, 'direction'>;

  function TransitionLeft(props: TransitionProps) {
    return <Slide {...props} direction="right" />;
  }

  return (
    <Snackbar
      style={{ whiteSpace: 'pre-line', bottom: alturaTela }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      TransitionComponent={TransitionLeft}
    >
      <Alerta
        style={obterEstiloSnackBar(severity, ERRO, AVISO, SUCESSO)}
        icon={<IconeFeedback tipo={severity} />}
        action={
          <DisablingTooltip title={closeTitle}>
            <IconButton size="small" color="inherit" onClick={onClose}>
              <CloseRounded />
            </IconButton>
          </DisablingTooltip>
        }
      >
        {mensagem}
      </Alerta>
    </Snackbar>
  );
};

export default FeedbackSnackbar;
