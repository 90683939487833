import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';

import { spacingInPixels } from '../../../functions/materialUtils';

export default withStyles((theme) => ({
  root: {
    width: spacingInPixels(theme, 3),
    height: spacingInPixels(theme, 3),
  },
  img: {
    width: spacingInPixels(theme, 3),
    height: spacingInPixels(theme, 3),
  },
}))(Avatar);
