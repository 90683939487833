import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import BotaoSugestao from 'features/chatNegociacao/components/BotaoSugestao';
import DialogMetricasSugestaoRobo from 'features/chatNegociacao/components/DialogMetricasSugestaoRobo';
import TextoValorSugeridoRobo from './TextoValorSugeridoRobo';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '20fr 1fr',
    padding: '8px 24px',
    alignItems: 'center',
    margin: '0px 0px 17px 1px',
    background: theme.palette.cores.amarelo[100],
    boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.25)',
    height: '47px',
  },
}));

type SugestaoRoboProps = {
  sugestaoValorInicialRobo: number;
};

const SugestaoRobo = ({
  sugestaoValorInicialRobo,
}: SugestaoRoboProps): JSX.Element => {
  const classes = useStyles();

  const [modalAberto, setModalAberto] = useState(false);

  const alternarEstadoModalAberto = (): void =>
    setModalAberto((aberto: boolean) => !aberto);

  return (
    <div className={classes.container}>
      <TextoValorSugeridoRobo
        sugestaoValorInicialRobo={sugestaoValorInicialRobo}
      />
      <BotaoSugestao aoClicar={alternarEstadoModalAberto} />
      <DialogMetricasSugestaoRobo
        modalAberto={modalAberto}
        fecharModal={alternarEstadoModalAberto}
      />
    </div>
  );
};

export default SugestaoRobo;
