import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import StatusDemandaEnum from 'shared/constants/StatusDemandaEnum';
import { DemandaColaborador } from './api/listagemNegociadoresApiTypes';
import AvatarComStatusConexao from './components/AvatarComStatusConexao';
import ChipAlertaDemanda from '../components/ChipAlertaDemanda';
import DadosNegociador from './components/DadosNegociador';
import EmptyPage from '../../../shared/components/emptyPage/EmptyPage';
import ModalDetalhes from '../components/modalDetalhes/ModalDetalhes';
import NegociacaoPorHora from './components/NegociacaoPorHora';
import StatusDemanda from '../components/StatusDemanda';
import ContainerPrioridadeDeCarga from '../components/ContainerPrioridadeDeCarga';
import { estilosTabelaNegociadores } from './styles/estilosTabelaNegociadores';

const useStyles = makeStyles((theme) => ({
  containerListagem: {
    overflowX: 'hidden',
    overflowY: 'auto',
    height: '93vh',
    minHeight: '104px',
    maxHeight: 'calc(100vh - 434px)',
    minWidth: '1300px',
    paddingBottom: '2px',
    '& > div': {
      borderBottom: `1px solid ${theme.palette.cores.cinza[300]}`,
      height: '54px',
    },
    '& > :first-child': {
      borderTop: `1px solid ${theme.palette.cores.cinza[300]}`,
    },
    '& > :last-child': {
      borderBottom: 'unset',
    },
    '@media (max-height:660px)': {
      maxHeight: 'calc(100vh - 510px)',
    },
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.cores.azul[600],
      borderRadius: '8px',
    },
  },
  informacaoNegociador: {
    display: 'flex',
    width: 246,
    '@media (min-width:1440px)': {
      gridTemplateColumns: '25% 75%',
    },
    '@media (min-width:1920px)': {
      gridTemplateColumns: '20% 80%',
    },
  },
  gridAreaAvatar: {
    gridRowStart: 1,
    gridRowEnd: 2,
  },
  gridAreaNomeEStatus: {
    flexGrow: 2,
    padding: '6px 0',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '90%',
  },
  prioridadeCarga: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: '6px',
    paddingLeft: '15%',
    height: '52px',
  },
}));

interface ConteudoTabelaNegociadoresProps {
  negociadores: DemandaColaborador[] | undefined;
}

const ConteudoTabelaNegociadores = ({
  negociadores,
}: ConteudoTabelaNegociadoresProps): JSX.Element => {
  const classes = useStyles();
  const { gridTabela } = estilosTabelaNegociadores();

  const [
    negociadorSelecionado,
    setNegociadorSelecionado,
  ] = useState<DemandaColaborador | null>(null);
  const [statusFiltro, setStatusFiltro] = useState<
    StatusDemandaEnum | null | undefined
  >();

  const abrirModalDetalhes = (
    negociador: DemandaColaborador,
    filtro?: StatusDemandaEnum | null,
  ) => {
    setStatusFiltro(filtro);
    setNegociadorSelecionado(negociador);
  };

  const fecharModalDetalhes = () => {
    setStatusFiltro(undefined);
    setNegociadorSelecionado(null);
  };

  if (!negociadores || negociadores?.length === 0)
    return (
      <EmptyPage
        texto={
          !negociadores
            ? 'Ops algo aconteceu, por algum motivo não conseguimos listar os negociadores.'
            : 'Ops, não existem demandas para negociadores.'
        }
      />
    );

  const validarRANA = (nomeNeg: string) => {
    return nomeNeg === 'R.A.N.A. - Robo Agregados de Negociações Automatizadas';
  };

  const alvo = negociadores.find((negociador) =>
    validarRANA(negociador.nomeNegociador),
  );

  if (alvo) {
    const index = negociadores.indexOf(alvo);
    if (index !== -1) {
      negociadores.splice(index, 1);
      negociadores.unshift(alvo);
    }
  }

  const negociadoresOn = negociadores.filter(
    (neg) => neg.dataHoraDesdePrimeiroAcesso || validarRANA(neg.nomeNegociador),
  );

  return (
    <>
      <div className={classes.containerListagem}>
        {negociadoresOn?.map((negociador) => (
          <div
            style={{
              border: validarRANA(negociador.nomeNegociador)
                ? '2px solid #1873cdb8'
                : 'unset',
              borderTop: validarRANA(negociador.nomeNegociador)
                ? '2px solid #1873cdb8'
                : '1px solid #ebecf4',

              borderBottom: validarRANA(negociador.nomeNegociador)
                ? '2px solid #1873cdb8'
                : '1px solid #ebecf4',
              borderRadius: validarRANA(negociador.nomeNegociador)
                ? '4px'
                : 'unset',
            }}
          >
            <div
              key={`item-tabela-negociador-${negociador.idNegociador}`}
              className={gridTabela}
            >
              <section className={classes.informacaoNegociador}>
                <AvatarComStatusConexao
                  isAutomatizado={validarRANA(negociador.nomeNegociador)}
                  isOnline={Boolean(negociador?.dataHoraDesdePrimeiroAcesso)}
                />
                <section className={classes.gridAreaNomeEStatus}>
                  <DadosNegociador
                    nome={
                      validarRANA(negociador.nomeNegociador)
                        ? 'Automatizado'
                        : negociador.nomeNegociador
                    }
                    tempoConexao={negociador?.dataHoraDesdePrimeiroAcesso}
                  />
                </section>
              </section>
              <ContainerPrioridadeDeCarga
                prioridadeCarga={negociador?.prioridadeCarga}
              />
              <StatusDemanda
                isAutomatizado={validarRANA(negociador.nomeNegociador)}
                dadosStatusDemanda={negociador?.demandas}
                onClick={(filtro) => abrirModalDetalhes(negociador, filtro)}
              />
              <NegociacaoPorHora
                valor={negociador?.negociacoesConcluidasPorHora}
              />
              <ChipAlertaDemanda
                onClick={() => abrirModalDetalhes(negociador)}
                quantidade={negociador?.quantidadeAlertasDemanda}
              />
            </div>
          </div>
        ))}
      </div>
      <ModalDetalhes
        isAutomatizado={validarRANA(
          negociadorSelecionado?.nomeNegociador || '',
        )}
        negociador={negociadorSelecionado}
        filtroStatus={statusFiltro}
        setFiltroStatus={setStatusFiltro}
        fecharModal={fecharModalDetalhes}
      />
    </>
  );
};

export default ConteudoTabelaNegociadores;
