import { createReducer } from '@reduxjs/toolkit';

import {
  converterDataApi,
  obterDiferencaDatas,
} from '../../../shared/functions/dataUtils';

import { MetadataType, NotificacaoType } from '../types/notificacoes';
import { notificarBrowser } from '../utils/notificacoesBrowser';
import {
  abrirNotificacoes,
  atualizarNotificacoes,
  atualizarRecebeuNovasNotificacoes,
  fecharNotificacoes,
} from './notificacoesAction';

export type NotificacoesState = {
  notificacoesAbertas: boolean;
  quantidadeNotificacoesNaoLidas: number;
  recebeuNovasNotificacoes: boolean;
  notificacoes: NotificacaoType[];
  notificacoesPaginaAtual: number;
  notificacoesUltimaPagina: boolean;
  quantidadeTotalNotificacoes?: number;
};

export const estadoInicial: NotificacoesState = {
  notificacoesAbertas: false,
  quantidadeNotificacoesNaoLidas: 0,
  recebeuNovasNotificacoes: false,
  notificacoes: [],
  notificacoesPaginaAtual: 1,
  notificacoesUltimaPagina: true,
  quantidadeTotalNotificacoes: undefined,
};

const filtrarNovasNotificacoes = (
  notificacao: NotificacaoType,
  idsNotificacoesAtuais: number[],
): boolean => !idsNotificacoesAtuais.includes(notificacao.idNotificacao);

const obterNovasNotificacoes = (
  notificacoes: NotificacaoType[],
  idsNotificacoesAtuais: number[],
): NotificacaoType[] =>
  notificacoes.filter((notificacao) =>
    filtrarNovasNotificacoes(notificacao, idsNotificacoesAtuais),
  );

const ordenarNotificacoesDataDecrescente = (
  a: NotificacaoType,
  b: NotificacaoType,
): number =>
  obterDiferencaDatas(
    converterDataApi(b.dataCriacao),
    converterDataApi(a.dataCriacao),
  );

const verificarNotificacoesVisualizadas = (notificacao: NotificacaoType) =>
  notificacao.visualizada;

const verificarNotificacoesNaoVisualizadas = (notificacao: NotificacaoType) =>
  !notificacao.visualizada;

const obterNotificacoes = (
  novasNotificacoes: NotificacaoType[],
  state: NotificacoesState,
): NotificacaoType[] => {
  if (novasNotificacoes.length === 0) {
    return state.notificacoes;
  }

  const notificacoes = novasNotificacoes.concat(state.notificacoes);

  const notificacoesNaoVisualizadas = notificacoes
    .filter(verificarNotificacoesNaoVisualizadas)
    .sort(ordenarNotificacoesDataDecrescente);

  const notificacoesVisualizadas = notificacoes
    .filter(verificarNotificacoesVisualizadas)
    .sort(ordenarNotificacoesDataDecrescente);

  return notificacoesNaoVisualizadas.concat(notificacoesVisualizadas);
};

const verificarUltimaPagina = (metadata: MetadataType): boolean => {
  const { totalPaginas, paginaAtual } = metadata;

  return totalPaginas <= 0 || totalPaginas <= paginaAtual;
};

const verificarRecebeuNovasNotificacoes = (
  state: NotificacoesState,
  quantidadeNaoLidas: number,
): boolean => state.quantidadeNotificacoesNaoLidas < quantidadeNaoLidas;

const obterQuantidadeNovasNotificacoes = (
  quantidadeNova: number,
  quantidadeAntiga?: number,
): number =>
  quantidadeAntiga === undefined ? 0 : quantidadeNova - quantidadeAntiga;

const notificacoesReducer = createReducer(estadoInicial, (builder) => {
  builder
    .addCase(abrirNotificacoes, (state) => ({
      ...state,
      notificacoesAbertas: true,
    }))
    .addCase(atualizarNotificacoes, (state, action) => {
      const idsNotificacoesAtuais = state.notificacoes.map(
        (notificacao) => notificacao.idNotificacao,
      );

      const { notificacoes, quantidadeNaoLidas, metadata } = action.payload;

      const novasNotificacoes = obterNovasNotificacoes(
        notificacoes,
        idsNotificacoesAtuais,
      );

      const quantidadeNovasNotificacoes = obterQuantidadeNovasNotificacoes(
        metadata.totalItens,
        state.quantidadeTotalNotificacoes,
      );

      notificarBrowser(
        notificacoes,
        quantidadeNovasNotificacoes,
        quantidadeNaoLidas,
      );

      return {
        ...state,
        notificacoes: obterNotificacoes(novasNotificacoes, state),
        quantidadeNotificacoesNaoLidas: quantidadeNaoLidas,
        recebeuNovasNotificacoes: verificarRecebeuNovasNotificacoes(
          state,
          quantidadeNaoLidas,
        ),
        notificacoesUltimaPagina: verificarUltimaPagina(metadata),
        notificacoesPaginaAtual: metadata.paginaAtual,
        quantidadeTotalNotificacoes: metadata.totalItens,
      };
    })
    .addCase(atualizarRecebeuNovasNotificacoes, (state) => ({
      ...state,
      recebeuNovasNotificacoes: false,
    }))

    .addCase(fecharNotificacoes, (state) => ({
      ...state,
      notificacoes: [],
      notificacoesAbertas: false,
    }))
    .addDefaultCase((state) => state);
});

export default notificacoesReducer;
