import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';
import FiltroChipCompleto, {
  OpcaoFiltro,
} from 'shared/components/filters/chip/FiltroChipCompleto';
import { buscarDadosPermissaoUsuario } from 'features/login/redux/loginOperation';
import { OpcoesAbasPaginaIndicadores } from '../constants/Constants';
import { FiltrosAbaPainel } from '../types/PainelIndicadoresTypes';
import ContainerConteudoGeral from './conteudoGeral/ContainerConteudoGeral';
import { resetarIndicadores } from '../utils/evolucaoDeCargas';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacingInPixels(theme, 4),
  },
}));

const ContainerIndicadores = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [filtroAtual, setFiltroAtual] = useState(
    OpcoesAbasPaginaIndicadores.GERAL,
  );
  const [abaAtual, setAbaAtual] = useState(OpcoesAbasPaginaIndicadores.GERAL);
  const filtros: FiltrosAbaPainel[] = [
    {
      descricao: 'Geral',
      chave: OpcoesAbasPaginaIndicadores.GERAL,
      desabilitado: false,
    },
    {
      descricao: 'Negociadores',
      chave: OpcoesAbasPaginaIndicadores.NEGOCIADORES,
      desabilitado: true,
    },
    {
      descricao: 'Filial',
      chave: OpcoesAbasPaginaIndicadores.FILIAL,
      desabilitado: true,
    },
  ];

  useEffect(() => {
    buscarDadosPermissaoUsuario()(dispatch);
  }, [dispatch]);

  const handleTrocaAba = (filtroSelecionado: OpcaoFiltro) => {
    setAbaAtual(filtroSelecionado.chave as number);
    setFiltroAtual(filtroSelecionado.chave as number);
  };

  const handleExibirConteudo = () => {
    switch (abaAtual) {
      case OpcoesAbasPaginaIndicadores.NEGOCIADORES:
        return <>Conteúdo de Negociadores</>;
      case OpcoesAbasPaginaIndicadores.FILIAL:
        return <>Conteúdo de Filial</>;
      case OpcoesAbasPaginaIndicadores.GERAL:
      default:
        return <ContainerConteudoGeral />;
    }
  };

  useEffect(() => {
    return () => {
      resetarIndicadores(dispatch);
    };
  }, [dispatch]);

  return (
    <section className={classes.container}>
      <div>
        <FiltroChipCompleto
          filtroAtual={filtroAtual}
          filtros={filtros}
          onClickFiltro={handleTrocaAba}
        />
      </div>
      <div>{handleExibirConteudo()}</div>
    </section>
  );
};

export default ContainerIndicadores;
