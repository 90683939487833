import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import { RootState } from '../../../store/reducer';

import LinkButton from '../../../shared/components/button/LinkButton';
import { Rubik } from '../../../shared/constants/font';
import { onClickVejaMais } from '../utils/botaoVejaMais';

export type BotaoVejaMaisProps = {
  paginaFilter: boolean;
};

const BotaoVejaMais = ({ paginaFilter }: BotaoVejaMaisProps): JSX.Element => {
  const dispatch = useDispatch();

  const [carregando, setCarregando] = useState(false);

  const { notificacoesUltimaPagina, notificacoesPaginaAtual } = useSelector(
    (state: RootState) => state.notificacoesReducer,
  );

  const onClick = async (): Promise<void> => {
    await onClickVejaMais(
      notificacoesPaginaAtual,
      setCarregando,
      paginaFilter,
      dispatch,
    );
  };

  if (notificacoesUltimaPagina) {
    return <></>;
  }

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="50px"
      style={{ cursor: 'pointer' }}
      onClick={onClick}
    >
      {carregando ? (
        <CircularProgress size="24px" />
      ) : (
        <LinkButton style={{ fontFamily: Rubik }}>Veja mais</LinkButton>
      )}
    </Box>
  );
};

export default BotaoVejaMais;
