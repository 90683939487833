import { useTheme } from '@material-ui/core';
import * as React from 'react';
import { SVGProps } from 'react';

export type propsIcone = SVGProps<SVGSVGElement> & { coricone?: string };

const IconeConversa = ({
  fill,
  coricone,
  ...outros
}: propsIcone): JSX.Element => {
  const theme = useTheme();
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" {...outros}>
      <ellipse
        cx="8.67773"
        cy="7.99995"
        rx="8"
        ry="7.99995"
        fill={fill || theme.palette.cores.amarelo[400]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.43921 9.69081L6.88048 9.24958H7.27187V9.87356C7.27187 10.0304 7.41179 10.1575 7.58437 10.1575H11.3344C11.4173 10.1575 11.4967 10.1874 11.5553 10.2407L12.2719 10.8917V7.03403C12.2719 6.87721 12.132 6.75008 11.9594 6.75008H11.4385V5.91675H12.1053C12.6575 5.91675 13.1052 6.33644 13.1052 6.85415V11.8537C13.1052 12.132 12.7462 12.2714 12.5362 12.0746L11.3007 10.9162H7.43911C6.88688 10.9162 6.43921 10.4966 6.43921 9.97884V9.69081Z"
        fill={coricone || theme.palette.cores.cinza[900]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.60506 8.80825L6.26188 8.15718C6.3156 8.10393 6.38846 8.07401 6.46444 8.07401H9.90194C10.0601 8.07401 10.1884 7.94688 10.1884 7.79006V4.95054C10.1884 4.79372 10.0601 4.66659 9.90194 4.66659H5.89152C5.73331 4.66659 5.60506 4.79372 5.60506 4.95054V8.80825ZM6.4635 8.83275L5.30515 9.99111C5.1083 10.188 4.77173 10.0485 4.77173 9.77016V4.77066C4.77173 4.25294 5.19142 3.83325 5.70913 3.83325H10.0837C10.6014 3.83325 11.0211 4.25294 11.0211 4.77066V7.89535C11.0211 8.41306 10.6014 8.83275 10.0837 8.83275H6.4635Z"
        fill={coricone || theme.palette.cores.cinza[900]}
      />
    </svg>
  );
};

export default IconeConversa;
