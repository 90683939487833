import React from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';
import IconeAvatar from 'assets/icones/IconeAvatar';
import estilosTabela from './styles/estilosTabela';
import CustomTooltip from '../tooltip/CustomTooltip';

const useStyles = makeStyles((theme: Theme) => ({
  negociador: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: spacingInPixels(theme, 0.5),
    overflow: 'hidden',
    '& > span': {
      color: theme.palette.cores.cinza[700],
      fontWeight: 700,
    },
  },
}));

export interface CelulaNegociadorProps {
  nomeNegociador: string;
}

const CelulaNegociador = ({
  nomeNegociador,
}: CelulaNegociadorProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const { fonteConteudo } = estilosTabela();

  return (
    <CustomTooltip title={nomeNegociador}>
      <td className={classes.negociador}>
        <IconeAvatar
          width={20}
          height={20}
          fill={theme.palette.cores.cinza[600]}
        />
        <span className={fonteConteudo}>{nomeNegociador}</span>
      </td>
    </CustomTooltip>
  );
};

export default CelulaNegociador;
