import { parse, format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const FormatoDataHoraIso = 'yyyy-MM-dd HH:mm:ss';
const FormatoDiaMesHoraMinuto = 'dd/MM HH:mm';
const FormatoDataIso = 'yyyy-MM-dd';
const FormatoDataBrasileira = 'dd/MM/yyyy';
const FormatoHoraMinuto = 'HH:mm';
const FormatoHoraMinutoSegundo = 'HH:mm:ss';

const MesesDoAno: Record<number, string> = {
  0: 'Janeiro',
  1: 'Fevereiro',
  2: 'Março',
  3: 'Abril',
  4: 'Maio',
  5: 'Junho',
  6: 'Julho',
  7: 'Agosto',
  8: 'Setembro',
  9: 'Outubro',
  10: 'Novembro',
  11: 'Dezembro',
};

export const MilissegundosPorDia = 1000 * 60 * 60 * 24;
export const MilissegundosPorHora = 1000 * 60 * 60;
export const MilissegundosPorMinuto = 1000 * 60;
export const MilissegundosPorSegundo = 1000;

export const obterDataAtual = (): Date => new Date();

export const converterData = (data: string, formato: string): Date =>
  parse(data, formato, obterDataAtual(), { locale: ptBR });

export const converterDataApi = (data: string): Date =>
  converterData(data, FormatoDataHoraIso);

export const converterDataBrasileira = (data: string): Date =>
  converterData(data, FormatoDataBrasileira);

export const converterHora = (hora: string): Date =>
  converterData(hora, FormatoHoraMinuto);

export const formatarData = (data: Date, formato: string): string =>
  format(data, formato, { locale: ptBR });

export const formatarDataApi = (data: Date): string =>
  formatarData(data, FormatoDataIso);

export const formatarDataBrasileira = (data: Date): string =>
  formatarData(data, FormatoDataBrasileira);

export const formatarDiaMesHoraMinuto = (data: Date): string =>
  formatarData(data, FormatoDiaMesHoraMinuto);

export const formatarHoraMinuto = (data: Date): string =>
  formatarData(data, FormatoHoraMinuto);

export const formatarHorasMinutosSegundos = (data: Date): string =>
  formatarData(data, FormatoHoraMinutoSegundo);

export const obterDiferencaDatas = (dataA: Date, dataB: Date): number =>
  dataA.getTime() - dataB.getTime();

export const verificarDataHoraMaior = (dataA: Date, dataB: Date): boolean => {
  return obterDiferencaDatas(dataA, dataB) > 0;
};

export const obterUTC = (data: Date): number =>
  Date.UTC(data.getFullYear(), data.getMonth(), data.getDate());

export const diferencaDataDias = (dataA: Date, dataB: Date): number => {
  const utcA = obterUTC(dataA);
  const utcB = obterUTC(dataB);

  return Math.floor((utcA - utcB) / MilissegundosPorDia);
};

export const verificarMesmoDia = (dataA: Date, dataB: Date): boolean => {
  return diferencaDataDias(dataA, dataB) === 0;
};

export const dataMaiorOuIgual = (dataA: Date, dataB: Date): boolean => {
  return diferencaDataDias(dataA, dataB) >= 0;
};

export const dataMenorOuIgual = (dataA: Date, dataB: Date): boolean => {
  return diferencaDataDias(dataA, dataB) <= 0;
};

export const obterDiaMesAnoSeparadoPorVirgula = (data?: string): string => {
  if (!data) return '-';
  const objetoData = new Date(data);
  const dia = objetoData.getDate();
  const mes = objetoData.getMonth();
  const ano = objetoData.getFullYear();
  return `${dia}, ${MesesDoAno[mes].substring(0, 3)}, ${ano}`;
};
