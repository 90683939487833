import React from 'react';

import {
  Box,
  Button,
  Slide,
  SlideProps,
  Snackbar,
  Typography,
  makeStyles,
} from '@material-ui/core';

export type NotificacaoSnackbarProps = {
  titulo: string;
  mensagem: string;
  nameButton: string;
  open?: boolean;
  autoHideDuration?: number;
  onClose: () => void;
  icone?: React.ReactNode;
};

const useStyles = makeStyles(() => ({
  customSnackbarContent: {
    backgroundColor: '#FFF8E1',
    color: 'black',
    display: 'flex',
    alignItems: 'flex-end',
    padding: '15px 28px',
    borderRadius: '10px',
    boxShadow: '0px 4px 4px 0px #00000040',
    width: '500px',
  },
  customText: {
    color: '#2C2C2CDE',
    fontSize: '14px',
    fontWeight: 500,
  },
  customTextSubTitle: {
    color: '#757575',
    fontSize: '12px',
    fontWeight: 400,
  },
  buttonClose: {
    backgroundColor: '#2962FF',
    color: 'white',
    textTransform: 'none',
    fontFamily: 'roboto',
    margin: '0',
    boxShadow: 'none',
    zIndex: 1700,
    '&:hover': {
      backgroundColor: '#2962FF',
      boxShadow: 'none',
    },
  },
}));

const NotificacaoSnackbar = ({
  open,
  titulo,
  mensagem,
  nameButton,
  onClose,
  icone,
}: NotificacaoSnackbarProps): JSX.Element => {
  const classes = useStyles();

  type TransitionProps = Omit<SlideProps, 'direction'>;

  function TransitionLeft(props: TransitionProps) {
    return <Slide {...props} direction="right" />;
  }

  return (
    <Snackbar
      style={{ whiteSpace: 'pre-line', height: '90px' }}
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      TransitionComponent={TransitionLeft}
      ContentProps={{
        className: classes.customSnackbarContent,
      }}
      message={
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingRight: '15px',
          }}
        >
          {icone && <div style={{ marginRight: '16px' }}>{icone}</div>}
          <Box>
            <Typography className={classes.customText}>{titulo}</Typography>
            <Typography className={classes.customTextSubTitle}>
              {mensagem}
            </Typography>
          </Box>
        </Box>
      }
      action={
        <Button
          onClick={onClose}
          variant="contained"
          size="small"
          className={classes.buttonClose}
        >
          {nameButton}
        </Button>
      }
    />
  );
};

export default NotificacaoSnackbar;
