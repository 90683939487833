import * as React from 'react';
import { StylesChip } from '../styles/stylesChip';
import { ChipRoleProps } from '../types/componentsTypes';

const ChipRoleUsuario = ({
  titulo,
  ativo,
  desabilitado,
  onClick,
}: ChipRoleProps): JSX.Element => {
  const styles = StylesChip();

  return (
    <button
      className={`${styles.chipStyled} ${
        ativo ? styles.ativo : styles.inativo
      }`}
      onClick={() => onClick()}
      type="button"
      disabled={desabilitado}
    >
      {titulo}
    </button>
  );
};

export default ChipRoleUsuario;
