import React from 'react';
import IconeExclamacaoCirculo from 'assets/icones/IconeExclamacaoCirculo';
import IconeCaixa from 'assets/icones/IconeCaixa';
import IconeBalaoChat from 'assets/icones/IconeBalaoChat';
import IconeRelogio from 'assets/icones/IconeRelogio';
import IconeCorretoQuadrado from 'assets/icones/IconeCorretoQuadrado';
import IconeExclamacaoOctogono from 'assets/icones/IconeExclamacaoOctogono';
import IconeReprovar from 'assets/icones/IconeReprovar';
import { StatusDemandaDiariaDashboard } from './Constants';

export type TituloPagina = {
  icone: React.ReactElement;
  descricao: string;
  status: StatusDemandaDiariaDashboard;
};

const DEMANDAS_DO_DIA: TituloPagina = {
  icone: <IconeCaixa width={24} height={24} />,
  descricao: 'Demandas do dia',
  status: StatusDemandaDiariaDashboard.DEMANDAS_DO_DIA,
};

const DEMANDAS_PENDENTES: TituloPagina = {
  icone: <IconeExclamacaoCirculo width={24} height={24} />,
  descricao: 'Demandas pendentes',
  status: StatusDemandaDiariaDashboard.PENDENTES,
};

const DEMANDAS_EM_NEGOCIACAO: TituloPagina = {
  icone: <IconeBalaoChat width={24} height={24} />,
  descricao: 'Demandas em negociação',
  status: StatusDemandaDiariaDashboard.EM_NEGOCIACAO,
};

const DEMANDAS_RECUSADAS: TituloPagina = {
  icone: <IconeReprovar width={24} height={24} />,
  descricao: 'Demandas recusadas',
  status: StatusDemandaDiariaDashboard.RECUSADAS,
};

const DEMANDAS_NAO_INICIADAS: TituloPagina = {
  icone: <IconeRelogio transform="scale(-1,1)" width={24} height={24} />,
  descricao: 'Demandas não iniciadas',
  status: StatusDemandaDiariaDashboard.NAO_INICIADAS,
};

const DEMANDAS_EM_APROVACAO: TituloPagina = {
  icone: <IconeExclamacaoOctogono width={24} height={24} />,
  descricao: 'Demandas em aprovação',
  status: StatusDemandaDiariaDashboard.EM_APROVACAO,
};

const DEMANDAS_NEGOCIADAS: TituloPagina = {
  icone: <IconeCorretoQuadrado width={24} height={24} />,
  descricao: 'Demandas negociadas',
  status: StatusDemandaDiariaDashboard.NEGOCIADAS,
};

export const titulosPagina = {
  demandasDoDia: DEMANDAS_DO_DIA,
  demandasPendentes: DEMANDAS_PENDENTES,
  demandasEmNegociacao: DEMANDAS_EM_NEGOCIACAO,
  demandasRecusadas: DEMANDAS_RECUSADAS,
  demandasNaoIniciadas: DEMANDAS_NAO_INICIADAS,
  demandasEmAprovacao: DEMANDAS_EM_APROVACAO,
  demandasNegociadas: DEMANDAS_NEGOCIADAS,
};
