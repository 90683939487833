enum EstadoAtualProposta {
  AGUARDANDO,
  PENDENTE_NEGOCIADOR,
  PENDENTE_SUPERVISOR,
  RESPOSTA_SUPERVISOR,
  CONCLUIDA,
  ACEITE_MOTORISTA,
  RECUSADA,
}

export type EstadoAtualPropostaType = EstadoAtualProposta;

export const verificarPropostaConcluida = (
  status: EstadoAtualPropostaType,
): boolean => EstadoAtualProposta.CONCLUIDA === status;

export const verificarPropostaRecusada = (
  status: EstadoAtualPropostaType,
): boolean => EstadoAtualProposta.RECUSADA === status;

export const verificarPropostaFechada = (
  status: EstadoAtualPropostaType,
): boolean =>
  verificarPropostaConcluida(status) || verificarPropostaRecusada(status);

export default EstadoAtualProposta;
