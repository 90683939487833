import { useTheme } from '@material-ui/core/styles';
import * as React from 'react';
import { SVGProps } from 'react';

const IconeSeta = ({
  fill,
  ...outros
}: SVGProps<SVGSVGElement>): JSX.Element => {
  const theme = useTheme();

  return (
    <svg width={12} height={14} fill="none" {...outros}>
      <path
        d="M11.92 6.62a1 1 0 0 0-.21-.33l-5-5a1.004 1.004 0 1 0-1.42 1.42L8.59 6H1a1 1 0 1 0 0 2h7.59l-3.3 3.29a.999.999 0 0 0 0 1.42 1 1 0 0 0 1.42 0l5-5a1 1 0 0 0 .21-.33 1 1 0 0 0 0-.76Z"
        fill={fill || theme.palette.cores.cinza[900]}
      />
    </svg>
  );
};

export default IconeSeta;
