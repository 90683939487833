import React from 'react';
import { isValid, set } from 'date-fns';

import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { makeStyles } from '@material-ui/core';

import { DatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { spacingInPixels } from '../../../../shared/functions/materialUtils';

const useStyles = makeStyles((theme) => ({
  id: {
    fontSize: '14px',
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  divDataHora: {
    '& .MuiOutlinedInput-input': {
      padding: 0,
      textAlign: 'center',
      fontSize: '14px',
    },
    '& .MuiOutlinedInput-root': {
      height: spacingInPixels(theme, 3),
      width: spacingInPixels(theme, 10),
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: spacingInPixels(theme, 0.5),
    },
    '& .MuiInputAdornment-positionStart': {
      marginRight: 0,
    },
    '& .MuiIconButton-root': {
      padding: 0,
    },
    '& div:only-child': {
      marginRight: spacingInPixels(theme, 1),
    },
  },
}));

type AccordionHeaderInputDataHoraProps = {
  dataInvalida?: boolean;
  setDataInvalida: React.Dispatch<React.SetStateAction<boolean>>;
  dataCarregamento: Date;
  setDataCarregamento: React.Dispatch<React.SetStateAction<Date>>;
};
const AccordionHeaderInputDataHora = ({
  dataInvalida,
  setDataInvalida,
  dataCarregamento,
  setDataCarregamento,
}: AccordionHeaderInputDataHoraProps): JSX.Element => {
  const classes = useStyles();

  const onChangeHora = (dataSelecionada: MaterialUiPickersDate) => {
    if (dataSelecionada && isValid(dataSelecionada)) {
      setDataCarregamento(
        set(dataCarregamento, {
          hours: dataSelecionada.getHours(),
          minutes: dataSelecionada.getMinutes(),
        }),
      );
      setDataInvalida(false);
    } else {
      setDataInvalida(true);
    }
  };

  const onChangeData = (dataSelecionada: MaterialUiPickersDate) => {
    if (dataSelecionada && isValid(dataSelecionada)) {
      setDataCarregamento(
        set(dataCarregamento, {
          date: dataSelecionada.getDate(),
          month: dataSelecionada.getMonth(),
          year: dataSelecionada.getFullYear(),
        }),
      );
      setDataInvalida(false);
    } else {
      setDataInvalida(true);
    }
  };

  const desabilitarAcaoSobreAccordion = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => event.stopPropagation();

  return (
    <Box display="flex" className={classes.divDataHora}>
      <DatePicker
        autoOk
        variant="inline"
        inputVariant="outlined"
        format="dd/MM"
        value={dataCarregamento}
        onChange={onChangeData}
        onClick={desabilitarAcaoSobreAccordion}
        PopoverProps={{ onClick: desabilitarAcaoSobreAccordion }}
        error={dataInvalida}
      />
      <KeyboardTimePicker
        autoOk
        mask="__h__"
        format="HH'h'mm"
        variant="inline"
        inputVariant="outlined"
        ampm={false}
        error={dataInvalida}
        value={dataCarregamento}
        onChange={onChangeHora}
        onClick={desabilitarAcaoSobreAccordion}
        PopoverProps={{ onClick: desabilitarAcaoSobreAccordion }}
        InputAdornmentProps={{
          position: 'start',
        }}
        keyboardIcon={
          <Tooltip title="Selecione o horário">
            <AccessTimeIcon />
          </Tooltip>
        }
      />
    </Box>
  );
};

AccordionHeaderInputDataHora.defaultProps = {
  dataInvalida: false,
};

export default AccordionHeaderInputDataHora;
