import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowForward } from '@material-ui/icons';
import Carregando from 'shared/components/carregando/Carregando';
import spacingInPixels from 'shared/functions/materialUtils';
import theme from 'theme';
import { buscarDadosPermissaoUsuario } from 'features/login/redux/loginOperation';
import BoxPagina from '../../../shared/components/box/BoxPagina';
import CaixaUpload from './CaixaUpload';
import DisablingTooltip from '../../../shared/components/tooltip/DisablingTooltip';
import Button from '../../../shared/components/button/ButtonTextTransformNone';
import Titulo from '../../../shared/components/titulo/Titulo';
import Subtitulo from '../../../shared/components/subtitulo/Subtitulo';
import { RootState } from '../../../store/reducer';
import { limparDadosUploadSimulador } from '../redux/uploadSimuladorAction';
import { enviarArquivo } from '../utils/uploadSimulador';
import TabUploads from './TabUploads';
import { typeAbasUpload } from '../types/tabUploads';
import { useStyles } from '../styles/styleUploadSimulador';

const UploadSimulador = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    arquivo,
    arquivoEstaSelecionado,
    arquivoFoiEnviado,
    carregando,
  } = useSelector((state: RootState) => state.uploadSimuladorReducer);

  const { dados } = useSelector((state: RootState) => state.loginReducer);

  const [abas, setAba] = useState<typeAbasUpload>('UploadDeCarga');

  useEffect(() => {
    buscarDadosPermissaoUsuario()(dispatch);
  }, [dispatch]);

  const onClickEnviar = (): void => {
    enviarArquivo(arquivo, abas)(dispatch);
  };

  useEffect(() => {
    return () => {
      dispatch(limparDadosUploadSimulador());
    };
  }, [dispatch, abas]);

  return (
    <BoxPagina component="section">
      <Titulo titulo="Upload do Simulador Agregado" />
      <Subtitulo
        aplicarEstilo
        subtitulo='O upload deve ser realizado todos os dias para manter o sistema atualizado. Faça o upload da planilha "Simulador Online" em formato .XLSX para atualizar as informações no sistema.'
      />
      <TabUploads
        aba={abas}
        roles={dados?.usuario.dadosPermissao?.roles || []}
        carregando={carregando}
        setAba={setAba}
      />
      <CaixaUpload aba={abas} />
      {!arquivoFoiEnviado && (
        <DisablingTooltip
          disabled={!arquivoEstaSelecionado}
          title="Enviar Planilha"
        >
          <Button
            aria-label="Enviar Planilha"
            variant="contained"
            onClick={onClickEnviar}
            endIcon={!carregando && <ArrowForward />}
            startIcon={
              carregando && (
                <Carregando size={spacingInPixels(theme, 3)} color="inherit" />
              )
            }
            className={classes.botaoPlanilha}
            type="submit"
            disabled={!arquivoEstaSelecionado || carregando}
          >
            {carregando ? <>Enviando...</> : <>Enviar planilha</>}
          </Button>
        </DisablingTooltip>
      )}
    </BoxPagina>
  );
};

export default UploadSimulador;
