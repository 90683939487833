import { makeStyles } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';

export const useStyles = makeStyles((theme) => ({
  quadroBigNumbers: {
    '& hr': {
      margin: 0,
    },
  },
  tituloAlerta: {
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: spacingInPixels(theme, 2.5),
    lineHeight: spacingInPixels(theme, 2.5),
    color: theme.palette.cores.azulMartins[700],
    marginBottom: spacingInPixels(theme, 2),
  },
  quadroAlertas: {
    width: '100%',
    borderRadius: spacingInPixels(theme, 1),
    '& > :not(:last-child)': {
      paddingBottom: '10px',
    },
  },
  containerBigNumber: {
    width: '100%',
    border: `1px solid transparent`,
    borderRadius: `${spacingInPixels(theme, 1)}`,
    backgroundColor: theme.palette.cores.branco,
    padding: `${spacingInPixels(theme, 2)} ${spacingInPixels(theme, 2)}`,
    boxShadow: '0px 1px 6px 0px rgba(0, 0, 0, 0.09)',
    marginBottom: spacingInPixels(theme, 1),
    cursor: 'pointer',

    '&:hover': {
      boxShadow: 'none',
      border: `1px solid ${theme.palette.cores.azul[400]}`,
    },
  },
}));
