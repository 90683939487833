import { PaginaType } from 'shared/constants/Pagina';
import { ConexaoAPI } from '../../../shared/classes/conexaoAPI';
import { LoginRequest, DadosUsuarioResponse } from './loginApiTypes';

const URL_BASE = process.env.REACT_APP_URL_API_BASE;
const URL_LOGIN = `${URL_BASE}/login`;

export const fazerLogin = async (body: LoginRequest): Promise<void> => {
  const conexao = new ConexaoAPI(URL_LOGIN, 'post');
  conexao.adicionarBody(body);
  const conectar = conexao.gerarFuncaoConectar<void>();
  await conectar();
};

export const buscarDadosUsuario = async (): Promise<DadosUsuarioResponse> => {
  const URL_BUSCAR_DADOS_USUARIO = `${URL_BASE}/colaboradores`;
  const conexao = new ConexaoAPI(URL_BUSCAR_DADOS_USUARIO, 'get');
  const conectar = conexao.gerarFuncaoConectarRefreshToken<DadosUsuarioResponse>();
  const resposta = await conectar();
  return resposta.data;
};

export const buscarDadosPermissaoUsuario = async (): Promise<PaginaType[]> => {
  const URL_BUSCAR_DADOS_USUARIO = `${URL_BASE}/colaboradores/paginas-acesso`;
  const conexao = new ConexaoAPI(URL_BUSCAR_DADOS_USUARIO, 'get');
  const conectar = conexao.gerarFuncaoConectarRefreshToken<PaginaType[]>();
  const resposta = await conectar();
  return resposta.data;
};

export const fazerLogout = async (): Promise<void> => {
  const conexao = new ConexaoAPI(URL_LOGIN, 'delete');
  const conectar = conexao.gerarFuncaoConectar<void>();
  await conectar();
};

export const refreshToken = async (): Promise<void> => {
  const conexao = new ConexaoAPI(URL_LOGIN, 'put');
  const conectar = conexao.gerarFuncaoConectar<void>();
  await conectar();
};
