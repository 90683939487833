import { useTheme } from '@material-ui/core';
import * as React from 'react';
import { SVGProps } from 'react';

const IconeCorretoCirculo = ({
  fill,
  height,
  width,
  ...outros
}: SVGProps<SVGSVGElement>): JSX.Element => {
  const theme = useTheme();
  return (
    <svg
      width={width || 22}
      height={height || 22}
      viewBox="0 0 22 22"
      fill="none"
      {...outros}
    >
      <path
        d="M20.975 8.325c.117.433.212.87.287 1.313.075.441.113.895.113 1.362 0 1.467-.27 2.83-.813 4.088a10.432 10.432 0 0 1-2.212 3.287 10.093 10.093 0 0 1-3.288 2.188c-1.258.524-2.612.787-4.062.787-1.467 0-2.83-.263-4.088-.788a10.093 10.093 0 0 1-3.287-2.187 10.092 10.092 0 0 1-2.188-3.287C.912 13.829.65 12.466.65 11c0-1.45.262-2.804.787-4.063A10.092 10.092 0 0 1 3.625 3.65a10.43 10.43 0 0 1 3.287-2.212C8.171.895 9.533.625 11 .625c1 0 1.95.142 2.85.425.9.283 1.742.658 2.525 1.125a.86.86 0 0 1 .487.613.998.998 0 0 1-.162.787c-.15.2-.35.33-.6.387-.25.059-.5.038-.75-.062a9.597 9.597 0 0 0-2.038-.925A7.503 7.503 0 0 0 11 2.625c-2.383 0-4.375.8-5.975 2.4-1.6 1.6-2.4 3.592-2.4 5.975 0 2.383.8 4.375 2.4 5.975 1.6 1.6 3.592 2.4 5.975 2.4 2.4 0 4.392-.804 5.975-2.413 1.583-1.608 2.375-3.604 2.375-5.987 0-.35-.02-.683-.063-1a7.666 7.666 0 0 0-.187-.95c-.033-.333.012-.617.137-.85a.966.966 0 0 1 .538-.475.957.957 0 0 1 .725.037.8.8 0 0 1 .475.588ZM8.8 15.075l-2.9-2.95a.88.88 0 0 1-.3-.675c0-.267.108-.508.325-.725.2-.183.433-.275.7-.275.267 0 .508.092.725.275L9.5 12.9l9.75-9.75a.977.977 0 0 1 .687-.288.93.93 0 0 1 .713.288c.217.217.325.462.325.738a.938.938 0 0 1-.325.712L10.2 15.075c-.2.2-.438.3-.713.3a.894.894 0 0 1-.687-.3Z"
        fill={fill || theme.palette.cores.azulMartins[500]}
      />
    </svg>
  );
};

export default IconeCorretoCirculo;
