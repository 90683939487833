import { withStyles } from '@material-ui/core/styles';

import Accordion from '@material-ui/core/Accordion';

export default withStyles({
  root: {
    '&.Mui-expanded': {
      margin: 0,
    },
    '&.MuiAccordion-rounded': {
      borderRadius: 0,
    },
    '&.MuiPaper-elevation1': {
      boxShadow: 'none',
    },
  },
})(Accordion);
