import { combineReducers } from 'redux';
import { enableMapSet } from 'immer';

import loginReducer from 'features/login/redux/loginReducer';
import listaCargasReducer from 'features/listaCargas/redux/listaCargasReducer';
import minhasCargasReducer from 'features/minhasCargas/redux/minhasCargasReducer';
import negociacoesAutomatizadasReducer from 'features/negociacoesAutomatizadas/redux';
import informacoesNegociacaoReducer from 'features/informacoesNegociacao/redux/informacoesNegociacaoReducer';
import valoresCardReducer from 'features/informacoesNegociacao/views/valoresCard/redux/valoresCardReducer';
import motoristasCardReducer from 'features/informacoesNegociacao/views/agregadosCard/redux/motoristasCardReducer';
import minhasNegociacoesReducer from 'features/minhasNegociacoes/redux/minhasNegociacoesReducer';
import snackbarReducer from 'shared/components/snackbar/redux/snackbarReducer';
import uploadSimuladorReducer from 'features/uploadSimulador/redux/uploadSimuladorReducer';
import acompanhamentoNegociacoesReducer from 'features/acompanhamentoNegociacoes/redux/acompanhamentoNegociacoesReducer';
import chatNegociacaoReducer from 'features/chatNegociacao/redux/chatNegociacaoReducer';
import notificacoesReducer from 'features/notificacoes/redux/notificacoesReducer';
import aprovacoesSupervisorReducer from 'features/aprovacoesSupervisor/redux/aprovacoesSupervisorReducer';
import listagemDemandasNegociadorReducer from 'features/dashboards/redux/listagemDemandasNegociador/listagemDemandasNegociadorReducer';
import detalhesFilialReducer from 'features/dashboards/redux/detalhesFilial/detalhesFilialReducer';
import evolucaoDeCargasReducer from 'features/indicadores/redux/evolucaoDeCargas/evolucaoDeCargasReducer';

enableMapSet();

const reducer = combineReducers({
  loginReducer,
  listaCargasReducer,
  minhasCargasReducer,
  negociacoesAutomatizadasReducer,
  informacoesNegociacaoReducer,
  valoresCardReducer,
  motoristasCardReducer,
  minhasNegociacoesReducer,
  snackbarReducer,
  uploadSimuladorReducer,
  acompanhamentoNegociacoesReducer,
  chatNegociacaoReducer,
  notificacoesReducer,
  aprovacoesSupervisorReducer,
  listagemDemandasNegociadorReducer,
  detalhesFilialReducer,
  evolucaoDeCargasReducer,
});

export type RootState = ReturnType<typeof reducer>;

export default reducer;
