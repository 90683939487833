import React, { useEffect, useState } from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import ExpandMoreRounded from '@material-ui/icons/ExpandMoreRounded';
import CloseRounded from '@material-ui/icons/CloseRounded';
import { withStyles, useTheme } from '@material-ui/core/styles';

import { spacingInPixels } from '../../../../shared/functions/materialUtils';
import { Carga, Motorista } from '../../../../shared/types/apiTypes';
import FeedbackSnackbar from '../../../../shared/components/snackbar/FeedbackSnackbar';

import { obterMotoristas } from '../../api/minhasCargasManager';

const AutocompleteMotoristas = withStyles((theme) => ({
  root: {
    marginRight: spacingInPixels(theme, 3),
  },
  input: {
    padding: '0px 50px 0px 0px !important',
    fontSize: '14px',
  },
  inputRoot: {
    padding: '4px !important',
  },
  endAdornment: {
    right: 0,
    top: 'auto',
  },
  paper: {
    width: 'max-content',
  },
}))(Autocomplete);

type AccordionHeaderAutocompleteMotoristasProps = {
  carga: Carga | null;
  motoristaIndicado: Motorista | null;
  setMotoristaIndicado: React.Dispatch<React.SetStateAction<Motorista | null>>;
};

const AccordionHeaderAutocompleteMotoristas = ({
  carga,
  motoristaIndicado,
  setMotoristaIndicado,
}: AccordionHeaderAutocompleteMotoristasProps): JSX.Element => {
  const theme = useTheme();

  const [snackbarAvisoAberto, setSnackbarAvisoAberto] = useState(false);
  const [autocompleteAberto, setAutocompleteAberto] = useState(false);
  const [motoristas, setMotoristas] = useState<Motorista[]>([]);

  useEffect(() => {
    if (carga) {
      obterMotoristas(carga.idFilial)
        .then((resposta) => {
          setMotoristas(resposta.motoristas);
        })
        .catch(() => {
          setSnackbarAvisoAberto(true);
        });
    }
  }, [carga]);

  const IconePopup = (): JSX.Element => (
    <Tooltip
      title={
        autocompleteAberto
          ? 'Fechar lista de motoristas'
          : 'Abrir lista de motoristas'
      }
    >
      <ExpandMoreRounded
        style={{
          color: theme.palette.cores.cinza[500],
          height: spacingInPixels(theme, 3),
          width: spacingInPixels(theme, 3),
        }}
      />
    </Tooltip>
  );

  const IconeLimpar = (): JSX.Element => (
    <Tooltip title="Limpar">
      <CloseRounded
        style={{
          color: theme.palette.cores.cinza[500],
          height: spacingInPixels(theme, 2),
          width: spacingInPixels(theme, 2),
        }}
      />
    </Tooltip>
  );

  const mensagemSnackBar = `Não foi possível carregar a lista de motoristas para a carga ${carga?.romaneio}`;

  return (
    <>
      <AutocompleteMotoristas
        value={motoristaIndicado}
        options={motoristas}
        getOptionLabel={(motorista) => (motorista as Motorista).nome}
        onOpen={() => {
          setAutocompleteAberto(true);
        }}
        onClose={() => {
          setAutocompleteAberto(false);
        }}
        onChange={(_, motorista) => {
          setMotoristaIndicado(motorista as Motorista);
        }}
        closeIcon={<IconeLimpar />}
        popupIcon={<IconePopup />}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Motorista Indicado"
            variant="outlined"
            onClick={(e) => e.stopPropagation()}
          />
        )}
      />
      <FeedbackSnackbar
        open={snackbarAvisoAberto}
        onClose={() => {
          setSnackbarAvisoAberto(false);
        }}
        severity="warning"
        mensagem={mensagemSnackBar}
      />
    </>
  );
};

export default AccordionHeaderAutocompleteMotoristas;
