import TipoValorContraproposta from '../../features/chatNegociacao/constants/TipoValorContraproposta';
import { formatarNumeroParaStringMonetaria } from './dinheiroUtils';
import {
  calcularPorcentagemDecimal,
  calcularPorcentagemSomenteParteInteira,
} from './porcentagemUtils';

const calcularValorRelacionado = (
  tipo: TipoValorContraproposta,
  campoDigitado: number,
  valorReferencia = 0,
): number => {
  switch (tipo) {
    case TipoValorContraproposta.PorcentagemFrotaPropria:
    case TipoValorContraproposta.PorcentagemFreteTerceiro:
      return calcularPorcentagemSomenteParteInteira(
        campoDigitado,
        valorReferencia,
      );
    case TipoValorContraproposta.PorcentagemMercadoria:
      return calcularPorcentagemDecimal(campoDigitado, valorReferencia);
    case TipoValorContraproposta.PorEntrega:
    case TipoValorContraproposta.PorKm:
    case TipoValorContraproposta.PorPeso:
      return Math.round(campoDigitado / valorReferencia);
    case TipoValorContraproposta.ValorProposto:
    default:
      return campoDigitado;
  }
};

export const exibirValorRelacionado = (
  tipo: TipoValorContraproposta,
  campoDigitado?: number,
  valorReferencia?: number,
): string => {
  if (
    campoDigitado === undefined ||
    valorReferencia === 0 ||
    (valorReferencia === undefined &&
      tipo !== TipoValorContraproposta.ValorProposto)
  )
    return '--';

  const valorRelacionado = calcularValorRelacionado(
    tipo,
    campoDigitado,
    valorReferencia,
  );

  switch (tipo) {
    case TipoValorContraproposta.PorcentagemFrotaPropria:
    case TipoValorContraproposta.PorcentagemFreteTerceiro:
    case TipoValorContraproposta.PorcentagemMercadoria:
      return valorRelacionado?.toLocaleString('pt-br');
    case TipoValorContraproposta.PorEntrega:
    case TipoValorContraproposta.PorKm:
    case TipoValorContraproposta.PorPeso:
    case TipoValorContraproposta.ValorProposto:
    default:
      return formatarNumeroParaStringMonetaria(valorRelacionado);
  }
};
