import {
  Box,
  makeStyles,
  Typography,
  useTheme,
  withStyles,
} from '@material-ui/core';
import React from 'react';
import IconeReprovadoCirculo from '../../../assets/icones/IconeReprovadoCirculo';
import IconePreenchido from '../icons/IconePreenchido';
import spacingInPixels from '../../functions/materialUtils';

interface EmptyPageStylesProps {
  corfundo?: string;
}

export const BoxEmptyPage = withStyles((theme) => ({
  root: (props: EmptyPageStylesProps) => ({
    backgroundColor: props.corfundo
      ? props.corfundo
      : theme.palette.cores.branco,
  }),
}))(Box);

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '54px 12px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    '& > figure': {
      borderRadius: '70px',
    },
  },
  texto: {
    fontWeight: 400,
    fontSize: 16,
    textAlign: 'center',
    marginTop: spacingInPixels(theme, 2),
  },
  iconeErro: {
    borderRadius: spacingInPixels(theme, 5),
  },
}));

export interface EmptyPageProps {
  texto: string;
  corFundo?: string;
  icone?: JSX.Element;
  iconePreenchido?: boolean;
}

const EmptyPage = ({
  texto,
  corFundo = '',
  icone,
  iconePreenchido,
}: EmptyPageProps): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <BoxEmptyPage corfundo={corFundo}>
      <article className={classes.container}>
        {iconePreenchido ? (
          <IconePreenchido
            icone={
              icone || (
                <IconeReprovadoCirculo
                  width={134}
                  height={134}
                  viewBox="-6 0 34 22"
                />
              )
            }
            cor={theme.palette.cores.cinza[700]}
            className={classes.iconeErro}
          />
        ) : (
          icone || (
            <IconeReprovadoCirculo
              width={134}
              height={134}
              viewBox="-6 0 34 22"
              fill={theme.palette.cores.cinza[600]}
            />
          )
        )}
        <Typography className={classes.texto}>{texto}</Typography>
      </article>
    </BoxEmptyPage>
  );
};

EmptyPage.defaultProps = {
  corFundo: 'white',
  iconePreenchido: true,
};
export default EmptyPage;
