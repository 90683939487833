import React from 'react';
import BoxPagina from 'shared/components/box/BoxPagina';
import FeatureDemandaDiaria from 'features/demandaDiaria';
import FeatureListagemNegociadores from 'features/dashboards';

const PainelGestao = (): JSX.Element => {
  return (
    <BoxPagina component="section">
      <FeatureDemandaDiaria />
      <FeatureListagemNegociadores />
    </BoxPagina>
  );
};

export default PainelGestao;
