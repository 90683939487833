import React from 'react';
import { useSelector } from 'react-redux';

import { Box } from '@material-ui/core';

import IconeCalendario from 'assets/icones/IconeCalendario';
import theme from 'theme';
import IconeCaminhaoLadoDireito from 'assets/icones/IconeCaminhaoLadoDireito';
import IconeMapa from 'assets/icones/IconeMapa';
import IconeCronometro from 'assets/icones/IconeCronometro';
import IconeMoney from 'assets/icones/IconeMoney';

import { format } from 'date-fns';
import InformacaoItem from '../../../../shared/components/item/InformacaoItem';
import { RootState } from '../../../../store/reducer';
import { formatarNumeroParaStringMonetariaComSimbolo } from '../../../../shared/functions/dinheiroUtils';

const InformacaoHeaderValores = (): JSX.Element => {
  const { negociacao, negociacaoConcluida } = useSelector(
    (state: RootState) => state.informacoesNegociacaoReducer,
  );
  let dataSolicitacaoFormat;
  let dataCarregamentoFormat;
  let dataEntregaFinalFormat;

  const dataSolicitacao = negociacao?.carga.dataSolicitacao;
  const dataCarregamento = negociacao?.dataCarregamento;
  const dataEntregaFinal = negociacao?.dataEntregaFinal;

  if (dataSolicitacao !== undefined && dataSolicitacao !== null) {
    const dataFormatSolicitacao = new Date(dataSolicitacao);
    dataSolicitacaoFormat = format(dataFormatSolicitacao, 'dd/MM/yyyy');
  }

  if (dataCarregamento !== undefined && dataCarregamento !== null) {
    const dataFormatCarregamento = new Date(dataCarregamento);
    dataCarregamentoFormat = format(dataFormatCarregamento, 'dd/MM/yyyy');
  }

  if (dataEntregaFinal) {
    const dataFormatEntregaFinal = new Date(dataEntregaFinal);
    dataEntregaFinalFormat = format(dataFormatEntregaFinal, 'dd/MM/yyyy');
  }

  return (
    <>
      {negociacao && (
        <Box display="flex" justifyContent="space-between" width="100%">
          <InformacaoItem
            icone={
              <IconeMapa
                fill={theme.palette.cores.azulMartins[500]}
                width="20px"
                height="20px"
              />
            }
            informacao={negociacao.carga.macro}
            altIcone="Macrorregião"
            titleIcone="Macrorregião"
          />

          <InformacaoItem
            icone={
              <IconeCaminhaoLadoDireito
                fill={theme.palette.cores.azulMartins[500]}
                width="20px"
                height="20px"
              />
            }
            informacao={negociacao.carga.veiculo}
            altIcone="Veículo"
            titleIcone="Veículo"
          />

          <InformacaoItem
            icone={
              <IconeMoney
                fill={theme.palette.cores.azulMartins[500]}
                width="20px"
                height="20px"
              />
            }
            informacao={formatarNumeroParaStringMonetariaComSimbolo(
              negociacao.carga.valor,
            )}
            altIcone="Valor"
            titleIcone="Valor da carga"
          />

          <InformacaoItem
            icone={
              <IconeCalendario
                fill={theme.palette.cores.azulMartins[500]}
                width="20px"
                height="20px"
              />
            }
            informacao={
              dataSolicitacaoFormat || negociacao.carga.dataSolicitacao
            }
            altIcone="Solicitação"
            titleIcone="Solicitação"
          />

          <InformacaoItem
            icone={
              <IconeCalendario
                fill={theme.palette.cores.azulMartins[500]}
                width="20px"
                height="20px"
              />
            }
            informacao={dataCarregamentoFormat || '-'}
            altIcone="Carregamento"
            titleIcone="Carregamento"
          />

          <InformacaoItem
            icone={
              <IconeCalendario
                fill={theme.palette.cores.azulMartins[500]}
                width="20px"
                height="20px"
              />
            }
            informacao={dataEntregaFinalFormat || '-'}
            altIcone="Entrega"
            titleIcone="Entrega final"
          />
          {!negociacaoConcluida && (
            <Box>
              <InformacaoItem
                icone={
                  <IconeCronometro
                    fill={theme.palette.cores.azulMartins[500]}
                    width="20px"
                    height="20px"
                  />
                }
                informacao={negociacao.carga.prazo}
                altIcone="Prazo"
                titleIcone="Prazo"
              />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default InformacaoHeaderValores;
