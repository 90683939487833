import { Theme } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

import { spacingInPixels } from './materialUtils';

const createFirefoxCustomScrollbar = (theme: Theme): CSSProperties => ({
  scrollbarWidth: 'thin',
  scrollbarColor: `${theme.palette.cores.cinza[500]}60 transparent`,
});

const createWebkitScrollbar = (theme: Theme): CSSProperties => ({
  width: spacingInPixels(theme, 0.7),
  height: spacingInPixels(theme, 1),
  borderRadius: spacingInPixels(theme, 1),
});

const createWebkitScrollbarTrack = (theme: Theme): CSSProperties => ({
  borderRadius: spacingInPixels(theme, 1),
  backgroundColor: 'transparent',
});

const createWebkitScrollbarThumb = (theme: Theme): CSSProperties => ({
  borderRadius: spacingInPixels(theme, 1),
  backgroundColor: `${theme.palette.cores.azul[500]}`,
});

const createWebkitScrollbarThumbHover = (theme: Theme): CSSProperties => ({
  backgroundColor: `${theme.palette.cores.azul[500]}80 `,
});

const createWebkitScrollbarThumbActive = (theme: Theme): CSSProperties => ({
  backgroundColor: `${theme.palette.cores.azul[500]}95 !important`,
});

const scrollbarConstructor = (
  className: string,
  theme: Theme,
): CSSProperties => ({
  [`& .${className}`]: {
    ...createFirefoxCustomScrollbar(theme),
  },
  [`& .${className}::-webkit-scrollbar-track`]: {
    ...createWebkitScrollbarTrack(theme),
  },
  [`& .${className}::-webkit-scrollbar`]: {
    ...createWebkitScrollbar(theme),
  },
  [`& .${className}::-webkit-scrollbar-thumb`]: {
    ...createWebkitScrollbarThumb(theme),
  },
  [`& .${className}::-webkit-scrollbar-thumb:active`]: {
    ...createWebkitScrollbarThumbActive(theme),
  },
  [`& .${className}::-webkit-scrollbar-thumb:hover`]: {
    ...createWebkitScrollbarThumbHover(theme),
  },
});

export default scrollbarConstructor;
