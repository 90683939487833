import { Dispatch } from 'redux';
import PropostaStatus from 'shared/constants/PropostaStatus';

import TipoMensagemProposta, {
  TipoMensagemPropostaType,
  verificarMensagemInicial,
} from 'shared/constants/TipoMensagemProposta';
import {
  aceitarPropostaGreguimOperation,
  aceitarPropostaOperation,
  enviarMensagemOperation,
} from '../redux/chatNegociacaoOperation';

export const ocultarCaixaResposta = (
  chatTipoMensagemReferencia: TipoMensagemPropostaType | undefined,
): boolean =>
  chatTipoMensagemReferencia === undefined ||
  verificarMensagemInicial(chatTipoMensagemReferencia);

interface EnviarMensagemInterface {
  chatIdNegociacao: number | undefined;
  chatIdProposta: number | undefined;
  valor: number | undefined;
  motivo: string | undefined;
  motivoRecusa: number | undefined;
  tipoMensagem: TipoMensagemProposta;
  propostaStatus: PropostaStatus;
  setEnviando: (erro: boolean) => void;
  dispatch: Dispatch;
  arquivo?: File;
}

export const enviarMensagem = async ({
  chatIdNegociacao,
  chatIdProposta,
  valor,
  motivo,
  motivoRecusa,
  tipoMensagem,
  propostaStatus,
  setEnviando,
  dispatch,
  arquivo,
}: EnviarMensagemInterface): Promise<void> => {
  setEnviando(true);

  const enviadoComSucesso = await enviarMensagemOperation({
    idNegociacao: chatIdNegociacao,
    idProposta: chatIdProposta,
    valorContraproposta: valor,
    texto: motivo,
    motivoRecusa,
    tipo: tipoMensagem,
    propostaStatus,
    arquivo,
  })(dispatch);

  if (!enviadoComSucesso) {
    setEnviando(false);
  }
};

interface ExibirBotaoAceitarInterface {
  sugestaoValorInicialRobo?: number;
  valorFrotaPropria?: number;
  valorContraproposta?: number;
  valorNovaMeta?: number;
}

export const exibirBotaoAceitar = ({
  sugestaoValorInicialRobo,
  valorContraproposta,
  valorFrotaPropria,
  valorNovaMeta,
}: ExibirBotaoAceitarInterface): boolean =>
  !!valorContraproposta &&
  !!valorFrotaPropria &&
  ((!!sugestaoValorInicialRobo &&
    sugestaoValorInicialRobo >= valorContraproposta) ||
    valorContraproposta <= valorFrotaPropria ||
    (!!valorNovaMeta && valorContraproposta <= valorNovaMeta));

interface ExibirBotaoPedirAprovacaoInterface {
  sugestaoValorInicialRobo?: number;
  valorFrotaPropria?: number;
  valorContraproposta?: number;
  valorNovaMeta?: number;
}

export const exibirBotaoPedirAprovacao = ({
  sugestaoValorInicialRobo,
  valorFrotaPropria,
  valorContraproposta,
  valorNovaMeta,
}: ExibirBotaoPedirAprovacaoInterface): boolean =>
  !!valorContraproposta &&
  !!valorFrotaPropria &&
  (!sugestaoValorInicialRobo ||
    valorContraproposta >= sugestaoValorInicialRobo) &&
  ((!valorNovaMeta && valorContraproposta > valorFrotaPropria) ||
    (!!valorNovaMeta && valorContraproposta > valorNovaMeta));

interface AceitarPropostaInterface {
  chatIdNegociacao: number | undefined;
  chatIdProposta: number | undefined;
  setEnviando: (erro: boolean) => void;
  dispatch: Dispatch;
}

export const aceitarProposta = async ({
  chatIdNegociacao,
  chatIdProposta,
  setEnviando,
  dispatch,
}: AceitarPropostaInterface): Promise<void> => {
  setEnviando(true);

  const enviadoComSucesso = await aceitarPropostaOperation({
    idNegociacao: chatIdNegociacao,
    idProposta: chatIdProposta,
  })(dispatch);

  if (!enviadoComSucesso) {
    setEnviando(false);
  }
};

export const aceitarPropostaGreguim = async ({
  chatIdNegociacao,
  chatIdProposta,
  setEnviando,
  dispatch,
}: AceitarPropostaInterface): Promise<void> => {
  setEnviando(true);

  const enviadoComSucesso = await aceitarPropostaGreguimOperation({
    idNegociacao: chatIdNegociacao,
    idProposta: chatIdProposta,
  })(dispatch);

  if (!enviadoComSucesso) {
    setEnviando(false);
  }
};
