import React from 'react';
import Configuracoes from '../../features/configuracoes/views/Configuracoes';
import BoxPagina from '../../shared/components/box/BoxPagina';

const PaginaConfiguracoes = (): JSX.Element => (
  <BoxPagina component="section">
    <Configuracoes />
  </BoxPagina>
);

export default PaginaConfiguracoes;
