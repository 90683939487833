import React, { useMemo, useState } from 'react';
import { DetalhesDemandaDiaria } from 'features/detalhesDemandasDiaria/api/detalhesDemandasDiariaApiTypes';
import { ordenarDemandas } from 'features/detalhesDemandasDiaria/utils/detalhesDemandas';
import { ChaveOrdenacaoDetalhesDemandas } from 'features/detalhesDemandasDiaria/constants/Constants';

import { DadosCabecalho } from 'shared/components/tabelaDemandas/types/TabelaTypes';

import ItensHeaderDemandasAndamentoEPendentes from 'features/detalhesDemandasDiaria/constants/ItensHeaderDemandasAndamentoEPendentes';
import PaginaVaziaIndicadores from 'shared/components/paginaVaziaIndicadores/PaginaVaziaIndicadores';
import { TabelaProps } from 'features/detalhesDemandasDiaria/types/tabelaTypes';
import { estilosTabelaDemandasPendentes } from '../../styles/styleTabela';
import ConteudoTabelaDemandasPendentes from './ConteudoTabelaDemandasPendentes';
import CabecalhoTabelaDetalhesDemandas from '../../components/tabelaDetalhesDemandas/CabecalhoTabelaDetalhesDemandas';
import SkeletonTabelaDetalhesDemandas from '../../components/tabelaDetalhesDemandas/SkeletonTabelaDetalhesDemandas';
import { useStyles } from '../../styles/styleParaTabelas.ts';
import { dadosSkeleton } from '../../constants/TabelaDemandasPendentes';

const TabelaDemandasPendentes = ({
  demandas,
  carregando,
  erro,
  chavesOrdenacao = [],
}: TabelaProps): JSX.Element => {
  const classes = useStyles();

  const { gridTabela } = estilosTabelaDemandasPendentes();

  const [demandasOrdenadas, setDemandasOrdenadas] = useState<
    DetalhesDemandaDiaria[]
  >([]);

  const handleClickOrdenacao = (chave: string, ordenacaoCrescente: boolean) => {
    setDemandasOrdenadas(
      ordenarDemandas(
        demandasOrdenadas,
        chave as ChaveOrdenacaoDetalhesDemandas,
        ordenacaoCrescente,
      ),
    );
  };

  const adicionaOrdenacao = (
    itens: DadosCabecalho[],
    chaves: ChaveOrdenacaoDetalhesDemandas[],
  ): DadosCabecalho[] => {
    const itensRetorno = [...itens];
    chaves.forEach((chave) => {
      const itemOrdenacao = itensRetorno.find((item) => item?.chave === chave);
      if (itemOrdenacao) {
        itemOrdenacao.possuiOrdenacao = true;
      }
    });

    return itensRetorno;
  };

  useMemo(() => {
    setDemandasOrdenadas([...demandas]);
  }, [demandas]);

  const dadosItensCabecalho = useMemo(
    () =>
      adicionaOrdenacao(
        ItensHeaderDemandasAndamentoEPendentes,
        chavesOrdenacao,
      ),
    [chavesOrdenacao],
  );

  if (demandasOrdenadas.length === 0 && !carregando)
    return <PaginaVaziaIndicadores erro={erro} />;

  return (
    <table className={classes.container}>
      <thead>
        <CabecalhoTabelaDetalhesDemandas
          onClickOrdenacao={handleClickOrdenacao}
          dadosItensCabecalho={dadosItensCabecalho}
          estiloCabecalho={gridTabela}
        />
      </thead>
      {carregando ? (
        <SkeletonTabelaDetalhesDemandas
          dadosSkeleton={dadosSkeleton}
          estiloLinha={gridTabela}
        />
      ) : (
        <ConteudoTabelaDemandasPendentes demandas={demandasOrdenadas} />
      )}
    </table>
  );
};

export default TabelaDemandasPendentes;
