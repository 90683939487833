import { useTheme } from '@material-ui/core/styles';
import * as React from 'react';
import { SVGProps } from 'react';

const IconeInformacao = ({
  fill,
  stroke,
  ...outros
}: SVGProps<SVGSVGElement>): JSX.Element => {
  const theme = useTheme();

  return (
    <svg width={22} height={22} viewBox="0 0 22 22" fill="none" {...outros}>
      <path
        d="M11.1 16.2a.898.898 0 0 0 .9-.925v-4.4a.822.822 0 0 0-.275-.612A.881.881 0 0 0 11.1 10a.898.898 0 0 0-.925.9v4.4c0 .25.092.463.275.638a.91.91 0 0 0 .65.262ZM11 7.95a.94.94 0 0 0 .675-.262.877.877 0 0 0 .275-.663.948.948 0 0 0-.275-.7A.918.918 0 0 0 11 6.05c-.283 0-.512.092-.687.275a.973.973 0 0 0-.263.7c0 .267.092.488.275.663A.94.94 0 0 0 11 7.95Zm0 13.45c-1.467 0-2.833-.267-4.1-.8a10.224 10.224 0 0 1-3.3-2.2 10.223 10.223 0 0 1-2.2-3.3C.867 13.833.6 12.467.6 11c0-1.467.267-2.833.8-4.1a10.223 10.223 0 0 1 2.2-3.3 10.223 10.223 0 0 1 3.3-2.2C8.167.867 9.533.6 11 .6c1.467 0 2.833.267 4.1.8a10.223 10.223 0 0 1 3.3 2.2 10.224 10.224 0 0 1 2.2 3.3c.533 1.267.8 2.633.8 4.1 0 1.467-.267 2.833-.8 4.1a10.223 10.223 0 0 1-2.2 3.3 10.223 10.223 0 0 1-3.3 2.2c-1.267.533-2.633.8-4.1.8Z"
        fill={fill || theme.palette.cores.cinza[400]}
        stroke={stroke || 'none'}
      />
    </svg>
  );
};

export default IconeInformacao;
