import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  tratarErroConexao,
  tratarRefreshToken,
} from '../functions/tratamentoErroAPI';

export type CabecalhoConfigType = {
  [key: string]: string;
};

type methodType = 'get' | 'post' | 'put' | 'patch' | 'delete';

type responseTypes = 'arraybuffer' | 'blob';

export class ConexaoAPI {
  private url: string;

  private method: string;

  private config: AxiosRequestConfig;

  private body: unknown;

  private cabecalhoConfig: CabecalhoConfigType;

  constructor(url: string, method: methodType) {
    this.url = url;
    this.method = method;
    this.config = {};
    this.body = null;
    this.cabecalhoConfig = {};

    this.adicionarPropriedadeCabecalho('Content-Type', 'application/json');
  }

  public adicionarBody(body: unknown): this {
    this.body = body;
    return this;
  }

  public adicionarResponseTypeArrayBuffer(): this {
    this.adicionarResponseType('arraybuffer');
    return this;
  }

  public adicionarResponseTypeBlob(): this {
    this.adicionarResponseType('blob');
    return this;
  }

  public gerarFuncaoConectarRefreshToken<T>(): () => Promise<AxiosResponse<T>> {
    this.construirConfiguracao();
    const conectar = this.definirFuncaoConectar<T>();

    return () =>
      conectar().catch(async (error) => tratarRefreshToken(error, conectar));
  }

  public gerarFuncaoConectar<T>(): () => Promise<AxiosResponse<T>> {
    this.construirConfiguracao();
    const conectar = this.definirFuncaoConectar<T>();

    return () => conectar().catch(tratarErroConexao);
  }

  private adicionarResponseType(responseType: responseTypes): this {
    this.config = { ...this.config, responseType };
    return this;
  }

  private definirFuncaoConectar<T>(): () => Promise<AxiosResponse<T>> {
    switch (this.method) {
      case 'get':
        return () => axios.get<T>(this.url, this.config);
      case 'post':
        return () => axios.post<T>(this.url, this.body, this.config);
      case 'put':
        return () => axios.put<T>(this.url, this.body, this.config);
      case 'delete':
        return () => axios.delete<T>(this.url, this.config);
      case 'patch':
      default:
        return () => axios.patch<T>(this.url, this.body, this.config);
    }
  }

  private adicionarPropriedadeCabecalho(nome: string, valor: string): void {
    this.cabecalhoConfig[nome] = valor;
  }

  private construirConfiguracao(): void {
    this.config = {
      ...this.config,
      headers: this.cabecalhoConfig,
    };
  }
}
