import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  buscarCategoriaVeiculosFreteTabelado,
  buscarFreteTabeladoMacros,
  buscarFreteTabeladoPorIdMacro,
  buscarFreteTabeladoPorIdFilial,
  buscarFreteTabeladoMacroFilial,
} from 'features/configuracoes/utils/configuracoes';
import {
  CategoriaVeiculo,
  FreteTabeladoRegiao,
  FreteTabeladoMacroId,
} from 'features/configuracoes/api/configuracoesApiTypes';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import SwitchAba, { OpcaoSwitchAbaType } from 'shared/components/aba/SwitchAba';
import { useStyles } from 'features/configuracoes/styles/freteTabeladoRegioes';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import { Autocomplete } from '@material-ui/lab';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import theme from 'theme';
import DataGridFreteTabeladoRegiao from 'features/configuracoes/components/DataGridFreteTabeladoRegiao';

const AtributosFreteTabeladoRegiao = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [horario, setHorario] = useState<string>('');
  const [horarioTemp, setHorarioTemp] = useState<number>();
  const [tableConfig, setTableConfig] = useState<boolean>(false);
  const [mostrarGrupo, setMostrarGrupo] = useState(true);
  const [messagemGrupo, setMessagemGrupo] = useState(true);
  const [dadosVeiculos, setDadosVeiculos] = useState<CategoriaVeiculo[]>([]);
  const [dadosRegioes, setDadosRegioes] = useState<FreteTabeladoRegiao[]>([]);
  const [dadosVeiculosRegiaoId, setDadosVeiculosRegiaoId] = useState<
    FreteTabeladoMacroId[]
  >([]);
  const [selectedsOption, setSelectedsOption] = useState<FreteTabeladoRegiao[]>(
    [],
  );
  const [abaSelecionada, setAbaSelecionada] = useState<number>(0);
  const [veiculosAtivo, setVeiculoAtivo] = useState(false);
  const [inputVazio, setInputVazio] = useState(false);
  const [
    dadosIdCategoriaVeiculo,
    setDadosIdCategoriaVeiculo,
  ] = useState<number>(1);

  const opcoesSwitchAba: OpcaoSwitchAbaType[] =
    dadosVeiculos?.map((veiculo, index) => ({
      id: index,
      idCategoriaVeiculo: veiculo.idCategoriaVeiculo,
      valor: veiculo.descricao,
      conteudoBadge: null,
    })) || [];

  const filteredData = (
    (dadosVeiculosRegiaoId &&
      dadosVeiculosRegiaoId[0] &&
      dadosVeiculosRegiaoId[0].fretes) ||
    []
  ).filter((item) => {
    const idCategoriaVeiculoSelecionada =
      opcoesSwitchAba[abaSelecionada].idCategoriaVeiculo;
    return (
      item.categoriaVeiculo.idCategoriaVeiculo === idCategoriaVeiculoSelecionada
    );
  });

  if (opcoesSwitchAba.length > 0 && opcoesSwitchAba[0].valor === 'Truck') {
    const primeiroItem = opcoesSwitchAba.shift();
    if (primeiroItem) {
      opcoesSwitchAba.push(primeiroItem);
    }
  }

  useEffect(() => {
    buscarCategoriaVeiculosFreteTabelado((dados) => {
      const dadosTruck = dados.filter((item) => item.descricao === 'Truck');
      const dadosSemTruck = dados.filter((item) => item.descricao !== 'Truck');
      const dadosAtualizados = [...dadosSemTruck, ...dadosTruck];
      setDadosVeiculos(dadosAtualizados);
    }, dispatch);
    setHorarioTemp(
      dadosVeiculosRegiaoId[0]?.tempoParaExpirarNegociacoesEmMinutos,
    );
  }, [dadosVeiculosRegiaoId, dispatch]);

  useEffect(() => {
    buscarCategoriaVeiculosFreteTabelado(setDadosVeiculos, dispatch);
    buscarFreteTabeladoMacros(setDadosRegioes, dispatch);
  }, [dispatch, abaSelecionada]);

  useEffect(() => {
    const buscarDadosPorRegiao = () => {
      if (
        abaSelecionada !== undefined &&
        dadosVeiculos.length > abaSelecionada
      ) {
        const idCategoVeiculos =
          dadosVeiculos[abaSelecionada].idCategoriaVeiculo;
        const IdCategoriaVeiculosRegiao = (
          (dadosVeiculosRegiaoId[0] && dadosVeiculosRegiaoId[0].fretes) ||
          []
        ).map((dado) => dado.categoriaVeiculo.idCategoriaVeiculo);

        setDadosIdCategoriaVeiculo(idCategoVeiculos);
        if (IdCategoriaVeiculosRegiao.includes(idCategoVeiculos)) {
          setVeiculoAtivo(true);
        } else {
          setVeiculoAtivo(false);
        }
      }
    };
    buscarDadosPorRegiao();
  }, [abaSelecionada, dadosVeiculosRegiaoId, dadosVeiculos]);

  useEffect(() => {
    const tempoParaExpirar = horarioTemp || null;

    if (tempoParaExpirar !== null) {
      // Converte o valor para "hh:mm"
      const horas = Math.floor(tempoParaExpirar / 60)
        .toString()
        .padStart(2, '0');
      const minutos = (tempoParaExpirar % 60).toString().padStart(2, '0');
      const horarioFormatado = `${horas}    ${minutos}`;

      setHorario(horarioFormatado);
    } else {
      setHorario('00   00');
    }
  }, [horarioTemp]);

  const handleAtivarVeiculo = () => {
    setVeiculoAtivo(!veiculosAtivo);
  };

  const handleOptionChange = async (
    _event: React.ChangeEvent<unknown>,
    newValue: FreteTabeladoRegiao[] | [],
  ) => {
    setSelectedsOption(newValue);
    if (Array.isArray(newValue) && newValue.length > 0) {
      const { idMacroFilial, idFilial } = newValue[0];

      if (idFilial === null) {
        await buscarFreteTabeladoPorIdMacro(
          idMacroFilial.toString(),
          setDadosVeiculosRegiaoId,
          dispatch,
        );
      }

      setInputVazio(true);
      setTableConfig(false);
      setMostrarGrupo(false);
    } else {
      setInputVazio(false);
      setDadosVeiculosRegiaoId([]);

      setTableConfig(false);
    }
  };

  const handleGroup = async (value: boolean) => {
    if (value === true) {
      await buscarFreteTabeladoMacroFilial(
        selectedsOption[0]?.idFilial.toString(),
        setSelectedsOption,
        dispatch,
      );
      await buscarFreteTabeladoPorIdFilial(
        selectedsOption[0]?.idFilial.toString(),
        setDadosVeiculosRegiaoId,
        dispatch,
      );
      if (!dadosVeiculosRegiaoId) {
        setVeiculoAtivo(false);
      }
      setTableConfig(true);
      setMessagemGrupo(false);
      setHorarioTemp(
        dadosVeiculosRegiaoId[0]?.tempoParaExpirarNegociacoesEmMinutos,
      );
    } else {
      await buscarFreteTabeladoPorIdMacro(
        selectedsOption[0]?.idMacroFilial.toString(),
        setDadosVeiculosRegiaoId,
        dispatch,
      );
      if (!dadosVeiculosRegiaoId) {
        setVeiculoAtivo(false);
      }
      setTableConfig(true);
      setMessagemGrupo(false);
      setHorarioTemp(
        dadosVeiculosRegiaoId[0]?.tempoParaExpirarNegociacoesEmMinutos,
      );
    }
    setMostrarGrupo(false);
  };

  const handleEnableAutocomplete = () => {
    setMostrarGrupo(true);
    setMessagemGrupo(true);
    setSelectedsOption([]);
    setTableConfig(false);
    setInputVazio(false);
    setVeiculoAtivo(false);
    setAbaSelecionada(0);
  };

  const handleChangeHorario = (e: { target: { value: string } }) => {
    const horas = e.target.value;
    const horasNumeros = horas.replace(/\D/g, '');
    const horasLimitadas = horasNumeros.slice(0, 4);
    const partes = horasLimitadas.match(/.{1,2}/g);

    if (partes) {
      const horasFormatadas = partes.join('    ');

      setHorario(horasFormatadas);
    } else {
      setHorario('');
    }
  };

  return (
    <Box style={{ height: veiculosAtivo && tableConfig ? '90vh' : 'auto' }}>
      <Box display="flex" flexWrap="wrap">
        <Autocomplete
          multiple
          options={dadosRegioes}
          getOptionLabel={(option) => option.descricao}
          className={classes.autoComplete}
          value={selectedsOption}
          disabled={!mostrarGrupo}
          onChange={handleOptionChange}
          getOptionSelected={(option, value) =>
            option.idMacroFilial === value.idMacroFilial
          }
          popupIcon={
            mostrarGrupo && (
              <SearchRoundedIcon
                style={{ color: theme.palette.cores.cinza[500] }}
              />
            )
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Regiões de Custos Fixos"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                style: { paddingRight: 0 },
                endAdornment: (
                  <>
                    {!mostrarGrupo && (
                      <IconButton
                        style={{ marginRight: '20px' }}
                        onClick={handleEnableAutocomplete}
                      >
                        <Close />
                      </IconButton>
                    )}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
        {tableConfig && (
          <FormControl className={classes.formControl} variant="outlined">
            <FormHelperText className={classes.textTempo}>
              Tempo de negociação:
            </FormHelperText>
            <OutlinedInput
              id="outlined-adornment-time"
              type="string"
              className={classes.inputNumber}
              value={horario}
              onChange={handleChangeHorario}
              endAdornment={
                <>
                  <InputAdornment
                    className={classes.AdornmentH}
                    position="start"
                  >
                    <Typography className={classes.inputTypography}>
                      h
                    </Typography>
                  </InputAdornment>
                  <InputAdornment
                    className={classes.AdornmentMin}
                    position="end"
                  >
                    <Typography className={classes.inputTypography}>
                      min
                    </Typography>
                  </InputAdornment>
                </>
              }
            />
          </FormControl>
        )}
      </Box>
      {messagemGrupo && selectedsOption[0]?.idFilial && (
        <Box>
          <Typography className={classes.grupoText}>
            <b>{selectedsOption[0]?.descricao}:</b> pertence há um grupo já
            configurado, deseja ver todos?
            <Button
              className={`${classes.buttonYesNo} ${classes.buttonNoMargin}`}
              variant="outlined"
              onClick={() => handleGroup(false)}
            >
              NÃO
            </Button>
            <Button
              className={classes.buttonYesNo}
              color="primary"
              variant="contained"
              onClick={() => handleGroup(true)}
            >
              SIM
            </Button>
          </Typography>
        </Box>
      )}
      {inputVazio && (!selectedsOption[0]?.idFilial || tableConfig) && (
        <>
          <Typography className={classes.title}>
            Selecione o grupo de veículo abaixo e salve os custo fixo:
          </Typography>

          {opcoesSwitchAba.length > 0 && (
            <SwitchAba
              abas={opcoesSwitchAba}
              idAbaInicial={opcoesSwitchAba?.[0].id}
              onClickOpcaoSwitch={(aba) => {
                setAbaSelecionada(aba);
              }}
            />
          )}

          <FormGroup className={classes.formSwitch}>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={veiculosAtivo && inputVazio}
                  onChange={handleAtivarVeiculo}
                />
              }
              label={
                <span className={classes.switchLabel}>
                  Veículo {veiculosAtivo && inputVazio ? 'Ativo' : 'Desativado'}
                </span>
              }
            />
          </FormGroup>

          {veiculosAtivo && inputVazio ? (
            <Box style={{ height: 400, width: '100%' }}>
              <DataGridFreteTabeladoRegiao
                dadosVeiculosRegiaoId={filteredData}
                idVeiculo={dadosIdCategoriaVeiculo}
                dataRegiao={selectedsOption}
                setDadosVeiculosRegiaoId={setDadosVeiculosRegiaoId}
                tempoParaExpirarNegociacoesEmMinutos={horario || '0'}
              />
            </Box>
          ) : (
            <Box className={classes.boxDesativado}>
              <LocalShippingOutlinedIcon className={classes.icon} />
              <Typography className={classes.titleDesativado}>
                Não existe configurações de parâmetros para esse <br />
                veículo, ative para habilitar esse veículo nessa região.
              </Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default AtributosFreteTabeladoRegiao;
