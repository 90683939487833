import React from 'react';
import { useSelector } from 'react-redux';

import { makeStyles, Typography, useTheme } from '@material-ui/core';

import TextoValorSugeridoRobo from 'features/chatNegociacao/components/TextoValorSugeridoRobo';
import { RootState } from 'store/reducer';
import IconeRelogio from 'assets/icones/IconeRelogio';
import { Rubik } from 'shared/constants/font';
import spacingInPixels from 'shared/functions/materialUtils';
import TipoMensagemProposta from 'shared/constants/TipoMensagemProposta';
import { formatarNumeroParaStringMonetariaComSimbolo } from 'shared/functions/dinheiroUtils';
import { converterDataApiParaDataChat } from '../../utils/dataHora';
import ChatBalaoInformativo from '../../components/ChatBalaoInformativo';

type ChatInformativoSolicitacaoAprovacaoProps = {
  valor?: number;
  data: string;
  tipo: TipoMensagemProposta;
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: 360,
    padding: spacingInPixels(theme, 1),
  },
  textoAviso: {
    fontFamily: Rubik,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '17px',
    '& b': {
      fontWeight: 500,
      fontSize: '15px',
    },
  },
  textoPercentual: {
    '& b': {
      color: theme.palette.cores.vermelho[400],
    },
  },
  textoInformativoRobo: {
    paddingTop: '10px',
  },
}));

const ChatInformativoSolicitacaoAprovacao = ({
  valor = 0,
  data,
  tipo,
}: ChatInformativoSolicitacaoAprovacaoProps): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles();
  const { negociacao } = useSelector(
    (state: RootState) => state.informacoesNegociacaoReducer,
  );

  const chatInformativoValorSugestaoRobo =
    tipo === TipoMensagemProposta.AprovacaoConclusaoSupervisor &&
    negociacao?.sugestaoValorInicialRobo;

  const mensagemBalao = chatInformativoValorSugestaoRobo
    ? 'Você aceitou a proposta de'
    : 'Você pediu aprovação de';

  // eslint-disable-next-line no-nested-ternary
  const percentualValorFrotaPropria = negociacao?.sugestaoValorInicialRobo
    ? Math.round((valor * 100) / negociacao.sugestaoValorInicialRobo)
    : negociacao?.valorFrotaPropria
    ? Math.round((valor * 100) / negociacao.valorFrotaPropria)
    : 0;

  return (
    <ChatBalaoInformativo
      className={classes.container}
      icone={<IconeRelogio fill={theme.palette.cores.amarelo[900]} />}
    >
      <Typography className={classes.textoAviso}>
        {mensagemBalao}{' '}
        <b>{formatarNumeroParaStringMonetariaComSimbolo(valor)}</b>
      </Typography>
      <Typography
        className={[classes.textoAviso, classes.textoPercentual].join(' ')}
      >
        <b>{`[${percentualValorFrotaPropria}% do target]`}</b>
        {` às ${converterDataApiParaDataChat(data)}.`}
      </Typography>
      <Typography className={classes.textoAviso}>
        <b>Aguardando aprovação</b> do gestor
      </Typography>
      {chatInformativoValorSugestaoRobo && (
        <div className={classes.textoInformativoRobo}>
          <TextoValorSugeridoRobo
            sugestaoValorInicialRobo={negociacao?.sugestaoValorInicialRobo || 0}
            tamanhoFonte={10}
            alturaIcone={12}
            larguraIcone={12}
          />
        </div>
      )}
    </ChatBalaoInformativo>
  );
};

ChatInformativoSolicitacaoAprovacao.defaultProps = {
  valor: 0,
};

export default ChatInformativoSolicitacaoAprovacao;
