import React from 'react';
import { Box } from '@material-ui/core';
import IconeAtencaoTriangulo from 'assets/icones/IconeAtencaoTriangulo';
import {
  MENSAGEM_ERRO_LISTAGEM_DEMANDAS,
  MENSAGEM_LISTAGEM_DEMANDAS_VAZIA,
} from 'features/detalhesDemandasDiaria/constants/Constants';
import EmptyPage from '../emptyPage/EmptyPage';
import { useStyles } from './style/PaginaVaziaIndicadores';
import { PaginaVaziaIndicadoresProps } from './types/PaginaVaziaIndicadores';

const PaginaVaziaIndicadores = ({
  erro,
}: PaginaVaziaIndicadoresProps): JSX.Element => {
  const classes = useStyles();

  const icone = !erro ? (
    <IconeAtencaoTriangulo width={134} height={134} viewBox="4 -2 4 18" />
  ) : undefined;

  return (
    <Box className={classes.boxInformativo}>
      <EmptyPage
        texto={
          erro
            ? MENSAGEM_ERRO_LISTAGEM_DEMANDAS
            : MENSAGEM_LISTAGEM_DEMANDAS_VAZIA
        }
        iconePreenchido
        icone={icone}
        corFundo="none"
      />
    </Box>
  );
};

export default PaginaVaziaIndicadores;
