import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import PropostaStatus from '../../../../../shared/constants/PropostaStatus';

import { PropostaNegociacao } from '../../../api/informacoesNegociacaoApiTypes';

import { abrirModalRecusarProposta } from '../redux/motoristasCardAction';
import BotaoIcone from '../../../../../shared/components/button/BotaoIcone';
import useStyles from '../../../styles/botaoPropostaStyles';

type RecusarPropostaButtonProps = {
  proposta: PropostaNegociacao;
};

const RecusarPropostaButton = ({
  proposta,
}: RecusarPropostaButtonProps): JSX.Element => {
  const classes = useStyles({ aceitar: false });
  const dispatch = useDispatch();

  const [desabilitado, setDesabilitado] = useState(true);

  useEffect(() => {
    setDesabilitado(proposta.status === PropostaStatus.RECUSA_NEGOCIADOR);
  }, [proposta.status]);

  const abrirModal = () => {
    dispatch(abrirModalRecusarProposta(proposta));
  };

  return (
    <BotaoIcone
      descricao="Recusar proposta"
      onClick={abrirModal}
      desabilitado={desabilitado}
      tamanho="small"
      aria-label={`recusar-proposta-${proposta.id}`}
      classe={`${classes.botao} ${desabilitado ? classes.desabilitado : ''}`}
    >
      <CloseRoundedIcon className={classes.icone} />
    </BotaoIcone>
  );
};

export default RecusarPropostaButton;
