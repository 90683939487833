import React, { ReactNode } from 'react';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import VersaoSistema from './VersaoSistema';
import { spacingInPixels } from '../../../shared/functions/materialUtils';

const useStyles = makeStyles((theme) => ({
  tela: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'space-around',
    },
  },
  banner: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  formulario: {
    position: 'absolute',
    top: '18%',
    width: spacingInPixels(theme, 45),
    [theme.breakpoints.up('md')]: {
      left: '57%',
    },
  },
  fundo: {
    position: 'absolute',
    height: '92%',
  },
}));

type LoginBackgroundProps = {
  children: ReactNode;
};

const LoginBackground = (props: LoginBackgroundProps): JSX.Element => {
  const classes = useStyles();

  const { children } = props;

  return (
    <>
      <Box className={classes.tela}>
        <Box className={classes.banner}>
          <img
            alt="banner"
            className={classes.fundo}
            src="/images/login/banner.svg"
          />
        </Box>
        <Box className={classes.formulario}>{children}</Box>
      </Box>
      <VersaoSistema />
    </>
  );
};

export default LoginBackground;
