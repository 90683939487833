import { Theme, makeStyles } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacingInPixels(theme, 2),
    backgroundColor: theme.palette.cores.branco,
    border: '1px solid #C9CAD280',
    borderRadius: spacingInPixels(theme, 1),
    padding: `${spacingInPixels(theme, 1)} 0 0 0`,
  },
  estiloLinhaSkeleton: {
    marginBottom: spacingInPixels(theme, 1.5),
  },
}));
