import React from 'react';
import { useTheme, withStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import IconButton from '@material-ui/core/IconButton';

import LinkButton from '../../../shared/components/button/LinkButton';

import Popover from '../../../shared/components/popover/CustomScrollPopover';
import { spacingInPixels } from '../../../shared/functions/materialUtils';

import { Motorista } from '../api/negociacoesAutomatizadasApiTypes';
import DisablingTooltip from '../../../shared/components/tooltip/DisablingTooltip';

import AvatarMotoristaNegociacaoConcluida from './AvatarMotoristaNegociacaoConcluida';
import AvatarMotoristaBadge from './AvatarMotoristaBadge';

export type DropdownMotoristasEnvolvidosProps = {
  idNegociacao: number;
  motoristas: Motorista[];
  negociacaoConcluida: boolean;
};

const LinkButtonCell = withStyles((theme) => ({
  root: {
    fontSize: '12px',
    fontWeight: 500,
    padding: theme.spacing(0, 1, 0, 1),
    color: '#2B2C32',
    textDecoration: 'none',
  },
}))(LinkButton);

const IconButtonFechar = withStyles({
  root: {
    padding: '0px',
  },
})(IconButton);

const DropdownMotoristasEnvolvidos = ({
  motoristas,
  idNegociacao,
  negociacaoConcluida,
}: DropdownMotoristasEnvolvidosProps): JSX.Element => {
  const [
    anchorElement,
    setAnchorElement,
  ] = React.useState<HTMLButtonElement | null>(null);

  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  const open = Boolean(anchorElement);
  const popoverId = open ? `motoristas-envolvidos-${idNegociacao}` : undefined;

  return (
    <>
      <DisablingTooltip title="Ver agregados envolvidos">
        <LinkButtonCell aria-describedby={popoverId} onClick={handleClick}>
          {`+${motoristas.length}`}
        </LinkButtonCell>
      </DisablingTooltip>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorElement}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box padding={theme.spacing(1, 3, 3, 3)}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            marginRight={spacingInPixels(theme, -2)}
            marginBottom={spacingInPixels(theme, 1)}
          >
            <DisablingTooltip title="Fechar">
              <IconButtonFechar onClick={handleClose}>
                <ClearRoundedIcon
                  fontSize="small"
                  style={{
                    color: theme.palette.cores.cinza[500],
                  }}
                />
              </IconButtonFechar>
            </DisablingTooltip>
          </Box>
          <Box display="flex" alignItems="center">
            {motoristas.map((motorista) =>
              negociacaoConcluida ? (
                <AvatarMotoristaNegociacaoConcluida
                  key={motorista.id}
                  motorista={motorista}
                  idNegociacao={idNegociacao}
                />
              ) : (
                <AvatarMotoristaBadge
                  key={motorista.id}
                  motorista={motorista}
                  idNegociacao={idNegociacao}
                />
              ),
            )}
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default DropdownMotoristasEnvolvidos;
