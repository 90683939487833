import { makeStyles } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';

export const useStyles = makeStyles((theme) => ({
  menuLateral: {
    backgroundColor: theme.palette.cores.branco,
    borderRight: `1px solid ${theme.palette.cores.cinza[400]}`,
    display: 'flex',
    position: 'fixed',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    overflowX: 'hidden',
    marginTop: '5px',
    zIndex: 5,
    height: '100%',
    width: ({ hover, isOpen }: { hover: boolean; isOpen: boolean }) =>
      hover || isOpen ? spacingInPixels(theme, 40) : spacingInPixels(theme, 7),
    transition: 'width 0.2s ease',
  },
}));
