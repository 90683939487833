import React, { ReactElement } from 'react';
import CustomWidthTooltip from './CustomWidthTooltip';

export type DisablingTooltipProps = {
  title: string;
  children: ReactElement;
  disabled?: boolean;
  maxWidth?: string;
};

const DisablingTooltip = ({
  title,
  children,
  disabled = false,
  maxWidth = '300px',
}: DisablingTooltipProps): JSX.Element =>
  disabled ? (
    <>{children}</>
  ) : (
    <CustomWidthTooltip maxWidth={maxWidth} title={title}>
      {children}
    </CustomWidthTooltip>
  );

export default DisablingTooltip;
