import { ConexaoAPI } from '../../../shared/classes/conexaoAPI';
import { baixarArquivoRespostaEndpoint } from '../../../shared/functions/arquivoUtils';

export const getPlanilha = async (queryString: string): Promise<void> => {
  const URL_BUSCAR_ACOMPANHAMENTO_NEGOCIACOES = `${process.env.REACT_APP_URL_API_BASE}/negociacoes/planilhas/geral${queryString}`;
  const nomeArquivoPadrao = 'Arquivo.xlsx';

  const conexao = new ConexaoAPI(URL_BUSCAR_ACOMPANHAMENTO_NEGOCIACOES, 'get');
  conexao.adicionarResponseTypeArrayBuffer();
  const conectar = conexao.gerarFuncaoConectarRefreshToken();
  const resposta = await conectar();

  baixarArquivoRespostaEndpoint(resposta, nomeArquivoPadrao);
};

export default getPlanilha;
