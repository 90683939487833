import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from 'features/configuracoes/styles/percentualPropostaFilial';
import SliderEstilizavel from 'shared/components/slider/SliderEstilizavel';
import { ConfiguracoesPercentualPropostaProps } from 'features/configuracoes/types/percentualPropostaFilial';
import SwitchAcesso from 'shared/components/switch/SwitchAcesso';
import { sliderMarks } from '../../../constants/sliderMarks';

const ConfiguracoesPercentualProposta = ({
  filialAlvo,
  setFilialAlvo,
}: ConfiguracoesPercentualPropostaProps): JSX.Element => {
  const classes = useStyles({ sliderTamanho: false });
  const handleTrocaPermissaointegracaoRoboAtivo = () => {
    setFilialAlvo((anterior) => ({
      ...anterior,
      integracaoRoboAtivo: !anterior.integracaoRoboAtivo,
    }));
  };

  const handleTrocaPermmissaoPercentual = (novoValor: number) => {
    setFilialAlvo((anterior) => ({
      ...anterior,
      percentualFrotaPropria: novoValor,
    }));
  };

  return (
    <>
      <Box className={classes.boxFitContent}>
        <SwitchAcesso
          titulo="Deseja habilitar/desabilitar o ALGORITMO para essa filial?"
          permissaoAcesso={filialAlvo.integracaoRoboAtivo || false}
          setPermissaoAcesso={handleTrocaPermissaointegracaoRoboAtivo}
        />
      </Box>
      <Box>
        <Typography className={classes.infoCard}>
          Arraste o nível de permissão que deseja configurar
        </Typography>
        <Box className={classes.sliderBox}>
          <SliderEstilizavel
            valor={filialAlvo.percentualFrotaPropria || 0}
            sliderMarks={sliderMarks}
            classe={classes.sliderStyle}
            setValor={handleTrocaPermmissaoPercentual}
          />
        </Box>
      </Box>
    </>
  );
};

export default ConfiguracoesPercentualProposta;
