class OpcaoAba {
  id: number;

  label: string;

  activeIcon: JSX.Element;

  defaultIcon: JSX.Element;

  desabilitado: boolean;

  constructor(
    id: number,
    label: string,
    activeIcon: JSX.Element,
    dafaultIcon: JSX.Element,
    desabilitado: boolean,
  ) {
    this.id = id;
    this.label = label;
    this.activeIcon = activeIcon;
    this.defaultIcon = dafaultIcon;
    this.desabilitado = desabilitado;
  }
}

enum OpcaoAbaId {
  Negociadores = 1,
  Filial,
}

export { OpcaoAba, OpcaoAbaId };
