import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import StatusDemandaEnum from 'shared/constants/StatusDemandaEnum';
import { Demandas } from 'features/dashboards/constants/Types';
import spacingInPixels from 'shared/functions/materialUtils';
import {
  separarDemandasPorFilial,
  separarFiliaisDistintas,
} from 'features/dashboards/utils/modalDetalhes';
import {
  Demanda,
  DemandaNegociador,
} from 'features/dashboards/negociadores/api/listagemNegociadoresApiTypes';

import { RootState } from 'store/reducer';

import MetricasDemandas, { MetricasDemanda } from '../MetricasDemandas';
import MetricasNegociador from './MetricasNegociador';
import FiltrosDemandas from '../demandas/FiltrosDemandas';

import ContainerTabelaDemandasNegociador from './tabelaDemandasNegociador/ContainerTabelaDemandasNegociador';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: spacingInPixels(theme, 3),
  },
  filtros: {
    width: '100%',
    minHeight: spacingInPixels(theme, 3.5),
    '@media (max-height: 700px)': {
      position: 'sticky',
      top: 0,
    },
  },
  tabelaDemandas: {
    paddingTop: spacingInPixels(theme, 3),
  },
}));

interface ConteudoModalDemandasNegociadorProps {
  quantidadeNegociacoesPorHora: number | undefined;
  demandas: Demandas | undefined;
  filtroStatusDemanda: StatusDemandaEnum | null | undefined;
  setFiltroStatusDemanda: React.Dispatch<
    React.SetStateAction<StatusDemandaEnum | null | undefined>
  >;
  carregando: boolean;
  isAutomatizado: boolean;
}

const ConteudoModalDemandasNegociador = ({
  quantidadeNegociacoesPorHora,
  demandas,
  filtroStatusDemanda,
  setFiltroStatusDemanda,
  carregando,
  isAutomatizado,
}: ConteudoModalDemandasNegociadorProps): JSX.Element => {
  const classes = useStyles();

  const [filtroFilialSelecionado, setFiltroFilialSelecionado] = useState('');
  const [listaDeFiltrosPorFilial, setListaDeFiltrosPorFilial] = useState<
    string[]
  >([]);

  const { demandasNegociadorPorFilial, erro } = useSelector(
    (reducer: RootState) => reducer.listagemDemandasNegociadorReducer,
  );

  const demandasNoStatusSelecionado = useMemo(() => {
    const todasAsDemandas: Demanda[] =
      demandasNegociadorPorFilial?.entries()?.next()?.value?.[1] || [];
    return filtroStatusDemanda !== null
      ? todasAsDemandas.filter((d) => d.status === filtroStatusDemanda)
      : todasAsDemandas;
  }, [demandasNegociadorPorFilial, filtroStatusDemanda]);

  const filiaisComDemandasNoStatusSelecionado = useMemo(
    () =>
      separarFiliaisDistintas(
        demandasNoStatusSelecionado.map((d) => d.nomeFilial),
      ),
    [demandasNoStatusSelecionado],
  );

  const demandasFiltradasProStatus: Map<string, Demanda[]> = useMemo(() => {
    const demandasComFiltrosPorFilial: Map<
      string,
      Demanda[]
    > = separarDemandasPorFilial(
      filiaisComDemandasNoStatusSelecionado,
      demandasNoStatusSelecionado,
    );
    const filtrosPorFilial = Array.from(
      demandasComFiltrosPorFilial?.keys() || [],
    );
    setFiltroFilialSelecionado(filtrosPorFilial[0]);
    setListaDeFiltrosPorFilial(filtrosPorFilial);
    return demandasComFiltrosPorFilial;
  }, [demandasNoStatusSelecionado, filiaisComDemandasNoStatusSelecionado]);

  const demandasNoStatusFiltradasPorFilial = useMemo(
    () => demandasFiltradasProStatus.get(filtroFilialSelecionado),
    [demandasFiltradasProStatus, filtroFilialSelecionado],
  );

  const handleFiltroStatusDemanda = useCallback(
    (novoStatusFiltro: StatusDemandaEnum | null) => {
      if (novoStatusFiltro !== filtroStatusDemanda) {
        setFiltroFilialSelecionado(listaDeFiltrosPorFilial[0]);
        setFiltroStatusDemanda(novoStatusFiltro);
      }
    },
    [filtroStatusDemanda, listaDeFiltrosPorFilial, setFiltroStatusDemanda],
  );

  const dadosDemandas: MetricasDemanda[] = [
    { chave: 'DemandasDoDia', valor: demandas?.total },
    { chave: 'Negociadas', valor: demandas?.concluidas },
    { chave: 'EmNegociacao', valor: demandas?.emNegociacao },
    { chave: 'NaoIniciadas', valor: demandas?.naoIniciadas },
    { chave: 'Aprovacao', valor: demandas?.emAprovacao },
  ];

  const dadosDemandasAutomatizadas: MetricasDemanda[] = [
    { chave: 'EmNegociacao', valor: demandas?.emNegociacao },
    { chave: 'Negociadas', valor: demandas?.concluidas },
    { chave: 'Recusadas', valor: demandas?.recusadas },
    { chave: 'NaoIniciadas', valor: demandas?.naoIniciadas },
  ];

  return (
    <>
      <Grid container direction="column">
        {!isAutomatizado && (
          <Grid item>
            <MetricasNegociador
              quantidadeNegociacoesPorHora={quantidadeNegociacoesPorHora}
            />
          </Grid>
        )}
        <Grid item>
          <MetricasDemandas
            isAutomatizado={isAutomatizado}
            metricasDemandas={
              isAutomatizado ? dadosDemandasAutomatizadas : dadosDemandas
            }
            filtroStatus={filtroStatusDemanda}
            setFiltroStatus={handleFiltroStatusDemanda}
          />
        </Grid>
      </Grid>
      <Grid container direction="column" className={classes.container}>
        <Grid item className={classes.filtros}>
          <FiltrosDemandas
            carregando={carregando}
            filtroAtual={filtroFilialSelecionado}
            filtros={listaDeFiltrosPorFilial}
            onClickFiltro={(novoFiltroFilial) =>
              setFiltroFilialSelecionado(novoFiltroFilial)
            }
          />
        </Grid>
        <Grid item className={classes.tabelaDemandas}>
          <ContainerTabelaDemandasNegociador
            isAutomatizado={isAutomatizado}
            carregando={carregando}
            demandas={demandasNoStatusFiltradasPorFilial as DemandaNegociador[]}
            erro={erro}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ConteudoModalDemandasNegociador;
