import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { TextoRegrasProps } from '../types';
import { useStyles } from '../styles/TextoRegras';

const TextoRegras = ({ propsRegras }: TextoRegrasProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Box className={classes.gapBox}>
      <Typography variant="h4">{propsRegras?.titulo}</Typography>
      {Array.isArray(propsRegras.texto) ? (
        propsRegras.texto.map((texto) => (
          <Typography variant="h6" className={classes.editeTexto}>
            {texto}
          </Typography>
        ))
      ) : (
        <Typography variant="h6" className={classes.editeTexto}>
          <b>{propsRegras.textoTituloNegrio}</b>
          {propsRegras.texto}
        </Typography>
      )}

      {propsRegras.regras?.map((regra) => (
        <Box className={classes.conteudoExpandido}>
          <TextoRegras propsRegras={regra} />
        </Box>
      ))}
    </Box>
  );
};

export default TextoRegras;
