import { useTheme } from '@material-ui/core/styles';
import * as React from 'react';
import { SVGProps } from 'react';

const IconeMotorista = ({
  fill,
  width,
  height,
}: SVGProps<SVGSVGElement>): JSX.Element => {
  const theme = useTheme();

  return (
    <svg
      width={width || '28'}
      height={height || '28'}
      viewBox="0 0 28 28"
      fill={fill || theme.palette.cores.azulMartins[500]}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1797 0.0328106C11.8672 0.202342 10.6477 0.634373 9.64145 1.29062C7.73833 2.52656 6.48599 4.38594 6.03755 6.64453C5.90083 7.32812 5.90083 8.85937 6.03755 9.54297C6.4313 11.5227 7.54145 13.3383 9.04536 14.4812L9.41724 14.7656L9.26411 14.8203C7.3063 15.5367 5.66567 16.5812 4.16177 18.0633C1.64067 20.5516 0.21333 23.7398 0.0219238 27.3055L-0.0163574 28H1.08286H2.18755V27.732C2.18755 26.7805 2.42817 25.4406 2.79458 24.3141C3.96489 20.743 6.74849 17.9594 10.3305 16.7891C12.786 15.9852 15.2196 15.9852 17.686 16.7945C20.3547 17.6695 22.5696 19.425 24.0899 21.875C24.9758 23.3078 25.5829 25.1289 25.7579 26.9008C25.7852 27.2125 25.8125 27.5844 25.8125 27.732V28H26.9063H28V27.6828C28 26.2773 27.6063 24.3523 27.0047 22.8102C25.5829 19.1734 22.3891 16.1 18.6868 14.7984C18.5993 14.7711 18.643 14.7164 18.9274 14.5031C20.4368 13.3711 21.5688 11.5172 21.9625 9.54297C22.0993 8.85937 22.0993 7.32812 21.9625 6.64453C21.3008 3.325 18.8727 0.853123 15.5915 0.158592C15.0774 0.0546856 13.6336 -0.0218769 13.1797 0.0328106ZM15.4493 2.37344C16.718 2.70156 17.9211 3.49453 18.7086 4.53359C19.0915 5.03672 19.5508 5.99375 19.7258 6.64453C19.8516 7.13125 19.868 7.28984 19.868 8.09375C19.868 8.89765 19.8516 9.05625 19.7258 9.54297C19.5508 10.1937 19.0915 11.1508 18.7086 11.6539C17.9211 12.6875 16.7344 13.475 15.4493 13.8195C14.9625 13.9453 14.804 13.9617 14 13.9617C13.2016 13.9617 13.0375 13.9453 12.5618 13.8195C11.9547 13.6555 11.1618 13.3 10.6696 12.9609C10.2157 12.6602 9.63052 12.0969 9.29145 11.6539C8.90864 11.1508 8.44927 10.1937 8.27427 9.54297C8.14849 9.05625 8.13208 8.89765 8.13208 8.09375C8.13208 7.28984 8.14849 7.13125 8.27427 6.64453C8.44927 5.99375 8.90864 5.03672 9.29145 4.53359C10.2047 3.33047 11.6321 2.48281 13.1579 2.24219C13.7266 2.15469 14.8368 2.22031 15.4493 2.37344Z"
        fill={fill || theme.palette.cores.azulMartins[500]}
      />
      <path
        d="M13.1797 19.9391C11.8672 20.1086 10.6477 20.5406 9.64146 21.1969C7.45942 22.6133 6.10864 24.8937 5.92817 27.4805L5.88989 28H6.98364H8.07739L8.11567 27.5297C8.17036 26.8461 8.38364 26.0805 8.68989 25.457C9.13286 24.5547 8.93599 24.5 10.3907 25.9492C11.5008 27.0539 11.6485 27.2234 11.6266 27.3437C11.6102 27.4203 11.5774 27.5953 11.561 27.743L11.5227 28H14H16.4774L16.4446 27.7703C16.4227 27.6391 16.3954 27.4586 16.379 27.3711C16.3461 27.218 16.4172 27.1359 17.6094 25.9492C19.0696 24.4945 18.8727 24.5547 19.3157 25.468C19.6219 26.1023 19.8297 26.8516 19.8844 27.5297L19.9227 28H21.0165H22.1102L22.0719 27.4805C21.8149 23.8109 19.2118 20.8305 15.5915 20.0648C15.0774 19.9609 13.6336 19.8844 13.1797 19.9391ZM15.4493 22.2797C15.75 22.3562 16.2422 22.5422 16.543 22.6898C17.4454 23.1328 17.5 22.9359 16.0508 24.3906C14.9407 25.5062 14.7766 25.6484 14.6563 25.6211C14.3172 25.5445 13.7758 25.5281 13.5079 25.5937L13.2235 25.6648L11.9711 24.4125C11.2821 23.7234 10.7243 23.1383 10.7352 23.1055C10.7735 22.9961 11.8508 22.4766 12.3321 22.3398C13.043 22.1375 13.393 22.0992 14.1915 22.1211C14.7219 22.1375 15.0446 22.1758 15.4493 22.2797Z"
        fill={fill || theme.palette.cores.azulMartins[500]}
      />
    </svg>
  );
};

export default IconeMotorista;
