import { ChaveOrdenacaoDetalhesDemandas } from 'features/detalhesDemandasDiaria/constants/Constants';
import { DadoSkeleton } from 'shared/components/skeleton/CustomSkeleton';
import { DadosCabecalho } from 'shared/components/tabelaDemandas/types/TabelaTypes';

export const dadosSkeleton: DadoSkeleton[] = [
  { borderRadius: 4, width: '80%' },
  { borderRadius: 4, width: '90%' },
  { borderRadius: 4, width: '70%' },
  { borderRadius: 4, width: '80%' },
  { borderRadius: 4, width: '90%' },
  { borderRadius: 4, width: '90%' },
  { borderRadius: 4, width: '60%' },
];

export const dadosItensCabecalho: DadosCabecalho[] = [
  {
    conteudo: 'Filial',
    descricao: 'Filial',
    chave: ChaveOrdenacaoDetalhesDemandas.FILIAL,
    possuiOrdenacao: true,
  },
  {
    conteudo: 'Negociador',
    descricao: 'Negociador',
  },
  { conteudo: 'Romaneio', descricao: 'Romaneio' },
  { conteudo: 'Valor fechado', descricao: 'Valor fechado' },
  { conteudo: 'Cidade', descricao: 'Cidade' },
  {
    conteudo: 'Data/Hora',
    descricao: 'Data/Hora',
    chave: ChaveOrdenacaoDetalhesDemandas.DATA_HORA,
    possuiOrdenacao: true,
  },
  { conteudo: 'Tarifário', descricao: 'Tarifário' },
];
