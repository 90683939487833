import React, { useState } from 'react';

import { IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';

import TextField from './TextField';
import DisablingTooltip from '../tooltip/DisablingTooltip';

type PasswordInputProps = {
  erroSenha: boolean;
  descricaoErroSenha: string;
  validarCampoSenha: () => void;
  setSenha: (value: string) => void;
  maxLength?: number;
};

const PasswordInput = (props: PasswordInputProps): JSX.Element => {
  const theme = useTheme();
  const [mostraSenha, setMostraSenha] = useState(false);

  const {
    erroSenha,
    descricaoErroSenha,
    validarCampoSenha,
    setSenha,
    maxLength,
  } = props;

  return (
    <TextField
      id="Senha"
      label="Senha"
      type={mostraSenha ? 'text' : 'password'}
      autoComplete="current-password"
      variant="outlined"
      onBlur={validarCampoSenha}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        setSenha(event.target.value)
      }
      error={erroSenha}
      helperText={descricaoErroSenha}
      style={{ width: '100%' }}
      InputProps={{
        inputProps: { maxLength },
        endAdornment: (
          <InputAdornment position="end">
            <DisablingTooltip
              title={mostraSenha ? 'Ocultar senha' : 'Exibir senha'}
            >
              <IconButton
                aria-label="Toggle password visibility"
                onClick={() => setMostraSenha(!mostraSenha)}
                edge="end"
                style={
                  erroSenha
                    ? { color: theme.palette.error.main }
                    : { color: theme.palette.secondary.main }
                }
              >
                {mostraSenha ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </DisablingTooltip>
          </InputAdornment>
        ),
      }}
    />
  );
};

PasswordInput.defaultProps = {
  maxLength: 25,
};

export default PasswordInput;
