import {
  Aceitar,
  Contraproposta,
  ExibicaoRespostaNegociador,
  AguardandoAprovacaoSupervisor,
  Recusar,
} from '../redux/chatNegociacaoReducer';

export const verificarExibicaoBotoesResposta = (
  chatExibicaoRespostaNegociador: ExibicaoRespostaNegociador | undefined,
): boolean => chatExibicaoRespostaNegociador === undefined;

export const verificarExibicaoContraproposta = (
  chatExibicaoRespostaNegociador: ExibicaoRespostaNegociador | undefined,
): boolean => chatExibicaoRespostaNegociador === Contraproposta;

export const verificarExibicaoAceite = (
  chatExibicaoRespostaNegociador: ExibicaoRespostaNegociador | undefined,
): boolean => chatExibicaoRespostaNegociador === Aceitar;

export const verificarExibicaoRecusa = (
  chatExibicaoRespostaNegociador: ExibicaoRespostaNegociador | undefined,
): boolean => chatExibicaoRespostaNegociador === Recusar;

export const verificarExibicaoPedirAprovacao = (
  chatExibicaoRespostaNegociador: ExibicaoRespostaNegociador | undefined,
): boolean => chatExibicaoRespostaNegociador === AguardandoAprovacaoSupervisor;
