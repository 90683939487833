import { createAction } from '@reduxjs/toolkit';
import { OrigemCargaType } from '../../../shared/constants/OrigemCarga';
import PropostaStatus from '../../../shared/constants/PropostaStatus';
import { Mensagem } from '../api/chatNegociacaoApiTypes';
import TipoValorContraproposta from '../constants/TipoValorContraproposta';
import { ExibicaoRespostaNegociador } from './chatNegociacaoReducer';

export type InformacoesProposta = {
  chatIdProposta: number;
  chatIdNegociacao: number;
  chatRomaneio: string;
  chatNomeMotorista: string;
  chatListaClientesLiberada: boolean;
  chatOrigemCarga?: OrigemCargaType;
  chatPropostaStatus: PropostaStatus;
  chatMotivoRecusa?: number;
  chatValorFrotaPropria: number;
  chatValorFreteTerceiro: number;
  chatValorMecadoria: number;
  chatQuilometragem: number;
  chatEntregas: number;
  chatPeso: number;
  metaValorProposta?: number;
};

export type InformacoesMensagens = {
  chatIdProposta: number;
  chatIdNegociacao: number;
  mensagens: Mensagem[];
  chatMotivoRecusa?: number;
};

export type InformacoesMensagem = {
  chatIdProposta: number;
  chatIdNegociacao: number;
  mensagem: Mensagem;
  chatPropostaStatus: PropostaStatus;
  chatMotivoRecusa?: number;
};

export type ArquivoImagem = {
  id: string;
  arquivo: File;
};

export type InformacoesModalContraproposta = {
  justificativa: string;
  tipoValor: TipoValorContraproposta;
  valorProposto: number | undefined;
  valorInput: number | undefined;
};

export const abrirChat = createAction<InformacoesProposta>(
  'chatNegociacaoAction/ABRIR_CHAT',
);

export const fecharChat = createAction<void>(
  'chatNegociacaoAction/FECHAR_CHAT',
);

export const atualizarMensagens = createAction<InformacoesMensagens>(
  'chatNegociacaoAction/ATUALIZAR_MENSAGENS',
);

export const adicionarMensagem = createAction<InformacoesMensagem>(
  'chatNegociacaoAction/ADICONAR_MENSAGEM',
);

export const atualizarExibicaoRespostaNegociador = createAction<
  ExibicaoRespostaNegociador | undefined
>('chatNegociacaoAction/ATUALIZAR_EXIBICAO_RESPOSTA_NEGOCIADOR');

export const atualizarListaClientesLiberada = createAction<boolean>(
  'chatNegociacaoAction/ATUALIZAR_LISTA_CLIENTES_LIBERADA',
);

export const adicionarImagemChat = createAction<ArquivoImagem>(
  'chatNegociacaoAction/ADICIONAR_IMAGEM_CHAT',
);

export const informacoesModalContraproposta = createAction<InformacoesModalContraproposta>(
  'chatNegociacaoAction/INFORMACOES_MODAL_CONTRAPROPOSTA',
);
