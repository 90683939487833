import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';
import CustomSkeleton from 'shared/components/skeleton/CustomSkeleton';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '3% 25% 10% 16% 17% 18% 10%',
    paddingBottom: spacingInPixels(theme, 2.5),
  },
  negociador: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: spacingInPixels(theme, 0.5),
  },
  status: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const SkeletonConteudoTabelaDemandas = (): JSX.Element => {
  const classes = useStyles();

  return (
    <section>
      {new Array(15).fill(null)?.map((_, index) => (
        <section
          className={classes.container}
          key={`skeleton-demanda-${index + 1}`}
        >
          <div className={classes.status}>
            <CustomSkeleton borderradius={50} width={16} height={16} />
          </div>
          <div className={classes.negociador}>
            <CustomSkeleton borderradius={50} width={20} height={20} />
            <CustomSkeleton borderradius={4} width="80%" height={20} />
          </div>
          <div>
            <CustomSkeleton borderradius={4} width="70%" height={20} />
          </div>
          <div>
            <CustomSkeleton borderradius={4} width="80%" height={20} />
          </div>
          <div>
            <CustomSkeleton borderradius={4} width="90%" height={20} />
          </div>
          <div>
            <CustomSkeleton borderradius={4} width="90%" height={20} />
          </div>
          <div>
            <CustomSkeleton borderradius={4} width="90%" height={20} />
          </div>
        </section>
      ))}
    </section>
  );
};

export default SkeletonConteudoTabelaDemandas;
