import React from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import IconeAtencaoTriangulo from '../../../assets/icones/IconeAtencaoTriangulo';

const useStyles = makeStyles((theme: Theme) => ({
  root: { marginTop: '10px', paddingLeft: '22px' },
  alerta: {
    backgroundColor: theme.palette.cores.vermelho[100],
    color: theme.palette.cores.vermelho[500],
  },
}));

interface ChipAlertaDemandaProps {
  onClick: () => void;
  quantidade: number;
}

const ChipAlertaDemanda = ({
  onClick,
  quantidade,
}: ChipAlertaDemandaProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {quantidade > 0 && (
        <Chip
          onClick={onClick}
          icon={
            <IconeAtencaoTriangulo width={16} height={15} viewBox="0 0 10 12" />
          }
          label={`Alerta (${quantidade})`}
          className={classes.alerta}
        />
      )}
    </div>
  );
};

export default ChipAlertaDemanda;
