import { createReducer } from '@reduxjs/toolkit';

import {
  limparDados,
  atualizarDataFim,
  atualizarDataInicio,
} from './acompanhamentoNegociacoesAction';

import {
  validarDataInicio,
  validarDataFim,
} from '../functions/acompanhamentoNegociacoesUtils';

export type acompanhamentoNegociacoesState = {
  dataInicio: Date | null;
  dataFim: Date | null;
  dataInicioValida: boolean;
  dataFimValida: boolean;
};

export const estadoInicial: Readonly<acompanhamentoNegociacoesState> = {
  dataInicio: null,
  dataFim: null,
  dataInicioValida: true,
  dataFimValida: true,
};

const acompanhamentoNegociacoesReducer = createReducer(
  estadoInicial,
  (builder) => {
    builder
      .addCase(atualizarDataInicio, (state, action) => ({
        ...state,
        dataInicio: action.payload,
        dataInicioValida: validarDataInicio(action.payload, state.dataFim),
      }))
      .addCase(atualizarDataFim, (state, action) => ({
        ...state,
        dataFim: action.payload,
        dataFimValida: validarDataFim(state.dataInicio, action.payload),
      }))
      .addCase(limparDados, () => estadoInicial)
      .addDefaultCase((state) => state);
  },
);

export default acompanhamentoNegociacoesReducer;
