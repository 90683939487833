import React, { useMemo, useState } from 'react';

import { makeStyles } from '@material-ui/core';
import { DemandaNegociador } from 'features/dashboards/negociadores/api/listagemNegociadoresApiTypes';
import {
  ItemOrdenacaoFilial,
  TipoItemHeaderTabelaDemandasNegociador as TipoItemHeader,
} from 'features/dashboards/constants/ItensHeaderTabelaDemandasNegociador';
import { ordenarDemandasNegociador } from 'features/dashboards/utils/modalDetalhesNegociador';
import HeaderTabelaDemandasNegociador from './HeaderTabelaDemandasNegociador';
import ConteudoTabelaDemandasNegociador from './ConteudoTabelaDemandasNegociador';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.cores.branco,
    height: 'calc(90vh - 400px)',
  },
}));

export interface ContainerTabelaDemandasNegociadorProps {
  demandas?: DemandaNegociador[];
  isAutomatizado: boolean;
  carregando: boolean;
  erro: boolean;
}

const ContainerTabelaDemandasNegociador = ({
  demandas,
  carregando,
  erro,
  isAutomatizado,
}: ContainerTabelaDemandasNegociadorProps): JSX.Element => {
  const classes = useStyles();

  const [itemSelecionado, setItemSelecionado] = useState<TipoItemHeader>(
    ItemOrdenacaoFilial,
  );

  let demandasFiltradasOrdenadas = useMemo(
    () =>
      ordenarDemandasNegociador(
        itemSelecionado.titulo,
        demandas,
        itemSelecionado.crescente,
      ),
    [demandas, itemSelecionado],
  );

  if (isAutomatizado) {
    demandasFiltradasOrdenadas = demandasFiltradasOrdenadas?.map((item) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { ultimoValorProposta, ...novoItem } = item;
      return novoItem;
    });
  }

  return (
    <div className={classes.container}>
      <HeaderTabelaDemandasNegociador
        isAutomatizado={isAutomatizado}
        itemSelecionado={itemSelecionado}
        setItemSelecionado={setItemSelecionado}
      />
      <ConteudoTabelaDemandasNegociador
        isAutomatizado={isAutomatizado}
        demandas={demandasFiltradasOrdenadas}
        carregando={carregando}
        erro={erro}
      />
    </div>
  );
};

export default ContainerTabelaDemandasNegociador;
