import React, { useMemo, useCallback, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { DemandaAlerta } from 'features/demandaDiaria/api/demandaDiariaApiTypes';
import spacingInPixels from 'shared/functions/materialUtils';
import { NegociacaoTipoAlerta } from 'features/dashboards/negociadores/api/listagemNegociadoresApiTypes';
import {
  definirLabelTempoAlertaBaseadoNaCategoriaAtual,
  ordenarDemandasPorCabecalho,
} from 'features/demandaDiaria/utils/demandaDiariaAlertas';

import CabecalhoTabelaDemandas, {
  ChaveCabecalho,
} from './CabecalhoTabelaDemandas';
import ConteudoTabelaDemandas from './ConteudoTabelaDemandas';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacingInPixels(theme, 1.5),
  },
  cabecalho: {
    display: 'grid',
    gridTemplateColumns: '3% 25% 10% 16% 17% 18% 10%',
    paddingBottom: spacingInPixels(theme, 1),
    paddingRight: spacingInPixels(theme, 1),
  },
}));

export interface TabelaDemandasProps {
  demandas: DemandaAlerta[];
  carregando: boolean;
  categoriaAtual?: NegociacaoTipoAlerta;
}

const TabelaDemandas = ({
  demandas,
  carregando,
  categoriaAtual,
}: TabelaDemandasProps): JSX.Element => {
  const classes = useStyles();

  const [demandasOrdenadas, setDemandasOrdenadas] = useState(demandas);

  const setLabelColunaTempoAlertaBaseadoNaCategoriaAtual = useCallback(
    () => definirLabelTempoAlertaBaseadoNaCategoriaAtual(categoriaAtual),
    [categoriaAtual],
  );

  const handleClickOrdenacao = (
    chave: ChaveCabecalho,
    sentidoOrdenacaoCrescente: boolean,
  ) => {
    setDemandasOrdenadas(
      ordenarDemandasPorCabecalho(demandas, chave, sentidoOrdenacaoCrescente),
    );
  };

  useMemo(() => {
    setDemandasOrdenadas(demandas);
  }, [demandas]);

  const cabecalho = useMemo(
    () => ({
      status: {
        chave: ChaveCabecalho.status,
        label: '',
        possuiOrdenacao: false,
      },
      negociador: {
        chave: ChaveCabecalho.negociador,
        label: 'Negociador',
        possuiOrdenacao: false,
      },
      sla: {
        chave: ChaveCabecalho.sla,
        label: 'SLA',
        possuiOrdenacao: false,
      },
      tempoAlerta: {
        chave: ChaveCabecalho.tempoAlerta,
        label: setLabelColunaTempoAlertaBaseadoNaCategoriaAtual(),
        possuiOrdenacao: false,
      },
      filial: {
        chave: ChaveCabecalho.filial,
        label: 'Filial',
        possuiOrdenacao: true,
      },
      romaneio: {
        chave: ChaveCabecalho.romaneio,
        label: 'Romaneio',
        possuiOrdenacao: false,
      },
      cidade: {
        chave: ChaveCabecalho.cidade,
        label: 'Cidade',
        possuiOrdenacao: false,
      },
    }),
    [setLabelColunaTempoAlertaBaseadoNaCategoriaAtual],
  );

  return (
    <div className={classes.container}>
      <div className={classes.cabecalho}>
        {Object.values(cabecalho).map((item) => (
          <CabecalhoTabelaDemandas
            item={item}
            onClickOrdenacao={handleClickOrdenacao}
            categoriaAtual={categoriaAtual}
            key={item.chave}
          />
        ))}
      </div>
      <div>
        <ConteudoTabelaDemandas
          carregando={carregando}
          demandas={demandasOrdenadas}
          mensagemErro="Ops, não há demandas para esse tipo de alerta!"
          categoriaAtual={categoriaAtual}
        />
      </div>
    </div>
  );
};

export default TabelaDemandas;
