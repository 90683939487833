import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import MuiBadge from '@material-ui/core/Badge';
import NotificationsNoneRounded from '@material-ui/icons/NotificationsNoneRounded';

import BotaoIcone from '../../button/BotaoIcone';
import { Rubik } from '../../../constants/font';
import {
  abrirNotificacoes,
  fecharNotificacoes,
} from '../../../../features/notificacoes/redux/notificacoesAction';
import { RootState } from '../../../../store/reducer';
import { obterPaginaNotificacoes } from '../../../../features/notificacoes/redux/notificacoesOperation';

const Badge = withStyles((theme) => ({
  badge: {
    height: '15px',
    minWidth: '15px',
    padding: '0 4px',
    fontSize: '10px',
    fontFamily: Rubik,
    color: theme.palette.cores.branco,
    backgroundColor: theme.palette.cores.vermelho[600],
  },
  anchorOriginTopRightRectangle: {
    top: '4px',
    right: '6px',
  },
}))(MuiBadge);

const BotaoNotificacoes = (): JSX.Element => {
  const dispatch = useDispatch();
  const { quantidadeNotificacoesNaoLidas } = useSelector(
    (state: RootState) => state.notificacoesReducer,
  );

  return (
    <BotaoIcone
      descricao="Notificações"
      color="primary"
      onClick={() => {
        dispatch(fecharNotificacoes());
        obterPaginaNotificacoes(1)(dispatch);
        dispatch(abrirNotificacoes());
      }}
    >
      <Badge badgeContent={quantidadeNotificacoesNaoLidas}>
        <NotificationsNoneRounded
          color="primary"
          style={{ fontSize: '28px' }}
        />
      </Badge>
    </BotaoIcone>
  );
};

export default BotaoNotificacoes;
