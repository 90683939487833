import React from 'react';

import useScrollTrigger from '@material-ui/core/useScrollTrigger';

interface Props {
  children: React.ReactElement;
}

const ElevationScroll = ({ children }: Props): React.ReactElement => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });
  return React.cloneElement(children, {
    elevation: trigger ? 2 : 1,
  });
};

export default ElevationScroll;
