import React, { useState, useEffect, createRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import spacingInPixels from '../../../../../shared/functions/materialUtils';
import HeaderAccordion from '../../../../../shared/components/accordion/HeaderAccordion';
import Accordion from '../../../../../shared/components/accordion/Accordion';
import AccordionDetails from '../../../../../shared/components/accordion/AccordionDetails';

import { PropostaNegociacao } from '../../../api/informacoesNegociacaoApiTypes';

import PropostaDetails from './PropostaDetails';
import PropostaHeaderAccordion from './PropostaHeaderAccordion';
import { RootState } from '../../../../../store/reducer';
import { smoothScroll } from '../../../../../shared/functions/scrollUtils';
import { atualizarIdPropostaComFoco } from '../../../redux/informacoesNegociacaoAction';
import { validarPropostaFocada } from './utils/proposta';
import { fecharChat } from '../../../../chatNegociacao/redux/chatNegociacaoAction';

const useStyles = makeStyles((theme) => ({
  sequencia: {
    color: theme.palette.primary.main,
  },
  corIconeAccordion: {
    color: theme.palette.cores.cinza[500],
  },
  porcentagem: {
    marginLeft: spacingInPixels(theme, 2),
    fontSize: '14px',
    fontWeight: 500,
  },
  boxMotoristas: {
    display: 'flex',
    marginLeft: `-${spacingInPixels(theme, 5)}`,
  },
  header: {
    borderBottom: '0px',
  },
  linha: {
    borderBottom: `1px solid ${theme.palette.cores.cinza[500]}`,
    padding: theme.spacing(0, 2, 0, 6.5),
  },
  itemLista: {
    backgroundColor: ({ index }: { index: number }) =>
      index % 2 === 0 ? theme.palette.cores.branco : '#F5F5F5',
  },
  itemSelecionado: {
    backgroundColor: theme.palette.cores.azul[700],
  },
  accordion: {
    '&.MuiAccordion-root:before': {
      height: 0,
    },
  },
}));

type PropostaGridProps = {
  sequencia: number;
  proposta: PropostaNegociacao;
};

const PropostaGrid = ({
  sequencia,
  proposta,
}: PropostaGridProps): JSX.Element => {
  const { idPropostaComFoco } = useSelector(
    (state: RootState) => state.informacoesNegociacaoReducer,
  );

  const dispatch = useDispatch();

  const scrollRef = createRef<HTMLInputElement>();

  const [propostaComFoco, setPropostaComFoco] = useState(
    validarPropostaFocada(idPropostaComFoco, proposta.id),
  );

  useEffect(() => {
    if (validarPropostaFocada(idPropostaComFoco, proposta.id)) {
      smoothScroll(scrollRef.current);
    }
  }, [idPropostaComFoco, proposta.id, scrollRef]);

  useEffect(() => {
    if (!validarPropostaFocada(idPropostaComFoco, proposta.id))
      setPropostaComFoco(false);
  }, [proposta.id, idPropostaComFoco]);

  const classes = useStyles({ index: sequencia });

  const alterarEstadoAccordion = () => {
    dispatch(
      atualizarIdPropostaComFoco(propostaComFoco ? undefined : proposta.id),
    );
    dispatch(fecharChat());
    setPropostaComFoco((estadoAnterior) => !estadoAnterior);
  };

  const estilosHeaderAccordion = propostaComFoco ? classes.header : '';

  return (
    <Accordion
      expanded={propostaComFoco}
      onChange={alterarEstadoAccordion}
      ref={scrollRef}
      classes={{ root: classes.accordion }}
    >
      <HeaderAccordion
        expandIconColor={classes.corIconeAccordion}
        styles={`${estilosHeaderAccordion} ${
          propostaComFoco ? classes.itemSelecionado : classes.itemLista
        }`}
        exibirIconeExpansao={false}
      >
        <PropostaHeaderAccordion
          proposta={proposta}
          propostaSelecionada={propostaComFoco}
        />
      </HeaderAccordion>
      <AccordionDetails>
        <PropostaDetails proposta={proposta} />
      </AccordionDetails>
    </Accordion>
  );
};

export default PropostaGrid;
