import { makeStyles } from '@material-ui/core';
import React from 'react';
import spacingInPixels from '../../functions/materialUtils';

export type IconePreenchidoProps = {
  icone: React.ReactElement;
  cor?: string;
  className?: string;
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'unset',
    padding: spacingInPixels(theme, 0.5),
    borderRadius: spacingInPixels(theme, 0.5),

    background: ({ cor }: { cor?: string }) =>
      `white linear-gradient(${cor}20, ${cor}20)`,
  },
}));

const IconePreenchido = ({
  icone,
  cor,
  className,
}: IconePreenchidoProps): JSX.Element => {
  const classes = useStyles({ cor });

  return (
    <figure className={`${classes.container} ${className}`}>
      {React.cloneElement(icone, {
        fill: cor,
      })}
    </figure>
  );
};

export default IconePreenchido;
