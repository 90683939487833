import { Box, makeStyles, MenuItem } from '@material-ui/core';
import React from 'react';
import Select from '../../../../../../../shared/components/select/Select';
import TipoValorContrapropostaMapper from '../../../../../constants/TipoValorContrapropostaMapper';

const useStyles = makeStyles(() => ({
  container: {
    width: '48.5%',
  },
}));

export type SelectTipoValorContrapropostaProps = {
  value: number;
  disabled?: boolean;
  onChange(novoValor: number): void;
};

const SelectTipoValorContraproposta = ({
  value,
  disabled,
  onChange,
}: SelectTipoValorContrapropostaProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Select
        id="select-tipos-valor-contraproposta"
        label="Tipo de valor"
        size="small"
        value={value.toString()}
        onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
          onChange(Number(event.target.value))
        }
        disabled={disabled}
      >
        {TipoValorContrapropostaMapper.map((tipoValor) => (
          <MenuItem
            key={`tipo-valor-contraproposta-${tipoValor.id}`}
            value={tipoValor.id}
          >
            {tipoValor.descricao}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default SelectTipoValorContraproposta;
