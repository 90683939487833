import React from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';
import EmptyPage from 'shared/components/emptyPage/EmptyPage';
import IconeCirculo from 'assets/icones/IconeCirculo';
import IconeAvatar from 'assets/icones/IconeAvatar';
import { formatarDataBrasileira } from 'shared/functions/dataUtils';
import { DemandaAlerta } from 'features/demandaDiaria/api/demandaDiariaApiTypes';
import {
  NegociacaoTipoAlerta,
  PrioridadeCargaNegociacao,
} from 'features/dashboards/negociadores/api/listagemNegociadoresApiTypes';
import { definirValorTempoAlerta } from 'features/demandaDiaria/utils/demandaDiariaAlertas';
import ColunaIntervaloTempo from 'features/demandaDiaria/components/ColunaIntervaloTempo';
import SkeletonConteudoTabelaDemandas from './SkeletonConteudoTabelaDemandas';
import { CustomWarningTooltip } from '../QuadroBigNumbersAlerta';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxHeight: 'calc(100vh - 324px)',
    overflow: 'auto',
    paddingRight: spacingInPixels(theme, 1),
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      background: `${theme.palette.cores.cinza[500]}60`,
      borderRadius: '8px',
    },
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '3% 25% 10% 16% 17% 18% 10%',
    paddingBottom: spacingInPixels(theme, 2.5),
  },
  texto: {
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: spacingInPixels(theme, 2),
    lineHeight: spacingInPixels(theme, 2.5),
    color: theme.palette.cores.cinza[600],
    letterSpacing: '0.4px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '20ch',
  },
  negociador: {
    display: 'flex',
    alignItems: 'center',
    gap: spacingInPixels(theme, 0.5),
    overflow: 'hidden',
    '& > span': {
      color: theme.palette.cores.cinza[700],
      fontWeight: 700,
    },
  },
  status: {
    display: 'inline-block',
    '& > svg': {
      verticalAlign: 'middle',
    },
  },
  romaneio: {
    paddingRight: 0,
  },
}));

export interface ConteudoTabelaDemandasProps {
  carregando: boolean;
  demandas: DemandaAlerta[];
  mensagemErro: string;
  categoriaAtual?: NegociacaoTipoAlerta;
}

const ConteudoTabelaDemandas = ({
  carregando,
  demandas,
  mensagemErro,
  categoriaAtual,
}: ConteudoTabelaDemandasProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();

  const handleCorStatusDemanda = (
    prioridadeCarga: PrioridadeCargaNegociacao,
  ) => {
    switch (prioridadeCarga) {
      case PrioridadeCargaNegociacao.baixa:
        return theme.palette.cores.verde[500];
      case PrioridadeCargaNegociacao.media:
        return theme.palette.cores.mostarda[800];
      case PrioridadeCargaNegociacao.maxima:
      default:
        return theme.palette.cores.vermelho[450];
    }
  };

  if (carregando) return <SkeletonConteudoTabelaDemandas />;

  if (!carregando && demandas?.length === 0)
    return <EmptyPage texto={mensagemErro} />;

  return (
    <div className={classes.root}>
      {demandas?.map(
        ({
          dataCarregamento,
          nomeCidade,
          nomeFilial,
          nomeNegociador,
          romaneio,
          alertas,
          prioridadeCarga,
        }) => {
          const tempoAlerta = definirValorTempoAlerta(alertas, categoriaAtual);
          const dataFormatada = formatarDataBrasileira(
            new Date(dataCarregamento),
          );
          return (
            <section className={classes.container} key={`demanda-${romaneio}`}>
              <div className={classes.status}>
                <IconeCirculo
                  viewBox="0 0 20 17"
                  fill={handleCorStatusDemanda(prioridadeCarga)}
                />
              </div>
              <CustomWarningTooltip title={nomeNegociador} isTopPlacement>
                <div className={classes.negociador}>
                  <IconeAvatar
                    width={20}
                    height={20}
                    fill={theme.palette.cores.cinza[600]}
                  />
                  <span className={classes.texto}>{nomeNegociador}</span>
                </div>
              </CustomWarningTooltip>
              <CustomWarningTooltip title={dataFormatada} isTopPlacement>
                <div className={classes.texto}>{dataFormatada}</div>
              </CustomWarningTooltip>
              <div className={classes.texto}>
                <ColunaIntervaloTempo tempoAlerta={tempoAlerta} />
              </div>
              <CustomWarningTooltip title={nomeFilial} isTopPlacement>
                <div className={classes.texto}>{nomeFilial}</div>
              </CustomWarningTooltip>
              <CustomWarningTooltip title={romaneio} isTopPlacement>
                <div className={`${classes.texto} ${classes.romaneio}`}>
                  {romaneio}
                </div>
              </CustomWarningTooltip>
              <CustomWarningTooltip title={nomeCidade} isTopPlacement>
                <div className={classes.texto}>{nomeCidade}</div>
              </CustomWarningTooltip>
            </section>
          );
        },
      )}
    </div>
  );
};

export default ConteudoTabelaDemandas;
