import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import spacingInPixels from '../../functions/materialUtils';
import IconePreenchido from '../icons/IconePreenchido';

export interface ConteudoBigNumberProps {
  icone: React.ReactElement;
  cor?: string;
  numero?: number;
  descricao: string;
  selecionado?: boolean;
  onClick?: () => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacingInPixels(theme, 0.5),
  },
  containerIconeNumero: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
    gridTemplateRows: 'auto',
    gridColumnGap: spacingInPixels(theme, 1),
    placeItems: 'center',
    maxWidth: 'fit-content',
  },
  numero: {
    fontWeight: 500,
    fontSize: 16,
    color: theme.palette.cores.cinza[800],
    gridColumnStart: 2,
    justifySelf: 'start',
  },
  descricao: {
    fontWeight: 400,
    fontSize: 12,
    color: theme.palette.cores.cinza[700],
    letterSpacing: '0.4px',
    gridColumnStart: 2,
    textAlign: 'left',
    justifySelf: 'start',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
  },
}));

const ConteudoBigNumber = ({
  icone,
  cor,
  numero,
  descricao,
  onClick,
}: ConteudoBigNumberProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.containerIconeNumero} onClick={() => onClick?.()}>
        <IconePreenchido icone={icone} cor={cor} />
        <Typography className={classes.numero}>
          {numero?.toLocaleString() || '--'}
        </Typography>
      </Box>
      <Typography className={classes.descricao}>{descricao}</Typography>
    </Box>
  );
};

export default ConteudoBigNumber;
