import { makeStyles } from '@material-ui/core';
import React from 'react';
import CustomSkeleton from 'shared/components/skeleton/CustomSkeleton';
import spacingInPixels from 'shared/functions/materialUtils';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: spacingInPixels(theme, 1.5),
  },
  porcentagemProposta: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '60% 40%',
  },
  badgePorcentagemProposta: {
    display: 'flex',
    justifyContent: 'space-around',
    gap: spacingInPixels(theme, 0.5),
  },
  badgeDadosProposta: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    gap: spacingInPixels(theme, 1),
  },
}));

const SkeletonDetalhesProposta = (): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.porcentagemProposta}>
        <CustomSkeleton
          width="70%"
          height={28}
          borderradius={16}
          key="skeleton-valor-contra-proposta"
        />
        <div className={classes.badgePorcentagemProposta}>
          {new Array(2).fill(null).map((_, index) => (
            <CustomSkeleton
              width="50%"
              height={28}
              borderradius={16}
              key={`skeleton-porcentagem-contra-proposta-${index + 1}`}
            />
          ))}
        </div>
      </div>
      <div className={classes.badgeDadosProposta}>
        {new Array(5).fill(null).map((_, index) => (
          <CustomSkeleton
            width="30%"
            height={32}
            borderradius={16}
            key={`skeleton-dados-contra-proposta-${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
};

export default SkeletonDetalhesProposta;
