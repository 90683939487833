import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { PropostaNegociacao } from '../../../api/informacoesNegociacaoApiTypes';

import Texto from '../listaDisponiveis/TextoItemLista';
import PropostaInformacoes from './PropostaInformacoes';
import {
  caminhoIconeWhatsappAzul,
  caminhoIconeWhatsappBranco,
} from '../../../../../shared/constants/CaminhoRecursos';
import spacingInPixels from '../../../../../shared/functions/materialUtils';
import BoxStatusProposta from './BoxStatusProposta';

type PropostaHeaderAccordionProps = {
  proposta: PropostaNegociacao;
  propostaSelecionada: boolean;
};

const useStyles = makeStyles((theme) => ({
  icone: {
    color: ({ selecionado }: { selecionado: boolean }) =>
      selecionado
        ? theme.palette.cores.branco
        : theme.palette.cores.azulMartins[500],
    transform: ({ selecionado }: { selecionado: boolean }) =>
      `rotate(${selecionado ? 180 : 0}deg)`,
    transition: 'transform 0.25s linear',
  },
  containerNome: {
    marginBottom: spacingInPixels(theme, 1.3),
  },
}));

const PropostaHeaderAccordion = ({
  proposta,
  propostaSelecionada,
}: PropostaHeaderAccordionProps): JSX.Element => {
  const classes = useStyles({ selecionado: propostaSelecionada });
  const theme = useTheme();
  return (
    <Grid container justify="space-evenly">
      <Box display="flex" width="100%">
        <Grid container>
          <Grid item xs={9}>
            <Box
              display="flex"
              alignItems="center"
              className={classes.containerNome}
            >
              <img
                src={
                  propostaSelecionada
                    ? caminhoIconeWhatsappBranco
                    : caminhoIconeWhatsappAzul
                }
                alt="Icone whatsapp"
              />
              <Texto
                cor={
                  propostaSelecionada
                    ? theme.palette.cores.branco
                    : theme.palette.cores.azulMartins[500]
                }
              >
                {proposta.motorista.toLowerCase()}
              </Texto>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <BoxStatusProposta codigoStatus={proposta.status} />
          </Grid>
          <Grid item xs={1}>
            <Box width="100%" display="flex" justifyContent="flex-end">
              <ExpandMoreIcon className={classes.icone} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box width="100%">
        <PropostaInformacoes
          proposta={proposta}
          propostaSelecionada={propostaSelecionada}
        />
      </Box>
    </Grid>
  );
};

export default PropostaHeaderAccordion;
