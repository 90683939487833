import { makeStyles, Theme } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '15% 83%',
    textAlign: 'left',
    gap: spacingInPixels(theme, 0.5),
    borderRadius: spacingInPixels(theme, 0.5),
    maxWidth: spacingInPixels(theme, 12.5),
    padding: spacingInPixels(theme, 0.5),
    alignItems: 'center',
  },
  fonte: {
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: spacingInPixels(theme, 1.5),
    lineHeight: spacingInPixels(theme, 2),
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  semStatus: {
    padding: spacingInPixels(theme, 3),
  },
}));
