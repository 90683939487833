import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Theme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import StatusDemandaEnum from 'shared/constants/StatusDemandaEnum';
import AvatarComStatusConexao from '../../negociadores/components/AvatarComStatusConexao';
import DadosNegociador from '../../negociadores/components/DadosNegociador';
import { DemandaColaborador } from '../../negociadores/api/listagemNegociadoresApiTypes';
import SwitchModalDetalhes from './SwitchModalDetalhes';
import {
  AbaSelecionada,
  buscarDemandasNegociador,
} from '../../utils/modalDetalhes';
import spacingInPixels from '../../../../shared/functions/materialUtils';
import ContainerListagemNotificacoes from './notificacoes/ContainerListagemNotificacoes';
import ConteudoModalDemandasNegociador from './negociador/ConteudoModalDemandasNegociador';

export type ModalDetalhesProps = {
  isAutomatizado: boolean;
  negociador: DemandaColaborador | null;
  filtroStatus?: StatusDemandaEnum | null;
  setFiltroStatus: React.Dispatch<
    React.SetStateAction<StatusDemandaEnum | null | undefined>
  >;
  fecharModal: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    minWidth: 1270,
    height: '90vh',
    display: 'flex',
    flex: 1,
    '& div.MuiDialogTitle-root': {
      paddingTop: spacingInPixels(theme, 4.5),
    },
    '@media (max-width: 1270px)': {
      minWidth: '95vw',
    },
    '@media (max-height: 700px)': {
      minHeight: '80vh',
    },
    overflowY: 'clip',
  },
  header: {
    paddingLeft: spacingInPixels(theme, 4),
  },
  botaoFechar: {
    position: 'absolute',
    right: spacingInPixels(theme, 4),
    top: spacingInPixels(theme, 4.5),
    color: theme.palette.grey[500],
    padding: spacingInPixels(theme, 0.5),
  },
  secaoAvatarNome: {
    display: 'flex',
  },
  dadosNegociador: {
    padding: `${spacingInPixels(theme, 1.25)} ${spacingInPixels(theme, 3)}`,
    '& :first-child': {
      fontFamily: 'Rubik',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: spacingInPixels(theme, 2.5),
      lineHeight: spacingInPixels(theme, 2.7),
      color: theme.palette.cores.cinza[600],
      maxWidth: '270px',
      textOverflow: 'ellipsis',
    },
  },
}));

const ModalDetalhes = ({
  negociador,
  filtroStatus,
  setFiltroStatus,
  fecharModal,
  isAutomatizado,
}: ModalDetalhesProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [carregando, setCarregando] = useState(false);
  const conteudoExibido = useMemo(
    () =>
      filtroStatus === undefined
        ? AbaSelecionada.Notificacoes
        : AbaSelecionada.Negociador,
    [filtroStatus],
  );
  const alternarAba = useCallback(
    (aba: AbaSelecionada) => {
      if (aba !== conteudoExibido)
        setFiltroStatus(aba === AbaSelecionada.Notificacoes ? undefined : null);
    },
    [conteudoExibido, setFiltroStatus],
  );

  useEffect(() => {
    buscarDemandasNegociador(dispatch, setCarregando, negociador?.idNegociador);
  }, [dispatch, negociador?.idNegociador]);

  return (
    <Dialog
      open={!!negociador}
      onClose={fecharModal}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle disableTypography>
        <header className={classes.header}>
          <Grid container>
            <Grid item md={4} lg={4}>
              {negociador && (
                <section className={classes.secaoAvatarNome}>
                  <AvatarComStatusConexao
                    isAutomatizado={isAutomatizado}
                    isOnline={Boolean(negociador?.dataHoraDesdePrimeiroAcesso)}
                    tamanhoIcone={40}
                  />
                  <div className={classes.dadosNegociador}>
                    <DadosNegociador
                      nome={
                        isAutomatizado
                          ? 'Automatizado'
                          : negociador?.nomeNegociador
                      }
                      tempoConexao={negociador?.dataHoraDesdePrimeiroAcesso}
                    />
                  </div>
                </section>
              )}
            </Grid>
            {!isAutomatizado && (
              <Grid item md={8} lg={8}>
                <SwitchModalDetalhes
                  abaSelecionada={conteudoExibido}
                  onClickOpcaoSwitch={alternarAba}
                  quantidadeNotificacoes={negociador?.quantidadeAlertasDemanda}
                />
              </Grid>
            )}
          </Grid>
          <IconButton
            aria-label="fechar modal"
            className={classes.botaoFechar}
            onClick={fecharModal}
          >
            <CloseIcon />
          </IconButton>
        </header>
      </DialogTitle>
      <DialogContent>
        {conteudoExibido === AbaSelecionada.Negociador ? (
          <ConteudoModalDemandasNegociador
            isAutomatizado={isAutomatizado}
            quantidadeNegociacoesPorHora={
              negociador?.negociacoesConcluidasPorHora
            }
            demandas={negociador?.demandas}
            filtroStatusDemanda={filtroStatus}
            setFiltroStatusDemanda={setFiltroStatus}
            carregando={carregando}
          />
        ) : (
          <ContainerListagemNotificacoes carregando={carregando} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ModalDetalhes;
