import React from 'react';

import { Box, makeStyles } from '@material-ui/core';

import { NotificacaoType } from '../../types/notificacoes';

import NotificacaoConteudo from './NotificacaoConteudo';

const useStyles = makeStyles((theme) => ({
  notificacaoNaoVisualizada: {
    width: '100%',
    backgroundColor: theme.palette.cores.cinza[300],
    borderLeft: `3px solid ${theme.palette.cores.azulMartins[400]}`,
    borderBottom: `1px solid ${theme.palette.cores.cinza[400]}`,
    '&:hover': {
      backgroundColor: theme.palette.cores.cinza[400],
      borderLeft: `3px solid ${theme.palette.cores.azulMartins[500]}`,
    },
  },
  notificacaoVisualizada: {
    width: '100%',
    paddingLeft: '3px',
    backgroundColor: theme.palette.cores.branco,
    borderBottom: `1px solid ${theme.palette.cores.cinza[400]}`,
    '&:hover': {
      backgroundColor: theme.palette.cores.cinza[100],
    },
  },
  notificacaoNaoVisualizadaPlanejador: {
    width: '100%',
    backgroundColor: theme.palette.cores.cinza[300],
    borderLeft: `3px solid ${theme.palette.cores.verde[800]}`,
    borderBottom: `1px solid ${theme.palette.cores.cinza[400]}`,
    '&:hover': {
      backgroundColor: theme.palette.cores.cinza[400],
      borderLeft: `3px solid ${theme.palette.cores.verde[700]}`,
    },
  },
}));

export type NotificacaoProps = {
  notificacao: NotificacaoType;
};

const Notificacao = ({ notificacao }: NotificacaoProps): JSX.Element => {
  const classes = useStyles();

  if (notificacao.complemento) {
    return (
      <Box
        className={
          notificacao.visualizada
            ? classes.notificacaoVisualizada
            : classes.notificacaoNaoVisualizadaPlanejador
        }
      >
        <NotificacaoConteudo notificacao={notificacao} />
      </Box>
    );
  }

  return (
    <Box
      className={
        notificacao.visualizada
          ? classes.notificacaoVisualizada
          : classes.notificacaoNaoVisualizada
      }
    >
      <NotificacaoConteudo notificacao={notificacao} />
    </Box>
  );
};

export default Notificacao;
