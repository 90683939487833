import React from 'react';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackRounded from '@material-ui/icons/ArrowBackRounded';

import { spacingInPixels } from '../../functions/materialUtils';
import BoxLighterBlue from '../box/BoxLighterBlue';
import DisablingTooltip from '../tooltip/DisablingTooltip';

type TituloProps = {
  titulo: string;
  quantidade?: number;
  possuiBotaoVoltar?: boolean;
  voltar?: () => void;
};

const useStyles = makeStyles((theme) => ({
  icone: {
    padding: spacingInPixels(theme, 0.5),
    margin: theme.spacing(0, 1, 0, -0.5),
  },
}));

const Titulo = ({
  titulo,
  quantidade,
  possuiBotaoVoltar,
  voltar,
}: TituloProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box
      display="flex"
      alignItems="center"
      marginBottom={spacingInPixels(theme, 2)}
    >
      {possuiBotaoVoltar && (
        <DisablingTooltip title="Voltar">
          <IconButton
            aria-label="Voltar"
            onClick={voltar}
            className={classes.icone}
          >
            <ArrowBackRounded color="primary" />
          </IconButton>
        </DisablingTooltip>
      )}
      <Typography variant="h4" color="primary">
        {titulo}
      </Typography>
      {quantidade !== undefined && (
        <BoxLighterBlue>
          <Typography component="span" color="primary">
            {quantidade}
          </Typography>
        </BoxLighterBlue>
      )}
    </Box>
  );
};

Titulo.defaultProps = {
  voltar: () => null,
  possuiBotaoVoltar: false,
  quantidade: undefined,
};

export default Titulo;
