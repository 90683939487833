import { useTheme } from '@material-ui/core/styles';
import * as React from 'react';
import { SVGProps } from 'react';

const IconeReprovar = ({
  fill,
  ...outros
}: SVGProps<SVGSVGElement>): JSX.Element => {
  const theme = useTheme();

  return (
    <svg width="16" height="14" viewBox="0 0 20 20" fill="none" {...outros}>
      <path
        d="M13.71 6.29C13.617 6.19627 13.5064 6.12188 13.3846 6.07111C13.2627 6.02034 13.132 5.9942 13 5.9942C12.868 5.9942 12.7373 6.02034 12.6154 6.07111C12.4936 6.12188 12.383 6.19627 12.29 6.29L10 8.59L7.71 6.29C7.5217 6.1017 7.2663 5.99591 7 5.99591C6.7337 5.99591 6.4783 6.1017 6.29 6.29C6.1017 6.4783 5.99591 6.7337 5.99591 7C5.99591 7.2663 6.1017 7.5217 6.29 7.71L8.59 10L6.29 12.29C6.19627 12.383 6.12188 12.4936 6.07111 12.6154C6.02034 12.7373 5.9942 12.868 5.9942 13C5.9942 13.132 6.02034 13.2627 6.07111 13.3846C6.12188 13.5064 6.19627 13.617 6.29 13.71C6.38296 13.8037 6.49356 13.8781 6.61542 13.9289C6.73728 13.9797 6.86799 14.0058 7 14.0058C7.13201 14.0058 7.26272 13.9797 7.38458 13.9289C7.50644 13.8781 7.61704 13.8037 7.71 13.71L10 11.41L12.29 13.71C12.383 13.8037 12.4936 13.8781 12.6154 13.9289C12.7373 13.9797 12.868 14.0058 13 14.0058C13.132 14.0058 13.2627 13.9797 13.3846 13.9289C13.5064 13.8781 13.617 13.8037 13.71 13.71C13.8037 13.617 13.8781 13.5064 13.9289 13.3846C13.9797 13.2627 14.0058 13.132 14.0058 13C14.0058 12.868 13.9797 12.7373 13.9289 12.6154C13.8781 12.4936 13.8037 12.383 13.71 12.29L11.41 10L13.71 7.71C13.8037 7.61704 13.8781 7.50644 13.9289 7.38458C13.9797 7.26272 14.0058 7.13201 14.0058 7C14.0058 6.86799 13.9797 6.73728 13.9289 6.61542C13.8781 6.49356 13.8037 6.38296 13.71 6.29ZM17 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H17C17.7956 20 18.5587 19.6839 19.1213 19.1213C19.6839 18.5587 20 17.7956 20 17V3C20 2.20435 19.6839 1.44129 19.1213 0.87868C18.5587 0.316071 17.7956 0 17 0ZM18 17C18 17.2652 17.8946 17.5196 17.7071 17.7071C17.5196 17.8946 17.2652 18 17 18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V17Z"
        fill={fill || theme.palette.cores.azulMartins[500]}
      />
    </svg>
  );
};

export default IconeReprovar;
