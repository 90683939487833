import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLoading } from 'shared/customHooks/useLoading';
import { IndicadoresAlertaResponse } from 'features/indicadores/api/indicadoresApiTypes';
import { PeriodoFiltroIndicadores } from 'features/indicadores/constants/Constants';
import PainelPadrao from 'features/indicadores/components/PainelPadrao';
import ConteudoAlerta from 'features/indicadores/views/conteudoGeral/alerta/ConteudoAlerta';
import { buscarDadosIndicadoresAlerta } from 'features/indicadores/utils/alerta';

const ContainerAlerta = (): JSX.Element => {
  const dispatch = useDispatch();
  const [
    indicadoresAlerta,
    setIndicadoresAlerta,
  ] = useState<IndicadoresAlertaResponse>({
    alertaCarga: [],
    alertaAprovacao: [],
    alertaVisualizacao: [],
    limiteAlertaVisualizacaoNegociacao: {
      dias: 0,
      horas: 0,
      minutos: 0,
      segundos: 0,
    },
    limiteAlertaSemAprovacao: {
      dias: 0,
      horas: 0,
      minutos: 0,
      segundos: 0,
    },
  });
  const [erro, setErro] = useState(false);
  const [periodoAtual, setPeriodoAtual] = useState(
    PeriodoFiltroIndicadores.WEEK,
  );

  const buscaIndicadoresAlerta = useCallback(
    async () =>
      buscarDadosIndicadoresAlerta(setIndicadoresAlerta, setErro, periodoAtual),
    [periodoAtual],
  );

  const [carregando, buscarIndicadoresAlerta] = useLoading(
    buscaIndicadoresAlerta,
  );

  useEffect(() => {
    buscarIndicadoresAlerta();
  }, [dispatch, buscarIndicadoresAlerta]);

  return (
    <PainelPadrao
      titulo="Alertas de Carga"
      onChangePeriodo={(periodo: string | number) =>
        setPeriodoAtual(periodo as PeriodoFiltroIndicadores)
      }
    >
      <ConteudoAlerta
        erro={erro}
        onClickTentarNovamente={buscarIndicadoresAlerta}
        indicadoresAlerta={indicadoresAlerta}
        carregando={carregando}
      />
    </PainelPadrao>
  );
};

export default ContainerAlerta;
