import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';

import HeaderAccordion from 'shared/components/accordion/HeaderAccordion';
import LabelHeaderAccordion from 'shared/components/accordion/LabelHeaderAccordion';
import formatarZerosIniciais from 'shared/functions/numberUtils';

type LocalidadesHeaderAccordionProps = {
  entregas: number;
};

const useStyles = makeStyles((theme) => ({
  headerAccordion: {
    maxHeight: '32px',
    borderBottom: `1px solid ${theme.palette.cores.cinza[500]}`,
  },
}));

const LocalidadesHeaderAccordion = ({
  entregas,
}: LocalidadesHeaderAccordionProps): JSX.Element => {
  const classes = useStyles();
  const estilo = {
    fontWeight: 600,
  };

  return (
    <HeaderAccordion styles={classes.headerAccordion}>
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <LabelHeaderAccordion
            titulo={formatarZerosIniciais(entregas, 2)}
            estilo={estilo}
          />
        </Grid>
        <Grid item xs={3}>
          <LabelHeaderAccordion
            icone="/images/detalhesCarga/icone-destino.svg"
            titulo="Localização"
          />
        </Grid>
        <Grid item xs={3}>
          <LabelHeaderAccordion
            icone="/images/detalhesCarga/icone-cliente.svg"
            titulo="Cliente"
          />
        </Grid>
        <Grid item xs={2}>
          <LabelHeaderAccordion
            icone="/images/detalhesCarga/icone-peso.svg"
            titulo="Peso (kg)"
          />
        </Grid>
        <Grid item xs={2}>
          <LabelHeaderAccordion
            icone="/images/detalhesCarga/icone-volume.svg"
            titulo="Volume (m³)"
          />
        </Grid>
      </Grid>
    </HeaderAccordion>
  );
};

export default LocalidadesHeaderAccordion;
