import { createAction } from '@reduxjs/toolkit';
import { SnackbarControle } from './snackbarReducer';

export const abrirSnackbarSucesso = createAction<SnackbarControle>(
  'snackbarAction/ABRIR_SNACKBAR_SUCESSO',
);

export const abrirSnackbarAviso = createAction<SnackbarControle>(
  'snackbarAction/ABRIR_SNACKBAR_AVISO',
);

export const abrirSnackbarErro = createAction<SnackbarControle>(
  'snackbarAction/ABRIR_SNACKBAR_ERRO',
);

export const fecharSnackbar = createAction<void>(
  'snackbarAction/FECHAR_SNACKBAR',
);

export const abrirSnackbarNotificacao = createAction<SnackbarControle>(
  'snackbarAction/ABRIR_SNACKBAR_NOTIFICACAO',
);

export const fecharSnackbarNotificacao = createAction<void>(
  'snackbarAction/FECHAR_SNACKBAR_NOTIFICACAO',
);
