import { ConexaoAPI } from 'shared/classes/conexaoAPI';
import { baixarArquivoRespostaEndpoint } from 'shared/functions/arquivoUtils';
import { PeriodoFiltroIndicadores } from '../constants/Constants';
import {
  AtendimentoResponse,
  IndicadoresAlertaResponse,
  IndicadoresResponse,
  IndicadorNegociacao,
  NegociacaoAtendimento,
} from './indicadoresApiTypes';

const obterUrlBuscarIndicadoresPorPeriodo = (periodo: number): string =>
  `${process.env.REACT_APP_URL_API_BASE}/dashboards/negociacoes/indicadores?periodo=${periodo}`;

export const obterIndicadoresPorPeriodo = async (
  periodo: PeriodoFiltroIndicadores,
): Promise<IndicadorNegociacao[]> => {
  const periodoIndice = Object.keys(PeriodoFiltroIndicadores).indexOf(periodo);
  const url = obterUrlBuscarIndicadoresPorPeriodo(periodoIndice);
  const conexaoApi = new ConexaoAPI(url, 'get');
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<IndicadoresResponse>();
  const response = await conectar();
  return response?.data?.indicadores;
};

const obterUrlBuscarAtendimentosPorPeriodo = (periodo: number): string =>
  `${process.env.REACT_APP_URL_API_BASE}/dashboards/negociacoes/atendimento?periodo=${periodo}`;

export const obterAtendimentosPorPeriodo = async (
  periodo: PeriodoFiltroIndicadores,
): Promise<NegociacaoAtendimento[]> => {
  const periodoIndice = Object.keys(PeriodoFiltroIndicadores).indexOf(periodo);
  const url = obterUrlBuscarAtendimentosPorPeriodo(periodoIndice);
  const conexaoApi = new ConexaoAPI(url, 'get');
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<AtendimentoResponse>();
  const response = await conectar();
  return response?.data?.negociacoesAtendimento;
};

const obterUrlGerarPlanilhaIndicadoresPorPeriodo = (periodo: number): string =>
  `${process.env.REACT_APP_URL_API_BASE}/dashboards/negociacoes/planilha/indicadores?periodo=${periodo}`;

export const gerarPlanilhaIndicadoresPorPeriodo = async (
  periodo: PeriodoFiltroIndicadores,
): Promise<void> => {
  const periodoIndice = Object.keys(PeriodoFiltroIndicadores).indexOf(periodo);
  const nomeArquivoPadrao = 'IndicadoresMacros.xlsx';
  const url = obterUrlGerarPlanilhaIndicadoresPorPeriodo(periodoIndice);
  const conexaoApi = new ConexaoAPI(url, 'get');
  conexaoApi.adicionarResponseTypeArrayBuffer();
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken();
  const resposta = await conectar();

  baixarArquivoRespostaEndpoint(resposta, nomeArquivoPadrao);
};

const obterUrlBuscarAlertaPorPeriodo = (periodo: number): string =>
  `${process.env.REACT_APP_URL_API_BASE}/dashboards/negociacoes/alertas?periodo=${periodo}`;

export const obterAlertasPorPeriodo = async (
  periodo: PeriodoFiltroIndicadores,
): Promise<IndicadoresAlertaResponse> => {
  const periodoIndice = Object.keys(PeriodoFiltroIndicadores).indexOf(periodo);
  const url = obterUrlBuscarAlertaPorPeriodo(periodoIndice);
  const conexaoApi = new ConexaoAPI(url, 'get');
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<IndicadoresAlertaResponse>();
  const response = await conectar();
  return response.data;
};
