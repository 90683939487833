import React, { useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { ChatBalaoProps } from '../ChatBalao';
import TypographyChat from '../../TypographyChat';
import { RootState } from '../../../../../store/reducer';
import DownloadImagemChat from './DownloadImagemChat';
import ModalZoomImagemChat from './ModalZoomImagemChat';

const useStyles = makeStyles(() => ({
  imagem: {
    height: 200,
    width: '100%',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    cursor: 'pointer',
  },
  texto: {
    marginTop: 8,
  },
}));

const MensagemArquivo = ({ mensagem }: ChatBalaoProps): JSX.Element => {
  const classes = useStyles();
  const { chatImagens } = useSelector(
    (state: RootState) => state.chatNegociacaoReducer,
  );
  const [modalAberto, setModalAberto] = useState(false);

  const imagemBaixada = chatImagens.find(
    (i) => i.id === (mensagem.anexos ? mensagem.anexos[0] : ''),
  );

  return (
    <>
      <Box>
        {mensagem.anexos?.length &&
          (imagemBaixada ? (
            <Box
              className={classes.imagem}
              style={{
                backgroundImage: `url(${URL.createObjectURL(
                  imagemBaixada.arquivo,
                )})`,
              }}
              onClick={() => setModalAberto(true)}
            />
          ) : (
            <DownloadImagemChat mensagem={mensagem} />
          ))}
        {mensagem.texto && (
          <Box className={classes.texto}>
            <TypographyChat>{mensagem.texto}</TypographyChat>
          </Box>
        )}
      </Box>
      {imagemBaixada && (
        <ModalZoomImagemChat
          modalAberto={modalAberto}
          setModalAberto={setModalAberto}
          urlImagem={URL.createObjectURL(imagemBaixada?.arquivo)}
        />
      )}
    </>
  );
};

export default MensagemArquivo;
