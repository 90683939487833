import { createReducer } from '@reduxjs/toolkit';
import {
  DadosOpcaoIndicador,
  opcoesIndicadores,
} from 'features/indicadores/constants/Constants';
import {
  atualizarIndicadores,
  resetarIndicadores,
} from './evolucaoDeCargasAction';

export type EvolucaoDeCargasState = {
  indicador1: DadosOpcaoIndicador;
  indicador2: DadosOpcaoIndicador;
};

const estadoInicial: Readonly<EvolucaoDeCargasState> = {
  indicador1: opcoesIndicadores.despesaFrete,
  indicador2: opcoesIndicadores.valorPorEntrega,
};

const evolucaoDeCargasReducer = createReducer(estadoInicial, (builder) => {
  builder
    .addCase(atualizarIndicadores, (_state, action) => action.payload)
    .addCase(resetarIndicadores, () => estadoInicial)
    .addDefaultCase((state) => state);
});

export default evolucaoDeCargasReducer;
