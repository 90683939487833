import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { makeStyles, useTheme } from '@material-ui/core';
import { handleConfiguracaoFormatoDasEscalas } from 'features/indicadores/utils/evolucaoDeCargas';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducer';
import { TipoValorIndicador } from 'features/indicadores/constants/Constants';
import { converterValorEmDecimal } from 'shared/functions/numberUtils';

export const useStyles = makeStyles(() => ({
  container: {
    minHeight: 300,
    width: '100%',

    '& > canvas': {
      maxHeight: 300,
    },
  },
}));

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

interface GraficoEvolucaoDeCargasProps {
  periodo: string[];
  valoresIndicador1: number[];
  valoresIndicador2: number[];
}

const GraficoEvolucaoDeCargas = ({
  periodo,
  valoresIndicador1,
  valoresIndicador2,
}: GraficoEvolucaoDeCargasProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const { indicador1, indicador2 } = useSelector(
    (reducer: RootState) => reducer.evolucaoDeCargasReducer,
  );

  const corIndicador1 = theme.palette.cores.azulMartins[500];
  const corIndicador2 = theme.palette.cores.laranja[900];

  const handleExibicaoDadosGrafico = (
    dados: number[],
    tipoIndicador: TipoValorIndicador,
  ) =>
    tipoIndicador === TipoValorIndicador.PORCENTAGEM
      ? dados?.map((dado) => converterValorEmDecimal(dado))
      : dados;

  const options = {
    locale: 'pt-BR',
    responsive: true,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    stacked: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        ticks: {
          format: handleConfiguracaoFormatoDasEscalas(indicador1.tipoValor),
        },
      },
      y1: {
        type: 'linear' as const,
        display: true,
        position: 'right' as const,
        ticks: {
          format: handleConfiguracaoFormatoDasEscalas(indicador2.tipoValor),
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };

  const data = {
    labels: periodo,
    datasets: [
      {
        label: indicador1?.descricao,
        data: handleExibicaoDadosGrafico(
          valoresIndicador1,
          indicador1.tipoValor,
        ),
        borderColor: corIndicador1,
        backgroundColor: corIndicador1,
        yAxisID: 'y',
      },
      {
        label: indicador2?.descricao,
        data: handleExibicaoDadosGrafico(
          valoresIndicador2,
          indicador2.tipoValor,
        ),
        borderColor: corIndicador2,
        backgroundColor: corIndicador2,
        yAxisID: 'y1',
      },
    ],
  };

  return (
    <section className={classes.container}>
      <Line options={options} data={data} />
    </section>
  );
};

export default GraficoEvolucaoDeCargas;
