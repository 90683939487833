import React from 'react';

import TabPanel from '@material-ui/lab/TabPanel';
import { Box, Grid } from '@material-ui/core';
import { PropostaSupervisorAprovados } from 'features/aprovacoesSupervisor/types';
import AbasPropostaSupervisor from '../../constants/AbasPropostaSupervisor';
import CardPropostaAprovada from '../cardsProposta/CardPropostaAprovada';
import CardPropostaNovaMeta from '../cardsProposta/CardPropostaNovaMeta';
import useStylesPanelProposta from '../../styles/panelProposta';
import ListaVazia from '../../components/ListaVazia';
import IconeCorretoCirculo from '../../../../assets/icones/IconeCorretoCirculo';

export type PanelAprovadosNovaMetaType = {
  propostasAprovadas: PropostaSupervisorAprovados[];
};

const PanelAprovadosNovaMeta = ({
  propostasAprovadas,
}: PanelAprovadosNovaMetaType): JSX.Element => {
  const classes = useStylesPanelProposta();
  return (
    <TabPanel
      className={classes.panelContainer}
      value={AbasPropostaSupervisor.Aprovados}
    >
      {propostasAprovadas.length > 0 ? (
        <Grid container spacing={1} className={classes.gridConteiner}>
          {propostasAprovadas.map((prop) => (
            <React.Fragment key={prop.idProposta}>
              {prop.novaMeta ? (
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <CardPropostaNovaMeta proposta={prop} />
                </Grid>
              ) : (
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <CardPropostaAprovada proposta={prop} />
                </Grid>
              )}
            </React.Fragment>
          ))}
        </Grid>
      ) : (
        <ListaVazia
          icone={
            <Box className={classes.boxIcone}>
              <IconeCorretoCirculo height={70} width={70} />
            </Box>
          }
          texto="Não temos nenhum pedido aprovado ou com nova meta nos últimos 30 dias."
        />
      )}
    </TabPanel>
  );
};

export default PanelAprovadosNovaMeta;
