import React from 'react';

import { RootState } from 'store/reducer';
import { useSelector } from 'react-redux';
import { OrigemNegociacao } from 'features/informacoesNegociacao/api/informacoesNegociacaoApiTypes';
import TypographyChat from '../../TypographyChat';

import { ChatBalaoProps } from '../ChatBalao';
import ValorContraProposta from '../ValorContraProposta';

const ContrapropostaMotorista = ({ mensagem }: ChatBalaoProps): JSX.Element => {
  const { negociacao } = useSelector(
    (state: RootState) => state.informacoesNegociacaoReducer,
  );

  if (negociacao?.origemNegociacao === OrigemNegociacao.freteTabelado) {
    return (
      <>
        <TypographyChat>
          Eu recuso o Frete no valor de{' '}
          <ValorContraProposta
            valorContraproposta={mensagem.valorContraproposta}
          />
          .
        </TypographyChat>
      </>
    );
  }

  return (
    <>
      <TypographyChat>
        Esse valor está muito baixo para mim. Posso oferecer por{' '}
        <ValorContraProposta
          valorContraproposta={mensagem.valorContraproposta}
        />
        .
      </TypographyChat>
      <TypographyChat>
        <b>Motivo:</b> {mensagem.texto}
      </TypographyChat>
    </>
  );
};

export default ContrapropostaMotorista;
