import CampoOrdenacaoDemandasNegociador from '../constants/CampoOrdenacaoTabelaDemandasNegociador';
import { DemandaNegociador } from '../negociadores/api/listagemNegociadoresApiTypes';

const ordenacaoCrescente = 1;
const ordenacaoDecrescente = -1;

export const ordenarDemandasNegociador = (
  campo: string,
  demandas?: DemandaNegociador[],
  crescente?: boolean,
): DemandaNegociador[] | undefined => {
  if (!demandas) return undefined;

  const direcaoOrdenacao = crescente
    ? ordenacaoCrescente
    : ordenacaoDecrescente;
  const demandasOrdenar = [...demandas];

  switch (campo) {
    case CampoOrdenacaoDemandasNegociador.Filial:
      return demandasOrdenar.sort(
        (a, b) => a.nomeFilial.localeCompare(b.nomeFilial) * direcaoOrdenacao,
      );
    case CampoOrdenacaoDemandasNegociador.Entregas:
      return demandasOrdenar.sort(
        (a, b) =>
          (a.quantidadeEntregas - b.quantidadeEntregas) * direcaoOrdenacao,
      );
    default:
      return demandasOrdenar;
  }
};
