import { Box, makeStyles, Typography } from '@material-ui/core';
import React, { CSSProperties } from 'react';
import { formatarNumeroParaStringMonetariaComSimbolo } from '../../../../shared/functions/dinheiroUtils';
import spacingInPixels from '../../../../shared/functions/materialUtils';

export type ValorDescricaoProps = {
  valor: number;
  descricao?: string;
  cor?: CSSProperties['color'];
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: spacingInPixels(theme, 1.5),
    marginRight: spacingInPixels(theme, 1.5),
  },
  valor: {
    fontSize: '16px',
    fontWeight: 500,
    color: ({ cor }: { cor?: CSSProperties['color'] }) => cor,
  },
  texto: {
    color: theme.palette.cores.cinza[700],
    fontWeight: 400,
    fontSize: '12px',
  },
}));

const ValorDescricao = ({
  valor,
  descricao,
  cor,
}: ValorDescricaoProps): JSX.Element => {
  const classes = useStyles({ cor });

  return (
    <Box className={classes.container}>
      <Typography className={classes.valor}>
        {formatarNumeroParaStringMonetariaComSimbolo(valor)}
      </Typography>
      <Typography className={classes.texto}>{descricao}</Typography>
    </Box>
  );
};

export default ValorDescricao;
