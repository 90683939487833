/* eslint-disable react/no-children-prop */
import React from 'react';
import { makeStyles } from '@material-ui/core';
import CustomSkeleton from 'shared/components/skeleton/CustomSkeleton';
import theme from 'theme';
import spacingInPixels from 'shared/functions/materialUtils';

const useStyle = makeStyles(() => ({
  containerSkeleton: {
    display: 'flex',
    gap: spacingInPixels(theme, 1.5),
  },
}));

export interface Props {
  quantidade: number;
}

const SkeletonBotoesCabecalho = ({ quantidade }: Props): JSX.Element => {
  const classes = useStyle();

  return (
    <div className={classes.containerSkeleton}>
      {new Array(quantidade).fill(null).map((_, index) => (
        <CustomSkeleton
          width="180px"
          height={40}
          borderradius={50}
          key={`skeleton-botao-acao-${index + 1}`}
        />
      ))}
    </div>
  );
};
export default SkeletonBotoesCabecalho;
