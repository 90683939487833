import React from 'react';
import { makeStyles } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';
import CustomSkeleton from 'shared/components/skeleton/CustomSkeleton';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacingInPixels(theme, 3),
  },
  tabela: {
    display: 'flex',
    gap: spacingInPixels(theme, 5),
    justifyContent: 'space-between',
  },
}));

const SkeletonContainerAlerta = (): JSX.Element => {
  const classes = useStyles();

  return (
    <section className={classes.container}>
      <CustomSkeleton borderradius={16} width="100%" height={274} />
      <div className={classes.tabela}>
        {new Array(3).fill(null).map((_, index) => (
          <CustomSkeleton
            key={`skeleton-tabela-${index + 1}`}
            borderradius={16}
            width="30%"
            height={42}
          />
        ))}
      </div>
    </section>
  );
};

export default SkeletonContainerAlerta;
