import React from 'react';
import { makeStyles, Menu, PopoverOrigin } from '@material-ui/core';

export type DropdownMenuProps = {
  children: React.ReactNode;
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
};

const useStyles = makeStyles(() => ({
  menu: {
    width: 344,
  },
}));

const DropdownMenu = ({
  children,
  anchorEl,
  handleClose,
  anchorOrigin,
  transformOrigin,
}: DropdownMenuProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Menu
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={handleClose}
      className={classes.menu}
      getContentAnchorEl={null}
      anchorOrigin={anchorOrigin || { vertical: 'top', horizontal: 'left' }}
      transformOrigin={
        transformOrigin || { vertical: 'bottom', horizontal: 'left' }
      }
    >
      {children}
    </Menu>
  );
};

export default DropdownMenu;
