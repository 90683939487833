import TipoValorContraproposta from './TipoValorContraproposta';

export type TipoValorContrapropostaMapperType = {
  id: TipoValorContraproposta;
  descricao: string;
};

const TipoValorContrapropostaMapper: Readonly<TipoValorContrapropostaMapperType>[] = [
  { id: TipoValorContraproposta.ValorProposto, descricao: 'Valor Proposto' },
  { id: TipoValorContraproposta.PorcentagemFrotaPropria, descricao: '% FP' },
  { id: TipoValorContraproposta.PorcentagemFreteTerceiro, descricao: '% FT' },
  { id: TipoValorContraproposta.PorEntrega, descricao: 'Por Entrega' },
  { id: TipoValorContraproposta.PorPeso, descricao: 'Por kg' },
  { id: TipoValorContraproposta.PorKm, descricao: 'Por km' },
  {
    id: TipoValorContraproposta.PorcentagemMercadoria,
    descricao: '% mercadoria',
  },
];

export default TipoValorContrapropostaMapper;
