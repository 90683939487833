import { ObterNotificacoesResponseType } from '../types/notificacoes';

import * as notificacoesService from './notificacoesService';

export const obterNotificacoes = async (
  pagina = 1,
  isLido = false,
): Promise<ObterNotificacoesResponseType> =>
  notificacoesService.obterNotificacoes(pagina, isLido);

export const atualizarNotificacao = async (
  idNotificacao: number,
): Promise<void> =>
  notificacoesService.atualizarNotificacao(idNotificacao).catch(() => {
    // Erros nesta função não devem ser exibidos para o usuário
  });

export const limparNotificacao = async (): Promise<void> =>
  notificacoesService.limparNotificacao();
