import { makeStyles } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';

const estilosTabela = makeStyles((theme) => ({
  celulaCabecalho: {
    '& svg': {
      verticalAlign: 'middle',
    },
  },
  fonteCabecalho: {
    fontFamily: 'Rubik',
    fontSize: spacingInPixels(theme, 2),
    fontWeight: 500,
    lineHeight: spacingInPixels(theme, 4),
    letterSpacing: '0em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '90%',
  },
  fonteConteudo: {
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: spacingInPixels(theme, 2),
    lineHeight: spacingInPixels(theme, 2.5),
    color: theme.palette.cores.cinza[600],
    letterSpacing: '0.4px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '90%',
  },
  tdCenter: {
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      margin: '0 4px',
    },
  },
}));

export default estilosTabela;
