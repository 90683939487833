import { Motorista } from '../api/minhasNegociacoesApiTypes';
import { formatarNumeroParaStringMonetariaComSimbolo } from '../../../shared/functions/dinheiroUtils';
import EstadoAtualProposta from '../constants/EstadoAtualProposta';

export const obterIniciaisMotorista = (nomeCompleto: string): string => {
  const nomes = nomeCompleto.trim().split(' ');

  const inicialPrimeiroNome = nomes[0].charAt(0);
  const inicialUltimoNome = nomes[nomes.length - 1].charAt(0);

  return `${inicialPrimeiroNome}${inicialUltimoNome}`.toUpperCase();
};

const ordenacaoStatus = [
  EstadoAtualProposta.CONCLUIDA,
  EstadoAtualProposta.ACEITE_MOTORISTA,
  EstadoAtualProposta.PENDENTE_NEGOCIADOR,
  EstadoAtualProposta.AGUARDANDO,
  EstadoAtualProposta.RECUSADA,
];

export const obterListaMotoristaOrdenada = (
  listaMotorista: Motorista[],
): Motorista[] =>
  [...listaMotorista]
    .sort((a, b) => a.nome.localeCompare(b.nome))
    .sort(
      (a, b) =>
        ordenacaoStatus.indexOf(a.status) - ordenacaoStatus.indexOf(b.status),
    );

export const obterTituloTooltip = (
  nome: string,
  valorProposta: number,
): string =>
  `${nome} - ${formatarNumeroParaStringMonetariaComSimbolo(valorProposta)}`;
