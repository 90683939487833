import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';

export type TextoViagensRotaProps = {
  quantidadeViagens?: number;
  diasCalculo?: number;
};

const useStyles = makeStyles((theme) => ({
  quantidade: {
    color: theme.palette.cores.azulMartins[500],
    fontSize: '16px',
    fontWeight: 500,
    display: 'inline',
  },
  descricao: {
    color: theme.palette.cores.cinza[600],
    fontSize: '12px',
    fontWeight: 400,
    display: 'inline',
  },
}));

const TextoViagensRota = ({
  quantidadeViagens = 0,
  diasCalculo = 0,
}: TextoViagensRotaProps): JSX.Element => {
  const classes = useStyles();

  const formatarQuantidadeViagens = () =>
    quantidadeViagens <= 1
      ? `${quantidadeViagens} viagem`
      : `${quantidadeViagens} viagens`;

  const formatarDescricaoViagens = () =>
    quantidadeViagens <= 1
      ? `feita nesta rota nos ultimos ${diasCalculo} dias`
      : `feitas nesta rota nos ultimos ${diasCalculo} dias`;

  return (
    <span>
      <Typography className={classes.quantidade}>
        {formatarQuantidadeViagens()}{' '}
      </Typography>
      <Typography className={classes.descricao}>
        {formatarDescricaoViagens()}
      </Typography>
    </span>
  );
};

export default TextoViagensRota;
