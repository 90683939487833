import React from 'react';
import { makeStyles } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';
import { IndicadoresAlertaResponse } from 'features/indicadores/api/indicadoresApiTypes';
import ConteudoErro from 'features/indicadores/components/ConteudoErro';
import { mensagemErro } from 'features/indicadores/constants/Constants';
import GraficoAprovacaoVisualizacao from './GraficoAprovacaoVisualizacao';
import GraficoVencimento from './GraficoVencimento';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacingInPixels(theme, 2.5),
    marginTop: spacingInPixels(theme, 1.5),
  },
  span: {
    position: 'relative',
    fontSize: spacingInPixels(theme, 2),
    fontWeight: 400,
    lineHeight: spacingInPixels(theme, 3.5),
    color: theme.palette.cores.cinza[700],
    right: spacingInPixels(theme, 0.75),
    bottom: spacingInPixels(theme, 1),
    letterSpacing: '0.15px',
  },
  containerGraficos: {
    display: 'flex',
    flexDirection: 'row',
    gap: spacingInPixels(theme, 5),
  },
}));

interface ConteudoAlertaProps {
  erro: boolean;
  carregando: boolean;
  onClickTentarNovamente: () => void;
  indicadoresAlerta: IndicadoresAlertaResponse;
}

const ConteudoAlerta = ({
  erro,
  carregando,
  onClickTentarNovamente,
  indicadoresAlerta,
}: ConteudoAlertaProps): JSX.Element => {
  const classes = useStyles();

  const ConteudoErroComTentarNovamente: React.FC = () => (
    <ConteudoErro
      onClickBotao={onClickTentarNovamente}
      mensagem={mensagemErro}
    />
  );

  return (
    <section className={classes.container}>
      {!erro && !carregando && (
        <span className={classes.span}>Alerta de vencimento</span>
      )}
      {erro ? (
        <ConteudoErroComTentarNovamente />
      ) : (
        <div className={classes.containerGraficos}>
          <GraficoVencimento
            carregando={carregando}
            indicadoresAlerta={indicadoresAlerta}
          />
          <GraficoAprovacaoVisualizacao
            carregando={carregando}
            indicadoresAlerta={indicadoresAlerta}
          />
        </div>
      )}
    </section>
  );
};

export default ConteudoAlerta;
