import { makeStyles, Theme } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';

export const estilosTabelaNegociadores = makeStyles((theme: Theme) => ({
  gridTabela: {
    minWidth: '1300px',
    display: 'grid',
    gridTemplateColumns: '18% 12% 42% 12% 12%',
    textAlign: 'left',
    gap: spacingInPixels(theme, 1),
    paddingLeft: '7px',
  },
  fonteCabecalho: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: spacingInPixels(theme, 1.75),
    lineHeight: spacingInPixels(theme, 3.5),
    letterSpacing: '0.15',
    color: theme.palette.cores.cinza[700],
  },
  botaoDirecao: {
    color: theme.palette.cores.azulMartins[500],
    position: 'absolute',
    marginLeft: '110px',
    '&:hover': {
      color: theme.palette.cores.azulMartins[900],
      cursor: 'pointer',
    },
  },
}));
