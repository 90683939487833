import { Theme, makeStyles } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';

export const useStyles = makeStyles<Theme, { altura: boolean }>(
  (theme: Theme) => ({
    corpoTabela: {
      height: ({ altura }) =>
        altura ? 'calc(100% - 224px)' : 'calc(90vh - 164px)',
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        display: 'none',
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.cores.cinza[500],
        borderRadius: '8px',
      },
      '& > tr': {
        marginBottom: spacingInPixels(theme, 1.5),
      },
    },
  }),
);
