import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import FormGroup from '@material-ui/core/FormGroup';
import IconButton from '@material-ui/core/IconButton';
import Popover, { PopoverOrigin } from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { Grid, withStyles, useTheme } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';

import Constants from '../../../../constants/msgConstants';
import { spacingInPixels } from '../../../../functions/materialUtils';
import DisablingTooltip from '../../../tooltip/DisablingTooltip';
import {
  limparDatasSelecionadas,
  selecionarData,
  definirDataInterna,
} from '../utils/popoverDropdownData';

export type PopoverDropdownDataProps = {
  id?: string;
  open: boolean;
  titulo: string;
  dataInicio: Date | null;
  dataFim: Date | null;
  onClose: () => void;
  onDataInicioChange: (dataInicio: Date | null) => void;
  onDataFimChange: (dataFim: Date | null) => void;
  anchorElement: HTMLButtonElement | null;
  anchorOrigin: PopoverOrigin;
  transformOrigin: PopoverOrigin;
};

const IconButtonLimpar = withStyles((theme) => ({
  root: {
    padding: spacingInPixels(theme, 1),
    marginLeft: spacingInPixels(theme, 2),
  },
}))(IconButton);

const PopoverDropdownData = ({
  id,
  open,
  titulo,
  dataInicio,
  dataFim,
  onClose,
  onDataInicioChange,
  onDataFimChange,
  anchorElement,
  anchorOrigin,
  transformOrigin,
}: PopoverDropdownDataProps): JSX.Element => {
  const theme = useTheme();

  const [dataFimMinima, setDataFimMinima] = useState<Date | null>(null);
  const [dataInicioMaxima, setDataInicioMaxima] = useState<Date | null>(null);
  const [dataInicioInterna, setDataInicioInterna] = useState<Date | null>(
    dataInicio,
  );
  const [dataFimInterna, setDataFimInterna] = useState<Date | null>(dataFim);

  const selecionarDataInicio = (data: Date | null) => {
    selecionarData(
      data,
      setDataInicioInterna,
      setDataFimMinima,
      onDataInicioChange,
    );
  };

  const selecionarDataFim = (data: Date | null) => {
    selecionarData(
      data,
      setDataFimInterna,
      setDataInicioMaxima,
      onDataFimChange,
    );
  };

  const onClickLimpar = () => {
    limparDatasSelecionadas({
      dataInicio,
      dataFim,
      onDataInicioChange,
      onDataFimChange,
      setDataFimMinima,
      setDataInicioMaxima,
      setDataInicioInterna,
      setDataFimInterna,
    });
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorElement}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    >
      <Box padding={theme.spacing(1, 1, 2, 2)}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          marginBottom={spacingInPixels(theme, 1)}
        >
          <Typography
            color="textPrimary"
            style={{ fontSize: '14px', fontWeight: 600 }}
          >
            {titulo}
          </Typography>
          <DisablingTooltip title="Limpar seleção">
            <IconButtonLimpar color="primary" onClick={onClickLimpar}>
              <DeleteForeverRoundedIcon color="primary" />
            </IconButtonLimpar>
          </DisablingTooltip>
        </Box>
        <FormGroup>
          <Grid direction="column" container justify="space-around">
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inicio"
              label="De"
              value={dataInicioInterna}
              onChange={(e) => selecionarDataInicio(e)}
              onBlur={() => {
                setDataInicioInterna(definirDataInterna(dataInicioInterna));
              }}
              maxDate={dataInicioMaxima === null ? undefined : dataInicioMaxima}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              invalidDateMessage={Constants.DATA_INVALIDA}
              maxDateMessage={Constants.DATA_INICIAL_SUPERIOR_DATA_FINAL}
            />
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-fim"
              label="Até"
              value={dataFimInterna}
              onChange={(e) => selecionarDataFim(e)}
              onBlur={() => {
                setDataFimInterna(definirDataInterna(dataFimInterna));
              }}
              minDate={dataFimMinima === null ? undefined : dataFimMinima}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              invalidDateMessage={Constants.DATA_INVALIDA}
              minDateMessage={Constants.DATA_FINAL_INFERIOR_DATA_INICIAL}
            />
          </Grid>
        </FormGroup>
      </Box>
    </Popover>
  );
};

export default PopoverDropdownData;
