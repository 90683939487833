import { makeStyles } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacingInPixels(theme, 4),
    marginTop: spacingInPixels(theme, 4),
  },
  autoComplete: {
    width: spacingInPixels(theme, 80),
    marginBottom: spacingInPixels(theme, 4),
    display: 'flex',

    '& .MuiAutocomplete-popupIndicator': {
      transform: 'none',
      height: spacingInPixels(theme, 3),
      width: spacingInPixels(theme, 4),
    },
    '& .MuiAutocomplete-clearIndicator': {
      display: 'none',
    },
  },
  grupoText: {
    fontSize: '14px',
    color: '#616161',
    fontWeight: 400,
  },
  title: {
    color: theme.palette.cores.cinza[700],
    fontWeight: 400,
    marginBottom: '10px',
  },
  formSwitch: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '10px',
  },
  switchLabel: {
    color: theme.palette.cores.cinza[700],
    fontWeight: 400,
  },
  boxDesativado: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  titleDesativado: {
    color: theme.palette.cores.cinza[700],
    fontWeight: 400,
    marginBottom: '40px',
  },
  icon: {
    color: theme.palette.cores.cinza[400],
    fontSize: '75px',
    transform: 'scaleX(-1)',
    margin: '20px 0 15px',
  },
  customFooterContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: theme.spacing(3),
  },
  addButton: {
    backgroundColor: '#f0eded',
    boxShadow: 'none',
    textTransform: 'none',
    marginRight: '8px',
    '&:hover': {
      backgroundColor: '#E0E0E0',
      boxShadow: 'none',
      cursor: 'pointer',
    },
  },
  addButtonUpload: {
    boxShadow: 'none',
    textTransform: 'none',
    '&:hover': {
      boxShadow: 'none',
      cursor: 'pointer',
    },
  },
  customButtons: {
    display: 'flex',
    backgroundColor: '#f8f8f8',
    justifyContent: 'space-between',
    width: '100%',
    borderTop: '0.1px solid #e4e1e1',
    boxShadow: 'none',
    padding: '20px',
  },
  buttonYesNo: {
    height: '30px',
  },
  buttonNoMargin: {
    marginLeft: '50px',
    marginRight: '8px',
  },
  excedenteRow: {
    '& .MuiDataGrid-cell.MuiDataGrid-cell--actions': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 0,
    },
    '& .MuiDataGrid-cell.MuiDataGrid-cell--actions .MuiDataGrid-cellValue': {
      display: 'none',
    },
    '& .MuiDataGrid-cell.MuiDataGrid-cell--actions .MuiIconButton-root': {
      display: 'block',
      padding: 8,
    },
  },
  select: {
    fontSize: '14px',
    fontWeight: 400,
    '&.MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '&.MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
  },
  selectedMacro: {
    border: 'none',
    width: '100%',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  selected: {
    border: 'none',
    width: '35%',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  menuItem: {
    fontWeight: 400,
  },
  menuPaper: {
    marginTop: theme.spacing(0.3),
  },
  paper: {
    position: 'fixed',
  },
  inputExcedente: {
    width: '100px',
    outline: 'none',
    fontSize: '14px',
    fontWeight: 400,
    '&.MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '&.MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
  },
  formControl: {
    width: 140,
    marginLeft: '18px',
    marginTop: '12px',
    marginBottom: '5px',
  },
  inputNumber: {
    height: '34px',
    width: '130px',
    marginTop: '3px',
    marginLeft: '12px',
    fontSize: '14px',
    fontWeight: 600,
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
      appearance: 'textfield',
    },
  },
  textTempo: {
    fontSize: '9px',
    fontWeight: 700,
    color: '#000',
  },
  AdornmentH: {
    position: 'absolute',
    left: '32px',
  },
  AdornmentMin: {
    position: 'absolute',
    left: '55px',
  },
  inputTypography: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#8C8C8C',
  },
}));
