import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Input,
  MenuItem,
  Select,
  Switch,
} from '@material-ui/core';
import {
  DataGrid,
  GridCellEditCommitParams,
  GridCellParams,
  GridCellValue,
  GridColDef,
  GridValueFormatterParams,
  ptBR,
} from '@material-ui/data-grid';
import { useStyles } from 'features/configuracoes/styles/freteTabeladoRegioes';
import { DeleteForever } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import {
  atualizarFreteTabeladoPorIdMacro,
  atualizarFreteTabeladoPorMacroFilial,
  deleteFreteTabeladoPorIdMacro,
  salvarFreteTabeladoPorIdMacro,
  salvarFreteTabeladoPorMacroFilial,
  deleteFreteTabeladoPorMacroFilial,
} from 'features/configuracoes/utils/configuracoes';
import {
  EnumCargaEspecial,
  EnumComponente,
  FreteTabeladoRegiao,
  FreteTabeladoMacroId,
  ResultNovaLinhaDadosPrecificacoes,
  DadosTabelaPrecificacoes,
  Fretes,
} from '../api/configuracoesApiTypes';
import MenuColumn from './MenuColumn';

interface DataGridFreteTabeladoRegiaoProps {
  dadosVeiculosRegiaoId: Fretes[];
  idVeiculo: number;
  dataRegiao: FreteTabeladoRegiao[];
  setDadosVeiculosRegiaoId: (dados: FreteTabeladoMacroId[]) => void;
  tempoParaExpirarNegociacoesEmMinutos: string;
}

const formatCurrency = (value: GridCellValue): string => {
  if (typeof value === 'number') {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  }
  return '';
};

const parseCurrency = (value: string): number => {
  if (!value || typeof value !== 'string') {
    return 0;
  }
  const numericValue = value.replace(/[R$\s.]/g, '').replace(',', '.');
  const parsedValue = parseFloat(numericValue);

  // eslint-disable-next-line no-restricted-globals
  return isNaN(parsedValue) ? 0 : parsedValue;
};

const DataGridFreteTabeladoRegiao = ({
  dadosVeiculosRegiaoId,
  idVeiculo,
  dataRegiao,
  setDadosVeiculosRegiaoId,
  tempoParaExpirarNegociacoesEmMinutos,
}: DataGridFreteTabeladoRegiaoProps): JSX.Element => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [excedenteValor, setExcedenteValor] = useState<
    { [key in number]: number }
  >();
  const [cleanTable, setCleanTable] = useState<boolean>(false);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [rowsData, setRowsData] = useState<Fretes[]>(
    dadosVeiculosRegiaoId || [],
  );
  const precificacoes = rowsData.flatMap((linha, index) =>
    linha.precificacoes.map((precificacao, idx) => ({
      ...precificacao,
      id: index * 1000 + idx,
    })),
  );

  useEffect(() => {
    setRowsData(dadosVeiculosRegiaoId || []);
    setCleanTable(false);
  }, [dadosVeiculosRegiaoId, cleanTable]);

  const [rows, setRows] = useState<DadosTabelaPrecificacoes[]>(precificacoes);

  useEffect(() => {
    const newPrecificacoes = rowsData.flatMap((linha, index) =>
      linha.precificacoes.map((precificacao, idx) => ({
        ...precificacao,
        id: index * 1000 + idx,
      })),
    );
    setRows(newPrecificacoes);
    setCleanTable(false);
  }, [rowsData, cleanTable]);

  const handleComponenteChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    rowId: number,
  ) => {
    const { value } = event.target;
    if (typeof value === 'string') {
      const componenteValue = value as EnumComponente;
      setRows((prevRows) =>
        prevRows.map((row) =>
          row.id === rowId ? { ...row, componente: componenteValue } : row,
        ),
      );
    }
  };

  const handleExcedenteChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    rowId: number,
  ) => {
    const { checked, value } = event.target;
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.id === rowId
          ? {
              ...row,
              valorExcedente: checked
                ? formatCurrency(parseCurrency(value))
                : null,
              possuiExcedente: checked,
            }
          : row,
      ),
    );
  };

  const handleCargaEspecialChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    rowId: number,
  ) => {
    const { checked, value } = event.target;
    const cargaValue = value as EnumCargaEspecial | null;
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.id === rowId
          ? {
              ...row,
              cargaEspecial: checked ? cargaValue : null,
              possuiCargaEspecial: checked,
            }
          : row,
      ),
    );
  };

  const handleCargaEspecialSelectChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    rowId: number,
  ) => {
    const { value } = event.target;
    if (typeof value === 'string') {
      const cargaValue = value as EnumCargaEspecial;
      setRows((prevRows) =>
        prevRows.map((row) =>
          row.id === rowId
            ? {
                ...row,
                cargaEspecial: cargaValue || null,
              }
            : row,
        ),
      );
    }
  };

  const handleExcedenteInputChange = (
    _event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    rowId: number,
  ) => {
    const inputValue = _event.target.value;
    const numericValue = parseCurrency(inputValue.replace('R$', ''));
    const updatedExcedenteValor = { ...excedenteValor };
    updatedExcedenteValor[rowId] = numericValue;

    setExcedenteValor(updatedExcedenteValor);
  };

  useEffect(() => {
    const handleEffect = async (): Promise<void> => {
      if (shouldUpdate) {
        const partes = tempoParaExpirarNegociacoesEmMinutos.split('    ');
        let tempoTotalEmMinutos: number;

        if (partes.length === 2) {
          const horasEmMinutos = parseInt(partes[0], 10) * 60;
          const minutos = parseInt(partes[1], 10);

          tempoTotalEmMinutos = horasEmMinutos + minutos;
        }

        const dataWithoutId: DadosTabelaPrecificacoes[] = rows.map(
          ({ ...rest }) => ({
            ...rest,
            tempoParaExpirarNegociacoesEmMinutos: tempoTotalEmMinutos,
          }),
        );

        if (
          dataWithoutId.length === 0 &&
          rowsData[0]?.precificacoes.length > 0
        ) {
          if (dataRegiao.length > 1) {
            await deleteFreteTabeladoPorMacroFilial(
              dataRegiao[0].idFilial.toString(),
              rowsData[0].categoriaVeiculo.idCategoriaVeiculo.toString(),
              dispatch,
              rowsData[0].categoriaVeiculo.descricao,
              setDadosVeiculosRegiaoId,
            );
          } else {
            await deleteFreteTabeladoPorIdMacro(
              dataRegiao[0].idMacroFilial.toString(),
              rowsData[0].categoriaVeiculo.idCategoriaVeiculo.toString(),
              dispatch,
              rowsData[0].categoriaVeiculo.descricao,
              setDadosVeiculosRegiaoId,
            );
          }
        } else if (
          dataWithoutId.length > 0 &&
          rowsData[0]?.precificacoes.length > 0
        ) {
          if (dataRegiao.length > 1) {
            await atualizarFreteTabeladoPorMacroFilial(
              dataRegiao[0]?.idFilial.toString(),
              idVeiculo.toString(),
              dataWithoutId,
              dispatch,
              setDadosVeiculosRegiaoId,
              false,
            );
          } else {
            await atualizarFreteTabeladoPorIdMacro(
              dataRegiao[0].idMacroFilial.toString(),
              idVeiculo.toString(),
              dataWithoutId,
              dispatch,
              setDadosVeiculosRegiaoId,
              false,
            );
          }
        }
        setShouldUpdate(false);
      }
    };

    handleEffect();
  }, [
    shouldUpdate,
    rows,
    dataRegiao,
    rowsData,
    dispatch,
    idVeiculo,
    setDadosVeiculosRegiaoId,
    tempoParaExpirarNegociacoesEmMinutos,
  ]);

  const handleDeleteRow = (rowId: number) => {
    setRows((prevTableData) => prevTableData.filter((row) => row.id !== rowId));
    setShouldUpdate(true);
  };

  const componenteLabels = {
    [EnumComponente.Entrega]: 'Entrega',
    [EnumComponente.Saida]: 'Saída',
    [EnumComponente.Km]: 'Km',
    [EnumComponente.EntregaKm]: 'Entrega Km',
  };

  const [columns, setColumns] = useState<GridColDef[]>([
    {
      field: 'componente',
      headerName: 'Componente',
      width: 150,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ value, id }: GridCellParams) => (
        <Box style={{ display: 'flex' }}>
          <Select
            value={value}
            onChange={(event) => handleComponenteChange(event, Number(id))}
            style={{ minWidth: '120px' }}
            className={classes.select}
            MenuProps={{
              classes: {
                paper: classes.menuPaper,
              },
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
          >
            {Object.values(EnumComponente).map((val) => (
              <MenuItem key={val} className={classes.menuItem} value={val}>
                {componenteLabels[val]}
              </MenuItem>
            ))}
          </Select>
        </Box>
      ),
    },
    {
      field: 'de',
      headerName: 'De',
      width: 100,
      sortable: false,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      editable: true,
      type: 'number',
      disableColumnMenu: true,
    },
    {
      field: 'ate',
      headerName: 'Até',
      width: 100,
      sortable: false,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      editable: true,
      type: 'number',

      disableColumnMenu: true,
    },
    {
      field: 'deKm',
      headerName: 'De/Km',
      width: 100,
      sortable: false,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      editable: true,
      type: 'number',
      disableColumnMenu: true,
    },
    {
      field: 'ateKm',
      headerName: 'Até/KM',
      width: 100,
      sortable: false,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      editable: true,
      type: 'number',
      disableColumnMenu: true,
    },
    {
      field: 'valorFixo',
      headerName: 'Valor Fixo',
      width: 120,
      sortable: false,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      editable: true,
      type: 'number',
      disableColumnMenu: true,
      valueFormatter: (params: GridValueFormatterParams) =>
        formatCurrency(params.value),
    },
    {
      field: 'valorPJ',
      headerName: 'R$/PJ',
      width: 120,
      sortable: false,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      editable: true,
      type: 'number',
      disableColumnMenu: true,
      valueFormatter: (params: GridValueFormatterParams) =>
        formatCurrency(params.value),
    },
    {
      field: 'valorPF',
      headerName: 'R$/PF',
      width: 120,
      sortable: false,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      editable: true,
      type: 'number',
      disableColumnMenu: true,
      valueFormatter: (params: GridValueFormatterParams) =>
        formatCurrency(params.value),
    },
    {
      field: 'valorKG',
      headerName: 'R$/Kg',
      width: 120,
      sortable: false,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      editable: true,
      type: 'number',
      disableColumnMenu: true,
      valueFormatter: (params: GridValueFormatterParams) =>
        formatCurrency(params.value),
    },
    {
      field: 'valorKM',
      headerName: 'R$/Km',
      width: 120,
      sortable: false,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      editable: true,
      type: 'number',
      disableColumnMenu: true,
      valueFormatter: (params: GridValueFormatterParams) =>
        formatCurrency(params.value),
    },
    {
      field: 'valorPedagio',
      headerName: 'R$/Pedágio',
      width: 140,
      sortable: false,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      editable: true,
      type: 'number',
      disableColumnMenu: true,
      valueFormatter: (params: GridValueFormatterParams) =>
        formatCurrency(params.value),
    },
    {
      field: 'valorReentrega',
      headerName: 'R$/Re-entrega',
      width: 160,
      sortable: false,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      editable: true,
      type: 'number',
      disableColumnMenu: true,
      valueFormatter: (params: GridValueFormatterParams) =>
        formatCurrency(params.value),
    },
    {
      field: 'valorDevolucao',
      headerName: 'R$/Devolução',
      width: 160,
      sortable: false,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      editable: true,
      type: 'number',
      disableColumnMenu: true,
      valueFormatter: (params: GridValueFormatterParams) =>
        formatCurrency(params.value),
    },
    {
      field: 'valorExcedente',
      headerName: 'Excendente',
      width: 200,
      sortable: false,
      filterable: false,
      editable: true,
      headerAlign: 'center',
      align: 'left',
      type: 'number',
      disableColumnMenu: true,
      renderCell: ({ value, id }: GridCellParams) => {
        const numericValue = Number(value);
        // eslint-disable-next-line no-restricted-globals
        const formattedValue = isNaN(numericValue)
          ? 'R$0,00'
          : `${formatCurrency(value)}`;

        return (
          <Box style={{ display: 'flex' }}>
            <Switch
              checked={value != null}
              color="primary"
              onChange={(event) => handleExcedenteChange(event, Number(id))}
            />
            {value && (
              <Input
                type="text"
                value={formattedValue}
                onChange={(event) =>
                  handleExcedenteInputChange(event, Number(id))
                }
                className={classes.inputExcedente}
              />
            )}
          </Box>
        );
      },
    },
    {
      field: 'cargaEspecial',
      headerName: 'C. Especial',
      width: 180,
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      editable: false,
      disableColumnMenu: true,
      renderCell: ({ value, id }: GridCellParams) => {
        return (
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <Switch
              checked={value != null}
              color="primary"
              onChange={(event) => handleCargaEspecialChange(event, Number(id))}
            />
            {value != null && (
              <Select
                value={value}
                style={{ minWidth: '100px' }}
                onChange={(event) =>
                  handleCargaEspecialSelectChange(event, Number(id))
                }
                className={classes.select}
                MenuProps={{
                  classes: {
                    paper: classes.menuPaper,
                  },
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                {Object.entries(EnumCargaEspecial).map(([key, val]) => (
                  <MenuItem key={val} className={classes.menuItem} value={val}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Box>
        );
      },
    },
    {
      field: 'acoes',
      headerName: 'Ações',
      width: 90,
      sortable: false,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      editable: false,
      disableColumnMenu: true,
      renderCell: ({ id }: GridCellParams) => (
        <IconButton onClick={() => handleDeleteRow(Number(id))}>
          <DeleteForever style={{ color: 'red' }} />
        </IconButton>
      ),
    },
  ]);

  const handleCellEditCommit = (params: GridCellEditCommitParams) => {
    const { id, field, value } = params;

    // Verificar se a coluna está oculta
    if (hiddenColumns.includes(field)) {
      // Definir o valor como null
      const updatedRows = rows.map((row) => {
        if (row.id === id) {
          return { ...row, [field]: null };
        }
        return row;
      });
      setRows(updatedRows);
    } else {
      // Atualizar normalmente
      const updatedRows = rows.map((row) => {
        if (row.id === id) {
          // Verificar se o valor é válido (não negativo) antes de atualizar
          const numericValue = parseFloat(value as string);

          // eslint-disable-next-line no-restricted-globals
          if (!isNaN(numericValue) && numericValue >= 0) {
            // Atualizar apenas se o valor for um número válido e não negativo
            return { ...row, [field]: numericValue };
          }
          return row;
        }
        return row;
      });
      setRows(updatedRows);
    }
  };

  useEffect(() => {
    if (rows.length === 0) {
      return;
    }

    const hasNonNullValue = (fieldName: string) => {
      return rows.some(
        (row) => row[fieldName as keyof DadosTabelaPrecificacoes] !== null,
      );
    };

    const temValorFixo = hasNonNullValue('valorFixo');
    const possuiCargaEspecialValue = hasNonNullValue('possuiCargaEspecial');
    const possuiExcedenteValue = hasNonNullValue('possuiExcedente');
    const valorDevolucao = hasNonNullValue('valorDevolucao');
    const valorReentrega = hasNonNullValue('valorReentrega');
    const valorPedagio = hasNonNullValue('valorPedagio');
    const valorKM = hasNonNullValue('valorKM');
    const valorKG = hasNonNullValue('valorKG');
    const valorPF = hasNonNullValue('valorPF');
    const valorPJ = hasNonNullValue('valorPJ');
    const ate = hasNonNullValue('ate');
    const de = hasNonNullValue('de');
    const atekm = hasNonNullValue('ateKm');
    const dekm = hasNonNullValue('deKm');

    const fieldsWithNullValues = Object.keys(rows[0]).filter((field) => {
      if (field === 'cargaEspecial' && possuiCargaEspecialValue) return false;
      if (field === 'valorExcedente' && possuiExcedenteValue) return false;
      if (field === 'valorFixo' && temValorFixo) return false;
      if (field === 'valorDevolucao' && valorDevolucao) return false;
      if (field === 'valorReentrega' && valorReentrega) return false;
      if (field === 'valorPedagio' && valorPedagio) return false;
      if (field === 'valorKM' && valorKM) return false;
      if (field === 'valorKG' && valorKG) return false;
      if (field === 'valorPF' && valorPF) return false;
      if (field === 'valorPJ' && valorPJ) return false;
      if (field === 'ate' && ate) return false;
      if (field === 'de' && de) return false;
      if (field === 'ateKm' && atekm) return false;
      if (field === 'deKm' && dekm) return false;
      return rows.some(
        (item) => item[field as keyof DadosTabelaPrecificacoes] === null,
      );
    });

    setHiddenColumns(fieldsWithNullValues);

    setColumns((prevColumns) =>
      prevColumns.map((col) => {
        switch (col.field) {
          case 'possuiCargaEspecial':
            return { ...col, hide: possuiCargaEspecialValue === null };
          case 'possuiExcedente':
            return { ...col, hide: possuiExcedenteValue === null };
          default:
            return { ...col, hide: fieldsWithNullValues.includes(col.field) };
        }
      }),
    );
  }, [rows]);

  const handleColumnVisibilityChange = useCallback(
    (params) => {
      const { isVisible, field } = params;

      if (field === 'componente' && !isVisible) {
        return;
      }

      if (field === 'acoes' && !isVisible) {
        return;
      }

      const possuiCargaEspecialValue = rows.find(
        (row) => row.idPrecificacaoFreteTabelado,
      )?.possuiCargaEspecial;
      const possuiExcedenteValue = rows.find(
        (row) => row.idPrecificacaoFreteTabelado,
      )?.possuiExcedente;

      if (!isVisible) {
        setRows((prevRows) =>
          prevRows.map((row) => {
            if (row[field as keyof DadosTabelaPrecificacoes] !== undefined) {
              if (field === 'cargaEspecial') {
                return { ...row, [field]: null, possuiCargaEspecial: null };
              }
              if (field === 'valorExcedente') {
                return { ...row, [field]: null, possuiExcedente: null };
              }
              return { ...row, [field]: null };
            }
            return row;
          }),
        );

        setHiddenColumns((prevHiddenColumns) => {
          const isCargaEspecialColumnHidden =
            field === 'cargaEspecial' && possuiCargaEspecialValue === false;
          const isExcedenteColumnHidden =
            field === 'valorExcedente' && possuiExcedenteValue === false;
          return isCargaEspecialColumnHidden || isExcedenteColumnHidden
            ? prevHiddenColumns
            : [...prevHiddenColumns, field];
        });

        if (field === 'cargaEspecial' && possuiCargaEspecialValue === null) {
          setColumns((prevColumns) =>
            prevColumns.map((col) =>
              col.field === 'cargaEspecial' ? { ...col, hide: true } : col,
            ),
          );
        }

        if (field === 'valorExcedente' && possuiExcedenteValue === null) {
          setColumns((prevColumns) =>
            prevColumns.map((col) =>
              col.field === 'valorExcedente' ? { ...col, hide: true } : col,
            ),
          );
        }
      } else {
        // Remover a coluna dos campos ocultos quando estiver visível novamente
        setRows((prevRows) =>
          prevRows.map((row) => {
            if (row[field as keyof DadosTabelaPrecificacoes] !== undefined) {
              if (field === 'cargaEspecial') {
                return { ...row, [field]: null, possuiCargaEspecial: false };
              }
              if (field === 'valorExcedente') {
                return { ...row, [field]: null, possuiExcedente: false };
              }
              return { ...row, [field]: 0 };
            }
            return row;
          }),
        );
        setHiddenColumns((prevHiddenColumns) =>
          prevHiddenColumns.filter((col) => col !== field),
        );
        if (field === 'cargaEspecial' && possuiCargaEspecialValue === null) {
          setColumns((prevColumns) =>
            prevColumns.map((col) =>
              col.field === 'cargaEspecial' ? { ...col, hide: false } : col,
            ),
          );
        }
        if (field === 'valorExcedente' && possuiExcedenteValue === null) {
          setColumns((prevColumns) =>
            prevColumns.map((col) =>
              col.field === 'valorExcedente' ? { ...col, hide: false } : col,
            ),
          );
        }
      }
      setColumns((prevColumns) =>
        prevColumns.map((col) =>
          col.field === field ? { ...col, hide: !isVisible } : col,
        ),
      );
    },
    [rows],
  );

  const handleSave = async () => {
    const partes = tempoParaExpirarNegociacoesEmMinutos.split('    ');
    let tempoTotalEmMinutos: number;

    if (partes.length === 2) {
      const horasEmMinutos = parseInt(partes[0], 10) * 60;
      const minutos = parseInt(partes[1], 10);

      tempoTotalEmMinutos = horasEmMinutos + minutos;
    }

    const dataWithoutId: DadosTabelaPrecificacoes[] = rows.map(
      ({ ...rest }) => ({
        ...rest,
        tempoParaExpirarNegociacoesEmMinutos: tempoTotalEmMinutos,
      }),
    );

    if (!rowsData[0]?.precificacoes || rowsData[0].precificacoes.length === 0) {
      if (dataRegiao.length > 1) {
        await salvarFreteTabeladoPorMacroFilial(
          dataRegiao[0]?.idFilial.toString(),
          idVeiculo.toString(),
          dataWithoutId,
          dispatch,
          setDadosVeiculosRegiaoId,
        );
      } else {
        await salvarFreteTabeladoPorIdMacro(
          dataRegiao[0].idMacroFilial.toString(),
          idVeiculo.toString(),
          dataWithoutId,
          dispatch,
          setDadosVeiculosRegiaoId,
        );
      }
    } else if (dataRegiao.length > 1) {
      await atualizarFreteTabeladoPorMacroFilial(
        dataRegiao[0]?.idFilial.toString(),
        idVeiculo.toString(),
        dataWithoutId,
        dispatch,
        setDadosVeiculosRegiaoId,
        true,
      );
    } else {
      await atualizarFreteTabeladoPorIdMacro(
        dataRegiao[0].idMacroFilial.toString(),
        idVeiculo.toString(),
        dataWithoutId,
        dispatch,
        setDadosVeiculosRegiaoId,
        true,
      );
    }
  };

  const criarNovaLinha = (): DadosTabelaPrecificacoes => {
    const newId = rows.reduce(
      (max, row) => (row.id !== undefined && row.id > max ? row.id : max),
      0,
    );

    const randomId = newId + 1;

    const novaLinha: DadosTabelaPrecificacoes = {
      id: randomId,
      idPrecificacaoFreteTabelado: randomId,
      componente: EnumComponente.Entrega,
      de: 0,
      ate: 0,
      ateKm: 0,
      deKm: 0,
      valorFixo: 0,
      valorPJ: 0,
      valorPF: 0,
      valorKG: 0,
      valorKM: 0,
      valorPedagio: 0,
      valorReentrega: 0,
      valorDevolucao: 0,
      valorExcedente: null,
      possuiExcedente: false,
      cargaEspecial: null,
      possuiCargaEspecial: false,
    };

    rows.forEach((row) => {
      Object.keys(row).forEach((key: string) => {
        if (
          row[key as keyof DadosTabelaPrecificacoes] === null &&
          key !== 'id' &&
          key !== 'idPrecificacaoFreteTabelado' &&
          key !== 'componente'
        ) {
          novaLinha[key as keyof ResultNovaLinhaDadosPrecificacoes] = null;
        }
      });
    });

    return novaLinha;
  };

  const handleAddRow = () => {
    const novaLinha = criarNovaLinha();
    setRows((prevRows) => [...prevRows, novaLinha]);
  };

  const handleCleanTable = () => {
    setCleanTable(true);
  };

  const CustomFooter = () => (
    <>
      <Box className={classes.customFooterContainer}>
        <Box
          className="MuiDataGrid-selectedRowCount"
          style={{ display: 'none' }}
        />
        <Button
          size="small"
          className={classes.addButton}
          variant="contained"
          onClick={handleAddRow}
        >
          Add nova linha
        </Button>
      </Box>
      <Box className={classes.customButtons}>
        <Button color="primary" variant="text" onClick={handleCleanTable}>
          LIMPAR
        </Button>
        <Button color="primary" variant="contained" onClick={handleSave}>
          SALVAR
        </Button>
      </Box>
    </>
  );

  return (
    <>
      <MenuColumn
        columns={columns}
        onColumnVisibilityChange={handleColumnVisibilityChange}
        hiddenColumns={hiddenColumns}
      />
      <DataGrid
        rows={rows}
        columns={columns}
        hideFooterPagination
        onCellEditCommit={handleCellEditCommit}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        localeText={ptBR.props.MuiDataGrid.localeText}
        disableSelectionOnClick
        components={{ Footer: CustomFooter }}
      />
    </>
  );
};

export default DataGridFreteTabeladoRegiao;
