import { Theme, makeStyles } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';

interface GridStylesProps {
  isAutomatizadoStyle: boolean;
}

const gridStyles = makeStyles<Theme, GridStylesProps>((theme) => ({
  gridContainer: (props) => ({
    display: 'grid',
    gridTemplateColumns: props.isAutomatizadoStyle
      ? '0.5fr 4fr 4fr 2fr 0.5fr 2.5fr 2.5fr 1fr 4.5fr 4fr'
      : '0.5fr 4fr 3fr 1fr 3.5fr 3.5fr 1.5fr 1fr 2.5fr 2fr',
    width: '100%',
    minHeight: spacingInPixels(theme, 7),
    maxHeight: '100%',
    padding: `0px 0px 0px 8px`,
    overflowX: 'hidden',
    overflowY: 'scroll',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      background: `${theme.palette.cores.cinza[500]}60`,
      borderRadius: '8px',
    },
  }),
  gridItem: {
    display: 'flex',
    alignItems: 'center',
    padding: `${spacingInPixels(theme, 1.5)} 0`,
    gap: spacingInPixels(theme, 1),
    overflow: 'hidden',
  },
}));

export default gridStyles;
