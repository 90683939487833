import { MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import React, { useState } from 'react';
import BotaoIcone from 'shared/components/button/BotaoIcone';
import DropdownMenu from 'shared/components/dropdown/dropdownMenu';

import CancelarNegociacaoButton from './listaEmNegociacao/CancelarNegociacaoButton';
import CancelarNegociacaoDialog from './listaEmNegociacao/CancelarNegociacaoDialog';

const CancelarNegociacaoMenu = (): JSX.Element => {
  const [anchorMenu, setAnchorMenu] = useState<HTMLElement | null>(null);

  const handleCloseMenu = (): void => {
    setAnchorMenu(null);
  };

  const handleAbrirMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    setAnchorMenu(event.currentTarget);
  };

  return (
    <>
      <BotaoIcone descricao="Ações" onClick={handleAbrirMenu}>
        <MoreVert />
      </BotaoIcone>
      <DropdownMenu
        anchorEl={anchorMenu}
        handleClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 220 }}
      >
        <MenuItem>
          <CancelarNegociacaoButton />
        </MenuItem>
      </DropdownMenu>
      <CancelarNegociacaoDialog />
    </>
  );
};

export default CancelarNegociacaoMenu;
