import { Chip, makeStyles, useTheme } from '@material-ui/core';
import React, { useState } from 'react';
import IconeFilial from '../../../assets/icones/IconeFilial';
import IconeUsuario from '../../../assets/icones/IconeUsuario';

import spacingInPixels from '../../../shared/functions/materialUtils';
import { OpcaoAba, OpcaoAbaId } from '../constants/TipoOpcaoAba';

const useStyles = makeStyles((theme) => ({
  containerAbas: {
    display: 'flex',
    alignItems: 'left',
  },
  opcaoAba: {
    marginRight: '1em',
    '&:hover': {
      cursor: 'pointer',
    },
    padding: `${spacingInPixels(theme, 0.8)} ${spacingInPixels(theme, 1.2)}`,
  },
}));

type MenuOpcoesAbasProps = {
  onClickAba: (idAba: OpcaoAbaId) => void;
};

const MenuOpcoesAbas = ({ onClickAba }: MenuOpcoesAbasProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const corPadraoIcone = theme.palette.cores.cinza[700];

  const opcoesAbas = [
    new OpcaoAba(
      OpcaoAbaId.Negociadores,
      'Negociadores',
      <IconeUsuario />,
      <IconeUsuario fill={corPadraoIcone} />,
      false,
    ),
    new OpcaoAba(
      OpcaoAbaId.Filial,
      'Filiais',
      <IconeFilial viewBox="0 0 10 12" />,
      <IconeFilial fill={corPadraoIcone} viewBox="0 0 10 12" />,
      false,
    ),
  ];

  const [abaAtual, setAbaAtual] = useState(OpcaoAbaId.Negociadores);

  const handleClick = (idAba: number) => {
    setAbaAtual(idAba);
    onClickAba(idAba);
  };

  return (
    <section className={classes.containerAbas}>
      {opcoesAbas?.map((opcao) => {
        const ativo = abaAtual === opcao.id;
        return (
          <Chip
            size="medium"
            label={opcao.label}
            color={ativo ? 'primary' : 'default'}
            icon={ativo ? opcao.activeIcon : opcao.defaultIcon}
            onClick={() => handleClick(opcao.id)}
            className={classes.opcaoAba}
            key={`aba-${opcao.label}-${opcao.id}`}
            disabled={opcao.desabilitado}
          />
        );
      })}
    </section>
  );
};

export default MenuOpcoesAbas;
