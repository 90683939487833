import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';

import { Button, withStyles } from '@material-ui/core';
import { RootState } from '../../../../store/reducer';
import useStyles from '../../styles/chatResposta';

import { Recusar } from '../../redux/chatNegociacaoReducer';
import { TypographyPadrao } from '../../../../shared/components/typography/TypographyPadrao';
import ModalReabrirNegociacao from '../../modals/ModalReabrirNegociacao';

const BotaoReiniciar = withStyles((theme) => ({
  root: {
    borderRadius: 50,
    textTransform: 'none',
    fontSize: '14px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontStyle: 'normal',
    color: theme.palette.cores.branco,
    width: '300px',
    alignSelf: 'center',
    padding: '10px 22px',
    marginTop: '20px',
  },
}))(Button);

const ReabrirNegociacao = (): JSX.Element => {
  const classes = useStyles();

  const [
    modalReabrirNegociacaoAberto,
    setmodalReabrirNegociacaoAberto,
  ] = useState(false);
  const { chatExibicaoRespostaNegociador } = useSelector(
    (state: RootState) => state.chatNegociacaoReducer,
  );

  const exibirComponenteRecusar = chatExibicaoRespostaNegociador === Recusar;

  return (
    <Box className={classes.boxResposta}>
      <Box className={classes.boxReabrirNegociacao}>
        <TypographyPadrao>
          Você deseja reabrir essa negociação?
        </TypographyPadrao>
        <BotaoReiniciar
          aria-label="Reabrir negociacao"
          variant="contained"
          color="primary"
          disabled={exibirComponenteRecusar}
          onClick={() => setmodalReabrirNegociacaoAberto((state) => !state)}
        >
          Reabrir negociação
        </BotaoReiniciar>
        <ModalReabrirNegociacao
          modalContrapropostaAberto={modalReabrirNegociacaoAberto}
          setModalContrapropostaAberto={setmodalReabrirNegociacaoAberto}
        />
      </Box>
    </Box>
  );
};

export default ReabrirNegociacao;
