import { AxiosResponse, AxiosError } from 'axios';
import { refreshToken } from '../../features/login/api/loginManager';
import store from '../../store';

import {
  ErrorResponse,
  HttpStatusCode,
  CLIENT_ERROR_4XX,
  SERVER_ERROR_5XX,
  CODIGO_ERRO_NAO_AUTORIZADO,
} from '../types/apiTypes';
import mensagemErroApi, {
  SISTEMA_INDISPONIVEL,
} from '../constants/MensagemErroApi';
import { fazerLogout } from '../../features/login/redux/loginOperation';
import { abrirSnackbarErro } from '../components/snackbar/redux/snackbarAction';

const obterTipoErro = (status: number): HttpStatusCode =>
  CLIENT_ERROR_4XX === String(status).substring(0, 1)
    ? CLIENT_ERROR_4XX
    : SERVER_ERROR_5XX;

const obterMensagensErros = (erros: string[]): string =>
  erros ? erros.join('\n') : SISTEMA_INDISPONIVEL;

const obterDescricaoErro = (response: AxiosResponse<ErrorResponse>): string => {
  const { data, status } = response;

  return CLIENT_ERROR_4XX === obterTipoErro(status)
    ? obterMensagensErros(data.mensagens)
    : SISTEMA_INDISPONIVEL;
};

const verificarErroNaoAutorizado = (error: AxiosError<ErrorResponse>) =>
  error?.response?.status === CODIGO_ERRO_NAO_AUTORIZADO;

export const tratarErroConexao = (error: unknown): never => {
  const { response } = error as AxiosError<ErrorResponse>;
  throw new Error(
    response ? obterDescricaoErro(response) : SISTEMA_INDISPONIVEL,
  );
};

const atualizarTokens = async (conectar: () => Promise<AxiosResponse>) => {
  try {
    await refreshToken();
  } catch (e) {
    fazerLogout()(store.dispatch);
    store.dispatch(
      abrirSnackbarErro({
        mensagem: mensagemErroApi.PROBLEMAS_ATUALIZACAO_TOKEN,
      }),
    );
    return Promise.reject(
      new Error(mensagemErroApi.PROBLEMAS_ATUALIZACAO_TOKEN),
    );
  }
  return conectar().catch(tratarErroConexao);
};

export const tratarRefreshToken = async (
  error: AxiosError<ErrorResponse>,
  conectar: () => Promise<AxiosResponse>,
): Promise<AxiosResponse> => {
  if (verificarErroNaoAutorizado(error)) return atualizarTokens(conectar);
  return tratarErroConexao(error);
};
