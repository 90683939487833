import React, { useMemo, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';
import { KeyboardArrowDownRounded } from '@material-ui/icons';
import estilosTabela from './styles/estilosTabela';
import { DadosCabecalho } from './types/TabelaTypes';
import CustomTooltip from '../tooltip/CustomTooltip';

const useStyles = makeStyles((theme: Theme) => ({
  ordenacao: {
    display: 'flex',
    columnGap: spacingInPixels(theme, 0.75),
    alignItems: 'center',
    transition: 'filter 0.5s ease-in-out',
    borderRadius: spacingInPixels(theme, 1),
    backgroundColor: theme.palette.cores.branco,
    '& > span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '90%',
    },
    '&[data-ordenacao=true]': {
      cursor: 'pointer',
      '& > span': {
        width: 'fit-content',
      },
    },
    '&[data-ordenacao=true]:hover': {
      filter: 'brightness(0.9)',
    },
    '& > svg[data-crescente=true]': {
      transform: 'rotate(180deg)',
    },
    '& > svg[data-selecionado=true]': {
      fill: theme.palette.cores.azul[500],
    },
  },
}));

export interface ItemCabecalhoComOrdenacaoProps {
  item: DadosCabecalho;
  onClickOrdenacao: (chave: string, sentidoOrdenacaoCrescente: boolean) => void;
  chaveOrdenacaoAtual?: string;
}

const ItemCabecalhoComOrdenacao = ({
  item,
  onClickOrdenacao,
  chaveOrdenacaoAtual,
}: ItemCabecalhoComOrdenacaoProps): JSX.Element => {
  const classes = useStyles();
  const { fonteCabecalho, celulaCabecalho } = estilosTabela();

  const [sentidoOrdenacaoCrescente, setSentidoOrdenacaoCrescente] = useState(
    false,
  );

  const handleClickOrdenacao = () => {
    setSentidoOrdenacaoCrescente((valorAnterior) => !valorAnterior);
    onClickOrdenacao(item?.chave || '', sentidoOrdenacaoCrescente);
  };

  useMemo(() => {
    setSentidoOrdenacaoCrescente(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomTooltip title={item?.descricao || ''}>
      <th
        className={`${classes.ordenacao} ${fonteCabecalho} ${celulaCabecalho}`}
        data-ordenacao={item.possuiOrdenacao}
        onClick={() => item.possuiOrdenacao && handleClickOrdenacao()}
      >
        {item.conteudo}
        {item.possuiOrdenacao && (
          <KeyboardArrowDownRounded
            data-crescente={sentidoOrdenacaoCrescente}
            data-selecionado={item.chave === chaveOrdenacaoAtual}
          />
        )}
      </th>
    </CustomTooltip>
  );
};

export default ItemCabecalhoComOrdenacao;
