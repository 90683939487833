import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import BoxWhiteCustomScrollTable from '../../../shared/components/box/BoxWhiteCustomScrollTable';
import TabelaTitulo from './TabelaTitulo';
import TabelaItem from './TabelaItem';
import { TabelaListaCargasProps } from '../types/TabelaListaCargas';
import { TableContainer, useStyles } from '../styles/tabelaListaCargas';

const TabelaListaCargas = ({
  cargas,
  colaboradoresData,
}: TabelaListaCargasProps): JSX.Element => {
  const classes = useStyles();

  return (
    <BoxWhiteCustomScrollTable>
      <TableContainer>
        <Table stickyHeader size="small" aria-label="Tabela - Lista Cargas">
          <TableHead className={classes.cabecalho}>
            <TabelaTitulo cargas={cargas} />
          </TableHead>
          <TableBody>
            {cargas.map((carga) => (
              <TabelaItem
                key={carga.id}
                carga={carga}
                colaboradoresData={colaboradoresData ?? []}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </BoxWhiteCustomScrollTable>
  );
};

export default TabelaListaCargas;
