import { makeStyles } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '87% 1fr',
    alignItems: 'center',
    gap: spacingInPixels(theme, 1.5),
    height: 'fit-content',
    '& > button': {
      height: 'fit-content',
    },
    justifyContent: 'space-between',
  },
  boxFlex: {
    display: 'flex',
    alignItems: 'center',
    gap: spacingInPixels(theme, 1.5),
  },
  titulo: {
    fontFamily: 'Rubik',
    fontSize: spacingInPixels(theme, 2.5),
    fontWeight: 400,
    lineHeight: spacingInPixels(theme, 2.5),
    letterSpacing: '0.4px',
    margin: 'unset',
  },
  iconeTitulo: {
    borderRadius: '50%',
    padding: spacingInPixels(theme, 1.5),
  },
}));
