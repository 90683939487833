/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/require-default-props */
import {
  Box,
  CircularProgress,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'grid',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

type CarregandoProps = {
  className?: string;
  size?: string | number;
  color?: 'primary' | 'secondary' | 'inherit';
};

const Carregando = ({
  className,
  size,
  color = 'primary',
}: CarregandoProps) => {
  const classes = useStyles();

  return (
    <Box className={clsx(className, classes.container)}>
      <CircularProgress color={color} size={size} />
    </Box>
  );
};

export default Carregando;
