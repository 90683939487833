import {
  Box,
  Divider,
  makeStyles,
  Typography,
  withStyles,
} from '@material-ui/core';
import React from 'react';
import CustomSkeleton from 'shared/components/skeleton/CustomSkeleton';
import spacingInPixels from 'shared/functions/materialUtils';

import DadosRota from './DadosRota';
import Justificativa from './Justificativa';
import DadosMotorista from './DadosMotorista';
import {
  Carga,
  Rota,
  Motorista,
  Proposta,
} from '../../api/detalhesAprovacaoSupervisorTypes';

export type IndicadoresMotoristaRotaProps = {
  carga?: Carga;
  rota?: Rota;
  motorista?: Motorista;
  proposta?: Proposta;
  valorSugeridoRobo?: boolean;
  carregando: boolean;
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: 'fit-content',
    borderRadius: spacingInPixels(theme, 1),
    padding: spacingInPixels(theme, 2),
    backgroundColor: theme.palette.cores.branco,
    boxShadow: '0px 1px 2px 0px rgb(0 0 0 / 20%)',
    borderBottom: `1px solid ${theme.palette.cores.cinza[600]}`,
  },
  divider: {
    marginTop: spacingInPixels(theme, 2),
    marginBottom: spacingInPixels(theme, 2),
  },
  skeletonJustificativa: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacingInPixels(theme, 2),
  },
}));

const SubtituloJustificativa = withStyles((theme) => ({
  root: {
    fontSize: spacingInPixels(theme, 2),
    fontWeight: 400,
    color: theme.palette.cores.cinza[900],
    marginBottom: spacingInPixels(theme, 2),
    marginLeft: 12,
  },
}))(Typography);

const IndicadoresMotoristaRota = ({
  carga,
  rota,
  motorista,
  proposta,
  valorSugeridoRobo,
  carregando,
}: IndicadoresMotoristaRotaProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Box className={`${classes.container} ${IndicadoresMotoristaRota.name}`}>
      {carregando ? (
        <div className={classes.skeletonJustificativa}>
          <CustomSkeleton
            width="30%"
            height={24}
            borderradius={16}
            key="skeleton-label-justificativa"
          />
          <CustomSkeleton
            width="100%"
            height={72}
            borderradius={16}
            key="skeleton-box-justificativa"
          />
        </div>
      ) : (
        <>
          <SubtituloJustificativa>Justificativa</SubtituloJustificativa>
          <Justificativa
            texto={proposta?.justificativaAprovacao || '-'}
            valorSugeridoRobo={valorSugeridoRobo}
          />
        </>
      )}
      <Divider className={classes.divider} orientation="horizontal" />

      <DadosRota
        carga={carga}
        rota={rota}
        valorProposta={proposta?.valorProposta || 0}
        carregando={carregando}
      />
      <Divider className={classes.divider} orientation="horizontal" />

      <DadosMotorista
        motorista={motorista}
        valorProposta={proposta?.valorProposta}
        carregando={carregando}
      />
    </Box>
  );
};

export default IndicadoresMotoristaRota;
