import React, { useMemo } from 'react';
import { Grid, makeStyles, useTheme } from '@material-ui/core';
import BigNumber from 'shared/components/bigNumber/BigNumber';
import IconeExclamacaoOctogono from 'assets/icones/IconeExclamacaoOctogono';
import IconeCaixa from 'assets/icones/IconeCaixa';
import IconeRelogio from 'assets/icones/IconeRelogio';
import IconeCorretoQuadrado from 'assets/icones/IconeCorretoQuadrado';
import IconeBalaoChat from 'assets/icones/IconeBalaoChat';
import StatusDemandaEnum from 'shared/constants/StatusDemandaEnum';
import IconeReprovar from 'assets/icones/IconeReprovar';

const useStyles = makeStyles(() => ({
  containerMetricas: {
    '& > div': {
      width: '18%',
    },
  },
}));

export type MetricasDemanda = {
  chave: string;
  valor?: number;
};

type DemandasDoDiaType = {
  icone: JSX.Element;
  cor: string;
  descricao: string;
  tipo: StatusDemandaEnum | null;
  selecionado: boolean;
};

export interface MetricasDemandasProps {
  metricasDemandas?: MetricasDemanda[];
  filtroStatus?: StatusDemandaEnum | null;
  setFiltroStatus: (novoFiltro: StatusDemandaEnum | null) => void;
  isAutomatizado: boolean;
}

const MetricasDemandas = ({
  metricasDemandas,
  filtroStatus,
  setFiltroStatus,
  isAutomatizado,
}: MetricasDemandasProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();

  const dadosBigNumbers: {
    [chave: string]: {
      icone: JSX.Element;
      cor: string;
      descricao: string;
      tipo: StatusDemandaEnum | null;
      selecionado: boolean;
    };
  } = useMemo(() => {
    let baseObject: { [chave: string]: DemandasDoDiaType } = {
      DemandasDoDia: {
        icone: <IconeCaixa width={15} height={15} />,
        cor: theme.palette.cores.azul[500] as string,
        descricao: 'Demandas do dia',
        tipo: null,
        selecionado: filtroStatus === null,
      },
      Negociadas: {
        icone: <IconeCorretoQuadrado width={15} height={15} />,
        cor: theme.palette.cores.verdeClaro[500] as string,
        descricao: 'Negociadas',
        tipo: StatusDemandaEnum.Concluida,
        selecionado: filtroStatus === StatusDemandaEnum.Concluida,
      },
      EmNegociacao: {
        icone: <IconeBalaoChat width={15} height={15} />,
        cor: theme.palette.cores.azulPiscina[400] as string,
        descricao: 'Em Negociação',
        tipo: StatusDemandaEnum.EmNegociacao,
        selecionado: filtroStatus === StatusDemandaEnum.EmNegociacao,
      },
      NaoIniciadas: {
        icone: <IconeRelogio transform="scale(-1,1)" width={15} height={15} />,
        cor: theme.palette.cores.mostarda[800] as string,
        descricao: 'Não Iniciadas',
        tipo: StatusDemandaEnum.NaoIniciada,
        selecionado: filtroStatus === StatusDemandaEnum.NaoIniciada,
      },
      Aprovacao: {
        icone: <IconeExclamacaoOctogono width={15} height={15} />,
        cor: theme.palette.cores.azulPiscina[900] as string,
        descricao: 'Aprovação',
        tipo: StatusDemandaEnum.EmAprovacao,
        selecionado: filtroStatus === StatusDemandaEnum.EmAprovacao,
      },
    };

    if (isAutomatizado) {
      baseObject = {
        EmNegociacao: {
          icone: <IconeBalaoChat width={15} height={15} />,
          cor: theme.palette.cores.azulPiscina[400] as string,
          descricao: 'Em Negociação',
          tipo: StatusDemandaEnum.EmNegociacao,
          selecionado: filtroStatus === StatusDemandaEnum.EmNegociacao,
        },
        Negociadas: {
          icone: <IconeCorretoQuadrado width={15} height={15} />,
          cor: theme.palette.cores.verdeClaro[500] as string,
          descricao: 'Negociadas',
          tipo: StatusDemandaEnum.Concluida,
          selecionado: filtroStatus === StatusDemandaEnum.Concluida,
        },
        Recusadas: {
          icone: <IconeReprovar width={15} height={15} />,
          cor: theme.palette.cores.vermelho[800] as string,
          descricao: 'Recusadas',
          tipo: StatusDemandaEnum.Recusada,
          selecionado: filtroStatus === StatusDemandaEnum.Recusada,
        },
        NaoIniciadas: {
          icone: (
            <IconeRelogio transform="scale(-1,1)" width={15} height={15} />
          ),
          cor: theme.palette.cores.mostarda[800] as string,
          descricao: 'Não Iniciadas',
          tipo: StatusDemandaEnum.NaoIniciada,
          selecionado: filtroStatus === StatusDemandaEnum.NaoIniciada,
        },
      };
    }

    return baseObject;
  }, [
    theme.palette.cores.azul,
    theme.palette.cores.verdeClaro,
    theme.palette.cores.azulPiscina,
    theme.palette.cores.mostarda,
    theme.palette.cores.vermelho,
    filtroStatus,
    isAutomatizado,
  ]);

  return (
    <Grid
      container
      className={classes.containerMetricas}
      justify="space-between"
    >
      {metricasDemandas?.map(({ chave, valor }) => {
        const demanda = { ...dadosBigNumbers[chave], valor };
        return (
          <Grid item key={`big-number-${demanda.descricao}`}>
            <BigNumber
              icone={demanda.icone}
              cor={demanda.cor}
              numero={demanda.valor}
              descricao={demanda.descricao}
              selecionado={demanda.selecionado}
              onClick={() => setFiltroStatus(demanda.tipo)}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default MetricasDemandas;
