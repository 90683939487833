import { useTheme } from '@material-ui/core';
import * as React from 'react';
import { SVGProps } from 'react';

const IconeExclamacaoOctogono = ({
  fill,
  width,
  height,
  ...outros
}: SVGProps<SVGSVGElement>): JSX.Element => {
  const theme = useTheme();
  return (
    <svg
      width={width || 12}
      height={height || 12}
      viewBox="0 0 12 12"
      fill="none"
      {...outros}
    >
      <path
        d="M5.9 3.083a.583.583 0 0 0-.584.584V6a.583.583 0 0 0 1.167 0V3.667a.583.583 0 0 0-.583-.584Zm0 4.667a.583.583 0 1 0 0 1.167.583.583 0 0 0 0-1.167Zm5.664-4.34L8.49.336a.613.613 0 0 0-.415-.17H3.724a.612.612 0 0 0-.414.17L.235 3.41a.613.613 0 0 0-.169.414v4.352a.613.613 0 0 0 .17.414l3.074 3.074c.111.107.26.167.414.17h4.351a.613.613 0 0 0 .415-.17l3.074-3.074a.613.613 0 0 0 .169-.414V3.824a.613.613 0 0 0-.17-.414Zm-.998 4.52L7.83 10.668H3.97L1.233 7.93V4.069l2.736-2.736H7.83l2.736 2.736v3.862Z"
        fill={fill || theme.palette.cores.azulPiscina[900]}
      />
    </svg>
  );
};

export default IconeExclamacaoOctogono;
