import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { spacingInPixels } from '../../../shared/functions/materialUtils';
import TableCell from '../../../shared/components/table/TableCellCustomPadding';

const BoxItemTitulo = withStyles((theme) => ({
  root: {
    '& img': {
      marginRight: spacingInPixels(theme, 1),
    },
    '& span': {
      fontWeight: 500,
      color: theme.palette.primary.main,
    },
  },
}))(Box);

type TabelaTituloColunaProps = {
  nome: string;
  caminhoIcone: string;
};

const TabelaTituloColuna = ({
  nome,
  caminhoIcone,
}: TabelaTituloColunaProps): JSX.Element => {
  return (
    <TableCell>
      <BoxItemTitulo display="flex" alignItems="center">
        <img src={caminhoIcone} alt={nome} />
        <span>{nome}</span>
      </BoxItemTitulo>
    </TableCell>
  );
};

export default TabelaTituloColuna;
