import { makeStyles } from '@material-ui/core';
import theme from '../../../theme';

export const StylesChip = makeStyles(() => ({
  chipStyled: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 400,
    borderRadius: '16px',
    padding: '3px 12px',
    cursor: 'pointer',
    lineHeight: '20px',
    outline: 'none',
    borderStyle: 'none',
  },
  ativo: {
    backgroundColor: theme.palette.cores.azul[600],
    color: theme.palette.cores.branco,
  },
  inativo: {
    backgroundColor: theme.palette.cores.cinza[300],
  },
}));
