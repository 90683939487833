import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import Slide from '@material-ui/core/Slide';
import { useTheme } from '@material-ui/core';

import { abrirSnackbarNotificacao } from 'shared/components/snackbar/redux/snackbarAction';
import { RootState } from '../../../store/reducer';

import NotificacoesCabecalho from './NotificacoesCabecalho';
import Notificacao from './Notificacao';
import BoxScroll from '../../../shared/components/box/BoxScroll';
import { fecharNotificacoes } from '../utils/notificacoes';

import BotaoVejaMais from './BotaoVejaMais';
import { INotificacoes, NotificacaoType } from '../types/notificacoes';
import { obterPaginaNotificacoes } from '../redux/notificacoesOperation';
import {
  atualizarNotificacao,
  limparNotificacao,
} from '../api/notificacoesManager';

const Notificacoes = ({ altura }: INotificacoes): JSX.Element => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState<boolean>(false);
  const [filtrados, setFiltrados] = useState<NotificacaoType[]>([]);
  const [useNotificacoes, setUseNotificacoes] = useState<NotificacaoType[]>([]);
  const [mostrarNotificacao, setMostrarNotificacao] = useState(false);

  const { notificacoesAbertas, notificacoes } = useSelector(
    (state: RootState) => state.notificacoesReducer,
  );

  const handleLimparNotificacao = async () => {
    await limparNotificacao();
    fecharNotificacoes(dispatch);
  };

  useEffect(() => {
    if (filter) {
      obterPaginaNotificacoes(1, true)(dispatch);
      setFiltrados(notificacoes.filter((item) => item.visualizada === true));
    } else {
      obterPaginaNotificacoes(1, false)(dispatch);
      setFiltrados(notificacoes.filter((item) => item.visualizada === false));
    }

    const newUseNotificacoes = notificacoes.filter(
      (notificacao) =>
        notificacao.titulo?.includes('frete tabelado') &&
        notificacao.visualizada === false,
    );

    setUseNotificacoes(newUseNotificacoes);
  }, [dispatch, filter, notificacoes]);

  useEffect(() => {
    if (useNotificacoes.length > 0) {
      setMostrarNotificacao(true);
      dispatch(
        abrirSnackbarNotificacao({
          mensagem: `Carga está com prioridade para ser negociada.`,
          quantidade: useNotificacoes.length,
          notificacaoSnackBar: true,
          onClose: () => {
            useNotificacoes.forEach(async (notificacao) => {
              await atualizarNotificacao(notificacao.idNotificacao);
            });

            setUseNotificacoes([]);
            setMostrarNotificacao(false);
          },
        }),
      );
    }
  }, [dispatch, filtrados, mostrarNotificacao, notificacoes, useNotificacoes]);

  return (
    <>
      <Backdrop
        open={notificacoesAbertas}
        onClick={() => fecharNotificacoes(dispatch)}
        style={{
          zIndex: 1098,
          backgroundColor: `${theme.palette.cores.cinza[900]}30`,
        }}
      />
      <Slide in={notificacoesAbertas} direction="left" timeout={250}>
        <Box
          bottom="0"
          right="0"
          height={altura ? 'calc(100vh - 60px)' : 'calc(100vh - 40px)'}
          width="332px"
          bgcolor="cores.branco"
          zIndex={1099}
          position="fixed"
        >
          <NotificacoesCabecalho
            hasNotificacao={filtrados.length > 0}
            setFilter={setFilter}
            handleLimparNotificacao={handleLimparNotificacao}
          />
          <BoxScroll>
            <Box overflow="auto" height="calc(100vh - 120px)">
              {filtrados.map((notificacao) => (
                <Notificacao
                  key={notificacao.idNotificacao}
                  notificacao={notificacao}
                />
              ))}
              <BotaoVejaMais paginaFilter={filter} />
            </Box>
          </BoxScroll>
        </Box>
      </Slide>
    </>
  );
};

export default Notificacoes;
