import { Box } from '@material-ui/core';
import AgrupamentoConfiguracao from 'features/configuracoes/components/AgrupamentoConfiguracao';
import { ContainerBotoes, TextoSimples } from 'features/configuracoes/styles';
import { obterMensagemErroHorasVencimento } from 'features/configuracoes/utils/inputIntervaloTempo';
import React, { useEffect, useState } from 'react';
import {
  buscarParametrosTempo,
  salvarParametrosTempo,
} from 'features/configuracoes/utils/configuracoes';
import { ParametrosTempo } from 'features/configuracoes/api/configuracoesApiTypes';
import { useDispatch } from 'react-redux';
import { IntervaloTempo } from 'shared/types/apiTypes';
import useMutationObserver from 'shared/customHooks/useMutationObserver';
import { SugestaoRoboHabilitadaProps } from 'features/configuracoes/types';
import { useStyles } from '../../../styles/configuracoesSistema';
import InputTempo from './InputTempo';
import BotoesConfig from '../BotoesConfig';

const TorreControle = ({
  sugestaoRoboHabilitada,
}: SugestaoRoboHabilitadaProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    values,
    initial,
    setValues,
    setInitial,
    isChanged,
  } = useMutationObserver({} as ParametrosTempo);

  const [erro, setErro] = useState({
    erroTempoInativo: false,
    erroTempoAprovacao: false,
    erroHorasVencimento: false,
  });

  const handleLimparAlteracoes = () => {
    setValues(initial);
    setErro({
      erroTempoInativo: false,
      erroTempoAprovacao: false,
      erroHorasVencimento: false,
    });
  };

  const handleSalvar = () => {
    salvarParametrosTempo(
      sugestaoRoboHabilitada,
      values.tempoLimiteSemVisualizarNegociacao,
      values.tempoLimiteSemAprovacao,
      values.tempoLimiteCargaPrioridadeMaxima,
      dispatch,
    );
    setInitial(values);
  };

  useEffect(() => {
    buscarParametrosTempo(setInitial, dispatch);
  }, [dispatch, setInitial]);

  useEffect(() => {
    setValues(initial);
  }, [initial, setValues]);

  return (
    <>
      <Box className={classes.containerNotificacoes}>
        <TextoSimples className={classes.textoNotificacoes}>
          Notificações
        </TextoSimples>

        <AgrupamentoConfiguracao
          titulo="Configuração Alerta de Cargas"
          className={classes.containerAgrupamento}
        >
          <InputTempo
            estadoTempo={values?.tempoLimiteSemVisualizarNegociacao}
            setEstadoTempo={(intervalo: IntervaloTempo | undefined) =>
              intervalo &&
              setValues((anterior) => ({
                ...anterior,
                tempoLimiteSemVisualizarNegociacao: intervalo,
              }))
            }
            id="input-configuracao-tempo-inativo"
            descricao="Não vista há mais de:"
            label="Tempo Inativo"
            textoAjuda="Campo Obrigatório"
            estadoErroTempo={erro.erroTempoInativo}
            setEstadoTempoErro={(estado: boolean) =>
              setErro((anterior) => ({
                ...anterior,
                erroTempoInativo: estado,
              }))
            }
          />
          <InputTempo
            estadoTempo={values?.tempoLimiteSemAprovacao}
            setEstadoTempo={(intervalo: IntervaloTempo | undefined) =>
              intervalo &&
              setValues((anterior) => ({
                ...anterior,
                tempoLimiteSemAprovacao: intervalo,
              }))
            }
            id="input-configuracao-tempo-aprovacao"
            descricao="Sem aprovação há:"
            label="Tempo de Aprovação"
            textoAjuda="Campo Obrigatório"
            estadoErroTempo={erro.erroTempoAprovacao}
            setEstadoTempoErro={(estado: boolean) =>
              setErro((anterior) => ({
                ...anterior,
                erroTempoAprovacao: estado,
              }))
            }
          />
          <InputTempo
            estadoTempo={values?.tempoLimiteCargaPrioridadeMaxima}
            setEstadoTempo={(intervalo: IntervaloTempo | undefined) =>
              intervalo &&
              setValues((anterior) => ({
                ...anterior,
                tempoLimiteCargaPrioridadeMaxima: intervalo,
              }))
            }
            id="input-configuracao-horas-vencimento"
            descricao="Alerta antes do vencimento:"
            label="Horas antes de vencer"
            textoAjuda={`${obterMensagemErroHorasVencimento(
              values?.tempoLimiteCargaPrioridadeMaxima,
            )}`}
            estadoErroTempo={erro.erroHorasVencimento}
            setEstadoTempoErro={(estado: boolean) =>
              setErro((anterior) => ({
                ...anterior,
                erroHorasVencimento: estado,
              }))
            }
          />
        </AgrupamentoConfiguracao>
      </Box>
      <ContainerBotoes>
        <BotoesConfig
          disabledLimpar={!isChanged}
          disabledSalvar={
            erro.erroTempoInativo ||
            erro.erroTempoAprovacao ||
            erro.erroHorasVencimento ||
            !isChanged
          }
          limparFuncao={handleLimparAlteracoes}
          salvarFuncao={handleSalvar}
        />
      </ContainerBotoes>
    </>
  );
};

export default TorreControle;
