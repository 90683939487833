import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import IconeCorreto from '../../../assets/icones/IconeCorreto';

import spacingInPixels from '../../../shared/functions/materialUtils';

export type CheckboxAbaType = {
  texto: string;
  selecionado: boolean;
  onClick: () => void;
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.cores.cinza[300],
    color: theme.palette.cores.cinza[900],
    borderRadius: spacingInPixels(theme, 2),
    padding: spacingInPixels(theme, 1),
    cursor: 'pointer',
    marginRight: spacingInPixels(theme, 0.8),
    '&:hover': {
      backgroundColor: theme.palette.cores.cinza[400],
    },
  },
  containerSelecionado: {
    backgroundColor: theme.palette.cores.azulMartins[500],
    color: theme.palette.cores.branco,
    '&:hover': {
      backgroundColor: theme.palette.cores.azulMartins[700],
    },
  },
  icone: {
    marginRight: spacingInPixels(theme, 1),
  },
  texto: {
    fontSize: '12px',
    fontWeight: 400,
  },
}));

const CheckboxAba = ({
  texto,
  selecionado,
  onClick,
}: CheckboxAbaType): JSX.Element => {
  const classes = useStyles();
  const estiloContainer = selecionado && classes.containerSelecionado;
  return (
    <Box
      className={`${classes.container} ${estiloContainer}`}
      onClick={onClick}
    >
      {selecionado && <IconeCorreto className={classes.icone} />}
      <Typography className={classes.texto}>{texto}</Typography>
    </Box>
  );
};

export default CheckboxAba;
