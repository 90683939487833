import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';

import TableCell from '../../../shared/components/table/TableCellCustomPadding';

type TabelaTituloImagemProps = {
  nome: string;
  caminhoIcone: string;
};

const TabelaTituloImagem = ({
  nome,
  caminhoIcone,
}: TabelaTituloImagemProps): JSX.Element => {
  return (
    <TableCell align="center">
      <Tooltip title={nome}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <img src={caminhoIcone} alt={nome} />
        </Box>
      </Tooltip>
    </TableCell>
  );
};

export default TabelaTituloImagem;
