import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useLoading } from 'shared/customHooks/useLoading';
import { buscarDadosPermissaoUsuario } from 'features/login/redux/loginOperation';
import { NegociacaoAndamento } from '../api/demandasAndamentoApiTypes';

import { buscarDemandasAndamento } from '../utils/demandasAndamento';
import HeaderDemandasAndamento from './HeaderDemandasAndamento';
import ContainerTabelaDemandasAndamento from './tabelaDemandasAndamento/ContainerTabelaDemandasAndamento';

const DemandasAndamento = (): JSX.Element => {
  const dispatch = useDispatch();
  const [demandas, setDemandas] = useState<NegociacaoAndamento[]>();

  const buscaDemandasCallback = useCallback(
    async () => buscarDemandasAndamento(setDemandas)(dispatch),
    [setDemandas, dispatch],
  );

  const [carregando, buscarDemandasLoading] = useLoading(buscaDemandasCallback);

  useEffect(() => {
    buscarDadosPermissaoUsuario()(dispatch);
  }, [dispatch]);

  useEffect(() => {
    buscarDemandasLoading();
  }, [dispatch, buscarDemandasLoading]);

  return (
    <>
      <HeaderDemandasAndamento
        existemDemandas={!!demandas && demandas?.length > 0}
      />
      <ContainerTabelaDemandasAndamento
        demandas={demandas}
        setDemandas={setDemandas}
        carregando={carregando}
      />
    </>
  );
};

export default DemandasAndamento;
