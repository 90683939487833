import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import InputAdornment from '@material-ui/core/InputAdornment';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, makeStyles, Theme, useTheme } from '@material-ui/core';

import { WithStylesOptions } from '@material-ui/core/styles/withStyles';
import IconeFechar from 'assets/icones/IconeFechar';
import TextField from 'shared/components/input/TextField';
import debounce from 'shared/functions/debounce';

import { Rubik } from 'shared/constants/font';
import spacingInPixels from 'shared/functions/materialUtils';
import { atualizarPesquisa } from './redux/motoristasCardAction';

const Pesquisa = withStyles<
  'root',
  WithStylesOptions<Theme>,
  { empty?: boolean; mostrarAba?: boolean }
>((theme) => ({
  root: {
    width: 'auto',
    '& .MuiOutlinedInput-input': {
      padding: ({ empty, mostrarAba }) =>
        empty && mostrarAba ? 0 : '15px 14px',
      width: ({ empty, mostrarAba }) => (empty && mostrarAba ? 0 : '100%'),
      fontFamily: Rubik,
      fontWeight: 400,
      '&::placeholder': {
        color: theme.palette.text.secondary,
        fontFamily: Rubik,
        fontWeight: 400,
      },
    },
    '& .MuiInputLabel-outlined': {
      '&:not(.MuiInputLabel-shrink)': {
        transform: 'translate(14px, 12px) scale(1)',
      },
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: 50,
        borderColor: theme.palette.cores.cinza[500],
      },
      height: 40,
    },
    '& .MuiInputAdornment-positionStart': {
      width: `fit-content`,
      height: 'fit-content',
      padding: '14px',
      margin: '0',
    },
    '& .MuiOutlinedInput-adornedStart': {
      padding: '0',
    },
    fontFamily: Rubik,
    height: 40,
    minHeight: 40,
    maxHeight: 40,
    marginBottom: spacingInPixels(theme, 2),
  },
}))(TextField);

const useStyles = makeStyles(() => ({
  pesquisarMax: {
    width: '100%',
  },
}));

type CampoPesquisaMotoristasProps = {
  mostrarAba: boolean;
  valorPesquisa: string;
  setMostrarAba: React.Dispatch<React.SetStateAction<boolean>>;
  setValorPesquisa: React.Dispatch<React.SetStateAction<string>>;
};

const CampoPesquisaMotoristas = ({
  mostrarAba,
  valorPesquisa,
  setMostrarAba,
  setValorPesquisa,
}: CampoPesquisaMotoristasProps): JSX.Element => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const estilo = useStyles();
  const textInput = useRef<HTMLInputElement>(null);

  const pesquisar = (textoPesquisa: string) => {
    dispatch(atualizarPesquisa(textoPesquisa));
  };

  const debounced = debounce(pesquisar, 300);

  const onClickFocus = () => {
    textInput.current?.focus();
    setMostrarAba(false);
  };

  const onBlurClose = () => {
    if (!valorPesquisa.length) setMostrarAba(true);
  };

  const handleClickClear = useCallback(() => {
    if (valorPesquisa.length) setValorPesquisa('');
    else setMostrarAba(true);
  }, [setMostrarAba, setValorPesquisa, valorPesquisa.length]);

  useEffect(() => {
    const handleEsc = (event: { keyCode: number }) => {
      if (event.keyCode === 27) {
        handleClickClear();
      }
    };

    window.addEventListener('keydown', handleEsc);

    return () => {
      dispatch(atualizarPesquisa(''));
      window.removeEventListener('keydown', handleEsc);
    };
  }, [dispatch, handleClickClear]);

  return (
    <Pesquisa
      id="Pesquisa"
      placeholder="Pesquise agregados"
      variant="outlined"
      value={valorPesquisa}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setValorPesquisa(event.target.value);
        debounced(event.target.value);
      }}
      mostrarAba={mostrarAba}
      empty={!textInput.current?.value?.length}
      onBlur={() => onBlurClose()}
      className={!mostrarAba ? estilo.pesquisarMax : ''}
      InputProps={{
        inputProps: { maxLength: 100 },
        inputRef: textInput,
        startAdornment: (
          <InputAdornment position="start" onClick={() => onClickFocus()}>
            <SearchRoundedIcon
              style={{ color: theme.palette.cores.cinza[500] }}
            />
          </InputAdornment>
        ),
        style: {
          backgroundColor: theme.palette.cores.branco,
          borderRadius: 50,
        },
        endAdornment: (
          <>
            {!mostrarAba && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickClear}
                >
                  <IconeFechar />
                </IconButton>
              </InputAdornment>
            )}
          </>
        ),
      }}
    />
  );
};

export default CampoPesquisaMotoristas;
