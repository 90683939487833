import { useTheme } from '@material-ui/core';
import * as React from 'react';
import { SVGProps } from 'react';

const IconeOlho = ({
  fill,
  ...outros
}: SVGProps<SVGSVGElement>): JSX.Element => {
  const theme = useTheme();
  return (
    <svg
      width={16}
      height={14}
      viewBox="0 0 11 9"
      fill={fill || 'none'}
      {...outros}
    >
      <path
        d="M10.96 3.8C9.95 1.455 8.05 0 6 0S2.05 1.455 1.04 3.8a.5.5 0 0 0 0 .4C2.05 6.545 3.95 8 6 8s3.95-1.455 4.96-3.8a.5.5 0 0 0 0-.4ZM6 7C4.415 7 2.915 5.855 2.05 4 2.915 2.145 4.415 1 6 1s3.085 1.145 3.95 3C9.085 5.855 7.585 7 6 7Zm0-5a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 3a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
        fill={fill || theme.palette.cores.cinza[600]}
      />
    </svg>
  );
};

export default IconeOlho;
