import { Box, CircularProgress, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useStyles } from './styles';
import { pegarIntervalo } from './functions';
import CustomTooltip from '../tooltip/CustomTooltip';

const TimerTrintaMinutos = (): JSX.Element => {
  const estilo = useStyles();
  const [time, setTime] = useState<number>(pegarIntervalo(new Date()));

  useEffect(() => {
    let intervalo: NodeJS.Timeout;
    if (time > 0) {
      intervalo = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    } else {
      setTime(pegarIntervalo(new Date()));
    }
    return () => clearInterval(intervalo);
  }, [time]);

  return (
    <CustomTooltip
      title="Tempo para sincronizar tarifário ao TMS "
      placement="bottom"
      fontSize={14}
      maxWidth="200px"
      textAlign="center"
      arrow
    >
      <Typography variant="h6" className={estilo.minuteText}>
        <Box style={{ position: 'relative' }}>
          <CircularProgress
            variant="determinate"
            className={estilo.inIconColor}
            size={12}
            thickness={6}
            value={100}
          />
          <CircularProgress
            variant="determinate"
            className={estilo.iconColor}
            value={((time / 60) * 100) / 30}
            size={12}
            thickness={6}
          />
        </Box>
        {Math.floor(time / 60)}:{time % 60 < 10 ? `0${time % 60}` : time % 60}
      </Typography>
    </CustomTooltip>
  );
};

export default TimerTrintaMinutos;
