import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Divider, Grid, makeStyles, withStyles } from '@material-ui/core';
import SugestaoRobo from 'features/chatNegociacao/components/SugestaoRobo';
import ChatNegociacao from 'features/chatNegociacao';

import { RootState } from 'store/reducer';

import { OrigemNegociacao } from 'features/informacoesNegociacao/api/informacoesNegociacaoApiTypes';
import { buscarParametrosTempo } from 'features/configuracoes/utils/configuracoes';
import { ParametrosTempo } from 'features/configuracoes/api/configuracoesApiTypes';
import MotoristasAbasCard from './MotoristasAbasCard';
import ValoresEstaticos from '../valoresCard/ValoresEstaticos';

import { atualizarTodosValores } from '../valoresCard/redux/valoresCardAction';
import ModalEditarValores from '../valoresCard/ModalEditarValores';

const useStyles = makeStyles((theme) => ({
  containerChat: {
    boxShadow: 'inset 0px 2px 5px rgba(0, 0, 0, 0.2)',
    height: '100%',
    maxHeight: 'calc(100vh - 160px)',
    flex: '6.5',
    backgroundColor: '#fafafa',
    backgroundImage:
      'url(../../../../images/chatNegociacao/icone-martins-up-down.svg)',
  },
  motoristas: {
    height: 'calc(100vh - 160px)',
    maxHeight: 'calc(100vh - 160px)',
    flex: '5.5',
    backgroundColor: theme.palette.cores.cinza[50],
  },
  conteudo: {
    overflow: 'hidden',
    height: 'calc(100vh - 160px)',
    maxHeight: 'calc(100vh - 160px)',
  },
  valores: {
    height: 48,
    backgroundColor: '#FFFFFF;',
    display: 'flex',
    borderBottom: '1px  solid #d7d7d7',
  },
}));

const Divisor = withStyles((theme) => ({
  root: {
    width: 3,
    backgroundColor: theme.palette.cores.cinza[600],
    margin: theme.spacing(1, 0),
    marginRight: -3,
    borderRadius: 4,
    zIndex: 1,
  },
}))(Divider);

const MotoristasCard = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [modalAberto, setModalAberto] = useState<boolean>(false);
  const [parametrosTempo, setParametrosTempo] = useState<ParametrosTempo>();

  const { negociacao } = useSelector(
    (state: RootState) => state.informacoesNegociacaoReducer,
  );

  const ApresentarComponenteSugestaoRobo = Boolean(
    negociacao?.sugestaoValorInicialRobo,
  );

  const { chatAberto } = useSelector(
    (state: RootState) => state.chatNegociacaoReducer,
  );

  useEffect(() => {
    buscarParametrosTempo(setParametrosTempo, dispatch);
  }, [dispatch]);

  const abrirModal = () => {
    dispatch(
      atualizarTodosValores({
        valorFreteTerceiro: negociacao?.valorFreteTerceiro || undefined,
        valorFrotaPropria: negociacao?.valorFrotaPropria || undefined,
        valorNegociacao: negociacao?.valorNegociacao || undefined,
      }),
    );

    setModalAberto(true);
  };

  return (
    (negociacao && (
      <>
        <Grid container className={classes.conteudo}>
          <Grid item className={classes.motoristas}>
            {negociacao.origemNegociacao === OrigemNegociacao.freteTabelado ? (
              <></>
            ) : (
              <Box className={classes.valores}>
                <ValoresEstaticos frotaPropria />
                {!chatAberto && !ApresentarComponenteSugestaoRobo && (
                  <Divisor flexItem orientation="vertical" />
                )}
              </Box>
            )}
            <MotoristasAbasCard />
          </Grid>
          <Grid item className={classes.containerChat}>
            {!chatAberto &&
              !ApresentarComponenteSugestaoRobo &&
              negociacao.origemNegociacao === OrigemNegociacao.negociador && (
                <Box className={classes.valores}>
                  <ValoresEstaticos
                    frotaPropria={false}
                    abrilModalEditarValores={abrirModal}
                  />
                </Box>
              )}
            {ApresentarComponenteSugestaoRobo &&
              parametrosTempo?.sugestaoRoboHabilitada === true &&
              negociacao.origemNegociacao === OrigemNegociacao.negociador && (
                <SugestaoRobo
                  sugestaoValorInicialRobo={
                    negociacao?.sugestaoValorInicialRobo || 0
                  }
                />
              )}
            <ChatNegociacao
              altura={
                ApresentarComponenteSugestaoRobo
                  ? 'calc(100% - 60px)'
                  : 'inherit'
              }
            />
          </Grid>
        </Grid>
        <ModalEditarValores
          modalAberto={modalAberto}
          fecharModal={() => setModalAberto(false)}
        />
      </>
    )) || <></>
  );
};

export default MotoristasCard;
