import { createAction } from '@reduxjs/toolkit';

export const atualizarArquivo = createAction<File | null>(
  'uploadSimuladorAction/ATUALIZAR_ARQUIVO',
);

export const atualizarEstadoArquivoEnviado = createAction<boolean>(
  'uploadSimuladorAction/ATUALIZAR_ESTADO_ARQUIVO_ENVIADO',
);

export const limparDadosUploadSimulador = createAction<void>(
  'uploadSimuladorAction/LIMPAR_DADOS_UPLOAD_SIMULADOR',
);

export const atualizarCarregando = createAction<boolean>(
  'uploadSimuladorAction/ATUALIZAR_ESTADO_CARREGANDO',
);

export default {
  atualizarArquivo,
  atualizarEstadoArquivoEnviado,
  limparDadosUploadSimulador,
  atualizarCarregando,
};
