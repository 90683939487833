import React from 'react';
import { Box, Grid, makeStyles, Typography, useTheme } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';
import IconeLocalizacao from '../../../assets/icones/IconeLocalizacao';
import IconeCasa from '../../../assets/icones/IconeCasa';
import IconeCaixa from '../../../assets/icones/IconeCaixa';

export type NegociadorFilialType = {
  negociador: string;
  filial: string;
  cidadePrincipal: string;
  romaneio: string;
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacingInPixels(theme, 1),
    marginTop: spacingInPixels(theme, 1),
  },
  texto: {
    fontSize: '13px',
    fontWeight: 400,
    color: theme.palette.cores.cinza[900],
    fontFamily: `Rubik`,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  textoRomaneio: {
    fontSize: '13px',
    fontWeight: 400,
    color: theme.palette.cores.cinza[900],
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontFamily: `Rubik`,
  },
  linha: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icone: {
    marginRight: '4px',
  },
  iconeCaixa: {
    marginRight: '4px',
    width: '13px',
    height: '15px',
  },
}));

const NegociadorFilial = ({
  negociador,
  filial,
  cidadePrincipal,
  romaneio,
}: NegociadorFilialType): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography className={classes.texto}>
        Negociador: <strong>{negociador}</strong>
      </Typography>
      <Grid container spacing={1}>
        <Grid item sm={6}>
          <Box className={classes.linha}>
            <Box className={classes.icone}>
              <IconeLocalizacao
                width={12}
                height={15}
                fill={theme.palette.cores.azulMartins[500]}
              />
            </Box>
            <Typography className={classes.texto}>
              Cidade: <strong>{cidadePrincipal}</strong>
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={6}>
          <Box className={classes.linha}>
            <Box className={classes.icone}>
              <IconeCasa width={12} height={15} />
            </Box>
            <Typography className={classes.texto}>
              Filial: <strong>{filial}</strong>
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box className={classes.linha}>
        <Box className={classes.icone}>
          <IconeCaixa
            width={12}
            height={15}
            fill={theme.palette.cores.azulMartins[500]}
          />
        </Box>
        <Typography className={classes.textoRomaneio}>
          Nº da carga: <strong>{romaneio}</strong>
        </Typography>
      </Box>
    </Box>
  );
};

export default NegociadorFilial;
