import { Accordion, Box, makeStyles, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import CustomSkeleton from 'shared/components/skeleton/CustomSkeleton';
import IconeCalendario from '../../../assets/icones/IconeCalendario';
import IconeSetaBaixo from '../../../assets/icones/IconeSetaBaixo';
import AccordionDetails from '../../../shared/components/accordion/AccordionDetails';
import AccordionSummary from '../../../shared/components/accordion/AccordionSummary';
import { Rubik } from '../../../shared/constants/font';
import {
  Proposta,
  Negociacao,
  Carga,
  Filial,
} from '../api/detalhesAprovacaoSupervisorTypes';
import CardFilial from '../components/DetalhesProposta/CardFilial';
import InformacoesNegociador from '../components/informacoesPersona/InformacoesNegociador';
import { formatarDataHora } from '../utils/detalhesAprovacaoSupervisor';

const useStyles = makeStyles((theme) => ({
  accordion: {
    color: theme.palette.cores.cinza[100],
    borderRadius: 8,
  },
  accordionSummary: {
    color: theme.palette.cores.azulMartins[500],
    gap: '15px',
  },
  accordionDetailsBox: {
    gap: 8,
    display: 'flex',
    flexDirection: 'row',
  },
  accordionDetailsDatas: {
    backgroundColor: theme.palette.background.default,
    zIndex: 3,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'row',
    width: '60%',
    padding: 10,
    alignItems: 'start',
    justifyContent: 'space-between',
  },
  accordionDetailsNegociador: {
    backgroundColor: theme.palette.background.default,
    zIndex: 3,
    borderRadius: 8,
    width: '30%',
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    alignItems: 'end',
    alignContent: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  labelDatas: {
    color: theme.palette.cores.cinza[900],
  },
  accordionDetailsFilial: {
    '& > div': {
      width: 'auto',
    },
  },
  accordionInformacoes: {
    color: theme.palette.cores.cinza[900],
    display: 'flex',
    flexDirection: 'column',
    fontFamily: Rubik,
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 400,
    alignItems: 'start',
    justifyContent: 'start',
  },

  mascara: {
    display: 'none',
  },
  mascaraExpandida: {
    display: 'flex',
    width: '100vw',
    height: '100vh',
    backgroundColor: theme.palette.cores.cinza[900],
    opacity: '60%',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 2,
  },
  accordionBefore: {
    marginBottom: 16,
    '&:before': {
      backgroundColor: 'transparent',
    },
  },
}));

export type MaisDetalhesProps = {
  proposta?: Proposta;
  negociacao?: Negociacao;
  carga?: Carga;
  filial?: Filial;
  carregando: boolean;
};

const MaisDetalhes = ({
  negociacao,
  proposta,
  carga,
  filial,
  carregando,
}: MaisDetalhesProps): JSX.Element => {
  const [accordionExpandido, setAccordionExpandido] = useState(false);
  const alterarEstadoAccordion = () => {
    setAccordionExpandido((estadoAnterior) => !estadoAnterior);
  };
  const classes = useStyles();

  return (
    <Accordion
      className={classes.accordion}
      expanded={accordionExpandido}
      onChange={alterarEstadoAccordion}
      classes={{ root: classes.accordionBefore }}
    >
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={!carregando && <IconeSetaBaixo />}
      >
        {carregando ? (
          <CustomSkeleton
            width="15%"
            height={24}
            borderradius={16}
            key="skeleton-mais-detalhes"
          />
        ) : (
          <Typography>Mais detalhes</Typography>
        )}
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetailsBox}>
        <Box className={classes.accordionDetailsDatas}>
          <Box padding="4px">
            <IconeCalendario />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="center"
            flexGrow={2}
          >
            <Box padding="4px" className={classes.labelDatas}>
              <Typography>Datas</Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="start"
              width="100%"
              justifyContent="space-between"
            >
              <Box className={classes.accordionInformacoes}>
                {carga?.dataSolicitacao}
                <span> Solicitação da carga</span>
              </Box>
              <Box className={classes.accordionInformacoes}>
                {negociacao?.dataCarregamento} <span> Carregamento</span>
              </Box>
              <Box className={classes.accordionInformacoes}>
                {formatarDataHora(proposta?.dataHoraPedidoAprovacao)}
                <span> Data e hora do pedido de aprovação</span>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.accordionDetailsNegociador}>
          <InformacoesNegociador nome={negociacao?.nomeNegociador || '-'} />
        </Box>
        {filial && (
          <div className={classes.accordionDetailsFilial}>
            <CardFilial carga={carga} filial={filial} carregando={carregando} />
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default MaisDetalhes;
