import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { buscarCategoriaVeiculosFreteTabelado } from 'features/configuracoes/utils/configuracoes';
import { CategoriaVeiculo } from 'features/configuracoes/api/configuracoesApiTypes';
import { useDispatch } from 'react-redux';
import SwitchAba, { OpcaoSwitchAbaType } from 'shared/components/aba/SwitchAba';
import ConteudoVeiculos from './ConteudoVeiculos';

const CategoriaVeiculos = (): JSX.Element => {
  const dispatch = useDispatch();
  const [dadosVeiculos, setDadosVeiculos] = useState<CategoriaVeiculo[]>([]);
  const [abaSelecionada, setAbaSelecionada] = useState<number | undefined>(0);

  useEffect(() => {
    buscarCategoriaVeiculosFreteTabelado(setDadosVeiculos, dispatch);
  }, [dispatch, abaSelecionada, setDadosVeiculos]);

  const opcoesSwitchAba: OpcaoSwitchAbaType[] =
    dadosVeiculos?.map((veiculo, index) => ({
      id: index,
      idCategoriaVeiculo: veiculo.idCategoriaVeiculo,
      valor: veiculo.descricao,
      conteudoBadge: null,
    })) || [];

  if (opcoesSwitchAba.length > 0 && opcoesSwitchAba[0].valor === 'Truck') {
    const primeiroItem = opcoesSwitchAba.shift();
    if (primeiroItem) {
      opcoesSwitchAba.push(primeiroItem);
    }
  }

  return (
    <Box>
      {opcoesSwitchAba.length > 0 && (
        <SwitchAba
          abas={opcoesSwitchAba}
          idAbaInicial={opcoesSwitchAba?.[0].id}
          onClickOpcaoSwitch={(aba) => {
            setAbaSelecionada(aba);
          }}
        />
      )}
      {abaSelecionada !== undefined &&
      abaSelecionada >= 0 &&
      abaSelecionada < opcoesSwitchAba.length ? (
        <ConteudoVeiculos
          veiculoSelecionado={dadosVeiculos[abaSelecionada]}
          setDadosVeiculos={setDadosVeiculos}
        />
      ) : (
        <p>Nenhuma aba selecionada ou aba inválida</p>
      )}
    </Box>
  );
};

export default CategoriaVeiculos;
