import {
  DetalhesDemandaAndamento,
  DetalhesDemandaConcluida,
  DetalhesTotalDemandasDoDia,
  DetalhesDemandaNaoIniciada,
  DetalhesNegociacaoRecusada,
  DetalhesDemandaEmAprovacao,
  DetalhesDemandasConcluidasResponse,
} from './detalhesDemandasDiariaApiTypes';
import * as detalhesDemandasDiariaService from './detalhesDemandasDiariaService';

export const obterDetalhesDemandasDiaria = async (): Promise<
  DetalhesTotalDemandasDoDia[]
> => detalhesDemandasDiariaService.obterDetalhesDemandasDiaria();

export const obterDetalhesDemandasAndamento = async (): Promise<
  DetalhesDemandaAndamento[]
> => detalhesDemandasDiariaService.obterDetalhesDemandasAndamento();

export const obterDetalhesDemandasNaoIniciadas = async (): Promise<
  DetalhesDemandaNaoIniciada[]
> => detalhesDemandasDiariaService.obterDetalhesDemandasNaoIniciadas();

export const obterDetalhesDemandasConcluidas = async (): Promise<
  DetalhesDemandaConcluida[]
> => detalhesDemandasDiariaService.obterDetalhesDemandasConcluidas();

export const obterDetalhesDemandasTarfifarioNaoAtualizadas = async (
  pagina?: number,
): Promise<DetalhesDemandasConcluidasResponse> =>
  detalhesDemandasDiariaService.obterDetalhesDemandasTarfifarioNaoAtualizadas(
    pagina,
  );

export const obterDetalhesDemandasEmAprovacao = async (): Promise<
  DetalhesDemandaEmAprovacao[]
> => detalhesDemandasDiariaService.obterDetalhesDemandasEmAprovacao();

export const inserirTarifarioDemandaConcluida = async (
  idNegociacao: number,
): Promise<void> =>
  detalhesDemandasDiariaService.inserirTarifarioDemandaConcluida(idNegociacao);

export const obterDetalhesNegociacoesRecusadas = async (): Promise<
  DetalhesNegociacaoRecusada[]
> => detalhesDemandasDiariaService.obterDetalhesNegociacoesRecusadas();
