import React from 'react';
import { Box } from '@material-ui/core';
import { IntervaloTempo } from 'shared/types/apiTypes';
import { obterPorcentagemFormatada } from 'shared/functions/numberUtils';
import { formatarNumeroParaStringMonetariaComSimbolo } from 'shared/functions/dinheiroUtils';
import Porcentagem from 'shared/components/porcentagem/Porcentagem';
import IconeExclamacaoCirculo from 'assets/icones/IconeExclamacaoCirculo';
import IconePararCirculo from 'assets/icones/IconePararCirculo';
import TypographyTipoValor from '../../components/TypographyTipoValor';
import { PropostaSupervisor } from '../../api/aprovacoesSupervisorApiTypes';
import ValorProposta from '../../components/ValorProposta';
import NegociadorFilial from '../../components/NegociadorFilial';
import BadgeIntervaloTempo from '../../components/BadgeIntervaloTempo';
import useStylesCardProposta from '../../styles/cardProposta';
import CardPropostaGreguimSugerido from './CardPropostaGreguimSugerido';

export type CardPropostaNovaMetaType = {
  proposta: PropostaSupervisor;
};

const CardPropostaNovaMeta = ({
  proposta,
}: CardPropostaNovaMetaType): JSX.Element => {
  const {
    valorProposta,
    novaMetaValorProposta,
    valorFrotaPropria,
    nomeNegociador,
    cidadeFilial,
    tempoAnaliseAprovacao,
    cidadePrincipal,
    romaneio,
    valorPropostaRobo,
  } = proposta;

  const classes = useStylesCardProposta();
  return (
    <Box className={classes.container}>
      <Box className={classes.boxSpaceBetween}>
        <TypographyTipoValor>
          Valor Pedido:{' '}
          <del>
            {formatarNumeroParaStringMonetariaComSimbolo(valorProposta)}
          </del>
        </TypographyTipoValor>

        <TypographyTipoValor>Valor máximo aprovado:</TypographyTipoValor>
      </Box>

      <Box className={classes.boxSpaceBetween}>
        <ValorProposta
          icone={<IconeExclamacaoCirculo />}
          valor={novaMetaValorProposta || 0}
        />

        <Porcentagem
          styles={classes.badgePorcentagem}
          porcentagem={obterPorcentagemFormatada(
            novaMetaValorProposta || 0,
            valorFrotaPropria,
          )}
          complemento="Target"
        />
      </Box>

      <NegociadorFilial
        negociador={nomeNegociador}
        filial={cidadeFilial}
        cidadePrincipal={cidadePrincipal}
        romaneio={romaneio}
      />
      <Box className={classes.boxIntervalo}>
        <BadgeIntervaloTempo
          icone={<IconePararCirculo />}
          intervalo={tempoAnaliseAprovacao || ({} as IntervaloTempo)}
        />
        {valorPropostaRobo === valorProposta && <CardPropostaGreguimSugerido />}
      </Box>
    </Box>
  );
};

export default CardPropostaNovaMeta;
