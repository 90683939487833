import React from 'react';
import { Box } from '@material-ui/core';
import {
  AttachMoney,
  CancelPresentation,
  Check,
  Info,
} from '@material-ui/icons';
import ModalComum from '../../../shared/components/modalComum/ModalComum';
import BotaoIconeTexto from '../../detalhesAprovacaoSupervisor/components/Botoes/BotaoIconeTexto';
import BotaoOutlineIconeTexto from '../../detalhesAprovacaoSupervisor/components/Botoes/BotaoOutlineIconeTexto';
import theme from '../../../theme';
import { TypographyPadrao } from '../../../shared/components/typography/TypographyPadrao';
import Bold from '../../../shared/components/typography/Bold';
import { formatarNumeroParaStringMonetariaComSimbolo } from '../../../shared/functions/dinheiroUtils';

const modalType = {
  default: {
    titleText: 'Propor nova meta',
    buttonText: 'Confirmar nova meta',
    description: 'Você está prestes a aprovar o valor de nova meta de',
    color: theme.palette.cores.azulMartins[500],
    icon: <Check />,
  },
  success: {
    titleText: 'Aprovar pedido',
    buttonText: 'Confirmar aprovação',
    description: 'Você está prestes a aprovar o valor de',
    color: theme.palette.cores.verde[400],
    icon: <Check />,
  },
  successGreguim: {
    titleText: 'Aprovar pedido',
    buttonText: 'Confirmar aprovação',
    description: 'Você está prestes a aprovar o valor de',
    color: theme.palette.cores.verde[400],
    icon: <Check />,
  },
  error: {
    titleText: 'Reprovar pedido',
    buttonText: 'Confirmar reprovação',
    description: 'Você está prestes a reprovar o valor de',
    color: theme.palette.cores.vermelho[400],
    icon: <CancelPresentation />,
  },
};

interface Props {
  modalOpen: boolean;
  type: 'default' | 'success' | 'error' | 'successGreguim';
  valorApresentar: number;
  onCloseModal: () => void;
  onConfirmModal: () => void;
}

export function ModalConfirmacao({
  modalOpen,
  type,
  valorApresentar,
  onCloseModal,
  onConfirmModal,
}: Props): JSX.Element {
  return (
    <ModalComum
      modalAberto={modalOpen}
      fecharModal={onCloseModal}
      titulo={modalType[type].titleText}
      conteudo={
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          marginX="80px"
          textAlign="center"
          justifyContent="center"
          height="300px"
        >
          <Info htmlColor="#FF852D" style={{ fontSize: 40 }} />
          <Bold fontSize="32px">Atenção</Bold>
          <Box marginTop="12px">
            <TypographyPadrao style={{ color: theme.palette.cores.cinza[800] }}>
              Esta ação não pode ser desfeita.{'\n'}{' '}
              {modalType[type].description}
              {'\n'}{' '}
              <strong style={{ whiteSpace: 'nowrap' }}>
                {formatarNumeroParaStringMonetariaComSimbolo(valorApresentar)}
              </strong>
              .
            </TypographyPadrao>
          </Box>
        </Box>
      }
      acoes={
        <Box
          display="flex"
          flex={1}
          flexDirection="row"
          justifyContent="space-between"
          gridRowGap={16}
          marginLeft={1.5}
          marginRight={1.5}
          marginBottom="32px"
        >
          <BotaoOutlineIconeTexto
            icon={<AttachMoney />}
            color={theme.palette.cores.azulMartins[500]}
            onClick={onCloseModal}
          >
            Cancelar
          </BotaoOutlineIconeTexto>
          <BotaoIconeTexto
            icon={modalType[type].icon}
            color={theme.palette.cores.branco}
            backgroundColor={modalType[type].color}
            onClick={onConfirmModal}
          >
            {modalType[type].buttonText}
          </BotaoIconeTexto>
        </Box>
      }
    />
  );
}
