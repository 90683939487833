import { makeStyles } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';

export const useStyles = makeStyles((theme) => ({
  gapBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacingInPixels(theme, 1),
  },
  conteudoExpandido: {
    paddingLeft: spacingInPixels(theme, 3),
  },
  editeTexto: {
    fontWeight: 400,
  },
}));
