import React from 'react';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/reducer';
import { ArrowUpward } from '@material-ui/icons';
import CustomTooltip from 'shared/components/tooltip/CustomTooltip';
import { verificarCargaPlanejador } from 'shared/constants/OrigemCarga';
import TableRow from '../../../shared/components/table/TableRowHeightSpacing6';
import TableCell from '../../../shared/components/table/TableCellCustomPadding';
import TabelaTituloColuna from './TabelaTituloColuna';
import TabelaTituloImagem from './TabelaTituloImagem';
import { useStyles } from '../styles/tabelaTitulo';
import { atualizarOrdenacao } from '../redux/listaCargasAction';
import { TabelaListaCargasProps } from '../types/TabelaListaCargas';

const TabelaTitulo = ({ cargas }: TabelaListaCargasProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { ordenacao } = useSelector(
    (reducer: RootState) => reducer.listaCargasReducer,
  );

  const onOrdenacaoChange = (ordem: boolean): void => {
    dispatch(atualizarOrdenacao(ordem));
  };

  return (
    <TableRow>
      <TableCell>
        <CustomTooltip
          title={ordenacao ? 'Mais antigo' : 'Mais recente'}
          placement="top"
          style={{ maxWidth: '220px', textAlign: 'center', fontSize: '14px' }}
          arrow
        >
          <Button
            className={classes.botaoDirecao}
            onClick={() => onOrdenacaoChange(!ordenacao)}
          >
            {ordenacao ? <ArrowUpward /> : <ArrowDownwardIcon />}
          </Button>
        </CustomTooltip>
      </TableCell>
      <TabelaTituloColuna
        nome="ID"
        caminhoIcone="/images/listaCargas/icone-id.svg"
      />
      {verificarCargaPlanejador(cargas[0]?.origem) && (
        <TabelaTituloColuna
          nome="Negociador"
          caminhoIcone="/images/listaCargas/icone-dolar-azul.svg"
        />
      )}
      {verificarCargaPlanejador(cargas[0]?.origem) && (
        <TabelaTituloColuna
          nome="Data Carregamento"
          caminhoIcone="/images/minhasNegociacoes/icone-calendar.svg"
        />
      )}
      <TabelaTituloColuna
        nome="Filial"
        caminhoIcone="/images/listaCargas/icone-filial.svg"
      />
      <TabelaTituloColuna
        nome="Cidade"
        caminhoIcone="/images/listaCargas/icone-cidade.svg"
      />
      <TabelaTituloImagem
        nome="Entregas"
        caminhoIcone="/images/listaCargas/icone-entregas.svg"
      />
      <TabelaTituloImagem
        nome="Peso (kg)"
        caminhoIcone="/images/listaCargas/icone-peso.svg"
      />
      <TabelaTituloImagem
        nome="Volume (m³)"
        caminhoIcone="/images/listaCargas/icone-volume.svg"
      />
    </TableRow>
  );
};

export default TabelaTitulo;
