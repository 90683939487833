import { ConexaoAPI } from '../../../shared/classes/conexaoAPI';
import { Mensagem } from '../../chatNegociacao/api/chatNegociacaoApiTypes';
import { DetalhesAprovacaoSupervisorResponse } from './detalhesAprovacaoSupervisorTypes';

const obterUrlAprovarPropostaSupervisor = (
  idNegociacao: number,
  idProposta: number,
): string =>
  `${process.env.REACT_APP_URL_API_BASE}/negociacoes/${idNegociacao}/propostas/${idProposta}/aprovacao-supervisor`;

const obterUrlReprovarPropostaSupervisor = (
  idNegociacao: number,
  idProposta: number,
): string =>
  `${process.env.REACT_APP_URL_API_BASE}/negociacoes/${idNegociacao}/propostas/${idProposta}/reprovacao-supervisor`;

const obterUrlProporNovaMetaPropostaSupervisor = (
  idNegociacao: number,
  idProposta: number,
): string =>
  `${process.env.REACT_APP_URL_API_BASE}/negociacoes/${idNegociacao}/propostas/${idProposta}/nova-meta`;

const obterUrlDetalhesPropostaSupervisor = (
  idNegociacao: number,
  idProposta: number,
): string =>
  `${process.env.REACT_APP_URL_API_BASE}/negociacoes/${idNegociacao}/propostas/${idProposta}/detalhe-supervisor`;

export const aprovarPropostaSupervisor = async (
  idNegociacao: number,
  idProposta: number,
): Promise<Mensagem> => {
  const url = obterUrlAprovarPropostaSupervisor(idNegociacao, idProposta);

  const conexaoApi = new ConexaoAPI(url, 'patch');
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<Mensagem>();
  const response = await conectar();

  return response.data;
};

export const reprovarPropostaSupervisor = async (
  idNegociacao: number,
  idProposta: number,
  body: { justificativa: string },
): Promise<Mensagem> => {
  const url = obterUrlReprovarPropostaSupervisor(idNegociacao, idProposta);
  const conexaoApi = new ConexaoAPI(url, 'patch');
  conexaoApi.adicionarBody(body);
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<Mensagem>();
  const response = await conectar();
  return response.data;
};

export const proporNovaMetaPropostaSupervisor = async (
  idNegociacao: number,
  idProposta: number,
  body: { valorNovaMeta: number; justificativa: string },
): Promise<Mensagem> => {
  const url = obterUrlProporNovaMetaPropostaSupervisor(
    idNegociacao,
    idProposta,
  );

  const conexaoApi = new ConexaoAPI(url, 'patch');
  conexaoApi.adicionarBody(body);
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<Mensagem>();
  const response = await conectar();

  return response.data;
};

export const obterDetalhesPropostaSupervisor = async (
  idNegociacao: number,
  idProposta: number,
): Promise<DetalhesAprovacaoSupervisorResponse> => {
  const url = obterUrlDetalhesPropostaSupervisor(idNegociacao, idProposta);

  const conexaoApi = new ConexaoAPI(url, 'get');
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<DetalhesAprovacaoSupervisorResponse>();
  const response = await conectar();

  return response.data;
};
