import { Box, Tab, Tabs } from '@material-ui/core';
import React from 'react';
import OrigemCarga from 'shared/constants/OrigemCarga';
import { TabDadosProps } from '../types/TabDados';
import { useStyles } from '../styles/tabStyles';

const TabDados = ({
  aba,
  planilhaNumber,
  planejadorNumber,
  setAba,
}: TabDadosProps): JSX.Element => {
  const classes = useStyles();

  const mudarAba = (
    _event: React.ChangeEvent<unknown>,
    novaAba: OrigemCarga,
  ) => {
    setAba(novaAba);
  };

  return (
    <Box className={classes.alingTab}>
      <Tabs
        value={aba}
        indicatorColor="primary"
        textColor="primary"
        onChange={mudarAba}
        TabIndicatorProps={{
          style: {
            display: 'none',
          },
        }}
        className={classes.listaAbas}
      >
        <Tab
          label={`Cargas planilha (${planilhaNumber})`}
          value={OrigemCarga.Planilha}
          className={classes.botaoTab}
        />
        <Tab
          label={`Carga planejador (${planejadorNumber})`}
          value={OrigemCarga.Planejador}
          className={classes.botaoTab}
        />
      </Tabs>
    </Box>
  );
};

export default TabDados;
