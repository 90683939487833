import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  Button,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';

import spacingInPixels from 'shared/functions/materialUtils';
import {
  dipararSnackbarErro,
  verificarExtensaoArquivoXlsx,
} from 'features/uploadSimulador/utils/uploadSimulador';
import mensagensUploadSimulador from 'features/uploadSimulador/constants/mensagensUploadSimulador';
import { Autocomplete } from '@material-ui/lab';
import { FreteTabeladoRegiao } from '../api/configuracoesApiTypes';
import {
  buscarFreteTabeladoMacros,
  postUploadCidades,
} from '../utils/configuracoes';

const useStyles = makeStyles((theme) => ({
  caixaUpload: {
    borderRadius: '3px',
    backgroundColor: theme.palette.cores.branco,
    border: `2px dashed ${theme.palette.cores.azulMartins[700]}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: spacingInPixels(theme, 5),
    minHeight: '45vh',
  },
  inputMacro: {
    marginTop: spacingInPixels(theme, 3),
    marginBottom: spacingInPixels(theme, 2),
    width: '100%',
  },
  botaoBuscar: {
    width: '15%',
    minWidth: spacingInPixels(theme, 25),
    maxWidth: spacingInPixels(theme, 50),
    height: spacingInPixels(theme, 6),
    fontSize: '16px',
    float: 'right',
    textTransform: 'none',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    color: theme.palette.cores.azulMartins[500],
    transition: 'filter 0.5s ease-in-out',
    '&:hover': {
      color: theme.palette.cores.azulMartins[500],
      backgroundColor: 'transparent',
      filter: 'brightness(0.7)',
      boxShadow: 'none',
    },
  },
  labelInputArquivo: {
    cursor: 'pointer',
  },
  footerButton: {
    display: 'flex',
    marginTop: '10px',
    justifyContent: 'flex-end',
  },
}));

interface UploadCidadesMacroProps {
  handleUploadBoolPlanilha: () => void;
}

const UploadCidadesMacro = ({
  handleUploadBoolPlanilha,
}: UploadCidadesMacroProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [possuiArquivo, setPossuiArquivo] = useState(false);
  const [arquivoData, setArquivoData] = useState<File | null>(null);
  const [macroSelect, setMacroSelect] = useState<FreteTabeladoRegiao | null>(
    null,
  );
  const [dadosMacros, setDadosMacros] = useState<FreteTabeladoRegiao[]>([]);

  useEffect(() => {
    buscarFreteTabeladoMacros(setDadosMacros, dispatch);
  }, [dispatch]);

  const changeHandler = (evento: React.ChangeEvent<HTMLInputElement>): void => {
    if (evento.target.files && evento.target.files.length > 0) {
      if (verificarExtensaoArquivoXlsx(evento.target.files[0].type)) {
        setPossuiArquivo(true);
        setArquivoData(evento.target?.files[0]);
      } else {
        dipararSnackbarErro(
          mensagensUploadSimulador.ERRO_TIPO_ARQUIVO_INVALIDO,
          dispatch,
        );
        setPossuiArquivo(false);
      }
    } else {
      setPossuiArquivo(false);
    }
  };

  const handleSalvarPlanilha = (): void => {
    if (arquivoData && macroSelect?.idMacroFilial) {
      postUploadCidades(
        arquivoData,
        String(macroSelect.idMacroFilial),
        dispatch,
      )
        .then(() => {
          setPossuiArquivo(false);
          setArquivoData(null);
          setMacroSelect(null);
        })
        .catch(() => {
          dipararSnackbarErro('Erro ao salvar!', dispatch);
        });
    }
  };

  return (
    <>
      <Autocomplete
        options={dadosMacros || []}
        getOptionLabel={(option) =>
          option ? `${option.idMacroFilial} - ${option.descricao}` : ''
        }
        onChange={(_event, value) => {
          setMacroSelect(value);
        }}
        size="medium"
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Selecione a macro"
            size="small"
            className={classes.inputMacro}
          />
        )}
      />
      <Box className={classes.caixaUpload}>
        <img
          src="/images/uploadSimulador/u_cloud-upload.svg"
          alt="Upload arquivo"
        />
        <Box>
          <label htmlFor="input-arquivo" className={classes.labelInputArquivo}>
            <input
              type="file"
              hidden
              id="input-arquivo"
              accept=".xlsx"
              onChange={(evento: React.ChangeEvent<HTMLInputElement>) =>
                changeHandler(evento)
              }
            />
            {possuiArquivo ? (
              <Typography>{arquivoData?.name}</Typography>
            ) : (
              <Button
                variant="contained"
                color="primary"
                className={classes.botaoBuscar}
                component="span"
              >
                Buscar arquivo
              </Button>
            )}
          </label>
        </Box>
      </Box>
      <Box className={classes.footerButton}>
        <Button
          style={{ marginRight: '25px', padding: '8px' }}
          color="primary"
          variant="text"
          onClick={handleUploadBoolPlanilha}
        >
          Voltar
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={!possuiArquivo || !macroSelect}
          onClick={handleSalvarPlanilha}
        >
          SALVAR
        </Button>
      </Box>
    </>
  );
};

export default UploadCidadesMacro;
