import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import { RootState } from '../../../../store/reducer';

import { spacingInPixels } from '../../../../shared/functions/materialUtils';

import { verificarSeNegociacaoTemObservacao } from '../../functions/informacoesNegociacaoUtils';

import { abrirModalExcluirObservacao } from '../../redux/informacoesNegociacaoAction';

import CampoObservacao from './CampoObservacao';
import ButtonDetalhesCarga from './ButtonDetalhesCarga';
import SwitchObservacao from './SwitchObservacao';

const useStyles = makeStyles((theme) => ({
  espacamento: {
    marginTop: spacingInPixels(theme, 3),
  },
}));

const ObservacaoGrid = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { negociacao, negociacaoConcluida } = useSelector(
    (state: RootState) => state.informacoesNegociacaoReducer,
  );

  const [switchHabilitado, setSwitchHabilitado] = useState(false);
  const [mostrarObservacao, setMostrarObservacao] = useState(false);

  const temObservacao = verificarSeNegociacaoTemObservacao(negociacao);

  useEffect(() => {
    setSwitchHabilitado(temObservacao);
    setMostrarObservacao(!negociacaoConcluida || temObservacao);
  }, [negociacao, negociacaoConcluida, temObservacao]);

  const onSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const habilitado = event.target.checked;
    if (!habilitado && temObservacao) {
      dispatch(abrirModalExcluirObservacao());
    } else {
      setSwitchHabilitado(habilitado);
    }
  };

  return (
    <>
      {negociacao && (
        <>
          <Box
            display="flex"
            justifyContent={mostrarObservacao ? 'space-between' : 'flex-end'}
          >
            {mostrarObservacao && (
              <SwitchObservacao
                checked={switchHabilitado}
                onChange={onSwitchChange}
              />
            )}
            <ButtonDetalhesCarga />
          </Box>
          {mostrarObservacao && switchHabilitado && (
            <Box display="flex" className={classes.espacamento}>
              <CampoObservacao />
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default ObservacaoGrid;
