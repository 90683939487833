import React from 'react';
import { Box, Typography, withStyles } from '@material-ui/core';

import { version } from '../../../../package.json';

const Texto = withStyles((theme) => ({
  root: {
    color: theme.palette.cores.azulMartins[500],
  },
}))(Typography);

const VersaoSistema = (): JSX.Element => (
  <Box
    position="absolute"
    display="flex"
    width="100%"
    justifyContent="center"
    bottom={0}
  >
    <Texto>Versão {version}</Texto>
  </Box>
);

export default VersaoSistema;
