import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/reducer';
import {
  verificarExibicaoAceite,
  verificarExibicaoBotoesResposta,
  verificarExibicaoRecusa,
} from '../../../utils/aguardandoNegociador';

import AceiteProposta from './AceiteProposta';
import BotoesResposta from './BotoesResposta';

import RecusaProposta from './RecusaProposta';
import ZoomBox from './ZoomBox';

const RespostaContraproposta = (): JSX.Element => {
  const { chatExibicaoRespostaNegociador } = useSelector(
    (state: RootState) => state.chatNegociacaoReducer,
  );

  return (
    <>
      <ZoomBox
        visivel={verificarExibicaoBotoesResposta(
          chatExibicaoRespostaNegociador,
        )}
      >
        <BotoesResposta />
      </ZoomBox>

      <ZoomBox
        visivel={verificarExibicaoAceite(chatExibicaoRespostaNegociador)}
      >
        <AceiteProposta />
      </ZoomBox>
      <ZoomBox
        visivel={verificarExibicaoRecusa(chatExibicaoRespostaNegociador)}
      >
        <RecusaProposta />
      </ZoomBox>
    </>
  );
};

export default RespostaContraproposta;
