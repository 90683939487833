import { createReducer } from '@reduxjs/toolkit';
import { OrigemCargaType } from '../../../shared/constants/OrigemCarga';
import PropostaStatus from '../../../shared/constants/PropostaStatus';
import {
  verificarMensagemArquivo,
  verificarMensagemBloqueioListaClientes,
  verificarMensagemLiberacaoListaClientes,
  verificarMensagemPedidoAprovacaoSupervisor,
  verificarMensagemSupervisor,
  verificarMensagemTextoLivre,
} from '../../../shared/constants/TipoMensagemProposta';
import {
  converterDataApi,
  obterDiferencaDatas,
  verificarDataHoraMaior,
} from '../../../shared/functions/dataUtils';
import { Mensagem } from '../api/chatNegociacaoApiTypes';
import TipoValorContraproposta from '../constants/TipoValorContraproposta';
import {
  abrirChat,
  adicionarMensagem,
  atualizarExibicaoRespostaNegociador,
  atualizarMensagens,
  fecharChat,
  InformacoesMensagens,
  atualizarListaClientesLiberada,
  adicionarImagemChat,
  ArquivoImagem,
  InformacoesModalContraproposta,
  informacoesModalContraproposta,
} from './chatNegociacaoAction';

export const Aceitar = 'aceitar';
export const Recusar = 'recusar';
export const Contraproposta = 'contraproposta';
export const AguardandoAprovacaoSupervisor = 'AguardandoAprovacaoSupervisor';

export type ExibicaoRespostaNegociador =
  | typeof Aceitar
  | typeof Recusar
  | typeof Contraproposta
  | typeof AguardandoAprovacaoSupervisor;

export type ChatNegociacaoState = {
  chatIdNegociacao?: number;
  chatIdProposta?: number;
  chatRomaneio: string;
  chatNomeMotorista: string;
  chatAberto: boolean;
  chatCarregando: boolean;
  chatMensagens: Mensagem[];
  chatDataUltimaMensagem?: Date;
  chatExibicaoRespostaNegociador?: ExibicaoRespostaNegociador;
  chatOrigemCarga?: OrigemCargaType;
  chatListaClientesLiberada: boolean;
  chatPropostaStatus: PropostaStatus;
  chatMotivoRecusa?: number;
  chatValorFrotaPropria?: number;
  chatValorFreteTerceiro?: number;
  chatValorMecadoria?: number;
  chatQuilometragem?: number;
  chatEntregas?: number;
  chatPeso?: number;
  chatImagens: ArquivoImagem[];
  metaValorProposta?: number;
  mensagemReferencia?: Mensagem;
  informacoesModalContraproposta: InformacoesModalContraproposta;
};

export const estadoInicial: ChatNegociacaoState = {
  chatRomaneio: '',
  chatNomeMotorista: '',
  chatAberto: false,
  chatMensagens: [],
  chatCarregando: false,
  chatListaClientesLiberada: false,
  chatPropostaStatus: PropostaStatus.AGUARDANDO,
  chatImagens: [],
  informacoesModalContraproposta: {
    justificativa: '',
    tipoValor: TipoValorContraproposta.ValorProposto,
    valorProposto: undefined,
    valorInput: undefined,
  },
};

const verificarPropostaPayloadIgualAtual = (
  state: ChatNegociacaoState,
  chatIdNegociacao: number,
  chatIdProposta: number,
): boolean =>
  state.chatIdNegociacao === chatIdNegociacao &&
  state.chatIdProposta === chatIdProposta;

const verificarQuantidadeMensagens = (
  state: ChatNegociacaoState,
  mensagens: Mensagem[],
): boolean =>
  state.chatMensagens.length !== 0 &&
  state.chatMensagens.length === mensagens.length;

const verificarNaoDeveAtualizarMensagens = (
  state: ChatNegociacaoState,
  payload: InformacoesMensagens,
): boolean =>
  !verificarPropostaPayloadIgualAtual(
    state,
    payload.chatIdNegociacao,
    payload.chatIdProposta,
  ) || verificarQuantidadeMensagens(state, payload.mensagens);

const filtrarMensagensRelevantes = (mensagens: Mensagem[]) =>
  mensagens.filter(
    (mensagem) =>
      !verificarMensagemTextoLivre(mensagem.tipo) &&
      !verificarMensagemArquivo(mensagem.tipo) &&
      !verificarMensagemLiberacaoListaClientes(mensagem.tipo) &&
      !verificarMensagemBloqueioListaClientes(mensagem.tipo) &&
      !verificarMensagemSupervisor(mensagem.tipo) &&
      !verificarMensagemPedidoAprovacaoSupervisor(mensagem.tipo),
  );

const filtrarNovasMensagens = (
  state: ChatNegociacaoState,
  mensagem: Mensagem,
) =>
  state.chatDataUltimaMensagem === undefined ||
  verificarDataHoraMaior(
    converterDataApi(mensagem.dataEnvio),
    state.chatDataUltimaMensagem,
  );

const ordenarMensagensPorDataCrescente = (a: Mensagem, b: Mensagem): number =>
  obterDiferencaDatas(
    converterDataApi(a.dataEnvio),
    converterDataApi(b.dataEnvio),
  );

const obterMensagens = (
  state: ChatNegociacaoState,
  payload: InformacoesMensagens,
): Mensagem[] =>
  payload.mensagens
    .filter((mensagem) => filtrarNovasMensagens(state, mensagem))
    .concat(state.chatMensagens)
    .sort(ordenarMensagensPorDataCrescente);

const obterUltimaMensagem = (mensagens: Mensagem[]): Mensagem | undefined =>
  mensagens.length > 0 ? mensagens[mensagens.length - 1] : undefined;

const obterMensagemReferencia = (mensagens: Mensagem[]): Mensagem | undefined =>
  obterUltimaMensagem(filtrarMensagensRelevantes(mensagens));

const obterDataUltimaMensagem = (
  ultimaMensagem: Mensagem | undefined,
): Date | undefined =>
  ultimaMensagem && converterDataApi(ultimaMensagem.dataEnvio);

const chatNegociacaoReducer = createReducer(estadoInicial, (builder) => {
  builder
    .addCase(abrirChat, (state, action) => {
      const { payload } = action;
      const checaOStatusProposta = () =>
        payload.chatPropostaStatus === state.chatPropostaStatus
          ? state
          : {
              ...state,
              chatPropostaStatus: payload.chatPropostaStatus,
            };

      return verificarPropostaPayloadIgualAtual(
        state,
        payload.chatIdNegociacao,
        payload.chatIdProposta,
      )
        ? checaOStatusProposta()
        : {
            ...estadoInicial,
            ...payload,
            chatMensagens: [],
            chatAberto: true,
            chatCarregando: true,
          };
    })
    .addCase(atualizarMensagens, (state, action) => {
      const { payload } = action;
      if (verificarNaoDeveAtualizarMensagens(state, payload)) {
        return state;
      }

      const chatMensagens = obterMensagens(state, payload);
      const ultimaMensagem = obterUltimaMensagem(chatMensagens);

      return {
        ...state,
        chatMensagens,
        chatCarregando: false,
        mensagemReferencia: obterMensagemReferencia(chatMensagens),
        chatDataUltimaMensagem: obterDataUltimaMensagem(ultimaMensagem),
        chatPropostaStatus: state.chatPropostaStatus,
        chatMotivoRecusa: payload.chatMotivoRecusa,
      };
    })
    .addCase(adicionarMensagem, (state, action) => {
      const {
        chatIdNegociacao,
        chatIdProposta,
        chatPropostaStatus,
        chatMotivoRecusa,
        mensagem,
      } = action.payload;

      if (
        !verificarPropostaPayloadIgualAtual(
          state,
          chatIdNegociacao,
          chatIdProposta,
        )
      ) {
        return state;
      }

      const chatMensagens = state.chatMensagens
        .concat(mensagem)
        .sort(ordenarMensagensPorDataCrescente);

      const ultimaMensagem = obterUltimaMensagem(chatMensagens);

      return {
        ...state,
        chatMensagens,
        chatExibicaoRespostaNegociador: undefined,
        mensagemReferencia: obterMensagemReferencia(chatMensagens),
        chatDataUltimaMensagem: obterDataUltimaMensagem(ultimaMensagem),
        chatPropostaStatus,
        chatMotivoRecusa,
      };
    })
    .addCase(atualizarExibicaoRespostaNegociador, (state, action) => ({
      ...state,
      chatExibicaoRespostaNegociador: action.payload,
    }))
    .addCase(atualizarListaClientesLiberada, (state, action) => ({
      ...state,
      chatListaClientesLiberada: action.payload,
    }))
    .addCase(adicionarImagemChat, (state, action) => ({
      ...state,
      chatImagens: [...state.chatImagens, action.payload],
    }))
    .addCase(fecharChat, () => estadoInicial)
    .addCase(informacoesModalContraproposta, (state, action) => ({
      ...state,
      informacoesModalContraproposta: action.payload,
    }))
    .addDefaultCase((state) => state);
});

export default chatNegociacaoReducer;
