import { Dispatch } from 'redux';
import {
  converterDataApi,
  obterDiferencaDatas,
} from 'shared/functions/dataUtils';
import {
  abrirSnackbarErro,
  abrirSnackbarSucesso,
} from 'shared/components/snackbar/redux/snackbarAction';
import { NegociacaoAndamento } from '../api/demandasAndamentoApiTypes';
import {
  obterDemandasAndamento,
  exportarDemandasAndamento,
} from '../api/demandasAndamentoManager';
import CampoOrdenacaoDemandasAndamento from '../constants/CampoOrdenacaoDemandasAndamento';

const tratarErro = (error: Error) => (dispatch: Dispatch) => {
  dispatch(abrirSnackbarErro({ mensagem: error.message }));
};

const ordenacaoCrescente = 1;
const ordenacaoDecrescente = -1;

export const ordenarDemandasAndamento = (
  campo: string,
  demandas?: NegociacaoAndamento[],
  crescente?: boolean,
): NegociacaoAndamento[] | undefined => {
  if (!demandas) return undefined;

  const direcaoOrdenacao = crescente
    ? ordenacaoCrescente
    : ordenacaoDecrescente;
  const demandasOrdenar = [...demandas];

  switch (campo) {
    case CampoOrdenacaoDemandasAndamento.Filial:
      return demandasOrdenar.sort(
        (a, b) => a.nomeFilial.localeCompare(b.nomeFilial) * direcaoOrdenacao,
      );
    case CampoOrdenacaoDemandasAndamento.Negociador:
      return demandasOrdenar.sort(
        (a, b) =>
          a.nomeNegociador.localeCompare(b.nomeNegociador) * direcaoOrdenacao,
      );
    case CampoOrdenacaoDemandasAndamento.DataCarregamento:
      return demandasOrdenar.sort(
        (a, b) =>
          obterDiferencaDatas(
            converterDataApi(a.dataCarregamento),
            converterDataApi(b.dataCarregamento),
          ) * direcaoOrdenacao,
      );
    default:
      return demandasOrdenar;
  }
};

export const buscarDemandasAndamento = (
  setDemandas: (demandas?: NegociacaoAndamento[]) => void,
) => async (dispatch: Dispatch): Promise<void> =>
  obterDemandasAndamento()
    .then((response) => {
      setDemandas(
        ordenarDemandasAndamento(
          CampoOrdenacaoDemandasAndamento.Filial,
          response,
          false,
        ),
      );
    })
    .catch((error) => tratarErro(error)(dispatch));

export const exportarPlanilhaDemandasAndamento = () => async (
  dispatch: Dispatch,
): Promise<void> =>
  exportarDemandasAndamento()
    .then(() => {
      dispatch(
        abrirSnackbarSucesso({
          mensagem: 'Demandas das negociações em andamento exportadas',
        }),
      );
    })
    .catch((error) => tratarErro(error)(dispatch));
