import {
  DemandaFilial,
  DemandasDiariasFilialResponse,
} from './listagemFiliaisApiTypes';
import {
  obterDemandaFilial,
  obterNegociacoesFilial,
} from './listagemFiliaisService';

export const buscarDemandaFilial = async (): Promise<DemandaFilial[]> =>
  obterDemandaFilial();

export const buscarNegociacoesFilial = async (
  idFilial: number,
): Promise<DemandasDiariasFilialResponse> => obterNegociacoesFilial(idFilial);
