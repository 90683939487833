import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core';
import IconeCaixa from '../../../assets/icones/IconeCaixa';
import IconeCorretoQuadrado from '../../../assets/icones/IconeCorretoQuadrado';
import IconeBalaoChat from '../../../assets/icones/IconeBalaoChat';
import IconeRelogio from '../../../assets/icones/IconeRelogio';
import IconeExclamacaoOctogono from '../../../assets/icones/IconeExclamacaoOctogono';
import IconePreenchido from '../../../shared/components/icons/IconePreenchido';
import CustomTooltip from '../../../shared/components/tooltip/CustomTooltip';
import { Demandas, StatusDemandaFilialType } from '../constants/Types';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    paddingRight: '20%',
    marginTop: '10px',
    '@media (max-width:1320px)': {
      paddingRight: '5%',
    },
    '& > div': {
      width: '20%',
      display: 'grid',
      gridTemplateColumns: '40px auto',
    },
  },
  boxInformacao: {
    display: 'flex',
    verticalAlign: 'middle',
    '& > div': {
      margin: 'auto',
    },
  },

  fonte: {
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '32px',
    marginLeft: '4px',
  },
}));

interface StatusDemandaProps {
  dadosStatusDemanda: Demandas;
}

const StatusDemandaFilial = ({
  dadosStatusDemanda,
}: StatusDemandaProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const {
    concluidas,
    emAprovacao,
    emNegociacao,
    naoIniciadas,
    total,
  } = dadosStatusDemanda;

  const listaStatusDemanda: StatusDemandaFilialType[] = [
    {
      id: 1,
      icone: <IconeCaixa width={15} height={15} />,
      valor: total,
      mensagemTooltip: 'Demandas do dia',
      cor: theme.palette.cores.azul[500] as string,
    },
    {
      id: 2,
      icone: <IconeCorretoQuadrado width={15} height={15} />,
      valor: concluidas,
      mensagemTooltip: 'Concluídas',
      cor: theme.palette.cores.verdeClaro[500] as string,
    },
    {
      id: 3,
      icone: <IconeBalaoChat width={15} height={15} />,
      valor: emNegociacao,
      mensagemTooltip: 'Em negociação',
      cor: theme.palette.cores.azulPiscina[400] as string,
    },
    {
      id: 4,
      icone: <IconeRelogio transform="scale(-1,1)" width={15} height={15} />,
      valor: naoIniciadas,
      mensagemTooltip: 'Não iniciadas',
      cor: theme.palette.cores.mostarda[800] as string,
    },
    {
      id: 5,
      icone: <IconeExclamacaoOctogono width={15} height={15} />,
      valor: emAprovacao,
      mensagemTooltip: 'Em aprovação',
      cor: theme.palette.cores.azulPiscina[900] as string,
    },
  ];

  return (
    <div className={classes.root}>
      {listaStatusDemanda.map((item, index) => (
        <div
          className={classes.boxInformacao}
          key={`status-demanda-filial-${index + 1}`}
        >
          <CustomTooltip
            title={item.mensagemTooltip}
            placement="top"
            fontSize={14}
            arrow
          >
            <div>
              <IconePreenchido icone={item.icone} cor={item.cor} />
            </div>
          </CustomTooltip>
          <span className={classes.fonte}>{item.valor}</span>
        </div>
      ))}
    </div>
  );
};

export default StatusDemandaFilial;
