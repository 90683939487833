import { OpcaoSwitchAbaType } from 'shared/components/aba/SwitchAba';

enum ConfiguracaoAba {
  freteTabelado,
  LeilãoFrete,
}

export const OpcoesConfiguracaoAba: OpcaoSwitchAbaType[] = [
  {
    id: ConfiguracaoAba.freteTabelado,
    valor: 'Frete Tabelado',
    conteudoBadge: null,
  },
  {
    id: ConfiguracaoAba.LeilãoFrete,
    valor: 'Leilão de Frete',
    conteudoBadge: null,
  },
];

export default ConfiguracaoAba;
