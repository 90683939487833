export const obterPermissaoNotificacao = (): string => Notification.permission;

export const permissaoNotificacaoConcedida = (): boolean =>
  Notification.permission === 'granted';

export const permissaoNotificacaoDefault = (): boolean =>
  Notification.permission === 'default';

export const requisitarPermissaoNotificacao = (): boolean => {
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      return permissaoNotificacaoConcedida();
    }
    return permissaoNotificacaoDefault();
  });
  return permissaoNotificacaoDefault();
};

type criarNotiticacaoType = {
  titulo: string;
  texto: string;
  icone: string;
  onClickFuncao?: () => void;
};

export const criarNotificacao = ({
  titulo,
  texto,
  icone,
  onClickFuncao = () => undefined,
}: criarNotiticacaoType): Notification => {
  const notificacao = new Notification(titulo, {
    body: texto,
    icon: icone,
  });

  notificacao.onclick = (event) => {
    event.preventDefault();
    onClickFuncao();
  };
  return notificacao;
};
