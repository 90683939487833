import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { formatarNumeroParaStringMonetariaComSimbolo } from '../../../../shared/functions/dinheiroUtils';
import spacingInPixels from '../../../../shared/functions/materialUtils';

export type BadgeMediaProps = {
  valorMedio?: number;
  diasCalculo?: number;
  valorProposta: number;
};

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.cores.cinza[200],
    borderRadius: spacingInPixels(theme, 2),
    padding: spacingInPixels(theme, 1),
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: ({ mediaPositiva }: { mediaPositiva: boolean }) =>
      mediaPositiva
        ? theme.palette.cores.verde[400]
        : theme.palette.cores.vermelho[700],
  },
  textoValor: {
    fontSize: '19px',
    fontWeight: 500,
    color: theme.palette.cores.cinza[800],
  },
  textoDescricao: {
    fontSize: '12px',
    fontWeight: 400,
    color: theme.palette.cores.cinza[500],
  },
}));

const BadgeMedia = ({
  valorMedio = 0,
  diasCalculo = 0,
  valorProposta,
}: BadgeMediaProps): JSX.Element => {
  const classes = useStyles({
    mediaPositiva: valorProposta <= valorMedio,
  });
  return (
    <Box className={classes.container}>
      <Typography className={classes.textoValor}>
        {formatarNumeroParaStringMonetariaComSimbolo(valorMedio)}
      </Typography>
      <Typography className={classes.textoDescricao}>
        Média {diasCalculo} dias
      </Typography>
    </Box>
  );
};

export default BadgeMedia;
