import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, useTheme } from '@material-ui/core';
import ModalComum from '../../../shared/components/modalComum/ModalComum';
import ModalComumAcoes from '../../../shared/components/modalComum/ModalComumAcoes';
import { RootState } from '../../../store/reducer';
import IconeExclamacaoCirculo from '../../../assets/icones/IconeExclamacaoCirculo';
import { formatarNumeroParaStringMonetariaComSimbolo } from '../../../shared/functions/dinheiroUtils';
import { pedirAprovacaoSupervisorOperation } from '../redux/chatNegociacaoOperation';
import { ModalPedirAprovacaoSupervisorPropTypes } from '../types/modalTypes';
import { useStyles } from '../styles/ModalConfirmarAprovacaoSupervisor';

const ModalConfirmarAprovacaoSupervisor = ({
  setModalConfirmarAprovacaoSupervisorAberto,
  modalConfirmarAprovacaoSupervisorAberto,
  valorParaAprovacao,
  justificativa,
}: ModalPedirAprovacaoSupervisorPropTypes): JSX.Element => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();

  const { idNegociacao, idPropostaComFoco } = useSelector(
    (state: RootState) => state.informacoesNegociacaoReducer,
  );

  const fecharModal = () => {
    setModalConfirmarAprovacaoSupervisorAberto(false);
  };

  const confirmarContraProposta = () => {
    if (
      !idNegociacao ||
      !idPropostaComFoco ||
      !valorParaAprovacao ||
      !justificativa
    )
      return;

    dispatch(
      pedirAprovacaoSupervisorOperation(idNegociacao, idPropostaComFoco, {
        valorContraproposta: valorParaAprovacao,
        justificativa,
      }),
    );

    fecharModal();
  };

  const atencao = 'Atenção';
  const textoAviso =
    'Esta ação não pode ser desfeita. Você está prestes a pedir aprovação do valor de ';

  return (
    <ModalComum
      modalAberto={modalConfirmarAprovacaoSupervisorAberto}
      fecharModal={fecharModal}
      titulo="Pedir Aprovação"
      conteudo={
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          paddingTop={6}
        >
          <IconeExclamacaoCirculo
            fill={theme.palette.cores.amarelo[900]}
            width={40}
            height={40}
            viewBox="0 0 20 20"
          />
          <Typography className={classes.textoAtencao}>{atencao}</Typography>
          <Typography className={classes.textoAviso}>
            {textoAviso}
            <b>
              {formatarNumeroParaStringMonetariaComSimbolo(valorParaAprovacao)}
            </b>
          </Typography>
        </Box>
      }
      acoes={
        <ModalComumAcoes
          textoBotaoPrimario="Confirmar Pedido"
          callbackBotaoPrimario={confirmarContraProposta}
          textoBotaoSecundario="Cancelar"
          callbackBotaoSecundario={fecharModal}
          botaoPrimarioDesativado={
            !valorParaAprovacao || !justificativa.trim().length
          }
        />
      }
    />
  );
};

export default ModalConfirmarAprovacaoSupervisor;
