import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Rubik } from '../../../../../shared/constants/font';

export default withStyles((theme) => ({
  root: {
    color: ({ cor }: { cor?: string }) => cor || theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: Rubik,
    textTransform: 'capitalize',
  },
}))(Typography);
