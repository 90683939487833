export enum NegociacaoStatus {
  NovaNegociacao,
  EmAndamento,
  Cancelada,
  AguardarGestao,
  Concluida,
  TempoExpirado,
}

export type NegociacaoStatusType = 0 | 1 | 2 | 3 | 4 | 5;

export const verificarNegociacaoConcluida = (
  status: NegociacaoStatusType,
): boolean => NegociacaoStatus.Concluida === status;

export const verificarNegociacaoCancelada = (
  status: NegociacaoStatusType,
): boolean => NegociacaoStatus.Cancelada === status;

export const verificarNegociacaoAguardarGestao = (
  status: NegociacaoStatusType,
): boolean => NegociacaoStatus.AguardarGestao === status;

export const verificarNegociacaoEmAndamento = (
  status: NegociacaoStatusType,
): boolean => NegociacaoStatus.EmAndamento === status;

export const verificarNegociacaoTempoExpirado = (
  status: NegociacaoStatusType,
): boolean => NegociacaoStatus.TempoExpirado === status;

export default NegociacaoStatus;
