import React from 'react';
import { useDispatch } from 'react-redux';

import BotaoRubik from '../../../../../shared/components/button/BotaoRubik';

import { atualizarExibicaoRespostaNegociador } from '../../../redux/chatNegociacaoAction';

export type BotaoCancelarProps = {
  disabled?: boolean;
  className?: string;
};

const BotaoCancelar = ({
  disabled,
  className,
}: BotaoCancelarProps): JSX.Element => {
  const dispatch = useDispatch();

  const onClickBotaoCancelar = (): void => {
    dispatch(atualizarExibicaoRespostaNegociador(undefined));
  };

  return (
    <BotaoRubik
      className={className}
      variant="outlined"
      color="primary"
      disabled={disabled}
      onClick={onClickBotaoCancelar}
    >
      Cancelar
    </BotaoRubik>
  );
};

export default BotaoCancelar;
