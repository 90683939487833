import { useTheme } from '@material-ui/core';
import * as React from 'react';
import { SVGProps } from 'react';

const IconeInformacaoChat = ({
  fill,
  ...outros
}: SVGProps<SVGSVGElement>): JSX.Element => {
  const theme = useTheme();
  return (
    <svg width={18} height={20} fill="none" {...outros}>
      <path
        d="M16.29 1.71a1 1 0 0 0 1.42 0 1.15 1.15 0 0 0 .21-.33 1 1 0 0 0-.21-1.09l-.15-.12a.761.761 0 0 0-.18-.09 1 1 0 0 0-1.09.21A1 1 0 0 0 16 1c.002.13.029.26.08.38.05.121.122.233.21.33ZM17 3a1 1 0 0 0-1 1v4a1 1 0 0 0 2 0V4a1 1 0 0 0-1-1Zm.06 8a1 1 0 0 0-1.11.87A7 7 0 0 1 9 18H3.41l.64-.63a1 1 0 0 0 0-1.41A7 7 0 0 1 9 4a6.91 6.91 0 0 1 3.49.94 1 1 0 0 0 1-1.72A8.84 8.84 0 0 0 9 2a9 9 0 0 0-7 14.62L.29 18.29a1 1 0 0 0-.21 1.09A1 1 0 0 0 1 20h8a9 9 0 0 0 8.93-7.88 1 1 0 0 0-.87-1.12Z"
        fill={fill || theme.palette.cores.cinza[800]}
      />
    </svg>
  );
};

export default IconeInformacaoChat;
