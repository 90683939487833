import { PaginaType } from 'shared/constants/Pagina';
import { UsuarioInfo, DadosUsuarioResponse } from './loginApiTypes';
import * as loginService from './loginService';

export const fazerLogin = async (usuarioInfo: UsuarioInfo): Promise<void> =>
  loginService.fazerLogin({ usuarioInfo });

export const buscarDadosUsuario = async (): Promise<DadosUsuarioResponse> =>
  loginService.buscarDadosUsuario();

export const buscarDadosPermissaoUsuario = async (): Promise<PaginaType[]> =>
  loginService.buscarDadosPermissaoUsuario();

export const fazerLogout = async (): Promise<void> =>
  loginService.fazerLogout();

export const refreshToken = async (): Promise<void> =>
  loginService.refreshToken();
