import { Dispatch } from 'redux';
import { formatarIntervaloTempo } from 'shared/functions/IntervaloTempo';
import { descricaoFiltroNotificacao } from '../../constants/Constants';
import { buscarDemandasNegociador } from '../../negociadores/api/listagemNegociadoresManager';
import {
  separarDemandasComNotificacaoPorCategoria,
  separarDemandasPorFilial,
  separarFiliaisDistintas,
} from '../../utils/modalDetalhes';
import { listarDemandasNegociador } from './listagemDemandasNegociadorActions';

export const demandasNegociadorPorFilialEstadoErro = new Map().set(
  'Todas as filiais (0)',
  [],
);
export const notificacoesNegociadorPorCategoriaEstadoErro = new Map()
  .set(descricaoFiltroNotificacao.todas, [])
  .set(descricaoFiltroNotificacao.naoVista, [])
  .set(descricaoFiltroNotificacao.semAprovacao, [])
  .set(descricaoFiltroNotificacao.alertaVencimento, []);

export const buscarDadosDemandasNegociador = (
  setCarregando: (carregando: boolean) => void,
  idNegociador?: number,
) => async (dispatch: Dispatch): Promise<void> => {
  try {
    setCarregando(true);
    if (!idNegociador) throw new Error();
    const {
      demandas,
      tempoLimiteSemAprovarNegociacao,
      tempoLimiteSemVisualizarNegociacao,
    } = await buscarDemandasNegociador(idNegociador);
    const filiaisDistintas = separarFiliaisDistintas(
      demandas.map((filial) => filial.nomeFilial),
    );
    const demandasNegociadorPorFilial = separarDemandasPorFilial(
      filiaisDistintas,
      demandas,
    );
    const notificacoesNegociadorPorCategoria = separarDemandasComNotificacaoPorCategoria(
      [...demandas].filter((demanda) => demanda.categorias.length),
      formatarIntervaloTempo(tempoLimiteSemVisualizarNegociacao),
      formatarIntervaloTempo(tempoLimiteSemAprovarNegociacao),
    );
    dispatch(
      listarDemandasNegociador({
        demandasNegociadorPorFilial,
        notificacoesNegociadorPorCategoria,
        erro: false,
      }),
    );
  } catch {
    dispatch(
      listarDemandasNegociador({
        demandasNegociadorPorFilial: demandasNegociadorPorFilialEstadoErro,
        notificacoesNegociadorPorCategoria: notificacoesNegociadorPorCategoriaEstadoErro,
        erro: true,
      }),
    );
  } finally {
    setCarregando(false);
  }
};
