import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import SelectTipoValorContraproposta from 'features/chatNegociacao/views/ChatResposta/RespostaContraproposta/DadosContraproposta/InputValorContraproposta/SelectValorContraproposta';
import InputValorContraproposta from 'features/chatNegociacao/views/ChatResposta/RespostaContraproposta/DadosContraproposta/InputValorContraproposta/InputValorContraproposta';
import { RootState } from '../../../../store/reducer';
import TipoValorContraproposta from '../../../chatNegociacao/constants/TipoValorContraproposta';
import { atualizarValorNegociacao } from './redux/valoresCardAction';
import BadgesValor from '../../../../shared/components/badgesValor/BadgesValor';

const EditarValorProposto = (): JSX.Element => {
  const dispatch = useDispatch();

  const {
    valorNegociacao,
    valorFreteTerceiro,
    valorFrotaPropria,
  } = useSelector((state: RootState) => state.valoresCardReducer);

  const { negociacao } = useSelector(
    (state: RootState) => state.informacoesNegociacaoReducer,
  );

  const [tipoValor, setTipoValor] = useState<number>(
    TipoValorContraproposta.ValorProposto,
  );

  const onChangeSelectTipo = (novoValor: number) => {
    setTipoValor(novoValor);
  };

  const onChangeInputValor = (novoValor: number | undefined) => {
    dispatch(atualizarValorNegociacao(novoValor));
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <SelectTipoValorContraproposta
          value={tipoValor}
          onChange={onChangeSelectTipo}
        />
        <InputValorContraproposta
          tipo={tipoValor}
          valorInput={valorNegociacao}
          handleInput={onChangeInputValor}
        />
      </Box>
      <BadgesValor
        valor={valorNegociacao}
        tipoValor={tipoValor}
        valorFreteTerceiro={valorFreteTerceiro || 0}
        valorFrotaPropria={valorFrotaPropria || 0}
        valorEntregas={negociacao?.carga.entregas || 0}
        valorMercadoria={negociacao?.carga.valor || 0}
        valorPeso={negociacao?.carga.peso || 0}
        valorQuilometragem={negociacao?.carga.quilometragem || 0}
        justificar="flex-start"
      />
    </>
  );
};

export default EditarValorProposto;
