export enum Pagina {
  MinhasNegociacoes,
  DetalhesCarga,
  InformacoesNegociacao,
  ListaCargas,
  MinhasCargas,
  UploadSimulador,
  AcompanhamentoNegociacao,
  PaginaNaoEncontrada,
  AprovacoesSupervisor,
  DetalhesAprovacaoSupervisor,
  Configuracoes,
  PainelGestao,
  DemandasAndamento,
  DetalhesDemandaDiaria,
  Indicadores,
  NegociacoesAutomatizadas,
  InformacoesNegociacaoAutomatizadas,
  MotoristasVeiculos,
}

export type PaginaType = Pagina;
