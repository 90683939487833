import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';

import TextField from '../input/TextField';
import debounce from '../../functions/debounce';

const Pesquisa = withStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 0, 0, 0.5),
    '& .MuiOutlinedInput-input': {
      background: '#FFFFFF',
      padding: '7px 14px',
    },
    '& .MuiOutlinedInput-root fieldset': {
      border: 'none',
    },

    '& .MuiInputBase-root': {
      background: '#FFFFFF',
      color: '#6D7384',
      paddingRight: '7px',
    },
  },
}))(TextField);

export type FiltroPesquisaProps = {
  pesquisa?: string;
  pesquisar: (valor: string) => void;
  id: string;
  maxLength?: number;
  debounceTime?: number;
};

const FiltroPesquisa = ({
  id,
  pesquisa,
  pesquisar,
  maxLength = 100,
  debounceTime = 1000,
}: FiltroPesquisaProps): JSX.Element => {
  const debouncedPesquisar = debounce(pesquisar, debounceTime);

  return (
    <Pesquisa
      id={id}
      placeholder="Pesquisar"
      variant="outlined"
      defaultValue={pesquisa}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        debouncedPesquisar(event.target.value);
      }}
      InputProps={{
        inputProps: { maxLength },
        endAdornment: (
          <InputAdornment position="end">
            <SearchRoundedIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default FiltroPesquisa;
