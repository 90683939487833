import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import spacingInPixels from 'shared/functions/materialUtils';
import theme from 'theme';
import { RootState } from '../../../store/reducer';
import FiltroLista from '../../../shared/components/filters/FiltroLista';
import FiltroData from '../../../shared/components/filters/FiltroData';
import FiltroPesquisa from '../../../shared/components/filters/FiltroPesquisa';
import TituloNumerado from '../../../shared/components/titulo/Titulo';
import { DropdownCheckboxOption } from '../../../shared/components/dropdown/dropdownCheckbox/views/DropdownCheckbox';
import { ItemFilial } from '../api/listaCargasApiTypes';
import {
  atualizarDataFim,
  atualizarDataInicio,
  atualizarFiliaisSelecionadas,
  atualizarPesquisa,
} from '../redux/listaCargasAction';
import TabDados from './TabDados';
import { TituloListaCargasProps } from '../types/TituloListaCargas';

const TituloListaCargas = ({
  stateOrigemCarga,
  updateStateOrigemCarga,
}: TituloListaCargasProps): JSX.Element => {
  const dispatch = useDispatch();

  const {
    dataFim,
    dataInicio,
    filiaisSelecionadas,
    pesquisa,
    filiais,
    cargasPlanilha,
    cargasPlanejador,
  } = useSelector((reducer: RootState) => reducer.listaCargasReducer);

  const pesquisar = (valor: string) => {
    dispatch(atualizarPesquisa(valor));
  };

  const onFiliaisChange = (valor: string[]) => {
    dispatch(atualizarFiliaisSelecionadas(valor));
  };

  const onDataInicioChange = (valor: Date | null) => {
    dispatch(atualizarDataInicio(valor));
  };

  const onDataFimChange = (valor: Date | null) => {
    dispatch(atualizarDataFim(valor));
  };

  const converterFilialParaListaOpcoes = (
    filial: ItemFilial,
  ): DropdownCheckboxOption => ({
    label: filial.descricao,
    name: String(filial.id),
  });

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <TituloNumerado
          titulo="Lista de Cargas"
          quantidade={
            cargasPlanilha.quantidadeCargas + cargasPlanejador.quantidadeCargas
          }
        />
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        paddingBottom={spacingInPixels(theme, 2)}
        flexWrap="wrap"
        gridGap={spacingInPixels(theme, 1.5)}
      >
        <TabDados
          aba={stateOrigemCarga}
          planilhaNumber={cargasPlanilha.quantidadeCargas}
          planejadorNumber={cargasPlanejador.quantidadeCargas}
          setAba={updateStateOrigemCarga}
        />
        <Box display="flex">
          <FiltroPesquisa
            id="PesquisaCargas"
            pesquisa={pesquisa}
            pesquisar={pesquisar}
          />
          <FiltroData
            texto="Data"
            titulo="Data de geração"
            dataFim={dataFim}
            dataInicio={dataInicio}
            onDataFimChange={onDataFimChange}
            onDataInicioChange={onDataInicioChange}
          />
          <FiltroLista
            onChange={onFiliaisChange}
            opcoesSelecionadas={filiaisSelecionadas}
            lista={filiais.map(converterFilialParaListaOpcoes)}
          />
        </Box>
      </Box>
    </>
  );
};

export default TituloListaCargas;
