import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import { Button, ButtonGroup, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import NegociacaoStatus from 'shared/constants/NegociacaoStatus';
import ConfiguracaoAba, {
  OpcoesConfiguracaoAba,
} from '../constants/configuracaoAbaSwitch';
import FiltroStatus, { OpcoesFiltroStatus } from '../constants/filtroStatus';
import { atualizarFiltroStatusAutomatizados } from '../redux/negociacoesAutomatizadasAction';
// import { Negociacao } from '../api/minhasNegociacoesApiTypes';

// import FiltrosNegociacao from './FiltrosNegociacao';
export type CabecalhoNegociacaoAutomatizadasProps = {
  selecionarAba: ConfiguracaoAba;
  setSelecionarAba: (aba: ConfiguracaoAba) => void;
};

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    boxShadow: 'none',
    border: '1px solid #d9d9d9',
  },
  buttons: {
    fontWeight: 400,
    textTransform: 'none',
    backgroundColor: theme.palette.cores.azul[600],
    color: 'white',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: theme.palette.cores.azul[500],
    },
  },
  selectedButton: {
    backgroundColor: theme.palette.cores.azul[600],
    color: 'white',
    borderRadius: '16px',
    margin: '0 5px',
    boxShadow: 'none',
    fontWeight: 400,
    textTransform: 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: theme.palette.cores.azul[500],
    },
  },
  disableButton: {
    backgroundColor: '#eeeeee',
    color: 'black',
    borderRadius: '16px',
    margin: '0 5px',
    boxShadow: 'none',
    fontWeight: 400,
    textTransform: 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#e2e2e2',
    },
  },
  disabledAba: {
    fontWeight: 400,
    textTransform: 'none',
    backgroundColor: '#F5F5F561 !important',
  },
}));

const CabecalhoNegociacaoAutomatizadas = ({
  selecionarAba,
  setSelecionarAba,
}: CabecalhoNegociacaoAutomatizadasProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [filtroBotoes, setFiltroBotoes] = useState<FiltroStatus>(0);
  const filtroStatusMap: Record<FiltroStatus, string[]> = {
    [FiltroStatus.todos]: ['0', '1', '2', '3', '4', '5'],
    [FiltroStatus.emAndamento]: ['1'],
    [FiltroStatus.tempoExpirado]: ['5'],
    [FiltroStatus.concluidos]: ['4'],
    [FiltroStatus.cancelados]: ['2'],
  };

  useEffect(() => {
    dispatch(
      atualizarFiltroStatusAutomatizados([
        NegociacaoStatus.NovaNegociacao.toString(),
        NegociacaoStatus.EmAndamento.toString(),
        NegociacaoStatus.AguardarGestao.toString(),
        NegociacaoStatus.Concluida.toString(),
        NegociacaoStatus.Cancelada.toString(),
        NegociacaoStatus.TempoExpirado.toString(),
      ]),
    );
  }, [dispatch]);

  const selecionarFiltroStatus = (statusId: FiltroStatus) => {
    setFiltroBotoes(statusId);
    dispatch(atualizarFiltroStatusAutomatizados(filtroStatusMap[statusId]));
  };
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      marginBottom="3%"
    >
      <ButtonGroup variant="contained" className={classes.buttonGroup}>
        {OpcoesConfiguracaoAba.map((aba) => (
          <Button
            key={aba.id}
            className={`${
              aba.id !== selecionarAba ? classes.disabledAba : classes.buttons
            }`}
            onClick={() => setSelecionarAba(aba.id)}
            disabled={aba.id !== selecionarAba}
            size="small"
          >
            {aba.valor}
          </Button>
        ))}
      </ButtonGroup>
      <Box>
        {OpcoesFiltroStatus.map((status) => (
          <Button
            key={status.id}
            variant="contained"
            size="small"
            className={
              filtroBotoes === status.id
                ? classes.selectedButton
                : classes.disableButton
            }
            onClick={() => selecionarFiltroStatus(status.id)}
          >
            {status.valor}
          </Button>
        ))}
      </Box>
      {/* <FiltrosNegociacao negociacoes={negociacoes} /> */}
    </Box>
  );
};

export default CabecalhoNegociacaoAutomatizadas;
