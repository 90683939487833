import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useTheme, makeStyles } from '@material-ui/core/styles';

import CustomWidthTooltip from '../../../shared/components/tooltip/CustomWidthTooltip';
import { spacingInPixels } from '../../../shared/functions/materialUtils';
import { NegociacaoStatusType } from '../../../shared/constants/NegociacaoStatus';
import { obterRomaneioFormatado } from '../../../shared/functions/romaneioUtils';

import BoxStatusNegociacao from '../../minhasNegociacoes/views/BoxStatusNegociacao';

import Titulo from './Titulo';

const useStyles = makeStyles((theme) => ({
  boxStatusNegociacao: {
    '& img': {
      margin: theme.spacing(0, 0, 0, 2),
    },
  },
  romaneio: {
    fontSize: '16px',
    fontWeight: 700,
    margin: theme.spacing(0, 2, 0, 0),
  },
}));

type CabecalhoProps = {
  titulo: string;
  voltar: () => void;
  romaneio: string | undefined;
  status: NegociacaoStatusType | undefined;
  urgente: boolean | undefined;
};

const Cabecalho = ({
  titulo,
  voltar,
  romaneio,
  status,
  urgente,
}: CabecalhoProps): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      marginBottom={spacingInPixels(theme, 3)}
    >
      <Titulo titulo={titulo} voltar={voltar} />

      {status !== undefined && (
        <Box display="flex">
          <CustomWidthTooltip title={romaneio}>
            <Typography className={classes.romaneio} variant="h4">
              ID {obterRomaneioFormatado(romaneio, 2)}
            </Typography>
          </CustomWidthTooltip>
          <Box className={classes.boxStatusNegociacao}>
            <BoxStatusNegociacao
              codigoStatus={status}
              isNegociacaoUrgente={urgente}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Cabecalho;
