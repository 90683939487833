import React from 'react';
import { useDispatch } from 'react-redux';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import { makeStyles } from '@material-ui/core';

import BotaoVermelho from '../../../shared/components/button/BotaoRubikVermelho';
import { Recusar } from '../redux/chatNegociacaoReducer';
import { atualizarExibicaoRespostaNegociador } from '../redux/chatNegociacaoAction';
import { Rubik } from '../../../shared/constants/font';

export type ChatRecusarPropostaProps = {
  handleClose: () => void;
};

const useStyles = makeStyles(() => ({
  botao: {
    fontFamily: Rubik,
    fontSize: '16px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const ChatRecusarProposta = ({
  handleClose,
}: ChatRecusarPropostaProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onClickRecusar = (): void => {
    dispatch(atualizarExibicaoRespostaNegociador(Recusar));
    handleClose();
  };
  return (
    <BotaoVermelho
      className={classes.botao}
      startIcon={<ClearRoundedIcon />}
      onClick={onClickRecusar}
    >
      Recusar proposta
    </BotaoVermelho>
  );
};

export default ChatRecusarProposta;
