import { makeStyles } from '@material-ui/core';
import spacingInPixels from '../../../shared/functions/materialUtils';

export const useStylesCardProposta = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.cores.branco,
    padding: spacingInPixels(theme, 2),
    borderRadius: spacingInPixels(theme, 1),
    flexDirection: 'column',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
  },
  boxSpaceBetween: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  badgePorcentagem: {
    borderRadius: spacingInPixels(theme, 2),
    margin: 0,
  },
  boxIntervalo: {
    display: 'flex',
    width: '100%',
    marginTop: spacingInPixels(theme, 1.5),
  },
}));

export default useStylesCardProposta;
