import React, { useMemo, useState } from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';
import { KeyboardArrowDownRounded } from '@material-ui/icons';
import CustomTooltip from 'shared/components/tooltip/CustomTooltip';
import { NegociacaoTipoAlerta } from 'features/dashboards/negociadores/api/listagemNegociadoresApiTypes';

const useStyles = makeStyles((theme: Theme) => ({
  fonte: {
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: spacingInPixels(theme, 2),
    lineHeight: spacingInPixels(theme, 4),
    color: theme.palette.cores.cinza[800],
  },
  ordenacao: {
    display: 'flex',
    columnGap: spacingInPixels(theme, 0.75),
    alignItems: 'center',
    '& > span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '90%',
    },
    '&[data-ordenacao=true]': {
      cursor: 'pointer',
      '& > span': {
        width: 'fit-content',
      },
    },
    '&[data-ordenacao=true]:hover': {
      backgroundColor: theme.palette.cores.cinza[50],
    },
    '& > svg[data-crescente=true]': {
      transform: 'rotate(180deg)',
    },
    '& > svg[data-selecionado=true]': {
      fill: theme.palette.cores.azul[500],
    },
  },
}));

export enum ChaveCabecalho {
  status = 'status',
  negociador = 'negociador',
  sla = 'sla',
  tempoAlerta = 'tempoAlerta',
  filial = 'filial',
  cidade = 'cidade',
  romaneio = 'romaneio',
}

type ItemCabecalho = {
  chave: ChaveCabecalho;
  label: string;
  possuiOrdenacao: boolean;
};

export interface CabecalhoTabelaDemandasProps {
  item: ItemCabecalho;
  categoriaAtual?: NegociacaoTipoAlerta;
  onClickOrdenacao: (
    chave: ChaveCabecalho,
    sentidoOrdenacaoCrescente: boolean,
  ) => void;
}

const CabecalhoTabelaDemandas = ({
  item,
  categoriaAtual,
  onClickOrdenacao,
}: CabecalhoTabelaDemandasProps): JSX.Element => {
  const classes = useStyles();

  const [sentidoOrdenacaoCrescente, setSentidoOrdenacaoCrescente] = useState(
    false,
  );
  const [selecionado, setSelecionado] = useState(false);

  const handleClickOrdenacao = () => {
    setSentidoOrdenacaoCrescente((valorAnterior) => !valorAnterior);
    setSelecionado(true);
    onClickOrdenacao(item.chave, sentidoOrdenacaoCrescente);
  };

  useMemo(() => {
    setSentidoOrdenacaoCrescente(false);
    setSelecionado(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriaAtual]);

  return (
    <CustomTooltip title={item.label}>
      <Box
        className={classes.ordenacao}
        data-ordenacao={item.possuiOrdenacao}
        key={`cabecalho-tabela-${item.chave}`}
        onClick={() => item.possuiOrdenacao && handleClickOrdenacao()}
      >
        <span className={classes.fonte}>{item.label}</span>
        {item.possuiOrdenacao && (
          <KeyboardArrowDownRounded
            data-crescente={sentidoOrdenacaoCrescente}
            data-selecionado={selecionado}
          />
        )}
      </Box>
    </CustomTooltip>
  );
};

export default CabecalhoTabelaDemandas;
