import { useSelector } from 'react-redux';
import React, { useState } from 'react';
import { RootState } from 'store/reducer';
import { makeStyles } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';
import { IndicadorNegociacao } from 'features/indicadores/api/indicadoresApiTypes';
import { getDadosIndicador } from 'features/indicadores/utils/evolucaoDeCargas';
import ConteudoErro from 'features/indicadores/components/ConteudoErro';
import {
  mensagemErro,
  mensagemListaVazia,
} from 'features/indicadores/constants/Constants';
import SeletorIndicadores from './SeletorIndicadores';
import GraficoEvolucaoDeCargas from './GraficoEvolucaoDeCargas';
import TabelaEvolucaoDeCargas from './TabelaEvolucaoDeCargas';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacingInPixels(theme, 2.5),
    minHeight: 428,
  },
}));

interface ConteudoEvolucaoDeCargasProps {
  erro: boolean;
  onClickTentarNovamente: () => void;
  dadosIndicadores: IndicadorNegociacao[];
  valoresPeriodo: string[];
}

const ConteudoEvolucaoDeCargas = ({
  erro,
  onClickTentarNovamente,
  dadosIndicadores,
  valoresPeriodo,
}: ConteudoEvolucaoDeCargasProps): JSX.Element => {
  const classes = useStyles();

  const { indicador1, indicador2 } = useSelector(
    (reducer: RootState) => reducer.evolucaoDeCargasReducer,
  );

  const [dadosIndicadores1, setDadosIndicadores1] = useState(
    getDadosIndicador(dadosIndicadores, indicador1.chave),
  );
  const [dadosIndicadores2, setDadosIndicadores2] = useState(
    getDadosIndicador(dadosIndicadores, indicador2.chave),
  );

  const handleSelecaoIndicador1 = (chave: string) => {
    setDadosIndicadores1(getDadosIndicador(dadosIndicadores, chave));
  };

  const handleSelecaoIndicador2 = (chave: string) => {
    setDadosIndicadores2(getDadosIndicador(dadosIndicadores, chave));
  };

  const handleExibicaoConteudo = () => {
    if (erro)
      return (
        <ConteudoErro
          onClickBotao={onClickTentarNovamente}
          mensagem={mensagemErro}
        />
      );
    if (dadosIndicadores.length === 0)
      return <ConteudoErro mensagem={mensagemListaVazia} />;
    return (
      <>
        <GraficoEvolucaoDeCargas
          periodo={valoresPeriodo}
          valoresIndicador1={dadosIndicadores1}
          valoresIndicador2={dadosIndicadores2}
        />
        <TabelaEvolucaoDeCargas
          valoresPeriodo={valoresPeriodo}
          valoresIndicador1={dadosIndicadores1}
          tipoIndicador1={indicador1.tipoValor}
          valoresIndicador2={dadosIndicadores2}
          tipoIndicador2={indicador2.tipoValor}
        />
      </>
    );
  };

  return (
    <section className={classes.container}>
      <SeletorIndicadores
        desabilitarSeletores={erro || dadosIndicadores.length === 0}
        onSelecaoIndicador1={handleSelecaoIndicador1}
        onSelecaoIndicador2={handleSelecaoIndicador2}
      />
      {handleExibicaoConteudo()}
    </section>
  );
};

export default ConteudoEvolucaoDeCargas;
