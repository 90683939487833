import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import { PopoverOrigin } from '@material-ui/core/Popover';

import ButtonDropdown from '../../ButtonDropdown';
import PopoverDropdown from './PopoverDropdown';

export type DropdownCheckboxOption = {
  label: string;
  name: string;
};

export type DropdownCheckboxProps = {
  id: string;
  texto: string;
  titulo: string;
  marginLeft?: React.CSSProperties['marginLeft'];
  opcoes?: DropdownCheckboxOption[];
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  opcoesSelecionadas?: string[];
  onChange: (opcoesSelecionadas: string[]) => void;
};

const defaultAnchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
};

const defaultTransformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

const DropdownCheckbox = ({
  id,
  texto,
  titulo,
  marginLeft = 0,
  onChange,
  opcoes = [],
  opcoesSelecionadas = [],
  anchorOrigin = defaultAnchorOrigin,
  transformOrigin = defaultTransformOrigin,
}: DropdownCheckboxProps): JSX.Element => {
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(
    null,
  );

  const open = Boolean(anchorElement);
  const dropdownId = open ? id : undefined;

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  return (
    <Box marginLeft={marginLeft}>
      <ButtonDropdown
        texto={texto}
        titulo={titulo}
        open={open}
        aria-describedby={dropdownId}
        onClick={handleOpen}
        invisible={opcoesSelecionadas.length === 0}
      />
      <PopoverDropdown
        id={dropdownId}
        open={open}
        titulo={titulo}
        anchorElement={anchorElement}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onClose={handleClose}
        onChange={onChange}
        opcoes={opcoes}
        opcoesSelecionadas={opcoesSelecionadas}
      />
    </Box>
  );
};

export default DropdownCheckbox;
