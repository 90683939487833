import React from 'react';
import BoxPagina from 'shared/components/box/BoxPagina';
import Detalhes from 'features/detalhesAprovacaoSupervisor/DetalhesAprovacaoNegociacaoSupervisor';

const PaginaDetalhesAprovacaoNegociacaoSupervisor = (): JSX.Element => {
  return (
    <BoxPagina component="section">
      <Detalhes />
    </BoxPagina>
  );
};

export default PaginaDetalhesAprovacaoNegociacaoSupervisor;
