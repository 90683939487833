import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import IconeInformacao from '../../../assets/icones/IconeInformacao';
import spacingInPixels from '../../../shared/functions/materialUtils';

export type ViagensVaziasProps = {
  texto: string;
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.cores.branco,
  },
  textoViagensVazias: {
    fontSize: '14px',
    color: theme.palette.cores.cinza[700],
    fontWeight: 400,
    marginTop: spacingInPixels(theme, 1),
    textAlign: 'center',
  },
}));

const ViagensVazias = ({ texto }: ViagensVaziasProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <IconeInformacao />
      <Typography className={classes.textoViagensVazias}>{texto}</Typography>
    </Box>
  );
};

export default ViagensVazias;
