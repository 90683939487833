import { makeStyles } from '@material-ui/core';
import React from 'react';
import { PrioridadeCarga, PrioridadeCargaEnum } from '../constants/Types';
import ChipPrioridadeCarga from './ChipPrioridadeCarga';

const useStyles = makeStyles(() => ({
  prioridadeCarga: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: '6px',
    paddingLeft: '0.8vw',
    height: '52px',
  },
}));

interface ContainerPrioridadeDeCargaProps {
  prioridadeCarga: PrioridadeCarga;
}

const ContainerPrioridadeDeCarga = ({
  prioridadeCarga,
}: ContainerPrioridadeDeCargaProps): JSX.Element => {
  const classes = useStyles();
  const { maxima, media, baixa } = prioridadeCarga;

  return (
    <section className={classes.prioridadeCarga}>
      <ChipPrioridadeCarga
        valor={maxima || 0}
        prioridade={PrioridadeCargaEnum.maxima}
      />
      <ChipPrioridadeCarga
        valor={media || 0}
        prioridade={PrioridadeCargaEnum.media}
      />
      <ChipPrioridadeCarga
        valor={baixa || 0}
        prioridade={PrioridadeCargaEnum.baixa}
      />
    </section>
  );
};

export default ContainerPrioridadeDeCarga;
