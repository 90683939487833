import React from 'react';
import { Box, makeStyles, Typography, withStyles } from '@material-ui/core';

import spacingInPixels from '../../../../shared/functions/materialUtils';
import { Rubik } from '../../../../shared/constants/font';
import { porcentagemStylesFundo } from '../../../../shared/styles/porcentagemStyles';
import { obterCorPorcentagem } from '../../../../shared/functions/porcentagemUtils';

export type CalcularPorcentagemProps = {
  texto: string;
  complemento?: string;
  porcentagem: number;
  esconder?: boolean;
  condensado?: boolean;
};

const TypographyTexto = withStyles((theme) => ({
  root: {
    fontFamily: Rubik,
    fontSize: '12px',
    fontWeight: 400,
    color: theme.palette.cores.branco,
    textAlign: 'center',
    height: '28px',
    alignItems: 'center',
    display: 'flex',
    lineHeight: '14px',
    justifyContent: 'center',
    fontStyle: 'normal',
    minWidth: '50px',
    padding: '8px 16px 8px 16px',
  },
}))(Typography);

export const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: spacingInPixels(theme, 9),

    borderRadius: theme.spacing(5),
    padding: theme.spacing(0.2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    height: ({ condensado }: { condensado?: boolean }) =>
      condensado ? 24 : 'unset',
  },
}));

const CalcularPorcentagem = ({
  texto,
  complemento,
  porcentagem,
  esconder,
  condensado = false,
}: CalcularPorcentagemProps): JSX.Element => {
  const classes = useStyles({ condensado });
  const estilosPorcentagem = porcentagemStylesFundo();
  return esconder ? (
    <></>
  ) : (
    <Box
      className={`${classes.container} ${
        estilosPorcentagem[obterCorPorcentagem(porcentagem)]
      }`}
    >
      <TypographyTexto color="primary">
        {texto} {complemento}
      </TypographyTexto>
    </Box>
  );
};

export default CalcularPorcentagem;
