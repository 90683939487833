import React from 'react';

import TypographyChat from '../../TypographyChat';

import { ChatBalaoProps } from '../ChatBalao';
import ValorContraProposta from '../ValorContraProposta';

const AceiteMotorista = ({ mensagem }: ChatBalaoProps): JSX.Element => (
  <>
    <TypographyChat>
      Eu aceito esse valor de{' '}
      <ValorContraProposta valorContraproposta={mensagem.valorContraproposta} />
      .
    </TypographyChat>
  </>
);

export default AceiteMotorista;
