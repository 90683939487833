import React from 'react';
import { Box, useTheme } from '@material-ui/core';

import spacingInPixels from '../../functions/materialUtils';
import {
  calcularPorcentagemInteira,
  calcularPorcentagemSomenteParteInteira,
} from '../../functions/porcentagemUtils';
import TipoValorContraproposta from '../../../features/chatNegociacao/constants/TipoValorContraproposta';

import BadgePadrao from './BadgePadrao';
import BadgePorcentagem from './BadgePorcentagem';
import { exibirValorRelacionado } from '../../functions/valorRelacionadoUtils';

export type BadgesValorProps = {
  valor?: number;
  tipoValor: TipoValorContraproposta;
  valorFrotaPropria: number;
  valorFreteTerceiro: number;
  valorMercadoria?: number;
  valorQuilometragem?: number;
  valorEntregas?: number;
  valorPeso?: number;
  justificar?:
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-evenly'
    | 'space-around'
    | 'center';
  condensado?: boolean;
};

const BadgesValor = ({
  valor,
  tipoValor,
  valorEntregas,
  valorFreteTerceiro,
  valorFrotaPropria,
  valorMercadoria,
  valorPeso,
  valorQuilometragem,
  justificar = 'space-evenly',
  condensado = false,
}: BadgesValorProps): JSX.Element => {
  const theme = useTheme();

  return (
    <>
      <Box
        marginY={spacingInPixels(theme, condensado ? 0 : 1)}
        display="flex"
        justifyContent={justificar}
        flexWrap="wrap"
      >
        <BadgePorcentagem
          porcentagem={calcularPorcentagemSomenteParteInteira(
            valor || 0,
            valorFrotaPropria,
          )}
          texto={`${calcularPorcentagemInteira(valor, valorFrotaPropria)}%`}
          complemento="Target"
          esconder={
            tipoValor === TipoValorContraproposta.PorcentagemFrotaPropria
          }
          condensado={condensado}
        />
        <BadgePorcentagem
          porcentagem={calcularPorcentagemInteira(
            valor || 0,
            valorFreteTerceiro,
          )}
          texto={`${calcularPorcentagemInteira(valor, valorFreteTerceiro)}% `}
          complemento="FT"
          esconder={
            tipoValor === TipoValorContraproposta.PorcentagemFreteTerceiro
          }
          condensado={condensado}
        />

        <BadgePorcentagem
          porcentagem={calcularPorcentagemInteira(
            valor || 0,
            valorFrotaPropria,
          )}
          texto={`Valor Proposto: R$ ${exibirValorRelacionado(
            TipoValorContraproposta.ValorProposto,
            valor,
          )}`}
          esconder={tipoValor === TipoValorContraproposta.ValorProposto}
          condensado={condensado}
        />
        {condensado && (
          <BadgePadrao
            texto={`R$ ${exibirValorRelacionado(
              TipoValorContraproposta.PorEntrega,
              valor,
              valorEntregas,
            )} por entrega`}
            esconder={tipoValor === TipoValorContraproposta.PorEntrega}
            condensado={condensado}
          />
        )}
      </Box>

      <Box
        marginTop={spacingInPixels(theme, condensado ? 0 : 1)}
        marginBottom={spacingInPixels(theme, 1)}
        display="flex"
        justifyContent={justificar || 'space-evenly'}
        flexWrap="wrap"
      >
        {!condensado && (
          <BadgePadrao
            texto={`R$ ${exibirValorRelacionado(
              TipoValorContraproposta.PorEntrega,
              valor,
              valorEntregas,
            )} por entrega`}
            esconder={tipoValor === TipoValorContraproposta.PorEntrega}
            condensado={condensado}
          />
        )}

        <BadgePadrao
          texto={`R$ ${exibirValorRelacionado(
            TipoValorContraproposta.PorPeso,
            valor,
            valorPeso,
          )} por kg`}
          esconder={tipoValor === TipoValorContraproposta.PorPeso}
          condensado={condensado}
        />
        <BadgePadrao
          texto={`R$ ${exibirValorRelacionado(
            TipoValorContraproposta.PorKm,
            valor,
            valorQuilometragem,
          )} por km`}
          esconder={tipoValor === TipoValorContraproposta.PorKm}
          condensado={condensado}
        />

        <BadgePadrao
          texto={`${exibirValorRelacionado(
            TipoValorContraproposta.PorcentagemMercadoria,
            valor,
            valorMercadoria,
          )}% mercadoria`}
          esconder={tipoValor === TipoValorContraproposta.PorcentagemMercadoria}
          condensado={condensado}
        />
      </Box>
    </>
  );
};

export default BadgesValor;
