import Typography from '@material-ui/core/Typography';
import React from 'react';

export type BoldProps = {
  color?: string;
  fontSize?: string;
  fontWeight?: number;
  children?: string;
  lineHeight?: string;
};

const Bold = ({
  color,
  lineHeight,
  fontSize = '16px',
  fontWeight = 700,
  children = '',
}: BoldProps): JSX.Element => {
  return (
    <Typography
      component="span"
      display="inline"
      style={{ fontSize, fontWeight, lineHeight, color }}
    >
      {children}
    </Typography>
  );
};

export default Bold;
