import { makeStyles } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';

export const useStyles = makeStyles((theme) => ({
  header: {
    height: 64,
    minHeight: 64,
    borderBottom: '1px  solid #d7d7d7',
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '0 36px',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 42,
  },
  icone: {
    padding: spacingInPixels(theme, 0.5),
    margin: theme.spacing(0, 1, 0, -0.5),
    width: 'fit-content',
    height: 'fit-content',
  },
  linkCss: {
    fontWeight: 400,
    fontSize: 12,
    color: theme.palette.cores.cinza[700],
    lineHeight: 14,
    cursor: 'pointer',
    height: 14,
    '&:hover': {
      color: theme.palette.cores.azul[500],
    },
  },
  textoAtivo: {
    color: theme.palette.cores.azul[500],
  },
  textoAtivoAmbienteAssistido: {
    color: theme.palette.cores.cinza[700],
    cursor: 'default',
    textDecoration: 'none !important',
    textTransform: 'none',
    '&:hover': {
      color: theme.palette.cores.cinza[700],
    },
  },
  flexBox: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  flexBoxSino: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
