import { makeStyles, Typography } from '@material-ui/core';
import IconeQuadradoArredondado from 'assets/icones/IconeQuadradoArredondado';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  texto: {
    font: 'Rubik',
    fontSize: '13px',
    fontWeight: 400,
    color: theme.palette.cores.cinza[700],
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontFamily: `Rubik`,
    marginLeft: '8px',
  },
}));

export type LabelGraficoProps = {
  cor: string;
  texto: string;
};

const LabelGrafico = ({ cor, texto }: LabelGraficoProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <IconeQuadradoArredondado fill={cor} />
      <Typography className={classes.texto}>{texto}</Typography>
    </div>
  );
};

export default LabelGrafico;
