import { withStyles } from '@material-ui/core/styles';

import AccordionSummary from '@material-ui/core/AccordionSummary';

export default withStyles({
  root: {
    minHeight: '32px',
    '&.Mui-expanded': {
      minHeight: '32px',
    },
  },
  expandIcon: {
    order: ({ order }: { order?: number }) => order || -1,
  },
})(AccordionSummary);
