import { Box, Button, Typography, withStyles } from '@material-ui/core';
import spacingInPixels from '../../../shared/functions/materialUtils';

export const BoxChatRodape = withStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacingInPixels(theme, 1),
  },
}))(Box);

export const BotaoFechar = withStyles({
  root: {
    width: '5px',
  },
})(Button);

export const BoxCampoTextoLivre = withStyles({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
  },
})(Box);

export const TypographyTextoMenu = withStyles((theme) => ({
  root: {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 400,
    marginLeft: '8px',
    color: `${theme.palette.cores.azulMartins[700]}`,
    display: 'flex',
    alignItems: 'center',
    height: '52px',
  },
}))(Typography);
