import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button } from '@material-ui/core';
import DisablingTooltip from '../../../shared/components/tooltip/DisablingTooltip';
import { RootState } from '../../../store/reducer';
import IconeCaixaUpload from './IconeCaixaUpload';
import TextoCaixaUpload from './TextoCaixaUpload';
import { changeHandler } from '../utils/uploadSimulador';
import { useStyles } from '../styles/styleCaixaUpload';
import { TextoCaixaUploadProps } from '../types/TextoCaixaUpload';

const CaixaUpload = ({ aba }: TextoCaixaUploadProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { arquivoEstaSelecionado } = useSelector(
    (state: RootState) => state.uploadSimuladorReducer,
  );

  const idInputArquivo = 'input-arquivo';
  const titleBotaoArquivo = 'Buscar arquivo';

  return (
    <Box className={classes.caixaUpload}>
      <IconeCaixaUpload />
      <TextoCaixaUpload aba={aba} />
      {!arquivoEstaSelecionado && (
        <Box>
          <label htmlFor={idInputArquivo} className={classes.labelInputArquivo}>
            <input
              type="file"
              hidden
              id={idInputArquivo}
              accept=".xlsx"
              onChange={(evento: React.ChangeEvent<HTMLInputElement>) =>
                changeHandler(evento, dispatch)
              }
            />
            <DisablingTooltip title={titleBotaoArquivo}>
              <Button
                variant="contained"
                color="primary"
                className={classes.botaoBuscar}
                component="span"
              >
                {titleBotaoArquivo}
              </Button>
            </DisablingTooltip>
          </label>
        </Box>
      )}
    </Box>
  );
};

export default CaixaUpload;
