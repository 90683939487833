const debounce = <Params extends unknown[]>(
  funcao: (...args: Params) => unknown,
  tempo: number,
): ((...args: Params) => void) => {
  let temporizador: NodeJS.Timeout;

  return (...args: Params) => {
    clearTimeout(temporizador);
    temporizador = setTimeout(() => {
      funcao(...args);
    }, tempo);
  };
};

export default debounce;
