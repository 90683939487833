import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import { Rubik } from '../../constants/font';
import spacingInPixels from '../../functions/materialUtils';

export default withStyles((theme) => ({
  root: {
    textTransform: 'none',
    fontFamily: Rubik,
    fontWeight: 500,
    fontSize: '14px',
    width: 300,
    borderRadius: spacingInPixels(theme, 3),
  },
  outlinedPrimary: {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.cores.branco,
  },
}))(Button);
