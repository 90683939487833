import { DadoSkeleton } from 'shared/components/skeleton/CustomSkeleton';
import { DadosCabecalho } from 'shared/components/tabelaDemandas/types/TabelaTypes';
import { ChaveOrdenacaoDetalhesDemandas } from './Constants';

export const dadosSkeletonNegociacoesRecusadas: DadoSkeleton[] = [
  { borderRadius: 4, width: '80%' },
  { borderRadius: 4, width: '90%' },
  { borderRadius: 4, width: '70%' },
  { borderRadius: 4, width: '70%' },
  { borderRadius: 4, width: '90%' },
];

export const dadosItensCabecalhoNegociacoesRecusadas: DadosCabecalho[] = [
  { conteudo: 'Romaneio', descricao: 'Romaneio' },
  {
    conteudo: 'Negociador',
    descricao: 'Negociador',
    possuiOrdenacao: true,
    chave: ChaveOrdenacaoDetalhesDemandas.NEGOCIADOR,
  },
  {
    conteudo: 'Filial',
    descricao: 'Filial',
    possuiOrdenacao: true,
    chave: ChaveOrdenacaoDetalhesDemandas.FILIAL,
  },

  { conteudo: 'Cidade', descricao: 'Cidade' },
  { conteudo: 'Motivo', descricao: 'Motivo de Recusa' },
];
