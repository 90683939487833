import { useTheme } from '@material-ui/core/styles';
import * as React from 'react';
import { SVGProps } from 'react';

const IconeDropBox = ({
  fill,
  ...outros
}: SVGProps<SVGSVGElement>): JSX.Element => {
  const theme = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...outros}>
      <path
        d="M21.61 11.8099L18.36 9.27992L21.62 6.71992C21.749 6.61871 21.8511 6.48734 21.9174 6.33737C21.9836 6.1874 22.012 6.02344 22 5.85992C21.9841 5.69702 21.9307 5.54003 21.8439 5.40123C21.7572 5.26244 21.6395 5.14561 21.5 5.05992L16.36 2.12992C16.1795 2.02837 15.9719 1.98537 15.7659 2.00687C15.5599 2.02836 15.3657 2.11329 15.21 2.24992L12 5.04992L8.79 2.24992C8.63435 2.11329 8.4401 2.02836 8.2341 2.00687C8.02811 1.98537 7.82052 2.02837 7.64 2.12992L2.5 5.05992C2.36056 5.14561 2.24285 5.26244 2.1561 5.40123C2.06935 5.54003 2.01592 5.69702 2 5.85992C1.98799 6.02344 2.01636 6.1874 2.08263 6.33737C2.14889 6.48734 2.25102 6.61871 2.38 6.71992L5.64 9.27992L2.39 11.7699C2.25919 11.8701 2.15514 12.001 2.0871 12.151C2.01906 12.301 1.98914 12.4655 2 12.6299C2.01108 12.7941 2.06252 12.953 2.14974 13.0926C2.23696 13.2321 2.35727 13.348 2.5 13.4299L5.91 15.4299V17.8999C5.91072 18.0708 5.95521 18.2387 6.03924 18.3875C6.12327 18.5363 6.24403 18.6611 6.39 18.7499L11.48 21.8799C11.632 21.9677 11.8045 22.0139 11.98 22.0139C12.1555 22.0139 12.328 21.9677 12.48 21.8799L17.57 18.7499C17.716 18.6611 17.8367 18.5363 17.9208 18.3875C18.0048 18.2387 18.0493 18.0708 18.05 17.8999V15.4199L21.46 13.4699C21.6027 13.388 21.7231 13.2721 21.8103 13.1326C21.8975 12.993 21.9489 12.8341 21.96 12.6699C21.9755 12.509 21.9518 12.3466 21.8909 12.1968C21.8299 12.0471 21.7335 11.9143 21.61 11.8099ZM16 4.21992L19.23 6.05992L16.68 8.05992L13.68 6.21992L16 4.21992ZM14.91 9.33992L12 11.3399L9.09 9.33992L12 7.54992L14.91 9.33992ZM4.79 6.05992L8 4.21992L10.31 6.21992L7.31 8.05992L4.79 6.05992ZM4.79 12.4499L7.29 10.5299L10.29 12.6099L8.07 14.3399L4.79 12.4499ZM16.08 17.3099L12 19.8299L7.91 17.3099V16.5099C8.0561 16.5458 8.2084 16.5482 8.35557 16.5171C8.50274 16.4859 8.64099 16.4219 8.76 16.3299L12 13.8299L15.24 16.3299C15.4146 16.4654 15.6291 16.5392 15.85 16.5399C15.9297 16.5496 16.0103 16.5496 16.09 16.5399L16.08 17.3099ZM15.93 14.3099L13.7 12.6099L16.7 10.5299L19.21 12.4699L15.93 14.3099Z"
        fill={fill || theme.palette.cores.azulMartins[500]}
      />
    </svg>
  );
};

export default IconeDropBox;
