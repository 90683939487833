import OrigemCarga from 'shared/constants/OrigemCarga';

export const GET = 'GET';
export const POST = 'POST';
export const PUT = 'PUT';
export const DELETE = 'DELETE';
export const PATCH = 'PATCH';

export type MetodoRest =
  | typeof GET
  | typeof POST
  | typeof PUT
  | typeof DELETE
  | typeof PATCH;

export type MetodosHttp = 'get' | 'post' | 'patch' | 'delete' | 'put';

export const CODIGO_ERRO_NAO_AUTORIZADO = 401;

export const INFORMATIONAL_1XX = '1';
export const SUCCESS_2XX = '2';
export const REDIRECTION_3XX = '3';
export const CLIENT_ERROR_4XX = '4';
export const SERVER_ERROR_5XX = '5';
export type HttpStatusCode =
  | typeof INFORMATIONAL_1XX
  | typeof SUCCESS_2XX
  | typeof REDIRECTION_3XX
  | typeof CLIENT_ERROR_4XX
  | typeof SERVER_ERROR_5XX;

export type Header = {
  Authorization?: string;
  'Content-Type'?: string;
};

export interface ErrorResponse {
  mensagens: string[];
}

export type Carga = {
  id: number;
  romaneio: string;
  idFilial: number;
  filial: string;
  cidade: string;
  entregas: number;
  peso: number;
  volume: number;
  quilometragem: number;
  valorCarga: number;
  dataGeracao: string;
  dataSolicitacao: string;
  status: number;
  origem: OrigemCarga;
  observacoes?: string;
  idMotoristaIndicado?: number;
  nomeMotoristaIndicado?: string;
  dataEntrada?: string;
  dataCarregamento?: string;
};

export type Metadata = {
  totalPaginas: number;
  totalItens: number;
  tamanhoPagina?: number;
  paginaAtual?: number;
  paginaAnterior?: number;
  proximaPagina?: number;
};

export type Motorista = {
  id: number;
  nome: string;
};

export type IntervaloTempo = {
  dias: number;
  horas: number;
  minutos: number;
  segundos: number;
};
