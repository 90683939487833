import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, TextField } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import IconePastaConectada from 'assets/icones/IconePastaConectada';
import CustomTooltip from 'shared/components/tooltip/CustomTooltip';

import { verificarCargaPlanejador } from 'shared/constants/OrigemCarga';
import { DadosPermissaoColaborador } from 'features/configuracoes/api/configuracoesApiTypes';
import {
  formatarNumeroComVirgulaCasaDecimais,
  formatarNumerosComVirgulas,
} from 'shared/functions/numberUtils';
import { Autocomplete } from '@material-ui/lab';
import { format } from 'date-fns';
import { adicionarIdCarga, removerIdCarga } from '../redux/listaCargasAction';
import TableRow from '../../../shared/components/table/TableRowHeightSpacing5';

import { RootState } from '../../../store/reducer';
import { obterRomaneioFormatado } from '../../../shared/functions/romaneioUtils';
import CustomWidthTooltip from '../../../shared/components/tooltip/CustomWidthTooltip';
import { TableCellSmall, useStyles } from '../styles/TabelaItem';
import { TabelaItemProps } from '../types/TabelaItem';

const TabelaItem = ({
  carga,
  colaboradoresData,
}: TabelaItemProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const dataFormatada =
    carga.dataEntrada && format(new Date(carga.dataGeracao), 'dd/MM/yyyy');

  const [selectedColaborador, setSelectedColaborador] = useState(() => {
    const savedData = localStorage.getItem('selectedColaborador');
    if (savedData) {
      const newDataArray = JSON.parse(savedData);

      const foundEntry = newDataArray.find(
        (entry: { id: number }) => entry.id === carga.id,
      );
      if (foundEntry) {
        return foundEntry.selectValue;
      }
    }
    return '';
  });

  const listaCargaReducer = useSelector(
    (reducer: RootState) => reducer.listaCargasReducer,
  );

  const [checado, setChecado] = useState(
    listaCargaReducer.idsCargasSelecionadas.has(carga.id),
  );

  const handleChangeStatus = (
    event: React.ChangeEvent<unknown>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    newValue: any | null,
  ) => {
    if (newValue) setSelectedColaborador(newValue);
    if (newValue && newValue.idColaborador) {
      const selectedValue = newValue.idColaborador;
      const savedData = localStorage.getItem('selectedColaborador');
      const newDataArray = savedData ? JSON.parse(savedData) : [];

      const existingEntryIndex = newDataArray.findIndex(
        (entry: { id: number }) => entry.id === carga.id,
      );

      if (existingEntryIndex !== -1) {
        newDataArray[existingEntryIndex].selectValue = selectedValue;
      } else {
        newDataArray.push({ id: carga.id, selectValue: selectedValue });
      }
      localStorage.setItem('selectedColaborador', JSON.stringify(newDataArray));
    }
  };

  const onChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setChecado(true);
      dispatch(adicionarIdCarga(carga.id));
    } else {
      setChecado(false);
      dispatch(removerIdCarga(carga.id));
    }
  };

  const formatarNome = (nome: string): string => {
    return nome
      .toLowerCase()
      .replace(
        /\b\w{3,}\b/g,
        (palavra) =>
          palavra.charAt(0).toUpperCase() + palavra.slice(1).toLowerCase(),
      )
      .replace(
        /\b[A-ZÁÉÍÓÚÂÊêúóÎÔÛÀÈÌÒÙÄËÏÖÜÇ]+\w*\b/g,
        (palavra) => palavra.charAt(0) + palavra.slice(1).toLowerCase(),
      );
  };

  return (
    <TableRow>
      <TableCellSmall align="left">
        <Box className={classes.boxFlexCenter}>
          <Checkbox
            name={carga.id.toString()}
            color="primary"
            checked={checado}
            onChange={onChangeCheckBox}
          />
          {verificarCargaPlanejador(carga.origem) && (
            <CustomTooltip
              title="Carga criada planejador local"
              placement="top"
              maxWidth="220px"
              style={{ textAlign: 'center', fontSize: 14 }}
              arrow
            >
              <Box className={classes.boxFlexCenter}>
                <IconePastaConectada width={20} height={20} />
              </Box>
            </CustomTooltip>
          )}
        </Box>
      </TableCellSmall>
      <TableCellSmall
        align="left"
        width={100}
        style={{ minWidth: '200px', paddingLeft: '0' }}
      >
        <CustomWidthTooltip title={carga.romaneio}>
          <Typography className={classes.centerTyprographyTooltip}>
            {obterRomaneioFormatado(carga.romaneio, 1)}
          </Typography>
        </CustomWidthTooltip>
      </TableCellSmall>
      {verificarCargaPlanejador(carga.origem) && (
        <TableCellSmall align="center">
          <Autocomplete
            value={selectedColaborador}
            onChange={handleChangeStatus}
            closeIcon={false}
            popupIcon={false}
            style={{
              minWidth: '180px',
              maxWidth: '250px',
              whiteSpace: 'nowrap',
            }}
            options={colaboradoresData.filter(
              (item: DadosPermissaoColaborador) =>
                item.acessoNegociacaoCargas === true && item.ativo === true,
            )}
            getOptionLabel={(colaborador) =>
              colaborador && colaborador?.nome
                ? formatarNome(colaborador.nome)
                : ''
            }
            renderOption={(props) => (
              <div
                {...props}
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {formatarNome(props.nome)}
              </div>
            )}
            renderInput={(params) => (
              <TextField
                className={classes.customTextField}
                variant="outlined"
                {...params}
                InputProps={{
                  ...params.InputProps,
                  style: { padding: 0, height: '30px' },
                }}
              />
            )}
          />
        </TableCellSmall>
      )}
      {verificarCargaPlanejador(carga.origem) && (
        <TableCellSmall width="200px" align="center">
          {dataFormatada}
        </TableCellSmall>
      )}
      <TableCellSmall align="left">
        <CustomWidthTooltip title={carga.filial}>
          <Box className={classes.boxFilial}>{carga.filial}</Box>
        </CustomWidthTooltip>
      </TableCellSmall>
      <TableCellSmall align="left">{carga.cidade}</TableCellSmall>
      <TableCellSmall align="center">{carga.entregas}</TableCellSmall>
      <TableCellSmall align="center">
        {formatarNumerosComVirgulas(carga.peso)}
      </TableCellSmall>
      <TableCellSmall align="center">
        {formatarNumeroComVirgulaCasaDecimais(carga.volume, 3)}
      </TableCellSmall>
    </TableRow>
  );
};

export default TabelaItem;
