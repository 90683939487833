import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';

import spacingInPixels from '../../../shared/functions/materialUtils';
import { Rubik } from '../../../shared/constants/font';

const TypographyChat = withStyles((theme) => ({
  root: {
    fontFamily: Rubik,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: spacingInPixels(theme, 2),
    '& b': {
      fontWeight: 500,
    },
  },
}))(Typography);

export default TypographyChat;
