import React from 'react';

import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import { Typography, IconButton } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

import { spacingInPixels } from '../../functions/materialUtils';
import DisablingTooltip from '../tooltip/DisablingTooltip';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
      padding: spacingInPixels(theme, 0.5),
    },
  });

interface DialogTitleProps extends WithStyles<typeof styles> {
  id?: string;
  children?: React.ReactNode;
  onClose: () => void;
  classesTitulo?: string;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, classesTitulo } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6" className={classesTitulo || ''}>
        {children}
      </Typography>
      {onClose ? (
        <DisablingTooltip title="Fechar">
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DisablingTooltip>
      ) : null}
    </MuiDialogTitle>
  );
});

export default DialogTitle;
