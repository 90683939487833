import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import DialogTitle from '../modal/DialogTitle';

export type ModalComumProps = {
  modalAberto: boolean;
  fecharModal: () => void;
  conteudo: JSX.Element;
  titulo?: string | JSX.Element;
  acoes?: JSX.Element;
  estilo?: ClassNameMap<'paper' | 'titulo'>;
  exibirBotaoFechar?: boolean;
};

const useStyles = makeStyles(() => ({
  paper: {
    width: 464,
    display: 'flex',
    flex: 1,
  },
  titulo: {
    fontSize: 18,
  },
}));

const ModalComum = ({
  fecharModal,
  modalAberto,
  titulo,
  conteudo,
  acoes,
  estilo,
  exibirBotaoFechar,
}: ModalComumProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Dialog
      open={modalAberto}
      onClose={fecharModal}
      classes={{ paper: estilo?.paper || classes.paper }}
      fullWidth
    >
      {(titulo || exibirBotaoFechar) && (
        <DialogTitle
          onClose={fecharModal}
          classesTitulo={estilo?.titulo || classes.titulo}
        >
          {titulo && <>{titulo}</>}
        </DialogTitle>
      )}
      <DialogContent>{conteudo}</DialogContent>
      {acoes && <DialogActions>{acoes}</DialogActions>}
    </Dialog>
  );
};

export default ModalComum;
