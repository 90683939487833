import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import HeaderDashboard from '../components/HeaderDashboard';
import { ItemCabecalho } from '../constants/Types';
import {
  buscarDadosFiliais,
  criarPollingDadosFiliais,
} from '../utils/painelFiliais';
import { DemandaFilial } from './api/listagemFiliaisApiTypes';
import ConteudoTabelaFiliais from './ConteudoTabelaFiliais';
import SkeletonItemTabelaFiliais from './SkeletonItemTabelaFiliais';

interface ContainerTabelaNegociadoresProps {
  listaDemandasFilial: DemandaFilial[] | undefined;
  setListaDemandasFilial: React.Dispatch<
    React.SetStateAction<DemandaFilial[] | undefined>
  >;
}

const ContainerTabelaFiliais = ({
  listaDemandasFilial,
  setListaDemandasFilial,
}: ContainerTabelaNegociadoresProps): JSX.Element => {
  const [carregando, setCarregando] = useState(!listaDemandasFilial);
  const [referenciaRefresh, setReferenciaRefresh] = useState<number>();

  useEffect(() => {
    if (!listaDemandasFilial)
      buscarDadosFiliais(setListaDemandasFilial, setCarregando);

    setReferenciaRefresh(
      criarPollingDadosFiliais(setListaDemandasFilial, setCarregando),
    );
  }, [setListaDemandasFilial, listaDemandasFilial]);

  useEffect(() => () => clearInterval(referenciaRefresh), [referenciaRefresh]);

  const itensCabecalho: ItemCabecalho[] = [
    { label: 'Prioridade de carga', tamanhoCelula: 3, paddingLeft: '2.5%' },
    { label: 'Demandas da filial', tamanhoCelula: 5 },
  ];

  return (
    <div>
      <HeaderDashboard
        nome="Filial"
        tamanhoCelulaNome={4}
        quantidade={listaDemandasFilial?.length}
        headers={itensCabecalho}
      />
      {carregando ? (
        new Array(5).fill(null).map((_, index) => (
          <Grid item key={`skeleton-item-tabela-filial-${index + 1}`}>
            <SkeletonItemTabelaFiliais />
          </Grid>
        ))
      ) : (
        <ConteudoTabelaFiliais filiais={listaDemandasFilial} />
      )}
    </div>
  );
};

export default ContainerTabelaFiliais;
