import React from 'react';

import { Box, makeStyles, Typography } from '@material-ui/core';

import { IntervaloTempo } from 'shared/types/apiTypes';
import spacingInPixels from '../../functions/materialUtils';
import { formatarDias, formatarHorario } from '../../functions/IntervaloTempo';

export type BadgeIntervaloTempoType = {
  intervalo: IntervaloTempo;
  icone: JSX.Element;
  backgroundColor?: string;
};

export type useStylesPropType = {
  backgroundColor?: string;
  tamanhoBadge: number;
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: ({ backgroundColor }: useStylesPropType) =>
      backgroundColor || theme.palette.cores.cinza[300],
    borderRadius: spacingInPixels(theme, 2),
    padding: spacingInPixels(theme, 0.8),
    minWidth: ({ tamanhoBadge }: useStylesPropType) => `${tamanhoBadge}px`,
  },
  texto: {
    fontSize: '12px',
    fontWeight: 400,
    color: theme.palette.cores.cinza[900],
    marginLeft: spacingInPixels(theme, 1),
  },
}));

const BadgeIntervaloTempo = ({
  intervalo,
  icone,
  backgroundColor,
}: BadgeIntervaloTempoType): JSX.Element => {
  const { dias, horas, minutos, segundos } = intervalo;
  const classes = useStyles({
    backgroundColor,
    tamanhoBadge: formatarDias(dias) !== '' ? 160 : 95,
  });

  return (
    <Box className={classes.container}>
      {icone}
      <Typography className={classes.texto}>
        {formatarDias(dias)}
        {formatarHorario(horas)}:{formatarHorario(minutos)}:
        {formatarHorario(segundos)}
      </Typography>
    </Box>
  );
};

export default BadgeIntervaloTempo;
