import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { withStyles, useTheme } from '@material-ui/core';

import { format } from 'date-fns';
import { spacingInPixels } from '../../../../shared/functions/materialUtils';
import { Carga } from '../../../../shared/types/apiTypes';
import { formatarNumeroParaStringMonetariaComSimbolo } from '../../../../shared/functions/dinheiroUtils';

import { removerIdCarga } from '../../../listaCargas/redux/listaCargasAction';

import { obterCarga } from '../../api/minhasCargasManager';

import ItemInformacao from './AccordionItemInformacao';
import Header from './AccordionHeader';

const StyledAccordion = withStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.cores.cinza[500]}50`,
    '&.Mui-expanded': {
      margin: '0px',
    },
  },
}))(MuiAccordion);

const AccordionDetails = withStyles({
  root: {
    padding: 0,
    display: 'inherit',
  },
})(MuiAccordionDetails);

type AccordionProps = {
  idCarga: number;
  setErro: React.Dispatch<React.SetStateAction<boolean>>;
};

const Accordion = ({ idCarga, setErro }: AccordionProps): JSX.Element => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [carregando, setCarregando] = useState(true);
  const [carga, setCarga] = useState<Carga | null>(null);

  useEffect(() => {
    obterCarga(idCarga)
      .then((resposta) => {
        setCarga(resposta.carga);
        setCarregando(false);
      })
      .catch(() => {
        setErro(true);
        dispatch(removerIdCarga(idCarga));
      });
  }, [dispatch, idCarga, setErro]);

  return (
    <StyledAccordion square elevation={0}>
      <Header carregando={carregando} carga={carga} />
      <AccordionDetails>
        <Box display="flex" justifyContent="center">
          <Box
            minWidth="89%"
            marginTop={spacingInPixels(theme, 1)}
            marginBottom={spacingInPixels(theme, 1)}
          >
            <Grid container spacing={2}>
              <ItemInformacao
                xs={5}
                caminhoIcone="/images/minhasCargas/icone-local.svg"
                altIcone="Cidade"
                titulo="Cidade"
                texto={carga && carga.cidade}
                carregando={carregando}
              />
              <ItemInformacao
                xs={2}
                caminhoIcone="/images/minhasCargas/icone-ouro.svg"
                altIcone="Peso (kg)"
                titulo="Peso (kg)"
                texto={carga && carga.peso.toLocaleString()}
                carregando={carregando}
              />
              <ItemInformacao
                xs={2}
                caminhoIcone="/images/minhasCargas/icone-volume.svg"
                altIcone="Volume (m³)"
                titulo="Volume (m³)"
                texto={carga && carga.volume.toLocaleString()}
                carregando={carregando}
              />
              <ItemInformacao
                xs={3}
                caminhoIcone="/images/minhasCargas/icone-velocimetro.svg"
                altIcone="Quilometragem"
                titulo="Quilometragem"
                texto={carga && `${carga.quilometragem.toLocaleString()} km`}
                carregando={carregando}
              />
            </Grid>
            <Grid container spacing={2}>
              <ItemInformacao
                xs={5}
                caminhoIcone="/images/minhasCargas/icone-filial.svg"
                altIcone="Filial"
                titulo="Filial"
                texto={carga && carga.filial}
                carregando={carregando}
              />
              <ItemInformacao
                xs={2}
                caminhoIcone="/images/minhasCargas/icone-calendario.svg"
                altIcone="Carregamento"
                titulo="Carregamento"
                texto={
                  carga && carga?.dataSolicitacao
                    ? format(new Date(carga?.dataSolicitacao), 'dd/MM/yyyy')
                    : ''
                }
                carregando={carregando}
              />
              <ItemInformacao
                xs={2}
                caminhoIcone="/images/minhasCargas/icone-dropbox.svg"
                altIcone="Entregas"
                titulo="Entregas"
                texto={carga && String(carga.entregas)}
                carregando={carregando}
              />
              <ItemInformacao
                xs={3}
                caminhoIcone="/images/minhasCargas/icone-dinheiro.svg"
                altIcone="Valor da carga"
                titulo="Valor da carga"
                texto={
                  carga &&
                  formatarNumeroParaStringMonetariaComSimbolo(carga.valorCarga)
                }
                carregando={carregando}
              />
            </Grid>
          </Box>
        </Box>
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default Accordion;
