import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { buscarDadosPermissaoUsuario } from 'features/login/redux/loginOperation';
import spacingInPixels from '../../../shared/functions/materialUtils';
import ChipDemandasEmAndamento from '../components/ChipDemandasEmAndamento';
import MenuOpcoesAbas from '../components/MenuOpcoesAbas';
import { OpcaoAbaId } from '../constants/TipoOpcaoAba';
import { DemandaFilial } from '../filiais/api/listagemFiliaisApiTypes';
import ContainerTabelaFiliais from '../filiais/ContainerTabelaFiliais';
import { DemandaColaborador } from '../negociadores/api/listagemNegociadoresApiTypes';
import ContainerTabelaNegociadores from '../negociadores/ContainerTabelaNegociadores';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'left',
    flexDirection: 'column',
    position: 'relative',

    background: theme.palette.cores.branco,
    paddingTop: `18px`,
    borderRadius: spacingInPixels(theme, 1),
    gap: spacingInPixels(theme, 3),
    border: `1px solid ${theme.palette.cores.cinza['400']}80`,
    marginTop: spacingInPixels(theme, 2.2),
  },
  containerAbas: {
    paddingLeft: spacingInPixels(theme, 2),
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const PainelNegociadoresFiliais = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [abaSelecionada, setAbaSelecionada] = useState(OpcaoAbaId.Negociadores);
  const [listaDemandaColaborador, setListaDemandaColaborador] = useState<
    DemandaColaborador[] | undefined
  >();
  const [listaDemandasFilial, setListaDemandasFilial] = useState<
    DemandaFilial[] | undefined
  >();

  useEffect(() => {
    buscarDadosPermissaoUsuario()(dispatch);
  }, [dispatch]);

  return (
    <section className={classes.container}>
      <section className={classes.containerAbas}>
        <MenuOpcoesAbas
          onClickAba={(idAba: OpcaoAbaId) => setAbaSelecionada(idAba)}
        />
        <ChipDemandasEmAndamento
          hidden={abaSelecionada === OpcaoAbaId.Filial}
        />
      </section>
      <section>
        {abaSelecionada === OpcaoAbaId.Negociadores ? (
          <ContainerTabelaNegociadores
            listaDemandaColaborador={listaDemandaColaborador}
            setListaDemandaColaborador={setListaDemandaColaborador}
          />
        ) : (
          <ContainerTabelaFiliais
            listaDemandasFilial={listaDemandasFilial}
            setListaDemandasFilial={setListaDemandasFilial}
          />
        )}
      </section>
    </section>
  );
};

export default PainelNegociadoresFiliais;
