import React from 'react';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

import Skeleton from '@material-ui/lab/Skeleton';

import { spacingInPixels } from '../../../../shared/functions/materialUtils';

const useStyles = makeStyles((theme) => ({
  titulo: {
    fontSize: '14px',
    lineHeight: spacingInPixels(theme, 3),
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  texto: {
    fontSize: '14px',
    lineHeight: spacingInPixels(theme, 3),
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  icone: {
    height: spacingInPixels(theme, 3),
    width: spacingInPixels(theme, 3),
    marginRight: spacingInPixels(theme, 1),
  },
}));

type AccordionItemInformacaoProps = {
  xs: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  caminhoIcone: string;
  altIcone: string;
  titulo: string;
  texto: string | null;
  carregando: boolean;
};

const AccordionItemInformacao = ({
  xs,
  caminhoIcone,
  altIcone,
  titulo,
  texto,
  carregando,
}: AccordionItemInformacaoProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Grid item xs={xs}>
      <Box display="flex">
        <Box>
          <img src={caminhoIcone} alt={altIcone} className={classes.icone} />
        </Box>
        <Box>
          <Typography className={classes.titulo}>{titulo}</Typography>

          <Typography className={classes.texto}>
            {carregando ? <Skeleton /> : texto}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default AccordionItemInformacao;
