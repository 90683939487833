import React from 'react';
import { Box, makeStyles, Typography, withStyles } from '@material-ui/core';

import { porcentagemStylesFundo } from '../../styles/porcentagemStyles';
import { obterCorPorcentagem } from '../../functions/porcentagemUtils';
import { Rubik } from '../../constants/font';
import spacingInPixels from '../../functions/materialUtils';

export type BadgePorcentagemProps = {
  texto: string;
  complemento?: string;
  porcentagem: number;
  esconder?: boolean;
  condensado?: boolean;
};

const TypographyTexto = withStyles((theme) => ({
  root: {
    fontFamily: Rubik,
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
    color: theme.palette.cores.branco,
    textAlign: 'center',
  },
}))(Typography);

export const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: spacingInPixels(theme, 8),
    borderRadius: theme.spacing(2),
    padding: '4px 8px',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    height: ({ condensado }: { condensado?: boolean }) =>
      condensado ? 24 : 'unset',
  },
}));

const BadgePorcentagem = ({
  texto,
  complemento,
  porcentagem,
  esconder,
  condensado = false,
}: BadgePorcentagemProps): JSX.Element => {
  const classes = useStyles({ condensado });
  const estilosPorcentagem = porcentagemStylesFundo();
  return esconder ? (
    <></>
  ) : (
    <Box
      className={`${classes.container} ${
        estilosPorcentagem[obterCorPorcentagem(porcentagem)]
      }`}
    >
      <TypographyTexto color="primary">
        <strong>{texto}</strong> {complemento}
      </TypographyTexto>
    </Box>
  );
};

export default BadgePorcentagem;
