import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CheckRounded from '@material-ui/icons/CheckRounded';

import { RootState } from '../../../../../store/reducer';

import BotaoIcone from '../../../../../shared/components/button/BotaoIcone';
import { verificarPropostaFechada } from '../../../../../shared/constants/PropostaStatus';

import { PropostaAEnviar } from '../../../types/PropostaAEnviar';
import TipoProposta from '../../../types/TipoProposta';

import { PropostaNegociacao } from '../../../api/informacoesNegociacaoApiTypes';

import { abrirModalEnviarProposta } from '../redux/motoristasCardAction';
import useStyles from '../../../styles/botaoPropostaStyles';

type AceitarPropostaButtonProps = {
  proposta: PropostaNegociacao;
};

const AceitarPropostaButton = ({
  proposta,
}: AceitarPropostaButtonProps): JSX.Element => {
  const classes = useStyles({ aceitar: true });
  const dispatch = useDispatch();

  const { negociacaoConcluida } = useSelector(
    (state: RootState) => state.informacoesNegociacaoReducer,
  );

  const [desabilitado, setDesabilitado] = useState(true);

  useEffect(() => {
    setDesabilitado(
      negociacaoConcluida || verificarPropostaFechada(proposta.status),
    );
  }, [negociacaoConcluida, proposta.status]);

  const abrirModal = () => {
    const propostaAEnviar: PropostaAEnviar = {
      proposta,
      motorista: undefined,
      tipoProposta: TipoProposta.PROPOSTA_FINAL,
    };

    dispatch(abrirModalEnviarProposta(propostaAEnviar));
  };

  return (
    <BotaoIcone
      color="primary"
      descricao="Aceitar Proposta"
      onClick={abrirModal}
      tamanho="small"
      desabilitado={desabilitado}
      classe={classes.botao}
    >
      <CheckRounded className={classes.icone} />
    </BotaoIcone>
  );
};

export default AceitarPropostaButton;
