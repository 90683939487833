import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Pagination from '@material-ui/lab/Pagination';
import OrigemCarga, {
  verificarCargaPlanilha,
} from 'shared/constants/OrigemCarga';

import { buscarDadosPermissaoUsuario } from 'features/login/redux/loginOperation';

import { buscarPermissaoColaboradores } from 'features/configuracoes/utils/configuracoes';
import { DadosPermissaoColaborador } from 'features/configuracoes/api/configuracoesApiTypes';

import BoxPagina from '../../../shared/components/box/BoxPagina';
import { PATH_MINHAS_CARGAS } from '../../../shared/constants/pathConstants';
import DisablingTooltip from '../../../shared/components/tooltip/DisablingTooltip';
import Button from '../../../shared/components/button/ButtonTextTransformNone';
import { RootState } from '../../../store/reducer';
import {
  atualizarCargasOperation,
  atualizarFiliaisOperation,
} from '../redux/listaCargasOperations';
import {
  atualizarOrigemCarga,
  atualizarPagina,
} from '../redux/listaCargasAction';
import TituloListaCargas from './TituloListaCargas';
import TabelaListaCargas from './TabelaListaCargas';
import { limparDadosMinhasNegociacoes } from '../../minhasNegociacoes/redux/minhasNegociacoesAction';
import { useStyles } from '../styles/listaCargas';

const ListaCargas = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [botaoDesabilitado, setBotaoDesabilitado] = useState(true);
  const [colaboradores, setColaboradores] = useState<
    DadosPermissaoColaborador[]
  >([]);

  const {
    idsCargasSelecionadas,
    filiaisSelecionadas,
    pesquisa,
    dataInicio,
    dataFim,
    cargasPlanilha,
    cargasPlanejador,
    stateOrigemCarga,
    ordenacao,
  } = useSelector((reducer: RootState) => reducer.listaCargasReducer);

  const onPaginaChange = (
    _: React.ChangeEvent<unknown>,
    novaPagina: number,
  ): void => {
    dispatch(atualizarPagina(novaPagina));
  };

  const updateStateOrigemCarga = (origem: OrigemCarga) => {
    dispatch(atualizarOrigemCarga(origem));
  };

  const updateVerificarCargaOrigem = useCallback(
    () =>
      verificarCargaPlanilha(stateOrigemCarga)
        ? cargasPlanilha
        : cargasPlanejador,
    [cargasPlanejador, cargasPlanilha, stateOrigemCarga],
  );

  useEffect(() => {
    buscarDadosPermissaoUsuario()(dispatch);
  }, [dispatch]);

  useEffect(() => {
    dispatch(limparDadosMinhasNegociacoes());
  }, [dispatch]);

  useEffect(() => {
    atualizarFiliaisOperation()(dispatch);
  }, [dispatch]);

  useEffect(() => {
    atualizarCargasOperation(
      cargasPlanilha.pagina,
      filiaisSelecionadas,
      pesquisa,
      dataInicio,
      dataFim,
      OrigemCarga.Planilha,
      ordenacao,
    )(dispatch);
  }, [
    dataFim,
    dataInicio,
    dispatch,
    filiaisSelecionadas,
    cargasPlanilha.pagina,
    pesquisa,
    ordenacao,
  ]);

  useEffect(() => {
    atualizarCargasOperation(
      cargasPlanejador.pagina,
      filiaisSelecionadas,
      pesquisa,
      dataInicio,
      dataFim,
      OrigemCarga.Planejador,
      ordenacao,
    )(dispatch);
  }, [
    dataFim,
    dataInicio,
    dispatch,
    filiaisSelecionadas,
    cargasPlanejador.pagina,
    pesquisa,
    ordenacao,
  ]);

  useEffect(() => {
    setBotaoDesabilitado(idsCargasSelecionadas.size === 0);
  }, [idsCargasSelecionadas.size]);

  useEffect(() => {
    buscarPermissaoColaboradores(setColaboradores, dispatch);
    localStorage.removeItem('selectedColaborador');
  }, [dispatch]);

  return (
    <BoxPagina component="section">
      <TituloListaCargas
        stateOrigemCarga={stateOrigemCarga}
        updateStateOrigemCarga={updateStateOrigemCarga}
      />
      <TabelaListaCargas
        cargas={updateVerificarCargaOrigem().cargas}
        colaboradoresData={colaboradores}
      />
      <Box className={classes.containerRodape}>
        <Pagination
          className={classes.paginacao}
          count={updateVerificarCargaOrigem().quantidadePaginas}
          shape="rounded"
          color="primary"
          page={updateVerificarCargaOrigem().pagina}
          size="large"
          onChange={onPaginaChange}
        />
        <DisablingTooltip
          title="Selecionar carga(s)"
          disabled={botaoDesabilitado}
        >
          <Button
            variant="contained"
            disabled={botaoDesabilitado}
            className={classes.botaoCargas}
            type="submit"
            onClick={() => history.push(PATH_MINHAS_CARGAS)}
          >
            Selecionar cargas
          </Button>
        </DisablingTooltip>
      </Box>
    </BoxPagina>
  );
};

export default ListaCargas;
