import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';

import scrollbarConstructor from '../../functions/scrollbarConstructor';

export default withStyles((theme) => ({
  root: {
    ...scrollbarConstructor('MuiBox-root', theme),
  },
}))(Box);
