import { makeStyles } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gap: spacingInPixels(theme, 5),
    minHeight: '30vh',
  },
  autocomplete: {
    maxWidth: spacingInPixels(theme, 62),

    '& .MuiAutocomplete-popupIndicator': {
      transform: 'none',
      height: spacingInPixels(theme, 3),
      width: spacingInPixels(theme, 4),
    },
  },
  boxFitContent: {
    maxWidth: 'fit-content',
  },
  sliderBox: {
    maxWidth: (sliderTamanho: { sliderTamanho?: boolean }) =>
      sliderTamanho ? spacingInPixels(theme, 55) : spacingInPixels(theme, 42),
    borderLeft: `${spacingInPixels(theme, 0.5)} solid`,
    borderColor: theme.palette.cores.cinza[300],
    paddingLeft: spacingInPixels(theme, 5),
    height: 115,
    display: 'flex',
    alignItems: 'flex-end',
    '& .MuiSlider-valueLabel': {
      left: 'calc(-86% - 4px)',
      top: '-42px',

      '& > span': {
        width: 42,
        height: 42,
      },
    },
  },
  infoCard: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '7px 16px 7px 16px',
    background: 'rgba(97, 97, 97, 0.9)',
    color: 'rgba(255, 255, 255, 1)',
    fontSize: 14,
    borderRadius: 4,
    width: 'fit-content',
    marginBottom: spacingInPixels(theme, 2),
  },
  sliderStyle: { color: `${theme.palette.cores.azul[600]} !important` },
  boxSorriso: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    flexDirection: 'column',
    padding: '5vh 0 10vh 0',

    '& p': {
      maxWidth: 440,
      textAlign: 'center',
      fontWeight: 400,
      color: theme.palette.cores.cinza[500],
    },
  },
  containerBotoes: {
    display: `flex`,
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    gap: '32px',
  },
  buttonInside: {
    color: theme.palette.cores.azul[600],
  },
  buttonOutside: {
    backgroundColor: theme.palette.cores.azul[600],

    '&:hover': {
      backgroundColor: theme.palette.cores.azul[600],
      filter: 'brightness(0.9)',
    },
  },
  sliderContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '400px',
    gap: 14,
  },
  menosMargin: {
    marginBottom: 0,
  },
}));
