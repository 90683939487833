import { Grid, makeStyles, Theme } from '@material-ui/core';
import StatusDemandaFilial from 'features/dashboards/components/StatusDemandaFilial';
import React, { useState } from 'react';
import EmptyPage from 'shared/components/emptyPage/EmptyPage';
import ModalComum from 'shared/components/modalComum/ModalComum';
import CustomTooltip from 'shared/components/tooltip/CustomTooltip';
import IconePredioCirculo from '../../../assets/icones/IconePredioCirculo';
import ContainerPrioridadeDeCarga from '../components/ContainerPrioridadeDeCarga';
import ConteudoDetalhesFilial from '../components/modalDetalhes/filial/ConteudoDetalhesFilial';
import { TITULO_MODAL_NEGOCIACOES_EM_ANDAMENTO } from '../constants/Constants';
import { DemandaFilial } from './api/listagemFiliaisApiTypes';

const useStyles = makeStyles((theme) => ({
  containerListagem: {
    cursor: 'pointer',
    overflow: 'auto',
    maxHeight: '372px',
    paddingBottom: '2px',
    '& > div': {
      borderBottom: `1px solid ${theme.palette.cores.cinza[300]}`,
      height: '54px',
    },
    '& > :first-child': {
      borderTop: `1px solid ${theme.palette.cores.cinza[300]}`,
    },
    '& > :last-child': {
      borderBottom: 'unset',
    },
    '@media (max-height:800px)': {
      maxHeight: '320px',
    },
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.cores.azul[600],
      borderRadius: '8px',
    },
  },

  informacaoFilial: {
    display: 'Grid',
    gridTemplateColumns: '50px auto',
    padding: 10,
    paddingLeft: 8,
    gap: 10,
    '& > svg': {
      marginBlock: 'auto',
      marginLeft: 10,
    },
  },
  gridAreaAvatar: {
    gridRowStart: 1,
    gridRowEnd: 2,
  },

  prioridadeCarga: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: '6px',
    paddingLeft: '15%',
    height: '52px',
  },
  gridContainerLinha: {
    '&:hover': {
      backgroundColor: theme.palette.cores.cinza[50],
      borderBottom: `1px solid ${theme.palette.cores.cinza[400]}`,
      boxShadow: '0px 1px 6px 0px rgb(0 0 0 / 30%)',
    },
  },

  nomeFilial: {
    marginBlock: 'auto',
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    lineHeight: '20px',
    letterSpacing: '0.4px',

    fontWeight: 500,
    fontSize: '14px',
    color: theme.palette.cores.cinza[800],
    display: 'block',

    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  tituloModal: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '30px',
  },
}));

const estiloModal = makeStyles((theme: Theme) => ({
  paper: {
    display: 'flex',
    flex: 1,
    minWidth: 1200,
    height: '93vh',
    position: 'relative',
    overflowX: 'hidden',
    '& .MuiDialogContent-root': {
      overflow: 'hidden',
    },
    '& button.MuiButtonBase-root[aria-label="close"]': {
      right: '40px',
      top: '30px',
    },
    '@media (max-width: 1260px)': {
      minWidth: '90vw',
    },
  },
  titulo: {
    padding: '24px 64px',
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 20,
    lineHeight: '20px',
    letterSpacing: '0.4px',
    color: theme.palette.cores.cinza[600],
  },
}));

export interface TituloModalFilialProps {
  tituloModal?: string;
}

const TituloModalFilial = ({
  tituloModal,
}: TituloModalFilialProps): JSX.Element => {
  const classes = useStyles();
  return (
    <section className={classes.tituloModal}>
      <IconePredioCirculo width={48} height={48} />
      <span>{tituloModal}</span>
    </section>
  );
};

interface ConteudoTabelaNegociadoresProps {
  filiais: DemandaFilial[] | undefined;
}

const ConteudoTabelaFiliais = ({
  filiais,
}: ConteudoTabelaNegociadoresProps): JSX.Element => {
  const classes = useStyles();

  const [modalAberto, setModalAberto] = useState(false);
  const [tituloModal, setTituloModal] = useState(
    TITULO_MODAL_NEGOCIACOES_EM_ANDAMENTO,
  );
  const [conteudoModal, setConteudoModal] = useState<JSX.Element>(<></>);

  const handleClickItemTabelaFilial = (filial: DemandaFilial) => {
    setConteudoModal(<ConteudoDetalhesFilial filial={filial} />);
    setTituloModal(filial.nomeFilial);
    setModalAberto(true);
  };

  if (!filiais || filiais?.length === 0)
    return (
      <EmptyPage
        texto={
          filiais === undefined
            ? 'Ops algo aconteceu, por algum motivo não conseguimos listar as filiais.'
            : 'Ops, não existem demandas para filiais.'
        }
      />
    );

  return (
    <>
      <Grid
        container
        className={classes.containerListagem}
        direction="column"
        wrap="nowrap"
      >
        {filiais?.map((filial) => (
          <Grid
            item
            onClick={() => handleClickItemTabelaFilial(filial)}
            key={`item-tabela-filial-${filial.idFilial}`}
          >
            <Grid container className={classes.gridContainerLinha}>
              <Grid item md={4} lg={4}>
                <section className={classes.informacaoFilial}>
                  <IconePredioCirculo />
                  <CustomTooltip title={filial.nomeFilial}>
                    <div className={classes.nomeFilial}>
                      {filial.nomeFilial}
                    </div>
                  </CustomTooltip>
                </section>
              </Grid>
              <Grid item md={3} lg={3}>
                <ContainerPrioridadeDeCarga
                  prioridadeCarga={filial?.prioridadeCarga}
                />
              </Grid>
              <Grid item md={5} lg={5}>
                <StatusDemandaFilial dadosStatusDemanda={filial?.demandas} />
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <ModalComum
        titulo={<TituloModalFilial tituloModal={tituloModal} />}
        modalAberto={modalAberto}
        fecharModal={() => setModalAberto(false)}
        conteudo={conteudoModal}
        estilo={estiloModal()}
      />
    </>
  );
};

export default ConteudoTabelaFiliais;
