import { withStyles } from '@material-ui/core';
import { TimelineItem as MuiTimelineItem, Timeline } from '@material-ui/lab';
import React from 'react';

import { EventoProposta } from '../../api/detalhesAprovacaoSupervisorTypes';
import CardEvento from './CardEvento';

type TimelineEventosProps = {
  historico: EventoProposta[];
  valorFrotaPropria: number;
};

const TimelineItem = withStyles({
  missingOppositeContent: {
    '&:before': {
      display: 'none',
    },
  },
})(MuiTimelineItem);

const TimelineEventos = ({
  historico,
  valorFrotaPropria,
}: TimelineEventosProps): JSX.Element => (
  <Timeline>
    {historico.map((evento, index, array) => (
      <TimelineItem key={`evento-timeline-${evento.idMensagemProposta}`}>
        <CardEvento
          evento={evento}
          valorFrotaPropria={valorFrotaPropria}
          primeiro={index === 0}
          ultimo={index === array.length - 1}
        />
      </TimelineItem>
    ))}
  </Timeline>
);

export default TimelineEventos;
