import { useTheme } from '@material-ui/core/styles';
import * as React from 'react';
import { SVGProps } from 'react';

const IconeCirculo = ({
  fill,
  ...outros
}: SVGProps<SVGSVGElement>): JSX.Element => {
  const theme = useTheme();

  return (
    <svg width={16} height={17} fill="none" {...outros}>
      <circle
        cx={8}
        cy={8.5}
        r={8}
        fill={fill || theme.palette.cores.vermelho[450]}
      />
    </svg>
  );
};

export default IconeCirculo;
