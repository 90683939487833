import React from 'react';

import { useTheme } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { spacingInPixels } from '../../functions/materialUtils';
import { useStyles } from './styles';

type SubtituloProps = {
  subtitulo: string;
  aplicarEstilo?: boolean;
};

const Subtitulo = ({
  subtitulo,
  aplicarEstilo,
}: SubtituloProps): JSX.Element => {
  const theme = useTheme();
  const estilo = useStyles();

  return (
    <Box
      display="flex"
      alignItems="center"
      marginBottom={spacingInPixels(theme, 2)}
    >
      <Typography
        variant="h6"
        style={{ color: '#000000' }}
        className={aplicarEstilo ? estilo.subtitulo : ''}
      >
        {subtitulo}
      </Typography>
    </Box>
  );
};

Subtitulo.defaultProps = {
  aplicarEstilo: false,
};

export default Subtitulo;
