import { createReducer } from '@reduxjs/toolkit';

import { verificarNegociacaoConcluida } from '../../../shared/constants/NegociacaoStatus';
import {
  MotoristaNegociacao,
  Negociacao,
  PropostaNegociacao,
} from '../api/informacoesNegociacaoApiTypes';

import {
  atualizarIdNegociacao,
  atualizarNegociacao,
  atualizarObservacao,
  atualizarPropostas,
  atualizarMotoristas,
  atualizarIdPropostaComFoco,
  abrirModalExcluirObservacao,
  fecharModalExcluirObservacao,
  limparDados,
  atualizarValores,
} from './informacoesNegociacaoAction';

export type InformacoesNegociacaoState = {
  idNegociacao: number;
  negociacao?: Negociacao;
  propostas: PropostaNegociacao[];
  propostasAplicativo: PropostaNegociacao[];
  motoristas?: MotoristaNegociacao[];
  idPropostaComFoco: number | undefined;
  negociacaoConcluida: boolean;
  modalExcluirObservacaoAberto: boolean;
};

export const estadoInicial: Readonly<InformacoesNegociacaoState> = {
  idNegociacao: 0,
  negociacao: undefined,
  propostas: new Array<PropostaNegociacao>(),
  propostasAplicativo: new Array<PropostaNegociacao>(),
  idPropostaComFoco: undefined,
  negociacaoConcluida: false,
  modalExcluirObservacaoAberto: false,
};

const negociacaoReducer = createReducer(estadoInicial, (builder) => {
  builder
    .addCase(atualizarIdNegociacao, (state, action) => ({
      ...state,
      idNegociacao: action.payload,
    }))
    .addCase(atualizarNegociacao, (state, action) => ({
      ...state,
      negociacao: action.payload,
      negociacaoConcluida:
        action.payload && verificarNegociacaoConcluida(action.payload.status),
    }))
    .addCase(atualizarObservacao, (state, action) => {
      const negociacaoAntiga = state.negociacao as Negociacao;
      return {
        ...state,
        negociacao: {
          ...negociacaoAntiga,
          observacoes: action.payload,
        },
      };
    })
    .addCase(atualizarPropostas, (state, action) => ({
      ...state,
      propostas: action.payload.filter((proposta) => !proposta.usuarioApp),
      propostasAplicativo: action.payload.filter(
        (proposta) => proposta.usuarioApp,
      ),
    }))
    .addCase(atualizarValores, (state, action) => {
      const negociacaoAntiga = state.negociacao as Negociacao;
      return {
        ...state,
        negociacao: {
          ...negociacaoAntiga,
          valorFrotaPropria: action.payload.valorFrotaPropria,
          valorFreteTerceiro: action.payload.valorFreteTerceiro,
          ValorNegociacao: action.payload.valorNegociacao,
        },
      };
    })
    .addCase(atualizarMotoristas, (state, action) => ({
      ...state,
      motoristas: action.payload,
    }))
    .addCase(atualizarIdPropostaComFoco, (state, action) => ({
      ...state,
      idPropostaComFoco: action.payload,
    }))
    .addCase(abrirModalExcluirObservacao, (state) => ({
      ...state,
      modalExcluirObservacaoAberto: true,
    }))
    .addCase(fecharModalExcluirObservacao, (state) => ({
      ...state,
      modalExcluirObservacaoAberto: false,
    }))
    .addCase(limparDados, () => estadoInicial)
    .addDefaultCase((state) => state);
});

export default negociacaoReducer;
