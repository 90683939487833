enum StatusDemandaEnum {
  Cancelada,
  Concluida,
  EmNegociacao,
  EmAprovacao,
  NaoIniciada,
  Recusada,
}

export default StatusDemandaEnum;
