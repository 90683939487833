import React, { useMemo } from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { porcentagemStylesTexto } from 'shared/styles/porcentagemStyles';
import { TipoCorPorcentagem } from 'shared/constants/TipoCorPorcentagem';
import { obterCorPorcentagem } from 'shared/functions/porcentagemUtils';
import { converterParaDecimal } from 'shared/functions/numberUtils';

const useStyles = makeStyles((theme) => ({
  abreviacao: {
    fontSize: '14px',
    color: theme.palette.text.secondary,
    marginBottom: '-4px',
  },
  porcentagem: {
    fontSize: '24px',
    fontWeight: 600,
  },
}));

export type InfoFreteProps = {
  porcentagem: string;
  abreviacao: string;
  marginRight?: string;
};

const InfoFrete = ({
  porcentagem,
  abreviacao,
  marginRight,
}: InfoFreteProps): JSX.Element => {
  const classes = useStyles();
  const estilosPorcentagem = porcentagemStylesTexto();

  const corPorcentagem = useMemo(() => {
    const valor = converterParaDecimal(porcentagem);
    const cor = obterCorPorcentagem(valor);
    return cor || TipoCorPorcentagem.CINZA;
  }, [porcentagem]);

  return (
    <Box display="flex" marginRight={marginRight}>
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <Typography
          className={classes.abreviacao}
        >{`% ${abreviacao}`}</Typography>
        <Typography
          className={`${classes.porcentagem} ${estilosPorcentagem[corPorcentagem]}`}
        >
          {porcentagem}
        </Typography>
      </Box>
    </Box>
  );
};

export default InfoFrete;
