import Role from 'shared/constants/RoleColaborador';

export type typeAbasUpload = 'UploadDeCarga' | 'UploadDePrecificacao' | null;

export type TabUploadsProps = {
  aba: string | null;
  roles: Role[] | undefined;
  carregando: boolean;
  setAba: (aba: typeAbasUpload) => void;
};

type UploadDicionario = {
  value: string;
  label: string;
};

export const dicionario: Record<Role, UploadDicionario> = {
  0: { value: 'UploadDeCarga', label: 'Upload de carga' },
  1: { value: 'UploadDePrecificacao', label: 'Upload de precificação' },
};
