import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import DetalhesDemandasDiaria from 'features/detalhesDemandasDiaria/views/DetalhesDemandasDiaria';
import BoxPagina from 'shared/components/box/BoxPagina';
import spacingInPixels from 'shared/functions/materialUtils';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingLeft: 'calc(80vw - 2220px)',
    marginBottom: spacingInPixels(theme, 3),
  },
}));

const PaginaDetalhesDemandasDiaria = (): JSX.Element => {
  const classes = useStyles();
  return (
    <BoxPagina className={classes.container} component="section">
      <DetalhesDemandasDiaria />
    </BoxPagina>
  );
};

export default PaginaDetalhesDemandasDiaria;
