import { useCallback, useState } from 'react';

export type useLoadingType = [boolean, () => void, boolean];

export const useLoading = (funcao: () => Promise<void>): useLoadingType => {
  const [carregando, setCarregando] = useState<boolean>(false);
  const [firstTime, setFirstTime] = useState<boolean>(true);

  const executarFuncao = useCallback(() => {
    const execFn = async () => {
      setCarregando(true);
      await funcao().catch();
      setCarregando(false);
      setFirstTime(false);
    };

    execFn();
  }, [funcao]);

  return [carregando, executarFuncao, firstTime];
};
