import { NegociacaoAndamento } from 'features/demandasAndamento/api/demandasAndamentoApiTypes';
import {
  itensHeaderTabelaDemandasAndamento,
  itemOrdenacaoFilial,
  ItemHeaderTabelaDemandasAndamento as ItemHeaderType,
} from 'features/demandasAndamento/constants/itensHeaderTabelaDemandasAndamento';
import gridStyles from 'features/demandasAndamento/styles/gridTabelaDemandasAndamentoStyle';
import { ordenarDemandasAndamento } from 'features/demandasAndamento/utils/demandasAndamento';
import React, { useEffect, useState } from 'react';
import ItemHeaderTabelaDemandasAndamento from './ItemHeaderTabelaDemandasAndamento';

export interface HeaderTabelaDemandasAndamentoProps {
  setDemandas: React.Dispatch<
    React.SetStateAction<NegociacaoAndamento[] | undefined>
  >;
}

const HeaderTabelaDemandasAndamento = ({
  setDemandas,
}: HeaderTabelaDemandasAndamentoProps): JSX.Element => {
  const estilosGrid = gridStyles();

  const [itemSelecionado, setItemSelecionado] = useState<ItemHeaderType>(
    itemOrdenacaoFilial,
  );

  useEffect(() => {
    setDemandas((demandasAnteriores) =>
      ordenarDemandasAndamento(
        itemSelecionado.titulo,
        demandasAnteriores,
        itemSelecionado.crescente,
      ),
    );
  }, [itemSelecionado, setDemandas]);

  return (
    <header className={estilosGrid.gridContainer}>
      {itensHeaderTabelaDemandasAndamento.map((itemHeader) => (
        <ItemHeaderTabelaDemandasAndamento
          itemHeader={itemHeader}
          setItemSelecionado={setItemSelecionado}
          selecionado={itemSelecionado.titulo === itemHeader.titulo}
          className={estilosGrid.gridItem}
          key={`item-header-tabela-demandas-andamento-${itemHeader.titulo}`}
        />
      ))}
    </header>
  );
};

export default HeaderTabelaDemandasAndamento;
