import { Box, makeStyles } from '@material-ui/core';
import CardDetalhesProposta from 'features/detalhesAprovacaoSupervisor/components/DetalhesProposta/CardDetalhesProposta';
import CardMediaRota from 'features/detalhesAprovacaoSupervisor/components/DetalhesProposta/CardMediaRota';
import React from 'react';
import spacingInPixels from 'shared/functions/materialUtils';
import theme from 'theme';
import * as detalhesAprovacaoSupervisorTypes from '../../api/detalhesAprovacaoSupervisorTypes';

export type DetalhesPropostaProps = {
  carga?: detalhesAprovacaoSupervisorTypes.Carga;
  proposta?: detalhesAprovacaoSupervisorTypes.Proposta;
  rota?: detalhesAprovacaoSupervisorTypes.Rota;
  motorista?: detalhesAprovacaoSupervisorTypes.Motorista;
  negociacao?: detalhesAprovacaoSupervisorTypes.Negociacao;
  carregando: boolean;
};

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: theme.palette.cores.branco,
    borderRadius: spacingInPixels(theme, 1),
    boxShadow: '0px 1px 2px 0px rgb(0 0 0 / 20%)',
    borderBottom: `1px solid ${theme.palette.cores.cinza[600]}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: theme.spacing(2, 0, 2, 0),
    padding: theme.spacing(0.5),
  },
}));

const DetalhesProposta = ({
  carga,
  proposta,
  rota,
  motorista,
  negociacao,
  carregando,
}: DetalhesPropostaProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <CardDetalhesProposta
        valorProposta={negociacao?.valorInicialSugeridoRobo}
        dataHoraPedidoAprovacao={proposta?.dataHoraPedidoAprovacao}
        carga={carga}
        negociacao={negociacao}
        carregando={carregando}
        aprovacaoNegociacao
      />
      <CardDetalhesProposta
        valorProposta={proposta?.valorProposta}
        dataHoraPedidoAprovacao={proposta?.dataHoraPedidoAprovacao}
        carga={carga}
        negociacao={negociacao}
        carregando={carregando}
        aprovacaoNegociacao
        valorSugeridoRobo
      />
      <CardMediaRota
        proposta={proposta}
        motorista={motorista}
        rota={rota}
        carregando={carregando}
      />
    </Box>
  );
};

export default DetalhesProposta;
