import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { Rubik } from '../../constants/font';

export default withStyles((theme) => ({
  root: {
    '& .MuiInputBase-input': {
      fontFamily: Rubik,
    },
    '& .MuiInputLabel-root': {
      fontWeight: 400,
    },
    '& .MuiInputBase-root': {
      fontWeight: 400,
      backgroundColor: theme.palette.cores.branco,
    },
  },
}))(TextField);
