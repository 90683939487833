import React from 'react';

import { Box, Switch, Typography, withStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { Rubik } from '../../../shared/constants/font';
import { atualizarListaClientesLiberadaOperation } from '../redux/chatNegociacaoOperation';
import { verificarCargaProd } from '../../../shared/constants/OrigemCarga';
import { RootState } from '../../../store/reducer';

const BoxSwitchListaClientes = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
})(Box);

const TypographyTextoListaClientes = withStyles((theme) => ({
  root: {
    fontFamily: Rubik,
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 400,
    marginLeft: '8px',
    color: `${theme.palette.cores.azulMartins[700]}`,
  },
}))(Typography);

const ChatListaClientes = (): JSX.Element => {
  const dispatch = useDispatch();

  const {
    chatIdNegociacao,
    chatIdProposta,
    chatListaClientesLiberada,
    chatOrigemCarga,
  } = useSelector((state: RootState) => state.chatNegociacaoReducer);
  return (
    <BoxSwitchListaClientes>
      <Box display="flex" alignItems="center">
        <TypographyTextoListaClientes color="secondary">
          Disponibilizar lista de clientes
        </TypographyTextoListaClientes>
      </Box>
      <Switch
        name="listaClientesLiberada"
        color="primary"
        checked={chatListaClientesLiberada}
        onChange={async (event: React.ChangeEvent<HTMLInputElement>) =>
          atualizarListaClientesLiberadaOperation(
            chatIdNegociacao,
            chatIdProposta,
            event.target.checked,
          )(dispatch)
        }
        disabled={!verificarCargaProd(chatOrigemCarga)}
      />
    </BoxSwitchListaClientes>
  );
};

export default ChatListaClientes;
