import React, { useMemo } from 'react';

import SwitchAba, { OpcaoSwitchAbaType } from 'shared/components/aba/SwitchAba';
import { AbaSelecionada } from '../../utils/modalDetalhes';

export interface SwitchModalDetalhesProps {
  abaSelecionada: AbaSelecionada;
  onClickOpcaoSwitch: (opcaoClicada: AbaSelecionada) => void;
  quantidadeNotificacoes?: number;
}

const SwitchModalDetalhes = ({
  abaSelecionada,
  onClickOpcaoSwitch,
  quantidadeNotificacoes,
}: SwitchModalDetalhesProps): JSX.Element => {
  const opcoes: OpcaoSwitchAbaType[] = useMemo(
    () => [
      {
        id: AbaSelecionada.Negociador,
        valor: 'Negociador',
        conteudoBadge: null,
      },
      {
        id: AbaSelecionada.Notificacoes,
        valor: 'Notificações',
        conteudoBadge: quantidadeNotificacoes || null,
      },
    ],
    [quantidadeNotificacoes],
  );

  return (
    <SwitchAba
      abas={opcoes}
      idAbaInicial={abaSelecionada}
      onClickOpcaoSwitch={onClickOpcaoSwitch}
    />
  );
};

export default SwitchModalDetalhes;
