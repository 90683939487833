import React from 'react';
import { Chip, createStyles, Theme, withStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import spacingInPixels from 'shared/functions/materialUtils';

export const StyledChip = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: spacingInPixels(theme, 3),
      borderRadius: spacingInPixels(theme, 1.5),
      color: theme.palette.cores.cinza[800],
      backgroundColor: theme.palette.cores.cinza[300],
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: spacingInPixels(theme, 1.5),
      lineHeight: spacingInPixels(theme, 2.5),
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.cores.cinza[400],
      },
    },
  }),
)(Chip);

const StyledSkeleton = withStyles((theme: Theme) =>
  createStyles({
    text: {
      transform: 'scale(1, 1)',
      borderRadius: spacingInPixels(theme, 1.5),
    },
  }),
)(Skeleton);

export interface SkeletonFiltroProps {
  quantidade: number;
  width?: number | string;
  height?: number | string;
}

export const SkeletonFiltro = ({
  quantidade,
  width,
  height,
}: SkeletonFiltroProps): JSX.Element => (
  <>
    {new Array(quantidade).fill(null).map((_, index) => (
      <StyledSkeleton
        width={width || 116}
        height={height || '100%'}
        key={`skeleton-filtro-${index + 1}`}
      />
    ))}
  </>
);
