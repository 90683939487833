import { TextoSimples } from 'features/configuracoes/styles';
import React from 'react';
import { useStyles } from '../styles/agrupamentoConfiguracao';
import { AgrupamentoConfiguracaoProps } from '../types/componentsTypes';

const AgrupamentoConfiguracao = ({
  titulo,
  className,
  children,
}: AgrupamentoConfiguracaoProps): JSX.Element => {
  const classes = useStyles();

  return (
    <section className={classes.container}>
      <span className={classes.agrupador} />
      <TextoSimples>{titulo}</TextoSimples>
      <div className={className}>{children}</div>
    </section>
  );
};

export default AgrupamentoConfiguracao;
