import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';
import CustomTooltip from 'shared/components/tooltip/CustomTooltip';
import EmptyPage from 'shared/components/emptyPage/EmptyPage';
import CelulaStatusPrioridade from 'shared/components/tabelaDemandas/CelulaStatusPrioridade';
import { formatarDataHoraSLA } from '../../utils/modalDetalhes';
import { TabelaNegociacoesProps } from './TabelaNegociacoes';
import SkeletonConteudoTabelaNegociacao from '../SkeletonConteudoTabelaNegociacao';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxHeight: '100%',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      background: `${theme.palette.cores.cinza[500]}60`,
      borderRadius: '8px',
    },
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '3fr 3.5fr 2.5fr 2.5fr',
    padding: `${spacingInPixels(theme, 1.5)} 0`,
  },
  texto: {
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: spacingInPixels(theme, 2),
    lineHeight: spacingInPixels(theme, 2.5),
    letterSpacing: '0.4px',
    color: theme.palette.cores.cinza[600],
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    paddingRight: spacingInPixels(theme, 2),
  },
  chipPrioridade: {
    paddingRight: '0.5em',
  },
}));

const ConteudoTabelaNegociacao = ({
  carregando,
  demandas,
  mensagemErro,
}: TabelaNegociacoesProps): JSX.Element => {
  const classes = useStyles();

  if (carregando)
    return (
      <>
        {new Array(10).fill(null).map((_, index) => (
          <SkeletonConteudoTabelaNegociacao
            key={`skeleton-demanda-${index + 1}`}
          />
        ))}
      </>
    );

  if (!carregando && demandas?.length === 0)
    return <EmptyPage texto={mensagemErro} />;

  return (
    <div className={classes.root}>
      {demandas?.map(
        ({ romaneio, nomeFilial, nomeCidade, prioridadeCarga, sla }) => (
          <section className={classes.container} key={`demanda-${romaneio}`}>
            <div style={{ display: 'flex' }}>
              <div className={classes.chipPrioridade}>
                <CelulaStatusPrioridade prioridadeCarga={prioridadeCarga} />
              </div>

              <CustomTooltip title={formatarDataHoraSLA(sla)}>
                <div className={classes.texto}>{formatarDataHoraSLA(sla)}</div>
              </CustomTooltip>
            </div>
            <CustomTooltip title={romaneio}>
              <div className={classes.texto}>{romaneio}</div>
            </CustomTooltip>
            <CustomTooltip title={nomeFilial}>
              <div className={classes.texto}>{nomeFilial}</div>
            </CustomTooltip>
            <CustomTooltip title={nomeCidade}>
              <div className={classes.texto}>{nomeCidade}</div>
            </CustomTooltip>
          </section>
        ),
      )}
    </div>
  );
};

export default ConteudoTabelaNegociacao;
