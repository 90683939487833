import { useTheme } from '@material-ui/core';
import * as React from 'react';
import { SVGProps } from 'react';

const IconeCorreto = ({
  fill,
  ...outros
}: SVGProps<SVGSVGElement>): JSX.Element => {
  const theme = useTheme();
  return (
    <svg width={16} height={12} fill="none" {...outros}>
      <path
        d="M14.71 1.21a1.001 1.001 0 0 0-1.42 0L5.84 8.67 2.71 5.53A1.022 1.022 0 0 0 1.29 7l3.84 3.84a1.001 1.001 0 0 0 1.42 0l8.16-8.16a1 1 0 0 0 0-1.47Z"
        fill={fill || theme.palette.cores.branco}
      />
    </svg>
  );
};

export default IconeCorreto;
