import { parseISO } from 'date-fns';
import {
  formatarDiaMesHoraMinuto,
  formatarHoraMinuto,
  obterDataAtual,
  verificarMesmoDia,
} from '../../../shared/functions/dataUtils';

export const converterDataApiParaDataChat = (dataHora: string): string => {
  const data = parseISO(dataHora);

  return verificarMesmoDia(data, obterDataAtual())
    ? formatarHoraMinuto(data)
    : formatarDiaMesHoraMinuto(data);
};
