import React, { useEffect, useState } from 'react';
import { useStyles } from 'features/configuracoes/styles/styleEsquemaColaborador';
import { Box, Button, CircularProgress } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import {
  ColaboradoresNegociadores,
  ConfiguracoesColaboradorNegociador,
  listaColaboradorNegociador,
} from 'features/configuracoes/api/configuracoesApiTypes';
import {
  apagarConfiguracaoColaboradoresNegociadores,
  atualizarConfiguracaoColaboradoresNegociadores,
  buscarColaboradoresNegociadores,
  configuracaoColaboradoresNegociadores,
  listaConfiguracaoColaboradoresNegociadores,
  salvarConfiguracaoColaboradoresNegociadores,
} from 'features/configuracoes/utils/configuracoes';
import {
  converterFormatoHorario,
  processarHoras,
  removeAccents,
} from 'features/configuracoes/utils/inputIntervaloTempo';
import ConteudoEditEsquemaColaborador from './ConteudoEditEsquemaColaborador';
import ListaColaboradoresConfigurados from './ListaColaboradoresConfigurados';

type DiasSelecionados = {
  segunda: boolean;
  terca: boolean;
  quarta: boolean;
  quinta: boolean;
  sexta: boolean;
  sabado: boolean;
  domingo: boolean;
};

const ContainerEsquemaColaboradores = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [
    colaboradores,
    setColaboradores,
  ] = useState<ColaboradoresNegociadores>();
  const [
    configColaboradorNegociador,
    setConfigColaboradorNegociador,
  ] = useState<ConfiguracoesColaboradorNegociador>();

  const [
    listaColaboradoresNegociadores,
    setListaColaboradoresNegociadores,
  ] = useState<listaColaboradorNegociador[] | undefined>();
  const [selectedColaborador, setSelectedColaborador] = useState<
    ColaboradoresNegociadores[]
  >([]);
  const [horarioInicio, setHorarioInicio] = useState<string>('00h 00');
  const [horarioFim, setHorarioFim] = useState<string>('00h 00');
  const [erroHorario, setErroHorario] = useState<boolean>(false);
  const [diasSelecionados, setDiasSelecionados] = useState<DiasSelecionados>({
    segunda: false,
    terca: false,
    quarta: false,
    quinta: false,
    sexta: false,
    sabado: false,
    domingo: false,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    buscarColaboradoresNegociadores(setColaboradores, dispatch);
  }, [dispatch]);

  const listaColaboradoresConfigurados = () => {
    listaConfiguracaoColaboradoresNegociadores(
      setListaColaboradoresNegociadores,
    );
  };

  useEffect(() => {
    listaColaboradoresConfigurados();
  }, []);

  useEffect(() => {
    configuracaoColaboradoresNegociadores(
      selectedColaborador[0]?.id,
      setConfigColaboradorNegociador,
    );
  }, [dispatch, selectedColaborador]);

  useEffect(() => {
    if (configColaboradorNegociador) {
      const horarioInicial = processarHoras(
        configColaboradorNegociador?.horarioInicio,
      );
      const horarioFinal = processarHoras(
        configColaboradorNegociador?.horarioFim,
      );
      if (horarioInicial) {
        const horasFormatadas = horarioInicial.join('h ');
        setHorarioInicio(horasFormatadas);
      }
      if (horarioFinal) {
        const horasFormatadas = horarioFinal.join('h ');
        setHorarioFim(horasFormatadas);
      }
    } else {
      setHorarioInicio('');
      setHorarioFim('');
      setDiasSelecionados({
        segunda: false,
        terca: false,
        quarta: false,
        quinta: false,
        sexta: false,
        sabado: false,
        domingo: false,
      });
    }
  }, [configColaboradorNegociador]);

  useEffect(() => {
    async function updateDiasSelecionados() {
      if (configColaboradorNegociador) {
        const {
          segunda,
          terca,
          quarta,
          quinta,
          sexta,
          sabado,
          domingo,
        } = configColaboradorNegociador;
        setDiasSelecionados({
          segunda: segunda || false,
          terca: terca || false,
          quarta: quarta || false,
          quinta: quinta || false,
          sexta: sexta || false,
          sabado: sabado || false,
          domingo: domingo || false,
        });
      }
    }
    updateDiasSelecionados();
  }, [configColaboradorNegociador]);

  const handleChangeHorarios = (
    e: { target: { value: string } },
    setHorario: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    const horas = processarHoras(e.target.value);

    if (horas && horas.length > 0) {
      const horasPart = horas[0];
      const minutosPart = horas[1];
      const horasValidas =
        parseInt(horasPart, 10) >= 0 && parseInt(horasPart, 10) <= 23;
      const minutosValidos =
        parseInt(minutosPart, 10) >= 0 && parseInt(minutosPart, 10) <= 59;

      setErroHorario(!(horasValidas && minutosValidos));
    }
    if (horas) {
      const horasFormatadas = horas.join('h ');
      setHorario(horasFormatadas);
    } else {
      setHorario('');
    }
  };

  const handleChangeHorarioInicio = (e: { target: { value: string } }) => {
    handleChangeHorarios(e, setHorarioInicio);
  };

  const handleChangeHorarioFim = (e: { target: { value: string } }) => {
    handleChangeHorarios(e, setHorarioFim);
  };

  const limparCampos = () => {
    setSelectedColaborador([]);
    setErroHorario(false);
    setHorarioFim('');
    setHorarioInicio('');
    setDiasSelecionados({
      segunda: false,
      terca: false,
      quarta: false,
      quinta: false,
      sexta: false,
      sabado: false,
      domingo: false,
    });
    listaColaboradoresConfigurados();
  };

  const handleSalvarConfig = async () => {
    const horarioInicioConvertido = converterFormatoHorario(horarioInicio);
    const horarioFimConvertido = converterFormatoHorario(horarioFim);
    const request: ConfiguracoesColaboradorNegociador = {
      horarioInicio: horarioInicioConvertido,
      horarioFim: horarioFimConvertido,
      segunda: diasSelecionados.segunda,
      terca: diasSelecionados.terca,
      quarta: diasSelecionados.quarta,
      quinta: diasSelecionados.quinta,
      sexta: diasSelecionados.sexta,
      sabado: diasSelecionados.sabado,
      domingo: diasSelecionados.domingo,
    };
    setLoading(true);

    const exiteColaborador = listaColaboradoresNegociadores?.filter(
      (item) => item.colaborador.idColaborador === selectedColaborador[0].id,
    );

    if (exiteColaborador?.length === 0) {
      await salvarConfiguracaoColaboradoresNegociadores(
        selectedColaborador[0]?.id,
        request,
        dispatch,
      );
    } else {
      await atualizarConfiguracaoColaboradoresNegociadores(
        selectedColaborador[0]?.id,
        request,
        dispatch,
      );
    }
    limparCampos();
    setLoading(false);
  };

  const apagarColaborador = async () => {
    setLoading(true);
    await apagarConfiguracaoColaboradoresNegociadores(
      selectedColaborador[0].id,
      dispatch,
    );

    limparCampos();
    setLoading(false);
  };

  const handleDiaSelecionado = (dia: string) => {
    const diaFormatado = removeAccents(dia.toLowerCase());

    setDiasSelecionados((prevDiasSelecionados) => ({
      ...prevDiasSelecionados,
      [diaFormatado as keyof DiasSelecionados]: !prevDiasSelecionados[
        diaFormatado as keyof DiasSelecionados
      ],
    }));
  };

  const handleAutocompleteChange = (
    event: React.ChangeEvent<unknown>,
    newValue: ColaboradoresNegociadores[] | [],
  ) => {
    if (Array.isArray(newValue) && newValue.length > 0) {
      const recentItem = newValue[newValue.length - 1];
      setSelectedColaborador([recentItem]);
    } else {
      setSelectedColaborador([]);
    }
  };

  const selecionarColaboradorEdit = (idColaborador: number, nome: string) => {
    const colaborador: ColaboradoresNegociadores = { id: idColaborador, nome };
    setSelectedColaborador([colaborador]);
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box className={classes.container}>
        <Box className={classes.coluna1}>
          <ConteudoEditEsquemaColaborador
            dadosColaboradores={colaboradores}
            selectedColaborador={selectedColaborador}
            horarioInicio={horarioInicio}
            horarioFim={horarioFim}
            erroHorario={erroHorario}
            diasSelecionados={diasSelecionados}
            handleChangeHorarioInicio={handleChangeHorarioInicio}
            handleChangeHorarioFim={handleChangeHorarioFim}
            handleAutocompleteChange={handleAutocompleteChange}
            handleDiaSelecionado={handleDiaSelecionado}
          />
        </Box>
        <Box className={classes.coluna2}>
          <ListaColaboradoresConfigurados
            listaColaboradoresNegociadores={listaColaboradoresNegociadores}
            selecionarColaboradorEdit={selecionarColaboradorEdit}
          />
        </Box>
      </Box>
      <Box
        display="flex"
        gridGap="15px"
        marginTop="10px"
        marginRight="30px"
        justifyContent="space-between"
      >
        <Button
          onClick={apagarColaborador}
          disabled={
            !listaColaboradoresNegociadores?.find(
              (item) =>
                item?.colaborador?.idColaborador === selectedColaborador[0]?.id,
            ) ||
            horarioInicio >= horarioFim ||
            erroHorario
          }
          className={classes.buttonApagar}
        >
          Apagar
        </Button>
        <Box display="flex" gridGap="10px" alignItems="center">
          <Button
            onClick={limparCampos}
            className={classes.buttonLimpar}
            disabled={selectedColaborador.length === 0}
          >
            Limpar
          </Button>
          {loading ? (
            <CircularProgress size={25} />
          ) : (
            <Button
              onClick={handleSalvarConfig}
              variant="contained"
              disabled={
                !horarioFim ||
                !horarioInicio ||
                !Object.values(diasSelecionados).some(
                  (valor) => valor === true,
                ) ||
                horarioInicio >= horarioFim ||
                selectedColaborador.length === 0 ||
                erroHorario
              }
              className={classes.buttonSalvar}
            >
              Salvar
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ContainerEsquemaColaboradores;
