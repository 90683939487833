import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Chip, useTheme } from '@material-ui/core';
import StatusDemandaEnum from 'shared/constants/StatusDemandaEnum';
import IconeReprovar from 'assets/icones/IconeReprovar';
import IconeCaixa from '../../../assets/icones/IconeCaixa';
import IconeCorretoQuadrado from '../../../assets/icones/IconeCorretoQuadrado';
import IconeBalaoChat from '../../../assets/icones/IconeBalaoChat';
import IconeRelogio from '../../../assets/icones/IconeRelogio';
import IconeExclamacaoOctogono from '../../../assets/icones/IconeExclamacaoOctogono';
import CustomTooltip from '../../../shared/components/tooltip/CustomTooltip';
import { Demandas, StatusDemandaType } from '../constants/Types';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    paddingRight: '20%',
    marginTop: '10px',
    gap: 10,
  },

  chipStatus: {
    width: 100,
    padding: 6,
    borderRadius: 4,
    gap: 6,
    verticalAlign: 'middle',
    cursor: 'pointer',
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '32px',
  },

  demandasDoDia: {
    background: theme.palette.cores.azul[50],
  },
  concluidas: {
    background: theme.palette.cores.verdeClaro[100],
  },
  emNegociacao: {
    background: theme.palette.cores.azulPiscina[50],
  },
  naoIniciadas: {
    background: theme.palette.cores.laranja[100],
  },
  emAprovacao: {
    background: theme.palette.cores.cinza[300],
  },
  canceladas: {
    background: theme.palette.cores.vermelho[100],
  },
}));

interface StatusDemandaProps {
  dadosStatusDemanda: Demandas;
  onClick: (filtro: StatusDemandaEnum | null) => void;
  isAutomatizado: boolean;
}

const StatusDemanda = ({
  dadosStatusDemanda,
  onClick,
  isAutomatizado,
}: StatusDemandaProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();

  const classTipoDemandas = (tipo: StatusDemandaEnum | null) => {
    switch (tipo) {
      case StatusDemandaEnum.Concluida:
        return classes.concluidas;
      case StatusDemandaEnum.EmNegociacao:
        return classes.emNegociacao;
      case StatusDemandaEnum.NaoIniciada:
        return classes.naoIniciadas;
      case StatusDemandaEnum.EmAprovacao:
        return classes.emAprovacao;
      case StatusDemandaEnum.Cancelada:
        return classes.canceladas;
      case StatusDemandaEnum.Recusada:
        return classes.canceladas;
      default:
        return classes.demandasDoDia;
    }
  };

  const {
    concluidas,
    emAprovacao,
    emNegociacao,
    naoIniciadas,
    total,
    recusadas,
  } = dadosStatusDemanda;

  const listaStatusDemanda: StatusDemandaType[] = [
    {
      id: 1,
      icone: <IconeCaixa width={15} height={15} />,
      valor: total,
      mensagemTooltip: 'Demandas do dia',
      tipo: null,
    },
    {
      id: 2,
      icone: (
        <IconeCorretoQuadrado
          width={15}
          height={15}
          fill={theme.palette.cores.verde[500]}
        />
      ),
      valor: concluidas,
      mensagemTooltip: 'Concluídas',
      tipo: StatusDemandaEnum.Concluida,
    },
    {
      id: 3,
      icone: <IconeBalaoChat width={15} height={15} />,
      valor: emNegociacao,
      mensagemTooltip: 'Em negociação',
      tipo: StatusDemandaEnum.EmNegociacao,
    },
    {
      id: 4,
      icone: (
        <IconeRelogio
          transform="scale(-1,1)"
          width={15}
          height={15}
          fill={theme.palette.cores.laranja[700]}
        />
      ),
      valor: naoIniciadas,
      mensagemTooltip: 'Não iniciadas',
      tipo: StatusDemandaEnum.NaoIniciada,
    },
    {
      id: 5,
      icone: <IconeExclamacaoOctogono width={15} height={15} />, // Ícone para isAutomatizado false
      valor: emAprovacao,
      mensagemTooltip: 'Em aprovação',
      tipo: StatusDemandaEnum.EmAprovacao,
    },
  ];

  const listaStatusDemandaAutomatizado: StatusDemandaType[] = [
    {
      id: 1,
      icone: <IconeBalaoChat width={15} height={15} />,
      valor: emNegociacao,
      mensagemTooltip: 'Em negociação',
      tipo: StatusDemandaEnum.EmNegociacao,
    },
    {
      id: 2,
      icone: (
        <IconeCorretoQuadrado
          width={15}
          height={15}
          fill={theme.palette.cores.verde[500]}
        />
      ),
      valor: concluidas,
      mensagemTooltip: 'Concluídas',
      tipo: StatusDemandaEnum.Concluida,
    },
    {
      id: 3,
      icone: (
        <IconeReprovar
          width={15}
          height={15}
          fill={theme.palette.cores.vermelho[700]}
        />
      ),
      valor: recusadas,
      mensagemTooltip: 'Recusadas',
      tipo: StatusDemandaEnum.Recusada,
    },
    {
      id: 4,
      icone: (
        <IconeRelogio
          transform="scale(-1,1)"
          width={15}
          height={15}
          fill={theme.palette.cores.laranja[700]}
        />
      ),
      valor: naoIniciadas,
      mensagemTooltip: 'Não iniciadas',
      tipo: StatusDemandaEnum.NaoIniciada,
    },
  ];

  const newList = isAutomatizado
    ? listaStatusDemandaAutomatizado
    : listaStatusDemanda;

  return (
    <div className={classes.root}>
      {newList.map((item, index) => (
        <CustomTooltip
          title={item.mensagemTooltip}
          placement="top"
          fontSize={14}
          arrow
          key={`chip-status-demanda-${index + 1}`}
        >
          <div>
            <Chip
              className={`${classes.chipStatus}  ${classTipoDemandas(
                item.tipo,
              )}`}
              icon={item.icone}
              label={item.valor}
              onClick={() => onClick(item.tipo)}
            />
          </div>
        </CustomTooltip>
      ))}
    </div>
  );
};

export default StatusDemanda;
