import { makeStyles, useTheme } from '@material-ui/core';
import React from 'react';
import IconeReprovadoCirculo from '../../../../../assets/icones/IconeReprovadoCirculo';
import { formatarNumeroParaStringMonetariaComSimbolo } from '../../../../../shared/functions/dinheiroUtils';
import spacingInPixels from '../../../../../shared/functions/materialUtils';
import ChatBalaoInformativo from '../../../components/ChatBalaoInformativo';
import { converterDataApiParaDataChat } from '../../../utils/dataHora';

import TypographyChat from '../../TypographyChat';
import { ChatBalaoProps } from '../ChatBalao';
import NomeGestor from './NomeGestor';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: `${theme.palette.cores.vermelho[200]}40`,
    borderColor: `${theme.palette.cores.vermelho[700]}90`,
    width: 360,
    padding: spacingInPixels(theme, 1),
  },
  textoValor: {
    fontSize: '16px',
    fontWeight: 600,
    color: theme.palette.cores.vermelho[700],
  },
  textoNegrito: {
    fontWeight: 600,
  },
}));

const RecusaSupervisor = ({ mensagem }: ChatBalaoProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <ChatBalaoInformativo
      className={classes.container}
      icone={<IconeReprovadoCirculo fill={theme.palette.cores.vermelho[700]} />}
    >
      <TypographyChat>
        O valor reprovado pelo supervisor foi de{' '}
        <span className={classes.textoValor}>
          {formatarNumeroParaStringMonetariaComSimbolo(
            mensagem.valorContraproposta,
          )}{' '}
        </span>
        às {converterDataApiParaDataChat(mensagem.dataEnvio)}.
      </TypographyChat>
      <TypographyChat>
        <span className={classes.textoNegrito}>Motivo: </span>
        {mensagem.texto}
      </TypographyChat>
      <NomeGestor nome={mensagem.nomeGestorAprovacao} />
    </ChatBalaoInformativo>
  );
};

export default RecusaSupervisor;
