import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';

import spacingInPixels from '../../../../shared/functions/materialUtils';
import { Rubik } from '../../../../shared/constants/font';
import { converterDataApiParaDataChat } from '../../utils/dataHora';

const TypographyHora = withStyles((theme) => ({
  root: {
    color: `${theme.palette.cores.cinza[900]}75`,
    fontFamily: Rubik,
    fontSize: '11px',
    fontWeight: 400,
    lineHeight: spacingInPixels(theme, 1.5),
  },
}))(Typography);

export type ChatHoraProps = {
  data: string;
};

const ChatHora = ({ data }: ChatHoraProps): JSX.Element => {
  return (
    <Box display="flex" justifyContent="flex-end" marginTop="4px">
      <TypographyHora>{converterDataApiParaDataChat(data)}</TypographyHora>
    </Box>
  );
};

export default ChatHora;
