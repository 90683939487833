import { ConexaoAPI } from 'shared/classes/conexaoAPI';
import {
  DemandaFilial,
  DemandasDiariasFilialResponse,
  DemandasFilialResponse,
} from './listagemFiliaisApiTypes';

const obterUrlBuscarListagemDemandaFilial = (): string =>
  `${process.env.REACT_APP_URL_API_BASE}/dashboards/filiais/demanda-diaria`;

const obterUrlBuscarMetricasNegociaoesFilial = (idFilial: number): string =>
  `${process.env.REACT_APP_URL_API_BASE}/dashboards/filiais/${idFilial}/demanda-diaria`;

export const obterDemandaFilial = async (): Promise<DemandaFilial[]> => {
  const url = obterUrlBuscarListagemDemandaFilial();
  const conexaoApi = new ConexaoAPI(url, 'get');
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<DemandasFilialResponse>();
  const response = await conectar();
  return response?.data?.filiais;
};

export const obterNegociacoesFilial = async (
  idFilial: number,
): Promise<DemandasDiariasFilialResponse> => {
  const url = obterUrlBuscarMetricasNegociaoesFilial(idFilial);
  const conexaoApi = new ConexaoAPI(url, 'get');
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<DemandasDiariasFilialResponse>();
  const response = await conectar();
  return response?.data;
};
