import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { HeaderProps } from '../types/componentsTypes';

const useStyles = makeStyles(() => ({
  header: {
    fontFamily: 'Rubik',
    fontSize: '22px',
  },
}));

const Header = ({ titulo, className }: HeaderProps): JSX.Element => {
  const styles = useStyles();
  return (
    <Typography className={`${styles.header} ${className}`}>
      {titulo}
    </Typography>
  );
};

export default Header;
