import React from 'react';
import theme from '../../../../theme';
import InformacoesPersona from './InformacoesPersona';

export type InformacoesNegociadorType = {
  nome: string;
};

const InformacoesNegociador = ({
  nome,
}: InformacoesNegociadorType): JSX.Element => {
  const cargo = `Negociador`;
  return (
    <InformacoesPersona
      nome={nome}
      texto={cargo}
      corFundoContainer={theme.palette.background.default}
    />
  );
};

export default InformacoesNegociador;
