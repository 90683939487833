import { useTheme } from '@material-ui/core';
import * as React from 'react';
import { SVGProps } from 'react';

const IconeAtencaoTriangulo = ({
  fill,
  ...outros
}: SVGProps<SVGSVGElement>): JSX.Element => {
  const theme = useTheme();
  return (
    <svg width={14} height={13} fill={fill || 'none'} {...outros}>
      <path
        d="M6 8a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Zm5.335.735-4.025-7a1.5 1.5 0 0 0-2.62 0l-4 7A1.5 1.5 0 0 0 1.97 11h8.06a1.5 1.5 0 0 0 1.305-2.265Zm-.865 1a.5.5 0 0 1-.44.255H1.97a.5.5 0 0 1-.44-.255.5.5 0 0 1 0-.5l4-7a.5.5 0 0 1 .89 0l4.025 7a.5.5 0 0 1 .025.51v-.01ZM6 4a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 1 0v-2A.5.5 0 0 0 6 4Z"
        fill={fill || theme.palette.cores.vermelho[500]}
      />
    </svg>
  );
};

export default IconeAtencaoTriangulo;
