import { makeStyles } from '@material-ui/core';
import React from 'react';
import TypographyChat from '../../TypographyChat';

export type NomeGestorProps = {
  nome?: string;
};

const useStyles = makeStyles(() => ({
  textoNegrito: {
    fontWeight: 600,
  },
}));

const NomeGestor = ({ nome }: NomeGestorProps): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      {nome && (
        <TypographyChat>
          <span className={classes.textoNegrito}>Gestor: </span>
          {nome}
        </TypographyChat>
      )}
    </>
  );
};

export default NomeGestor;
