import React from 'react';
import { Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import IconePeso from 'assets/icones/IconePeso';
import IconeCaixa from 'assets/icones/IconeCaixa';
import IconeVelocimetro from 'assets/icones/IconeVelocimetro';
import IconeLoja from 'assets/icones/IconeLoja';
import IconeDropBox from 'assets/icones/IconeDropBox';
import IconeCalendario from 'assets/icones/IconeCalendario';
import IconeLocalizacao from 'assets/icones/IconeLocalizacao';
import Paper from '../../../shared/components/paper/Paper';
import InformacaoItem from '../../../shared/components/item/InformacaoItem';

type InformacoesGeraisCargaProps = {
  filial: string;
  destino: string;
  dataCarregamento: string;
  distancia: number;
  entregas: number;
  volumeTotal: number;
  pesoTotal: number;
  cargaPF: number;
  cargaPJ: number;
};

const InformacoesGeraisCarga = ({
  filial,
  destino,
  dataCarregamento,
  distancia,
  entregas,
  volumeTotal,
  pesoTotal,
  cargaPF,
  cargaPJ,
}: InformacoesGeraisCargaProps): JSX.Element => {
  const theme = useTheme();

  const estilo = {
    tituloColor: String(theme.palette.primary.main),
  };

  return (
    <Paper style={{ margin: theme.spacing(0, 0, 2, 0) }}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={5} lg={6}>
          <InformacaoItem
            icone={
              <IconeLoja
                fill={theme.palette.cores.azulMartins[500]}
                width="24px"
                height="24px"
              />
            }
            titulo="Filial"
            informacao={filial.toUpperCase()}
            altIcone="Filial"
            titleIcone="Filial"
            estilo={estilo}
          />
        </Grid>
        <Grid item xs={6} md={2} lg={2}>
          <InformacaoItem
            icone={
              <IconeVelocimetro
                fill={theme.palette.cores.azulMartins[500]}
                width="24px"
                height="24px"
              />
            }
            titulo="Quilometragem"
            informacao={`${distancia.toLocaleString()} km`}
            altIcone="Quilometragem"
            titleIcone="Quilometragem"
            estilo={estilo}
          />
        </Grid>
        <Grid item xs={6} md={2} lg={2}>
          <InformacaoItem
            icone={
              <IconeDropBox
                fill={theme.palette.cores.azulMartins[500]}
                width="24px"
                height="24px"
              />
            }
            titulo="Entregas"
            informacao={String(entregas)}
            altIcone="Entregas"
            titleIcone="Entregas"
            estilo={estilo}
            countPJ={cargaPJ}
            countPF={cargaPF}
          />
        </Grid>
        <Grid item xs={6} md={2} lg={2}>
          <InformacaoItem
            icone={
              <IconeCalendario
                fill={theme.palette.cores.azulMartins[500]}
                width="24px"
                height="24px"
              />
            }
            titulo="Carregamento"
            informacao={dataCarregamento}
            altIcone="Carregamento"
            titleIcone="Carregamento"
            estilo={estilo}
          />
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={4} md={5} lg={6}>
          <InformacaoItem
            icone={
              <IconeLocalizacao
                fill={theme.palette.cores.azulMartins[500]}
                width="24px"
                height="24px"
              />
            }
            titulo="Principal Destino"
            informacao={destino}
            altIcone="Principal Destino"
            titleIcone="Principal Destino"
            estilo={estilo}
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <InformacaoItem
            icone={
              <IconeCaixa
                fill={theme.palette.cores.azulMartins[500]}
                width="24px"
                height="24px"
              />
            }
            titulo="Volume total"
            informacao={`${volumeTotal.toLocaleString()} m³`}
            altIcone="Volume total"
            titleIcone="Volume total"
            estilo={estilo}
          />
        </Grid>
        <Grid item xs={2} md={2}>
          <InformacaoItem
            icone={<IconePeso fill={theme.palette.cores.azulMartins[500]} />}
            titulo="Peso total"
            informacao={`${pesoTotal.toLocaleString()} kg`}
            altIcone="Peso total"
            titleIcone="Peso total"
            estilo={estilo}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default InformacoesGeraisCarga;
