import React from 'react';
import { makeStyles } from '@material-ui/core';
import BotaoRubik from 'shared/components/button/BotaoRubik';

const useStyles = makeStyles((theme) => ({
  botao: {
    background: theme.palette.cores.verde[700],
    borderColor: theme.palette.cores.verde[700],
    borderRadius: '8px',
    color: theme.palette.cores.branco,
    width: '112px',
    fontSize: '12px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.cores.verde[400],
    },
  },
}));

interface IBotaoSugestao {
  aoClicar: () => void;
}

const BotaoSugestao = ({ aoClicar }: IBotaoSugestao): JSX.Element => {
  const classes = useStyles();
  return (
    <BotaoRubik
      className={classes.botao}
      variant="outlined"
      disabled={false}
      onClick={aoClicar}
    >
      Ver sugestão
    </BotaoRubik>
  );
};

export default BotaoSugestao;
