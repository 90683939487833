import { useTheme } from '@material-ui/core';
import IconeRelogio from 'assets/icones/IconeRelogio';
import React, { useEffect, useState } from 'react';
import { obterIntervaloTempo } from 'shared/functions/IntervaloTempo';

import {
  converterDataApi,
  MilissegundosPorSegundo,
  obterDataAtual,
  obterDiferencaDatas,
} from '../../functions/dataUtils';
import BadgeIntervaloTempo from './BedgeIntervaloTempo';

export type BadgeCronometroType = {
  dataSolicitacaoAnalise?: string;
};

const BadgeCronometro = ({
  dataSolicitacaoAnalise,
}: BadgeCronometroType): JSX.Element => {
  const theme = useTheme();

  const obterDiferencaDataAtual = (): number => {
    if (!dataSolicitacaoAnalise) return 0;
    const dataAtual = obterDataAtual();
    const dataSolicitacao = converterDataApi(dataSolicitacaoAnalise);

    return obterDiferencaDatas(dataAtual, dataSolicitacao);
  };

  const [milissegundos, setMilissegundos] = useState(obterDiferencaDataAtual());

  useEffect(() => {
    setInterval(
      () => setMilissegundos((ms) => ms + MilissegundosPorSegundo),
      MilissegundosPorSegundo,
    );
  }, []);

  return (
    <BadgeIntervaloTempo
      icone={<IconeRelogio />}
      intervalo={obterIntervaloTempo(milissegundos)}
      backgroundColor={theme.palette.cores.laranja[200]}
    />
  );
};

export default BadgeCronometro;
