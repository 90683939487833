import React from 'react';
import { makeStyles } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';
import CustomSkeleton from 'shared/components/skeleton/CustomSkeleton';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacingInPixels(theme, 2),
    height: 616,
  },
}));

const SkeletonTabelaIndicadoresMacros = (): JSX.Element => {
  const classes = useStyles();

  return (
    <section className={classes.container}>
      {new Array(12).fill(null).map((_, index) => (
        <CustomSkeleton
          key={`skeleton-tabela-${index + 1}`}
          borderradius={16}
          width="100%"
          height={36}
        />
      ))}
    </section>
  );
};

export default SkeletonTabelaIndicadoresMacros;
