import { isValid } from 'date-fns';
import { dataMenorOuIgual } from '../../../shared/functions/dataUtils';

const validarDataMenorOuIgual = (
  dataInicio: Date | null,
  dataFim: Date | null,
): boolean => {
  return (
    dataFim == null ||
    dataInicio == null ||
    dataMenorOuIgual(dataInicio, dataFim)
  );
};

export const validarDataFim = (
  dataInicio: Date | null,
  dataFim: Date | null,
): boolean => {
  if (dataFim != null && !isValid(dataFim)) {
    return false;
  }

  return validarDataMenorOuIgual(dataInicio, dataFim);
};

export const validarDataInicio = (
  dataInicio: Date | null,
  dataFim: Date | null,
): boolean => {
  if (dataInicio != null && !isValid(dataInicio)) {
    return false;
  }

  return validarDataMenorOuIgual(dataInicio, dataFim);
};
