import { useTheme } from '@material-ui/core/styles';
import * as React from 'react';
import { SVGProps } from 'react';

const IconeSetaCirculo = ({
  fill,
  width,
  height,
  ...outros
}: SVGProps<SVGSVGElement>): JSX.Element => {
  const theme = useTheme();

  return (
    <svg
      width={width || 20}
      height={height || 20}
      viewBox="0 0 14 14"
      fill="none"
      {...outros}
    >
      <path
        d="M7.473 4.527a.667.667 0 0 0-.22-.14.667.667 0 0 0-.506 0 .667.667 0 0 0-.22.14l-2 2a.67.67 0 1 0 .946.946l.86-.866V9a.667.667 0 1 0 1.334 0V6.607l.86.866a.667.667 0 0 0 1.092-.216.667.667 0 0 0-.146-.73l-2-2ZM7 .333a6.667 6.667 0 1 0 0 13.334A6.667 6.667 0 0 0 7 .333Zm0 12A5.334 5.334 0 1 1 7 1.667a5.334 5.334 0 0 1 0 10.668Z"
        fill={fill || theme.palette.cores.azulMartins[500]}
      />
    </svg>
  );
};

export default IconeSetaCirculo;
