import { makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import spacingInPixels from 'shared/functions/materialUtils';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    minWidth: '18%',
    gridTemplateColumns: '1fr 3fr',
    gridTemplateRows: 'repeat(2,min-content)',
    gridColumnGap: spacingInPixels(theme, 1),
    placeItems: 'center',

    backgroundColor: theme.palette.cores.branco,
    padding: spacingInPixels(theme, 1),
    borderRadius: spacingInPixels(theme, 1),
    boxShadow: '0px 1px 6px 0px rgba(0, 0, 0, 0.09)',

    '& :last-child': {
      gridColumnStart: 2,
    },

    '& > *': {
      width: '100%',
    },
  },
}));
const SkeletonBigNumber = (): JSX.Element => {
  const classes = useStyles();
  return (
    <article className={classes.container}>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </article>
  );
};

export default SkeletonBigNumber;
