import React from 'react';
import { withStyles, Theme } from '@material-ui/core/styles';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';

export interface CustomTooltipProps extends TooltipProps {
  fontColor?: string;
  fontSize?: number;
  width?: string;
  backgroundColor?: string;
  maxWidth?: string;
  textAlign?: 'left' | 'right' | 'center';
}

const CustomizedTooltip = withStyles((theme: Theme) => ({
  tooltip: (props: CustomTooltipProps) => ({
    backgroundColor: props?.backgroundColor || theme.palette.cores.cinza[700],
    color: props?.fontColor || theme.palette.cores.branco,
    fontSize: theme.typography.pxToRem(props?.fontSize || 12),
    fontWeight: 'lighter',
    width: 'fit-content',
    maxWidth: props?.maxWidth,
    textAlign: props?.textAlign,
  }),
}))(Tooltip);

const CustomTooltip = (props: CustomTooltipProps): JSX.Element => (
  <CustomizedTooltip {...props} />
);

CustomizedTooltip.defaultProps = {
  placement: 'top-start',
  arrow: true,
  interactive: true,
};

export default CustomTooltip;
