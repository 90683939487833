export const verificarExtensaoArquivo = (
  tipoArquivo: string,
  tiposEsperados: Array<string>,
): boolean => tiposEsperados.includes(tipoArquivo);

export const verificarTamanhoArquivo = (
  tamanhoArquivo: number,
  tamanhoEsperado: number,
): boolean => tamanhoArquivo <= tamanhoEsperado;

export const verificarSeEhArquivo = (tipo: string): boolean => tipo === 'file';
