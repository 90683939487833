enum TipoValorContraproposta {
  ValorProposto,
  PorcentagemFrotaPropria,
  PorcentagemFreteTerceiro,
  PorEntrega,
  PorPeso,
  PorKm,
  PorcentagemMercadoria,
}

export default TipoValorContraproposta;
