import NegociacaoStatus from '../../../shared/constants/NegociacaoStatus';
import PropostaStatus from '../../../shared/constants/PropostaStatus';
import {
  Negociacao,
  PropostaNegociacao,
} from '../../informacoesNegociacao/api/informacoesNegociacaoApiTypes';

export const verificarDesabilitarPedirAprovacao = (
  propostasAplicativo: PropostaNegociacao[],
  idPropostaComFoco: number | undefined,
  negociacao: Negociacao | undefined,
): boolean => {
  const proposta = propostasAplicativo.find((p) => p.id === idPropostaComFoco);

  const negociacaoStatusInvalido =
    negociacao?.status !== NegociacaoStatus.EmAndamento &&
    negociacao?.status !== NegociacaoStatus.NovaNegociacao;

  const propostaStatusInvalido =
    proposta?.status !== PropostaStatus.AGUARDANDO &&
    proposta?.status !== PropostaStatus.CONTRAPROPOSTA &&
    proposta?.status !== PropostaStatus.ACEITE_MOTORISTA;

  return negociacaoStatusInvalido || propostaStatusInvalido;
};
