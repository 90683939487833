import { Box, makeStyles, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { Rubik } from '../../constants/font';

export type BadgePadraoProps = {
  texto: string;
  esconder?: boolean;
  condensado?: boolean;
};

const TypographyTexto = withStyles({
  root: {
    fontFamily: Rubik,
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
  },
})(Typography);

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.cores.cinza[300],
    borderRadius: theme.spacing(2),
    padding: '4px 8px',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    height: ({ condensado }: { condensado: boolean }) =>
      condensado ? 24 : 'unset',
  },
}));

const BadgePadrao = ({
  texto,
  esconder,
  condensado = false,
}: BadgePadraoProps): JSX.Element => {
  const classes = useStyles({ condensado });
  return esconder ? (
    <></>
  ) : (
    <Box className={classes.container}>
      <TypographyTexto color="primary">{texto}</TypographyTexto>
    </Box>
  );
};

export default BadgePadrao;
