import React, { ReactNode } from 'react';
import { EstiloBotaoIconeTexto, LabelBotao } from './styles';

interface BotaoIconeTextoProps {
  color?: string;
  children: ReactNode;
  icon: React.ReactElement;
  backgroundColor?: string;
  onClick?: () => void;
  desabilitado?: boolean;
}

const BotaoIconeTexto = ({
  color,
  children,
  icon,
  backgroundColor,
  onClick,
  desabilitado,
}: BotaoIconeTextoProps): JSX.Element => {
  return (
    <EstiloBotaoIconeTexto
      corTexto={color}
      corFundo={backgroundColor}
      onClick={onClick}
      disabled={desabilitado}
    >
      {React.cloneElement(icon, {
        fill: color,
      })}
      <LabelBotao>{children} </LabelBotao>
    </EstiloBotaoIconeTexto>
  );
};

BotaoIconeTexto.defaultProps = {
  color: '',
  backgroundColor: 'transparent',
  onClick: () => null,
  desabilitado: false,
};

export default BotaoIconeTexto;
