import { Typography, withStyles } from '@material-ui/core';

const TypographyTipoValor = withStyles((theme) => ({
  root: {
    fontSize: '14px',
    fontWeight: 400,
    color: theme.palette.cores.cinza[600],
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}))(Typography);

export default TypographyTipoValor;
