import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import { IndicadoresAlertaResponse } from 'features/indicadores/api/indicadoresApiTypes';
import ConteudoErro from 'features/indicadores/components/ConteudoErro';
import { mensagemListaVazia } from 'features/indicadores/constants/Constants';
import { Bar } from 'react-chartjs-2';
import LabelGrafico from 'features/indicadores/components/LabelGrafico';
import { formatarIntervaloTempo } from 'shared/functions/IntervaloTempo';
import spacingInPixels from 'shared/functions/materialUtils';
import SkeletonContainerAlerta from './SkeletonContainerAlerta';

export const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: 300,
    width: '100%',

    '& > canvas': {
      maxHeight: 300,
    },
  },
  labelContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: spacingInPixels(theme, 4),
    marginTop: spacingInPixels(theme, 3),
  },
}));

interface GraficoAprovacaoVisualizacaoProps {
  indicadoresAlerta: IndicadoresAlertaResponse;
  carregando: boolean;
}

const GraficoAprovacaoVisualizacao = ({
  carregando,
  indicadoresAlerta,
}: GraficoAprovacaoVisualizacaoProps): JSX.Element => {
  const classes = useStyles();
  const listaAprovacaoVazia = !indicadoresAlerta.alertaAprovacao.length;
  const listaVisualizacaoVazia = !indicadoresAlerta.alertaVisualizacao.length;
  const theme = useTheme();

  const indicadorVisualizacao = {
    cor: theme.palette.cores.roxo[200],
    texto: `Não visto há +${formatarIntervaloTempo(
      indicadoresAlerta.limiteAlertaVisualizacaoNegociacao,
    )}`,
  };

  const indicadorAprovacao = {
    cor: theme.palette.cores.roxo[100],
    texto: `Sem aprovação há +${formatarIntervaloTempo(
      indicadoresAlerta.limiteAlertaSemAprovacao,
    )}`,
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
      },
    },
  };

  const data = {
    labels: indicadoresAlerta.alertaVisualizacao.map(
      (indicadores) => indicadores.periodo,
    ),
    datasets: [
      {
        label: indicadorVisualizacao.texto,
        data: indicadoresAlerta.alertaVisualizacao.map(
          (indicadores) => indicadores.quantidade,
        ),
        borderColor: indicadorVisualizacao.cor,
        backgroundColor: indicadorVisualizacao.cor,
        borderRadius: 12,
      },
      {
        label: indicadorAprovacao.texto,
        data: indicadoresAlerta.alertaAprovacao.map(
          (indicadores) => indicadores.quantidade,
        ),
        borderColor: indicadorAprovacao.cor,
        backgroundColor: indicadorAprovacao.cor,
        borderRadius: 12,
      },
    ],
  };

  return (
    <section className={classes.container}>
      {(() => {
        if (carregando) return <SkeletonContainerAlerta />;
        if (listaAprovacaoVazia && listaVisualizacaoVazia)
          return <ConteudoErro mensagem={mensagemListaVazia} />;
        return (
          <div className={classes.container}>
            <Bar options={options} data={data} />
            <div className={classes.labelContainer}>
              {[indicadorVisualizacao, indicadorAprovacao].map((indicador) => (
                <LabelGrafico
                  cor={indicador.cor as string}
                  texto={indicador.texto}
                />
              ))}
            </div>
          </div>
        );
      })()}
    </section>
  );
};

export default GraficoAprovacaoVisualizacao;
