import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { RootState } from '../../../../store/reducer';

import ConfirmarDialogButton from '../../../../shared/components/button/ConfirmarDialogButton';
import spacingInPixels from '../../../../shared/functions/materialUtils';
import MotivoRecusa from '../../../../shared/constants/MotivoRecusa';
import Bold from '../../../../shared/components/typography/Bold';
import Dialog from '../../../../shared/components/modal/Dialog';
import ButtonTextTransformNone from '../../../../shared/components/button/ButtonTextTransformNone';

import { cancelarNegociacaoPorReatribuicaoOperation as cancelarNegociacaoOperation } from '../../redux/informacoesNegociacaoOperation';

import { recusarPropostaOperation } from './redux/motoristasCardOperation';

const useStyles = makeStyles((theme) => ({
  primeiroParagrafo: {
    marginTop: spacingInPixels(theme, 3),
    marginBottom: spacingInPixels(theme, 2),
  },
  botaoCancelar: {
    marginRight: spacingInPixels(theme, 2),
  },
}));

const ReatribuirNegociacaoDialog = (): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const { idNegociacao } = useSelector(
    (state: RootState) => state.informacoesNegociacaoReducer,
  );

  const {
    modalReatribuirNegociacaoAberto,
    propostaARecusar,
    motivoRecusarProposta,
  } = useSelector((state: RootState) => state.motoristasCardReducer);

  const recusarProposta = async () => {
    if (propostaARecusar) {
      await recusarPropostaOperation(
        idNegociacao,
        propostaARecusar.id,
        Number(motivoRecusarProposta),
      )(dispatch);
    }
  };

  const cancelarNegociacao = async () => {
    await cancelarNegociacaoOperation(
      idNegociacao,
      Number(motivoRecusarProposta),
    )(dispatch);
  };

  const nomeMotorista = propostaARecusar?.motorista;
  const textoMotivoRecusa = MotivoRecusa.find(
    (motivo) => motivo.id === Number(motivoRecusarProposta),
  )?.descricao.toLocaleUpperCase();

  return (
    <Dialog open={modalReatribuirNegociacaoAberto}>
      <DialogContent>
        <Box>
          <Typography variant="body1" className={classes.primeiroParagrafo}>
            Você irá recusar a proposta com o motorista{' '}
            <Bold>{nomeMotorista}</Bold> pelo motivo{' '}
            <Bold>{textoMotivoRecusa}</Bold>.
          </Typography>
          <Typography variant="body1">
            Você deseja continuar a negociação?
          </Typography>
        </Box>
        <DialogActions>
          <Box
            display="flex"
            justifyContent="flex-end"
            marginTop={spacingInPixels(theme, 8)}
          >
            <Tooltip title="Cancelar negociação">
              <ButtonTextTransformNone
                color="default"
                variant="contained"
                className={classes.botaoCancelar}
                onClick={cancelarNegociacao}
              >
                Cancelar negociação
              </ButtonTextTransformNone>
            </Tooltip>
            <ConfirmarDialogButton
              textoBotao="Continuar negociação"
              onClick={recusarProposta}
              disabled={false}
            />
          </Box>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ReatribuirNegociacaoDialog;
