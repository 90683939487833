import React, { useEffect, useMemo, useState } from 'react';
import { BuscarFiliaisResponse } from 'features/configuracoes/api/configuracoesApiTypes';
import {
  atualizarAlgoritmoFilial,
  buscarFiliais,
} from 'features/configuracoes/utils/configuracoes';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
  Box,
  TextField,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Autocomplete, Pagination } from '@material-ui/lab';

const ConfiguracaoFilialAlgoritmo = (): JSX.Element => {
  const dispatch = useDispatch();
  const [filiaisDados, setFiliaisDados] = useState<BuscarFiliaisResponse[]>([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(5);
  const [initialLoad, setInitialLoad] = useState(true);
  const [consulta, setConsulta] = useState('');

  useEffect(() => {
    buscarFiliais(setFiliaisDados, dispatch, consulta);
  }, [consulta, dispatch]);

  useEffect(() => {
    if (initialLoad && filiaisDados.length > 0) {
      setPage(1);
      setInitialLoad(false);
    }
  }, [filiaisDados, initialLoad]);

  useEffect(() => {
    setPage(1);
  }, [consulta]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const filiaisFiltradas = useMemo(
    () => filiaisDados.filter((filial) => filial.descricaoSlim !== ''),
    [filiaisDados],
  );

  const numberOfPages = useMemo(
    () => Math.ceil(filiaisFiltradas.length / rowsPerPage),
    [filiaisFiltradas, rowsPerPage],
  );

  const atualizarCampoGreguimAtivo = (
    idFilial: string,
    novoStatus: boolean,
  ) => {
    const novasFiliaisDados = filiaisDados.map((filial) =>
      String(filial.id) === idFilial
        ? { ...filial, isGreguimAtivo: novoStatus }
        : filial,
    );

    setFiliaisDados(novasFiliaisDados);
  };

  const handleCheckedFilial = (idFilial: string, ativar: boolean) => {
    atualizarAlgoritmoFilial(idFilial, ativar, dispatch);
  };

  const handleSwitchChange = (idFilial: number) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const novoStatus = event.target.checked;
    atualizarCampoGreguimAtivo(String(idFilial), novoStatus);
    handleCheckedFilial(String(idFilial), novoStatus);
  };

  return (
    <>
      <Autocomplete
        freeSolo
        options={[]}
        inputValue={consulta}
        onInputChange={(event, newInputValue) => {
          setConsulta(newInputValue);
        }}
        renderInput={(params) => (
          <TextField {...params} label="Buscar Filiais" variant="outlined" />
        )}
        style={{ marginBottom: '10px', width: '60%' }}
      />
      <TableContainer component={Paper} style={{ width: '60%' }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Nome da Filial</TableCell>
              <TableCell style={{ paddingLeft: '25px' }}>Ativo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filiaisFiltradas
              .slice((page - 1) * rowsPerPage, page * rowsPerPage)
              .map((filial) => (
                <TableRow key={filial.id}>
                  <TableCell>{filial.descricaoSlim}</TableCell>
                  <TableCell>
                    <Switch
                      checked={filial.isGreguimAtivo}
                      onChange={handleSwitchChange(filial.id)}
                      color="primary"
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <Box alignItems="center" padding={2} style={{ float: 'right' }}>
          <Pagination
            count={numberOfPages}
            page={page}
            onChange={handleChangePage}
            shape="rounded"
            variant="outlined"
            color="primary"
          />
        </Box>
      </TableContainer>
    </>
  );
};

export default ConfiguracaoFilialAlgoritmo;
