import React from 'react';

import TabPanel from '@material-ui/lab/TabPanel';
import { CircularProgress } from '@material-ui/core';
import AbasPropostaSupervisor from '../../constants/AbasPropostaSupervisor';
import useStylesPanelProposta from '../../styles/panelProposta';

const PanelCarregando = (): JSX.Element => {
  const classes = useStylesPanelProposta();

  return (
    <TabPanel
      className={classes.panelContainerLoading}
      value={AbasPropostaSupervisor.Carregando}
    >
      <CircularProgress size="72px" className={classes.loading} />
    </TabPanel>
  );
};

export default PanelCarregando;
