import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CloseRounded from '@material-ui/icons/CloseRounded';
import { Button, makeStyles, useTheme } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';

import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { Rubik } from 'shared/constants/font';
import { fecharNotificacoes } from '../utils/notificacoes';

import BotaoIcone from '../../../shared/components/button/BotaoIcone';

const useStyles = makeStyles((theme) => ({
  buttonNotificacao: {
    backgroundColor: 'blue',
    borderRadius: '100px',
    fontSize: '12px',
    fontWeight: 400,
    textTransform: 'none',
    boxShadow: 'none',
    margin: '10px 0px 16px 16px',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  buttonNotificacaoRight: {
    backgroundColor: 'blue',
    borderRadius: '100px',
    fontSize: '12px',
    fontWeight: 400,
    textTransform: 'none',
    boxShadow: 'none',
    margin: '10px 0px 16px 5px',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  containerActions: {
    display: 'flex',
    gap: '4px',
    margin: '15px 0px 0px 48px',
    height: '15px',
    transition: 'background-color 0.3s ease',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  textoMarcarLido: {
    fontFamily: Rubik,
    color: theme.palette.cores.cinza[800],
    fontSize: 11,
    borderBottom: '1px solid transparent',
    transition: 'border-color 0.3s ease',
    '&:hover': {
      borderBottomColor: theme.palette.cores.cinza[600],
    },
  },
  iconeEye: {
    width: '16px',
    height: '16px',
  },
  iconBlock: {
    width: '100px',
    height: '100px',
  },
  textoNenhumaNot: {
    fontFamily: Rubik,
    color: theme.palette.cores.cinza[600],
    fontWeight: 400,
  },
}));

const NotificacoesCabecalho = ({
  setFilter,
  hasNotificacao,
  handleLimparNotificacao,
}: {
  setFilter: (value: boolean) => void;
  hasNotificacao: boolean;
  handleLimparNotificacao: () => void;
}): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeButton, setActiveButton] = useState(false);

  const handleClick = (newValue: boolean) => {
    setFilter(newValue);
    setActiveButton(newValue);
  };

  return (
    <Box>
      <Box borderBottom={`1px solid ${theme.palette.cores.cinza[400]}`}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          height="56px"
          marginTop="5px"
          paddingRight="4px"
          paddingLeft="12px"
          marginBottom="-15px"
        >
          <Typography
            color="secondary"
            style={{ fontSize: '18px', fontWeight: 500 }}
          >
            Notificações
          </Typography>
          <BotaoIcone
            descricao="Fechar"
            onClick={() => fecharNotificacoes(dispatch)}
          >
            <CloseRounded style={{ color: theme.palette.cores.cinza[600] }} />
          </BotaoIcone>
        </Box>
        <Box display="flex">
          <Button
            variant="contained"
            onClick={() => handleClick(false)}
            size="small"
            className={classes.buttonNotificacao}
            style={{
              backgroundColor: activeButton === false ? '#1976D2' : '#0000001F',
              color: activeButton === false ? 'white' : 'black',
            }}
          >
            Não lidos
          </Button>
          <Button
            variant="contained"
            onClick={() => handleClick(true)}
            size="small"
            className={classes.buttonNotificacaoRight}
            style={{
              backgroundColor: activeButton === true ? '#1976D2' : '#0000001F',
              color: activeButton === true ? 'white' : 'black',
            }}
          >
            Lidos
          </Button>
          {hasNotificacao && !activeButton && (
            <Box className={classes.containerActions}>
              <VisibilityOutlinedIcon className={classes.iconeEye} />
              <Typography
                onClick={handleLimparNotificacao}
                className={classes.textoMarcarLido}
              >
                Marcar como lido
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      {!hasNotificacao && (
        <Box
          display="flex"
          alignItems="center"
          marginTop="120px"
          flexDirection="column"
          textAlign="center"
          padding="0px 15px"
        >
          <BlockIcon color="disabled" className={classes.iconBlock} />
          <Typography className={classes.textoNenhumaNot}>
            Você não possui nenhuma notificação no momento
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default NotificacoesCabecalho;
