import React from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import theme from 'theme';
import { useStyles } from 'features/configuracoes/styles/percentualPropostaFilial';
import { DadosPermissaoColaborador } from '../api/configuracoesApiTypes';
import { AutocompleteColaboradorProps } from '../types/componentsTypes';

const AutocompleteColaborador = ({
  colaboradores,
  setColaboradorAlvo,
  setInitial,
  setAcessoAprovacaoNovosValores,
}: AutocompleteColaboradorProps): JSX.Element => {
  const styles = useStyles({});

  const setAcessosColaborador = (dados: DadosPermissaoColaborador) => {
    const preencherValores = () => ({
      ...dados,
      percentualMaximoAprovacao:
        dados?.percentualMaximoAprovacao == null
          ? 200
          : dados?.percentualMaximoAprovacao,
    });

    setColaboradorAlvo(preencherValores());
    setInitial(preencherValores());
    setAcessoAprovacaoNovosValores(
      dados?.percentualMaximoAprovacao == null ||
        dados.percentualMaximoAprovacao > 0,
    );
  };

  return (
    <Autocomplete
      options={colaboradores || []}
      getOptionLabel={(option) => `${option.matricula} - ${option.nome}`}
      className={styles.autocomplete}
      onChange={(_event, value) =>
        value ? setAcessosColaborador(value) : null
      }
      popupIcon={
        <InputAdornment position="start">
          <SearchRoundedIcon
            style={{ color: theme.palette.cores.cinza[500] }}
          />
        </InputAdornment>
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label="Matrícula/Nome do colaborador"
          variant="outlined"
        />
      )}
    />
  );
};

export default AutocompleteColaborador;
