import React, { useEffect, useState } from 'react';
import { makeStyles, TextField, Box, Button, Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Autocomplete } from '@material-ui/lab';

import { buscarFiliais, cadastrarMacroFiliais } from '../utils/configuracoes';
import { BuscarFiliaisResponse } from '../api/configuracoesApiTypes';

const useStyles = makeStyles(() => ({
  FormularioCadastroMacro: {
    fontFamily: 'Rubik',
    fontSize: '22px',
    marginTop: '3%',
  },
  input: {
    marginBottom: '20px',
  },
  customFooterContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: 6,
  },
  customButtons: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    borderTop: '0.1px solid #e4e1e1',
    boxShadow: 'none',
    padding: '20px',
  },
}));

const FormularioCadastroMacro = (): JSX.Element => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const [descricaoMacroFilial, setDescricaoMacroFilial] = useState('');
  const [filialSelect, setFilialSelect] = useState<BuscarFiliaisResponse>();
  const [filiaisDados, setFiliaisDados] = useState<BuscarFiliaisResponse[]>([]);

  useEffect(() => {
    buscarFiliais(setFiliaisDados, dispatch);
  }, [dispatch]);

  const handleSaveMacroFilial = async () => {
    try {
      await cadastrarMacroFiliais(
        {
          descricao: descricaoMacroFilial,
          filialId: filialSelect?.id,
        },
        dispatch,
      );
      setDescricaoMacroFilial('');
      setFilialSelect(undefined);
    } catch (error) {
      // Tratar o erro
    }
  };

  return (
    <div className={styles.FormularioCadastroMacro}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            className={styles.input}
            label="Descrição macro filial"
            variant="outlined"
            size="medium"
            fullWidth
            value={descricaoMacroFilial}
            onChange={(event) => setDescricaoMacroFilial(event.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            options={filiaisDados || []}
            getOptionLabel={(option) =>
              option ? `${option.id} - ${option.descricaoSlim}` : ''
            }
            onChange={(_event, value) => {
              if (value) {
                setFilialSelect(value);
              } else {
                setFilialSelect(undefined);
              }
            }}
            size="medium"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Nome da Filial"
                variant="outlined"
              />
            )}
          />
        </Grid>
      </Grid>
      <Box className={styles.customFooterContainer}>
        <Box
          className="MuiDataGrid-selectedRowCount"
          style={{ display: 'none' }}
        />
      </Box>
      <Box className={styles.customButtons}>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSaveMacroFilial}
          disabled={!descricaoMacroFilial || !filialSelect}
        >
          SALVAR
        </Button>
      </Box>
    </div>
  );
};

export default FormularioCadastroMacro;
