import { withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';

import { spacingInPixels } from '../../functions/materialUtils';

export default withStyles((theme) => ({
  root: {
    height: spacingInPixels(theme, 5),
  },
}))(TableRow);
