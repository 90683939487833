import { createReducer } from '@reduxjs/toolkit';
import { MetricasFilial } from 'features/dashboards/filiais/api/listagemFiliaisApiTypes';
import { Demanda } from 'features/dashboards/negociadores/api/listagemNegociadoresApiTypes';
import {
  limparDetalhesFilial,
  listarDetalhesFilial,
} from './detalhesFilialActions';

export type DetalhesFilialState = {
  negociacoes: Demanda[];
  metricas: MetricasFilial | undefined;
};

const estadoInicial: Readonly<DetalhesFilialState> = {
  negociacoes: [],
  metricas: undefined,
};

const detalhesFilialReducer = createReducer(estadoInicial, (builder) => {
  builder
    .addCase(listarDetalhesFilial, (_state, action) => action.payload)
    .addCase(limparDetalhesFilial, () => estadoInicial)
    .addDefaultCase((state) => state);
});

export default detalhesFilialReducer;
