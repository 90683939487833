import { Dispatch } from 'redux';
import { abrirSnackbarErro } from '../../../shared/components/snackbar/redux/snackbarAction';
import { PropostaSupervisor } from '../api/aprovacoesSupervisorApiTypes';
import * as aprovacoesSupervisorManager from '../api/aprovacoesSupervisorManager';
import { listarAprovacoesSupervisor } from './aprovacoesSupervisorAction';

const tratarErro = (error: Error) => (dispatch: Dispatch) => {
  dispatch(abrirSnackbarErro({ mensagem: error.message }));
};

const obterPropostasPendentesComValorInicialGreguim = (
  propostasPendente: PropostaSupervisor[],
  propostasRobo: PropostaSupervisor[],
): PropostaSupervisor[] =>
  [...propostasPendente?.concat(propostasRobo)]?.sort(
    (a, b) =>
      new Date(a.dataSolicitacaoAnalise).getTime() -
      new Date(b.dataSolicitacaoAnalise).getTime(),
  );

export const buscarListaAprovacoes = (
  setCarregando: (carregando: boolean) => void,
) => async (dispatch: Dispatch): Promise<void> => {
  await aprovacoesSupervisorManager
    .obterPropostasSupervisor()
    .then((resposta) => {
      const propostasRoboGreguimComFlagIndicativa = resposta.propostasRobo?.map(
        (proposta) => ({
          ...proposta,
          propostaRoboGreguim: true,
        }),
      );

      const propostasPendentes = obterPropostasPendentesComValorInicialGreguim(
        resposta.propostasPendentes,
        propostasRoboGreguimComFlagIndicativa,
      );

      const listaAprovacoesSupervisor = {
        ...resposta,
        propostasPendentes,
      };

      dispatch(listarAprovacoesSupervisor(listaAprovacoesSupervisor));
    })
    .catch((error: Error) => {
      tratarErro(error)(dispatch);
    });
  setCarregando(false);
};
