import React, { useEffect, useMemo, useState } from 'react';

import Box from '@material-ui/core/Box';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import { Button, Container, Slide, MenuItem, Badge } from '@material-ui/core';
import { useSelector } from 'react-redux';
import MoreVert from '@material-ui/icons/MoreVert';

import IconeTaskCheck from 'assets/icones/IconeTaskCheck';
import { Carga } from 'shared/types/apiTypes';
import { obterCarga } from 'features/minhasCargas/api/minhasCargasService';
import { OrigemNegociacao } from 'features/informacoesNegociacao/api/informacoesNegociacaoApiTypes';
import { RootState } from '../../../store/reducer';
import ChatCampoTextoLivre from './ChatCampoTextoLivre';
import DropdownMenu from '../../../shared/components/dropdown/dropdownMenu';
import BotaoIcone from '../../../shared/components/button/BotaoIcone';
import ChatListaClientes from './ChatListaClientes';
import { verificarCargaProd } from '../../../shared/constants/OrigemCarga';
import useStyles from '../styles/chatRodape';
import ChatRecusarProposta from './ChatRecusarProposta';
import PropostaStatus, {
  verificarPropostaFechada,
} from '../../../shared/constants/PropostaStatus';
import NegociacaoStatus from '../../../shared/constants/NegociacaoStatus';
import ModalFazerContraproposta from '../modals/ModalFazerContraproposta';
import ModalConfirmarAprovacaoSupervisor from '../modals/ModalConfirmarAprovacaoSupervisor';
import ModalPedirAprovacao from '../modals/ModalPedirAprovacao';
import {
  BoxCampoTextoLivre,
  BoxChatRodape,
  TypographyTextoMenu,
} from './style';
import { verificarDesabilitarPedirAprovacao } from '../utils/chatRodape';
import { DrawerLinhaDoTempoChat } from './drawer/DrawerLinhaDoTempoChat';
import { DrawerInformacoesAdicionais } from './drawer/DrawerInformacoesAdicionais';

const ChatRodape = (): JSX.Element => {
  const classes = useStyles();

  const [chatImagem, setChatImagem] = useState<File | undefined>(undefined);
  const [anchorMenu, setAnchorMenu] = useState<HTMLElement | null>(null);
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [carga, setCarga] = useState<Carga | null>(null);
  const [modalContrapropostaAberto, setModalContrapropostaAberto] = useState(
    false,
  );

  const { chatCarregando, chatOrigemCarga, chatPropostaStatus } = useSelector(
    (state: RootState) => state.chatNegociacaoReducer,
  );

  const { ativandoAmbienteAssistido } = useSelector(
    (state: RootState) => state.minhasNegociacoesReducer,
  );

  const {
    propostas,
    negociacao,
    idPropostaComFoco,
    propostasAplicativo,
  } = useSelector((state: RootState) => state.informacoesNegociacaoReducer);

  useEffect(() => {
    const idCarga = negociacao?.carga.id;
    obterCarga(Number(idCarga)).then((resposta) => {
      setCarga(resposta.carga);
    });
  }, [negociacao?.carga.id]);

  const handleDrawerClick = () => {
    if (!(carga?.nomeMotoristaIndicado || carga?.observacoes)) return;
    setVisibleDrawer(!visibleDrawer);
  };

  const [
    modalConfirmarAprovacaoSupervisorAberto,
    setModalConfirmarAprovacaoSupervisorAberto,
  ] = useState(false);

  const [justificativaAprovacao, setJustificativaAprovacao] = useState('');
  const [valorAprovacao, setValorAprovacao] = useState(0);

  const abrirModalConfirmarAprovacaoSupervisor = (
    valor: number,
    justificativa: string,
  ): void => {
    setValorAprovacao(valor);
    setJustificativaAprovacao(justificativa);
    setModalConfirmarAprovacaoSupervisorAberto(true);
  };

  const [modalPedirAprovacaoAberto, setModalPedirAprovacaoAberto] = useState(
    false,
  );

  const valorContrapropostaAtual = useMemo(() => {
    return propostas
      .concat(propostasAplicativo)
      .find((propostaNegociacao) => idPropostaComFoco === propostaNegociacao.id)
      ?.propostaAtual;
  }, [idPropostaComFoco, propostas, propostasAplicativo]);

  if (chatCarregando) {
    return <></>;
  }

  const handleAbrirMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    setAnchorMenu(event.currentTarget);
  };

  const handleCloseMenu = (): void => {
    setAnchorMenu(null);
  };

  const verificarBotaoContrapropostaDesabilitado = () => {
    const proposta = propostasAplicativo.find(
      (p) => p.id === idPropostaComFoco,
    );

    const negociacaoStatusInvalido =
      negociacao?.status !== NegociacaoStatus.EmAndamento;

    const propostaStatusInvalido =
      proposta?.status !== PropostaStatus.AGUARDANDO &&
      proposta?.status !== PropostaStatus.CONTRAPROPOSTA;

    return negociacaoStatusInvalido || propostaStatusInvalido;
  };

  const verificarDesabilitarChatListaClientes = () => {
    const proposta = propostasAplicativo.find(
      (p) => p.id === idPropostaComFoco,
    );
    return (
      proposta?.status === PropostaStatus.CONCLUIDA ||
      proposta?.status === PropostaStatus.RECUSA_NEGOCIADOR ||
      proposta?.status === PropostaStatus.RECUSA_MOTORISTA
    );
  };

  if (
    negociacao?.origemNegociacao === OrigemNegociacao.freteTabelado ||
    ativandoAmbienteAssistido
  ) {
    return <></>;
  }

  return (
    <>
      <BoxChatRodape>
        {chatImagem && (
          <Box className={classes.imagemChat}>
            <Slide in={chatImagem !== null} direction="up">
              <Container
                className={classes.containerImagemChat}
                style={{
                  backgroundImage: `url(${URL.createObjectURL(chatImagem)})`,
                }}
              >
                <Button
                  className={classes.botaoLimparImagem}
                  onClick={() => setChatImagem(undefined)}
                >
                  <CloseRoundedIcon className={classes.iconeExcluirImagem} />
                </Button>
              </Container>
            </Slide>
          </Box>
        )}
        <BoxCampoTextoLivre>
          <BotaoIcone
            descricao="Ações"
            classe={classes.iconeMaisAcoesChat}
            onClick={handleAbrirMenu}
          >
            <MoreVert />
          </BotaoIcone>
          <DropdownMenu anchorEl={anchorMenu} handleClose={handleCloseMenu}>
            <MenuItem
              disabled={
                !verificarCargaProd(chatOrigemCarga) ||
                verificarDesabilitarChatListaClientes()
              }
            >
              <ChatListaClientes />
            </MenuItem>
            <MenuItem
              disabled={verificarBotaoContrapropostaDesabilitado()}
              onClick={() => {
                handleCloseMenu();
                setModalContrapropostaAberto(true);
              }}
            >
              <TypographyTextoMenu
                color="secondary"
                className={classes.textoContraproposta}
              >
                Fazer contraproposta
              </TypographyTextoMenu>
            </MenuItem>
            <MenuItem
              disabled={verificarDesabilitarPedirAprovacao(
                propostasAplicativo,
                idPropostaComFoco,
                negociacao,
              )}
              onClick={() => {
                handleCloseMenu();
                setModalPedirAprovacaoAberto(true);
              }}
            >
              <TypographyTextoMenu
                color="secondary"
                className={classes.textoContraproposta}
              >
                Pedir Aprovação
              </TypographyTextoMenu>
            </MenuItem>
            <MenuItem disabled={verificarPropostaFechada(chatPropostaStatus)}>
              <ChatRecusarProposta handleClose={handleCloseMenu} />
            </MenuItem>
          </DropdownMenu>
          <div>
            <DrawerLinhaDoTempoChat />
          </div>
          <Box style={{ marginRight: '8px' }}>
            <BotaoIcone
              descricao={
                !(carga?.nomeMotoristaIndicado || carga?.observacoes)
                  ? 'Nenhuma Informação'
                  : 'Informações adicionais'
              }
              classe={classes.iconeMaisAcoesChat}
              onClick={handleDrawerClick}
            >
              {(carga?.nomeMotoristaIndicado || carga?.observacoes) && (
                <Badge variant="dot" color="error" className={classes.badge} />
              )}
              <IconeTaskCheck
                fill={
                  !(carga?.nomeMotoristaIndicado || carga?.observacoes)
                    ? '#82838a86'
                    : '#82838A'
                }
              />
            </BotaoIcone>
          </Box>
          <ChatCampoTextoLivre
            chatImagem={chatImagem}
            setChatImagem={setChatImagem}
          />
        </BoxCampoTextoLivre>
      </BoxChatRodape>
      {modalContrapropostaAberto && (
        <ModalFazerContraproposta
          modalContrapropostaAberto={modalContrapropostaAberto}
          setModalContrapropostaAberto={setModalContrapropostaAberto}
        />
      )}
      <ModalPedirAprovacao
        modalPedirAprovacaoAberto={modalPedirAprovacaoAberto}
        setModalPedirAprovacaoAberto={setModalPedirAprovacaoAberto}
        valorContraproposta={valorContrapropostaAtual}
        abriModalConfirmarAprovacaoSupervisor={
          abrirModalConfirmarAprovacaoSupervisor
        }
      />
      <ModalConfirmarAprovacaoSupervisor
        modalConfirmarAprovacaoSupervisorAberto={
          modalConfirmarAprovacaoSupervisorAberto
        }
        setModalConfirmarAprovacaoSupervisorAberto={
          setModalConfirmarAprovacaoSupervisorAberto
        }
        justificativa={justificativaAprovacao}
        valorParaAprovacao={valorAprovacao}
      />
      <DrawerInformacoesAdicionais
        negociacoes={negociacao}
        openDrawer={visibleDrawer}
        onCloseOpenDrawer={handleDrawerClick}
      />
    </>
  );
};

export default ChatRodape;
