import { Box, makeStyles, MenuItem, Typography } from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import React, { useState } from 'react';
import BotaoIcone from '../../../shared/components/button/BotaoIcone';
import DropdownMenu from '../../../shared/components/dropdown/dropdownMenu';
import spacingInPixels from '../../../shared/functions/materialUtils';
import { Indicador } from '../api/detalhesAprovacaoSupervisorTypes';

type MenuDiasCalculoProps = {
  indicadores: Indicador[];
  onClick: (indicador: Indicador) => void;
};

const useStyles = makeStyles((theme) => ({
  containerMenu: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: spacingInPixels(theme, 0.5),
  },
  textoMenu: {
    fontSize: '14px',
    fontWeight: 400,
    color: theme.palette.cores.cinza[900],
  },
}));

const MenuDiasCalculo = ({
  indicadores,
  onClick,
}: MenuDiasCalculoProps): JSX.Element => {
  const [anchorMenu, setAnchorMenu] = useState<HTMLElement | null>(null);
  const classes = useStyles();

  const handleAbrirMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    setAnchorMenu(event.currentTarget);
  };

  const handleFecharMenu = (): void => {
    setAnchorMenu(null);
  };

  return (
    <>
      <BotaoIcone descricao="Cálculo de Dias" onClick={handleAbrirMenu}>
        <MoreHoriz />
      </BotaoIcone>
      <DropdownMenu anchorEl={anchorMenu} handleClose={handleFecharMenu}>
        {indicadores.map((indicador) => (
          <MenuItem
            onClick={() => {
              handleFecharMenu();
              onClick(indicador);
            }}
          >
            <Box className={classes.containerMenu}>
              <Typography className={classes.textoMenu}>
                {indicador.diasCalculo} dias
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </DropdownMenu>
    </>
  );
};

export default MenuDiasCalculo;
