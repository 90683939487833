import React from 'react';
import {
  Chip,
  createStyles,
  makeStyles,
  Theme,
  useTheme,
  withStyles,
} from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';
import { formatarIntervaloTempo } from 'shared/functions/IntervaloTempo';
import { IntervaloTempo } from 'shared/types/apiTypes';
import IconeRelogio from 'assets/icones/IconeRelogio';
import { definirLabelAtrasoValorTempoAlerta } from '../utils/demandaDiariaAlertas';
import { CustomWarningTooltip } from '../views/QuadroBigNumbersAlerta';

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    display: 'flex',
    alignItems: 'center',
    columnGap: spacingInPixels(theme, 0.5),
  },
  texto: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '90%',
  },
}));

const ChipDemandaAtrasada = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.cores.branco,
      backgroundColor: theme.palette.cores.vermelho[600],
      height: spacingInPixels(theme, 3),
      width: 'fit-content',
      borderRadius: spacingInPixels(theme, 0.5),
      fontFamily: 'Rubik',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: spacingInPixels(theme, 1.5),
      lineHeight: spacingInPixels(theme, 1.5),
    },
    label: {
      padding: spacingInPixels(theme, 0.75),
    },
  }),
)(Chip);

interface LabelChipDemandaAtrasadaProps {
  label: string;
}

const LabelChipDemandaAtrasada = ({
  label,
}: LabelChipDemandaAtrasadaProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.label}>
      <span>Atrasada</span>
      <IconeRelogio
        transform="scale(-1,1)"
        fill={theme.palette.cores.branco}
        height={12}
        width={12}
      />
      <span>{label}</span>
    </div>
  );
};

export interface ColunaIntervaloTempoProps {
  tempoAlerta?: IntervaloTempo;
}

const ColunaIntervaloTempo = ({
  tempoAlerta,
}: ColunaIntervaloTempoProps): JSX.Element => {
  const classes = useStyles();

  const demandaAtrasada =
    tempoAlerta && Object.values(tempoAlerta).some((valor) => valor < 0);

  if (!tempoAlerta) return <span> -- </span>;

  const dataFormatada = formatarIntervaloTempo(tempoAlerta, true);

  return (
    <div>
      {demandaAtrasada ? (
        <ChipDemandaAtrasada
          label={
            <LabelChipDemandaAtrasada
              label={definirLabelAtrasoValorTempoAlerta(tempoAlerta)}
            />
          }
        />
      ) : (
        <CustomWarningTooltip title={dataFormatada} isTopPlacement>
          <div className={classes.texto}>
            <span>{dataFormatada}</span>
          </div>
        </CustomWarningTooltip>
      )}
    </div>
  );
};

export default ColunaIntervaloTempo;
