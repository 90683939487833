import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import InformacaoItem from 'shared/components/item/InformacaoItem';
import Titulo from 'shared/components/titulo/Titulo';
import {
  formatarDataBrasileira,
  obterDataAtual,
} from 'shared/functions/dataUtils';
import spacingInPixels from 'shared/functions/materialUtils';

const useStyles = makeStyles((theme) => ({
  cabecalho: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardData: {
    borderRadius: spacingInPixels(theme, 0.7),
    padding: spacingInPixels(theme, 0.6),
    border: `1px solid ${theme.palette.cores.cinza['400']}80`,
    height: 'fit-content',
  },
  iconeCalendario: {
    color: theme.palette.cores.azul[600],
    width: 20,
    height: 20,
  },
}));
const HeaderTotalDemandas = (): JSX.Element => {
  const classes = useStyles();

  return (
    <header className={classes.cabecalho}>
      <Titulo titulo="Demandas do Dia (Hoje)" />
      <Box className={classes.cardData}>
        <InformacaoItem
          icone={
            <CalendarTodayOutlinedIcon className={classes.iconeCalendario} />
          }
          informacao={formatarDataBrasileira(obterDataAtual())}
          altIcone="Data Atual"
          titleIcone="Data Atual"
        />
      </Box>
    </header>
  );
};

export default HeaderTotalDemandas;
