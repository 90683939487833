import { createReducer } from '@reduxjs/toolkit';

import {
  atualizarArquivo,
  limparDadosUploadSimulador,
  atualizarEstadoArquivoEnviado,
  atualizarCarregando,
} from './uploadSimuladorAction';

export type UploadSimuladorState = {
  arquivoEstaSelecionado: boolean;
  arquivo: File | null;
  arquivoFoiEnviado: boolean;
  carregando: boolean;
};

export const estadoInicial: Readonly<UploadSimuladorState> = {
  arquivoEstaSelecionado: false,
  arquivo: null,
  arquivoFoiEnviado: false,
  carregando: false,
};

const uploadSimuladorReducer = createReducer(estadoInicial, (builder) => {
  builder
    .addCase(atualizarArquivo, (state, action) => ({
      ...state,
      arquivoEstaSelecionado: Boolean(action.payload),
      arquivo: action.payload,
    }))
    .addCase(atualizarEstadoArquivoEnviado, (state, action) => ({
      ...state,
      arquivoFoiEnviado: action.payload,
    }))
    .addCase(atualizarCarregando, (state, action) => ({
      ...state,
      carregando: action.payload,
    }))
    .addCase(limparDadosUploadSimulador, () => estadoInicial)
    .addDefaultCase((state) => state);
});

export default uploadSimuladorReducer;
