import { makeStyles } from '@material-ui/core';
import { rgba } from 'polished';
import theme from 'theme';

export const useStyles = makeStyles(() => ({
  textoSimples: {
    fontFamily: 'Roboto',
    fontSize: '16px',
  },
  boxSwitch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .Mui-checked': {
      '&  .MuiSwitch-thumb': {
        backgroundColor: theme.palette.cores.azul[600],
      },
      '&  .MuiSwitch-track': {
        backgroundColor: rgba(25, 118, 210, 0.2),
      },
    },
  },
}));
