import { GridSize } from '@material-ui/core';
import StatusDemandaEnum from 'shared/constants/StatusDemandaEnum';

type ItemCabecalho = {
  label: string;
  tamanhoCelula: GridSize;
  paddingLeft?: string;
};

type StatusDemandaType = {
  id: number;
  mensagemTooltip: string;
  icone: React.ReactElement;
  valor: number;
  tipo: StatusDemandaEnum | null;
};

type StatusDemandaFilialType = {
  id: number;
  mensagemTooltip: string;
  icone: React.ReactElement;
  valor: number;
  cor: string;
};

type PrioridadeCarga = {
  maxima: number;
  media: number;
  baixa: number;
};

type Demandas = {
  total: number;
  concluidas: number;
  emNegociacao: number;
  naoIniciadas: number;
  emAprovacao: number;
  recusadas: number;
};

enum PrioridadeCargaEnum {
  maxima,
  media,
  baixa,
}

export { PrioridadeCargaEnum };
export type {
  Demandas,
  ItemCabecalho,
  PrioridadeCarga,
  StatusDemandaType,
  StatusDemandaFilialType,
};
