import { DropdownCheckboxOption } from '../components/dropdown/dropdownCheckbox/views/DropdownCheckbox';

export const removerPalavrasRepetidasReducer = (
  unique: string[],
  item: string,
): string[] => (unique.includes(item) ? unique : [...unique, item]);

export const removerNumerosRepetidosReducer = (
  unique: number[],
  item: number,
): number[] => (unique.includes(item) ? unique : [...unique, item]);

export const removerDropdownItensRepetidosReducer = (
  unique: DropdownCheckboxOption[],
  item: DropdownCheckboxOption,
): DropdownCheckboxOption[] =>
  unique.map((u) => u.name).includes(item.name) ? unique : [...unique, item];
