import { buscarNegociacoesFilial } from 'features/dashboards/filiais/api/listagemFiliaisManager';
import { ordenarDemandasPorPrioridadeCarga } from 'features/dashboards/utils/modalDetalhes';
import { adapterNegociacaoParaDemanda } from 'features/dashboards/utils/modalDetalhesFilial';
import { Dispatch } from 'redux';
import {
  listarDetalhesFilial,
  limparDetalhesFilial,
} from './detalhesFilialActions';

export const buscarDadosNegociacoesFilial = (
  setCarregando: (valor: boolean) => void,
  idFilial?: number,
  nomeFilial?: string,
) => async (dispatch: Dispatch): Promise<void> => {
  try {
    setCarregando(true);
    if (!idFilial || !nomeFilial) throw new Error();
    const { filial, negociacoes } = await buscarNegociacoesFilial(idFilial);
    dispatch(
      listarDetalhesFilial({
        negociacoes: ordenarDemandasPorPrioridadeCarga(
          adapterNegociacaoParaDemanda(nomeFilial, negociacoes),
        ),
        metricas: filial,
      }),
    );
  } catch {
    dispatch(
      listarDetalhesFilial({
        negociacoes: [],
        metricas: undefined,
      }),
    );
  } finally {
    setCarregando(false);
  }
};

export const limparDadosNegociacoesFilial = (dispatch: Dispatch): void => {
  dispatch(
    limparDetalhesFilial({
      negociacoes: [],
      metricas: undefined,
    }),
  );
};
