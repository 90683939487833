import React, { ReactNode } from 'react';

import Tabs from '@material-ui/core/Tabs';
import { withStyles } from '@material-ui/core/styles';
import AbasMotoristas from '../../../features/informacoesNegociacao/views/agregadosCard/constants/AbasMotoristas';

const TabsItem = withStyles(() => ({
  root: {
    borderRadius: 24,
    maxHeight: 'unset',
    minHeight: 'unset',
    height: 40,

    '& .MuiTabs-flexContainer': {
      gap: '8px',
    },
  },
}))(Tabs);

type TabsProps = {
  aba: string;
  mudarAba: (
    event: React.ChangeEvent<unknown>,
    novaAba: AbasMotoristas,
  ) => void;
  children: ReactNode;
  styles?: string;
};

const TabsList = ({
  aba,
  mudarAba,
  children,
  styles,
}: TabsProps): JSX.Element => (
  <TabsItem
    value={aba}
    indicatorColor="primary"
    textColor="primary"
    onChange={mudarAba}
    TabIndicatorProps={{
      style: {
        display: 'none',
      },
    }}
    className={styles}
  >
    {children}
  </TabsItem>
);

TabsList.defaultProps = {
  styles: '',
};

export default TabsList;
