import { useTheme } from '@material-ui/core';
import * as React from 'react';
import { SVGProps } from 'react';

const IconeAlerta = ({
  fill,
  ...outros
}: SVGProps<SVGSVGElement>): JSX.Element => {
  const theme = useTheme();
  return (
    <svg width={10} height={12} viewBox="0 0 10 12" fill="none" {...outros}>
      <path
        d="M9.45 1.129a.583.583 0 0 0-.49-.117 4.667 4.667 0 0 1-3.628-.74.583.583 0 0 0-.665 0 4.667 4.667 0 0 1-3.628.74.583.583 0 0 0-.706.572V5.93a5.25 5.25 0 0 0 2.2 4.276l2.129 1.516a.584.584 0 0 0 .676 0l2.13-1.516A5.25 5.25 0 0 0 9.666 5.93V1.584a.583.583 0 0 0-.216-.455Zm-.95 4.8a4.083 4.083 0 0 1-1.71 3.326L5 10.532 3.21 9.255A4.083 4.083 0 0 1 1.5 5.93V2.255A5.833 5.833 0 0 0 5 1.444a5.833 5.833 0 0 0 3.5.81V5.93ZM5.898 4.595 4.33 6.169l-.519-.525a.586.586 0 0 0-.828.828l.933.934a.583.583 0 0 0 .828 0l2.007-1.99a.586.586 0 1 0-.828-.828l-.024.006Z"
        fill={fill || theme.palette.cores.laranjaForte[700]}
      />
    </svg>
  );
};

export default IconeAlerta;
