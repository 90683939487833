import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import { RootState } from '../../../store/reducer';
import { useStyles } from '../styles/styleTextoCaixaUpload';
import { TextoCaixaUploadProps } from '../types/TextoCaixaUpload';

const TextoCaixaUpload = ({ aba }: TextoCaixaUploadProps): JSX.Element => {
  const classes = useStyles();

  const { arquivo, arquivoEstaSelecionado, arquivoFoiEnviado } = useSelector(
    (state: RootState) => state.uploadSimuladorReducer,
  );

  const getTexto = (): string | undefined => {
    if (arquivoFoiEnviado) {
      return 'Seu arquivo foi processado com sucesso!';
    }
    return arquivo?.name;
  };

  const preTexto = () => {
    if (aba === 'UploadDeCarga') return 'Escolha seu arquivo';

    return 'Escolha seu arquivo ou arraste e solte aqui dentro';
  };

  return arquivoEstaSelecionado ? (
    <Box>
      <Typography variant="h6">{getTexto()}</Typography>
    </Box>
  ) : (
    <Typography variant="h6" className={classes.textoCinza}>
      {preTexto()}
    </Typography>
  );
};

export default TextoCaixaUpload;
