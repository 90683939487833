import React from 'react';
import { Grid, makeStyles, Typography, useTheme } from '@material-ui/core';
import { Rubik } from 'shared/constants/font';
import IconeGrafico from 'assets/icones/IconeGrafico';

const useStyles = makeStyles((theme) => ({
  textoSugerido: {
    fontFamily: Rubik,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '13px',
    color: theme.palette.cores.cinza[600],
  },
}));

const CardPropostaGreguimSugerido = (): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Grid container alignItems="center" justify="flex-end" spacing={1}>
      <Grid style={{ display: 'flex', alignItems: 'center' }} item>
        <IconeGrafico
          width={16}
          height={16}
          fill={theme.palette.cores.verde[700]}
        />

        <Typography className={classes.textoSugerido}>
          Sugerido pelo <strong>Algoritmo</strong>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CardPropostaGreguimSugerido;
