import * as React from 'react';
import { SVGProps } from 'react';
import theme from 'theme';

export interface IconePredioCirculoProps extends SVGProps<SVGSVGElement> {
  fillCirculo?: string;
}

const IconePredioCirculo = ({
  fill,
  fillCirculo,
  ...outros
}: IconePredioCirculoProps): JSX.Element => (
  <svg width={34} height={34} viewBox="0 0 34 34" fill="none" {...outros}>
    <circle cx="17" cy="17" r="17" fill={fillCirculo || '#F5F5F5'} />
    <path
      d="M19 13H20C20.2652 13 20.5196 12.8946 20.7071 12.7071C20.8946 12.5196 21 12.2652 21 12C21 11.7348 20.8946 11.4804 20.7071 11.2929C20.5196 11.1054 20.2652 11 20 11H19C18.7348 11 18.4804 11.1054 18.2929 11.2929C18.1054 11.4804 18 11.7348 18 12C18 12.2652 18.1054 12.5196 18.2929 12.7071C18.4804 12.8946 18.7348 13 19 13V13ZM19 17H20C20.2652 17 20.5196 16.8946 20.7071 16.7071C20.8946 16.5196 21 16.2652 21 16C21 15.7348 20.8946 15.4804 20.7071 15.2929C20.5196 15.1054 20.2652 15 20 15H19C18.7348 15 18.4804 15.1054 18.2929 15.2929C18.1054 15.4804 18 15.7348 18 16C18 16.2652 18.1054 16.5196 18.2929 16.7071C18.4804 16.8946 18.7348 17 19 17V17ZM14 13H15C15.2652 13 15.5196 12.8946 15.7071 12.7071C15.8946 12.5196 16 12.2652 16 12C16 11.7348 15.8946 11.4804 15.7071 11.2929C15.5196 11.1054 15.2652 11 15 11H14C13.7348 11 13.4804 11.1054 13.2929 11.2929C13.1054 11.4804 13 11.7348 13 12C13 12.2652 13.1054 12.5196 13.2929 12.7071C13.4804 12.8946 13.7348 13 14 13V13ZM14 17H15C15.2652 17 15.5196 16.8946 15.7071 16.7071C15.8946 16.5196 16 16.2652 16 16C16 15.7348 15.8946 15.4804 15.7071 15.2929C15.5196 15.1054 15.2652 15 15 15H14C13.7348 15 13.4804 15.1054 13.2929 15.2929C13.1054 15.4804 13 15.7348 13 16C13 16.2652 13.1054 16.5196 13.2929 16.7071C13.4804 16.8946 13.7348 17 14 17V17ZM26 25H25V8C25 7.73478 24.8946 7.48043 24.7071 7.29289C24.5196 7.10536 24.2652 7 24 7H10C9.73478 7 9.48043 7.10536 9.29289 7.29289C9.10536 7.48043 9 7.73478 9 8V25H8C7.73478 25 7.48043 25.1054 7.29289 25.2929C7.10536 25.4804 7 25.7348 7 26C7 26.2652 7.10536 26.5196 7.29289 26.7071C7.48043 26.8946 7.73478 27 8 27H26C26.2652 27 26.5196 26.8946 26.7071 26.7071C26.8946 26.5196 27 26.2652 27 26C27 25.7348 26.8946 25.4804 26.7071 25.2929C26.5196 25.1054 26.2652 25 26 25ZM18 25H16V21H18V25ZM23 25H20V20C20 19.7348 19.8946 19.4804 19.7071 19.2929C19.5196 19.1054 19.2652 19 19 19H15C14.7348 19 14.4804 19.1054 14.2929 19.2929C14.1054 19.4804 14 19.7348 14 20V25H11V9H23V25Z"
      fill={fill || theme.palette.cores.cinza[700]}
    />
  </svg>
);

export default IconePredioCirculo;
