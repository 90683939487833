import React from 'react';
import { Box } from '@material-ui/core';
import { AttachMoney, CancelPresentation } from '@material-ui/icons';
import ModalComum from '../../../shared/components/modalComum/ModalComum';
import InputRubik from '../../../shared/components/input/InputRubik';
import BotaoIconeTexto from '../../detalhesAprovacaoSupervisor/components/Botoes/BotaoIconeTexto';
import BotaoOutlineIconeTexto from '../../detalhesAprovacaoSupervisor/components/Botoes/BotaoOutlineIconeTexto';
import theme from '../../../theme';

interface Props {
  modalOpen: boolean;
  onCloseModal: () => void;
  onConfirmModal: () => void;
  justificativa: string;
  setJustificativa: (valor: string) => void;
}

export function ModalReprovacao({
  modalOpen,
  onCloseModal,
  onConfirmModal,
  justificativa,
  setJustificativa,
}: Props): JSX.Element {
  return (
    <ModalComum
      modalAberto={modalOpen}
      fecharModal={onCloseModal}
      titulo="Reprovar pedido"
      conteudo={
        <Box display="flex" flex={1}>
          <InputRubik
            fullWidth
            multiline
            label="Justifique a reprovação"
            size="small"
            variant="outlined"
            value={justificativa}
            onChange={(event) => setJustificativa(event.target.value)}
            rows={4}
          />
        </Box>
      }
      acoes={
        <Box
          display="flex"
          flex={1}
          flexDirection="row"
          justifyContent="space-between"
          gridRowGap={16}
          marginLeft={1.5}
          marginRight={1.5}
          marginBottom="32px"
        >
          <BotaoOutlineIconeTexto
            icon={<AttachMoney />}
            color={theme.palette.cores.azulMartins[500]}
            onClick={onCloseModal}
          >
            Cancelar
          </BotaoOutlineIconeTexto>
          <BotaoIconeTexto
            icon={<CancelPresentation />}
            color={theme.palette.cores.branco}
            backgroundColor={theme.palette.cores.vermelho[400]}
            onClick={onConfirmModal}
            desabilitado={justificativa.trim().length === 0}
          >
            Reprovar
          </BotaoIconeTexto>
        </Box>
      }
    />
  );
}
