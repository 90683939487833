import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Drawer,
  IconButton,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { buscarPermissaoColaboradores } from 'features/configuracoes/utils/configuracoes';
import { useDispatch } from 'react-redux';
import { DadosPermissaoColaborador } from 'features/configuracoes/api/configuracoesApiTypes';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import {
  abrirSnackbarErro,
  abrirSnackbarSucesso,
} from 'shared/components/snackbar/redux/snackbarAction';
import CloseIcon from '@material-ui/icons/Close';
import { transferirNegociacoes } from '../api/negociacoesAutomatizadasService';

export type TransferirCargasDrawerProps = {
  openDrawer: boolean;
  negociacoesIdTransferencia: { [key: number]: boolean };
  handleCloseDrawer: () => void;
  handleLimparCampos: () => void;
};

const useStyles = makeStyles(() => ({
  containerAutocomplete: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '144px',
  },
  customPopupButton: {
    animation: 'none !important',
    transform: 'none !important',
    transition: 'none !important',
    '&.MuiAutocomplete-popupIndicator': {
      transform: 'none',
    },
  },
  text: {
    fontSize: '24px',
    paddingRight: '33px',
    paddingLeft: '54px',
  },
  boxTransferirCargas: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  autoComplete: {
    width: '460px',
  },
  buttonTransferencia: {
    width: '213px',
    height: '40px',
    borderRadius: '100px',
    fontSize: '16px',
    fontWeight: 400,
    textTransform: 'none',
  },
  buttonCancelar: {
    width: '170px',
    height: '40px',
    borderRadius: '100px',
    fontSize: '16px',
    fontWeight: 400,
    textTransform: 'none',
  },
  drawerDocked: {
    boxShadow: ' 0px -6px 40px 0px #00000040',
  },
  iconX: {
    marginRight: '27px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const TransferirCargasDrawer = ({
  openDrawer,
  negociacoesIdTransferencia,
  handleCloseDrawer,
  handleLimparCampos,
}: TransferirCargasDrawerProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [colaboradores, setColaboradores] = useState<
    DadosPermissaoColaborador[]
  >([]);
  const [valorIdColaborador, setValorIdColaborador] = useState<
    number | undefined
  >();
  const [resetKey, setResetKey] = useState(0);

  const handleAutocompleteChange = (
    event: React.ChangeEvent<unknown>,
    newValue: DadosPermissaoColaborador | null,
  ) => {
    if (newValue) {
      setValorIdColaborador(newValue.idColaborador);
    }
  };

  const dados: number[] = [];

  Object.keys(negociacoesIdTransferencia).forEach((index): void => {
    if (negociacoesIdTransferencia[Number(index)] === true) {
      dados.push(Number(index));
    }
  });

  const handleTranferenciaColaborador = async () => {
    try {
      dados.forEach(async (dadoId) => {
        await transferirNegociacoes(dadoId, valorIdColaborador);
      });

      dispatch(
        abrirSnackbarSucesso({
          mensagem: 'Negociação transferida com sucesso',
        }),
      );

      handleCloseDrawer();
    } catch (e) {
      dispatch(
        abrirSnackbarErro({
          mensagem: `${e}`,
        }),
      );
    }
  };

  const limparFecharDrawer = () => {
    handleLimparCampos();
    setValorIdColaborador(undefined);
    setResetKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    buscarPermissaoColaboradores(setColaboradores, dispatch);
  }, [dispatch]);

  return (
    <Drawer
      anchor="bottom"
      variant="persistent"
      open={openDrawer}
      classes={{
        paperAnchorDockedBottom: classes.drawerDocked,
      }}
      BackdropProps={{ invisible: true }}
      ModalProps={{
        keepMounted: true,
        disableBackdropClick: true,
      }}
    >
      <Box className={classes.containerAutocomplete}>
        <Box className={classes.boxTransferirCargas}>
          <Typography className={classes.text}>Transferir cargas:</Typography>
          <Autocomplete
            key={resetKey}
            onChange={handleAutocompleteChange}
            closeIcon={false}
            popupIcon={false}
            className={classes.autoComplete}
            options={colaboradores.filter(
              (item: DadosPermissaoColaborador) =>
                item.acessoNegociacaoCargas === true && item.ativo === true,
            )}
            getOptionLabel={(colaborador) => colaborador.nome}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Selecione o negociador"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  style: { paddingRight: 0, top: '-4px' },
                  endAdornment: (
                    <IconButton>
                      <SearchRoundedIcon />
                    </IconButton>
                  ),
                }}
              />
            )}
          />
        </Box>
        <Box display="flex" gridGap="15px" marginRight="100px">
          <Button
            className={classes.buttonCancelar}
            variant="contained"
            color="default"
            onClick={limparFecharDrawer}
          >
            Cancelar
          </Button>
          <Button
            className={classes.buttonTransferencia}
            variant="contained"
            color="primary"
            onClick={handleTranferenciaColaborador}
          >
            Transferir negociação
          </Button>
        </Box>
        <Box className={classes.iconX}>
          <CloseIcon onClick={limparFecharDrawer} color="disabled" />
        </Box>
      </Box>
    </Drawer>
  );
};

export default TransferirCargasDrawer;
