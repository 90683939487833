import React from 'react';
import { titulosPagina } from 'features/detalhesDemandasDiaria/constants/TitulosPagina';
import TituloPagina from 'features/detalhesDemandasDiaria/components/TituloPagina';
import { ContainerDemandasDoDiaProps } from 'features/detalhesDemandasDiaria/types/containerTypes';
import TabelaDemandasDoDia from './TabelaDemandasDoDia';
import { useStyles } from '../../styles/styleContainerDetalhes';

const ContainerDemandasDoDia = ({
  demandas,
  carregando,
  erro,
}: ContainerDemandasDoDiaProps): JSX.Element => {
  const classes = useStyles();

  return (
    <section className={classes.container}>
      <TituloPagina
        tituloPaginaConfig={titulosPagina.demandasDoDia}
        quantidadeItens={demandas.length}
        carregando={carregando}
      />
      <TabelaDemandasDoDia
        carregando={carregando}
        demandas={demandas}
        erro={erro}
      />
    </section>
  );
};

export default ContainerDemandasDoDia;
