const PROBLEMAS_ATUALIZACAO_TOKEN =
  'Houve um problema ao atualizar o token de autenticação e o refresh token.';

export const SISTEMA_INDISPONIVEL =
  'Sistema indisponível no momento. Tente novamente mais tarde.';

export default {
  PROBLEMAS_ATUALIZACAO_TOKEN,
  SISTEMA_INDISPONIVEL,
};
