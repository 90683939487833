import { CSSProperties } from 'react';
import { Dispatch } from 'redux';
import PropostaStatus from '../../../shared/constants/PropostaStatus';
import TipoMensagemProposta, {
  verificarMensagemAceite,
} from '../../../shared/constants/TipoMensagemProposta';
import theme from '../../../theme';
import { ExibicaoRespostaNegociador } from '../redux/chatNegociacaoReducer';
import { verificarExibicaoBotoesResposta } from './aguardandoNegociador';
import { enviarMensagem } from './chatResposta';

interface InterfaceBotaoEnviarTextoLivre {
  chatIdNegociacao: number | undefined;
  chatIdProposta: number | undefined;
  valor: number | undefined;
  motivo: string | undefined;
  propostaStatus: PropostaStatus;
  tipoMensagem: TipoMensagemProposta;
  setEnviando: (erro: boolean) => void;
  dispatch: Dispatch;
  arquivo?: File;
}

export const onClickBotaoEnviarTextoLivre = async ({
  chatIdNegociacao,
  chatIdProposta,
  valor,
  motivo,
  propostaStatus,
  tipoMensagem,
  setEnviando,
  dispatch,
  arquivo,
}: InterfaceBotaoEnviarTextoLivre): Promise<void> => {
  await enviarMensagem({
    chatIdNegociacao,
    chatIdProposta,
    valor,
    motivo,
    motivoRecusa: undefined,
    tipoMensagem,
    propostaStatus,
    setEnviando,
    dispatch,
    arquivo,
  });
};

export const verificarConteudoVazio = (
  valorTexto: string,
  arquivo?: File,
): boolean => valorTexto.trim() === '' && arquivo === undefined;

export const verificarTextoVazio = (valorTexto: string): boolean =>
  valorTexto.trim() === '';

export const verificarArquivoVazio = (arquivo?: File): boolean =>
  arquivo === undefined;

export const obterCorIcone = (
  valorTexto: string,
  arquivo?: File,
): CSSProperties['color'] =>
  verificarConteudoVazio(valorTexto, arquivo)
    ? theme.palette.cores.cinza[500]
    : theme.palette.cores.azulMartins[500];

export const enviarMensagemDesabilitado = (
  exibicaoRespostaNegociador: ExibicaoRespostaNegociador | undefined,
  tipoMensagem: TipoMensagemProposta | undefined,
): boolean =>
  !verificarExibicaoBotoesResposta(exibicaoRespostaNegociador) ||
  verificarMensagemAceite(tipoMensagem);
