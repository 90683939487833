import React from 'react';
import { useSelector } from 'react-redux';
import { verificarPropostaRecusada } from 'shared/constants/PropostaStatus';
import TypographyChat from '../TypographyChat';
import { RootState } from '../../../../store/reducer';
import useStyles from '../../styles/chatResposta';
import { obterMotivoRecusa } from '../../../../shared/constants/MotivoRecusa';
import { verificarRemetenteSupervisor } from '../../../../shared/constants/RemetenteMensagemProposta';

const RespostaRecusa = (): JSX.Element => {
  const classes = useStyles();

  const {
    chatPropostaStatus,
    chatMotivoRecusa,
    mensagemReferencia,
  } = useSelector((state: RootState) => state.chatNegociacaoReducer);

  if (!verificarPropostaRecusada(chatPropostaStatus)) {
    return <></>;
  }

  return (
    <>
      <TypographyChat className={classes.respostaRecusa}>
        A negociação foi recusada pelo motivo:
      </TypographyChat>
      <TypographyChat className={classes.motivoRecusa}>
        {verificarRemetenteSupervisor(mensagemReferencia?.remetente)
          ? mensagemReferencia?.texto
          : obterMotivoRecusa(chatMotivoRecusa)}
      </TypographyChat>
      <TypographyChat className={classes.respostaRecusa}>
        (Informação não disponibilizada ao motorista)
      </TypographyChat>
    </>
  );
};

export default RespostaRecusa;
