export type MotivoRecusaType = {
  id: number;
  descricao: string;
  selecionavel: boolean;
};

export const MotivoRecusa: Readonly<MotivoRecusaType>[] = [
  { id: 0, descricao: 'Agregado indisponível no dia', selecionavel: true },
  { id: 1, descricao: 'Carga pega por engano', selecionavel: true },
  { id: 2, descricao: 'Caminhão vendido', selecionavel: true },
  { id: 3, descricao: 'Carga não carregada', selecionavel: true },
  { id: 4, descricao: 'Carregamento atrasado', selecionavel: true },
  { id: 5, descricao: 'Cliente especial', selecionavel: true },
  { id: 6, descricao: 'Desacordo com o tarifário', selecionavel: true },
  { id: 7, descricao: 'Entregas não finalizadas', selecionavel: true },
  { id: 8, descricao: 'Pagamento em atraso', selecionavel: true },
  { id: 9, descricao: 'Parceiro de outra empresa', selecionavel: true },
  { id: 10, descricao: 'Problema mecânico no veículo', selecionavel: true },
  { id: 11, descricao: 'Região não atendida', selecionavel: true },
  { id: 12, descricao: 'Sem resposta', selecionavel: true },
  { id: 13, descricao: 'Problemas mecânicos', selecionavel: true },
  { id: 14, descricao: 'Peso da carga excede capacidade', selecionavel: true },
  { id: 15, descricao: 'Necessário quebrar carga', selecionavel: true },
  { id: 16, descricao: 'Necessário juntar carga', selecionavel: true },
  { id: 17, descricao: 'Carga acima do limite de dias', selecionavel: false },
  { id: 18, descricao: 'Viagem encerrada por fora', selecionavel: false },
  {
    id: 19,
    descricao: 'Negociação fechada com outro motorista',
    selecionavel: false,
  },
];

const MotivoRecusaAutomatizado: Readonly<MotivoRecusaType>[] = [
  { id: 0, descricao: 'Agregado indisponível no dia', selecionavel: true },
  { id: 1, descricao: 'Problema mecânico no veículo', selecionavel: true },
  { id: 2, descricao: 'Peso da carga excede a capacidade', selecionavel: true },
  { id: 3, descricao: 'Região não atendida', selecionavel: true },
  { id: 4, descricao: 'Outros', selecionavel: true },
];

export const obterMotivoRecusa = (motivoRecusa: number | undefined): string =>
  MotivoRecusa.find((element) => element.id === motivoRecusa)?.descricao ||
  'Sem motivo declarado.';

export const obterMotivosRecusaSelecionaveis = (): typeof MotivoRecusa =>
  MotivoRecusa.filter((value) => value.selecionavel);

export const obterMotivosRecusaSelecionaveisAutomatizado = (): typeof MotivoRecusaAutomatizado =>
  MotivoRecusaAutomatizado.filter((value) => value.selecionavel);

export default MotivoRecusa;
