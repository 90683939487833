import { PeriodoFiltroIndicadores } from '../constants/Constants';
import {
  IndicadoresAlertaResponse,
  IndicadorNegociacao,
  NegociacaoAtendimento,
} from './indicadoresApiTypes';
import * as indicadoresService from './indicadoresService';

export const obterIndicadoresPorPeriodo = async (
  periodo: PeriodoFiltroIndicadores,
): Promise<IndicadorNegociacao[]> =>
  indicadoresService.obterIndicadoresPorPeriodo(periodo);

export const obterAtendimentosPorPeriodo = async (
  periodo: PeriodoFiltroIndicadores,
): Promise<NegociacaoAtendimento[]> =>
  indicadoresService.obterAtendimentosPorPeriodo(periodo);

export const gerarPlanilhaIndicadoresPorPeriodo = async (
  periodo: PeriodoFiltroIndicadores,
): Promise<void> =>
  indicadoresService.gerarPlanilhaIndicadoresPorPeriodo(periodo);

export const obterAlertasPorPeriodo = async (
  periodo: PeriodoFiltroIndicadores,
): Promise<IndicadoresAlertaResponse> =>
  indicadoresService.obterAlertasPorPeriodo(periodo);
