import { ConexaoAPI } from 'shared/classes/conexaoAPI';
import { DemandaDiaria, DemandasAlertaResponse } from './demandaDiariaApiTypes';

const obterUrlBuscarDemandaDiaria = (): string =>
  `${process.env.REACT_APP_URL_API_BASE}/dashboards/demanda-diaria`;

const obterUrlBuscarDemandasAlerta = (): string =>
  `${process.env.REACT_APP_URL_API_BASE}/dashboards/demandas-alertas`;

export const obterDemandaDiaria = async (): Promise<DemandaDiaria> => {
  const url = obterUrlBuscarDemandaDiaria();
  const conexaoApi = new ConexaoAPI(url, 'get');
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<DemandaDiaria>();
  const response = await conectar();

  return response.data;
};

export const obterDemandasAlerta = async (): Promise<DemandasAlertaResponse> => {
  const url = obterUrlBuscarDemandasAlerta();
  const conexaoApi = new ConexaoAPI(url, 'get');
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<DemandasAlertaResponse>();
  const response = await conectar();

  return response.data;
};
