import React from 'react';
import { Box } from '@material-ui/core';
import { IntervaloTempo } from 'shared/types/apiTypes';
import { obterPorcentagemFormatada } from 'shared/functions/numberUtils';
import Porcentagem from 'shared/components/porcentagem/Porcentagem';
import IconeAprovadoCirculo from 'assets/icones/IconeAprovadoCirculo';
import IconePararCirculo from 'assets/icones/IconePararCirculo';
import TypographyTipoValor from '../../components/TypographyTipoValor';
import { PropostaSupervisor } from '../../api/aprovacoesSupervisorApiTypes';
import ValorProposta from '../../components/ValorProposta';
import NegociadorFilial from '../../components/NegociadorFilial';
import BadgeIntervaloTempo from '../../components/BadgeIntervaloTempo';
import useStylesCardProposta from '../../styles/cardProposta';
import CardPropostaGreguimSugerido from './CardPropostaGreguimSugerido';

export type CardPropostaAprovadaType = {
  proposta: PropostaSupervisor;
};

const CardPropostaAprovada = ({
  proposta,
}: CardPropostaAprovadaType): JSX.Element => {
  const {
    valorProposta,
    valorFrotaPropria,
    nomeNegociador,
    cidadeFilial,
    tempoAnaliseAprovacao,
    cidadePrincipal,
    romaneio,
    valorPropostaRobo,
  } = proposta;

  const classes = useStylesCardProposta();
  return (
    <Box className={classes.container}>
      <TypographyTipoValor>Valor aprovado:</TypographyTipoValor>
      <Box className={classes.boxSpaceBetween}>
        <ValorProposta icone={<IconeAprovadoCirculo />} valor={valorProposta} />
        <Porcentagem
          styles={classes.badgePorcentagem}
          porcentagem={obterPorcentagemFormatada(
            valorProposta,
            valorFrotaPropria,
          )}
          complemento="FP"
        />
      </Box>
      <NegociadorFilial
        negociador={nomeNegociador}
        filial={cidadeFilial}
        cidadePrincipal={cidadePrincipal}
        romaneio={romaneio}
      />
      <Box className={classes.boxIntervalo}>
        <BadgeIntervaloTempo
          icone={<IconePararCirculo />}
          intervalo={tempoAnaliseAprovacao || ({} as IntervaloTempo)}
        />
        {valorPropostaRobo === valorProposta && <CardPropostaGreguimSugerido />}
      </Box>
    </Box>
  );
};

export default CardPropostaAprovada;
