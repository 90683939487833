import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  boxInformativo: {
    margin: 'auto',
    height: '60vh',
    display: 'flex',
    alignItems: 'center',
  },
}));
