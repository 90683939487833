import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Drawer,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  Chip,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { buscarColaboradoresNegociadores } from 'features/configuracoes/utils/configuracoes';
import { useDispatch } from 'react-redux';
import { ColaboradoresNegociadores } from 'features/configuracoes/api/configuracoesApiTypes';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import CloseIcon from '@material-ui/icons/Close';
import { Cancel } from '@material-ui/icons';
import IconeSincronizar from 'assets/icones/IconeSincronizar';
import { abrirSnackbarErro } from 'shared/components/snackbar/redux/snackbarAction';
import ModalTransferenciaNegociacao from './ModalTransferenciaNegociacao';

export type TrocarNegociadorDrawerProps = {
  openDrawer: boolean;
  hasDemanda: boolean;
  negociacoesIdTransferencia: { [key: number]: boolean };
  closeDrawerDemandasAndNegociador: () => Promise<void>;
  handleTrocarNegociador: () => Promise<void>;
  selectColaboradorDrawer: ColaboradoresNegociadores[];
  finalizarTransferencia: () => Promise<void>;
};

const useStyles = makeStyles(() => ({
  containerAutocomplete: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '88px',
  },
  customPopupButton: {
    animation: 'none !important',
    transform: 'none !important',
    transition: 'none !important',
    '&.MuiAutocomplete-popupIndicator': {
      transform: 'none',
    },
  },
  text: {
    fontSize: '24px',
  },
  boxTransferirCargas: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '20px',
    gap: '14px',
  },
  autoComplete: {
    minWidth: '345px',
  },
  buttonTransferencia: {
    width: '213px',
    height: '40px',
    borderRadius: '100px',
    fontSize: '16px',
    fontWeight: 400,
    textTransform: 'none',
    boxShadow: 'none',
    backgroundColor: '#2962FF',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#0f4fff',
    },
  },
  buttonCancelar: {
    width: '115px',
    height: '40px',
    borderRadius: '100px',
    fontSize: '16px',
    fontWeight: 400,
    textTransform: 'none',
    boxShadow: 'none',
    backgroundColor: '#F5F5F5',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#e0e0e0',
    },
  },
  drawerDocked: {
    boxShadow: ' 0px -6px 40px 0px #00000040',
  },
  iconX: {
    marginRight: '27px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  negSelecionado: {
    backgroundColor: '#EEEEEE',
    padding: '6px 19px',
    borderRadius: '20px',
    textAlign: 'center',
    maxWidth: '18%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const TrocarNegociadorDrawer = ({
  openDrawer,
  hasDemanda,
  negociacoesIdTransferencia,
  closeDrawerDemandasAndNegociador,
  handleTrocarNegociador,
  selectColaboradorDrawer,
  finalizarTransferencia,
}: TrocarNegociadorDrawerProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [
    colaboradores,
    setColaboradoresNegociadores,
  ] = useState<ColaboradoresNegociadores>();

  const [colaboradorBool, setColaboradorBool] = useState<boolean>(false);
  const [fecharDrawer, setFecharDrawer] = useState<boolean>(false);
  const [abrirModal, setAbrirModal] = useState<boolean>(false);
  const [confirmar, setLoadingConfirmar] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState<
    ColaboradoresNegociadores[]
  >([]);

  const handleAutocompleteChange = (
    event: React.ChangeEvent<unknown>,
    newValue: ColaboradoresNegociadores[] | [],
  ) => {
    if (Array.isArray(newValue) && newValue.length > 0) {
      const recentItem = newValue[newValue.length - 1];
      setColaboradorBool(true);
      setSelectedOptions([recentItem]);
    } else {
      setSelectedOptions([]);
    }
  };

  const trocaNegociador = async () => {
    try {
      setLoadingConfirmar(true);
      await handleTrocarNegociador();
    } catch (error) {
      dispatch(abrirSnackbarErro({ mensagem: 'algo deu errado' }));
    } finally {
      setLoadingConfirmar(false);
      setSelectedOptions([]);
    }
  };

  const handleTranferenciaColaborador = () => {
    setAbrirModal(true);
    setFecharDrawer(true);
  };

  const handleFecharModal = () => {
    setAbrirModal(false);
    setFecharDrawer(false);
  };

  const handleDelete = () => {
    setSelectedOptions([]);
    setColaboradorBool(false);
  };

  const handleCloseDemandasNegociador = async () => {
    try {
      setLoadingCancel(true);
      await closeDrawerDemandasAndNegociador();
    } catch (error) {
      dispatch(abrirSnackbarErro({ mensagem: 'algo deu errado' }));
    } finally {
      setLoadingCancel(false);
      setSelectedOptions([]);
    }
  };

  const finalizacaoTransferencia = async () => {
    await finalizarTransferencia();
    setFecharDrawer(true);
  };

  useEffect(() => {
    buscarColaboradoresNegociadores(setColaboradoresNegociadores, dispatch);
  }, [dispatch]);

  return (
    <>
      {fecharDrawer ? (
        <></>
      ) : (
        <Drawer
          anchor="bottom"
          variant="persistent"
          open={openDrawer}
          classes={{
            paperAnchorDockedBottom: classes.drawerDocked,
          }}
          BackdropProps={{ invisible: true }}
          ModalProps={{
            keepMounted: true,
            disableBackdropClick: true,
          }}
        >
          <Box className={classes.containerAutocomplete}>
            <Box className={classes.boxTransferirCargas}>
              <Typography className={classes.text}>
                {hasDemanda
                  ? 'Enviar negociações de:'
                  : 'Você está em um ambiente assistido de:'}
              </Typography>
              <Tooltip title="Nayana Candida Candida Candida">
                <Box className={classes.negSelecionado}>
                  {selectColaboradorDrawer[0]?.nome}
                </Box>
              </Tooltip>
              {hasDemanda && (
                <>
                  <IconeSincronizar style={{ margin: '0 8px' }} />
                  <Autocomplete
                    multiple
                    onChange={handleAutocompleteChange}
                    value={selectedOptions}
                    closeIcon={false}
                    popupIcon={false}
                    className={classes.autoComplete}
                    options={
                      Array.isArray(colaboradores)
                        ? colaboradores.filter(
                            (colaborador) =>
                              colaborador.id !== selectColaboradorDrawer[0]?.id,
                          )
                        : []
                    }
                    getOptionLabel={(colaborador) =>
                      colaborador.nome
                        ? colaborador.nome
                            .toLowerCase()
                            .split(' ')
                            .map(
                              (texto: string) =>
                                texto.charAt(0).toUpperCase() + texto.slice(1),
                            )
                            .join(' ')
                        : ''
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecione o negociador"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            padding: '2px 2px',
                            caretColor: 'white',
                            display: 'flex',
                            alignItems: 'center',
                          },
                          endAdornment: (
                            <IconButton>
                              <SearchRoundedIcon />
                            </IconButton>
                          ),
                        }}
                      />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          key={option.idColaborador}
                          label={option.nome}
                          {...getTagProps({ index })}
                          onDelete={() => {
                            handleDelete();
                          }}
                          deleteIcon={<Cancel />}
                        />
                      ))
                    }
                  />
                </>
              )}
            </Box>
            <Box display="flex" gridGap="15px" marginRight="30px">
              <Button
                className={classes.buttonCancelar}
                variant="contained"
                color="default"
                onClick={handleCloseDemandasNegociador}
              >
                {loadingCancel ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  'Cancelar'
                )}
              </Button>
              {hasDemanda ? (
                <Button
                  className={classes.buttonTransferencia}
                  variant="contained"
                  color="primary"
                  disabled={!colaboradorBool}
                  onClick={handleTranferenciaColaborador}
                >
                  Transferir demanda
                </Button>
              ) : (
                <Button
                  className={classes.buttonTransferencia}
                  variant="contained"
                  color="primary"
                  onClick={trocaNegociador}
                >
                  {confirmar ? (
                    <CircularProgress color="inherit" size={24} />
                  ) : (
                    'Trocar de negociador'
                  )}
                </Button>
              )}
            </Box>
            <Box className={classes.iconX}>
              <CloseIcon
                onClick={handleCloseDemandasNegociador}
                color="disabled"
              />
            </Box>
          </Box>
        </Drawer>
      )}
      <ModalTransferenciaNegociacao
        fecharModal={handleFecharModal}
        openModal={abrirModal}
        negociacoesIdTransferencia={negociacoesIdTransferencia}
        negociadorSelecionado={selectColaboradorDrawer}
        negociadorTransferencia={selectedOptions}
        finalizarTransferencia={finalizacaoTransferencia}
      />
    </>
  );
};

export default TrocarNegociadorDrawer;
