import React from 'react';
import { Box, Switch, Typography } from '@material-ui/core';
import { SwitchAcessoProps } from './types';
import { useStyles } from './styles';

const SwitchAcesso = ({
  titulo,
  permissaoAcesso,
  desabilitado,
  setPermissaoAcesso,
  setValorMaximo,
}: SwitchAcessoProps): JSX.Element => {
  const styles = useStyles();
  return (
    <Box className={styles.boxSwitch}>
      <Typography variant="h6" component="p" className={styles.textoSimples}>
        {titulo}
      </Typography>
      <Switch
        checked={permissaoAcesso}
        onChange={(e) => {
          setPermissaoAcesso(e.target.checked);

          if (setValorMaximo) {
            setValorMaximo(e.target.checked ? 100 : 0);
          }
        }}
        disabled={desabilitado}
        color="primary"
      />
    </Box>
  );
};

export default SwitchAcesso;
