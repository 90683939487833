import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';
import { formatarNumeroParaStringMonetariaComSimbolo } from 'shared/functions/dinheiroUtils';

export type ValorPropostaType = {
  valor: number;
  icone?: JSX.Element;
  deletado?: boolean;
  propostaSugeridaPorGreguim?: boolean;
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  valorProposta: {
    fontSize: '20px',
    fontWeight: 400,
    color: theme.palette.cores.cinza[900],
    textDecoration: ({ deletado }: { deletado?: boolean }) =>
      deletado ? 'line-through' : 'none',
  },
  valorPropostaGreguim: {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '24px',
    color: theme.palette.cores.verde[700],
  },
  margem: {
    marginLeft: spacingInPixels(theme, 1),
  },
}));

const ValorProposta = ({
  valor,
  icone,
  deletado,
  propostaSugeridaPorGreguim,
}: ValorPropostaType): JSX.Element => {
  const classes = useStyles({ deletado });
  const estilosMargem = icone && classes.margem;
  const classTypography = propostaSugeridaPorGreguim
    ? classes.valorPropostaGreguim
    : classes.valorProposta;
  return (
    <Box className={classes.container}>
      {icone}
      <Typography className={`${classTypography} ${estilosMargem}`}>
        {formatarNumeroParaStringMonetariaComSimbolo(valor)}
      </Typography>
    </Box>
  );
};

export default ValorProposta;
