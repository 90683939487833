import React from 'react';

import TipoMensagemProposta from '../../../../../shared/constants/TipoMensagemProposta';

import { ChatBalaoProps } from '../ChatBalao';
import TextoLivre from '../MensagemComum/TextoLivre';

import AceiteMotorista from './AceiteMotorista';
import ContrapropostaMotorista from './ContrapropostaMotorista';

const MensagemMotorista = ({ mensagem }: ChatBalaoProps): JSX.Element => {
  switch (mensagem.tipo) {
    case TipoMensagemProposta.Aceite:
      return <AceiteMotorista mensagem={mensagem} />;
    case TipoMensagemProposta.TextoLivre:
      return <TextoLivre mensagem={mensagem} />;
    default:
      return <ContrapropostaMotorista mensagem={mensagem} />;
  }
};

export default MensagemMotorista;
