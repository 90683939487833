import React, { useMemo, useState } from 'react';
import { DetalhesDemandaDiaria } from 'features/detalhesDemandasDiaria/api/detalhesDemandasDiariaApiTypes';
import { ordenarDemandas } from 'features/detalhesDemandasDiaria/utils/detalhesDemandas';
import { ChaveOrdenacaoDetalhesDemandas } from 'features/detalhesDemandasDiaria/constants/Constants';

import PaginaVaziaIndicadores from 'shared/components/paginaVaziaIndicadores/PaginaVaziaIndicadores';
import { TabelaProps } from 'features/detalhesDemandasDiaria/types/tabelaTypes';
import CabecalhoTabelaDetalhesDemandas from '../../components/tabelaDetalhesDemandas/CabecalhoTabelaDetalhesDemandas';
import SkeletonTabelaDetalhesDemandas from '../../components/tabelaDetalhesDemandas/SkeletonTabelaDetalhesDemandas';
import { estilosTabelaDemandasConcluidas } from '../../styles/styleTabela';
import ConteudoTabelaDemandasConcluidas from './ConteudoTabelaDemandasConcluidas';
import { useStyles } from '../../styles/styleCompaddinDiferente';
import {
  dadosSkeleton,
  dadosItensCabecalho,
} from '../../constants/TabelaDemandasConcluidas';

const TabelaDemandasConcluidas = ({
  demandas,
  carregando,
  erro,
}: TabelaProps): JSX.Element => {
  const classes = useStyles();

  const { gridTabela } = estilosTabelaDemandasConcluidas();

  const [demandasOrdenadas, setDemandasOrdenadas] = useState<
    DetalhesDemandaDiaria[]
  >([]);

  const handleClickOrdenacao = (chave: string, ordenacaoCrescente: boolean) => {
    setDemandasOrdenadas(
      ordenarDemandas(
        demandasOrdenadas,
        chave as ChaveOrdenacaoDetalhesDemandas,
        ordenacaoCrescente,
      ),
    );
  };

  useMemo(() => {
    setDemandasOrdenadas([...demandas]);
  }, [demandas]);

  if (demandasOrdenadas.length === 0 && !carregando)
    return <PaginaVaziaIndicadores erro={erro} />;

  return (
    <table className={classes.container}>
      <thead>
        <CabecalhoTabelaDetalhesDemandas
          onClickOrdenacao={handleClickOrdenacao}
          dadosItensCabecalho={dadosItensCabecalho}
          estiloCabecalho={gridTabela}
        />
      </thead>
      {carregando ? (
        <SkeletonTabelaDetalhesDemandas
          dadosSkeleton={dadosSkeleton}
          estiloLinha={`${gridTabela} ${classes.estiloLinhaSkeleton}`}
        />
      ) : (
        <ConteudoTabelaDemandasConcluidas demandas={demandasOrdenadas} />
      )}
    </table>
  );
};

export default TabelaDemandasConcluidas;
