import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForward from '@material-ui/icons/ArrowForward';

import {
  possuiEspaco,
  possuiCaracteresEspeciais,
  estaVazio,
} from '../../../shared/functions/stringUtils';
import Button from '../../../shared/components/button/ButtonTextTransformNone';
import PasswordInput from '../../../shared/components/input/PasswordInput';
import TextField from '../../../shared/components/input/TextField';
import MsgConstants from '../../../shared/constants/msgConstants';
import UtilConstants from '../../../shared/constants/utilConstants';
import { spacingInPixels } from '../../../shared/functions/materialUtils';
import { PATH_PAGINA_INICIAL } from '../../../shared/constants/pathConstants';

import LoginBackground from './LoginBackground';
import DisablingTooltip from '../../../shared/components/tooltip/DisablingTooltip';
import { fazerLogin } from '../redux/loginOperation';
import { abrirSnackbarErro } from '../../../shared/components/snackbar/redux/snackbarAction';

const useStyles = makeStyles((theme) => ({
  info: {
    marginBottom: spacingInPixels(theme, 1),
    marginTop: spacingInPixels(theme, 4),
    '& p': {
      fontWeight: 400,
      fontSize: spacingInPixels(theme, 2),
      lineHeight: spacingInPixels(theme, 3),
    },
  },
  tituloGestaoCargas: {
    fontSize: spacingInPixels(theme, 4),
    lineHeight: spacingInPixels(theme, 5),
  },
  tituloAgregados: {
    fontStyle: 'italic',
    fontWeight: 600,
    fontSize: spacingInPixels(theme, 4),
    lineHeight: spacingInPixels(theme, 6),
  },
  espacamento: {
    marginTop: spacingInPixels(theme, 3),
    width: '100%',
  },
}));

const Login = (): JSX.Element => {
  const classes = useStyles();
  const [usuario, setUsuario] = useState('');
  const [senha, setSenha] = useState('');
  const dispatch = useDispatch();

  const [erroUsuario, setErroUsuario] = useState(false);
  const [erroSenha, setErroSenha] = useState(false);
  const [descricaoErroUsuario, setDescricaoErroUsuario] = useState('');
  const [descricaoErroSenha, setDescricaoErroSenha] = useState('');
  const [desabilitarBotao, setDesabilitarBotao] = useState(false);

  const history = useHistory();

  const setStatusUsuario = (possuiErro: boolean, descricao: string) => {
    setErroUsuario(possuiErro);
    setDescricaoErroUsuario(descricao);
  };

  const setStatusSenha = (possuiErro: boolean, descricao: string) => {
    setErroSenha(possuiErro);
    setDescricaoErroSenha(descricao);
  };

  const campoUsuarioPreenchido = () => {
    const campoVazio = estaVazio(usuario);
    if (campoVazio) {
      setStatusUsuario(true, MsgConstants.CAMPO_OBRIGATORIO);
    }

    return !campoVazio;
  };

  const campoSenhaPreenchido = () => {
    const campoVazio = estaVazio(senha);
    if (campoVazio) {
      setStatusSenha(true, MsgConstants.CAMPO_OBRIGATORIO);
    }

    return !campoVazio;
  };

  const camposEstaoPreenchidos = () => {
    const usuarioPreenchido = campoUsuarioPreenchido();
    const senhaPreenchida = campoSenhaPreenchido();
    return usuarioPreenchido && senhaPreenchida;
  };

  const validarUsuarioInvalido = () => {
    return possuiEspaco(usuario) || possuiCaracteresEspeciais(usuario);
  };

  const dispararSnackbarErro = (erro: string) => {
    dispatch(
      abrirSnackbarErro({
        mensagem: erro,
      }),
    );
  };

  const validarCampoUsuario = () => {
    if (validarUsuarioInvalido()) {
      setStatusUsuario(true, MsgConstants.CAMPO_INVALIDO);
    } else {
      setStatusUsuario(false, UtilConstants.VAZIO);
    }
  };

  const validarCampoSenha = () => {
    setStatusSenha(false, UtilConstants.VAZIO);
  };

  const setSenhaInput = (value: string) => {
    setSenha(value);
  };

  const acessar = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!camposEstaoPreenchidos() || validarUsuarioInvalido()) {
      return;
    }

    setDesabilitarBotao(true);

    await fazerLogin({ usuario, senha })(dispatch)
      .then(() => {
        history.push(PATH_PAGINA_INICIAL);
      })
      .catch((e) => {
        setDesabilitarBotao(false);
        dispararSnackbarErro(e.message);
      });
  };

  return (
    <LoginBackground>
      <Grid container direction="column" alignItems="flex-start">
        <Typography
          variant="h5"
          color="secondary"
          className={classes.tituloGestaoCargas}
        >
          GESTÃO DE CARGAS
        </Typography>
        <Typography color="primary" className={classes.tituloAgregados}>
          AGREGADOS
        </Typography>

        <Grid item className={classes.info}>
          <Typography component="p" color="textPrimary">
            Olá! Informe seus dados de rede do Martins para entrar na
            plataforma.
          </Typography>
        </Grid>

        <form onSubmit={acessar} style={{ width: '100%' }}>
          <Grid className={classes.espacamento} item>
            <TextField
              id="Usuário"
              label="Usuário"
              variant="outlined"
              onBlur={validarCampoUsuario}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setUsuario(event.target.value)
              }
              error={erroUsuario}
              helperText={descricaoErroUsuario}
              style={{ width: '100%' }}
              inputProps={{ maxLength: 25 }}
              autoFocus
            />
          </Grid>

          <Grid className={classes.espacamento} item>
            <PasswordInput
              erroSenha={erroSenha}
              descricaoErroSenha={descricaoErroSenha}
              setSenha={setSenhaInput}
              validarCampoSenha={validarCampoSenha}
            />
          </Grid>
          <Grid className={classes.espacamento} item>
            <DisablingTooltip disabled={desabilitarBotao} title="Entrar">
              <Button
                variant="contained"
                color="primary"
                onClick={acessar}
                endIcon={!desabilitarBotao && <ArrowForward />}
                disabled={desabilitarBotao}
                style={{
                  width: '100%',
                  height: '48px',
                  fontSize: '16px',
                }}
                type="submit"
              >
                {desabilitarBotao ? (
                  <CircularProgress color="inherit" size={18} />
                ) : (
                  'Entrar'
                )}
              </Button>
            </DisablingTooltip>
          </Grid>
        </form>
      </Grid>
    </LoginBackground>
  );
};

export default Login;
