import {
  formatarIntervaloTempo,
  formatarUnidadeIntervaloTempo,
  transformarIntervaloTempoEmSegundos,
} from 'shared/functions/IntervaloTempo';
import { NegociacaoTipoAlerta } from 'features/dashboards/negociadores/api/listagemNegociadoresApiTypes';
import { IntervaloTempo } from 'shared/types/apiTypes';

import { obterDemandasAlerta } from '../api/demandaDiariaManager';
import {
  Alerta,
  DemandaAlerta,
  DemandaDiaria,
  DemandasAlertaResponse,
} from '../api/demandaDiariaApiTypes';
import { ChaveCabecalho } from '../views/modalAlertas/CabecalhoTabelaDemandas';

export const ordenarDemandasPorPrioridade = (
  demandas: DemandaAlerta[],
  categoriaAlerta: NegociacaoTipoAlerta,
): DemandaAlerta[] => {
  switch (categoriaAlerta) {
    case NegociacaoTipoAlerta.semAprovacao:
    case NegociacaoTipoAlerta.naoVista:
      return [...demandas].sort(
        (a, b) =>
          transformarIntervaloTempoEmSegundos(
            b.alertas.find((alertaB) => alertaB.categoria === categoriaAlerta)
              ?.tempoCategoria,
          ) -
          transformarIntervaloTempoEmSegundos(
            a.alertas.find((alertaA) => alertaA.categoria === categoriaAlerta)
              ?.tempoCategoria,
          ),
      );
    case NegociacaoTipoAlerta.alertaVencimento:
    default:
      return [...demandas].sort(
        (a, b) =>
          new Date(a.dataCarregamento).getTime() -
          new Date(b.dataCarregamento).getTime(),
      );
  }
};

export const filtrarDemandasPorAlerta = (
  demandasAlertas: DemandaAlerta[],
  categoriaAlerta: NegociacaoTipoAlerta,
  setDemandasAlertasFiltradas: (
    demandasAlertasFiltradas: DemandaAlerta[],
  ) => void,
): void => {
  const demandasFiltradas =
    [...demandasAlertas].filter((demanda) =>
      demanda.alertas.some((alerta) => alerta.categoria === categoriaAlerta),
    ) || [];
  const demandasOrdenadas = ordenarDemandasPorPrioridade(
    demandasFiltradas,
    categoriaAlerta,
  );
  setDemandasAlertasFiltradas(demandasOrdenadas);
};

export type FiltroAlerta = {
  tipo: NegociacaoTipoAlerta;
  descricao: string;
};

export const definirFiltros = (
  setFiltroAtual: (filtro: FiltroAlerta) => void,
  categoriaAtual: NegociacaoTipoAlerta,
  demandasDiarias?: DemandaDiaria,
): FiltroAlerta[] => {
  if (!demandasDiarias) return [];
  const {
    naoVisto,
    semAprovacao,
    alertaVencimento,
    tempoMaximoAlertaNaoVisto,
    tempoMaximoAlertaSemAprovacao,
  } = demandasDiarias;
  const filtros = [
    {
      tipo: NegociacaoTipoAlerta.naoVista,
      descricao: `Não visto há +${formatarIntervaloTempo(
        tempoMaximoAlertaNaoVisto,
      )} (${naoVisto})`,
    },
    {
      tipo: NegociacaoTipoAlerta.semAprovacao,
      descricao: `Sem aprovação há +${formatarIntervaloTempo(
        tempoMaximoAlertaSemAprovacao,
      )} (${semAprovacao})`,
    },
    {
      tipo: NegociacaoTipoAlerta.alertaVencimento,
      descricao: `Alerta vencimento (${alertaVencimento})`,
    },
  ];
  setFiltroAtual(
    filtros.find((filtro) => filtro.tipo === categoriaAtual) || filtros[0],
  );
  return filtros;
};

export const definirLabelTempoAlertaBaseadoNaCategoriaAtual = (
  categoriaAtual?: NegociacaoTipoAlerta,
): string => {
  switch (categoriaAtual) {
    case NegociacaoTipoAlerta.semAprovacao:
      return 'Tempo s/ aprovação';
    case NegociacaoTipoAlerta.alertaVencimento:
      return 'Tempo até vencimento';
    case NegociacaoTipoAlerta.naoVista:
    default:
      return 'Tempo s/ interação';
  }
};

export const definirValorTempoAlerta = (
  alertas: Alerta[],
  categoriaAtual?: NegociacaoTipoAlerta,
): IntervaloTempo | undefined =>
  alertas.find((alerta) => alerta.categoria === categoriaAtual)
    ?.tempoCategoria || undefined;

export const definirLabelAtrasoValorTempoAlerta = (
  tempoAlerta: IntervaloTempo,
): string => {
  const tempoAtrasoValido = Object.entries(tempoAlerta).find(
    (item) => item[1] !== 0,
  );
  if (!tempoAtrasoValido) return '--';
  return formatarUnidadeIntervaloTempo(tempoAtrasoValido);
};

export const ordenarDemandasPorCabecalho = (
  demandas: DemandaAlerta[],
  chaveOrdenacao: ChaveCabecalho,
  sentidoOrdenacaoCrescente: boolean,
): DemandaAlerta[] => {
  if (chaveOrdenacao === ChaveCabecalho.filial) {
    if (sentidoOrdenacaoCrescente) {
      return [...demandas].sort((a, b) =>
        a.nomeFilial.localeCompare(b.nomeFilial),
      );
    }
    return [...demandas].sort((a, b) =>
      b.nomeFilial.localeCompare(a.nomeFilial),
    );
  }

  return demandas;
};

export const buscarDemandasAlertas = async (
  setDemandasAlertas: (demandasAlertas: DemandaAlerta[]) => void,
  setDemandasAlertasFiltradas: (
    demandasAlertasFiltradas: DemandaAlerta[],
  ) => void,
  categoriaAlerta: NegociacaoTipoAlerta,
): Promise<void> => {
  await obterDemandasAlerta()
    .then(({ demandasAlerta }: DemandasAlertaResponse) => {
      setDemandasAlertas(demandasAlerta);
      filtrarDemandasPorAlerta(
        demandasAlerta,
        categoriaAlerta,
        setDemandasAlertasFiltradas,
      );
    })
    .catch(() => {
      setDemandasAlertas([]);
      setDemandasAlertasFiltradas([]);
    });
};
