import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { CamposDoHeaderAlternativo } from 'shared/components/menu/PaginasPortal';
import { RootState } from '../../store/reducer';
import { buscarDadosUsuario } from '../../features/login/redux/loginOperation';
import { refreshToken } from '../../features/login/api/loginManager';

import { PATH_LOGIN } from '../constants/pathConstants';

import { criarPollingNotificacoes } from '../../features/notificacoes/utils/notificacoes';

import { PaginaType } from '../constants/Pagina';

import EstruturaPagina from './EstruturaPagina';

export type PrivateRouteProps = {
  component: JSX.Element;
  path: string;
  codigo: PaginaType;
  headerAlternativo?: CamposDoHeaderAlternativo[];
};

const PrivateRoute = ({
  component,
  path,
  codigo,
  headerAlternativo,
}: PrivateRouteProps): JSX.Element => {
  const { dados, deslogado } = useSelector(
    (state: RootState) => state.loginReducer,
  );

  const [logado, setLogado] = useState(false);

  const [validacaoAcessoRealizada, setValidacaoAcessoRealizada] = useState(
    false,
  );
  const [
    referenciaIntervalNotificacoes,
    setReferenciaIntervalNotificacoes,
  ] = useState<number>();

  const dispatch = useDispatch();

  useEffect(() => {
    if (deslogado) {
      setLogado(false);
      return;
    }
    if (!dados) {
      buscarDadosUsuario()(dispatch);
    }
  }, [dados, deslogado, dispatch]);

  useEffect(() => {
    const tratarRefreshToken = async () => {
      await refreshToken()
        .then(async () => {
          setLogado(true);
          setReferenciaIntervalNotificacoes(criarPollingNotificacoes(dispatch));
          setValidacaoAcessoRealizada(true);
        })
        .catch(() => {
          setLogado(false);
          setValidacaoAcessoRealizada(true);
        });
    };

    if (!validacaoAcessoRealizada) {
      tratarRefreshToken();
    }
  }, [dispatch, validacaoAcessoRealizada]);

  useEffect(() => {
    return () => {
      clearInterval(referenciaIntervalNotificacoes);
    };
  }, [referenciaIntervalNotificacoes]);

  const possuiAcessoPagina =
    codigo === 5
      ? dados?.usuario.dadosPermissao.ativo ||
        dados?.usuario.paginasAcesso?.includes(codigo)
      : dados?.usuario.paginasAcesso?.includes(codigo);

  return (
    <>
      {validacaoAcessoRealizada && (
        <Route
          exact
          path={path}
          render={({ location }) =>
            logado && validacaoAcessoRealizada ? (
              <EstruturaPagina
                possuiAcessoPagina={possuiAcessoPagina}
                component={component}
                headerAlternativo={headerAlternativo}
              />
            ) : (
              <Redirect
                to={{
                  pathname: PATH_LOGIN,
                  state: { from: location },
                }}
              />
            )
          }
        />
      )}
    </>
  );
};

export default PrivateRoute;
