import { makeStyles, Typography } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import React from 'react';
import spacingInPixels from 'shared/functions/materialUtils';
import ConteudoBigNumber from './ConteudoBigNumberAlerta';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacingInPixels(theme, 1),
  },
  containerComplemento: {
    display: 'inline-flex',
    alginItems: 'center',
    gap: spacingInPixels(theme, 0.5),
  },
  textoComplemento: {
    fontWeight: 300,
    fontSize: '10px',
  },
  iconeInformacao: {
    fill: theme.palette.cores.azulMartins[700],
    width: '12px',
    height: '12px',
  },
}));

export interface ConteudoBigNumberComplementoProps {
  icone: React.ReactElement;
  cor?: string;
  numero?: number;
  descricao: string;
  onClick?: () => void;
  complemento: string;
}

const ConteudoBigNumberComplemento = ({
  icone,
  cor,
  numero,
  descricao,
  onClick,
  complemento,
}: ConteudoBigNumberComplementoProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <ConteudoBigNumber
        icone={icone}
        cor={cor}
        numero={numero}
        descricao={descricao}
        onClick={onClick}
      />
      <span className={classes.containerComplemento}>
        <InfoOutlined className={classes.iconeInformacao} />
        <Typography className={classes.textoComplemento}>
          {complemento}
        </Typography>
      </span>
    </div>
  );
};

export default ConteudoBigNumberComplemento;
