import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import TextoItemLista from '../listaDisponiveis/TextoItemLista';
import IconeCaminhao from '../../../../../assets/icones/IconeCaminhao';

export type VeiculoInfoPropTypes = {
  nomeVeiculo: string;
  propostaSelecionada?: boolean;
};

const useStyles = makeStyles((theme) => ({
  texto: {
    marginLeft: 8,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '19px',
    color: ({ propostaSelecionada }: { propostaSelecionada: boolean }) =>
      propostaSelecionada ? theme.palette.cores.branco : '',
  },
  nomeVeiculo: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

const VeiculoInfo = ({
  nomeVeiculo,
  propostaSelecionada = false,
}: VeiculoInfoPropTypes): JSX.Element => {
  const classes = useStyles({ propostaSelecionada });
  const theme = useTheme();

  return (
    <Box display="flex" alignItems="center" height="100%">
      <IconeCaminhao
        width={24}
        height={24}
        fill={
          propostaSelecionada
            ? theme.palette.cores.branco
            : theme.palette.cores.cinza[700]
        }
      />
      <TextoItemLista
        className={[classes.texto, classes.nomeVeiculo].join(' ')}
      >
        {nomeVeiculo.toLowerCase()}
      </TextoItemLista>
    </Box>
  );
};

export default VeiculoInfo;
