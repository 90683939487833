import { makeStyles, useTheme } from '@material-ui/core';
import React from 'react';
import IconeRelogio from 'assets/icones/IconeRelogio';
import IconeReprovadoCirculo from 'assets/icones/IconeReprovadoCirculo';
import CustomTooltip from 'shared/components/tooltip/CustomTooltip';

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    lineHeight: '20px',
    letterSpacing: '0.4px',
  },
  nomeNegociador: {
    fontWeight: 500,
    fontSize: '14px',
    color: theme.palette.cores.cinza[800],
    display: 'block',
    width: 'auto',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  fonteStatusConexao: {
    fontWeight: 400,
    fontSize: '12px',

    display: 'inline',
    '& svg': {
      marginRight: '4px',
      verticalAlign: 'middle',
    },
    '& span': {
      verticalAlign: 'middle',
    },
  },
  statusOnline: {
    color: theme.palette.cores.cinza[600],
  },
  statusOffline: {
    color: theme.palette.cores.vermelho[800],
  },
}));

export interface DadosNegociadorProps {
  nome: string;
  tempoConexao?: string;
}

const DadosNegociador = ({
  nome,
  tempoConexao,
}: DadosNegociadorProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();

  const corIconeStatusOnline = theme.palette.cores.cinza[600];
  const corIconeStatusOffline = theme.palette.cores.vermelho[800];

  return (
    <div className={classes.root}>
      <CustomTooltip title={nome}>
        <span className={classes.nomeNegociador}>{nome}</span>
      </CustomTooltip>
      <div className={classes.fonteStatusConexao}>
        {tempoConexao ? (
          <>
            <IconeRelogio
              transform="scale(1,1)"
              width={15}
              height={15}
              fill={corIconeStatusOnline}
            />
            <span className={classes.statusOnline}>{tempoConexao}</span>
          </>
        ) : (
          <>
            <IconeReprovadoCirculo
              fill={corIconeStatusOffline}
              width={16}
              height={16}
              viewBox="0 0 21 21"
            />
            <span className={classes.statusOffline}>Offline</span>
          </>
        )}
      </div>
    </div>
  );
};

export default DadosNegociador;
