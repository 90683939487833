import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Checkbox } from '@material-ui/core';

import { OrigemNegociacao } from 'features/informacoesNegociacao/api/informacoesNegociacaoApiTypes';

import { useHistory } from 'react-router-dom';
import { RootState } from '../../../../../store/reducer';

import Dialog from '../../../../../shared/components/modal/Dialog';
import ConfirmarDialogButton from '../../../../../shared/components/button/ConfirmarDialogButton';
import spacingInPixels from '../../../../../shared/functions/materialUtils';
import DialogTitle from '../../../../../shared/components/modal/DialogTitle';

import msgConstants from '../../../../../shared/constants/msgConstants';
import { estaEmBranco } from '../../../../../shared/functions/stringUtils';

import {
  cancelarNegociacaoOperation,
  cancelarNegociacaoAutomatizadoOperation,
} from '../../../redux/informacoesNegociacaoOperation';

import { fecharModalCancelarNegociacao } from '../redux/motoristasCardAction';
import SelectMotivoRecusa from '../../../../../shared/components/select/SelectMotivoRecusa';
import { atualizarMensagensChatAberto } from '../../../../chatNegociacao/utils/chatNegociacao';
import PropostaStatus from '../../../../../shared/constants/PropostaStatus';

type CancelarNegociacaoDialogProps = {
  // eslint-disable-next-line react/require-default-props
  disabledTest?: string;
};

const useStyles = makeStyles((theme) => ({
  paragrafo: {
    marginBottom: spacingInPixels(theme, 4),
  },
  paragrafo2: {
    color: '#424242',
    fontWeight: 400,
    fontSize: '12px',
  },
}));

const CancelarNegociacaoDialog = ({
  disabledTest,
}: CancelarNegociacaoDialogProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const { idNegociacao, negociacao } = useSelector(
    (state: RootState) => state.informacoesNegociacaoReducer,
  );
  const { modalCancelarNegociacaoAberto } = useSelector(
    (state: RootState) => state.motoristasCardReducer,
  );

  const { chatIdProposta } = useSelector(
    (state: RootState) => state.chatNegociacaoReducer,
  );

  const [erro, setErro] = useState(false);
  const [mensagemErro, setMensagemErro] = useState('');
  const [motivoRecusa, setMotivoRecusa] = useState('');
  const [checkTMS, setCheckTMS] = useState(false);
  const [showCheck, setShowCheck] = useState(false);

  const isAutomatizado =
    negociacao?.origemNegociacao === OrigemNegociacao.freteTabelado;

  const history = useHistory();

  useEffect(() => {
    if (!estaEmBranco(motivoRecusa)) {
      setErro(false);
      setMensagemErro('');
    }
  }, [motivoRecusa]);

  const preencherErro = () => {
    setErro(true);
    setMensagemErro(msgConstants.CAMPO_OBRIGATORIO);
  };

  const onSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setMotivoRecusa(String(event.target.value));
  };

  const fecharModal = () => {
    setErro(false);
    setMensagemErro('');
    setMotivoRecusa('');
    dispatch(fecharModalCancelarNegociacao());
  };

  const cancelarNegociacao = async () => {
    if (!estaEmBranco(motivoRecusa)) {
      fecharModal();
      let result;
      if (isAutomatizado) {
        result = await cancelarNegociacaoAutomatizadoOperation(
          idNegociacao,
          Number(motivoRecusa),
          checkTMS,
        )(dispatch);
      } else {
        result = await cancelarNegociacaoOperation(
          idNegociacao,
          Number(motivoRecusa),
        )(dispatch);
      }

      if (chatIdProposta) {
        await atualizarMensagensChatAberto(
          dispatch,
          true,
          idNegociacao,
          chatIdProposta,
          PropostaStatus.RECUSA_NEGOCIADOR,
          Number(motivoRecusa),
        );
      }

      if (isAutomatizado && result === 500) {
        setShowCheck(true);
      }

      if (result === 200 && isAutomatizado) {
        history.push('/negociacoes-automatizadas');
      }
    } else {
      preencherErro();
    }
  };

  const validaButtonCancelar = () => {
    if (disabledTest === 'disabledTest') {
      return false;
    }
    if (estaEmBranco(motivoRecusa)) {
      return true;
    }

    if (showCheck) {
      if (!estaEmBranco(motivoRecusa) && checkTMS) {
        return false;
      }
      return true;
    }

    return false;
  };

  return (
    <Dialog open={modalCancelarNegociacaoAberto} onClose={fecharModal}>
      <DialogTitle onClose={fecharModal} />
      <DialogContent>
        <Box>
          <Typography variant="body1" className={classes.paragrafo}>
            Você deseja realmente cancelar essa negociação?
          </Typography>
          <Typography variant="body1" className={classes.paragrafo2}>
            Por qual motivo você deseja cancelar?
          </Typography>
        </Box>
        <Box width="50%" margin={theme.spacing(2, 0, 2, 0)}>
          <SelectMotivoRecusa
            isAutomatizado={isAutomatizado}
            value={motivoRecusa}
            onChange={onSelectChange}
            label="Motivo cancelar negociação"
            error={erro}
            mensagemErro={mensagemErro}
            id="select-motivos-cancelar-negociacao"
          />
        </Box>
        {isAutomatizado && showCheck && (
          <Box display="flex" alignItems="center">
            <Checkbox
              color="primary"
              onChange={(event) => setCheckTMS(event.target.checked)}
              checked={checkTMS}
              // disabled={negociacao.status === NegociacaoStatus.Concluida}
            />
            <Typography variant="body1" className={classes.paragrafo2}>
              Esse tarifário foi excluido do TMS
            </Typography>
          </Box>
        )}
        <DialogActions>
          <ConfirmarDialogButton
            disabled={validaButtonCancelar()}
            textoBotao="Cancelar a negociação"
            onClick={cancelarNegociacao}
          />
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default CancelarNegociacaoDialog;
