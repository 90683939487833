import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Divider } from '@material-ui/core';
import useMutationObserver from 'shared/customHooks/useMutationObserver';
import { buscarDadosPermissaoUsuario } from 'features/login/redux/loginOperation';
import {
  DadosPermissaoColaborador,
  salvarPermissaoColaboradorRequest,
} from '../../api/configuracoesApiTypes';
import {
  buscarPermissaoColaboradores,
  salvarPermissaoColaborador,
  toggleRoleNegociador,
  toggleRoleSupervisor,
} from '../../utils/configuracoes';
import Header from '../../components/Header';
import AutocompleteColaborador from '../../components/AutocompleteColaborador';
import {
  ContainerInferior,
  ContainerSuperior,
  TextoSimples,
} from '../../styles';
import Role from '../../../../shared/constants/RoleColaborador';
import { ConfiguracoesPerfilProps } from '../../types';
import BotoesConfig from '../configuracoesSistema/BotoesConfig';
import ChipRoles from './ChipRoles';
import ContainerPermissoesPrivilegio from './ContainerPermissoesPrivilegio';

const ConfiguracoesPerfil = ({
  colaboradores,
  setColaboradores,
}: ConfiguracoesPerfilProps): JSX.Element => {
  const dispatch = useDispatch();
  const {
    values,
    initial,
    setValues,
    setInitial,
    isChanged,
  } = useMutationObserver({} as DadosPermissaoColaborador);

  const [
    acessoAprovacaoNovosValores,
    setAcessoAprovacaoNovosValores,
  ] = useState<boolean>(false);

  const handleLimpar = () => {
    setValues(initial);
    setAcessoAprovacaoNovosValores(
      initial?.percentualMaximoAprovacao == null ||
        initial.percentualMaximoAprovacao > 0,
    );
  };

  const handleSalvarAlteracoes = async () => {
    const request: salvarPermissaoColaboradorRequest = {
      ...values,
      percentualMaximoAprovacao: acessoAprovacaoNovosValores
        ? values.percentualMaximoAprovacao || 0
        : 0,
    };
    setInitial(values);
    await salvarPermissaoColaborador(request, dispatch);
    buscarPermissaoColaboradores(setColaboradores, dispatch);
    buscarDadosPermissaoUsuario()(dispatch);
  };

  const tiraTodasAsPermissoes = () => {
    setValues((anterior) => ({
      ...anterior,
      acessoConfiguracaoPerfil: false,
      acessoNegociacaoCargas: false,
      acessoTorreControle: false,
      acessoFreteTabelado: false,
      acessoSimuladorPlanilha: false,
    }));
    setAcessoAprovacaoNovosValores(false);
  };

  const possuiSupervisoENegociador = useCallback(
    () =>
      !values.roles?.includes(Role.NEGOCIADOR) &&
      !values.roles?.includes(Role.SUPERVISOR),
    [values.roles],
  );

  const configurarAcessos = () => {
    if (values.ativo) {
      tiraTodasAsPermissoes();
    }

    setValues((anterior) => ({
      ...anterior,
      ativo: !values.ativo,
    }));
  };

  const handleTrocaMaximo = (novoValor: number) => {
    setValues((anterior) => ({
      ...anterior,
      percentualMaximoAprovacao: novoValor,
    }));
  };

  const campoVazio = () => Object.keys(values).length !== 0;

  useEffect(() => {
    if (possuiSupervisoENegociador() && values?.ativo) {
      tiraTodasAsPermissoes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [possuiSupervisoENegociador, values.ativo]);

  return (
    <>
      <ContainerSuperior>
        <Header titulo="Configuração de perfil" />
        <TextoSimples>
          Digite a matrícula do colaborador e marque as opções de permissão de
          acesso.
        </TextoSimples>
        <AutocompleteColaborador
          colaboradores={colaboradores}
          setColaboradorAlvo={setValues}
          setInitial={setInitial}
          setAcessoAprovacaoNovosValores={setAcessoAprovacaoNovosValores}
        />
        {campoVazio() && (
          <ChipRoles
            negociadorAtivo={values?.roles?.includes(Role.NEGOCIADOR)}
            supervisorAtivo={values?.roles?.includes(Role.SUPERVISOR)}
            ativo={values.ativo}
            setNegociadorAtivo={() =>
              setValues((antigo) => ({
                ...antigo,
                roles: toggleRoleNegociador(antigo.roles),
              }))
            }
            setSupervisorAtivo={() =>
              setValues((antigo) => ({
                ...antigo,
                roles: toggleRoleSupervisor(antigo.roles),
              }))
            }
            configurarAcessos={configurarAcessos}
          />
        )}
      </ContainerSuperior>
      {campoVazio() && (
        <ContainerInferior>
          <Divider orientation="horizontal" />
          <ContainerPermissoesPrivilegio
            configuracoes={values}
            acessoAprovacaoNovosValores={acessoAprovacaoNovosValores}
            possuiSupervisoENegociador={possuiSupervisoENegociador}
            setAcessoConfiguracaoPerfil={(valor: boolean) =>
              setValues((anterior) => ({
                ...anterior,
                acessoConfiguracaoPerfil: valor,
              }))
            }
            setAcessoNegociacaoCargas={(valor: boolean) =>
              setValues((anterior) => ({
                ...anterior,
                acessoNegociacaoCargas: valor,
              }))
            }
            setAcessoAprovacaoNovosValores={setAcessoAprovacaoNovosValores}
            setValorMaximo={(valor: number) =>
              setValues((anterior) => ({
                ...anterior,
                percentualMaximoAprovacao: valor,
              }))
            }
            setAcessoTorreControle={(valor: boolean) =>
              setValues((anterior) => ({
                ...anterior,
                acessoTorreControle: valor,
              }))
            }
            setAcessoNegociacaoAutomatizadas={(valor: boolean) =>
              setValues((anterior) => ({
                ...anterior,
                acessoFreteTabelado: valor,
              }))
            }
            setAcessoUploadExcelCargaSimulador={(valor: boolean) =>
              setValues((anterior) => ({
                ...anterior,
                acessoSimuladorPlanilha: valor,
              }))
            }
            handleTrocaMaximo={handleTrocaMaximo}
          />
          <BotoesConfig
            disabledLimpar={!isChanged}
            disabledSalvar={!isChanged}
            limparFuncao={handleLimpar}
            salvarFuncao={handleSalvarAlteracoes}
          />
        </ContainerInferior>
      )}
    </>
  );
};

export default ConfiguracoesPerfil;
