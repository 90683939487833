import { obterAtendimentosPorPeriodo } from 'features/indicadores/api/indicadoresManager';
import { NegociacaoAtendimento } from '../api/indicadoresApiTypes';
import { PeriodoFiltroIndicadores } from '../constants/Constants';

export const getValoresPeriodo = (
  dadosIndicadores: NegociacaoAtendimento[],
): string[] =>
  dadosIndicadores.map((dadosIndicador) => dadosIndicador?.periodo) || [];

export const buscarDadosAtendimentos = async (
  setDadosIndicadores: (indicadores: NegociacaoAtendimento[]) => void,
  setValoresPeriodo: (periodo: string[]) => void,
  setErro: (erro: boolean) => void,
  periodo: PeriodoFiltroIndicadores,
): Promise<void> =>
  obterAtendimentosPorPeriodo(periodo)
    .then((response) => {
      setDadosIndicadores(response);
      setValoresPeriodo(getValoresPeriodo(response));
      setErro(false);
    })
    .catch(() => {
      setErro(true);
    });
