import React from 'react';

import CelulaConteudoTexto from 'shared/components/tabelaDemandas/CelulaConteudoTexto';
import CelulaNegociador from 'shared/components/tabelaDemandas/CelulaNegociador';
import { obterMotivoRecusa } from 'shared/constants/MotivoRecusa';
import { ConteudoNegociacoes } from 'features/detalhesDemandasDiaria/types/tabelaTypes';
import { useStyles } from '../../styles/styleCorpoTabela';
import { estilosTabelaNegociacoesRecusadas } from '../../styles/styleTabela';

const ConteudoTabelaNegociacoesRecusadas = ({
  negociacoes,
}: ConteudoNegociacoes): JSX.Element => {
  const classes = useStyles({ altura: false });
  const { gridTabela } = estilosTabelaNegociacoesRecusadas();

  return (
    <tbody className={classes.corpoTabela}>
      {negociacoes.map((negociacao) => (
        <tr
          className={gridTabela}
          key={`${negociacao.idNegociacao}-${negociacao.romaneio}`}
        >
          <CelulaConteudoTexto conteudo={negociacao.romaneio} />
          <CelulaNegociador nomeNegociador={negociacao.nomeNegociador} />
          <CelulaConteudoTexto conteudo={negociacao.nomeFilial} />
          <CelulaConteudoTexto conteudo={negociacao.nomeCidade} />
          <CelulaConteudoTexto
            conteudo={obterMotivoRecusa(negociacao?.motivoRecusa)}
          />
        </tr>
      ))}
    </tbody>
  );
};

export default ConteudoTabelaNegociacoesRecusadas;
