import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BoxScroll from 'shared/components/box/BoxScroll';
import { RootState } from 'store/reducer';

import { OrigemNegociacao } from 'features/informacoesNegociacao/api/informacoesNegociacaoApiTypes';
import ChatCorpo from './ChatCorpo';
import {
  atualizarMensagensChatAberto,
  atualizarMensagensChatAbertoFreteTabelado,
  criarPollingMensagens,
  criarPollingMensagensFreteTabelado,
} from '../utils/chatNegociacao';
import ChatSemConversaSelecionada from './ChatSemConversaSelecionada';
import { fecharChat } from '../redux/chatNegociacaoAction';

type ChatNegociacaoProps = {
  altura: string;
};

const ChatNegociacao = ({ altura }: ChatNegociacaoProps): JSX.Element => {
  const dispatch = useDispatch();

  const { negociacao } = useSelector(
    (state: RootState) => state.informacoesNegociacaoReducer,
  );
  const { colaboradorAssistido } = useSelector(
    (state: RootState) => state.minhasNegociacoesReducer,
  );

  const {
    chatAberto,
    chatIdProposta,
    chatIdNegociacao,
    mensagemReferencia,
    chatPropostaStatus,
    chatMotivoRecusa,
  } = useSelector((state: RootState) => state.chatNegociacaoReducer);

  useEffect(() => {
    if (negociacao?.origemNegociacao === OrigemNegociacao.freteTabelado) {
      atualizarMensagensChatAbertoFreteTabelado(
        dispatch,
        chatAberto,
        chatIdNegociacao,
        chatIdProposta,
        chatMotivoRecusa,
      );
    } else {
      atualizarMensagensChatAberto(
        dispatch,
        chatAberto,
        chatIdNegociacao,
        chatIdProposta,
        chatMotivoRecusa,
        colaboradorAssistido[0]?.id,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    chatAberto,
    chatIdNegociacao,
    chatIdProposta,
    chatMotivoRecusa,
    chatPropostaStatus,
    dispatch,
  ]);

  useEffect(() => {
    let referencia:
      | ReturnType<typeof criarPollingMensagensFreteTabelado>
      | ReturnType<typeof criarPollingMensagens>;

    if (negociacao?.origemNegociacao === OrigemNegociacao.freteTabelado) {
      referencia = criarPollingMensagensFreteTabelado(
        dispatch,
        chatAberto,
        chatIdNegociacao,
        chatIdProposta,
        chatMotivoRecusa,
      );
    } else {
      referencia = criarPollingMensagens(
        dispatch,
        chatAberto,
        chatIdNegociacao,
        chatIdProposta,
        chatMotivoRecusa,
        colaboradorAssistido[0]?.id,
      );
    }

    return () => {
      clearInterval(referencia);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    chatAberto,
    chatIdNegociacao,
    chatIdProposta,
    chatMotivoRecusa,
    chatPropostaStatus,
    mensagemReferencia?.remetente,
    dispatch,
  ]);

  useEffect(() => {
    return () => {
      dispatch(fecharChat());
    };
  }, [dispatch]);

  return (
    <>
      {!chatIdNegociacao || !chatIdProposta ? (
        <ChatSemConversaSelecionada />
      ) : (
        <BoxScroll height={altura} maxHeight={altura} position="relative">
          <ChatCorpo />
        </BoxScroll>
      )}
    </>
  );
};

export default ChatNegociacao;
