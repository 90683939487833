import React, { ReactNode } from 'react';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MUISelect from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core';

export type SelectProps = {
  id: string;
  label: string;
  children: ReactNode;
  value: string;
  error?: boolean;
  mensagemErro?: string;
  onBlur?: () => void;
  onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  disabled?: boolean;
  size?: 'small' | 'medium';
  isAutomatizado?: boolean;
};

const useStyles = makeStyles(() => ({
  texto: {
    fontWeight: 400,
    textAlign: 'left',
  },
}));

const Select = ({
  id,
  label,
  children,
  value,
  error,
  mensagemErro,
  onChange,
  onBlur,
  disabled,
  size,
}: SelectProps): JSX.Element => {
  const classes = useStyles();
  return (
    <FormControl fullWidth variant="outlined" error={error} size={size}>
      <InputLabel className={classes.texto} htmlFor={id}>
        {label}
      </InputLabel>
      <MUISelect
        label={label}
        value={value}
        className={classes.texto}
        onChange={onChange}
        onBlur={onBlur}
        inputProps={{
          id,
        }}
        disabled={disabled}
        fullWidth
      >
        {children}
      </MUISelect>
      <FormHelperText>{mensagemErro}</FormHelperText>
    </FormControl>
  );
};

Select.defaultProps = {
  error: false,
  mensagemErro: '',
  onBlur: undefined,
  size: undefined,
};

export default Select;
