import CampoOrdenacaoDemandasAndamento from './CampoOrdenacaoDemandasAndamento';

export type ItemHeaderTabelaDemandasAndamento = {
  titulo: string;
  subtitulo?: string;
  ordenacao?: boolean;
  crescente?: boolean;
};

export const itemOrdenacaoFilial: ItemHeaderTabelaDemandasAndamento = {
  titulo: CampoOrdenacaoDemandasAndamento.Filial,
  ordenacao: true,
  crescente: false,
};

export const itemOrdenacaoNegociador: ItemHeaderTabelaDemandasAndamento = {
  titulo: CampoOrdenacaoDemandasAndamento.Negociador,
  ordenacao: true,
  crescente: false,
};

export const itemOrdenacaoDataCarregamento: ItemHeaderTabelaDemandasAndamento = {
  titulo: CampoOrdenacaoDemandasAndamento.DataCarregamento,
  ordenacao: true,
  crescente: false,
};

export const itensHeaderTabelaDemandasAndamento: ItemHeaderTabelaDemandasAndamento[] = [
  itemOrdenacaoFilial,
  itemOrdenacaoNegociador,
  itemOrdenacaoDataCarregamento,
  {
    titulo: 'Romaneio',
  },
  {
    titulo: 'Peso',
  },
  {
    titulo: 'Motorista',
  },
  {
    titulo: 'Veiculo',
  },
  {
    titulo: 'Valor Finalizado',
  },
  {
    titulo: 'Valor Carga',
  },
  {
    titulo: 'Km Rota',
  },
  {
    titulo: 'M³',
  },
  {
    titulo: 'Entregas',
  },
  {
    titulo: 'Última Proposta',
  },
  {
    titulo: 'Qtd. Proposta',
  },
  {
    titulo: 'R$ Entrega',
  },
  {
    titulo: 'R$ Peso',
  },
  {
    titulo: 'R$ KM',
  },
  {
    titulo: 'Custo Relativo',
  },
  {
    titulo: 'Custo Target',
  },
  {
    titulo: '% Saving',
  },
  {
    titulo: 'Cidade Destino',
  },
];
