import React from 'react';

import CustomSkeleton from '../skeleton/CustomSkeleton';
import { useEstiloLinhaDoTempo } from './SkeletonLinhaDoTempo';

const SkeletonCabecalhoLinhaDoTempo = (): JSX.Element => {
  const classes = useEstiloLinhaDoTempo();

  return (
    <div className={classes.container}>
      <CustomSkeleton
        width="50%"
        height={24}
        borderradius={16}
        key="skeleton-titulo-linha-tempo"
      />
      <div className={classes.containerBadges}>
        {new Array(3).fill(null).map((_, index) => (
          <CustomSkeleton
            width="30%"
            height={26}
            borderradius={16}
            key={`skeleton-badge-linha-tempo-${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
};

export default SkeletonCabecalhoLinhaDoTempo;
