const TAMANHO_CODIGO = 5;

export const obterRomaneioFormatado = (
  romaneio: string | undefined,
  quantidadeMaximaRomaneios: number,
): string => {
  if (!romaneio) {
    return '';
  }

  const arrayRomaneios = romaneio.split('/');

  return arrayRomaneios.length > quantidadeMaximaRomaneios
    ? `${arrayRomaneios.slice(0, quantidadeMaximaRomaneios).join('/')}...`
    : romaneio;
};

export const obterCodigoFormatado = (
  romaneio: string | undefined,
  quantidadeMaximaCodigos: number | undefined = undefined,
): string => {
  if (!romaneio) {
    return '';
  }

  const arrayRomaneios = romaneio.split('/');
  const arrayCodigos = arrayRomaneios.map((r) =>
    r.includes('-')
      ? r.substr(r.length - TAMANHO_CODIGO - 2)
      : r.substr(r.length - TAMANHO_CODIGO),
  );

  return quantidadeMaximaCodigos &&
    arrayCodigos.length > quantidadeMaximaCodigos
    ? `${arrayCodigos.slice(0, quantidadeMaximaCodigos).join('/')}...`
    : arrayCodigos.join('/');
};
