import { makeStyles } from '@material-ui/core';
import spacingInPixels from '../../../shared/functions/materialUtils';

export const useStyles = makeStyles((theme) => ({
  balao: {
    maxWidth: 440,
    position: 'relative',
    borderRadius: spacingInPixels(theme, 1),
    padding: '8px 16px 2px 16px',
    marginBottom: spacingInPixels(theme, 1),
    marginTop: spacingInPixels(theme, 1),
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      width: 0,
      height: 0,
    },
  },
  balaoNegociador: {
    marginRight: spacingInPixels(theme, 2),
    background: theme.palette.cores.azulMartins[100],
    boxShadow: `-2px 2px 2px 0px ${theme.palette.cores.cinza[900]}40`,
    '&:before': {
      right: spacingInPixels(theme, -1),
      borderTop: `32px solid ${theme.palette.cores.azulMartins[100]}`,
      borderRight: '24px solid transparent',
    },
  },
  balaoMotorista: {
    background: theme.palette.cores.branco,
    marginLeft: spacingInPixels(theme, 2),
    boxShadow: `2px 2px 2px 0px ${theme.palette.cores.cinza[900]}40`,
    '&:before': {
      left: spacingInPixels(theme, -1),
      borderTop: `32px solid ${theme.palette.cores.branco}`,
      borderLeft: '24px solid transparent',
    },
  },
  balaoIndicativo: {
    backgroundColor: theme.palette.cores.cinza[100],
    border: `1px solid ${theme.palette.cores.cinza[400]}`,
    marginInline: theme.spacing(2),
    borderRadius: 5,
    paddingTop: 0,
    paddingLeft: spacingInPixels(theme, 1),
    paddingRight: spacingInPixels(theme, 0.5),
  },
  balaoImagem: {
    width: 440,
  },
}));

export default useStyles;
