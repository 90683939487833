import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { AttachMoney, Check } from '@material-ui/icons';
import ModalComum from '../../../shared/components/modalComum/ModalComum';
import InputRubik from '../../../shared/components/input/InputRubik';
import BotaoIconeTexto from '../../detalhesAprovacaoSupervisor/components/Botoes/BotaoIconeTexto';
import BotaoOutlineIconeTexto from '../../detalhesAprovacaoSupervisor/components/Botoes/BotaoOutlineIconeTexto';
import theme from '../../../theme';
import EditarValorContrapropostaBadges from '../../../shared/components/modalComum/EditarValorContrapropostaBadges';
import TipoValorContraproposta from '../constants/TipoValorContraproposta';
import {
  Negociacao,
  Carga,
  Filial,
} from '../../detalhesAprovacaoSupervisor/api/detalhesAprovacaoSupervisorTypes';
import BoxInfoFilial from '../../detalhesAprovacaoSupervisor/components/boxInfo/BoxInfoFilial';

interface Props {
  modalOpen: boolean;
  onCloseModal: () => void;
  onConfirmModal: () => void;
  justificativa: string;
  setJustificativa: (texto: string) => void;
  valorNovaMeta: number | undefined;
  setValorNovaMeta: (valor: number | undefined) => void;
  negociacao: Negociacao;
  carga: Carga;
  filial: Filial;
  habilitarConfirmacao: boolean;
}

export const ModalPropor = ({
  modalOpen,
  onCloseModal,
  onConfirmModal,
  justificativa,
  setJustificativa,
  valorNovaMeta,
  setValorNovaMeta,
  negociacao,
  carga,
  filial,
  habilitarConfirmacao,
}: Props): JSX.Element => {
  const [valorInput, setValorInput] = useState<number | undefined>(undefined);
  const [tipoValor, setTipoValor] = useState<TipoValorContraproposta>(
    TipoValorContraproposta.ValorProposto,
  );

  return (
    <ModalComum
      modalAberto={modalOpen}
      fecharModal={onCloseModal}
      titulo="Propor nova meta"
      conteudo={
        <Box>
          <EditarValorContrapropostaBadges
            tipoValor={tipoValor}
            setTipoValor={setTipoValor}
            valorProposto={valorNovaMeta}
            setValorProposto={setValorNovaMeta}
            valorInput={valorInput}
            setValorInput={setValorInput}
            valorFreteTerceiro={negociacao.valorFrotaTerceiro}
            valorFrotaPropria={
              negociacao?.valorInicialSugeridoRobo
                ? negociacao?.valorInicialSugeridoRobo
                : negociacao.valorFrotaPropria
            }
            valorEntregas={
              carga.quantidadeEntregasPF + carga.quantidadeEntregasPJ
            }
            valorMercadoria={carga.valorMercadoria}
            valorPeso={carga.peso}
            valorQuilometragem={carga.distancia}
          />
          <Box
            style={{
              backgroundColor: theme.palette.cores.cinza[50],
              marginTop: 22,
              borderRadius: 8,
            }}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <BoxInfoFilial carga={carga} filial={filial} />
          </Box>
          <Box marginTop="24px">
            <InputRubik
              fullWidth
              multiline
              label="Justifique o valor"
              size="small"
              variant="outlined"
              value={justificativa}
              onChange={(e) => setJustificativa(e.target.value)}
              rows={4}
            />
          </Box>
        </Box>
      }
      acoes={
        <Box
          display="flex"
          flex={1}
          flexDirection="row"
          justifyContent="space-between"
          gridRowGap={16}
          marginLeft={1.5}
          marginRight={1.5}
          marginTop="32px"
          marginBottom="32px"
        >
          <BotaoOutlineIconeTexto
            icon={<AttachMoney />}
            color={theme.palette.cores.azulMartins[500]}
            onClick={onCloseModal}
          >
            Cancelar
          </BotaoOutlineIconeTexto>
          <BotaoIconeTexto
            icon={<Check />}
            color={theme.palette.cores.branco}
            backgroundColor={theme.palette.cores.azulMartins[500]}
            onClick={onConfirmModal}
            desabilitado={!habilitarConfirmacao}
          >
            Propor nova meta
          </BotaoIconeTexto>
        </Box>
      }
    />
  );
};
