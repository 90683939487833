import React, { ReactElement } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { spacingInPixels } from '../../../../shared/functions/materialUtils';

const useStyles = makeStyles((theme) => ({
  titulo: {
    marginLeft: spacingInPixels(theme, 1),
    fontSize: '14px',
    fontWeight: 500,
  },
}));

type XsGrid = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

type HeaderItemProps = {
  xs: XsGrid;
  titulo: string;
  icone: ReactElement;
};

const HeaderItem = ({ xs, titulo, icone }: HeaderItemProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Grid item xs={xs}>
      <Box display="flex" alignItems="center">
        {icone}
        <Typography className={classes.titulo} color="primary">
          {titulo}
        </Typography>
      </Box>
    </Grid>
  );
};

export default HeaderItem;
