import React from 'react';
import { makeStyles } from '@material-ui/core';

import Porcentagem from 'shared/components/porcentagem/Porcentagem';
import spacingInPixels from 'shared/functions/materialUtils';
import estilosTabela from './styles/estilosTabela';
import CustomTooltip from '../tooltip/CustomTooltip';

const useStyles = makeStyles((theme) => ({
  containerCelula: {
    display: 'flex',
    gap: spacingInPixels(theme, 1),
  },
  textoCelula: {
    width: 'fit-content',
    '@media (max-width: 1326px)': {
      width: '80%',
    },
  },
  badgePorcentagem: {
    margin: 0,
    borderRadius: spacingInPixels(theme, 2),
    padding: `${spacingInPixels(theme, 0.5)}`,
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 1326px)': {
      display: 'none',
    },
  },
  textoPorcentagem: {
    fontSize: 10,
    lineHeight: 'initial',
  },
}));

export interface CelulaConteudoTextoComPorcentagemProps {
  texto: string;
  porcentagem?: string;
  complemento?: string;
}

const CelulaConteudoTextoComPorcentagem = ({
  texto,
  porcentagem,
  complemento,
}: CelulaConteudoTextoComPorcentagemProps): JSX.Element => {
  const classes = useStyles();
  const { fonteConteudo } = estilosTabela();
  const descricao = `${texto} ${porcentagem} ${complemento}`.replaceAll(
    'undefined',
    '',
  );

  return (
    <CustomTooltip title={descricao}>
      <td className={classes.containerCelula}>
        <span className={`${fonteConteudo} ${classes.textoCelula}`}>
          {texto}
        </span>
        {porcentagem && (
          <Porcentagem
            styles={classes.badgePorcentagem}
            stylesPorcentagem={classes.textoPorcentagem}
            stylesComplemento={classes.textoPorcentagem}
            porcentagem={porcentagem}
            complemento={complemento}
          />
        )}
      </td>
    </CustomTooltip>
  );
};

export default CelulaConteudoTextoComPorcentagem;
