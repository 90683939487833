import { makeStyles } from '@material-ui/core';
import { Rubik } from 'shared/constants/font';

export const useStyles = makeStyles(() => ({
  textoSupervisor: {
    margin: '2px 0px 0px 8px',
    fontFamily: Rubik,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '17px',
    '& b': {
      fontWeight: 700,
    },
  },
}));
