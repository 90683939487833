import { makeStyles } from '@material-ui/core';
import CardPropostaGreguimSugerido from 'features/aprovacoesSupervisor/views/cardsProposta/CardPropostaGreguimSugerido';
import React from 'react';
import { formatarNumeroParaStringMonetariaComSimbolo } from 'shared/functions/dinheiroUtils';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    padding: '8px 0 8px 16px',
  },
  textoValor: {
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '28px',
  },
  textoInformacao: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.cores.cinza[700],
  },
  textoValorCarga: {
    textDecorationLine: 'line-through',
    color: theme.palette.cores.vermelho[400],
  },
  textoValorRobo: {
    color: theme.palette.cores.verde[700],
  },
}));

export type ValorPropostaProps = {
  valorProposta?: number;
  valorSugeridoRobo?: boolean;
};

const ValorProposta = ({
  valorProposta,
  valorSugeridoRobo,
}: ValorPropostaProps): JSX.Element => {
  const classes = useStyles();

  const valorFormatado = formatarNumeroParaStringMonetariaComSimbolo(
    valorProposta,
  );

  return (
    <section className={classes.container}>
      <div
        className={`${classes.textoValor} ${
          valorSugeridoRobo ? classes.textoValorRobo : classes.textoValorCarga
        }`}
      >
        {valorFormatado}
      </div>
      {!valorSugeridoRobo ? (
        <CardPropostaGreguimSugerido />
      ) : (
        <div className={classes.textoInformacao}>Valor negociado</div>
      )}
    </section>
  );
};

export default ValorProposta;
