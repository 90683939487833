import AprovacaoSupervisorSlider from 'features/configuracoes/components/AprovacaoSupervisorSlider';
import { ContainerPermissoes, SubTitulo } from 'features/configuracoes/styles';
import { ContainerPermissoesPrivilegioProps } from 'features/configuracoes/types';
import React from 'react';
import SwitchAcesso from 'shared/components/switch/SwitchAcesso';

const ContainerPermissoesPrivilegio = ({
  configuracoes,
  acessoAprovacaoNovosValores,
  possuiSupervisoENegociador,
  setAcessoConfiguracaoPerfil,
  setAcessoNegociacaoCargas,
  setAcessoAprovacaoNovosValores,
  setValorMaximo,
  setAcessoTorreControle,
  setAcessoNegociacaoAutomatizadas,
  setAcessoUploadExcelCargaSimulador,
  handleTrocaMaximo,
}: ContainerPermissoesPrivilegioProps): JSX.Element => {
  return (
    <ContainerPermissoes>
      <SubTitulo>Privilégio de perfis</SubTitulo>
      <SwitchAcesso
        titulo="Acesso para configurar perfil"
        permissaoAcesso={configuracoes.acessoConfiguracaoPerfil}
        setPermissaoAcesso={setAcessoConfiguracaoPerfil}
        desabilitado={possuiSupervisoENegociador()}
      />
      <SwitchAcesso
        titulo="Acesso para negociar carga"
        permissaoAcesso={configuracoes.acessoNegociacaoCargas}
        setPermissaoAcesso={setAcessoNegociacaoCargas}
        desabilitado={possuiSupervisoENegociador()}
      />
      <SwitchAcesso
        titulo="Acesso para aprovar novos valores"
        permissaoAcesso={acessoAprovacaoNovosValores}
        setPermissaoAcesso={setAcessoAprovacaoNovosValores}
        setValorMaximo={setValorMaximo}
        desabilitado={possuiSupervisoENegociador()}
      />
      {acessoAprovacaoNovosValores && (
        <AprovacaoSupervisorSlider
          valoresLabel={[0, 100, 200]}
          valorInicial={0}
          valorMaximo={200}
          intervalo={5}
          minimoValorAceito={100}
          valor={configuracoes?.percentualMaximoAprovacao || 200}
          setValor={handleTrocaMaximo}
        />
      )}
      <SwitchAcesso
        titulo="Acesso para torre de controle"
        permissaoAcesso={configuracoes.acessoTorreControle}
        setPermissaoAcesso={setAcessoTorreControle}
        desabilitado={possuiSupervisoENegociador()}
      />
      <SwitchAcesso
        titulo="Acesso para ver negociações automatizadas"
        permissaoAcesso={configuracoes.acessoFreteTabelado}
        setPermissaoAcesso={setAcessoNegociacaoAutomatizadas}
        desabilitado={possuiSupervisoENegociador()}
      />
      <SwitchAcesso
        titulo="Acesso para upload de cargas"
        permissaoAcesso={configuracoes.acessoSimuladorPlanilha}
        setPermissaoAcesso={setAcessoUploadExcelCargaSimulador}
        desabilitado={possuiSupervisoENegociador()}
      />
    </ContainerPermissoes>
  );
};

export default ContainerPermissoesPrivilegio;
