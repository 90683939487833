import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';
import EmptyPage from 'shared/components/emptyPage/EmptyPage';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: spacingInPixels(theme, 2.5),
    '& article': {
      paddingBottom: 0,
      paddingTop: spacingInPixels(theme, 3),
    },
    '& article > p': {
      maxWidth: '20em',
      marginTop: spacingInPixels(theme, 3),
      marginBottom: spacingInPixels(theme, 0.5),
    },
    '& button.MuiButton-root:hover': {
      backgroundColor: `${theme.palette.cores.azul[600]}D9`,
    },
  },
  botaoTenteNovamente: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: spacingInPixels(theme, 1.75),
    lineHeight: spacingInPixels(theme, 2.5),

    backgroundColor: theme.palette.cores.azul[600],
    color: theme.palette.cores.branco,
    textTransform: 'none',
    maxWidth: 'fit-content',
    height: spacingInPixels(theme, 4.5),
    borderRadius: spacingInPixels(theme, 2),
    padding: `0 ${spacingInPixels(theme, 1.5)}`,
    margin: '0 auto',
  },
}));

export interface ConteudoErroProps {
  mensagem: string;
  onClickBotao?: () => void;
}

const ConteudoErro = ({
  mensagem,
  onClickBotao,
}: ConteudoErroProps): JSX.Element => {
  const classes = useStyles();

  return (
    <section className={classes.container}>
      <EmptyPage texto={mensagem} />
      {onClickBotao && (
        <Button
          onClick={() => onClickBotao?.()}
          className={classes.botaoTenteNovamente}
        >
          Tente novamente
        </Button>
      )}
    </section>
  );
};

export default ConteudoErro;
