import { useTheme } from '@material-ui/core';
import * as React from 'react';
import { SVGProps } from 'react';

const IconeFechar = ({
  fill,
  width = 20,
  height = 20,
}: SVGProps<SVGSVGElement>): JSX.Element => {
  const theme = useTheme();

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.1749 10.0005L14.7583 6.42548C14.9152 6.26856 15.0034 6.05573 15.0034 5.83381C15.0034 5.6119 14.9152 5.39907 14.7583 5.24215C14.6014 5.08523 14.3885 4.99707 14.1666 4.99707C13.9447 4.99707 13.7319 5.08523 13.575 5.24215L9.99995 8.82548L6.42495 5.24215C6.26803 5.08523 6.0552 4.99707 5.83328 4.99707C5.61136 4.99707 5.39854 5.08523 5.24162 5.24215C5.0847 5.39907 4.99654 5.6119 4.99654 5.83381C4.99654 6.05573 5.0847 6.26856 5.24162 6.42548L8.82495 10.0005L5.24162 13.5755C5.16351 13.653 5.10151 13.7451 5.05921 13.8467C5.0169 13.9482 4.99512 14.0571 4.99512 14.1671C4.99512 14.2772 5.0169 14.3861 5.05921 14.4876C5.10151 14.5892 5.16351 14.6813 5.24162 14.7588C5.31908 14.8369 5.41125 14.8989 5.5128 14.9412C5.61435 14.9835 5.72327 15.0053 5.83328 15.0053C5.94329 15.0053 6.05221 14.9835 6.15376 14.9412C6.25531 14.8989 6.34748 14.8369 6.42495 14.7588L9.99995 11.1755L13.575 14.7588C13.6524 14.8369 13.7446 14.8989 13.8461 14.9412C13.9477 14.9835 14.0566 15.0053 14.1666 15.0053C14.2766 15.0053 14.3855 14.9835 14.4871 14.9412C14.5886 14.8989 14.6808 14.8369 14.7583 14.7588C14.8364 14.6813 14.8984 14.5892 14.9407 14.4876C14.983 14.3861 15.0048 14.2772 15.0048 14.1671C15.0048 14.0571 14.983 13.9482 14.9407 13.8467C14.8984 13.7451 14.8364 13.653 14.7583 13.5755L11.1749 10.0005Z"
        fill={fill || theme.palette.cores.cinza[600]}
      />
    </svg>
  );
};

export default IconeFechar;
