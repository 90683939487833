import { Negociacao, Motorista } from '../api/minhasNegociacoesApiTypes';
import NegociacaoStatus, {
  verificarNegociacaoEmAndamento,
} from '../../../shared/constants/NegociacaoStatus';
import {
  converterDataApi,
  obterDiferencaDatas,
} from '../../../shared/functions/dataUtils';

const ordenacaoStatus = [
  NegociacaoStatus.EmAndamento,
  NegociacaoStatus.NovaNegociacao,
  NegociacaoStatus.Cancelada,
  NegociacaoStatus.Concluida,
  NegociacaoStatus.AguardarGestao,
];

const obterDataPropostaMaisAntiga = (listaMotoristas: Motorista[]): Date => {
  [...listaMotoristas].sort((a, b) =>
    obterDiferencaDatas(
      converterDataApi(a.dataUltimaModificacao),
      converterDataApi(b.dataUltimaModificacao),
    ),
  );
  return converterDataApi(listaMotoristas[0].dataUltimaModificacao);
};

const ordenarPorDataMaisAntiga = (
  listaNegociacoes: Negociacao[],
): Negociacao[] =>
  [...listaNegociacoes].sort((a, b) =>
    obterDiferencaDatas(
      obterDataPropostaMaisAntiga(a.motoristasEnvolvidos),
      obterDataPropostaMaisAntiga(b.motoristasEnvolvidos),
    ),
  );

const obterNegociacoesEmAndamentoComProposta = (
  listaNegociacoes: Negociacao[],
): Negociacao[] =>
  [...listaNegociacoes].filter(
    (negociacao) =>
      verificarNegociacaoEmAndamento(negociacao.status) &&
      negociacao.motoristasEnvolvidos.length,
  );

const ordenarNegociacoesPorStatus = (
  listaNegociacoes: Negociacao[],
): Negociacao[] =>
  [...listaNegociacoes].sort(
    (a, b) =>
      ordenacaoStatus.indexOf(a.status) - ordenacaoStatus.indexOf(b.status),
  );

export const ordenarListaNegociacoes = (
  listaNegociacoes: Negociacao[],
): Negociacao[] => {
  const listaEmAndamento = ordenarPorDataMaisAntiga(
    obterNegociacoesEmAndamentoComProposta(listaNegociacoes),
  );

  const listaRestante = listaNegociacoes.filter(
    (negociacao) =>
      !negociacao.motoristasEnvolvidos.length ||
      !verificarNegociacaoEmAndamento(negociacao.status),
  );

  const listaRestanteOrdenada = ordenarNegociacoesPorStatus(listaRestante);

  return listaEmAndamento.concat(listaRestanteOrdenada);
};
