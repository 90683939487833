import React from 'react';
import { IntervaloTempo } from 'shared/types/apiTypes';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { formatarDias, formatarHorario } from 'shared/functions/IntervaloTempo';
import spacingInPixels from '../../../shared/functions/materialUtils';

export type BadgeIntervaloTempoType = {
  intervalo: IntervaloTempo;
  icone: JSX.Element;
  backgroundColor?: string;
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: ({ backgroundColor }: { backgroundColor?: string }) =>
      backgroundColor || theme.palette.cores.cinza[300],
    borderRadius: spacingInPixels(theme, 2),
    padding: spacingInPixels(theme, 0.8),
  },
  texto: {
    fontSize: '12px',
    fontWeight: 400,
    color: theme.palette.cores.cinza[900],
    marginLeft: spacingInPixels(theme, 1),
  },
}));

const BadgeIntervaloTempo = ({
  intervalo,
  icone,
  backgroundColor,
}: BadgeIntervaloTempoType): JSX.Element => {
  const classes = useStyles({ backgroundColor });
  const { dias, horas, minutos, segundos } = intervalo;

  return (
    <Box className={classes.container}>
      {icone}
      <Typography className={classes.texto}>
        {formatarDias(dias)}
        {formatarHorario(horas)}:{formatarHorario(minutos)}:
        {formatarHorario(segundos)}
      </Typography>
    </Box>
  );
};

export default BadgeIntervaloTempo;
