import React from 'react';
import { Grid, makeStyles, useTheme } from '@material-ui/core';
import IconeExclamacaoOctogono from 'assets/icones/IconeExclamacaoOctogono';
import IconeCaixa from 'assets/icones/IconeCaixa';
import IconeRelogio from 'assets/icones/IconeRelogio';
import IconeCorretoQuadrado from 'assets/icones/IconeCorretoQuadrado';
import IconeBalaoChat from 'assets/icones/IconeBalaoChat';
import BigNumberSimples from 'shared/components/bigNumber/BigNumberSimples';

const useStyles = makeStyles(() => ({
  containerMetricas: {
    '& > div': {
      width: '18%',
    },
  },
}));

export type MetricasDemanda = {
  chave: string;
  valor?: number;
};

export interface MetricasDemandasProps {
  metricasDemandas?: MetricasDemanda[];
}

const MetricasDemandasFilial = ({
  metricasDemandas,
}: MetricasDemandasProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();

  const dadosDemandas: {
    [chave: string]: { icone: JSX.Element; cor: string; descricao: string };
  } = {
    DemandasDoDia: {
      icone: <IconeCaixa width={15} height={15} />,
      cor: theme.palette.cores.azul[500] as string,
      descricao: 'Demandas do dia',
    },
    NaoIniciadas: {
      icone: <IconeRelogio transform="scale(-1,1)" width={15} height={15} />,
      cor: theme.palette.cores.mostarda[800] as string,
      descricao: 'Não Iniciadas',
    },
    Negociadas: {
      icone: <IconeCorretoQuadrado width={15} height={15} />,
      cor: theme.palette.cores.verdeClaro[500] as string,
      descricao: 'Negociadas',
    },
    EmNegociacao: {
      icone: <IconeBalaoChat width={15} height={15} />,
      cor: theme.palette.cores.azulPiscina[400] as string,
      descricao: 'Em Negociação',
    },
    Aprovacao: {
      icone: <IconeExclamacaoOctogono width={15} height={15} />,
      cor: theme.palette.cores.azulPiscina[900] as string,
      descricao: 'Aprovação',
    },
  };

  return (
    <Grid
      container
      className={classes.containerMetricas}
      justify="space-between"
    >
      {metricasDemandas?.map(({ chave, valor }) => {
        const demanda = { ...dadosDemandas[chave], valor };
        return (
          <Grid item key={`big-number-${demanda.descricao}`}>
            <BigNumberSimples
              icone={demanda.icone}
              cor={demanda.cor}
              numero={demanda.valor}
              descricao={demanda.descricao}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default MetricasDemandasFilial;
