import { Box, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { estilosTabelaNegociadores } from './styles/estilosTabelaNegociadores';

const useStyles = makeStyles(() => ({
  informacaoNegociador: {
    display: 'flex',
    gap: '12px',
    paddingLeft: '16px',
    paddingTop: '6px',
  },
  prioridadeCarga: {
    display: 'flex',
    paddingLeft: '1.25vw',
    '& > span': {
      marginRight: '5px',
    },
  },
  statusDemanda: {
    display: 'flex',
    width: '75%',
    justifyContent: 'space-between',
  },
  negociacaoPorHora: {
    paddingLeft: '20%',
  },
}));
const SkeletonItemTabelaNegociadores = (): JSX.Element => {
  const classes = useStyles();
  const { gridTabela } = estilosTabelaNegociadores();

  return (
    <Box className={gridTabela}>
      <section className={classes.informacaoNegociador}>
        <Skeleton variant="circle" width={34} height={34} />
        <Box>
          <Skeleton width="140px" height={20} />
          <Skeleton width="80px" height={20} />
        </Box>
      </section>

      <section className={classes.prioridadeCarga}>
        {new Array(3).fill(null).map((_, index) => (
          <Skeleton
            width={34}
            height={44}
            key={`skeleton-prioridade-carga-${index + 1}`}
          />
        ))}
      </section>

      <section className={classes.statusDemanda}>
        {new Array(5).fill(null).map((_, index) => (
          <Skeleton
            width="18%"
            height={44}
            key={`skeleton-status-demanda-${index + 1}`}
          />
        ))}
      </section>

      <section className={classes.negociacaoPorHora}>
        <Skeleton width={42} height={44} />
      </section>

      <Skeleton width={100} height={44} />
    </Box>
  );
};

export default SkeletonItemTabelaNegociadores;
