import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackRounded from '@material-ui/icons/ArrowBackRounded';

import { spacingInPixels } from '../../../shared/functions/materialUtils';
import DisablingTooltip from '../../../shared/components/tooltip/DisablingTooltip';

type TituloProps = {
  titulo: string;
  voltar: () => void;
};

const useStyles = makeStyles((theme) => ({
  titulo: {
    fontSize: '20px',
  },
  icone: {
    padding: spacingInPixels(theme, 0.5),
    margin: theme.spacing(0, 1, 0, -0.5),
  },
}));

const Titulo = ({ titulo, voltar }: TituloProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center">
      <DisablingTooltip title="Voltar">
        <IconButton
          aria-label="Voltar"
          onClick={voltar}
          className={classes.icone}
        >
          <ArrowBackRounded />
        </IconButton>
      </DisablingTooltip>
      <Typography className={classes.titulo} variant="h4">
        {titulo}
      </Typography>
    </Box>
  );
};

export default Titulo;
