import IconeCaixa from 'assets/icones/IconeCaixa';
import IconeExclamacaoOctogono from 'assets/icones/IconeExclamacaoOctogono';
import IconeRelogio from 'assets/icones/IconeRelogio';
import React from 'react';
import CampoOrdenacaoDemandasNegociador from './CampoOrdenacaoTabelaDemandasNegociador';

export type TipoItemHeaderTabelaDemandasNegociadorAutomatizadas = {
  titulo: string;
  icone?: JSX.Element;
  ordenacao?: boolean;
  crescente?: boolean;
};

export const ItemOrdenacaoFilial: TipoItemHeaderTabelaDemandasNegociadorAutomatizadas = {
  titulo: CampoOrdenacaoDemandasNegociador.Filial,
  ordenacao: true,
  crescente: false,
};

export const ItemOrdencacaoEntregas: TipoItemHeaderTabelaDemandasNegociadorAutomatizadas = {
  titulo: CampoOrdenacaoDemandasNegociador.Entregas,
  icone: <IconeCaixa />,
  ordenacao: true,
  crescente: false,
};

const ItensHeaderTabelaDemandasNegociadorAutomatizadas: TipoItemHeaderTabelaDemandasNegociadorAutomatizadas[] = [
  {
    titulo: '',
  },
  {
    titulo: 'SLA',
  },
  ItemOrdenacaoFilial,
  ItemOrdencacaoEntregas,
  {
    titulo: '',
  },
  {
    titulo: 'Alvo',
  },
  {
    titulo: 'Cycle Time',
    icone: <IconeRelogio />,
  },
  {
    titulo: 'Pedidos de Aprovação',
    icone: <IconeExclamacaoOctogono />,
  },
  {
    titulo: 'Cidade',
  },
  {
    titulo: 'Romaneio',
  },
];

export default ItensHeaderTabelaDemandasNegociadorAutomatizadas;
