import { Divider, makeStyles } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { useLoading } from 'shared/customHooks/useLoading';

import spacingInPixels from 'shared/functions/materialUtils';
import ModalComum from 'shared/components/modalComum/ModalComum';
import { NegociacaoTipoAlerta } from 'features/dashboards/negociadores/api/listagemNegociadoresApiTypes';

import { buscarDadosPermissaoUsuario } from 'features/login/redux/loginOperation';
import { DemandaDiaria as DemandaDiariaType } from '../api/demandaDiariaApiTypes';
import {
  buscarDemandaDiaria,
  criarPollingDemandaDiaria,
} from '../utils/demandaDiaria';

import QuadroBigNumbersAlerta from './QuadroBigNumbersAlerta';
import ConteudoModalDetalhes from './modalAlertas/ConteudoModalAlertas';
import QuadroTotalDemandas from './totalDemandas/QuadroTotalDemandas';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '70% 2% 25%',
    columnGap: '1em',
    alignItems: 'flex-start',
    background: theme.palette.cores.branco,
    padding: spacingInPixels(theme, 2),
    borderRadius: spacingInPixels(theme, 1),
    border: `1px solid ${theme.palette.cores.cinza['400']}80`,
    maxHeight: 'max-content',
    '& > hr': {
      margin: 'auto',
    },
  },
  bigNumbersContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  cabecalho: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardData: {
    borderRadius: spacingInPixels(theme, 0.7),
    padding: spacingInPixels(theme, 0.6),
    border: `1px solid ${theme.palette.cores.cinza['400']}80`,
    height: 'fit-content',
  },
  iconeCalendario: {
    color: theme.palette.cores.azul[600],
    width: 20,
    height: 20,
  },
}));

const useStylesModalAlertas = makeStyles((theme) => ({
  paper: {
    minWidth: 1200,
    height: '90vh',
    '& div.MuiDialogContent-root': {
      padding: `0 ${spacingInPixels(theme, 5.25)} ${spacingInPixels(theme, 2)}`,
    },
    '& button.MuiButtonBase-root[aria-label="close"]': {
      top: spacingInPixels(theme, 4),
      right: spacingInPixels(theme, 4.5),
    },
    '@media (max-width: 1200px)': {
      minWidth: '90vw',
    },
  },
  titulo: {},
}));

const DemandaDiaria = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [demandasDiarias, setDemandasDiarias] = useState<DemandaDiariaType>();
  const [referenciaRefresh, setReferenciaRefresh] = useState<number>();
  const [modalAberto, setModalAberto] = useState(false);
  const [
    categoriaSelecionada,
    setCategoriaSelecionada,
  ] = useState<NegociacaoTipoAlerta>(NegociacaoTipoAlerta.naoVista);

  useEffect(() => {
    buscarDadosPermissaoUsuario()(dispatch);
  }, [dispatch]);

  const handleClickCardAlerta = (categoria: NegociacaoTipoAlerta) => {
    setCategoriaSelecionada(categoria);
    setModalAberto(true);
  };

  const buscar = useCallback(
    async () => buscarDemandaDiaria(setDemandasDiarias, dispatch),
    [setDemandasDiarias, dispatch],
  );

  const [carregando, buscarDemandas] = useLoading(buscar);

  useEffect(() => {
    buscarDemandas();
    setReferenciaRefresh(
      criarPollingDemandaDiaria(setDemandasDiarias, dispatch),
    );
  }, [dispatch, buscarDemandas]);

  useEffect(() => {
    return () => {
      clearInterval(referenciaRefresh);
    };
  }, [referenciaRefresh]);

  return (
    <>
      <section className={classes.container}>
        <QuadroTotalDemandas
          carregando={carregando}
          demandasDiarias={demandasDiarias}
        />
        <Divider orientation="vertical" />

        <QuadroBigNumbersAlerta
          carregando={carregando}
          demandasDiarias={demandasDiarias}
          onClickAlerta={handleClickCardAlerta}
        />
      </section>
      <ModalComum
        conteudo={
          <ConteudoModalDetalhes
            demandasDiarias={demandasDiarias}
            categoriaAtual={categoriaSelecionada}
          />
        }
        modalAberto={modalAberto}
        fecharModal={() => setModalAberto(false)}
        estilo={useStylesModalAlertas()}
        exibirBotaoFechar
      />
    </>
  );
};

export default DemandaDiaria;
