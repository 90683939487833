import React from 'react';
import { makeStyles } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';
import { NegociacaoAtendimento } from 'features/indicadores/api/indicadoresApiTypes';
import {
  mensagemErro,
  mensagemListaVazia,
} from 'features/indicadores/constants/Constants';
import ConteudoErro from 'features/indicadores/components/ConteudoErro';
import GraficoAtendimento from './GraficoAtendimento';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacingInPixels(theme, 2.5),
  },
}));

interface ConteudoAtendimentoProps {
  erro: boolean;
  onClickTentarNovamente: () => void;
  indicadoresAtendimento: NegociacaoAtendimento[];
  valoresPeriodo: string[];
}

const ConteudoAtendimento = ({
  erro,
  onClickTentarNovamente,
  indicadoresAtendimento,
  valoresPeriodo,
}: ConteudoAtendimentoProps): JSX.Element => {
  const classes = useStyles();

  const handleExibicaoConteudo = () => {
    if (erro)
      return (
        <ConteudoErro
          onClickBotao={onClickTentarNovamente}
          mensagem={mensagemErro}
        />
      );
    if (indicadoresAtendimento.length === 0)
      return <ConteudoErro mensagem={mensagemListaVazia} />;
    return (
      <GraficoAtendimento
        periodo={valoresPeriodo}
        negociacoesAtendidasDentroPrazo={indicadoresAtendimento.map(
          (item) => item.quantidadeNegociacoesNoPrazo,
        )}
        negociacoesAtendidasForaPrazo={indicadoresAtendimento.map(
          (item) => item.quantidadeNegociacoesForaPrazo,
        )}
      />
    );
  };

  return (
    <section className={classes.container}>{handleExibicaoConteudo()}</section>
  );
};

export default ConteudoAtendimento;
