import { withStyles } from '@material-ui/core/styles';

import Tab from '@material-ui/core/Tab';

export default withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.cores.cinza[300],
    color: theme.palette.cores.cinza[900],
    textTransform: 'none',
    '&.Mui-selected': {
      backgroundColor: theme.palette.cores.azulMartins[500],
      color: theme.palette.cores.branco,
    },
    borderRadius: 24,
  },
}))(Tab);
