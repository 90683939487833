export enum TipoMensagemProposta {
  Inicial = 0,
  Contraproposta = 1,
  TextoLivre = 2,
  Aceite = 3,
  Recusa = 4,
  Arquivo = 5,
  ReinicioNegociacao = 6,
  AvaliacaoSupervisor = 7,
  AceiteSupervisor = 8,
  RecusaSupervisor = 9,
  NovaMetaSupervisor = 10,
  LiberacaoListaClientes = 11,
  BloqueioListaClientes = 12,
  AprovacaoConclusaoSupervisor = 13,
}

export type TipoMensagemPropostaType = TipoMensagemProposta;

export const verificarMensagemInicial = (
  tipoMensagem: TipoMensagemPropostaType | undefined,
): boolean => tipoMensagem === TipoMensagemProposta.Inicial;

export const verificarMensagemAceite = (
  tipoMensagem: TipoMensagemPropostaType | undefined,
): boolean => tipoMensagem === TipoMensagemProposta.Aceite;

export const verificarMensagemTextoLivre = (
  tipoMensagem: TipoMensagemPropostaType | undefined,
): boolean => tipoMensagem === TipoMensagemProposta.TextoLivre;

export const verificarMensagemArquivo = (
  tipoMensagem: TipoMensagemPropostaType | undefined,
): boolean => tipoMensagem === TipoMensagemProposta.Arquivo;

export const verificarReiniciarNegociacao = (
  tipoMensagem: TipoMensagemPropostaType | undefined,
): boolean => tipoMensagem === TipoMensagemProposta.ReinicioNegociacao;

export const verificarContraproposta = (
  tipoMensagem: TipoMensagemPropostaType | undefined,
): boolean => tipoMensagem === TipoMensagemProposta.Contraproposta;

export const verificarNovaMeta = (
  tipoMensagem: TipoMensagemPropostaType | undefined,
): boolean => tipoMensagem === TipoMensagemProposta.NovaMetaSupervisor;

export const verificarMensagemAceiteSupervisor = (
  tipoMensagem: TipoMensagemPropostaType | undefined,
): boolean => tipoMensagem === TipoMensagemProposta.AceiteSupervisor;

export const verificarMensagemRecusaSupervisor = (
  tipoMensagem: TipoMensagemPropostaType | undefined,
): boolean => tipoMensagem === TipoMensagemProposta.RecusaSupervisor;

export const verificarMensagemPedidoAprovacaoSupervisor = (
  tipoMensagem: TipoMensagemPropostaType | undefined,
): boolean => tipoMensagem === TipoMensagemProposta.AvaliacaoSupervisor;

export const verificarMensagemSupervisor = (
  tipoMensagem: TipoMensagemPropostaType | undefined,
): boolean =>
  verificarNovaMeta(tipoMensagem) ||
  verificarMensagemAceiteSupervisor(tipoMensagem) ||
  verificarMensagemRecusaSupervisor(tipoMensagem) ||
  verificarMensagemPedidoAprovacaoSupervisor(tipoMensagem);

export const verificarMensagemLiberacaoListaClientes = (
  tipoMensagem: TipoMensagemPropostaType | undefined,
): boolean => tipoMensagem === TipoMensagemProposta.LiberacaoListaClientes;

export const verificarMensagemBloqueioListaClientes = (
  tipoMensagem: TipoMensagemPropostaType | undefined,
): boolean => tipoMensagem === TipoMensagemProposta.BloqueioListaClientes;

export default TipoMensagemProposta;
