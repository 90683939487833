import React from 'react';
import { Box } from '@material-ui/core';

const DefaultBackground = (): JSX.Element => (
  <Box
    bgcolor="background.default"
    position="fixed"
    width="100%"
    height="100%"
    top={0}
    left={0}
    zIndex={-10}
  />
);

export default DefaultBackground;
