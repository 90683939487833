export const TITULO_PAGINA = document.title;

export const documentoEmFoco = (): boolean => document.hasFocus();

export const abrirEmNovaAba = (url: string): void => {
  const novaAba = window.open(url, '_parent', 'noopener,noreferrer');
  if (novaAba) novaAba.opener = null;
};

export const atualizarNotificacoesTitulo = (
  quantidadeNaoLidas: number,
): void => {
  if (quantidadeNaoLidas > 0) {
    document.title = `(${quantidadeNaoLidas}) ${TITULO_PAGINA}`;
  } else {
    document.title = `${TITULO_PAGINA}`;
  }
};
