import { TextField } from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';

export default withStyles((theme: Theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      fontWeight: 400,
      '& fieldset': {
        borderColor: theme.palette.secondary.main,
      },
      '&:not(.Mui-error):not(.Mui-disabled):hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
    '& .MuiInputLabel-root': {
      fontWeight: 400,
      '&:not(.Mui-error)': {
        color: theme.palette.secondary.main,
      },
      '&.Mui-disabled': {
        color: theme.palette.text.secondary,
      },
    },
    '& .MuiInputBase-root': {
      color: theme.palette.text.primary,
    },
    '& .Mui-focused': {
      borderColor: theme.palette.primary.main,
    },
  },
}))(TextField);
