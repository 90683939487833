import React from 'react';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import { RootState } from '../../../../store/reducer';

import { spacingInPixels } from '../../../../shared/functions/materialUtils';
import { Rubik } from '../../../../shared/constants/font';

const useStyles = makeStyles((theme) => ({
  tituloObservacao: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: spacingInPixels(theme, 3),
    fontFamily: Rubik,
  },
}));

const Label = withStyles((theme) => ({
  root: {
    marginLeft: 0,
    '& .MuiTypography-body1': {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: spacingInPixels(theme, 3),
      fontFamily: Rubik,
    },
  },
}))(FormControlLabel);

type SwitchObservacaoProps = {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const SwitchObservacao = ({
  checked,
  onChange,
}: SwitchObservacaoProps): JSX.Element => {
  const classes = useStyles();

  const { negociacaoConcluida } = useSelector(
    (state: RootState) => state.informacoesNegociacaoReducer,
  );

  const label = 'A carga tem observações?';
  const tooltip = checked ? 'Sim' : 'Não';

  return negociacaoConcluida ? (
    <Box display="flex" alignItems="flex-end">
      <Typography className={classes.tituloObservacao}>{label}</Typography>
    </Box>
  ) : (
    <Label
      control={
        <Tooltip title={tooltip}>
          <Switch
            name="possuiObservacao"
            color="primary"
            checked={checked}
            onChange={onChange}
          />
        </Tooltip>
      }
      label={label}
      className={classes.tituloObservacao}
      labelPlacement="start"
    />
  );
};

export default SwitchObservacao;
