import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import IconeCaixaVazia from 'assets/icones/IconeCaixaVazia';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/reducer';
import { abrirSnackbarAviso } from 'shared/components/snackbar/redux/snackbarAction';
import { atualizarRecebeuNovasNotificacoes } from 'features/notificacoes/redux/notificacoesAction';
import NegociacaoStatus from 'shared/constants/NegociacaoStatus';
import { Box, TableCell, TableRow, Typography } from '@material-ui/core';

import { Pagination, Skeleton } from '@material-ui/lab';
import BoxWhiteCustomScrollTable from '../../../shared/components/box/BoxWhiteCustomScrollTable';

import { Negociacao } from '../api/negociacoesAutomatizadasApiTypes';

import TabelaTitulo from './TabelaTitulo';
import TabelaItem from './TabelaItem';
import TransferirCargasDrawer from './TransferirCargasDrawer';
import { obterNegociacoesFreteTabelado } from '../api/negociacoesAutomatizadasService';

const TabelaNegociacoesAutomatizadas = (): JSX.Element => {
  const [filtroData, setFiltroData] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [checkboxValues, setCheckboxValues] = useState<{
    [key: number]: boolean;
  }>({});
  const [countValues, setCountValues] = useState<number>(0);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [negociacoes, setNegociacoes] = useState<Negociacao[]>([]);
  const [dadosValidacao, setDadosValidacao] = useState(false);
  const [boxVazio, setBoxVazio] = useState(false);

  const [paginaAtual, setPaginaAtual] = useState<number>(1);
  const [totalPagina, setTotalPagina] = useState<number>();

  const { recebeuNovasNotificacoes } = useSelector(
    (state: RootState) => state.notificacoesReducer,
  );

  const { statusSelecionados } = useSelector(
    (state: RootState) => state.negociacoesAutomatizadasReducer,
  );

  const negociacoesFreteTabelado = useCallback(
    async (novaPaginaAtual) => {
      setDadosValidacao(false);
      await obterNegociacoesFreteTabelado(
        String(statusSelecionados.has(String(3)) ? novaPaginaAtual : '1'),
        statusSelecionados.has(String(3)) ? '10' : '100',
      )
        .then((resposta) => {
          if (resposta.negociacoes.length > 0) {
            const negociacoesAtualizadas = resposta.negociacoes.map(
              (negociacao) => {
                const dataAtual = new Date(
                  new Date().toLocaleString('pt-BR', {
                    timeZone: 'America/Sao_Paulo',
                  }),
                );

                const dataCarregamento = new Date(negociacao.dataCarregamento);

                if (
                  negociacao.tempoParaExpirarNegociacoesEmMinutos !== undefined
                ) {
                  const diferencaEmMinutos = Math.floor(
                    (dataAtual.getTime() - dataCarregamento.getTime()) / 60000,
                  );

                  if (
                    diferencaEmMinutos <
                    negociacao.tempoParaExpirarNegociacoesEmMinutos
                  ) {
                    const minutosRestantes =
                      negociacao.tempoParaExpirarNegociacoesEmMinutos -
                      diferencaEmMinutos;

                    if (
                      minutosRestantes <= 60 &&
                      negociacao.status !== NegociacaoStatus.Concluida &&
                      negociacao.status !== NegociacaoStatus.NovaNegociacao
                    ) {
                      return {
                        ...negociacao,
                        status: NegociacaoStatus.TempoExpirado,
                      };
                    }
                  }
                }

                if (
                  negociacao.motoristasEnvolvidos.every(
                    (motorista) => motorista.status === 6,
                  )
                ) {
                  return {
                    ...negociacao,
                    status: NegociacaoStatus.Cancelada,
                  };
                }

                if (
                  negociacao.isTempoExpirado &&
                  negociacao.status !== NegociacaoStatus.Concluida &&
                  negociacao.status !== NegociacaoStatus.NovaNegociacao
                ) {
                  return {
                    ...negociacao,
                    status: NegociacaoStatus.TempoExpirado,
                  };
                }
                return negociacao;
              },
            );
            setTotalPagina(resposta.totalPaginas);
            setNegociacoes(negociacoesAtualizadas);
            setDadosValidacao(true);
          } else {
            setBoxVazio(true);
            dispatch(
              abrirSnackbarAviso({
                mensagem: 'Nenhuma negociação encontrada.',
              }),
            );
          }
          dispatch(atualizarRecebeuNovasNotificacoes());
        })
        .catch((e) => {
          console.log(e.message);
        });
    },
    [dispatch, statusSelecionados],
  );

  useEffect(() => {
    if (
      negociacoes.length === 0 ||
      recebeuNovasNotificacoes ||
      !dadosValidacao
    ) {
      negociacoesFreteTabelado(paginaAtual);
    }
  }, [
    negociacoes.length,
    negociacoesFreteTabelado,
    recebeuNovasNotificacoes,
    dadosValidacao,
    paginaAtual,
  ]);

  const filtroNegociacoes = useMemo(() => {
    return negociacoes.filter((negociacao) =>
      statusSelecionados.has(String(negociacao.status)),
    );
  }, [negociacoes, statusSelecionados]);

  const handleCheckboxChange = (id: number, value: boolean) => {
    setCheckboxValues((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));

    setCountValues((prevCount) => prevCount + (value ? 1 : -1));
  };

  useEffect(() => {
    if (countValues >= 1) {
      setOpenDrawer(true);
    } else {
      setOpenDrawer(false);
    }
  }, [countValues, openDrawer]);

  const handleCloseDrawer = () => {
    setCountValues(0);
    setCheckboxValues({});
    negociacoesFreteTabelado(1);
  };

  const handleLimparCampos = () => {
    setCountValues(0);
    setCheckboxValues({});
  };

  const compararDatasCriacao = (a: Negociacao, b: Negociacao) => {
    const dataA: number = new Date(a.dataCarregamento).getTime();
    const dataB: number = new Date(b.dataCarregamento).getTime();

    if (filtroData) {
      return dataA - dataB;
    }
    return dataB - dataA;
  };

  filtroNegociacoes.sort(compararDatasCriacao);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number,
  ) => {
    negociacoesFreteTabelado(page);
    setPaginaAtual(page);
  };

  const skeletonRows = [...Array(10)].map((_) => (
    <TableRow key={`Negociacao-${_}`}>
      <TableCell align="left">
        <Skeleton
          width={70}
          animation="wave"
          height={22}
          style={{ borderRadius: '8px' }}
        />
      </TableCell>
      <TableCell align="left">
        <Skeleton
          width={120}
          animation="wave"
          height={22}
          style={{ borderRadius: '8px' }}
        />
      </TableCell>
      <TableCell align="left">
        <Skeleton
          width={120}
          animation="wave"
          height={22}
          style={{ borderRadius: '8px' }}
        />
      </TableCell>
      <TableCell align="left">
        <Skeleton
          width={140}
          animation="wave"
          height={22}
          style={{ borderRadius: '8px' }}
        />
      </TableCell>
      <TableCell align="left">
        <Skeleton
          width={55}
          animation="wave"
          height={22}
          style={{ borderRadius: '8px' }}
        />
      </TableCell>
      <TableCell align="right">
        <Skeleton
          width={55}
          animation="wave"
          height={22}
          style={{ borderRadius: '8px' }}
        />
      </TableCell>
      <TableCell align="left">
        <Skeleton
          width={55}
          animation="wave"
          height={22}
          style={{ borderRadius: '8px' }}
        />
      </TableCell>
      <TableCell align="left">
        <Skeleton
          width={60}
          animation="wave"
          height={22}
          style={{ borderRadius: '8px' }}
        />
      </TableCell>
      <TableCell align="left">
        <Skeleton
          width={55}
          animation="wave"
          height={22}
          style={{ borderRadius: '8px' }}
        />
      </TableCell>
      <TableCell align="left">
        <Skeleton
          width={65}
          animation="wave"
          height={22}
          style={{ borderRadius: '8px' }}
        />
      </TableCell>
      <TableCell align="left">
        <Skeleton
          width={65}
          animation="wave"
          height={22}
          style={{ borderRadius: '8px' }}
        />
      </TableCell>
    </TableRow>
  ));

  return (
    <>
      <BoxWhiteCustomScrollTable
        style={{
          borderRadius: '0 0 8px 8px',
          marginBottom: openDrawer ? '150px' : '',
        }}
      >
        <TableContainer>
          <Table size="small" aria-label="Tabela - Minhas Negociações">
            <TableHead>
              <TabelaTitulo
                filtroData={filtroData}
                setFiltroData={setFiltroData}
              />
            </TableHead>
            <TableBody>
              {!dadosValidacao && filtroNegociacoes.length > 0 && skeletonRows}
              {filtroNegociacoes.map((negociacao) => (
                <>
                  {dadosValidacao && (
                    <TabelaItem
                      key={`Negociacao-${negociacao.id}`}
                      negociacao={negociacao}
                      checkboxValue={checkboxValues[negociacao.id]}
                      onCheckboxChange={(value) =>
                        handleCheckboxChange(negociacao.id, value)
                      }
                    />
                  )}
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {filtroNegociacoes.length === 0 && dadosValidacao && (
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              height: '66vh',
              borderRadius: '100%',
              textAlign: 'center',
            }}
          >
            <IconeCaixaVazia style={{ margin: '-10px 0 24px 0' }} />
            <Typography style={{ color: '#424242', fontWeight: 400 }}>
              Não encontramos nenhuma carga
            </Typography>
            <Typography style={{ color: '#424242', fontWeight: 400 }}>
              para esse status no momento
            </Typography>
          </Box>
        )}
        {(filtroNegociacoes.length === 0 && dadosValidacao) ||
          (boxVazio && (
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                height: '66vh',
                borderRadius: '100%',
                textAlign: 'center',
              }}
            >
              <IconeCaixaVazia style={{ margin: '-10px 0 24px 0' }} />
              <Typography style={{ color: '#424242', fontWeight: 400 }}>
                Não encontramos nenhuma carga
              </Typography>
              <Typography style={{ color: '#424242', fontWeight: 400 }}>
                para esse status no momento
              </Typography>
            </Box>
          ))}
        <TransferirCargasDrawer
          handleCloseDrawer={handleCloseDrawer}
          openDrawer={openDrawer}
          handleLimparCampos={handleLimparCampos}
          negociacoesIdTransferencia={checkboxValues}
        />
      </BoxWhiteCustomScrollTable>
      <Box style={{ marginTop: '10px' }}>
        {statusSelecionados.has(String(3)) ? (
          <Pagination
            count={totalPagina}
            shape="rounded"
            color="primary"
            size="large"
            variant="outlined"
            page={paginaAtual}
            onChange={handlePageChange}
          />
        ) : (
          <></>
        )}
      </Box>
    </>
  );
};

export default TabelaNegociacoesAutomatizadas;
