import {
  MotoristaNegociacao,
  Negociacao,
  PropostaNegociacao,
} from '../../../api/informacoesNegociacaoApiTypes';

import informacoesNegociacaoUtils from '../../../functions/informacoesNegociacaoUtils';

import TipoProposta, { TipoPropostaType } from '../../../types/TipoProposta';

export const obterValorProposta = (
  tipoProposta: TipoPropostaType | undefined,
  proposta: PropostaNegociacao | undefined,
  valorNegociacao: number | undefined,
): number => {
  switch (tipoProposta) {
    case TipoProposta.PROPOSTA_INICIAL:
      return valorNegociacao || 0;
    case TipoProposta.CONTRAPROPOSTA:
      return proposta?.contrapropostaNegociador || 0;
    case TipoProposta.PROPOSTA_FINAL:
      return proposta?.propostaAtual || 0;
    case TipoProposta.RETOMAR_PROPOSTA:
      return proposta?.contrapropostaNegociador || 0;
    default:
      return 0;
  }
};

export const enviarNovaPropostaWhatsapp = (
  motorista: MotoristaNegociacao,
  negociacao: Negociacao,
): void => {
  const mensagem = informacoesNegociacaoUtils.criarMensagemEnviarProposta(
    motorista,
    negociacao,
  );

  informacoesNegociacaoUtils.enviarMensagemWhatsApp(
    motorista.contato,
    mensagem,
  );
};

export const enviarContrapropostaWhatsapp = (
  proposta: PropostaNegociacao,
): void => {
  const mensagem = informacoesNegociacaoUtils.criarMensagemEnviarContraproposta(
    proposta,
  );

  informacoesNegociacaoUtils.enviarMensagemWhatsApp(proposta.contato, mensagem);
};

export const enviarPropostaFinalWhatsapp = (
  proposta: PropostaNegociacao,
  negociacao: Negociacao,
): void => {
  const mensagem = informacoesNegociacaoUtils.criarMensagemEnviarPropostaFinal(
    proposta,
    negociacao,
  );

  informacoesNegociacaoUtils.enviarMensagemWhatsApp(proposta.contato, mensagem);
};

export const retomarPropostaWhatsapp = (
  proposta: PropostaNegociacao,
  negociacao: Negociacao,
): void => {
  const mensagem = informacoesNegociacaoUtils.criarMensagemRetomarProposta(
    proposta,
    negociacao,
  );

  informacoesNegociacaoUtils.enviarMensagemWhatsApp(proposta.contato, mensagem);
};
