import { useTheme } from '@material-ui/core/styles';
import * as React from 'react';
import { SVGProps } from 'react';

const IconeCorretoQuadrado = ({
  fill,
  width,
  height,
  ...outros
}: SVGProps<SVGSVGElement>): JSX.Element => {
  const theme = useTheme();

  return (
    <svg
      width={width || 12}
      height={height || 12}
      viewBox="0 0 12 12"
      fill="none"
      {...outros}
    >
      <path
        d="M4.855 7.604a.583.583 0 0 0 .829 0l2.38-2.38a.586.586 0 0 0-.829-.828L5.27 6.367l-.706-.711a.586.586 0 1 0-.829.828l1.12 1.12ZM11.15.167H.65A.583.583 0 0 0 .066.75v10.5a.583.583 0 0 0 .584.583h10.5a.583.583 0 0 0 .583-.583V.75a.583.583 0 0 0-.583-.583Zm-.584 10.5H1.233V1.333h9.333v9.334Z"
        fill={fill || theme.palette.cores.azulMartins[500]}
      />
    </svg>
  );
};

export default IconeCorretoQuadrado;
