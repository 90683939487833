import React from 'react';

import { makeStyles } from '@material-ui/core';

import { Rubik } from '../../../../../../../shared/constants/font';

import InputPorcentagem from '../../../../../../../shared/components/input/InputPorcentagem';

const useStyles = makeStyles((theme) => ({
  input: {
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.cores.branco,
    },
    '& .MuiInputBase-input': {
      fontFamily: Rubik,
    },
    '& .MuiFormHelperText-contained': {
      marginLeft: '0px',
    },
  },
}));

export type InputPorcentagemValorContrapropostaProps = {
  id: string;
  value?: number;
  error?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  precisaoDecimal?: boolean;
  onChange(novoValor: number | undefined): void;
};

const InputPorcentagemValorContraproposta = ({
  id,
  value,
  error,
  errorMessage = '*Campo obrigatório',
  disabled,
  precisaoDecimal,
  onChange,
}: InputPorcentagemValorContrapropostaProps): JSX.Element => {
  const classes = useStyles();

  return (
    <InputPorcentagem
      precisaoDecimal={precisaoDecimal}
      id={id}
      size="small"
      label="Valor"
      className={classes.input}
      value={value}
      fullWidth
      onChange={onChange}
      disabled={disabled}
      error={error}
      helperText={error ? errorMessage : undefined}
    />
  );
};

export default InputPorcentagemValorContraproposta;
