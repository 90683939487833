export const HEADER_ALTERNATIVO_INFORMACOES_NEGOCIACAO_AUTOMATIZADAS = [
  {
    titulo: 'Voltar',
    link: '/negociacoes-automatizadas',
    boataoVoltar: true,
  },
  {
    titulo: 'Negociações automatizadas',
    link: '/portal/negociacoes-automatizadas',
  },
  { titulo: 'Painel de negociação automatizada', link: '', textoOn: true },
];
