import { useTheme } from '@material-ui/core/styles';
import * as React from 'react';
import { SVGProps } from 'react';

const IconePin = ({
  fill,
  width,
  height,
  ...outros
}: SVGProps<SVGSVGElement>): JSX.Element => {
  const theme = useTheme();

  return (
    <svg
      width={width || 12}
      height={height || 14}
      viewBox="0 0 12 14"
      fill="none"
      {...outros}
    >
      <path
        d="M8.913 7.527a.667.667 0 1 0-.493 1.24c.973.386 1.58.986 1.58 1.566 0 .947-1.64 2-4 2-2.36 0-4-1.053-4-2 0-.58.607-1.18 1.58-1.566a.667.667 0 1 0-.493-1.24c-1.514.6-2.42 1.646-2.42 2.806 0 1.867 2.34 3.334 5.333 3.334 2.993 0 5.333-1.467 5.333-3.334 0-1.16-.906-2.206-2.42-2.806Zm-3.58-1.954v4.76a.667.667 0 0 0 1.334 0v-4.76a2.667 2.667 0 1 0-1.334 0ZM6 1.667a1.333 1.333 0 1 1 0 2.666 1.333 1.333 0 0 1 0-2.666Z"
        width={width || 12}
        height={height || 14}
        fill={fill || theme.palette.cores.cinza[700]}
      />
    </svg>
  );
};

export default IconePin;
