import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import { ChatBalaoProps } from '../ChatBalao';
import TypographyChat from '../../TypographyChat';
import ChatBalaoInformativo from '../../../components/ChatBalaoInformativo';
import IconeCorreto from '../../../../../assets/icones/IconeCorreto';
import { formatarNumeroParaStringMonetariaComSimbolo } from '../../../../../shared/functions/dinheiroUtils';
import { converterDataApiParaDataChat } from '../../../utils/dataHora';
import spacingInPixels from '../../../../../shared/functions/materialUtils';
import NomeGestor from './NomeGestor';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: `${theme.palette.cores.verde[200]}40`,
    borderColor: `${theme.palette.cores.verde[700]}90`,
    width: 360,
    padding: spacingInPixels(theme, 1),
  },
  textoValor: {
    fontSize: '16px',
    fontWeight: 600,
    color: theme.palette.cores.verde[700],
  },
}));

const AceiteSupervisor = ({ mensagem }: ChatBalaoProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <ChatBalaoInformativo
      className={classes.container}
      icone={<IconeCorreto fill={theme.palette.cores.verde[700]} />}
    >
      <TypographyChat>
        O valor aprovado pelo supervisor foi de{' '}
        <span className={classes.textoValor}>
          {formatarNumeroParaStringMonetariaComSimbolo(
            mensagem.valorContraproposta,
          )}{' '}
        </span>
        às {converterDataApiParaDataChat(mensagem.dataEnvio)}.
      </TypographyChat>
      <NomeGestor nome={mensagem.nomeGestorAprovacao} />
    </ChatBalaoInformativo>
  );
};

export default AceiteSupervisor;
