import React from 'react';
import { makeStyles, Theme, Typography, useTheme } from '@material-ui/core';
import { Rubik } from 'shared/constants/font';
import { tratarValorMonetario } from 'shared/functions/dinheiroUtils';
import IconeGrafico from 'assets/icones/IconeGrafico';

interface StyleCustomProps {
  tamanhoFonte?: number;
}

const useStyles = makeStyles<Theme, StyleCustomProps>((theme) => ({
  container: {
    display: 'flex',
  },
  valorSugestao: {
    color: theme.palette.cores.verde[700],
    fontSize: ({ tamanhoFonte }) => `${tamanhoFonte}px`,
    fontWeight: 700,
  },
  texto: {
    marginLeft: '1em',
    font: Rubik,
    fontSize: ({ tamanhoFonte }) => `${tamanhoFonte}px`,
    fontWeight: 400,
    color: theme.palette.cores.cinza[800],
    '& b': {
      fontWeight: 600,
    },
  },
}));

type SugestaoRoboProps = {
  sugestaoValorInicialRobo: number;
  tamanhoFonte?: number;
  larguraIcone?: number;
  alturaIcone?: number;
};

const TextoValorSugeridoRobo = ({
  sugestaoValorInicialRobo,
  tamanhoFonte,
  larguraIcone,
  alturaIcone,
}: SugestaoRoboProps): JSX.Element => {
  const classes = useStyles({ tamanhoFonte });
  const theme = useTheme();

  return (
    <div className={classes.container}>
      <IconeGrafico
        width={larguraIcone}
        height={alturaIcone}
        fill={theme.palette.cores.verde[700]}
      />
      <Typography className={classes.texto}>
        Valor sugerido pelo <b>ALGORITMO</b>:{' '}
        <span className={classes.valorSugestao}>
          {tratarValorMonetario(sugestaoValorInicialRobo)}
        </span>
      </Typography>
    </div>
  );
};

TextoValorSugeridoRobo.defaultProps = {
  tamanhoFonte: 14,
  larguraIcone: 20,
  alturaIcone: 20,
};

export default TextoValorSugeridoRobo;
