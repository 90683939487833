import * as service from './informacoesNegociacaoService';
import {
  ObservacaoNegociacaoRequest,
  ValoresRequest,
  MotoristasResponse,
  PropostasResponse,
  AtualizarPropostaRequest,
  CriarPropostaRequest,
  Negociacao,
} from './informacoesNegociacaoApiTypes';
import NegociacaoStatus from '../../../shared/constants/NegociacaoStatus';
import { typeEnviarNovaPropostaOperation } from '../types/PropostaAEnviar';

export const buscarNegociacao = async (
  idNegociacao: number,
  idUsuarioMaster?: number | null,
): Promise<Negociacao> => service.buscar(idNegociacao, idUsuarioMaster);

export const salvarObservacao = async (
  idNegociacao: number,
  observacao: ObservacaoNegociacaoRequest,
): Promise<void> => service.salvarObservacao(idNegociacao, observacao);

export const salvarValores = async (
  idNegociacao: number,
  valores: ValoresRequest,
): Promise<void> => service.salvarValores(idNegociacao, valores);

export const buscarMotoristas = async (
  idFilial?: number | null,
  freteTabelado?: boolean | null,
): Promise<MotoristasResponse> =>
  service.buscarMotoristas(idFilial, freteTabelado);

export const buscarPropostas = async (
  idNegociacao: number,
  idUsuarioMaster?: number | null,
): Promise<PropostasResponse> =>
  service.buscarPropostas(idNegociacao, idUsuarioMaster);

export const criarProposta = async (
  idNegociacao: number,
  body: CriarPropostaRequest,
): Promise<typeEnviarNovaPropostaOperation> =>
  service.criarProposta(idNegociacao, body);

export const atualizarProposta = async (
  idNegociacao: number,
  idProposta: number,
  request: AtualizarPropostaRequest,
): Promise<void> =>
  service.atualizarProposta(idNegociacao, idProposta, request);

export const cancelarNegociacao = async (
  negociacaoId: number,
  motivoRecusa: number,
  isNegociacaoCanceladaManualmenteTms: boolean,
): Promise<number> => {
  const resultado = await service.atualizarNegociacao({
    negociacaoId,
    motivoRecusa,
    status: NegociacaoStatus.Cancelada,
    isNegociacaoCanceladaManualmenteTms,
  });

  return resultado;
};
