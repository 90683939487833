import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';
import SeletorPeriodo from './SeletorPeriodo';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacingInPixels(theme, 3),
    backgroundColor: theme.palette.cores.branco,
    boxShadow: '4px 1px 25px rgba(0, 0, 0, 0.17)',
    borderRadius: spacingInPixels(theme, 2.25),
    padding: `${spacingInPixels(theme, 4)} ${spacingInPixels(theme, 4.5)}`,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  titulo: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: spacingInPixels(theme, 3),
    lineHeight: spacingInPixels(theme, 4),
    color: theme.palette.cores.cinza[800],
    margin: 0,
  },
}));

interface PainelPadraoProps {
  titulo: string;
  children: ReactNode;
  onChangePeriodo: (valor: string) => void;
}

const PainelPadrao = ({
  titulo,
  children,
  onChangePeriodo,
}: PainelPadraoProps): JSX.Element => {
  const classes = useStyles();

  return (
    <section className={classes.container}>
      <div className={classes.header}>
        <h3 className={classes.titulo}>{titulo}</h3>
        <SeletorPeriodo onSelectPeriodo={onChangePeriodo} />
      </div>
      <div>{children}</div>
    </section>
  );
};

export default PainelPadrao;
