import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { calcularPorcentagemSomenteParteInteira } from 'shared/functions/porcentagemUtils';
import { RootState } from '../../../../store/reducer';

import TipoProposta from '../../types/TipoProposta';

import { obterValorProposta } from './functions/enviarPropostaControleUtils';
import {
  enviarContrapropostaOperation,
  enviarNovaPropostaOperation,
  enviarPropostaFinalOperation,
  retomarPropostaOperation,
} from './redux/motoristasCardOperation';

import EnviarPropostaDialog from './EnviarPropostaDialog';

type PropsEnviarPropostaControle = {
  limparBarraPesquisa: () => void;
};

const EnviarPropostaControle = ({
  limparBarraPesquisa,
}: PropsEnviarPropostaControle): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [valorAlgoritmo, setValorAlgoritmo] = useState<number | null>();

  const { idNegociacao, negociacao } = useSelector(
    ({ informacoesNegociacaoReducer }: RootState) =>
      informacoesNegociacaoReducer,
  );

  const { propostaAEnviar } = useSelector(
    ({ motoristasCardReducer }: RootState) => motoristasCardReducer,
  );

  const valorFrotaPropria = negociacao?.valorFrotaPropria;
  const valorFreteTerceiro = negociacao?.valorFreteTerceiro;

  const valorProposta = obterValorProposta(
    propostaAEnviar?.tipoProposta,
    propostaAEnviar?.proposta,
    negociacao?.valorNegociacao,
  );

  const valorPropostaRobo = obterValorProposta(
    propostaAEnviar?.tipoProposta,
    propostaAEnviar?.proposta,
    negociacao?.sugestaoValorInicialRobo,
  );

  const porcentagemFrotaPropria = (temGreguim: boolean) => {
    const valorAlgoritmoSeguro = valorAlgoritmo ?? 0;
    const valorParaEnviar: number | null =
      valorAlgoritmoSeguro === 0 ? null : valorAlgoritmoSeguro;

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const converter = parseCurrencyStringToNumberAlgoritmo(
      String(valorParaEnviar),
    );
    return calcularPorcentagemSomenteParteInteira(
      temGreguim ? converter : valorProposta,
      temGreguim ? negociacao?.sugestaoValorInicialRobo : valorFrotaPropria,
    );
  };

  const porcentagemFreteTerceiro = (temGreguim: boolean): number => {
    const valorAlgoritmoSeguro = valorAlgoritmo ?? 0;
    const valorParaEnviar: number | null =
      valorAlgoritmoSeguro === 0 ? null : valorAlgoritmoSeguro;

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const converter = parseCurrencyStringToNumberAlgoritmo(
      String(valorParaEnviar),
    );

    return calcularPorcentagemSomenteParteInteira(
      temGreguim ? converter : valorProposta,
      valorFreteTerceiro,
    );
  };

  const parseCurrencyStringToNumberAlgoritmo = (val: string): number => {
    const cleanVal = val.replace(/[^\d,]/g, '').replace(',', '.');
    if (val.length === 4) {
      return parseFloat(cleanVal) * 100;
    }
    if (val.length === 6) {
      return parseFloat(cleanVal) * 10;
    }
    if (val.length === 5 || val.length >= 7) {
      return parseFloat(cleanVal);
    }

    return parseFloat(cleanVal) * 100;
  };

  const enviarNovaProposta = async () => {
    if (negociacao && propostaAEnviar?.motorista) {
      const valorAlgoritmoSeguro = valorAlgoritmo ?? 0;
      const valorParaEnviar: number | null =
        valorAlgoritmoSeguro === 0 ? null : valorAlgoritmoSeguro;

      const aux = await enviarNovaPropostaOperation(
        idNegociacao,
        propostaAEnviar.motorista,
        negociacao,
        valorParaEnviar,
      )(dispatch);
      history.push(
        `/minhas-negociacoes/${aux.idNegociacao}?idProposta=${aux.idProposta}`,
      );
      limparBarraPesquisa();
    }
  };

  const enviarContraproposta = () => {
    if (negociacao && propostaAEnviar?.proposta) {
      dispatch(
        enviarContrapropostaOperation(idNegociacao, propostaAEnviar.proposta),
      );
    }
  };

  const retomarProposta = () => {
    if (negociacao && propostaAEnviar?.proposta) {
      dispatch(
        retomarPropostaOperation(
          idNegociacao,
          propostaAEnviar.proposta,
          negociacao,
        ),
      );
    }
  };

  const enviarPropostaFinal = () => {
    if (negociacao && propostaAEnviar?.proposta) {
      dispatch(
        enviarPropostaFinalOperation(
          idNegociacao,
          propostaAEnviar.proposta,
          negociacao,
        ),
      );
    }
  };

  const funcaoEnviarProposta: () => void = {
    [TipoProposta.PROPOSTA_INICIAL]: enviarNovaProposta,
    [TipoProposta.CONTRAPROPOSTA]: enviarContraproposta,
    [TipoProposta.PROPOSTA_FINAL]: enviarPropostaFinal,
    [TipoProposta.RETOMAR_PROPOSTA]: retomarProposta,
  }[propostaAEnviar?.tipoProposta || TipoProposta.PROPOSTA_INICIAL];

  return (
    <EnviarPropostaDialog
      porcentagemFrotaPropria={porcentagemFrotaPropria}
      porcentagemFreteTerceiro={porcentagemFreteTerceiro}
      valorProposta={valorProposta}
      valorPropostaRobo={valorPropostaRobo}
      setValorAlgoritmo={setValorAlgoritmo}
      confirmar={funcaoEnviarProposta}
    />
  );
};

export default EnviarPropostaControle;
