import React from 'react';
import { Box } from '@material-ui/core';

import MenuItems from './MenuItems';

import { useStyles } from './styles/MenuLateral';

type MenuContainerProps = {
  isOpen: boolean;
  hover: boolean;
  setHover: React.Dispatch<React.SetStateAction<boolean>>;
};

const MenuLateral = ({
  isOpen,
  hover,
  setHover,
}: MenuContainerProps): JSX.Element => {
  const classes = useStyles({ hover, isOpen });

  return (
    <Box
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={`${classes.menuLateral}`}
    >
      <MenuItems aberto={hover || isOpen} />
    </Box>
  );
};

export default MenuLateral;
