import { ContainerBotoes } from 'features/configuracoes/styles';
import React from 'react';
import { Button } from '@material-ui/core';
import { BotoesConfigProps } from 'features/configuracoes/types';
import { useStyles } from '../../styles/configuracoesSistema';

const BotoesConfig = ({
  disabledLimpar,
  disabledSalvar,
  limparFuncao,
  salvarFuncao,
}: BotoesConfigProps): JSX.Element => {
  const classes = useStyles();
  return (
    <ContainerBotoes>
      <Button
        onClick={limparFuncao}
        color="primary"
        disabled={disabledLimpar}
        className={classes.buttonInside}
      >
        Limpar
      </Button>
      <Button
        variant="contained"
        color="primary"
        className={classes.buttonOutside}
        disabled={disabledSalvar}
        onClick={salvarFuncao}
      >
        Salvar
      </Button>
    </ContainerBotoes>
  );
};

export default BotoesConfig;
