import { Dispatch } from 'redux';
import { abrirSnackbarErro } from '../../../shared/components/snackbar/redux/snackbarAction';
import { obterNotificacoes } from '../api/notificacoesManager';
import { atualizarNotificacoes } from './notificacoesAction';

export const obterPaginaNotificacoes = (
  pagina: number,
  isLido?: boolean,
) => async (dispatch: Dispatch): Promise<void> =>
  obterNotificacoes(pagina, isLido)
    .then((resposta) => {
      dispatch(atualizarNotificacoes(resposta));
    })
    .catch((error) => {
      dispatch(abrirSnackbarErro({ mensagem: error.message }));
    });

export const obterPrimeiraPaginaNotificacoes = () => async (
  dispatch: Dispatch,
): Promise<void> =>
  obterNotificacoes(1, false)
    .then((resposta) => {
      dispatch(atualizarNotificacoes(resposta));
    })
    .catch(() => {
      // Erros nesta função não devem ser exibidos para o usuário
    });
