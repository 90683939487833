import { Divider, makeStyles } from '@material-ui/core';
import React from 'react';
import CustomSkeleton from 'shared/components/skeleton/CustomSkeleton';
import spacingInPixels from 'shared/functions/materialUtils';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '49.5% 1% 49.5%',
    backgroundColor: theme.palette.cores.branco,
    padding: spacingInPixels(theme, 1.5),
    marginBottom: spacingInPixels(theme, 1.5),
    borderRadius: spacingInPixels(theme, 1),
    boxShadow: '0px 1px 6px 0px rgba(0, 0, 0, 0.09)',
  },
  containerLinha: {
    display: 'flex',
    gap: spacingInPixels(theme, 1),
  },
  containerSkeletonBigNumber: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacingInPixels(theme, 0.5),
  },
}));

const SkeletonBigNumberInterno = (): JSX.Element => {
  const classes = useStyles();
  return (
    <section className={classes.containerSkeletonBigNumber}>
      <div className={classes.containerLinha}>
        <CustomSkeleton borderradius={4} width="10%" height={24} />
        <CustomSkeleton borderradius={4} width="25%" height={24} />
      </div>
      <CustomSkeleton borderradius={4} width="60%" height={24} />
      <CustomSkeleton borderradius={4} width="80%" height={18} />
    </section>
  );
};
const SkeletonQuadroBigNumbersDivididos = (): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <SkeletonBigNumberInterno />
      <Divider orientation="vertical" />
      <SkeletonBigNumberInterno />
    </div>
  );
};

export default SkeletonQuadroBigNumbersDivididos;
