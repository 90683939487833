export const PATH_LOGIN = '/login';
export const PATH_DETALHES_CARGA =
  '/minhas-negociacoes/:idNegociacao/carga/:idCarga';
export const PATH_INFORMACOES_NEGOCIACAO = '/minhas-negociacoes/:id';
export const PATH_MINHAS_NEGOCIACOES = '/minhas-negociacoes';
export const PATH_INFORMACOES_NEGOCIACAO_AUTOMATIZADAS =
  '/negociacoes-automatizada/:id';
export const PATH_DETALHES_CARGA_NEGOCIACAO_AUTOMATIAZADAS =
  '/negociacoes-automatizada/:idNegociacao/carga/:idCarga';
export const PATH_NEGOCIACOES_AUTOMATIZADAS = '/negociacoes-automatizadas';
export const PATH_MOTORISTAS_VEICULOS = '/motoristas-veiculos';
export const PATH_LISTA_CARGAS = '/lista-cargas';
export const PATH_MINHAS_CARGAS = '/minhas-cargas';
export const PATH_UPLOAD_SIMULADOR = '/upload-simulador';
export const PATH_ACOMPANHAMENTO_NEGOCIACOES = '/acompanhamento-negociacoes';
export const PATH_APROVACOES_SUPERVISOR = '/aprovacoes-supervisor';
export const PATH_DETALHES_APROVACAO_SUPERVISOR =
  '/detalhes-aprovacao-supervisor/negociacoes/:idNegociacao/propostas/:idProposta';
export const PATH_DETALHES_APROVACAO_NEGOCIACAO_SUPERVISOR =
  '/detalhes-aprovacao-supervisor/negociacoes-valor-inicial-robo/:idNegociacao/propostas/:idProposta';
export const PATH_CONFIGURACOES = '/configuracoes';
export const PATH_PAINEL_GESTAO = '/painel-gestao';
export const PATH_DEMANDAS_ANDAMENTO = `${PATH_PAINEL_GESTAO}/demandas-andamento`;
export const PATH_DETALHES_DEMANDAS_DIARIA = `${PATH_PAINEL_GESTAO}/detalhes-demandas-diaria/:tipoDemanda`;
export const PATH_INDICADORES = '/indicadores';

export const PATH_PAGINA_INICIAL = PATH_MINHAS_NEGOCIACOES;
