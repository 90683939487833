import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import { Breadcrumbs, IconButton, Link } from '@material-ui/core';
import ArrowBackRounded from '@material-ui/icons/ArrowBackRounded';
import IconeSetaDireita from 'assets/icones/IconeSetaDireita';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/reducer';
import {
  ativarAmbienteAssistido,
  atualizarDadosColaboradorAssistido,
  dispararBuscaNegociacaoColaboradores,
} from 'features/minhasNegociacoes/redux/minhasNegociacoesAction';
import DisablingTooltip from '../tooltip/DisablingTooltip';
import { useStyles } from './styles/HeaderAlternativo';
import BotaoNotificacoes from '../header/views/BotaoNotificacoes';
import { HeaderAlternativoProps } from './types';

const HeaderAlternativo = ({ campos }: HeaderAlternativoProps): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { ativandoAmbienteAssistido, colaboradorAssistido } = useSelector(
    (state: RootState) => state.minhasNegociacoesReducer,
  );

  const somentePaginas = campos.filter((c) => !c.boataoVoltar);

  return (
    <>
      <AppBar color="inherit" className={classes.header}>
        <DisablingTooltip title={campos[0].titulo}>
          <IconButton
            aria-label={campos[0].titulo}
            onClick={() => {
              history.push(campos[0].link);
              if (ativandoAmbienteAssistido) {
                dispatch(ativarAmbienteAssistido(true));
                dispatch(dispararBuscaNegociacaoColaboradores(true));
                dispatch(
                  atualizarDadosColaboradorAssistido(colaboradorAssistido),
                );
              }
            }}
            className={classes.icone}
          >
            <ArrowBackRounded color="primary" />
          </IconButton>
        </DisablingTooltip>
        <Box className={classes.flexBox}>
          <Breadcrumbs separator={<IconeSetaDireita />} aria-label="breadcrumb">
            {somentePaginas?.map((campo) => (
              <>
                {ativandoAmbienteAssistido ? (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <Link
                    key={campo.link}
                    color="inherit"
                    onClick={() => {
                      if (!campo.textoOn) {
                        history.push(campos[0].link);
                        dispatch(ativarAmbienteAssistido(true));
                        dispatch(dispararBuscaNegociacaoColaboradores(true));
                        dispatch(
                          atualizarDadosColaboradorAssistido(
                            colaboradorAssistido,
                          ),
                        );
                      }
                    }}
                    className={
                      campo.textoOn
                        ? `${classes.textoAtivoAmbienteAssistido} ${classes.linkCss}`
                        : classes.linkCss
                    }
                  >
                    {campo.titulo}
                  </Link>
                ) : (
                  <Link
                    key={campo.link}
                    color="inherit"
                    href={campo.link}
                    className={
                      campo.textoOn
                        ? `${classes.textoAtivo} ${classes.linkCss}`
                        : classes.linkCss
                    }
                  >
                    {campo.titulo}
                  </Link>
                )}
              </>
            ))}
          </Breadcrumbs>
          <Box className={classes.flexBoxSino}>
            <BotaoNotificacoes />
          </Box>
        </Box>
      </AppBar>
      <Box height={64} />
    </>
  );
};

export default HeaderAlternativo;
