import { Dispatch } from 'redux';

import OrigemCarga from 'shared/constants/OrigemCarga';
import {
  abrirSnackbarAviso,
  abrirSnackbarErro,
} from '../../../shared/components/snackbar/redux/snackbarAction';

import { ResponseCargas } from '../api/listaCargasApiTypes';
import { obterCargas, obterFiliais } from '../api/listaCargasManager';

import {
  ERRO_BUSCAR_FILIAIS,
  NENHUMA_CARGA_ENCONTRADA,
} from '../constants/mensagensListaCargas';

import {
  atualizarCargas,
  atualizarFiliais,
  atualizarQuantidadeCargas,
  atualizarQuantidadePaginas,
} from './listaCargasAction';

const tratarSucessoBuscaDeCargas = (
  resposta: ResponseCargas,
  origemCarga: OrigemCarga,
) => (dispatch: Dispatch): void => {
  const { cargas, metadata } = resposta;

  dispatch(atualizarCargas({ cargas, origem: origemCarga }));
  dispatch(
    atualizarQuantidadePaginas({
      totalPaginas: metadata.totalPaginas,
      origem: origemCarga,
    }),
  );
  dispatch(
    atualizarQuantidadeCargas({
      totalItens: metadata.totalItens,
      origem: origemCarga,
    }),
  );

  if (cargas.length === 0) {
    dispatch(
      abrirSnackbarAviso({
        mensagem: NENHUMA_CARGA_ENCONTRADA,
      }),
    );
  }
};

const tratarErroBuscaDeCargas = (error: Error, origemCarga: OrigemCarga) => (
  dispatch: Dispatch,
): void => {
  dispatch(atualizarCargas({ cargas: [], origem: origemCarga }));
  dispatch(
    atualizarQuantidadePaginas({ totalPaginas: 0, origem: origemCarga }),
  );
  dispatch(
    abrirSnackbarErro({
      mensagem: error.message,
    }),
  );
};

export const atualizarCargasOperation = (
  pagina: number,
  filiais: string[],
  pesquisa: string,
  dataInicio: Date | null,
  dataFim: Date | null,
  origemCarga: OrigemCarga,
  ordenacao: boolean,
) => async (dispatch: Dispatch): Promise<void> => {
  await obterCargas(
    pagina,
    filiais,
    pesquisa,
    dataInicio,
    dataFim,
    origemCarga,
    ordenacao,
  )
    .then((resposta) => {
      tratarSucessoBuscaDeCargas(resposta, origemCarga)(dispatch);
    })
    .catch((error) => {
      tratarErroBuscaDeCargas(error, origemCarga)(dispatch);
    });
};

export const atualizarFiliaisOperation = () => async (
  dispatch: Dispatch,
): Promise<void> => {
  await obterFiliais()
    .then((resposta) => {
      dispatch(atualizarFiliais(resposta.filiais));
    })
    .catch(() => {
      dispatch(
        abrirSnackbarAviso({
          mensagem: ERRO_BUSCAR_FILIAIS,
        }),
      );
    });
};

export default atualizarCargasOperation;
