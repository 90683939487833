import React from 'react';
import {
  Box,
  Drawer,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';

import { Autocomplete } from '@material-ui/lab';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { Negociacao } from 'features/informacoesNegociacao/api/informacoesNegociacaoApiTypes';

export type DrawerInformacoesAdicionais = {
  openDrawer: boolean;
  onCloseOpenDrawer?: (value: boolean) => void;
  negociacoes?: Negociacao;
};

const useStyles = makeStyles(() => ({
  drawerInfoAd: {
    width: '435px',
    padding: '25px',
    marginTop: '10px',
  },
  boxContent: {
    marginTop: '30px',
  },
  obsevacaoText: {
    marginTop: '15px',
    '& .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled': {
      color: '#424242f0',
      fontWeight: 400,
    },
  },
  autocompleteInput: {
    marginTop: '18px',
    '& .MuiAutocomplete-clearIndicator': {
      display: 'none',
    },
    '& .MuiChip-root.MuiAutocomplete-tag.Mui-disabled.MuiChip-deletable': {
      opacity: 1,
      fontWeight: 400,
    },
    '& .MuiChip-deleteIcon': {
      display: 'none',
    },
  },
  tituloPrincipal: {
    fontSize: '18px',
    fontWeight: 600,
  },
  titulos: {
    display: 'flex',
  },
}));

export const DrawerInformacoesAdicionais = ({
  negociacoes,
  openDrawer,
  onCloseOpenDrawer,
}: DrawerInformacoesAdicionais): JSX.Element => {
  const classes = useStyles();

  const handleCloseDrawer = () => {
    if (onCloseOpenDrawer) {
      onCloseOpenDrawer(false);
    }
  };

  return (
    <Drawer anchor="left" open={openDrawer} onClose={handleCloseDrawer}>
      <Box className={classes.drawerInfoAd}>
        <Typography className={classes.tituloPrincipal} color="primary">
          Informações Adicionais
        </Typography>
        <Box className={classes.boxContent}>
          <Box className={classes.titulos}>
            <VisibilityOutlinedIcon
              style={{ color: '#82838A', marginRight: '7px' }}
            />
            <Typography color="primary">Observação</Typography>
          </Box>
          <TextField
            fullWidth
            className={classes.obsevacaoText}
            size="small"
            multiline
            placeholder="Observações..."
            rows={6}
            variant="outlined"
            disabled
            value={negociacoes?.observacoes}
          />
        </Box>
        <Box className={classes.boxContent}>
          <Box className={classes.titulos}>
            <img
              style={{ marginRight: '7px' }}
              src="/images/informacoesNegociacao/icone-motorista.svg"
              alt="Motorista Icon"
            />
            <Typography color="primary">Motorista Indicado</Typography>
          </Box>
          <Autocomplete
            multiple
            defaultValue={
              negociacoes?.nomeMotoristaIndicado
                ? [negociacoes?.nomeMotoristaIndicado]
                : []
            }
            filterSelectedOptions
            disabled
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Motorista"
                disabled
                size="small"
                className={classes.autocompleteInput}
              />
            )}
            popupIcon={null}
            options={[]}
          />
        </Box>
      </Box>
    </Drawer>
  );
};
