import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ArrowForward } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import { ColaboradoresNegociadores } from 'features/configuracoes/api/configuracoesApiTypes';
import { Box } from '@material-ui/core';
import NotificacaoSnackbar from 'shared/components/snackbar/NotificacaoSnackbar';
import IconeTransferenciaNegociador from 'assets/icones/IconeTransferenciaNegociador';
import { obterNegociacoesNegociadores } from 'features/negociacoesAutomatizadas/api/negociacoesAutomatizadasService';
import { buscarDadosPermissaoUsuario } from 'features/login/redux/loginOperation';
import BoxPagina from '../../../shared/components/box/BoxPagina';

import { Negociacao } from '../api/minhasNegociacoesApiTypes';
import {
  obterNegociacoes as obterNegociacoesManager,
  getPlanilha as getPlanilhaManager,
} from '../api/minhasNegociacoesManager';

import {
  abrirSnackbarErro,
  abrirSnackbarAviso,
} from '../../../shared/components/snackbar/redux/snackbarAction';

import TituloMinhasNegociacoes from './TituloMinhasNegociacoes';
import TabelaMinhasNegociacoes from './TabelaMinhasNegociacoes';

import { removerEspacosECaracteresEspeciais } from '../../../shared/functions/stringUtils';
import { spacingInPixels } from '../../../shared/functions/materialUtils';
import Button from '../../../shared/components/button/ButtonTextTransformNone';
import DisablingTooltip from '../../../shared/components/tooltip/DisablingTooltip';
import { RootState } from '../../../store/reducer';
import mensagensMinhasNegociacoes from '../constants/mensagensMinhasNegociacoes';
import { atualizarRecebeuNovasNotificacoes } from '../../notificacoes/redux/notificacoesAction';
import DrawerDemandas from './DrawerDemandas';
import {
  ativarAmbienteAssistido,
  atualizarDadosColaboradorAssistido,
  atualizarNegociacoesUsuarioMaster,
  dispararBuscaNegociacaoColaboradores,
} from '../redux/minhasNegociacoesAction';

const useStyles = makeStyles((theme) => ({
  botaoPlanilha: {
    width: '28%',
    minWidth: spacingInPixels(theme, 25),
    maxWidth: spacingInPixels(theme, 50),
    height: spacingInPixels(theme, 6),
    fontSize: '16px',
    float: 'right',
    marginTop: spacingInPixels(theme, 3),
    marginBottom: spacingInPixels(theme, 3),
  },
}));

const MinhasNegociacoes = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { statusSelecionados, filiaisSelecionadas } = useSelector(
    (state: RootState) => state.minhasNegociacoesReducer,
  );

  const {
    ativandoAmbienteAssistido,
    colaboradorAssistido,
    buscaColaboradores,
  } = useSelector((state: RootState) => state.minhasNegociacoesReducer);
  const [dadosValidacao, setDadosValidacao] = useState(false);
  const [negociacoes, setNegociacoes] = useState<Negociacao[]>([]);
  const [negociacoesFiltradas, setNegociacoesFiltradas] = useState<
    Negociacao[]
  >([]);

  const { dados } = useSelector((state: RootState) => state.loginReducer);
  const permissaoSupervisor = Boolean(
    dados?.usuario.dadosPermissao.roles &&
      dados?.usuario.dadosPermissao.roles.length >= 2,
  );
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [openNotificacao, setNotificacao] = useState<boolean>(
    !ativandoAmbienteAssistido,
  );
  const [
    resetarOpcoesNegociadores,
    setResetarOpcoesNegociadores,
  ] = useState<boolean>(false);

  const [loadingDemanda, setLoadingDemanda] = useState(false);

  useEffect(() => {
    buscarDadosPermissaoUsuario()(dispatch);
  }, [dispatch]);

  const filtrarPorFilial = useCallback(
    (listaCompleta: Negociacao[]) => {
      let listaFiltrada = listaCompleta;
      if (filiaisSelecionadas.size > 0) {
        listaFiltrada = listaFiltrada.filter((negociacao) =>
          filiaisSelecionadas.has(
            removerEspacosECaracteresEspeciais(negociacao.filial),
          ),
        );
      }
      return listaFiltrada;
    },
    [filiaisSelecionadas],
  );

  const filtrarPorStatus = useCallback(
    (listaCompleta: Negociacao[]) => {
      let listaFiltrada = listaCompleta;
      if (statusSelecionados.size > 0) {
        listaFiltrada = listaFiltrada.filter((negociacao) =>
          statusSelecionados.has(String(negociacao.status)),
        );
      }
      return listaFiltrada;
    },
    [statusSelecionados],
  );

  const tratarErro = useCallback(
    (erro: Error) => {
      dispatch(
        abrirSnackbarErro({
          mensagem: erro.message,
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    let listaNegociacoes = negociacoes;
    listaNegociacoes = filtrarPorFilial(listaNegociacoes);
    listaNegociacoes = filtrarPorStatus(listaNegociacoes);
    setNegociacoesFiltradas(listaNegociacoes);
  }, [filtrarPorFilial, filtrarPorStatus, negociacoes]);

  const demandasNegociador = useCallback(async () => {
    try {
      setLoadingDemanda(true);
      await obterNegociacoesNegociadores(String(colaboradorAssistido[0].id))
        .then((resposta) => {
          if (resposta.negociacoes.length > 0) {
            setNegociacoes(resposta.negociacoes);
            dispatch(atualizarNegociacoesUsuarioMaster(resposta.negociacoes));
          } else {
            setNegociacoesFiltradas([]);
          }
        })
        .catch(() => {
          dispatch(atualizarNegociacoesUsuarioMaster([]));
          setNegociacoesFiltradas([]);
        });
    } finally {
      setOpenDrawer(false);
      setNotificacao(false);
      setResetarOpcoesNegociadores(false);
      dispatch(ativarAmbienteAssistido(true));
      setLoadingDemanda(false);
    }
  }, [dispatch, colaboradorAssistido]);

  useEffect(() => {
    if (buscaColaboradores) {
      demandasNegociador();
    }
  }, [buscaColaboradores, demandasNegociador, dispatch]);

  const obterNegociacoes = useCallback(async () => {
    setDadosValidacao(true);
    await obterNegociacoesManager()
      .then((resposta) => {
        if (resposta.negociacoes.length > 0) {
          setNegociacoes(resposta.negociacoes);
        } else {
          dispatch(
            abrirSnackbarAviso({
              mensagem:
                mensagensMinhasNegociacoes.NENHUMA_NEGOCIACAO_ENCONTRADA,
            }),
          );
        }
        dispatch(atualizarRecebeuNovasNotificacoes());
      })
      .catch(() => {
        setNegociacoes([]);
      })
      .finally(() => {
        setDadosValidacao(false);
      });
  }, [dispatch]);

  const getPlanilha = async () => {
    await getPlanilhaManager(
      negociacoesFiltradas.map((negociacao) => negociacao.id),
    ).catch((erro: Error) => {
      tratarErro(erro);
    });
  };

  useEffect(() => {
    if (negociacoes.length === 0 && buscaColaboradores === false) {
      obterNegociacoes();
    }
  }, [buscaColaboradores, negociacoes.length, obterNegociacoes]);

  const close = () => {
    setOpenDrawer(true);
    setNotificacao(false);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
    setNotificacao(true);
    dispatch(ativarAmbienteAssistido(false));
    dispatch(dispararBuscaNegociacaoColaboradores(false));
    dispatch(atualizarDadosColaboradorAssistido([]));
  };

  const closeDrawerDemandasAndNegociador = async () => {
    await obterNegociacoes();
    setNotificacao(true);
    setOpenDrawer(false);
    setResetarOpcoesNegociadores(true);
    dispatch(ativarAmbienteAssistido(false));
    dispatch(dispararBuscaNegociacaoColaboradores(false));
    dispatch(atualizarDadosColaboradorAssistido([]));
  };

  const openDrawerDemandasAndNegociador = async () => {
    await obterNegociacoes();
    setOpenDrawer(true);
    dispatch(ativarAmbienteAssistido(false));
    dispatch(dispararBuscaNegociacaoColaboradores(false));
  };

  const selecionarNegociador = (
    selectedOptions: ColaboradoresNegociadores[],
  ) => {
    dispatch(atualizarDadosColaboradorAssistido(selectedOptions));
  };

  const notificacaoSnackbar = useMemo(() => {
    return (
      <Box
        style={{
          zIndex: 9999,
          position: 'fixed',
          bottom: 16,
          right: 16,
        }}
      >
        <NotificacaoSnackbar
          mensagem="Para transferir, toque em ver negociador"
          titulo="Gostaria de transferir uma negociação?"
          nameButton="Ver negociador"
          icone={<IconeTransferenciaNegociador />}
          onClose={() => close()}
          open
        />
      </Box>
    );
  }, []);

  return (
    <BoxPagina component="section">
      <TituloMinhasNegociacoes negociacoes={negociacoes} />
      <TabelaMinhasNegociacoes
        dadosValidacao={dadosValidacao}
        selectColaboradorDrawer={colaboradorAssistido}
        onAmbienteAssistido={ativandoAmbienteAssistido}
        closeDrawerDemandasAndNegociador={closeDrawerDemandasAndNegociador}
        openDrawerDemandasAndNegociador={openDrawerDemandasAndNegociador}
        negociacoes={negociacoesFiltradas}
        demandasNegociador={demandasNegociador}
      />
      {negociacoesFiltradas.length > 0 && (
        <DisablingTooltip title="Exportar Planilha">
          <Button
            variant="contained"
            color="primary"
            onClick={getPlanilha}
            endIcon={<ArrowForward />}
            className={classes.botaoPlanilha}
            type="submit"
            style={{ marginBottom: '120px' }}
          >
            Exportar planilha
          </Button>
        </DisablingTooltip>
      )}
      {openNotificacao && permissaoSupervisor && notificacaoSnackbar}
      <DrawerDemandas
        openDrawer={openDrawer}
        handleLimparCampos={handleCloseDrawer}
        handleCloseDrawer={handleCloseDrawer}
        selecionarNegociador={selecionarNegociador}
        demandasNegociador={demandasNegociador}
        resetarOpcoesNegociadores={resetarOpcoesNegociadores}
        loadingDemanda={loadingDemanda}
      />
    </BoxPagina>
  );
};

export default MinhasNegociacoes;
