import { createAction } from '@reduxjs/toolkit';
import { ValoresNegociacao } from './valoresCardReducer';

export const atualizarValorFrotaPropria = createAction<number | undefined>(
  'valoresCardAction/ATUALIZAR_VALOR_FROTA_PROPRIA',
);

export const atualizarValorFreteTerceiro = createAction<number | undefined>(
  'valoresCardAction/ATUALIZAR_VALOR_FRETE_TERCEIRO',
);

export const atualizarValorNegociacao = createAction<number | undefined>(
  'valoresCardAction/ATUALIZAR_VALOR_NEGOCIACAO',
);

export const atualizarTodosValores = createAction<ValoresNegociacao>(
  'valoresCardAction/ATUALIZAR_TODOS_VALORES',
);

export const limparDados = createAction<void>('valoresCardAction/LIMPAR_DADOS');
