import React, { ChangeEvent, useState } from 'react';
import { Box, Tab, Tabs } from '@material-ui/core';
import TabelaMacro from 'features/configuracoes/components/TabelaMacro';
import FormularioCadastroMacro from 'features/configuracoes/components/FormularioCadastroMacro';
import TabelaCidades from 'features/configuracoes/components/TabelaCidades';

const ConfiguracaoMacrofilial = (): JSX.Element => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: ChangeEvent<unknown>, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{ height: tabValue === 0 ? '25vh' : '80vh' }}
    >
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="Tabs de Configuração"
      >
        <Tab label="Cadastrar Macro" style={{ marginRight: '5%' }} />
        <Tab label="Listar/Atualizar Macro" style={{ marginRight: '5%' }} />
        <Tab label="Cadastrar/Atualizar Cidade" />
      </Tabs>
      <Box width="80%">
        {tabValue === 0 && <FormularioCadastroMacro />}
        {tabValue === 1 && <TabelaMacro />}
        {tabValue === 2 && <TabelaCidades />}
      </Box>
    </Box>
  );
};

export default ConfiguracaoMacrofilial;
