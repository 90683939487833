import React from 'react';
import { useSelector } from 'react-redux';

import { Box, makeStyles } from '@material-ui/core';

import { spacingInPixels } from '../../../../shared/functions/materialUtils';
import HeaderAccordion from '../../../../shared/components/accordion/HeaderAccordion';
import InformacaoHeaderCarga from './InformacaoHeaderCarga';
import InformacaoHeaderValores from './InformacaoHeaderValores';
import { RootState } from '../../../../store/reducer';

const useStyles = makeStyles((theme) => ({
  containerHeader: {
    paddingLeft: spacingInPixels(theme, 4),
    paddingRight: spacingInPixels(theme, 4),
    maxHeight: 96,
    height: 96,
    backgroundColor: '#F5F5F5',
    borderBottom: '1px  solid #d7d7d7',
  },
  conteudo: {
    height: '100%',
  },
}));

const InformacaoHeader = (): JSX.Element => {
  const classes = useStyles();

  const { negociacao } = useSelector(
    (state: RootState) => state.informacoesNegociacaoReducer,
  );

  return (
    <>
      {negociacao && (
        <HeaderAccordion
          styles={classes.containerHeader}
          iconPosition="iconEnd"
          iconEdge="end"
          classesProp={{ content: classes.conteudo }}
        >
          <Box
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
            paddingRight={2}
          >
            <InformacaoHeaderCarga />
            <InformacaoHeaderValores />
          </Box>
        </HeaderAccordion>
      )}
    </>
  );
};

export default InformacaoHeader;
