import React from 'react';

import TabPanel from '@material-ui/lab/TabPanel';
import { Box, Grid } from '@material-ui/core';

import { PropostaSupervisor } from '../../api/aprovacoesSupervisorApiTypes';
import AbasPropostaSupervisor from '../../constants/AbasPropostaSupervisor';

import CardPropostaReprovada from '../cardsProposta/CardPropostaReprovada';
import useStylesPanelProposta from '../../styles/panelProposta';
import ListaVazia from '../../components/ListaVazia';
import IconeCorretoCirculo from '../../../../assets/icones/IconeCorretoCirculo';

export type PanelReprovadosType = {
  propostas: PropostaSupervisor[];
};

const PanelReprovados = ({ propostas }: PanelReprovadosType): JSX.Element => {
  const classes = useStylesPanelProposta();
  return (
    <TabPanel
      className={classes.panelContainer}
      value={AbasPropostaSupervisor.Reprovados}
    >
      {propostas.length > 0 ? (
        <Grid container spacing={1} className={classes.gridConteiner}>
          {propostas.map((prop) => (
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={3}
              key={prop.idProposta}
            >
              <CardPropostaReprovada proposta={prop} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <ListaVazia
          icone={
            <Box className={classes.boxIcone}>
              <IconeCorretoCirculo height={70} width={70} />
            </Box>
          }
          texto="Não temos nenhum pedido reprovado nos últimos 30 dias."
        />
      )}
    </TabPanel>
  );
};

export default PanelReprovados;
