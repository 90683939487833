import React from 'react';

import { Motorista } from '../api/negociacoesAutomatizadasApiTypes';

import AvatarMotoristaBadge from './AvatarMotoristaBadge';
import AvatarMotoristaConteudo from './AvatarMotoristaConteudo';
import { verificarPropostaConcluida } from '../constants/EstadoAtualProposta';

export type AvatarMotoristaProps = {
  motorista: Motorista;
  idNegociacao: number;
};

const AvatarMotoristaNegociacaoConcluida = ({
  motorista,
  idNegociacao,
}: AvatarMotoristaProps): JSX.Element => {
  return (
    <>
      {verificarPropostaConcluida(motorista.status) ? (
        <AvatarMotoristaBadge
          idNegociacao={idNegociacao}
          motorista={motorista}
        />
      ) : (
        <AvatarMotoristaConteudo
          motorista={motorista}
          idNegociacao={idNegociacao}
          avatarPadrao={false}
        />
      )}
    </>
  );
};

export default AvatarMotoristaNegociacaoConcluida;
