import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { RootState } from '../../../store/reducer';
import titlesUploadSimulador from '../constants/titlesUploadSimulador';
import { useStyles } from '../styles/styleIconeCaixaUpload';

const IconeCaixaUpload = (): JSX.Element => {
  const classes = useStyles();

  const { arquivoEstaSelecionado, arquivoFoiEnviado } = useSelector(
    (state: RootState) => state.uploadSimuladorReducer,
  );

  return arquivoEstaSelecionado ? (
    <Box className={classes.caixaIcone}>
      {!arquivoFoiEnviado && (
        <img
          src="/images/uploadSimulador/icone-task-concluida.svg"
          alt={titlesUploadSimulador.ICONE_ARQUIVO_SELECIONADO}
          className={classes.iconeTask}
        />
      )}
      {arquivoFoiEnviado && (
        <img
          src="/images/uploadSimulador/icone-task-concluida-verde.svg"
          alt={titlesUploadSimulador.ICONE_ARQUIVO_ENVIADO_COM_SUCESSO}
          className={classes.iconeTask}
        />
      )}
    </Box>
  ) : (
    <Box className={classes.caixaIcone}>
      <img
        src="/images/uploadSimulador/u_cloud-upload.svg"
        alt={titlesUploadSimulador.ICONE_UPLOAD_ARQUIVO}
      />
    </Box>
  );
};

export default IconeCaixaUpload;
