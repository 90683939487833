import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ArrowForward from '@material-ui/icons/ArrowForward';
import MuiButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import { abrirSnackbarErro } from 'shared/components/snackbar/redux/snackbarAction';
import DisablingTooltip from '../../../shared/components/tooltip/DisablingTooltip';
import FeedbackSnackbar from '../../../shared/components/snackbar/FeedbackSnackbar';

import { RootState } from '../../../store/reducer';
import { limparDados } from '../../listaCargas/redux/listaCargasAction';
import { removerTodasNegociacoes } from '../redux/minhasCargasActions';

import { criarNegociacoes } from '../api/minhasCargasManager';
import { PATH_MINHAS_NEGOCIACOES } from '../../../shared/constants/pathConstants';
import { NovaNegociacao } from '../api/minhasCargasApiTypes';

const Button = withStyles({
  root: {
    textTransform: 'none',
  },
  label: {
    fontSize: '16px',
    padding: '4px 40px',
  },
})(MuiButton);

const BotaoCriarNegociacoes = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [botaoHabilitado, setBotaoHabilitado] = useState(false);

  const [snackbarErroAberto, setSnackbarErroAberto] = useState(false);
  const [mensagemErro, setMensagemErro] = useState('');

  const { idsCargasSelecionadas: idsCargas } = useSelector(
    (reducer: RootState) => reducer.listaCargasReducer,
  );

  const { novasNegociacoes } = useSelector(
    (reducer: RootState) => reducer.minhasCargasReducer,
  );

  const { dados } = useSelector((state: RootState) => state.loginReducer);

  useEffect(() => {
    setBotaoHabilitado(
      novasNegociacoes.length === idsCargas.size &&
        novasNegociacoes.every(
          (negociacao) =>
            negociacao.dataCarregamento && negociacao.horaCarregamento,
        ),
    );
  }, [idsCargas.size, novasNegociacoes]);

  const validarNegociadorIndicado = (
    negociacoes: NovaNegociacao[],
    idColaborador: number,
  ): NovaNegociacao[] => {
    return negociacoes.map((negociacao) => {
      if (
        negociacao.idNegociadorIndicado === null ||
        negociacao.idNegociadorIndicado === undefined
      ) {
        return {
          ...negociacao,
          idNegociadorIndicado: idColaborador,
        };
      }
      return negociacao;
    });
  };

  const concluirCriacaoNegociacao = async () => {
    dispatch(limparDados());
    dispatch(removerTodasNegociacoes());
    history.push(PATH_MINHAS_NEGOCIACOES);
  };

  const criarNovaNegociacao = async () => {
    const idColaborador = dados?.usuario.dadosPermissao.idColaborador;
    if (idColaborador === undefined) {
      dispatch(
        abrirSnackbarErro({ mensagem: 'Id do colaborador está incorreto' }),
      );
      return;
    }

    const novasNegociacoesValidadas = validarNegociadorIndicado(
      novasNegociacoes,
      idColaborador,
    );

    await criarNegociacoes({
      negociacoes: novasNegociacoesValidadas,
    })
      .then(async () => {
        await concluirCriacaoNegociacao();
        localStorage.removeItem('selectedColaborador');
      })

      .catch((e) => {
        setMensagemErro(e.message);
        setSnackbarErroAberto(true);
      });
  };

  const mensagemSnackBar =
    mensagemErro === ''
      ? 'Não foi possível criar estas negociações no momento.'
      : mensagemErro;

  return (
    <>
      <DisablingTooltip title="Criar Negociações" disabled={!botaoHabilitado}>
        <Button
          variant="contained"
          color="primary"
          endIcon={<ArrowForward />}
          disabled={!botaoHabilitado}
          onClick={criarNovaNegociacao}
        >
          Criar Negociações
        </Button>
      </DisablingTooltip>
      <FeedbackSnackbar
        open={snackbarErroAberto}
        onClose={() => {
          setSnackbarErroAberto(false);
        }}
        severity="error"
        mensagem={mensagemSnackBar}
      />
    </>
  );
};

export default BotaoCriarNegociacoes;
