import { Grid, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import CustomSkeleton from 'shared/components/skeleton/CustomSkeleton';

const useStyles = makeStyles(() => ({
  informacaoFilial: {
    display: 'flex',
    gap: '12px',
    paddingLeft: '16px',
    paddingTop: '6px',
    alignItems: 'center',
  },
  prioridadeCarga: {
    display: 'flex',
    paddingLeft: '1.25vw',
    alignSelf: 'center',
    '& > span': {
      marginRight: '5px',
    },
  },
  demandasFilial: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
const SkeletonItemTabelaFiliais = (): JSX.Element => {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item md={4} lg={4}>
        <section className={classes.informacaoFilial}>
          <Skeleton variant="circle" width={34} height={34} />
          <CustomSkeleton width="40%" height={20} borderradius={4} />
        </section>
      </Grid>
      <Grid item md={3} lg={3} style={{ alignSelf: 'center' }}>
        <section className={classes.prioridadeCarga}>
          {new Array(3).fill(null).map((_, index) => (
            <CustomSkeleton
              width={38}
              height={26}
              borderradius={16}
              key={`skeleton-prioridade-carga-${index + 1}`}
            />
          ))}
        </section>
      </Grid>
      <Grid item md={4} lg={4}>
        <section className={classes.demandasFilial}>
          {new Array(5).fill(null).map((_, index) => (
            <Skeleton
              width="15%"
              height={44}
              key={`skeleton-status-demanda-${index + 1}`}
            />
          ))}
        </section>
      </Grid>
    </Grid>
  );
};

export default SkeletonItemTabelaFiliais;
