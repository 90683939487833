import React from 'react';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';

import { useStyles } from '../../styles/chatBalao';
import { Rubik } from '../../../../shared/constants/font';

import ChatHora from './ChatHora';
import { RootState } from '../../../../store/reducer';
import { ChatBalaoProps } from './ChatBalao';
import MensagemMotorista from './MensagemMotorista';
import spacingInPixels from '../../../../shared/functions/materialUtils';
import { obterPrimeiroNomeMotorista } from '../../utils/chatBalao';
import ChatBalaoValores from './ChatBalaoValores';
import TipoMensagemProposta from '../../../../shared/constants/TipoMensagemProposta';

const TypographyNome = withStyles((theme) => ({
  root: {
    color: theme.palette.cores.azulMartins[500],
    fontFamily: Rubik,
    fontSize: '13px',
    fontWeight: 500,
    lineHeight: spacingInPixels(theme, 2),
  },
}))(Typography);

const ChatBalaoMotorista = ({ mensagem }: ChatBalaoProps): JSX.Element => {
  const classes = useStyles();

  const { chatNomeMotorista } = useSelector(
    (state: RootState) => state.chatNegociacaoReducer,
  );

  const nomeFormatado = obterPrimeiroNomeMotorista(chatNomeMotorista);
  return (
    <Box display="flex" flexDirection="column">
      <Box className={[classes.balao, classes.balaoMotorista].join(' ')}>
        <TypographyNome>{nomeFormatado}</TypographyNome>
        <MensagemMotorista mensagem={mensagem} />
        <ChatHora data={mensagem.dataEnvio} />
      </Box>
      {mensagem.tipo === TipoMensagemProposta.Contraproposta && (
        <ChatBalaoValores valor={mensagem.valorContraproposta} />
      )}
    </Box>
  );
};

export default ChatBalaoMotorista;
