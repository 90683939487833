import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import { RootState } from '../../../../store/reducer';

import ConfirmarDialogButton from '../../../../shared/components/button/ConfirmarDialogButton';
import spacingInPixels from '../../../../shared/functions/materialUtils';
import DialogTitle from '../../../../shared/components/modal/DialogTitle';

import msgConstants from '../../../../shared/constants/msgConstants';
import { estaEmBranco } from '../../../../shared/functions/stringUtils';
import Bold from '../../../../shared/components/typography/Bold';
import Dialog from '../../../../shared/components/modal/Dialog';

import { recusarPropostaOuAbrirModalDeReatribuicao } from './redux/motoristasCardOperation';
import {
  atualizarMotivoRecusarProposta,
  fecharModalRecusarProposta,
} from './redux/motoristasCardAction';
import SelectMotivoRecusa from '../../../../shared/components/select/SelectMotivoRecusa';

const useStyles = makeStyles((theme) => ({
  paragrafo: {
    marginBottom: spacingInPixels(theme, 2),
  },
  select: {
    width: '50%',
    margin: theme.spacing(3, 0, 3, 0),
  },
}));

const RecusarPropostaDialog = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { idNegociacao } = useSelector(
    (state: RootState) => state.informacoesNegociacaoReducer,
  );

  const {
    modalRecusarPropostaAberto,
    propostaARecusar,
    motivoRecusarProposta,
  } = useSelector((state: RootState) => state.motoristasCardReducer);

  const [erro, setErro] = useState(false);
  const [mensagemErro, setMensagemErro] = useState('');

  const preencherErro = () => {
    setErro(true);
    setMensagemErro(msgConstants.CAMPO_OBRIGATORIO);
  };

  const limparErro = () => {
    setErro(false);
    setMensagemErro('');
  };

  const fechar = () => {
    limparErro();
    dispatch(fecharModalRecusarProposta());
  };

  const verificarCampoPreenchido = (): boolean => {
    if (estaEmBranco(motivoRecusarProposta)) {
      preencherErro();
      return false;
    }

    limparErro();
    return true;
  };

  const onSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch(atualizarMotivoRecusarProposta(String(event.target.value)));
    limparErro();
  };

  const recusarProposta = async () => {
    if (propostaARecusar && verificarCampoPreenchido()) {
      await recusarPropostaOuAbrirModalDeReatribuicao(
        idNegociacao,
        propostaARecusar.id,
        propostaARecusar.status,
        Number(motivoRecusarProposta),
      )(dispatch);
    }
  };

  return (
    <Dialog open={modalRecusarPropostaAberto} onClose={fechar}>
      <DialogTitle onClose={fechar} />
      <DialogContent>
        <Box>
          <Typography variant="body1" className={classes.paragrafo}>
            Você deseja realmente recusar essa proposta com{' '}
            <Bold>{propostaARecusar?.motorista}</Bold>?
          </Typography>
          <Typography variant="body1" className={classes.paragrafo}>
            Por qual motivo você deseja recusar essa proposta?
          </Typography>
        </Box>
        <Box className={classes.select}>
          <SelectMotivoRecusa
            value={motivoRecusarProposta}
            onChange={onSelectChange}
            label="Motivo recusar proposta"
            onBlur={verificarCampoPreenchido}
            error={erro}
            mensagemErro={mensagemErro}
            id="select-motivos-recusar-proposta"
          />
        </Box>
        <DialogActions>
          <ConfirmarDialogButton
            textoBotao="Recusar a proposta"
            onClick={recusarProposta}
            disabled={false}
          />
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default RecusarPropostaDialog;
