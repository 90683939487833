import React from 'react';
import CelulaNegociador from 'shared/components/tabelaDemandas/CelulaNegociador';
import CelulaConteudoTexto from 'shared/components/tabelaDemandas/CelulaConteudoTexto';
import { formatarDataBrasileira } from 'shared/functions/dataUtils';
import { formatarCycleTime } from 'shared/functions/IntervaloTempo';
import CelulaConteudoTextoComPorcentagem from 'shared/components/tabelaDemandas/CelulaConteudoTextoComPorcentagem';
import { tratarValorMonetario } from 'shared/functions/dinheiroUtils';
import { tratarValorPorcentagem } from 'shared/functions/porcentagemUtils';
import CelulaStatusPrioridade from 'shared/components/tabelaDemandas/CelulaStatusPrioridade';
import { useStyles } from 'features/detalhesDemandasDiaria/styles/styleCorpoTabela';
import { ConteudoDemandas } from 'features/detalhesDemandasDiaria/types/tabelaTypes';
import { estilosTabelaDemandasDoDia } from '../../styles/styleTabela';
import CelulaStatusDemanda from './CelulaStatusDemanda';

const ConteudoTabelaDemandasDoDia = ({
  demandas,
}: ConteudoDemandas): JSX.Element => {
  const classes = useStyles({ altura: true });
  const { gridTabela } = estilosTabelaDemandasDoDia();

  return (
    <tbody className={classes.corpoTabela}>
      {demandas.map((demanda) => (
        <tr
          className={gridTabela}
          key={`${demanda.idNegociacao}-${demanda.romaneio}`}
        >
          <CelulaStatusPrioridade prioridadeCarga={demanda.prioridadeCarga} />
          <CelulaConteudoTexto
            conteudo={formatarDataBrasileira(
              new Date(demanda.dataCarregamento),
            )}
          />
          <CelulaNegociador nomeNegociador={demanda.nomeNegociador} />
          <CelulaConteudoTexto conteudo={demanda.nomeFilial} />
          <CelulaConteudoTexto
            conteudo={demanda.quantidadeEntregas.toLocaleString('pt-br')}
          />
          <CelulaConteudoTexto
            conteudo={`${formatarCycleTime(
              demanda.leadTime,
            )}/${formatarCycleTime(demanda.cycleTime)}`}
          />
          <CelulaConteudoTexto conteudo={demanda.nomeCidade} />
          <CelulaConteudoTexto conteudo={demanda.romaneio} />
          <CelulaConteudoTextoComPorcentagem
            texto={tratarValorMonetario(demanda?.valorUltimaContraproposta)}
            porcentagem={
              demanda?.savingUltimaContraproposta
                ? tratarValorPorcentagem(demanda?.savingUltimaContraproposta)
                : undefined
            }
            complemento="Target"
          />
          <CelulaConteudoTextoComPorcentagem
            texto={tratarValorMonetario(demanda?.valorMeta)}
            porcentagem={
              demanda?.valorMeta
                ? tratarValorPorcentagem(demanda?.savingValorMeta)
                : undefined
            }
            complemento="Target"
          />
          <CelulaStatusDemanda status={demanda.status} />
        </tr>
      ))}
    </tbody>
  );
};

export default ConteudoTabelaDemandasDoDia;
