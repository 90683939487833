import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { useTheme } from '@material-ui/core/styles';

import { spacingInPixels } from '../../../../shared/functions/materialUtils';

import HeaderItem from './HeaderItem';

const Header = (): JSX.Element => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      alignItems="center"
      paddingLeft={spacingInPixels(theme, 7)}
      paddingRight={spacingInPixels(theme, 2)}
      borderBottom={`1px solid ${theme.palette.cores.cinza[500]}`}
      height={spacingInPixels(theme, 6)}
    >
      <Grid container spacing={2}>
        <HeaderItem
          xs={3}
          titulo="ID"
          icone={
            <img src="/images/minhasNegociacoes/icone-carga.svg" alt="ID" />
          }
        />
        <HeaderItem
          xs={2}
          titulo="Solicitação"
          icone={
            <img
              src="/images/minhasNegociacoes/icone-calendario.svg"
              alt="Solicitação"
            />
          }
        />
        <HeaderItem
          xs={3}
          titulo="Carregamento"
          icone={
            <img
              src="/images/minhasNegociacoes/icone-calendario.svg"
              alt="Carregamento"
            />
          }
        />
        <HeaderItem
          xs={2}
          titulo="Motorista Indicado"
          icone={
            <img
              src="/images/minhasNegociacoes/icone-motorista.svg"
              alt="Motorista Indicado"
            />
          }
        />
        <HeaderItem
          xs={2}
          titulo="Observação"
          icone={<VisibilityOutlinedIcon color="primary" />}
        />
      </Grid>
    </Box>
  );
};

export default Header;
