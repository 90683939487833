import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import PropostaStatus from '../../../../../shared/constants/PropostaStatus';
import TextoItemLista from '../listaDisponiveis/TextoItemLista';
import BoxStatusProposta from '../listaEmNegociacao/BoxStatusProposta';
import spacingInPixels from '../../../../../shared/functions/materialUtils';

export type BoxTituloCardPropostaPropTypes = {
  nomeMotorista: string;
  statusProposta: PropostaStatus;
  propostaSelecionada: boolean;
};

const useStyles = makeStyles((theme) => ({
  possicionamentoDoTexto: {
    paddingBottom: '20px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  nomeMotorista: {
    color: ({ propostaSelecionada }: { propostaSelecionada: boolean }) =>
      propostaSelecionada
        ? theme.palette.cores.branco
        : theme.palette.cores.azulMartins[500],
    marginLeft: spacingInPixels(theme, 0.7),
  },
}));

const TituloCardProposta = ({
  nomeMotorista,
  statusProposta,
  propostaSelecionada,
}: BoxTituloCardPropostaPropTypes): JSX.Element => {
  const classes = useStyles({
    propostaSelecionada,
  });
  return (
    <Box className={classes.possicionamentoDoTexto}>
      <Box>
        <TextoItemLista className={classes.nomeMotorista}>
          {nomeMotorista.toLowerCase()}
        </TextoItemLista>
      </Box>
      <Box>
        <BoxStatusProposta codigoStatus={statusProposta} />
      </Box>
    </Box>
  );
};

export default TituloCardProposta;
