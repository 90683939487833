import React from 'react';
import { Box, Typography, Tooltip } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { Rubik } from 'shared/constants/font';
import { spacingInPixels } from '../../functions/materialUtils';

export type InformacaoItemProps = {
  icone: string | JSX.Element;
  titulo?: string;
  informacao?: string;
  altIcone: string;
  titleIcone: string;
  estilo?: {
    iconeBackGround?: string;
    tituloColor?: string;
  };
  countPJ?: number;
  countPF?: number;
};

const useStyles = makeStyles((theme) => ({
  titulo: {
    fontSize: '12px',
    color: theme.palette.text.secondary,
  },
  icone: {
    width: '24px',
    height: '24px',
  },
  iconeBackGround: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: spacingInPixels(theme, 1),
    width: spacingInPixels(theme, 6),
    height: spacingInPixels(theme, 6),
  },
  iconePuro: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  informacao: {
    fontSize: '14px',
    fontFamily: Rubik,
    fontWeight: 500,
    maxWidth: 180,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  countPjPf: {
    color: theme.palette.cores.cinza[700],
    fontWeight: 500,
    fontSize: '13px',
  },
}));

const InformacaoItem = (props: InformacaoItemProps): JSX.Element => {
  const {
    icone,
    titulo,
    informacao,
    altIcone,
    titleIcone,
    estilo,
    countPF,
    countPJ,
  } = props;
  const classes = useStyles();
  const theme = useTheme();

  const componenteIcone =
    typeof icone === 'string' ? (
      <img src={icone} alt={altIcone} className={classes.icone} />
    ) : (
      icone
    );
  return (
    <Box display="flex">
      <Tooltip title={titleIcone}>
        {estilo?.iconeBackGround ? (
          <Box
            className={classes.iconeBackGround}
            bgcolor={estilo.iconeBackGround}
          >
            {componenteIcone}
          </Box>
        ) : (
          <Box className={classes.iconePuro}>{componenteIcone}</Box>
        )}
      </Tooltip>
      <Box
        display="flex"
        flexDirection="column"
        style={{
          marginLeft: spacingInPixels(theme, 1),
        }}
      >
        <Typography
          variant="h6"
          className={classes.titulo}
          style={{ color: estilo?.tituloColor }}
        >
          {titulo}
        </Typography>
        <Typography className={classes.informacao}>
          {informacao || '-'}
          <span className={classes.countPjPf}>
            {titulo === 'Entregas' ? ` (${countPJ} PJ / ${countPF} PF)` : ''}
          </span>
        </Typography>
      </Box>
    </Box>
  );
};

InformacaoItem.defaultProps = {
  titulo: undefined,
  estilo: {
    iconeBackGround: undefined,
    tituloColor: undefined,
  },
};

export default InformacaoItem;
