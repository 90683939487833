import { makeStyles, Typography, Box, useTheme } from '@material-ui/core';
import React from 'react';

import IconeRelogio from '../../../../assets/icones/IconeRelogio';
import spacingInPixels from '../../../../shared/functions/materialUtils';

import { converterDataApiParaDataChat } from '../../../chatNegociacao/utils/dataHora';

type DataHoraEventoProps = {
  dataHora: string;
};

const useStyles = makeStyles((theme) => ({
  containerDataHora: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  texto: {
    color: theme.palette.cores.cinza[700],
    fontWeight: 400,
    fontSize: '12px',
    marginRight: spacingInPixels(theme, 0.5),
  },
}));

const DataHoraEvento = ({ dataHora }: DataHoraEventoProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box className={classes.containerDataHora}>
      <Typography className={classes.texto}>
        {converterDataApiParaDataChat(dataHora)}
      </Typography>
      <IconeRelogio
        fill={theme.palette.cores.cinza[500]}
        width={12}
        height={12}
      />
    </Box>
  );
};

export default DataHoraEvento;
