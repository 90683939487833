import React from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core';

import { RootState } from '../../../../store/reducer';

import { spacingInPixels } from '../../../../shared/functions/materialUtils';
import AccordionDetails from '../../../../shared/components/accordion/AccordionDetails';
import ObservacaoGrid from './ObservacaoGrid';

const useStyles = makeStyles((theme) => ({
  containerDetails: {
    paddingTop: spacingInPixels(theme, 2),
    paddingBottom: spacingInPixels(theme, 2),
    paddingLeft: spacingInPixels(theme, 4),
    paddingRight: spacingInPixels(theme, 4),
    position: 'absolute',
    left: '0',
    backgroundColor: theme.palette.cores.cinza[200],
    width: '100%',
  },
}));

const InformacaoDetails = (): JSX.Element => {
  const classes = useStyles();

  const { negociacao } = useSelector(
    (state: RootState) => state.informacoesNegociacaoReducer,
  );

  return (
    <>
      {negociacao && (
        <AccordionDetails className={classes.containerDetails}>
          <ObservacaoGrid />
        </AccordionDetails>
      )}
    </>
  );
};

export default InformacaoDetails;
