import React from 'react';
import { makeStyles } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';
import { IndicadorNegociacao } from 'features/indicadores/api/indicadoresApiTypes';
import ConteudoErro from 'features/indicadores/components/ConteudoErro';
import {
  mensagemErro,
  mensagemListaVazia,
} from 'features/indicadores/constants/Constants';
import TabelaIndicadoresMacros from './TabelaIndicadoresMacros';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacingInPixels(theme, 2.5),
    minHeight: 428,
    overflowX: 'auto',

    '&::-webkit-scrollbar': {
      height: spacingInPixels(theme, 0.75),
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      background: `${theme.palette.cores.cinza[500]}60`,
      borderRadius: spacingInPixels(theme, 1),
    },
  },
  containerErro: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'center',
    height: 616,
    paddingBottom: spacingInPixels(theme, 6),
  },
}));

interface ConteudoIndicadoresMacrosProps {
  erro: boolean;
  onClickTentarNovamente: () => void;
  dadosIndicadores: IndicadorNegociacao[];
  valoresPeriodo: string[];
}

const ConteudoIndicadoresMacros = ({
  erro,
  onClickTentarNovamente,
  dadosIndicadores,
  valoresPeriodo,
}: ConteudoIndicadoresMacrosProps): JSX.Element => {
  const classes = useStyles();

  const handleExibicaoConteudo = () => {
    if (erro)
      return (
        <section className={classes.containerErro}>
          <ConteudoErro
            onClickBotao={onClickTentarNovamente}
            mensagem={mensagemErro}
          />
        </section>
      );
    if (dadosIndicadores.length === 0)
      return <ConteudoErro mensagem={mensagemListaVazia} />;
    return (
      <TabelaIndicadoresMacros
        valoresPeriodo={valoresPeriodo}
        dadosIndicadores={dadosIndicadores}
      />
    );
  };

  return (
    <section className={classes.container}>{handleExibicaoConteudo()}</section>
  );
};

export default ConteudoIndicadoresMacros;
