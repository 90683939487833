import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Box, withStyles, makeStyles } from '@material-ui/core';

import spacingInPixels from 'shared/functions/materialUtils';
import {
  Negociacao,
  OrigemNegociacao,
  PropostaNegociacao,
} from '../../../api/informacoesNegociacaoApiTypes';

import { abrirChat as abrirChatAction } from '../../../../chatNegociacao/redux/chatNegociacaoAction';
import { RootState } from '../../../../../store/reducer';
import { atualizarIdPropostaComFoco } from '../../../redux/informacoesNegociacaoAction';
import { validarPropostaFocada } from './utils/proposta';
import PropostaInformacoes from './PropostaInformacoes';
import TituloCardProposta from '../components/TituloCardProposta';

type PropostaHeaderAplicativoProps = {
  sequencia: number;
  proposta: PropostaNegociacao;
};

const BoxPropostaAplicativo = withStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: ({ index }: { index: number }) =>
      index % 2 === 0 ? theme.palette.cores.branco : '#F5F5F5',
    padding: '20px 20px 22.5px 20px',
    justifyContent: 'space-evenly',
    cursor: 'pointer',
    marginBottom: '4px',
    border: '1px solid #E0E0E0',
    borderRadius: '4px 4px 0px 0px',
  },
}))(Box);

const useStyles = makeStyles((theme) => ({
  nomeMotorista: {
    color: ({ propostaSelecionada }: { propostaSelecionada: boolean }) =>
      propostaSelecionada
        ? theme.palette.cores.branco
        : theme.palette.cores.azulMartins[500],
    marginLeft: spacingInPixels(theme, 0.7),
  },
  itemSelecionado: {
    backgroundColor: theme.palette.cores.azul[700],
  },
}));

const PropostaAplicativoCard = ({
  sequencia,
  proposta,
}: PropostaHeaderAplicativoProps): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { negociacao: negociacaoFreteTabelado } = useSelector(
    (state: RootState) => state.informacoesNegociacaoReducer,
  );

  const { ativandoAmbienteAssistido, colaboradorAssistido } = useSelector(
    (state: RootState) => state.minhasNegociacoesReducer,
  );

  const { idNegociacao, negociacao, idPropostaComFoco } = useSelector(
    (state: RootState) => state.informacoesNegociacaoReducer,
  );

  const classes = useStyles({
    propostaSelecionada: validarPropostaFocada(idPropostaComFoco, proposta.id),
  });

  const atualizarUrl = (
    idNegociacaoAtualizarUrl: number,
    idPropostaAtualizarUrl: number,
  ) => {
    if (
      negociacaoFreteTabelado?.origemNegociacao ===
      OrigemNegociacao.freteTabelado
    ) {
      const estadoPersonalizado = {
        origemFreteTabelado: true,
        idFilial: negociacaoFreteTabelado?.carga.idFilialDestino,
      };
      history.push({
        pathname: `/negociacoes-automatizada/${idNegociacaoAtualizarUrl}?idProposta=${idPropostaAtualizarUrl}`,
        state: estadoPersonalizado,
      });
      return;
    }

    if (ativandoAmbienteAssistido) {
      history.push(
        `/minhas-negociacoes/${idNegociacaoAtualizarUrl}?idProposta=${idPropostaAtualizarUrl}`,
        colaboradorAssistido && {
          idUsuarioMaster: colaboradorAssistido[0]?.id,
          origemFreteTabelado: false,
          idFilial: negociacao?.carga.idFilialDestino,
        },
      );
      return;
    }

    history.push(
      `/minhas-negociacoes/${idNegociacaoAtualizarUrl}?idProposta=${idPropostaAtualizarUrl}`,
      {
        origemFreteTabelado: false,
        idFilial: negociacao?.carga.idFilialDestino,
      },
    );
  };

  const abrirChat = useCallback(() => {
    dispatch(
      abrirChatAction({
        chatIdNegociacao: idNegociacao,
        chatIdProposta: proposta.id,
        chatNomeMotorista: proposta.motorista,
        chatValorFrotaPropria: (negociacao as Negociacao)
          .valorFrotaPropria as number,
        chatRomaneio: (negociacao as Negociacao).carga.romaneio,
        chatListaClientesLiberada: proposta.listaClientesLiberada,
        chatOrigemCarga: (negociacao as Negociacao).carga.origem,
        chatPropostaStatus: proposta.status,
        chatMotivoRecusa: proposta.motivoRecusa,
        chatValorFreteTerceiro: (negociacao as Negociacao)
          .valorFreteTerceiro as number,
        chatValorMecadoria: (negociacao as Negociacao).carga.valor,
        chatQuilometragem: (negociacao as Negociacao).carga.quilometragem,
        chatEntregas: (negociacao as Negociacao).carga.entregas,
        chatPeso: (negociacao as Negociacao).carga.peso,
        metaValorProposta: (negociacao as Negociacao).valorNovaMeta,
      }),
    );
    dispatch(atualizarIdPropostaComFoco(proposta.id));
  }, [
    dispatch,
    idNegociacao,
    proposta.id,
    proposta.motorista,
    proposta.listaClientesLiberada,
    proposta.status,
    proposta.motivoRecusa,
    negociacao,
  ]);

  useEffect(() => {
    if (validarPropostaFocada(idPropostaComFoco, proposta.id)) {
      dispatch(atualizarIdPropostaComFoco(undefined));
      abrirChat();
    }
  }, [abrirChat, dispatch, idPropostaComFoco, proposta.id]);

  return (
    <BoxPropostaAplicativo
      index={sequencia}
      onClick={() => atualizarUrl(idNegociacao, proposta.id)}
      className={
        validarPropostaFocada(idPropostaComFoco, proposta.id)
          ? classes.itemSelecionado
          : ''
      }
    >
      <TituloCardProposta
        nomeMotorista={proposta.motorista}
        statusProposta={proposta.status}
        propostaSelecionada={validarPropostaFocada(
          idPropostaComFoco,
          proposta.id,
        )}
      />
      <PropostaInformacoes
        proposta={proposta}
        propostaSelecionada={validarPropostaFocada(
          idPropostaComFoco,
          proposta.id,
        )}
      />
    </BoxPropostaAplicativo>
  );
};

export default PropostaAplicativoCard;
