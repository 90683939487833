import * as React from 'react';
import { SVGProps } from 'react';
import theme from '../../theme';

export type propsSvg = SVGProps<SVGSVGElement> & { corIcone?: string };

const IconePolegar = ({ fill, corIcone, ...outros }: propsSvg): JSX.Element => {
  return (
    <svg
      version="1.0"
      fill="none"
      width="40.000000pt"
      height="40.000000pt"
      viewBox="0 0 40.000000 40.000000"
      preserveAspectRatio="xMidYMid meet"
      {...outros}
    >
      <g
        transform="translate(0.000000,40.000000) scale(0.100000,-0.100000)"
        fill={fill || theme.palette.cores.cinza[900]}
        stroke="none"
      >
        <path
          d="M173 388 c-6 -7 -22 -39 -36 -70 -23 -53 -29 -58 -57 -58 -19 0 -40
-9 -55 -25 -22 -22 -25 -32 -25 -105 0 -73 3 -83 25 -105 23 -24 29 -25 165
-25 167 0 171 2 189 106 7 38 15 77 18 86 2 10 -6 28 -21 43 -20 20 -35 25
-75 25 l-51 0 5 43 c6 45 -4 65 -42 85 -27 15 -28 15 -40 0z m47 -49 c8 -15 8
-29 1 -51 -14 -38 -14 -34 5 -52 10 -11 34 -16 75 -16 l59 0 -1 -32 c0 -18 -6
-58 -12 -88 l-12 -55 -97 -3 -98 -3 0 104 c0 90 4 113 25 161 27 60 38 68 55
35z m-120 -209 l0 -90 -30 0 -30 0 0 90 0 90 30 0 30 0 0 -90z"
          fill={corIcone || theme.palette.cores.cinza[900]}
        />
      </g>
    </svg>
  );
};

export default IconePolegar;
