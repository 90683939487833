import * as React from 'react';
import { SVGProps } from 'react';

const IconeGraphicFreteTabelado = ({
  ...outros
}: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg width="18" height="17" viewBox="0 0 18 18" fill="none" {...outros}>
      <path
        d="M16.5001 15.666H15.6667V3.16602C15.6667 2.945 15.579 2.73304 15.4227 2.57676C15.2664 2.42048 15.0544 2.33268 14.8334 2.33268C14.6124 2.33268 14.4004 2.42048 14.2442 2.57676C14.0879 2.73304 14.0001 2.945 14.0001 3.16602V15.666H12.3334V9.83268C12.3334 9.61167 12.2456 9.39971 12.0893 9.24343C11.9331 9.08715 11.7211 8.99935 11.5001 8.99935C11.2791 8.99935 11.0671 9.08715 10.9108 9.24343C10.7545 9.39971 10.6667 9.61167 10.6667 9.83268V15.666H9.00008V6.49935C9.00008 6.27834 8.91228 6.06637 8.756 5.91009C8.59972 5.75381 8.38776 5.66602 8.16675 5.66602C7.94573 5.66602 7.73377 5.75381 7.57749 5.91009C7.42121 6.06637 7.33342 6.27834 7.33342 6.49935V15.666H5.66675V13.166C5.66675 12.945 5.57895 12.733 5.42267 12.5768C5.26639 12.4205 5.05443 12.3327 4.83342 12.3327C4.6124 12.3327 4.40044 12.4205 4.24416 12.5768C4.08788 12.733 4.00008 12.945 4.00008 13.166V15.666H2.33341V1.49935C2.33341 1.27834 2.24562 1.06637 2.08934 0.910093C1.93306 0.753813 1.7211 0.666016 1.50008 0.666016C1.27907 0.666016 1.06711 0.753813 0.910826 0.910093C0.754545 1.06637 0.666748 1.27834 0.666748 1.49935V16.4993C0.666748 16.7204 0.754545 16.9323 0.910826 17.0886C1.06711 17.2449 1.27907 17.3327 1.50008 17.3327H16.5001C16.7211 17.3327 16.9331 17.2449 17.0893 17.0886C17.2456 16.9323 17.3334 16.7204 17.3334 16.4993C17.3334 16.2783 17.2456 16.0664 17.0893 15.9101C16.9331 15.7538 16.7211 15.666 16.5001 15.666Z"
        fill="#008C41"
      />
    </svg>
  );
};

export default IconeGraphicFreteTabelado;
