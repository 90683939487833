import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  TextField,
  MenuItem,
  Button,
  Grid,
  Modal,
  Paper,
  Box,
} from '@material-ui/core';
import {
  buscarCategoriaVeiculosFreteTabelado,
  buscarFiliais,
} from 'features/configuracoes/utils/configuracoes';
import {
  BuscarFiliaisResponse,
  CategoriaVeiculo,
} from 'features/configuracoes/api/configuracoesApiTypes';
import { useDispatch } from 'react-redux';

interface MotoristasVeiculosFiltersProps {
  onFilter: (filtro: {
    ordenacaoFilter: string;
    idFilial: string;
    idVeiculo: string;
    searchNome: string;
  }) => void;
  open: boolean;
  onClose: () => void;
}

const MotoristasVeiculosFilters = ({
  onFilter,
  open,
  onClose,
}: MotoristasVeiculosFiltersProps): JSX.Element => {
  const dispatch = useDispatch();
  const [ordenacaoFilter, setOrdenacaoFilter] = useState('');
  const [idFilial, setIdFilial] = useState('');
  const [idVeiculo, setIdVeiculo] = useState('');

  const [searchNome, setSearchNome] = useState('');
  const [dadosVeiculos, setDadosVeiculos] = useState<CategoriaVeiculo[]>([]);
  const [filiaisDados, setFiliaisDados] = useState<BuscarFiliaisResponse[]>([]);

  const ordernacao = [
    { id: '0', descricao: 'Nome', value: 'nome' },
    { id: '1', descricao: 'Filial', value: 'filial' },
    { id: '2', descricao: 'CPF', value: 'cpf' },
    { id: '3', descricao: 'Tipo veiculo', value: 'tipoVeiculo' },
  ];

  const handleFilter = () => {
    onFilter({ ordenacaoFilter, idFilial, idVeiculo, searchNome });
    onClose();
  };

  const handleCleanFilter = () => {
    setOrdenacaoFilter('');
    setIdFilial('');
    setIdVeiculo('');
    setSearchNome('');
    onFilter({
      ordenacaoFilter: '',
      idFilial: '',
      idVeiculo: '',
      searchNome: '',
    });
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchNome(event.target.value);
  };

  useEffect(() => {
    buscarCategoriaVeiculosFreteTabelado(setDadosVeiculos, dispatch);
    buscarFiliais(setFiliaisDados, dispatch);
  }, [dispatch]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="filter-modal-title"
      aria-describedby="filter-modal-description"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Paper style={{ padding: '20px', width: '50%' }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6}>
            <TextField
              select
              fullWidth
              label="Filiais"
              variant="outlined"
              value={idFilial}
              onChange={(e) => setIdFilial(e.target.value)}
            >
              <MenuItem value="">Selecione uma filial</MenuItem>
              {filiaisDados.map((filial) => (
                <MenuItem key={filial.id} value={filial.id}>
                  {filial.descricaoSlim}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              select
              fullWidth
              label="Veiculos"
              variant="outlined"
              value={idVeiculo}
              onChange={(e) => setIdVeiculo(e.target.value)}
            >
              <MenuItem value="">Selecione um veiculo</MenuItem>
              {dadosVeiculos.map((veiculo) => (
                <MenuItem
                  key={veiculo.idCategoriaVeiculo}
                  value={veiculo.idCategoriaVeiculo}
                >
                  {veiculo.descricao}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Digite o nome do motorista"
              variant="outlined"
              type="search"
              value={searchNome}
              onChange={handleSearchChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              select
              fullWidth
              label="Ordenação"
              variant="outlined"
              value={ordenacaoFilter}
              onChange={(e) => setOrdenacaoFilter(e.target.value)}
            >
              <MenuItem value="">Ordernar por</MenuItem>
              {ordernacao.map((ordenar) => (
                <MenuItem key={ordenar.id} value={ordenar.value}>
                  {ordenar.descricao}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid
            style={{ display: 'flex', justifyContent: 'space-between' }}
            item
            xs={12}
          >
            <Box>
              <Button
                onClick={onClose}
                color="secondary"
                style={{ marginRight: '10px' }}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleCleanFilter}
              >
                Limpar
              </Button>
            </Box>
            <Button variant="contained" color="primary" onClick={handleFilter}>
              Filtrar
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
};

export default MotoristasVeiculosFilters;
