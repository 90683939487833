import OrigemCarga from 'shared/constants/OrigemCarga';
import { formatarDataApi } from '../../../shared/functions/dataUtils';
import { estaEmBranco } from '../../../shared/functions/stringUtils';

import { ResponseCargas, ResponseFiliais } from './listaCargasApiTypes';
import * as listaCargasService from './listaCargasService';

export const obterCargas = async (
  pagina: number,
  filiais: string[],
  pesquisa: string,
  dataInicio: Date | null,
  dataFim: Date | null,
  origemCarga: OrigemCarga,
  ordenacao: boolean,
): Promise<ResponseCargas> => {
  const queryStringArray: string[] = [`?pagina=${pagina}`];

  if (!estaEmBranco(pesquisa)) {
    queryStringArray.push(`&pesquisa=${pesquisa}`);
  }

  if (dataInicio) {
    queryStringArray.push(`&dataInicial=${formatarDataApi(dataInicio)}`);
  }

  if (dataFim) {
    queryStringArray.push(`&dataFinal=${formatarDataApi(dataFim)}`);
  }

  if (filiais.length > 0) {
    queryStringArray.push(`&filial=${filiais.join('&filial=')}`);
  }

  queryStringArray.push(`&OrigemCarga=${origemCarga}`);

  queryStringArray.push('&ordenarPor=dataGeracao');

  queryStringArray.push(`&direcaoOrdenacao=${ordenacao ? 'asc' : 'desc'}`);
  return listaCargasService.obterCargas(queryStringArray.join(''));
};

export const obterFiliais = async (): Promise<ResponseFiliais> =>
  listaCargasService.obterFiliais();
