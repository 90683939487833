import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import { MuiThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import PoliticaDePrivacidade from 'pages/politicaDePrivacidade/PoliticaDePrivacidade';
import PrivateRoute from './shared/auth/PrivateRoute';
import LoginRoute from './shared/auth/LoginRoute';
import { PATH_LOGIN } from './shared/constants/pathConstants';

import PaginaNaoEncontrada from './features/pagina-nao-encontrada';

import store from './store';
import theme from './theme';
import { Pagina } from './shared/constants/Pagina';
import { obterPaginasAcesso } from './shared/components/menu/PaginasPortal';
import SnackbarLateral from './shared/components/snackbar/SnackbarLateral';

const App = (): JSX.Element => {
  const paginasAcesso = obterPaginasAcesso();

  return (
    <CssBaseline>
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <BrowserRouter basename="/portal">
              <Switch>
                <Route
                  exact
                  path="/"
                  render={() => <Redirect to={PATH_LOGIN} />}
                />
                <Route
                  exact
                  path="/politica-de-privacidade"
                  render={() => <PoliticaDePrivacidade />}
                />
                <LoginRoute path={PATH_LOGIN} />
                {paginasAcesso.map((pagina) => (
                  <PrivateRoute
                    path={pagina.link}
                    component={pagina.componentePagina}
                    codigo={pagina.codigo}
                    headerAlternativo={pagina.headerAlternativo}
                  />
                ))}
                <PrivateRoute
                  path="*"
                  component={<PaginaNaoEncontrada />}
                  codigo={Pagina.PaginaNaoEncontrada}
                />
              </Switch>
            </BrowserRouter>
            <SnackbarLateral />
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </Provider>
    </CssBaseline>
  );
};

export default App;
