import React from 'react';
import { Box, Typography } from '@material-ui/core';
import SliderCustomizado from './SliderCustomizado';
import { useStyles } from '../styles/percentualPropostaFilial';
import { AprovacaoSupervisorSliderProps } from '../types/componentsTypes';

const AprovacaoSupervisorSlider = ({
  valoresLabel,
  valorMaximo,
  intervalo,
  minimoValorAceito,
  setValor,
  valor,
}: AprovacaoSupervisorSliderProps): JSX.Element => {
  const classes = useStyles({ sliderTamanho: true });

  return (
    <Box className={classes.sliderContainer}>
      <Typography className={`${classes.infoCard} ${classes.menosMargin}`}>
        Arraste o nível de permissão que deseja configurar
      </Typography>
      <Box className={classes.sliderBox}>
        <SliderCustomizado
          valoresLabel={valoresLabel}
          valorMaximo={valorMaximo}
          intervalo={intervalo}
          minimoValorAceito={minimoValorAceito}
          setValor={setValor}
          valor={valor}
          classe={classes.sliderStyle}
        />
      </Box>
    </Box>
  );
};

export default AprovacaoSupervisorSlider;
