import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import { PropostaNegociacao } from '../../../api/informacoesNegociacaoApiTypes';

import PropostaStatus from '../../../../../shared/constants/PropostaStatus';

import CampoContraproposta from './CampoContraproposta';
import ColunaControleProposta from './ColunaControleProposta';

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    alignItems: 'center',
    height: 80,
    padding: theme.spacing(0, 0.5),
  },
}));

type PropostaDetailsProps = {
  proposta: PropostaNegociacao;
};

const PropostaDetails = ({ proposta }: PropostaDetailsProps): JSX.Element => {
  const classes = useStyles();

  const verificarExibicaoCampoNegociador = (): boolean =>
    proposta.status !== PropostaStatus.AGUARDANDO;

  return (
    <form autoComplete="off">
      <Box className={classes.box}>
        <Grid container alignItems="center">
          <Grid item xs={9}>
            <CampoContraproposta
              isNegociador={verificarExibicaoCampoNegociador()}
              proposta={proposta}
            />
          </Grid>
          <Grid item xs={3}>
            <ColunaControleProposta proposta={proposta} />
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

export default PropostaDetails;
