import React from 'react';
import { Mark, Slider } from '@material-ui/core';
import { SliderCustomizadoProps } from '../types/componentsTypes';

const montarSliderLabels = (
  valoresLabel: number[],
  valorInicial: number,
  valorMaximo: number,
  intervalo: number,
): Mark[] => {
  const marks = [];
  let labelValue = valorInicial;
  while (labelValue <= valorMaximo) {
    marks.push({
      label: valoresLabel.includes(labelValue) ? labelValue : undefined,
      value: labelValue,
    });
    labelValue += intervalo;
  }
  return marks;
};

const SliderCustomizado = ({
  valoresLabel,
  valorMaximo,
  intervalo,
  minimoValorAceito,
  classe,
  setValor,
  valor,
}: SliderCustomizadoProps): JSX.Element => {
  return (
    <Slider
      aria-label="Temperature"
      defaultValue={minimoValorAceito}
      valueLabelDisplay="auto"
      value={valor}
      step={null}
      className={classe}
      marks={montarSliderLabels(
        valoresLabel,
        minimoValorAceito,
        valorMaximo,
        intervalo,
      )}
      onChange={(event, value) =>
        setValor(Array.isArray(value) ? value[0] : value)
      }
      max={valorMaximo}
      getAriaValueText={() => `${valor}%`}
      valueLabelFormat={() => `${valor}%`}
    />
  );
};

export default SliderCustomizado;
