import { makeStyles } from '@material-ui/core';
import DemandasAndamento from 'features/demandasAndamento/views/DemandasAndamento';
import React from 'react';
import BoxPagina from 'shared/components/box/BoxPagina';

const useStyles = makeStyles(() => ({
  container: {
    paddingLeft: 'calc(80vw - 2220px)',
  },
}));

const PaginaDemandasAndamento = (): JSX.Element => {
  const classes = useStyles();
  return (
    <BoxPagina className={classes.container} component="section">
      <DemandasAndamento />
    </BoxPagina>
  );
};

export default PaginaDemandasAndamento;
