import { ConexaoAPI } from '../../../shared/classes/conexaoAPI';
import { PostPrecificacaoResponse } from '../types/TypepostPrecificacao';

export const postCargasService = async (arquivo: File): Promise<void> => {
  const formData = new FormData();

  formData.append('arquivo', arquivo);

  const URL = `${process.env.REACT_APP_URL_API_BASE}/cargas`;
  const conexao = new ConexaoAPI(URL, 'post');
  conexao.adicionarBody(formData);
  const conectar = conexao.gerarFuncaoConectarRefreshToken<void>();
  const resposta = await conectar();
  return resposta.data;
};

export const postPrecificacaoService = async (
  arquivo: File,
): Promise<PostPrecificacaoResponse> => {
  const formData = new FormData();

  formData.append('arquivo', arquivo);

  const URL = `${process.env.REACT_APP_URL_API_BASE}/cargas/upload-configuracao-cargas`;
  const conexao = new ConexaoAPI(URL, 'post');
  conexao.adicionarBody(formData);
  const conectar = conexao.gerarFuncaoConectarRefreshToken<PostPrecificacaoResponse>();
  const resposta = await conectar();
  return resposta.data;
};

export default { postCargasService, postPrecificacaoService };
