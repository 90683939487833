import React from 'react';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';

import DialogTitle from './DialogTitle';
import { spacingInPixels } from '../../functions/materialUtils';

import Button from '../button/ButtonTextTransformNone';
import DisablingTooltip from '../tooltip/DisablingTooltip';

const useStyles = makeStyles((theme) => ({
  mensagem: {
    fontWeight: 600,
    fontSize: spacingInPixels(theme, 2),
  },
  espacamento: {
    marginTop: spacingInPixels(theme, 2),
  },
  botoes: {
    margin: theme.spacing(3, 2, 2, 0),
  },
}));

type ExcluirDialogProps = {
  modalAberto: boolean;
  onClose: () => void;
  mensagem: string;
  textoCancelar: string;
  textoConfirmar: string;
  confirmar: () => void;
};

const ExcluirDialog = ({
  modalAberto,
  onClose,
  mensagem,
  textoCancelar,
  textoConfirmar,
  confirmar,
}: ExcluirDialogProps): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Dialog open={modalAberto} keepMounted onClose={onClose}>
      <DialogTitle onClose={onClose} />
      <DialogContent>
        <Grid container direction="column" alignItems="center">
          <Grid className={classes.espacamento} item>
            <img src="/images/modal/lixeira.svg" alt="excluir" />
          </Grid>
          <Grid className={classes.espacamento} item>
            <Typography className={classes.mensagem}>{mensagem}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.botoes}>
        <DisablingTooltip title={textoCancelar}>
          <Button
            variant="contained"
            size="small"
            onClick={onClose}
            style={{
              padding: theme.spacing(1, 3, 1, 3),
              marginRight: spacingInPixels(theme, 1),
            }}
          >
            {textoCancelar}
          </Button>
        </DisablingTooltip>
        <DisablingTooltip title={textoConfirmar}>
          <Button
            variant="contained"
            size="small"
            onClick={confirmar}
            color="primary"
            style={{
              padding: theme.spacing(1, 2, 1, 2),
            }}
          >
            {textoConfirmar}
          </Button>
        </DisablingTooltip>
      </DialogActions>
    </Dialog>
  );
};

export default ExcluirDialog;
