import { ConexaoAPI } from 'shared/classes/conexaoAPI';
import {
  AtualizarLiberacaoListaClientesRequest,
  AvaliarAgregadoRequest,
  HistoricoMensagensResponse,
  Mensagem,
} from './chatNegociacaoApiTypes';

const obterUrlMensagens = (idNegociacao: number, idProposta: number): string =>
  `${process.env.REACT_APP_URL_API_BASE}/negociacoes/${idNegociacao}/propostas/${idProposta}/mensagens`;

const novoUrlObterMensagens = (
  idNegociacao: number,
  idProposta: number,
  idUsuarioMaster?: number | null,
): string =>
  `${
    process.env.REACT_APP_URL_API_BASE
  }/mensagens?propostaId=${idProposta}&negociacaoId=${idNegociacao}${
    idUsuarioMaster ? `&usuarioMaster=${idUsuarioMaster}` : ''
  }`;

const obterUrlMensagensFreteTabelado = (
  idNegociacao: number,
  idProposta: number,
): string =>
  `${process.env.REACT_APP_URL_API_BASE}/propostas/${idProposta}/mensagens/${idNegociacao}`;

const obterUrlDownloadImagem = (
  idNegociacao: number,
  idProposta: number,
  idMensagem: number,
  chaveAnexo: string,
): string =>
  `${process.env.REACT_APP_URL_API_BASE}/negociacoes/${idNegociacao}/proposta/${idProposta}/mensagens/${idMensagem}/anexos/${chaveAnexo}`;

const obterUrlFazerContraProposta = (
  idNegociacao: number,
  idProposta: number,
): string =>
  `${process.env.REACT_APP_URL_API_BASE}/negociacoes/${idNegociacao}/propostas/${idProposta}/contraproposta`;

const obterUrlPedirAprovacaoSupervisor = (
  idNegociacao: number,
  idProposta: number,
): string =>
  `${process.env.REACT_APP_URL_API_BASE}/negociacoes/${idNegociacao}/propostas/${idProposta}/solicitacao-aprovacao`;

const obterUrlatualizarLiberacaoListaClientes = (
  idNegociacao: number,
  idProposta: number,
): string =>
  `${process.env.REACT_APP_URL_API_BASE}/negociacoes/${idNegociacao}/propostas/${idProposta}/acesso-lista-clientes`;

const obterUrlAceiteProposta = (idProposta: number): string =>
  `${process.env.REACT_APP_URL_API_BASE}/propostas/${idProposta}/aceite-proposta`;

const obterUrlAceitePropostaGreguim = (idProposta: number): string =>
  `${process.env.REACT_APP_URL_API_BASE}/propostas/${idProposta}/aprovacao-supervisor`;

export const obterHistoricoMensagens = async (
  idNegociacao: number,
  idProposta: number,
  idUsuarioMaster?: number | null,
): Promise<HistoricoMensagensResponse> => {
  const urlMensagens = novoUrlObterMensagens(
    idNegociacao,
    idProposta,
    idUsuarioMaster,
  );
  const conexaoApi = new ConexaoAPI(urlMensagens, 'get');
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<HistoricoMensagensResponse>();
  const response = await conectar();
  return response.data;
};

export const obterHistoricoMensagensFreteTabelado = async (
  idNegociacao: number,
  idProposta: number,
): Promise<HistoricoMensagensResponse> => {
  const urlMensagens = obterUrlMensagensFreteTabelado(idNegociacao, idProposta);
  const conexaoApi = new ConexaoAPI(urlMensagens, 'get');
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<HistoricoMensagensResponse>();
  const response = await conectar();
  return response.data;
};

export const enviarMensagem = async (
  idNegociacao: number,
  idProposta: number,
  request: FormData,
): Promise<Mensagem> => {
  const urlMensagens = obterUrlMensagens(idNegociacao, idProposta);

  const conexaoApi = new ConexaoAPI(urlMensagens, 'post');
  conexaoApi.adicionarBody(request);
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<Mensagem>();
  const response = await conectar();

  return response.data;
};

export const fazerDownloadImagem = async (
  idNegociacao: number,
  idProposta: number,
  idMensagem: number,
  chaveAnexo: string,
): Promise<Blob> => {
  const urlDownloadImagem = obterUrlDownloadImagem(
    idNegociacao,
    idProposta,
    idMensagem,
    chaveAnexo,
  );

  const conexaoApi = new ConexaoAPI(urlDownloadImagem, 'get');
  conexaoApi.adicionarResponseTypeBlob();
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<Blob>();
  const response = await conectar();

  return response.data;
};

export const fazerContraProposta = async (
  idNegociacao: number,
  idProposta: number,
  body: { valorContraproposta: number; justificativa: string },
): Promise<Mensagem> => {
  const url = obterUrlFazerContraProposta(idNegociacao, idProposta);

  const conexaoApi = new ConexaoAPI(url, 'patch');
  conexaoApi.adicionarBody(body);
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<Mensagem>();
  const response = await conectar();

  return response.data;
};

export const pedirAprovacaoSupervisor = async (
  idNegociacao: number,
  idProposta: number,
  body: { valorContraproposta: number; justificativa: string },
): Promise<Mensagem> => {
  const url = obterUrlPedirAprovacaoSupervisor(idNegociacao, idProposta);

  const conexaoApi = new ConexaoAPI(url, 'patch');
  conexaoApi.adicionarBody(body);
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<Mensagem>();
  const response = await conectar();

  return response.data;
};

export const atualizarLiberacaoListaClientes = async (
  idNegociacao: number,
  idProposta: number,
  body: AtualizarLiberacaoListaClientesRequest,
): Promise<void> => {
  const url = obterUrlatualizarLiberacaoListaClientes(idNegociacao, idProposta);

  const conexaoApi = new ConexaoAPI(url, 'patch');
  conexaoApi.adicionarBody(body);
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<void>();

  await conectar();
};

export const aceitarPropostaMotorista = async (
  idProposta: number,
): Promise<Mensagem> => {
  const url = obterUrlAceiteProposta(idProposta);

  const conexaoApi = new ConexaoAPI(url, 'put');
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<Mensagem>();

  const response = await conectar();

  return response.data;
};

export const aceitarPropostaMotoristaEPedirAprovacao = async (
  idProposta: number,
): Promise<Mensagem> => {
  const url = obterUrlAceitePropostaGreguim(idProposta);

  const conexaoApi = new ConexaoAPI(url, 'put');
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<Mensagem>();

  const response = await conectar();

  return response.data;
};

export const salvarAvaliacaoAgregado = async (
  request: AvaliarAgregadoRequest,
): Promise<void> => {
  const url = `${process.env.REACT_APP_URL_API_BASE}/negociacoes/avaliar-motorista`;
  const conexaoApi = new ConexaoAPI(url, 'post');
  conexaoApi.adicionarBody(request);
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<void>();
  await conectar();
};
