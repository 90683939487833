import { useTheme } from '@material-ui/core';
import * as React from 'react';
import { SVGProps } from 'react';

const IconeVelocimetro = ({
  fill,
  ...outros
}: SVGProps<SVGSVGElement>): JSX.Element => {
  const theme = useTheme();
  return (
    <svg width={14} height={15} viewBox="0 0 14 15" fill="none" {...outros}>
      <path
        d="M11.725 2.803c-.005-.005-.006-.012-.011-.017-.005-.005-.012-.007-.018-.012a6.653 6.653 0 0 0-9.393 0c-.005.005-.012.007-.017.012-.005.005-.006.012-.011.017a6.667 6.667 0 1 0 9.45 0ZM7 12.833a5.324 5.324 0 0 1-4.157-2H5.52a1.975 1.975 0 0 0 2.96 0h2.677a5.323 5.323 0 0 1-4.157 2ZM6.333 9.5a.667.667 0 1 1 1.333 0 .667.667 0 0 1-1.333 0Zm5.607.002-.007-.002H9a1.997 1.997 0 0 0-1.333-1.877V5.5a.667.667 0 1 0-1.334 0v2.123A1.997 1.997 0 0 0 5 9.5H2.067l-.007.002a5.287 5.287 0 0 1-.347-1.335h.62a.667.667 0 0 0 0-1.333h-.62a5.3 5.3 0 0 1 1.08-2.6l.436.437a.667.667 0 1 0 .942-.942l-.436-.437a5.301 5.301 0 0 1 2.598-1.079v.62a.667.667 0 0 0 1.334 0v-.62c.947.12 1.845.492 2.598 1.08l-.437.436a.667.667 0 0 0 .943.942l.436-.436a5.3 5.3 0 0 1 1.08 2.598h-.62a.666.666 0 1 0 0 1.334h.62a5.285 5.285 0 0 1-.347 1.335Z"
        fill={fill || theme.palette.cores.cinza[700]}
      />
    </svg>
  );
};

export default IconeVelocimetro;
