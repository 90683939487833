import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import { formatarTelefone } from 'shared/functions/telefoneUtils';
import { Box, Tooltip, Typography } from '@material-ui/core';
import TableRow from '../../../shared/components/table/TableRowHeightSpacing6';
import TableCell from '../../../shared/components/table/TableCellCustomPadding';
import { MotoristasList } from '../api/motoristasVeiculosApiTypes';

export type TabelaItemProps = {
  motorista: MotoristasList;
};

const TableCellSmall = withStyles({
  root: {
    fontSize: '14px',
    fontWeight: 500,
  },
})(TableCell);

const TabelaItem = ({ motorista }: TabelaItemProps): JSX.Element => {
  const formatarCPF = (cpf: string): string => {
    // Remover caracteres não numéricos do CPF
    const cpfLimpo = cpf.replace(/\D/g, '');

    // Aplicar a formatação do CPF
    return cpfLimpo.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  };

  return (
    <TableRow>
      <TableCellSmall align="left">{formatarCPF(motorista.cpf)}</TableCellSmall>
      <TableCellSmall align="left">{motorista.nome}</TableCellSmall>
      <TableCellSmall align="center">
        {motorista.codigoMartinsVeiculo}
      </TableCellSmall>
      <TableCellSmall align="left">
        {formatarTelefone(motorista.telefone)}
      </TableCellSmall>
      <TableCellSmall align="left">{motorista.filial}</TableCellSmall>
      <TableCellSmall align="center">
        <Tooltip
          title={
            <Box display="flex" flexDirection="column">
              <Typography variant="body2">
                Min: {motorista.capacidadeMinimaCargaVeiculo} Kg - Max:{' '}
                {motorista.capacidadeMaximaCargaVeiculo} Kg, Vol:{' '}
                {motorista.volumetriaVeiculo} m³
              </Typography>
            </Box>
          }
          placement="top"
          arrow
        >
          <span>{motorista.categoriaVeiculo}</span>
        </Tooltip>
      </TableCellSmall>
      <TableCellSmall align="center">{motorista.placa}</TableCellSmall>
      <TableCellSmall align="center">
        {motorista.statusAdesaoFreteTabelado.charAt(0).toUpperCase() +
          motorista.statusAdesaoFreteTabelado.slice(1)}
      </TableCellSmall>
      <TableCellSmall align="center">
        {motorista.isAtivo ? 'Ativo' : 'Inativo'}
      </TableCellSmall>
    </TableRow>
  );
};

export default TabelaItem;
