import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, useTheme } from '@material-ui/core';
import InputRubik from '../../../shared/components/input/InputRubik';
import EditarValorContrapropostaBadges from '../../../shared/components/modalComum/EditarValorContrapropostaBadges';
import ModalContraproposta from '../../../shared/components/modalComum/ModalComum';
import ModalContrapropostaAcoes from '../../../shared/components/modalComum/ModalComumAcoes';
import { RootState } from '../../../store/reducer';
import TipoValorContraproposta from '../constants/TipoValorContraproposta';
import { LIMITE_CARACTERES_TEXTO_LIVRE } from '../../../shared/constants/utilConstants';
import IconeExclamacaoCirculo from '../../../assets/icones/IconeExclamacaoCirculo';
import { sleep } from '../../../shared/functions/tempoUtils';
import { ModalPedirAprovacaoPropTypes } from '../types/modalTypes';
import { useStyles } from '../styles/ModalPedirAprovacao';

const ModalPedirAprovacao = ({
  setModalPedirAprovacaoAberto,
  modalPedirAprovacaoAberto,
  valorContraproposta,
  abriModalConfirmarAprovacaoSupervisor,
}: ModalPedirAprovacaoPropTypes): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles();

  const { negociacao, idNegociacao, idPropostaComFoco } = useSelector(
    (state: RootState) => state.informacoesNegociacaoReducer,
  );

  const [erroInput, setErroInput] = useState(false);
  const [justificativa, setJustificativa] = useState('');
  const [tipoValor, setTipoValor] = useState<TipoValorContraproposta>(
    TipoValorContraproposta.ValorProposto,
  );
  const [valorProposto, setValorProposto] = useState<number | undefined>(
    valorContraproposta,
  );
  const [valorInput, setValorInput] = useState<number | undefined>(
    valorContraproposta ||
      negociacao?.valorNovaMeta ||
      negociacao?.valorNegociacao ||
      undefined,
  );

  const confirmarContraProposta = async () => {
    if (!idNegociacao || !idPropostaComFoco || !valorProposto || !justificativa)
      return;
    setModalPedirAprovacaoAberto(false);
    await sleep(500);
    abriModalConfirmarAprovacaoSupervisor(valorProposto || 0, justificativa);
  };

  useEffect(() => {
    setValorInput(
      valorContraproposta ||
        negociacao?.valorNovaMeta ||
        negociacao?.valorNegociacao ||
        undefined,
    );
  }, [
    negociacao?.valorNegociacao,
    negociacao?.valorNovaMeta,
    valorContraproposta,
  ]);

  return (
    <ModalContraproposta
      modalAberto={modalPedirAprovacaoAberto}
      fecharModal={() => setModalPedirAprovacaoAberto(false)}
      titulo="Pedir Aprovação"
      conteudo={
        <Box>
          <Box>
            <EditarValorContrapropostaBadges
              tipoValor={tipoValor}
              setTipoValor={setTipoValor}
              valorProposto={valorProposto}
              setValorProposto={setValorProposto}
              valorInput={valorInput}
              setValorInput={setValorInput}
              valorFreteTerceiro={negociacao?.valorFreteTerceiro}
              valorFrotaPropria={
                negociacao?.sugestaoValorInicialRobo
                  ? negociacao?.sugestaoValorInicialRobo
                  : negociacao?.valorFrotaPropria
              }
              valorEntregas={negociacao?.carga.entregas}
              valorMercadoria={negociacao?.carga.valor}
              valorPeso={negociacao?.carga.peso}
              valorQuilometragem={negociacao?.carga.quilometragem}
              erroInput={erroInput}
              setErroInput={setErroInput}
              valorNovaMeta={negociacao?.valorNovaMeta}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            marginTop="24px"
          >
            <IconeExclamacaoCirculo fill={theme.palette.cores.amarelo[800]} />
            <Typography className={classes.textoSupervisor}>
              Este valor precisa ser aprovado pelo <b>Supervisor</b>
            </Typography>
          </Box>
          <Box marginTop="32px">
            <InputRubik
              fullWidth
              multiline
              label="Justifique o valor"
              size="small"
              variant="outlined"
              value={justificativa}
              onChange={(e) => setJustificativa(e.target.value)}
              inputProps={{ maxLength: LIMITE_CARACTERES_TEXTO_LIVRE }}
              rows={4}
            />
          </Box>
        </Box>
      }
      acoes={
        <ModalContrapropostaAcoes
          textoBotaoPrimario="Pedir Aprovação"
          callbackBotaoPrimario={confirmarContraProposta}
          textoBotaoSecundario="Cancelar"
          callbackBotaoSecundario={() => setModalPedirAprovacaoAberto(false)}
          botaoPrimarioDesativado={
            !valorProposto || !justificativa.trim().length || erroInput
          }
        />
      }
    />
  );
};

export default ModalPedirAprovacao;
