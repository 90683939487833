import React from 'react';

import EmptyPage from 'shared/components/emptyPage/EmptyPage';
import IconeArquivoBarraDiagonal from 'assets/icones/IconeArquivoBarraDiagonal';
import {
  MENSAGEM_ERRO_LISTAGEM_DEMANDAS_NEGOCIADORES,
  MENSAGEM_LISTAGEM_DEMANDAS_NEGOCIADORES_VAZIA,
} from 'features/dashboards/constants/Constants';
import gridStyles from 'features/dashboards/styles/gridTabelaDemandasNegociadorStyle';
import { DemandaNegociador } from 'features/dashboards/negociadores/api/listagemNegociadoresApiTypes';
import ItemConteudoTabelaDemandasNegociador from './ItemConteudoTabelaDemandasNegociador';
import SkeletonTabelaDemandasNegociador from './SkeletonTabelaDemandasNegociador';

export interface ConteudoTabelaDemandasNegociadorProps {
  demandas?: DemandaNegociador[];
  carregando: boolean;
  erro: boolean;
  isAutomatizado: boolean;
}

const ConteudoTabelaDemandasNegociador = ({
  demandas,
  carregando,
  erro,
  isAutomatizado,
}: ConteudoTabelaDemandasNegociadorProps): JSX.Element => {
  const gridStyle = gridStyles({ isAutomatizadoStyle: isAutomatizado });

  if (carregando) return <SkeletonTabelaDemandasNegociador />;

  if (erro)
    return (
      <EmptyPage
        iconePreenchido
        texto={MENSAGEM_ERRO_LISTAGEM_DEMANDAS_NEGOCIADORES}
      />
    );

  if (demandas?.length === 0)
    return (
      <EmptyPage
        iconePreenchido
        icone={
          <IconeArquivoBarraDiagonal
            width={134}
            height={134}
            viewBox="-6 0 34 22"
          />
        }
        texto={MENSAGEM_LISTAGEM_DEMANDAS_NEGOCIADORES_VAZIA}
      />
    );

  return (
    <section className={gridStyle.gridContainer}>
      {demandas?.map((demanda) => (
        <ItemConteudoTabelaDemandasNegociador
          isAutomatizado={isAutomatizado}
          demanda={demanda}
          className={gridStyle.gridItem}
          key={`item-corpo-tabela-demandas-negociador-${demanda.romaneio}`}
        />
      ))}
    </section>
  );
};

export default ConteudoTabelaDemandasNegociador;
