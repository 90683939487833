import { Box, makeStyles, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import spacingInPixels from '../../functions/materialUtils';
import IconePreenchido from '../icons/IconePreenchido';

export interface BigNumberProps {
  icone: React.ReactElement;
  cor?: string;
  numero?: number;
  descricao: string;
  selecionado?: boolean;
  onClick: () => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
    gridTemplateRows: 'repeat(2,min-content)',
    gridColumnGap: spacingInPixels(theme, 1),
    placeItems: 'center',
    cursor: 'pointer',

    backgroundColor: theme.palette.cores.branco,
    padding: spacingInPixels(theme, 1),
    borderRadius: spacingInPixels(theme, 1),
    boxShadow: '0px 1px 6px 0px rgba(0, 0, 0, 0.09)',

    '&:hover': {
      backgroundColor: theme.palette.cores.cinza[50],
      boxShadow: '0px 1px 6px 0px rgb(0 0 0 / 30%)',
    },
  },
  selecionado: {
    border: `2px solid ${theme.palette.cores.azulMartins[500]}`,
  },
  numero: {
    fontWeight: 500,
    fontSize: 16,
    color: theme.palette.cores.cinza[800],
    gridColumnStart: 2,
    justifySelf: 'start',
  },
  descricao: {
    fontWeight: 400,
    fontSize: 12,
    color: theme.palette.cores.cinza[700],
    letterSpacing: '0.4px',
    gridColumnStart: 2,
    justifySelf: 'start',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
  },
}));

const BigNumber = ({
  icone,
  cor,
  numero,
  descricao,
  selecionado,
  onClick,
}: BigNumberProps): JSX.Element => {
  const classes = useStyles();
  const classSelecionado = useMemo(
    () => (selecionado ? classes.selecionado : undefined),
    [classes.selecionado, selecionado],
  );
  return (
    <Box
      className={`${classes.container} ${classSelecionado}`}
      onClick={onClick}
    >
      <IconePreenchido icone={icone} cor={cor} />
      <Typography className={classes.numero}>
        {numero?.toLocaleString() || '--'}
      </Typography>
      <Typography className={classes.descricao} title={descricao}>
        {descricao}
      </Typography>
    </Box>
  );
};

export default BigNumber;
