import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';
import SelectCustomizado from 'features/indicadores/components/SelectCustomizado';
import IconeQuadradoArredondado from 'assets/icones/IconeQuadradoArredondado';
import {
  atualizarIndicadores,
  getDadosOpcaoIndicadorPorChave,
  getListaSeletorIndicadores,
} from 'features/indicadores/utils/evolucaoDeCargas';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/reducer';
import { ChaveIndicador } from 'features/indicadores/constants/Constants';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    gap: spacingInPixels(theme, 1.5),
    alignItems: 'center',

    '& div.MuiInputBase-root': {
      width: '14em',
    },
  },
  texto: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: spacingInPixels(theme, 2),
    lineHeight: spacingInPixels(theme, 3.5),
    color: theme.palette.cores.cinza[600],
  },
  estiloContainerSelect: {
    gridTemplateColumns: '10% 90%',
  },
}));

interface SeletorIndicadoresProps {
  onSelecaoIndicador1: (valor: string) => void;
  onSelecaoIndicador2: (valor: string) => void;
  desabilitarSeletores: boolean;
}

const SeletorIndicadores = ({
  onSelecaoIndicador1,
  onSelecaoIndicador2,
  desabilitarSeletores,
}: SeletorIndicadoresProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const { indicador1, indicador2 } = useSelector(
    (reducer: RootState) => reducer.evolucaoDeCargasReducer,
  );

  const [opcoesIndicador1, setOpcoesIndicador1] = useState(
    getListaSeletorIndicadores(indicador2.chave),
  );
  const [opcoesIndicador2, setOpcoesIndicador2] = useState(
    getListaSeletorIndicadores(indicador1.chave),
  );

  const handleSelecaoIndicador1 = (valor: string) => {
    const dadosIndicador = getDadosOpcaoIndicadorPorChave(
      valor as ChaveIndicador,
    );
    setOpcoesIndicador2(getListaSeletorIndicadores(valor));
    atualizarIndicadores(dadosIndicador, indicador2, dispatch);
    onSelecaoIndicador1(valor);
  };

  const handleSelecaoIndicador2 = (valor: string) => {
    const dadosIndicador = getDadosOpcaoIndicadorPorChave(
      valor as ChaveIndicador,
    );
    setOpcoesIndicador1(getListaSeletorIndicadores(valor));
    atualizarIndicadores(indicador1, dadosIndicador, dispatch);
    onSelecaoIndicador2(valor);
  };

  return (
    <section className={classes.container}>
      <SelectCustomizado
        opcoes={opcoesIndicador1}
        valorInicial={indicador1.chave}
        icone={<IconeQuadradoArredondado />}
        onChangeEvent={handleSelecaoIndicador1}
        estiloContainer={classes.estiloContainerSelect}
        desabilitado={desabilitarSeletores}
      />
      <span className={classes.texto}>e</span>
      <SelectCustomizado
        opcoes={opcoesIndicador2}
        valorInicial={indicador2.chave}
        icone={
          <IconeQuadradoArredondado fill={theme.palette.cores.laranja[900]} />
        }
        onChangeEvent={handleSelecaoIndicador2}
        estiloContainer={classes.estiloContainerSelect}
        desabilitado={desabilitarSeletores}
      />
    </section>
  );
};

export default SeletorIndicadores;
