import { makeStyles } from '@material-ui/core';

export const porcentagemStylesFundo = makeStyles((theme) => ({
  verde: {
    backgroundColor: theme.palette.success.main,
  },
  amarelo: {
    backgroundColor: theme.palette.cores.amarelo[800],
  },
  vermelho: {
    backgroundColor: theme.palette.error.main,
  },
  cinza: {
    backgroundColor: theme.palette.text.secondary,
  },
}));

export const porcentagemStylesTexto = makeStyles((theme) => ({
  verde: {
    color: theme.palette.success.main,
  },
  amarelo: {
    color: theme.palette.warning.main,
  },
  vermelho: {
    color: theme.palette.error.main,
  },
  cinza: {
    color: theme.palette.text.secondary,
  },
}));
