import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';

import ControleUsuario from './ControleUsuario';
import ElevationScroll from './ElevationScroll';
import Titulo from './Titulo';
import Toolbar from './Toolbar';
import Hamburger from '../../menu/Hamburger';
import { caminhoIconeLogoMartins } from '../../../constants/CaminhoRecursos';
import spacingInPixels from '../../../functions/materialUtils';

type HeaderProps = {
  fazerLogout: () => Promise<void>;
  toggleMenu: (event: React.KeyboardEvent | React.MouseEvent) => void;
};

const useStyles = makeStyles((theme) => ({
  header: {
    boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.0690104)',
    height: 50,
    minHeight: 40,
    borderBottomRightRadius: 16,
    paddingLeft: spacingInPixels(theme, 0.5),
  },
  icone: {
    height: 32,
    width: 32,
  },
}));

const Header = ({ fazerLogout, toggleMenu }: HeaderProps): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <ElevationScroll>
        <AppBar color="inherit" className={classes.header}>
          <Toolbar variant="dense" className={classes.header}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item xs="auto">
                <Box display="flex" alignItems="center">
                  <Hamburger toggleMenu={toggleMenu} />
                  <Box marginLeft="8px" marginRight="16px" display="flex">
                    <img
                      src={caminhoIconeLogoMartins}
                      alt="Logo"
                      className={classes.icone}
                    />
                  </Box>
                  <Titulo />
                </Box>
              </Grid>
              <Grid item xs="auto">
                <ControleUsuario fazerLogout={fazerLogout} />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Box height="40px" />
    </>
  );
};

export default Header;
