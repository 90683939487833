import { DetalhesDemandaDiaria } from 'features/detalhesDemandasDiaria/api/detalhesDemandasDiariaApiTypes';
import CabecalhoTabelaDetalhesDemandas from 'features/detalhesDemandasDiaria/components/tabelaDetalhesDemandas/CabecalhoTabelaDetalhesDemandas';
import SkeletonTabelaDetalhesDemandas from 'features/detalhesDemandasDiaria/components/tabelaDetalhesDemandas/SkeletonTabelaDetalhesDemandas';
import { ChaveOrdenacaoDetalhesDemandas } from 'features/detalhesDemandasDiaria/constants/Constants';
import {
  dadosItensCabecalhoDemandasNaoIniciadas,
  dadosSkeletonDemandasNaoIniciadas,
} from 'features/detalhesDemandasDiaria/constants/demandasNaoIniciadasConstants';
import { ordenarDemandas } from 'features/detalhesDemandasDiaria/utils/detalhesDemandas';
import React, { useMemo, useState } from 'react';
import PaginaVaziaIndicadores from 'shared/components/paginaVaziaIndicadores/PaginaVaziaIndicadores';
import { TabelaProps } from 'features/detalhesDemandasDiaria/types/tabelaTypes';
import ConteudoTabelaDemandasNaoIniciadas from './ConteudoTabelaDemandasNaoIniciadas';
import { estilosTabelaDemandasNaoIniciadas } from '../../styles/styleTabela';
import { useStyles } from '../../styles/styleParaTabelas.ts';

const TabelaDemandasNaoIniciadas = ({
  demandas,
  carregando,
  erro,
}: TabelaProps): JSX.Element => {
  const classes = useStyles();
  const { gridTabela } = estilosTabelaDemandasNaoIniciadas();

  const [demandasOrdenadas, setDemandasOrdenadas] = useState<
    DetalhesDemandaDiaria[]
  >([]);

  const handleClickOrdenacao = (chave: string, ordenacaoCrescente: boolean) => {
    setDemandasOrdenadas(
      ordenarDemandas(
        demandasOrdenadas,
        chave as ChaveOrdenacaoDetalhesDemandas,
        ordenacaoCrescente,
      ),
    );
  };

  useMemo(() => {
    setDemandasOrdenadas([...demandas]);
  }, [demandas]);

  if (demandasOrdenadas.length === 0 && !carregando)
    return <PaginaVaziaIndicadores erro={erro} />;

  return (
    <table className={classes.container}>
      <thead>
        <CabecalhoTabelaDetalhesDemandas
          onClickOrdenacao={handleClickOrdenacao}
          estiloCabecalho={gridTabela}
          dadosItensCabecalho={dadosItensCabecalhoDemandasNaoIniciadas}
        />
      </thead>
      {carregando ? (
        <SkeletonTabelaDetalhesDemandas
          dadosSkeleton={dadosSkeletonDemandasNaoIniciadas}
          estiloLinha={gridTabela}
        />
      ) : (
        <ConteudoTabelaDemandasNaoIniciadas demandas={demandasOrdenadas} />
      )}
    </table>
  );
};

export default TabelaDemandasNaoIniciadas;
