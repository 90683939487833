import React from 'react';

import Box from '@material-ui/core/Box';

import Skeleton from '@material-ui/lab/Skeleton';

const SkeletonMotorista = (): JSX.Element => {
  return (
    <Box display="flex" alignItems="start">
      <Box paddingRight="20px">
        <Skeleton variant="circle" width="33px" height="33px" />
        <Skeleton width="33px" height="12px" />
      </Box>
      <Skeleton variant="rect" width="320px" height="80px" />
    </Box>
  );
};

export default SkeletonMotorista;
