import { Box } from '@material-ui/core';
import React from 'react';
import CustomSkeleton from 'shared/components/skeleton/CustomSkeleton';
import { Filial, Carga } from '../../api/detalhesAprovacaoSupervisorTypes';
import BoxInfoFilial from '../boxInfo/BoxInfoFilial';

export type CardFilialProps = {
  carga?: Carga;
  filial?: Filial;
  carregando: boolean;
};

const CardFilial = ({
  carga,
  filial,
  carregando,
}: CardFilialProps): JSX.Element => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="33%"
      padding="0px 8px"
      minHeight="auto"
      alignItems="center"
      justifyContent="center"
    >
      {carregando ? (
        <CustomSkeleton
          width="100%"
          height={104}
          borderradius={8}
          key="skeleton-filial"
        />
      ) : (
        <BoxInfoFilial carga={carga} filial={filial} />
      )}
    </Box>
  );
};

export default CardFilial;
