const formatarZerosIniciais = (
  numero: number,
  minimoDigitos: number,
): string => {
  return `${numero}`.padStart(minimoDigitos, '0');
};

export const calcularPorcentagem = (
  numerador: number,
  denominador: number,
): number => Math.round((numerador / denominador) * 100);

export const obterPorcentagemFormatada = (
  numerador: number,
  denominador: number,
): string => {
  const porcentagem = calcularPorcentagem(numerador, denominador);
  return Number.isNaN(porcentagem) ? '%' : `${porcentagem}%`;
};

export const converterParaDecimal = (numero: string): number => {
  return parseFloat(numero);
};

export const formatarDecimalComPontoParaStringComVirgula = (
  numero: number,
): string => {
  return numero - Number(numero.toFixed()) === 0
    ? numero.toFixed()
    : numero.toFixed(2).replace('.', ',');
};

export const converterValorEmDecimal = (valor: number): number => {
  return Number((valor * 0.01).toFixed(2));
};

export const formatarNumeroComVirgulaCasaDecimais = (
  number: number,
  casaDecimais: number,
): string => {
  const formattedNumber = number.toLocaleString('pt-BR', {
    minimumFractionDigits: casaDecimais,
    maximumFractionDigits: casaDecimais,
  });
  return formattedNumber;
};

export const formatarNumerosComVirgulas = (numero: number): string => {
  const parts = numero.toFixed(2).toString().split('.');
  const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  const decimalPart = parts[1];
  return `${integerPart},${decimalPart}`;
};

export default formatarZerosIniciais;
