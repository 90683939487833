import { makeStyles, useTheme } from '@material-ui/core';
import IconeCaminhao from 'assets/icones/IconeCaminhao';
import IconeVelocimetro from 'assets/icones/IconeVelocimetro';
import { NegociacaoAndamento } from 'features/demandasAndamento/api/demandasAndamentoApiTypes';
import React from 'react';
import CustomTooltip from 'shared/components/tooltip/CustomTooltip';
import {
  converterDataApi,
  formatarDataBrasileira,
} from 'shared/functions/dataUtils';
import { formatarNumeroParaStringMonetariaComSimbolo } from 'shared/functions/dinheiroUtils';

const useStyles = makeStyles((theme) => ({
  textoItem: {
    fontSize: 14,
    fontWeight: 400,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '90%',
  },
  descricaoItem: {
    fontSize: 14,
    fontWeight: 400,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: theme.palette.cores.cinza[500],
  },
}));

export interface ItemTabelaDemandasAndamentoProps {
  demanda: NegociacaoAndamento;
  className?: string;
}

interface TooltipSimpleArticleProps {
  extraComponent?: JSX.Element;
  extraTitle?: string;
  children?: string;
}

const ItemTabelaDemandasAndamento = ({
  demanda,
  className,
}: ItemTabelaDemandasAndamentoProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();

  const tratarValorMonetario = (valor?: number): string =>
    valor ? formatarNumeroParaStringMonetariaComSimbolo(valor) : '--';

  const tratarValorPorcentagem = (valor?: number): string =>
    `${valor ? valor.toLocaleString('pt-BR') : '--'}%`;

  const dataFormatada = formatarDataBrasileira(
    converterDataApi(demanda.dataCarregamento),
  );

  const pesoEmKilos = `${demanda.pesoKg.toLocaleString('pt-br')} kg`;

  const valorCargaFormatado = formatarNumeroParaStringMonetariaComSimbolo(
    demanda.valorCarga,
  );

  const valorAprovadoNegociacao = formatarNumeroParaStringMonetariaComSimbolo(
    demanda.valorAprovadoNegociacao,
  );

  const distanciaEmKilometros = demanda.distanciaKm.toLocaleString('pt-br');

  const entregasPorPessoa = `(${demanda.quantidadeEntregaPj} PJ/ ${demanda.quantidadeEntregaPf} PF)`;

  const quantidadeDeEntregaPorPessoa = `${demanda.quantidadeEntregas.toLocaleString(
    'pt-br',
  )} ${entregasPorPessoa}`;

  const TooltipSimpleArticle = ({
    extraComponent,
    extraTitle,
    children,
  }: TooltipSimpleArticleProps): JSX.Element => {
    return (
      <CustomTooltip title={children || extraTitle || '--'}>
        <article className={className}>
          {extraComponent === null || extraComponent}
          <span className={classes.textoItem}>{children}</span>
        </article>
      </CustomTooltip>
    );
  };

  TooltipSimpleArticle.defaultProps = {
    extraComponent: null,
    extraTitle: null,
    children: null,
  };

  return (
    <>
      <TooltipSimpleArticle>{demanda.nomeFilial}</TooltipSimpleArticle>

      <TooltipSimpleArticle>{demanda.nomeNegociador}</TooltipSimpleArticle>

      <TooltipSimpleArticle>{dataFormatada}</TooltipSimpleArticle>

      <TooltipSimpleArticle>{demanda.romaneio}</TooltipSimpleArticle>

      <TooltipSimpleArticle>{pesoEmKilos}</TooltipSimpleArticle>

      <TooltipSimpleArticle>{demanda.nomeAgregado}</TooltipSimpleArticle>

      <TooltipSimpleArticle>
        {demanda.placaVeiculoAtivoAgregado}
      </TooltipSimpleArticle>

      <TooltipSimpleArticle>{valorAprovadoNegociacao}</TooltipSimpleArticle>

      <TooltipSimpleArticle>{valorCargaFormatado}</TooltipSimpleArticle>

      <TooltipSimpleArticle
        extraComponent={
          <IconeVelocimetro
            fill={theme.palette.cores.cinza[500]}
            width={15}
            height={15}
          />
        }
      >
        {distanciaEmKilometros}
      </TooltipSimpleArticle>

      <TooltipSimpleArticle>
        {demanda.volumeM3.toLocaleString('pt-br')}
      </TooltipSimpleArticle>

      <TooltipSimpleArticle
        extraComponent={
          <>
            <span className={classes.textoItem}>
              {demanda.quantidadeEntregas.toLocaleString('pt-br')}
            </span>
            <span className={classes.descricaoItem}>{entregasPorPessoa}</span>
          </>
        }
        extraTitle={quantidadeDeEntregaPorPessoa}
      />

      <TooltipSimpleArticle
        extraComponent={
          <IconeCaminhao
            fill={theme.palette.cores.cinza[500]}
            width={15}
            height={15}
          />
        }
      >
        {tratarValorMonetario(demanda.menorPropostaAtual)}
      </TooltipSimpleArticle>

      <TooltipSimpleArticle>
        {demanda.qntdPropostasNegociador.toLocaleString('pt-br')}
      </TooltipSimpleArticle>

      <TooltipSimpleArticle>
        {tratarValorMonetario(demanda.valorPorEntrega)}
      </TooltipSimpleArticle>

      <TooltipSimpleArticle>
        {tratarValorMonetario(demanda.valorPorPeso)}
      </TooltipSimpleArticle>

      <TooltipSimpleArticle>
        {tratarValorMonetario(demanda.valorPorKm)}
      </TooltipSimpleArticle>

      <TooltipSimpleArticle>
        {tratarValorPorcentagem(demanda.custoRelativo)}
      </TooltipSimpleArticle>

      <TooltipSimpleArticle>
        {tratarValorMonetario(demanda.valorFrotaPropria)}
      </TooltipSimpleArticle>

      <TooltipSimpleArticle>
        {tratarValorPorcentagem(demanda.saving)}
      </TooltipSimpleArticle>

      <TooltipSimpleArticle>{demanda.nomeCidadePrincipal}</TooltipSimpleArticle>
    </>
  );
};

export default ItemTabelaDemandasAndamento;
