import { Typography, withStyles } from '@material-ui/core';
import spacingInPixels from '../../../shared/functions/materialUtils';

const SubtituloDetalhesSupervisor = withStyles((theme) => ({
  root: {
    fontSize: '16px',
    fontWeight: 400,
    color: theme.palette.cores.cinza[900],
    padding: spacingInPixels(theme, 2),
    marginLeft: 12,
  },
}))(Typography);

export default SubtituloDetalhesSupervisor;
