import React, { useState } from 'react';

import Botao from '../../../../../shared/components/button/BotaoRubik';
import ModalConfirmarAprovacaoSupervisor from '../../../modals/ModalConfirmarAprovacaoSupervisor';
import ModalPedirAprovacao from '../../../modals/ModalPedirAprovacao';

export type PedirAprovacaoProps = {
  estiloBotao?: string;
  valorContrapropostaAtual?: number;
};

const PedirAprovacao = ({
  estiloBotao,
  valorContrapropostaAtual,
}: PedirAprovacaoProps): JSX.Element => {
  const [modalPedirAprovacaoAberto, setModalPedirAprovacaoAberto] = useState(
    false,
  );
  const [justificativaAprovacao, setJustificativaAprovacao] = useState('');
  const [valorAprovacao, setValorAprovacao] = useState(0);
  const [
    modalConfirmarAprovacaoSupervisorAberto,
    setModalConfirmarAprovacaoSupervisorAberto,
  ] = useState(false);

  const abrirModalConfirmarAprovacaoSupervisor = (
    valor: number,
    justificativa: string,
  ): void => {
    setValorAprovacao(valor);
    setJustificativaAprovacao(justificativa);
    setModalConfirmarAprovacaoSupervisorAberto(true);
  };

  return (
    <>
      <Botao
        className={estiloBotao}
        variant="outlined"
        color="primary"
        onClick={() => setModalPedirAprovacaoAberto(true)}
      >
        Pedir Aprovação
      </Botao>

      <ModalPedirAprovacao
        modalPedirAprovacaoAberto={modalPedirAprovacaoAberto}
        setModalPedirAprovacaoAberto={setModalPedirAprovacaoAberto}
        valorContraproposta={valorContrapropostaAtual}
        abriModalConfirmarAprovacaoSupervisor={
          abrirModalConfirmarAprovacaoSupervisor
        }
      />
      <ModalConfirmarAprovacaoSupervisor
        modalConfirmarAprovacaoSupervisorAberto={
          modalConfirmarAprovacaoSupervisorAberto
        }
        setModalConfirmarAprovacaoSupervisorAberto={
          setModalConfirmarAprovacaoSupervisorAberto
        }
        justificativa={justificativaAprovacao}
        valorParaAprovacao={valorAprovacao}
      />
    </>
  );
};

export default PedirAprovacao;
