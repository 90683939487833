export enum Role {
  NEGOCIADOR,
  SUPERVISOR,
}

export type RoleType = Role;

export const verificarRoleNegociador = (
  role: RoleType | null | undefined,
): boolean => {
  if (role === undefined || role === null) return false;
  return Role.NEGOCIADOR === role;
};
export const verificarRoleSupervisor = (role: RoleType): boolean =>
  Role.SUPERVISOR === role;

export default Role;
