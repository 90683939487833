import {
  createStyles,
  Grid,
  GridSize,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import React from 'react';
import { ItemCabecalho } from '../constants/Types';

interface ItemCabecalhoProps {
  paddingleft?: string;
}

const useStyles = makeStyles((theme) => ({
  cabecalho: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '28px',
    letterSpacing: '0.15',
    color: theme.palette.cores.cinza[700],
    padding: '0 18px',
  },
}));

const StyledGrid = withStyles(() =>
  createStyles({
    item: (props: ItemCabecalhoProps) => ({
      paddinglLeft: props.paddingleft,
    }),
  }),
)(Grid);

type HeaderDashboardProps = {
  nome: string;
  quantidade: number | undefined;
  headers: ItemCabecalho[];
  tamanhoCelulaNome: GridSize;
};

const HeaderDashboard = ({
  nome,
  quantidade,
  headers,
  tamanhoCelulaNome,
}: HeaderDashboardProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Grid container className={classes.cabecalho}>
      <Grid item md={tamanhoCelulaNome} lg={tamanhoCelulaNome}>
        {nome} {quantidade !== undefined && quantidade > 0 && `(${quantidade})`}
      </Grid>
      {headers.map((item) => (
        <StyledGrid
          key={item.label}
          item
          md={item.tamanhoCelula}
          lg={item.tamanhoCelula}
          paddingleft={item?.paddingLeft || 'auto'}
        >
          {item.label}
        </StyledGrid>
      ))}
    </Grid>
  );
};

export default HeaderDashboard;
