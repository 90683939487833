import React from 'react';

import { makeStyles } from '@material-ui/core';

import InputMonetario from '../../../../../../../shared/components/input/InputMonetario';
import { Rubik } from '../../../../../../../shared/constants/font';

const useStyles = makeStyles((theme) => ({
  input: {
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.cores.branco,
    },
    '& .MuiInputBase-input': {
      fontFamily: Rubik,
    },

    '& .MuiFormHelperText-contained': {
      marginLeft: '0px',
    },
  },
}));

export type InputMonetarioContrapropostaProps = {
  id: string;
  value?: number;
  error?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  onChange(novoValor: number | undefined): void;
};

const InputMonetarioContraproposta = ({
  id,
  value,
  error,
  errorMessage = '*Campo obrigatório',
  disabled,
  onChange,
}: InputMonetarioContrapropostaProps): JSX.Element => {
  const classes = useStyles();

  return (
    <InputMonetario
      fullWidth
      id={id}
      size="small"
      placeholder="R$ 0,00"
      label="Valor"
      className={classes.input}
      value={value}
      onChange={onChange}
      disabled={disabled}
      error={error}
      helperText={error ? errorMessage : undefined}
    />
  );
};

export default InputMonetarioContraproposta;
