const MENSAGEM_ERRO_LISTAGEM_NEGOCIADORES =
  'Ops algo aconteceu, por algum motivo não conseguimos listar os negociadores.';
const MENSAGEM_ERRO_LISTAGEM_DEMANDAS_NEGOCIADORES =
  'Ops algo aconteceu, por algum motivo não conseguimos listar as demandas para o negociador.';
const MENSAGEM_LISTAGEM_DEMANDAS_NEGOCIADORES_VAZIA =
  'Ops, não há demandas para o negociador!';
const MENSAGEM_ERRO_LISTAGEM_NOTIFICACOES =
  'Ops algo aconteceu, por algum motivo não conseguimos listar suas notificações.';
const MENSAGEM_LISTAGEM_NOTIFICACOES_VAZIA =
  'Ops, não há notificações para esse tipo de alerta!';
const MENSAGEM_LISTAGEM_NEGOCIACOES_EM_ANDAMENTO_VAZIA =
  'Ops, não há negociações em andamento!';

const TITULO_MODAL_NEGOCIACOES_EM_ANDAMENTO = 'Todas as filiais';

enum descricaoFiltroNotificacao {
  todas = 'Ver todas',
  naoVista = 'Não vista há',
  semAprovacao = 'Sem aprovação há',
  alertaVencimento = 'Alerta de vencimento',
}

enum ordenacaoPor {
  maisAntiga,
  maisRecente,
}

export {
  MENSAGEM_ERRO_LISTAGEM_NEGOCIADORES,
  MENSAGEM_ERRO_LISTAGEM_DEMANDAS_NEGOCIADORES,
  MENSAGEM_LISTAGEM_DEMANDAS_NEGOCIADORES_VAZIA,
  MENSAGEM_ERRO_LISTAGEM_NOTIFICACOES,
  MENSAGEM_LISTAGEM_NOTIFICACOES_VAZIA,
  TITULO_MODAL_NEGOCIACOES_EM_ANDAMENTO,
  MENSAGEM_LISTAGEM_NEGOCIACOES_EM_ANDAMENTO_VAZIA,
  descricaoFiltroNotificacao,
  ordenacaoPor,
};
