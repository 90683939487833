export const formatarUnidade = (numero: number | undefined): string => {
  const valor = numero || 0;
  return `${valor.toLocaleString('pt-br', { minimumFractionDigits: 2 })}`;
};

export const formatarPeso = (numero: number | undefined): string => {
  return `${formatarUnidade(numero)} kg`;
};

export const formatarVolume = (numero: number | undefined): string => {
  return `${formatarUnidade(numero)} m³`;
};

export const formatarDistancia = (numero: number | undefined): string => {
  return `${formatarUnidade(numero)} km`;
};
