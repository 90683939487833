import { Tab, Tabs } from '@material-ui/core';
import React from 'react';
import Role from 'shared/constants/RoleColaborador';
import { useStyles } from '../styles/styleTabUploads';
import {
  TabUploadsProps,
  dicionario,
  typeAbasUpload,
} from '../types/tabUploads';

const TabUploads = ({
  aba,
  roles,
  carregando,
  setAba,
}: TabUploadsProps): JSX.Element => {
  const classes = useStyles();

  const temPermissaoSupervisor = () =>
    roles?.some((role) => role === Role.SUPERVISOR);

  const mudarAba = (
    _event: React.ChangeEvent<unknown>,
    novaAba: typeAbasUpload,
  ) => {
    setAba(novaAba);
  };

  return (
    <Tabs
      value={aba}
      indicatorColor="primary"
      textColor="primary"
      onChange={mudarAba}
      TabIndicatorProps={{
        style: {
          display: 'none',
        },
      }}
      className={classes.listaAbas}
    >
      <Tab
        label={dicionario[Role.NEGOCIADOR].label}
        value={dicionario[Role.NEGOCIADOR].value}
        disabled={carregando}
        className={classes.botaoTab}
      />

      {temPermissaoSupervisor() && (
        <Tab
          label={dicionario[Role.SUPERVISOR].label}
          value={dicionario[Role.SUPERVISOR].value}
          disabled={carregando}
          className={classes.botaoTab}
        />
      )}
    </Tabs>
  );
};

export default TabUploads;
