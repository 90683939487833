import { useTheme } from '@material-ui/core';
import IconeAlerta from 'assets/icones/IconeAlerta';
import IconeRelogio from 'assets/icones/IconeRelogio';
import IconeVelocimetro from 'assets/icones/IconeVelocimetro';
import { NegociacaoTipoAlerta } from 'features/dashboards/negociadores/api/listagemNegociadoresApiTypes';
import React from 'react';
import ConteudoBigNumberAlerta from 'shared/components/bigNumber/ConteudoBigNumberAlerta';
import CustomTooltip, {
  CustomTooltipProps,
} from 'shared/components/tooltip/CustomTooltip';
import { formatarIntervaloTempo } from 'shared/functions/IntervaloTempo';
import SkeletonQuadroBigNumbersAlerta from './skeletons/SkeletonQuadroBigNumbersAlerta';
import { useStyles } from './styles/QuadroBigNumbersAlerta';
import {
  CustomWarningProps,
  QuadroBigNumbersAlertaProps,
} from './types/QuadroBigNumbersAlerta';

export const CustomWarningTooltip = ({
  children,
  title,
  isTopPlacement,
}: CustomWarningProps): JSX.Element => {
  const customWarningProps: CustomTooltipProps = {
    title,
    arrow: true,
    placement: isTopPlacement ? 'top' : 'bottom',
    children,
  };
  return (
    <div>{React.createElement(CustomTooltip, { ...customWarningProps })}</div>
  );
};

const QuadroBigNumbersAlerta = ({
  carregando,
  demandasDiarias,
  onClickAlerta,
}: QuadroBigNumbersAlertaProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();

  const descricaoAlertaNaoVisto = `Não visto há +${formatarIntervaloTempo(
    demandasDiarias?.tempoMaximoAlertaNaoVisto,
  )}`;
  const descricaoAlertaSemAprovacao = `Sem aprovação há +${formatarIntervaloTempo(
    demandasDiarias?.tempoMaximoAlertaSemAprovacao,
  )}`;
  const descricaoAlertaVencimento = 'Alerta de vencimento';

  return (
    <section className={classes.quadroBigNumbers}>
      <section className={classes.tituloAlerta}>Alertas</section>
      <section className={classes.quadroAlertas}>
        {carregando ? (
          <SkeletonQuadroBigNumbersAlerta />
        ) : (
          <section>
            <CustomWarningTooltip title={descricaoAlertaNaoVisto}>
              <button
                className={classes.containerBigNumber}
                onClick={() => onClickAlerta(NegociacaoTipoAlerta.naoVista)}
                type="button"
              >
                <ConteudoBigNumberAlerta
                  icone={
                    <IconeRelogio
                      transform="scale(1,1)"
                      width={15}
                      height={15}
                    />
                  }
                  cor={theme.palette.cores.lilas[800]}
                  numero={demandasDiarias?.naoVisto}
                  descricao={descricaoAlertaNaoVisto}
                  onClick={() => undefined}
                />
              </button>
            </CustomWarningTooltip>

            <CustomWarningTooltip title={descricaoAlertaSemAprovacao}>
              <button
                className={classes.containerBigNumber}
                onClick={() => onClickAlerta(NegociacaoTipoAlerta.semAprovacao)}
                type="button"
              >
                <ConteudoBigNumberAlerta
                  icone={<IconeVelocimetro width={17} height={17} />}
                  cor={theme.palette.cores.mostarda[800]}
                  numero={demandasDiarias?.semAprovacao}
                  descricao={descricaoAlertaSemAprovacao}
                  onClick={() => undefined}
                />
              </button>
            </CustomWarningTooltip>

            <CustomWarningTooltip title={descricaoAlertaVencimento}>
              <button
                className={classes.containerBigNumber}
                onClick={() =>
                  onClickAlerta(NegociacaoTipoAlerta.alertaVencimento)
                }
                type="button"
              >
                <ConteudoBigNumberAlerta
                  icone={<IconeAlerta width={15} height={15} />}
                  cor={theme.palette.cores.laranjaForte[700]}
                  numero={demandasDiarias?.alertaVencimento}
                  descricao={descricaoAlertaVencimento}
                  onClick={() => undefined}
                />
              </button>
            </CustomWarningTooltip>
          </section>
        )}
      </section>
    </section>
  );
};

export default QuadroBigNumbersAlerta;
