import { makeStyles, withStyles } from '@material-ui/core';
import MuiTableContainer from '@material-ui/core/TableContainer';

export const useStyles = makeStyles((theme) => ({
  cabecalho: {
    '& .MuiTableCell-stickyHeader': {
      backgroundColor: theme.palette.cores.branco,
    },
  },
}));

export const TableContainer = withStyles((theme) => ({
  root: {
    maxHeight: '57vh',
    '& .MuiTableCell-sizeSmall': {
      padding: theme.spacing(0, 1, 0, 1),
    },
  },
}))(MuiTableContainer);
