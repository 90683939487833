export const caminhoImgLogo32x32 = '/images/logos/logo-martins-32x32.png';
export const caminhoImgAvatar = '/images/header/avatar.svg';
export const caminhoIconeLogoMartins = '/images/header/icone-logo-martins.svg';
export const caminhoIconeListaVazia =
  '/images/minhasNegociacoes/icone-lista-motoristas-vazia.svg';
export const caminhoIconeDolarBranco =
  '/images/minhasNegociacoes/icone-dolar-branco.svg';
export const caminhoIconeDolarCinza =
  '/images/minhasNegociacoes/icone-dolar-cinza.svg';
export const caminhoIconeWhatsappBranco =
  '/images/minhasNegociacoes/icone-whatsapp-branco.svg';
export const caminhoIconeWhatsappAzul =
  '/images/minhasNegociacoes/icone-whatsapp-azul.svg';
export const caminhoIconeEnviarMensagem =
  '/images/informacoesNegociacao/icone-enviar-mensagem.svg';
export const caminhoIconeEnviarMensagemCinza =
  '/images/informacoesNegociacao/icone-enviar-mensagem-cinza.svg';
export const caminhoIconeWhatsappAzulGrande =
  '/images/informacoesNegociacao/icone-whatsapp-azul-grande.svg';
export const caminhoIconeImagemDownload =
  '/images/chatNegociacao/icone-download-imagem.svg';
export const caminhoIconeWhatsappCinzaGrande =
  '/images/informacoesNegociacao/icone-whatsapp-cinza-grande.svg';
