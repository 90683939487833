import React from 'react';

import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';

import Porcentagem from '../../../../../shared/components/porcentagem/Porcentagem';

type PorcentagemPropostaProps = {
  styles: string;
  porcentagem: string;
};

const PorcentagemProposta = ({
  styles,
  porcentagem,
}: PorcentagemPropostaProps): JSX.Element => {
  return (
    <Tooltip title="% em relação ao target">
      <Box component="span">
        <Porcentagem styles={styles} porcentagem={porcentagem} />
      </Box>
    </Tooltip>
  );
};

export default PorcentagemProposta;
