import { makeStyles } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';

export const useStyles = makeStyles((theme) => ({
  botaoTab: {
    padding: 9,
    fontSize: 12,
    fontWeight: 400,
    borderRadius: spacingInPixels(theme, 3),
    background: theme.palette.cores.cinza[400],
    color: '#000000',
    textTransform: 'none',
    minHeight: 'auto',
    minWidth: 123,
    transition: 'filter 0.2s ease-in-out',

    '&.Mui-selected': {
      color: theme.palette.cores.branco,
      background: theme.palette.cores.azul[600],
    },

    '&:hover': {
      filter: 'brightness(0.8)',
    },
  },
  listaAbas: {
    height: 32,
    maxHeight: 32,
    marginBottom: '25px',

    '& .MuiTabs-flexContainer': {
      gap: '8px',
    },
  },
}));
