import React, { useCallback, useState } from 'react';
import { Checkbox, CircularProgress } from '@material-ui/core';
import { inserirTarifario } from 'features/detalhesDemandasDiaria/utils/detalhesDemandas';
import { useDispatch } from 'react-redux';
import { useLoading } from 'shared/customHooks/useLoading';
import { ConteudoTarifarioProps } from 'features/detalhesDemandasDiaria/types/tabelaTypes';
import IconeTariafario from './IconeTariafario';
import { useStyles } from '../../styles/styleConteudoTarifario';

const ConteudoTarifario = ({
  idNegociacao,
  tarifarioCadastrado,
}: ConteudoTarifarioProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [statusTarifarioCadastrado, setStatusTarifarioCadastrado] = useState(
    tarifarioCadastrado,
  );

  const buscaDemandasCallback = useCallback(
    async () =>
      inserirTarifario(idNegociacao, setStatusTarifarioCadastrado)(dispatch),
    [dispatch, idNegociacao],
  );

  const [carregando, inserirTarifarioLoading] = useLoading(
    buscaDemandasCallback,
  );

  const handleClickInserirTarifario = () => {
    inserirTarifarioLoading();
  };

  if (statusTarifarioCadastrado) {
    return (
      <div className={classes.formInserir} style={{ gap: '9px' }}>
        <IconeTariafario status={1} />
        <div className={classes.tarafarioCadastrado}>
          <span className={classes.texto}>Feito</span>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.formInserir}>
      <IconeTariafario status={Number(tarifarioCadastrado)} />
      {carregando ? (
        <CircularProgress color="inherit" size={16} />
      ) : (
        <Checkbox onClick={() => handleClickInserirTarifario()} />
      )}
      <span className={classes.texto}>Inserir</span>
    </div>
  );
};

export default ConteudoTarifario;
