import React from 'react';
import { makeStyles } from '@material-ui/core';

import SkeletonBigNumber from './SkeletonBigNumber';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    '@media (max-width: 1024px)': {
      minWidth: '100%',
    },
  },
}));

const SkeletonQuadroBigNumbers = (): JSX.Element => {
  const classes = useStyles();

  return (
    <section className={classes.container}>
      <SkeletonBigNumber />
      <SkeletonBigNumber />
      <SkeletonBigNumber />
      <SkeletonBigNumber />
      <SkeletonBigNumber />
    </section>
  );
};

export default SkeletonQuadroBigNumbers;
