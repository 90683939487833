import { useTheme } from '@material-ui/core/styles';
import * as React from 'react';
import { SVGProps } from 'react';

const IconeReprovadoCirculo = ({
  fill,
  ...outros
}: SVGProps<SVGSVGElement>): JSX.Element => {
  const theme = useTheme();

  return (
    <svg width={22} height={22} viewBox="0 0 22 22" fill="none" {...outros}>
      <path
        d="M14.71 7.29a1 1 0 0 0-1.42 0L11 9.59l-2.29-2.3a1.004 1.004 0 0 0-1.42 1.42L9.59 11l-2.3 2.29a.999.999 0 0 0 0 1.42 1 1 0 0 0 1.42 0l2.29-2.3 2.29 2.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42L12.41 11l2.3-2.29a1 1 0 0 0 0-1.42Zm3.36-3.36A10.001 10.001 0 1 0 3.93 18.07 10 10 0 1 0 18.07 3.93Zm-1.41 12.73A8 8 0 1 1 19 11a7.953 7.953 0 0 1-2.34 5.66Z"
        fill={fill || theme.palette.cores.vermelho[600]}
      />
    </svg>
  );
};

export default IconeReprovadoCirculo;
