import React from 'react';
import { verificarMensagemLiberacaoListaClientes } from '../../../../../shared/constants/TipoMensagemProposta';
import TypographyChat from '../../TypographyChat';
import { ChatBalaoProps } from '../ChatBalao';

const MensagemListaClientes = ({ mensagem }: ChatBalaoProps): JSX.Element => {
  return (
    <TypographyChat>
      O acesso à lista de clientes foi{' '}
      {verificarMensagemLiberacaoListaClientes(mensagem.tipo)
        ? 'liberado'
        : 'bloqueado'}
      .
    </TypographyChat>
  );
};

export default MensagemListaClientes;
