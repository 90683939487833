import { Box, makeStyles } from '@material-ui/core';
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineSeparator,
} from '@material-ui/lab';

import React, { CSSProperties } from 'react';
import { calcularPorcentagemInteira } from 'shared/functions/porcentagemUtils';

import Porcentagem from 'shared/components/porcentagem/Porcentagem';
import spacingInPixels from 'shared/functions/materialUtils';
import { EventoProposta } from '../../api/detalhesAprovacaoSupervisorTypes';
import DataHoraEvento from './DataHoraEvento';
import { obterItemCard } from './ItensCards';
import ValorDescricao from './ValorDescricao';

export type CardEventoProps = {
  evento: EventoProposta;
  valorFrotaPropria: number;
  primeiro?: boolean;
  ultimo?: boolean;
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    gridTemplateRows: 'auto',
    backgroundColor: theme.palette.cores.cinza[300],
    borderRadius: spacingInPixels(theme, 1),
    padding: spacingInPixels(theme, 1),
  },
  badgePorcentagem: {
    borderRadius: spacingInPixels(theme, 2),
    margin: 0,
  },
  pontoTimeline: {
    backgroundColor: ({ cor }: { cor?: CSSProperties['color'] }) => cor,
    boxShadow: 'none',
  },
  conectorPonta: {
    backgroundColor: theme.palette.cores.branco,
  },
  content: {
    padding: '6px 5px 6px 16px',
  },
}));

const CardEvento = ({
  evento,
  valorFrotaPropria,
  primeiro,
  ultimo,
}: CardEventoProps): JSX.Element => {
  const itemEvento = obterItemCard(evento);
  const classes = useStyles({ cor: itemEvento?.cor });

  return (
    <>
      <TimelineSeparator>
        <TimelineConnector className={primeiro ? classes.conectorPonta : ''} />
        <TimelineDot className={classes.pontoTimeline} />
        <TimelineConnector className={ultimo ? classes.conectorPonta : ''} />
      </TimelineSeparator>
      <TimelineContent className={classes.content}>
        <Box className={classes.container} alignItems="center">
          {itemEvento?.icone}
          <ValorDescricao
            valor={evento.valorContraproposta}
            descricao={itemEvento?.texto}
            cor={itemEvento?.cor}
          />
          <Porcentagem
            styles={classes.badgePorcentagem}
            porcentagem={`${calcularPorcentagemInteira(
              evento.valorContraproposta,
              valorFrotaPropria,
            )}%`}
            complemento="Target"
          />
          <DataHoraEvento dataHora={evento.dataEnvio} />
        </Box>
      </TimelineContent>
    </>
  );
};

export default CardEvento;
