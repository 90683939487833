import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';

import { makeStyles } from '@material-ui/core';
import TypographyChat from '../../TypographyChat';

import Botao from '../../../../../shared/components/button/BotaoRubik';

import BotaoCancelar from './BotaoCancelar';

import { estaEmBranco } from '../../../../../shared/functions/stringUtils';
import msgConstants from '../../../../../shared/constants/msgConstants';
import { RootState } from '../../../../../store/reducer';
import { onClickBotaoEnviarRecusa } from '../../../utils/chatRecusa';
import SelectMotivoRecusa from '../../../../../shared/components/select/SelectMotivoRecusa';
import spacingInPixels from '../../../../../shared/functions/materialUtils';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: spacingInPixels(theme, 2),
    marginTop: spacingInPixels(theme, 2),
  },
  margemBotao: {
    marginBottom: spacingInPixels(theme, 1),
  },
}));

const RecusaProposta = (): JSX.Element => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [mensagemErro, setMensagemErro] = useState('');
  const [motivoRecusa, setMotivoRecusa] = useState('');
  const [erro, setErro] = useState(false);
  const [enviando, setEnviando] = useState(false);

  const { chatIdNegociacao, chatIdProposta } = useSelector(
    (state: RootState) => state.chatNegociacaoReducer,
  );

  const onSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setMotivoRecusa(String(event.target.value));
  };

  useEffect(() => {
    if (!estaEmBranco(motivoRecusa)) {
      setErro(false);
      setMensagemErro('');
    }
  }, [motivoRecusa]);

  const preencherErro = () => {
    setErro(true);
    setMensagemErro(msgConstants.CAMPO_OBRIGATORIO);
  };

  const fecharModal = () => {
    setErro(false);
    setMensagemErro('');
    setMotivoRecusa('');
  };

  const recusarProposta = async () => {
    if (!estaEmBranco(motivoRecusa)) {
      fecharModal();

      await onClickBotaoEnviarRecusa(
        chatIdNegociacao,
        chatIdProposta,
        undefined,
        undefined,
        Number(motivoRecusa),
        setEnviando,
        dispatch,
      );
    } else {
      preencherErro();
    }
    setEnviando(false);
  };

  return (
    <Box className={classes.container}>
      <Box width="65%">
        <TypographyChat>
          Selecione um motivo para a recusa dessa proposta. Essa informação não
          será disponibilizada ao motorista.
        </TypographyChat>
        <Box margin="16px">
          <SelectMotivoRecusa
            value={motivoRecusa}
            onChange={onSelectChange}
            error={erro}
            label="Motivo recusar negociação"
            id="select-motivos-recusar-negociacao"
            mensagemErro={mensagemErro}
          />
        </Box>
      </Box>

      <Botao
        fullWidth
        variant="contained"
        color="primary"
        className={classes.margemBotao}
        onClick={recusarProposta}
        disabled={enviando}
      >
        Recusar Proposta
      </Botao>
      <BotaoCancelar />
    </Box>
  );
};

export default RecusaProposta;
