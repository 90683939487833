import React from 'react';
import { Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import IconePeso from 'assets/icones/IconePeso';
import IconeCaixa from 'assets/icones/IconeCaixa';
import IconeVelocimetro from 'assets/icones/IconeVelocimetro';
import IconeDropBox from 'assets/icones/IconeDropBox';
import CustomSkeleton from 'shared/components/skeleton/CustomSkeleton';
import { formatarNumeroParaStringMonetariaComSimbolo } from 'shared/functions/dinheiroUtils';
import Paper from 'shared/components/paper/Paper';
import InformacaoItem from 'shared/components/item/InformacaoItem';
import IconePapel from 'assets/icones/IconePapel';
import IconeMoney from 'assets/icones/IconeMoney';
import IconeLocalizacao from 'assets/icones/IconeLocalizacao';
import spacingInPixels from 'shared/functions/materialUtils';

export type CardDetalhesCargaProps = {
  valorMercadoria?: number;
  pesoTotal?: number;
  volumeTotal?: number;
  distancia?: number;
  totalEntregas?: number;
  entregasPFePJ?: string;
  romaneio?: string;
  cidadePrincipal?: string;
  carregando: boolean;
};

const CardDetalhesCarga = ({
  valorMercadoria,
  pesoTotal,
  volumeTotal,
  distancia,
  totalEntregas,
  entregasPFePJ,
  romaneio,
  cidadePrincipal,
  carregando,
}: CardDetalhesCargaProps): JSX.Element => {
  const theme = useTheme();

  const estilo = {
    tituloColor: String(theme.palette.primary.main),
  };

  const formatarValor = (valor?: number): string =>
    valor?.toLocaleString() || '-';

  return (
    <Paper
      style={{
        margin: theme.spacing(2, 0, 2, 0),
        padding: theme.spacing(1),
        borderRadius: spacingInPixels(theme, 1),
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        padding="10px"
      >
        {carregando ? (
          new Array(7)
            .fill(null)
            .map((_, index) => (
              <CustomSkeleton
                width="100px"
                height={24}
                borderradius={16}
                key={`skeleton-detalhe-carga-${index + 1}`}
              />
            ))
        ) : (
          <>
            <InformacaoItem
              icone={
                <IconeLocalizacao
                  width="24px"
                  height="24px"
                  fill={theme.palette.cores.azulMartins[500]}
                />
              }
              informacao={cidadePrincipal}
              altIcone="Cidade principal"
              titleIcone={`Cidade principal: ${cidadePrincipal} `}
              estilo={estilo}
            />
            <InformacaoItem
              icone={
                <IconePapel width="24px" height="24px" viewBox="0 -2 24 24" />
              }
              informacao={romaneio}
              altIcone="Romaneio"
              titleIcone={`Romaneio: ${romaneio} `}
              estilo={estilo}
            />
            <InformacaoItem
              icone={<IconeMoney width={24} height={24} />}
              informacao={`${formatarNumeroParaStringMonetariaComSimbolo(
                valorMercadoria,
              )}`}
              altIcone="Valor Mercadoria"
              titleIcone="Valor Mercadoria"
              estilo={estilo}
            />

            <InformacaoItem
              icone={<IconePeso fill={theme.palette.cores.azulMartins[500]} />}
              informacao={`${formatarValor(pesoTotal)} kg`}
              altIcone="Peso total"
              titleIcone="Peso total"
              estilo={estilo}
            />

            <InformacaoItem
              icone={
                <IconeCaixa
                  fill={theme.palette.cores.azulMartins[500]}
                  width="20px"
                  height="20px"
                />
              }
              informacao={`${formatarValor(volumeTotal)} m³`}
              altIcone="Volume total"
              titleIcone="Volume total"
              estilo={estilo}
            />

            <InformacaoItem
              icone={
                <IconeVelocimetro
                  fill={theme.palette.cores.azulMartins[500]}
                  width="20px"
                  height="20px"
                />
              }
              informacao={`${formatarValor(distancia)} km`}
              altIcone="Quilometragem"
              titleIcone="Quilometragem"
              estilo={estilo}
            />

            <InformacaoItem
              icone={
                <IconeDropBox
                  fill={theme.palette.cores.azulMartins[500]}
                  width="20px"
                  height="20px"
                />
              }
              informacao={`${formatarValor(totalEntregas)} (${entregasPFePJ})`}
              altIcone="Entregas PF e PJ"
              titleIcone="Entregas PF e PJ"
              estilo={estilo}
            />
          </>
        )}
      </Box>
    </Paper>
  );
};

export default CardDetalhesCarga;
