import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import TipoValorContraproposta from '../../../../../constants/TipoValorContraproposta';
import InputMonetarioContraproposta from './InputMonetarioContraproposta';
import InputPorcentagemValorContraproposta from './InputPorcentagemValorContraproposta';

const useStyles = makeStyles(() => ({
  container: {
    width: '48.5%',
  },
}));

export type InputValorContrapropostaProps = {
  tipo: TipoValorContraproposta;
  valorInput?: number;
  handleInput: (valor: number) => void;
  erro?: boolean;
  mensagemErro?: string;
  desabilitado?: boolean;
};

const InputValorContraproposta = ({
  tipo,
  valorInput,
  handleInput,
  erro,
  mensagemErro,
  desabilitado,
}: InputValorContrapropostaProps): JSX.Element => {
  const classes = useStyles();
  const montarInputPorcentagem = (id: string, precisaoDecimal: boolean) => (
    <InputPorcentagemValorContraproposta
      id={id}
      precisaoDecimal={precisaoDecimal}
      value={valorInput}
      error={erro}
      errorMessage={mensagemErro}
      disabled={desabilitado}
      onChange={handleInput}
    />
  );

  const montarInputMonetario = (id: string) => (
    <InputMonetarioContraproposta
      id={id}
      value={valorInput}
      error={erro}
      errorMessage={mensagemErro}
      disabled={desabilitado}
      onChange={handleInput}
    />
  );

  const montarInputs = () => {
    switch (tipo) {
      case TipoValorContraproposta.PorcentagemFrotaPropria:
        return montarInputPorcentagem(
          'chat-input-porcentagem-frota-propria',
          false,
        );
      case TipoValorContraproposta.PorcentagemFreteTerceiro:
        return montarInputPorcentagem(
          'chat-input-porcentagem-frete-terceiro',
          false,
        );
      case TipoValorContraproposta.PorcentagemMercadoria:
        return montarInputPorcentagem(
          'chat-input-porcentagem-mercadoria',
          true,
        );
      case TipoValorContraproposta.PorEntrega:
        return montarInputMonetario('chat-input-monetario-por-entrega');
      case TipoValorContraproposta.PorKm:
        return montarInputMonetario('chat-input-monetario-por-km');
      case TipoValorContraproposta.PorPeso:
        return montarInputMonetario('chat-input-monetario-por-peso');
      case TipoValorContraproposta.ValorProposto:
      default:
        return montarInputMonetario('chat-input-monetario-valor-proposto');
    }
  };

  return <Box className={classes.container}>{montarInputs()}</Box>;
};

export default InputValorContraproposta;
