import { useTheme } from '@material-ui/core';
import * as React from 'react';
import { SVGProps } from 'react';

const IconeFilial = ({
  fill,
  width,
  height,
  ...outros
}: SVGProps<SVGSVGElement>): JSX.Element => {
  const theme = useTheme();
  return (
    <svg
      width={width || 12}
      height={height || 14}
      fill={fill || 'none'}
      {...outros}
    >
      <path
        d="M6 3h.5a.5.5 0 1 0 0-1H6a.5.5 0 1 0 0 1Zm0 2h.5a.5.5 0 1 0 0-1H6a.5.5 0 1 0 0 1ZM3.5 3H4a.5.5 0 1 0 0-1h-.5a.5.5 0 1 0 0 1Zm0 2H4a.5.5 0 1 0 0-1h-.5a.5.5 0 1 0 0 1Zm6 4H9V.5a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0-.5.5V9H.5a.5.5 0 0 0 0 1h9a.5.5 0 1 0 0-1Zm-4 0h-1V7h1v2ZM8 9H6.5V6.5A.5.5 0 0 0 6 6H4a.5.5 0 0 0-.5.5V9H2V1h6v8Z"
        fill={fill || theme.palette.cores.cinza[50]}
      />
    </svg>
  );
};

export default IconeFilial;
