import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';

import { RootState } from '../../../../store/reducer';

import TextField from '../../../../shared/components/input/TextField';
import BotaoIcone from '../../../../shared/components/button/BotaoIcone';
import MsgConstants from '../../../../shared/constants/msgConstants';
import UtilConstants from '../../../../shared/constants/utilConstants';
import { estaVazio } from '../../../../shared/functions/stringUtils';
import { spacingInPixels } from '../../../../shared/functions/materialUtils';

import { atualizarObservacaoOperation } from '../../redux/informacoesNegociacaoOperation';
import { abrirModalExcluirObservacao } from '../../redux/informacoesNegociacaoAction';

import { verificarSeNegociacaoTemObservacao } from '../../functions/informacoesNegociacaoUtils';

const TextFieldObservacao = withStyles({
  root: {
    width: '68%',
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  espacamento: {
    marginRight: spacingInPixels(theme, 1),
  },
  textoObservacao: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: spacingInPixels(theme, 3),
  },
}));

const CampoObservacao = (): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const { idNegociacao, negociacao, negociacaoConcluida } = useSelector(
    (state: RootState) => state.informacoesNegociacaoReducer,
  );

  const campoObservacoes = React.createRef<HTMLInputElement>();

  const [observacoes, setObservacoes] = useState('');
  const [observacoesAnterior, setObservacoesAnterior] = useState('');
  const [descricaoErro, setDescricaoErro] = useState('');
  const [campoDesabilitado, setCampoDesabilitado] = useState(false);
  const [erroCampoObrigatorio, setErroCampoObrigatorio] = useState(false);

  useEffect(() => {
    const observacoesString = negociacao?.observacoes || '';
    const negociacaoPossuiObservacao = verificarSeNegociacaoTemObservacao(
      negociacao,
    );

    setObservacoes(observacoesString);
    setObservacoesAnterior(observacoesString);
    setCampoDesabilitado(negociacaoPossuiObservacao);
  }, [negociacao]);

  const abrirModalExcluir = () => {
    dispatch(abrirModalExcluirObservacao());
  };

  const setFluxoCriar = () => {
    setCampoDesabilitado(false);
    if (campoObservacoes.current) {
      campoObservacoes.current.focus();
    }
  };

  const setFluxoEditar = () => {
    setCampoDesabilitado(true);
  };

  const salvarObservacao = async (valorObservacao: string) =>
    atualizarObservacaoOperation(idNegociacao, {
      observacoes: valorObservacao,
    })(dispatch);

  const campoEstaPreenchido = () => {
    const campoVazio = estaVazio(observacoes);
    if (campoVazio) {
      setErroCampoObrigatorio(true);
      setDescricaoErro(MsgConstants.CAMPO_OBRIGATORIO);

      if (campoObservacoes.current) {
        campoObservacoes.current.focus();
      }
    }
    return !campoVazio;
  };

  const campoFoiPreenchido = () => {
    setErroCampoObrigatorio(false);
    setDescricaoErro(UtilConstants.VAZIO);
  };

  const criar = async () => {
    if (campoEstaPreenchido()) {
      await salvarObservacao(observacoes);
      setObservacoesAnterior(observacoes);
      setFluxoEditar();
    }
  };

  const cancelar = () => {
    setObservacoes(observacoesAnterior);
    if (estaVazio(observacoesAnterior)) {
      setFluxoCriar();
    } else {
      setFluxoEditar();
    }
  };

  const habilitarEdicao = () => {
    setFluxoCriar();
  };

  const atualizarObservacoes = (event: React.ChangeEvent<HTMLInputElement>) => {
    setObservacoes(event.target.value);
  };

  const BotoesCriar = (): JSX.Element => (
    <>
      <Box className={classes.espacamento}>
        <BotaoIcone descricao="Salvar observação" onClick={criar}>
          <SaveRoundedIcon color="primary" />
        </BotaoIcone>
      </Box>
      <Box>
        <BotaoIcone
          descricao="Cancelar edição da observação"
          onClick={cancelar}
        >
          <CloseRoundedIcon color="primary" />
        </BotaoIcone>
      </Box>
    </>
  );

  const BotoesEditar = (): JSX.Element => (
    <>
      <Box className={classes.espacamento}>
        <BotaoIcone descricao="Editar observação" onClick={habilitarEdicao}>
          <EditRoundedIcon color="primary" />
        </BotaoIcone>
      </Box>
      <Box>
        <BotaoIcone descricao="Excluir observação" onClick={abrirModalExcluir}>
          <DeleteForeverRoundedIcon color="primary" />
        </BotaoIcone>
      </Box>
    </>
  );

  if (negociacaoConcluida) {
    return (
      <Box display="flex" width="100%" marginTop={spacingInPixels(theme, -3)}>
        <Typography className={classes.textoObservacao} color="textPrimary">
          {observacoes}
        </Typography>
      </Box>
    );
  }

  return (
    <Box display="flex" width="100%">
      <TextFieldObservacao
        id="Observações"
        label="Observações"
        variant="outlined"
        size="small"
        multiline
        inputRef={campoObservacoes}
        value={observacoes}
        onBlur={campoFoiPreenchido}
        onChange={atualizarObservacoes}
        className={classes.espacamento}
        disabled={campoDesabilitado}
        error={erroCampoObrigatorio}
        helperText={descricaoErro}
        autoFocus
        inputProps={{ maxLength: 200 }}
      />
      {campoDesabilitado ? <BotoesEditar /> : <BotoesCriar />}
    </Box>
  );
};

export default CampoObservacao;
