import { Box, Tab, Tabs } from '@material-ui/core';
import React from 'react';
import { Skeleton } from '@material-ui/lab';
import TimerTrintaMinutos from 'shared/components/timerTrintaSegundos/TimerTrintaMinutos';
import { TypeTabDadosProps } from '../../types/typeTabsDados';
import { useStyles } from '../../styles/styleTabProps';

const TabsDados = ({
  aba,
  carregando,
  setAba,
}: TypeTabDadosProps): JSX.Element => {
  const classes = useStyles();

  const mudarAba = (_event: React.ChangeEvent<unknown>, novaAba: boolean) => {
    setAba(novaAba);
  };

  return carregando ? (
    <Box className={classes.skeletonBox}>
      <Skeleton width={145} height={60} />
      <Skeleton width={145} height={60} />
    </Box>
  ) : (
    <Box className={classes.alingTab}>
      <Tabs
        value={aba}
        indicatorColor="primary"
        textColor="primary"
        onChange={mudarAba}
        TabIndicatorProps={{
          style: {
            display: 'none',
          },
        }}
        className={classes.listaAbas}
      >
        <Tab label="Negociações do dia" value className={classes.botaoTab} />

        <Tab
          label="Tarifas não atualizadas"
          value={false}
          className={classes.botaoTab}
        />
      </Tabs>
      <TimerTrintaMinutos />
    </Box>
  );
};

export default TabsDados;
