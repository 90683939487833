import React from 'react';

import MuiBadge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core';

import IconStatusMotorista from '../../../shared/components/icons/IconStatusMotorista';

import { Motorista } from '../api/minhasNegociacoesApiTypes';

import AvatarMotoristaConteudo from './AvatarMotoristaConteudo';

const Badge = withStyles({
  anchorOriginBottomRightRectangle: {
    bottom: '2px',
    right: '4px',
  },
})(MuiBadge);

export type AvatarMotoristaProps = {
  motorista: Motorista;
  idNegociacao: number;
};

const AvatarMotoristaBadge = ({
  motorista,
  idNegociacao,
}: AvatarMotoristaProps): JSX.Element => {
  return (
    <Badge
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      badgeContent={<IconStatusMotorista codigo={motorista.status} />}
    >
      <AvatarMotoristaConteudo
        motorista={motorista}
        idNegociacao={idNegociacao}
        avatarPadrao
      />
    </Badge>
  );
};

export default AvatarMotoristaBadge;
