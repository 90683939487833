import React, { useCallback, useEffect, useState } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';

import {
  Box,
  Button,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';

import { Pagination, Skeleton } from '@material-ui/lab';
import MotoristasVeiculosFilters from 'shared/components/motoristasVeiculosFilters';
import IconeCaixaVazia from 'assets/icones/IconeCaixaVazia';
import BoxWhiteCustomScrollTable from '../../../shared/components/box/BoxWhiteCustomScrollTable';

import TabelaTitulo from './TabelaTitulo';
import TabelaItem from './TabelaItem';
import { obterMotoristasVeiculosDashboard } from '../api/motoristasVeiculosService';
import { MotoristasList } from '../api/motoristasVeiculosApiTypes';

const TabelaMotoristasVeiculos = (): JSX.Element => {
  const [dadosMotoristas, setDadosMotoristas] = useState<MotoristasList[]>([]);
  const [filtroFilial, setFiltroFilial] = useState<string>('');
  const [filtroNome, setFiltroNome] = useState<string>('');
  const [filtroCategoriaVeiculo, setFiltroCategoriaVeiculo] = useState<string>(
    '',
  );
  const [boxVazio, setBoxVazio] = useState(false);
  const [filtroOrdenarPor, setFiltroOrdenarPor] = useState<string>('');

  const [paginaAtual, setPaginaAtual] = useState<number>(1);
  const [totalPagina, setTotalPagina] = useState<number>();

  const [open, setOpen] = useState(false);

  const getMotoristasDashboard = useCallback(
    async (
      pagina: number,
      idFilial: string,
      categoriaVeiculoId: string,
      ordenarPor: string,
      nome: string,
    ) => {
      try {
        const resposta = await obterMotoristasVeiculosDashboard(
          String(pagina),
          idFilial,
          categoriaVeiculoId,
          ordenarPor,
          nome,
        );

        if (resposta.motoristas.length === 0) {
          setBoxVazio(true);
        } else {
          setBoxVazio(false);
        }

        setDadosMotoristas(resposta.motoristas || []);
        setTotalPagina(resposta.quantidadePaginas);
      } catch (err) {
        setDadosMotoristas([]);
        setBoxVazio(true);
        setTotalPagina(0);
      }
    },
    [],
  );

  useEffect(() => {
    getMotoristasDashboard(
      paginaAtual,
      filtroFilial,
      filtroCategoriaVeiculo,
      filtroOrdenarPor,
      filtroNome,
    );
  }, [
    filtroCategoriaVeiculo,
    filtroFilial,
    getMotoristasDashboard,
    paginaAtual,
    filtroOrdenarPor,
    filtroNome,
  ]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number,
  ) => {
    setPaginaAtual(page);
  };

  const handleFilter = (filtro: {
    ordenacaoFilter: string;
    idFilial: string;
    idVeiculo: string;
    searchNome: string;
  }) => {
    setFiltroFilial(filtro.idFilial);
    setFiltroCategoriaVeiculo(filtro.idVeiculo);
    setFiltroOrdenarPor(filtro.ordenacaoFilter);
    setFiltroNome(filtro.searchNome);
    setPaginaAtual(1);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const skeletonRows = [...Array(10)].map((_) => (
    <TableRow key={`Negociacao-${_}`}>
      <TableCell align="left">
        <Skeleton
          width={100}
          animation="wave"
          height={22}
          style={{ borderRadius: '8px' }}
        />
      </TableCell>
      <TableCell align="left">
        <Skeleton
          width={120}
          animation="wave"
          height={22}
          style={{ borderRadius: '8px' }}
        />
      </TableCell>
      <TableCell align="left">
        <Skeleton
          width={120}
          animation="wave"
          height={22}
          style={{ borderRadius: '8px' }}
        />
      </TableCell>
      <TableCell align="right">
        <Skeleton
          width={80}
          animation="wave"
          height={22}
          style={{ borderRadius: '8px' }}
        />
      </TableCell>
      <TableCell align="left">
        <Skeleton
          width={140}
          animation="wave"
          height={22}
          style={{ borderRadius: '8px' }}
        />
      </TableCell>
      <TableCell align="right">
        <Skeleton
          width={80}
          animation="wave"
          height={22}
          style={{ borderRadius: '8px' }}
        />
      </TableCell>
      <TableCell align="right">
        <Skeleton
          width={80}
          animation="wave"
          height={22}
          style={{ borderRadius: '8px' }}
        />
      </TableCell>
      <TableCell align="right">
        <Skeleton
          width={120}
          animation="wave"
          height={22}
          style={{ borderRadius: '8px' }}
        />
      </TableCell>
    </TableRow>
  ));

  return (
    <>
      <Button
        style={{ position: 'absolute', top: 85, right: 20 }}
        variant="contained"
        color="primary"
        onClick={handleOpen}
      >
        Filtros
      </Button>
      <BoxWhiteCustomScrollTable
        style={{
          borderRadius: '0 0 8px 8px',
        }}
      >
        <TableContainer>
          <Table size="small" aria-label="Tabela - Minhas Negociações">
            <TableHead>
              <TabelaTitulo />
            </TableHead>
            <TableBody>
              {dadosMotoristas.length === 0 && !boxVazio && skeletonRows}
              {dadosMotoristas.map((motorista) => (
                <>
                  {dadosMotoristas && (
                    <TabelaItem
                      key={`Motorista-${motorista.cpf}`}
                      motorista={motorista}
                    />
                  )}
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {dadosMotoristas.length === 0 && boxVazio && (
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              height: '66vh',
              borderRadius: '100%',
              textAlign: 'center',
            }}
          >
            <IconeCaixaVazia style={{ margin: '-10px 0 24px 0' }} />
            <Typography style={{ color: '#424242', fontWeight: 400 }}>
              Não encontramos nenhum motorista com esse filtro
            </Typography>
          </Box>
        )}
      </BoxWhiteCustomScrollTable>
      <Box style={{ marginTop: '10px', float: 'right', marginBottom: '50px' }}>
        <Pagination
          count={totalPagina}
          shape="rounded"
          color="primary"
          size="large"
          variant="outlined"
          page={paginaAtual}
          onChange={handlePageChange}
        />
      </Box>
      <MotoristasVeiculosFilters
        open={open}
        onClose={handleClose}
        onFilter={handleFilter}
      />
    </>
  );
};

export default TabelaMotoristasVeiculos;
