import { withStyles, Icon, Tooltip } from '@material-ui/core';
import React from 'react';

interface BackgroundIconStatusMotoristaStylesProps {
  corfundo?: string;
}

export const BackgroundIconStatusMotorista = withStyles(() => ({
  root: (props: BackgroundIconStatusMotoristaStylesProps) => ({
    borderRadius: '50%',
    backgroundColor: props.corfundo,
    height: '17px',
    width: '17px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
}))(Icon);

interface IconTooltipStatusMotoristaProps {
  icon: React.ReactElement;
  backgroundColor?: string;
  title?: string;
}

const IconTooltipStatusMotorista = ({
  icon,
  backgroundColor,
  title = '',
}: IconTooltipStatusMotoristaProps): JSX.Element => {
  return (
    <Tooltip title={title}>
      <BackgroundIconStatusMotorista corfundo={backgroundColor}>
        {icon}
      </BackgroundIconStatusMotorista>
    </Tooltip>
  );
};

IconTooltipStatusMotorista.defaultProps = {
  backgroundColor: 'transparent',
  title: '',
};

export default IconTooltipStatusMotorista;
