import React from 'react';
import { formatarViagens } from '../../utils/indicadoresMotoristaRota';
import InformacoesPersona from './InformacoesPersona';

export type InformacoesMotoristaType = {
  nome?: string;
  tipoVeiculo?: string;
  viagensRotasSimilares: number;
};

const InformacoesMotorista = ({
  nome = '-',
  tipoVeiculo = '-',
  viagensRotasSimilares,
}: InformacoesMotoristaType): JSX.Element => {
  const textoViagens = `${viagensRotasSimilares} ${formatarViagens(
    viagensRotasSimilares,
  )}`;
  return (
    <InformacoesPersona
      nome={nome}
      tipoVeiculo={tipoVeiculo}
      texto={textoViagens}
    />
  );
};

export default InformacoesMotorista;
