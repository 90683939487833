import { withStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';

import scrollbarConstructor from '../../functions/scrollbarConstructor';

export default withStyles((theme) => ({
  root: {
    ...scrollbarConstructor('MuiPaper-root', theme),
  },
}))(Popover);
