import React from 'react';
import { useHistory } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

import { format } from 'date-fns';

import { Box } from '@material-ui/core';

import { ColaboradoresNegociadores } from 'features/configuracoes/api/configuracoesApiTypes';
import LinkButton from '../../../shared/components/button/LinkButton';
import TableRow from '../../../shared/components/table/TableRowHeightSpacing6';
import TableCell from '../../../shared/components/table/TableCellCustomPadding';
import { obterCodigoFormatado } from '../../../shared/functions/romaneioUtils';
import CustomWidthTooltip from '../../../shared/components/tooltip/CustomWidthTooltip';

import { Negociacao } from '../api/minhasNegociacoesApiTypes';

import ListaMotoristasEnvolvidos from './ListaMotoristasEnvolvidos';
import BoxStatusNegociacao from './BoxStatusNegociacao';

export type TabelaItemProps = {
  negociacao: Negociacao;
  checkboxValue: boolean;
  onCheckboxChange: (checked: boolean) => void;
  onAmbienteAssistido: boolean;
  selectColaboradorDrawer: ColaboradoresNegociadores[];
};

const LinkButtonCellBold = withStyles({
  root: {
    fontSize: '16px',
    fontWeight: 700,
  },
})(LinkButton);

const TableCellBold = withStyles({
  root: {
    fontSize: '16px',
    fontWeight: 700,
  },
})(TableCell);

const TableCellSmall = withStyles({
  root: {
    fontSize: '14px',
    fontWeight: 500,
  },
})(TableCell);

const TabelaItem = ({
  negociacao,
  checkboxValue,
  onCheckboxChange,
  onAmbienteAssistido,
  selectColaboradorDrawer,
}: TabelaItemProps): JSX.Element => {
  const history = useHistory();
  const dataFormatada = format(
    new Date(negociacao.dataCarregamento),
    'dd/MM/yyyy',
  );

  return (
    <TableRow key={negociacao.id}>
      <TableCellBold
        align="left"
        style={{
          padding: negociacao.isNegociacaoTransferida ? '0' : undefined,
          height: '9vh',
        }}
      >
        <Box display="flex">
          {negociacao.isNegociacaoTransferida ? (
            <div
              style={{
                borderLeft: '4px solid #2962FF',
                height: '9vh',
                paddingRight: '15px',
              }}
            />
          ) : (
            <div
              style={{
                borderLeft: '4px solid #ffffff',
                height: '9vh',
              }}
            />
          )}
          {onAmbienteAssistido && (
            <Checkbox
              id={obterCodigoFormatado(negociacao.romaneioCarga, 1)}
              style={{ marginLeft: '-20px' }}
              onChange={(event) => onCheckboxChange(event.target.checked)}
              checked={checkboxValue || false}
            />
          )}
          <CustomWidthTooltip
            title={`Ver detalhamento da negociação ${obterCodigoFormatado(
              negociacao.romaneioCarga,
            )}`}
          >
            <LinkButtonCellBold
              onClick={() =>
                history.push(
                  `/minhas-negociacoes/${negociacao.id}`,
                  onAmbienteAssistido
                    ? {
                        idUsuarioMaster: selectColaboradorDrawer[0]?.id,
                        origemFreteTabelado: false,
                        idFilial: negociacao.filialId,
                      }
                    : {
                        origemFreteTabelado: false,
                        idFilial: negociacao.filialId,
                      },
                )
              }
            >
              {obterCodigoFormatado(negociacao.romaneioCarga, 1)}
            </LinkButtonCellBold>
          </CustomWidthTooltip>
        </Box>
      </TableCellBold>
      <TableCellSmall align="left">{dataFormatada}</TableCellSmall>
      <TableCellSmall align="left">{negociacao.filial}</TableCellSmall>
      <TableCellSmall align="left">{negociacao.cidade}</TableCellSmall>
      <TableCellSmall align="center">
        {negociacao.quantidadeEntregas}
      </TableCellSmall>
      <TableCellSmall align="center">
        {negociacao.peso.toLocaleString()}
      </TableCellSmall>
      <TableCellSmall align="center">
        {negociacao.volume.toLocaleString()}
      </TableCellSmall>
      <TableCellSmall align="center">
        {negociacao.distancia.toLocaleString()}
      </TableCellSmall>
      <TableCell align="center">
        {negociacao.motoristasEnvolvidos.length > 0 && (
          <ListaMotoristasEnvolvidos negociacao={negociacao} />
        )}
      </TableCell>
      <TableCell align="center">
        <BoxStatusNegociacao
          codigoStatus={negociacao.status}
          isNegociacaoUrgente={negociacao.urgente}
        />
      </TableCell>
    </TableRow>
  );
};

export default TabelaItem;
