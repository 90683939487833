import React from 'react';

import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { useTheme, withStyles } from '@material-ui/core/styles';

import { spacingInPixels } from '../../../shared/functions/materialUtils';
import NegociacaoStatus, {
  NegociacaoStatusType,
  verificarNegociacaoConcluida,
} from '../../../shared/constants/NegociacaoStatus';

export type StatusNegociacao = {
  codigo: NegociacaoStatusType;
  texto: string;
  componente: JSX.Element;
};

const BoxStatus = withStyles((theme) => ({
  root: {
    height: spacingInPixels(theme, 3),
    padding: '0px 12px 0px 12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '25px',
  },
}))(Box);

const Texto = withStyles({
  root: {
    fontSize: '12px',
    fontWeight: 500,
  },
})(Typography);

const NOVA_NEGOCIACAO: StatusNegociacao = {
  codigo: NegociacaoStatus.NovaNegociacao,
  texto: 'Nova negociação',
  componente: (
    <BoxStatus bgcolor="cores.azul.50">
      <Texto style={{ color: '#2962FF' }}>Nova</Texto>
    </BoxStatus>
  ),
};

const EM_ANDAMENTO: StatusNegociacao = {
  codigo: NegociacaoStatus.EmAndamento,
  texto: 'Em andamento',
  componente: (
    <BoxStatus bgcolor="cores.lilas.50">
      <Texto style={{ color: '#6A1B9A' }}>Em andamento</Texto>
    </BoxStatus>
  ),
};

const CANCELADA: StatusNegociacao = {
  codigo: NegociacaoStatus.Cancelada,
  texto: 'Cancelada',
  componente: (
    <BoxStatus bgcolor="cores.vermelho.100">
      <Texto style={{ color: '#B71C1C' }}>Cancelada</Texto>
    </BoxStatus>
  ),
};

const AGUARDAR_GESTAO: StatusNegociacao = {
  codigo: NegociacaoStatus.AguardarGestao,
  texto: 'Aguardar gestão',
  componente: (
    <BoxStatus bgcolor="cores.laranja.100">
      <Texto style={{ color: '#FF6D00' }}>Supervisor</Texto>
    </BoxStatus>
  ),
};

const CONCLUIDA: StatusNegociacao = {
  codigo: NegociacaoStatus.Concluida,
  texto: 'Concluída',
  componente: (
    <BoxStatus bgcolor="cores.verdeClaro.100">
      <Texto style={{ color: '#2E7D32' }}>Concluída</Texto>
    </BoxStatus>
  ),
};

export const StatusNegociacaoConstants: StatusNegociacao[] = [
  NOVA_NEGOCIACAO,
  EM_ANDAMENTO,
  AGUARDAR_GESTAO,
  CANCELADA,
  CONCLUIDA,
];

const BoxImg = withStyles((theme) => ({
  root: {
    '& img': {
      height: spacingInPixels(theme, 3),
      width: spacingInPixels(theme, 3),
      marginRight: spacingInPixels(theme, -2),
    },
  },
}))(Box);

type BoxStatusNegociacaoProps = {
  codigoStatus: NegociacaoStatusType;
  isNegociacaoUrgente?: boolean;
};

const BoxStatusNegociacao = ({
  codigoStatus,
  isNegociacaoUrgente,
}: BoxStatusNegociacaoProps): JSX.Element => {
  const theme = useTheme();

  const statusNegociacao = StatusNegociacaoConstants.find(
    (status) => codigoStatus === status.codigo,
  );

  return statusNegociacao ? (
    <Box
      display="flex"
      alignItems="center"
      width="100%"
      justifyContent="center"
    >
      {statusNegociacao.componente}
      {isNegociacaoUrgente &&
        !verificarNegociacaoConcluida(statusNegociacao.codigo) && (
          <BoxImg
            display="flex"
            alignItems="center"
            justifyContent="center"
            minWidth={spacingInPixels(theme, 2)}
          >
            <Tooltip title="Negociação Urgente">
              <img
                src="/images/minhasNegociacoes/icone-urgente.svg"
                alt="Negociação Urgente"
              />
            </Tooltip>
          </BoxImg>
        )}
    </Box>
  ) : (
    <></>
  );
};

BoxStatusNegociacao.defaultProps = {
  isNegociacaoUrgente: false,
};

export default BoxStatusNegociacao;
