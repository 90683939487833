import { makeStyles, useTheme } from '@material-ui/core/styles';
import IconeInformacao from 'assets/icones/IconeInformacao';
import TextoValorSugeridoRobo from 'features/chatNegociacao/components/TextoValorSugeridoRobo';
import React from 'react';
import { useSelector } from 'react-redux';
import Bold from 'shared/components/typography/Bold';
import { Rubik } from 'shared/constants/font';
import { RootState } from 'store/reducer';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    padding: '32px 60px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10px',
    background: theme.palette.cores.amarelo[100],
    height: '88px',
    marginBottom: '10px',
  },
  textoInformativo: {
    font: Rubik,
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 400,
    color: theme.palette.cores.cinza[700],
    display: 'block',
    marginLeft: '8px',
  },
  info: {
    marginBottom: '30px',
    display: 'flex',
  },
}));

const SugestaoRoboCard = (): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();

  const { negociacao } = useSelector(
    ({ informacoesNegociacaoReducer }: RootState) =>
      informacoesNegociacaoReducer,
  );

  return (
    <>
      <div className={classes.container}>
        <TextoValorSugeridoRobo
          sugestaoValorInicialRobo={negociacao?.sugestaoValorInicialRobo || 0}
          tamanhoFonte={20}
          alturaIcone={24}
          larguraIcone={24}
        />
      </div>
      <div className={classes.info}>
        <IconeInformacao
          fill={theme.palette.cores.branco}
          stroke={theme.palette.cores.cinza[700]}
          width={16}
          height={16}
        />
        <span className={classes.textoInformativo}>
          <Bold fontSize="12px">Algoritmo </Bold>é a nossa inteligência
          artificial, pronta pra te ajudar
        </span>
      </div>
    </>
  );
};

export default SugestaoRoboCard;
