import React from 'react';

import { NegociacaoAndamento } from 'features/demandasAndamento/api/demandasAndamentoApiTypes';

import { makeStyles } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';
import HeaderTabelaDemandasAndamento from './HeaderTabelaDemandasAndamento';
import ConteudoTabelaDemandasAndamento from './ConteudoTabelaDemandasAndamento';

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: 'calc(100vh - 180px)',
    maxWidth: '2600px',
    overflowX: 'auto',
    overflowY: 'hidden',

    backgroundColor: theme.palette.cores.branco,
    borderRadius: spacingInPixels(theme, 2),
    border: `1px solid ${theme.palette.cores.cinza[300]}`,
    marginTop: spacingInPixels(theme, 2),

    '&::-webkit-scrollbar': {
      height: '8px',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.cores.azul[600],
      borderRadius: '8px',
    },
  },
}));

export interface ContainerTabelaDemandasAndamentoProps {
  demandas?: NegociacaoAndamento[];
  setDemandas: React.Dispatch<
    React.SetStateAction<NegociacaoAndamento[] | undefined>
  >;
  carregando: boolean;
}

const ContainerTabelaDemandasAndamento = ({
  demandas,
  setDemandas,
  carregando,
}: ContainerTabelaDemandasAndamentoProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <HeaderTabelaDemandasAndamento setDemandas={setDemandas} />
      <ConteudoTabelaDemandasAndamento
        demandas={demandas}
        carregando={carregando}
      />
    </div>
  );
};

export default ContainerTabelaDemandasAndamento;
