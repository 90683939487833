import { TableCell, makeStyles, withStyles } from '@material-ui/core';

export const TableCellSmall = withStyles({
  root: {
    fontSize: '14px',
    fontWeight: 500,
  },
})(TableCell);

export const useStyles = makeStyles((theme) => ({
  boxFlexCenter: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiButtonBase-root.Mui-disabled': {
      cursor: 'not-allowed',
      pointerEvents: 'auto',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  centerTyprographyTooltip: {
    width: 'fit-content',
    color: theme.palette.cores.azulMartins[700],
    fontSize: '13px',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  boxFilial: {
    width: '135px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginLeft: '5px',
  },
  customTextField: {
    '& input': {
      marginTop: '-5px',
    },
  },
}));
