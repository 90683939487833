import React from 'react';
import { estaEmBranco } from '../../../../../shared/functions/stringUtils';
import { ChatBalaoProps } from '../ChatBalao';
import TypographyChat from '../../TypographyChat';
import ValorContraProposta from '../ValorContraProposta';

const ContrapropostaNegociador = ({
  mensagem,
}: ChatBalaoProps): JSX.Element => (
  <>
    <TypographyChat>
      Sugiro{' '}
      <ValorContraProposta valorContraproposta={mensagem.valorContraproposta} />{' '}
      como contra proposta.
    </TypographyChat>
    {!estaEmBranco(mensagem.texto) && (
      <TypographyChat>
        <b>Motivo:</b> {mensagem.texto}
      </TypographyChat>
    )}
  </>
);

export default ContrapropostaNegociador;
