import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';

export type EstruturaPaginaProps = {
  isOpen: boolean;
  hover: boolean;
  children: JSX.Element;
};

const useStyles = makeStyles((theme) => ({
  expamdir: {
    marginLeft: ({ hover, isOpen }: { hover: boolean; isOpen: boolean }) =>
      hover || isOpen ? spacingInPixels(theme, 35) : 0,
    transition: 'width 0.2s ease',
  },
}));

const ExampansivoLateral = ({
  isOpen,
  hover,
  children,
}: EstruturaPaginaProps): JSX.Element => {
  const estilo = useStyles({ hover, isOpen });

  return <Box className={estilo.expamdir}>{children}</Box>;
};

export default ExampansivoLateral;
