import { TipoCorPorcentagem } from '../constants/TipoCorPorcentagem';
import { obterApenasNumeros } from './stringUtils';

export const obterPorcentagemInteira = (numero: number | undefined): string => {
  return obterApenasNumeros(numero?.toString() || '0');
};
export const obterPorcentagemDecimal = (numero: number | undefined): string => {
  const numeroString = numero?.toFixed(2) || '0';

  const parteInteira = numeroString.substr(0, numeroString.length - 2);
  const parteDecimal = numeroString.substring(numeroString.length - 2);
  return `${Number(parteInteira).toLocaleString('pt-br')},${parteDecimal}`;
};

export const converterPorcentagemInteiraParaNumero = (
  valorPorcentagem: string,
): number => {
  const porcentagemNumero = obterApenasNumeros(valorPorcentagem);
  return Number(porcentagemNumero);
};

export const converterPorcentagemDecimalParaNumero = (
  valorPorcentagem: string,
): number => {
  const porcentagemNumero = converterPorcentagemInteiraParaNumero(
    valorPorcentagem,
  );

  return porcentagemNumero / 100;
};

export const obterCorPorcentagem = (valor: number): TipoCorPorcentagem => {
  if (valor >= 0 && valor < 70) {
    return TipoCorPorcentagem.VERDE;
  }
  if (valor >= 70 && valor < 85) {
    return TipoCorPorcentagem.AMARELO;
  }
  if (valor >= 85) {
    return TipoCorPorcentagem.VERMELHO;
  }
  return TipoCorPorcentagem.CINZA;
};

export const calcularValorPelaPorcentagem = (
  porcentagem: number,
  valorTotal: number,
): number => Math.ceil((porcentagem / 100) * valorTotal);

export const calcularPorcentagemInteira = (
  numerador?: number,
  denominador?: number,
): number =>
  (numerador && denominador && Math.ceil((numerador / denominador) * 100)) || 0;

export const calcularPorcentagemSomenteParteInteira = (
  numerador?: number,
  denominador?: number,
): number => {
  const porcentagem =
    (numerador && denominador && (numerador / denominador) * 100) || 0;
  return parseFloat(porcentagem.toFixed(2));
};

export const calcularPorcentagemDecimal = (
  numerador: number,
  denominador: number,
): number => Number(((numerador / denominador) * 100).toFixed(2));

export const tratarValorPorcentagem = (valor?: number): string =>
  `${
    (valor !== undefined && valor !== null) || valor === 0
      ? valor.toLocaleString('pt-BR')
      : '--'
  }%`;
