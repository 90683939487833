import React from 'react';

import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Popover, { PopoverOrigin } from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { withStyles, useTheme } from '@material-ui/core';

import { spacingInPixels } from '../../../../functions/materialUtils';
import { DropdownCheckboxOption } from './DropdownCheckbox';
import DisablingTooltip from '../../../tooltip/DisablingTooltip';

export type PopoverDropdownProps = {
  open: boolean;
  titulo: string;
  anchorElement: HTMLButtonElement | null;
  anchorOrigin: PopoverOrigin;
  transformOrigin: PopoverOrigin;
  id?: string;
  opcoesSelecionadas: string[];
  opcoes: DropdownCheckboxOption[];
  onClose: () => void;
  onChange: (opcoesSelecionadas: string[]) => void;
};

const IconButtonLimpar = withStyles((theme) => ({
  root: {
    padding: spacingInPixels(theme, 1),
    marginLeft: spacingInPixels(theme, 2),
  },
}))(IconButton);

const PopoverDropdown = ({
  id,
  open,
  titulo,
  anchorElement,
  anchorOrigin,
  transformOrigin,
  opcoes,
  opcoesSelecionadas,
  onClose,
  onChange,
}: PopoverDropdownProps): JSX.Element => {
  const theme = useTheme();

  const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      onChange(opcoesSelecionadas.concat(event.target.name));
    } else {
      onChange(
        opcoesSelecionadas.filter((opcao) => opcao !== event.target.name),
      );
    }
  };

  const isChecked = (opcao: string): boolean =>
    opcoesSelecionadas.includes(opcao);

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorElement}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    >
      <Box padding={theme.spacing(1, 1, 2, 2)}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          marginBottom={spacingInPixels(theme, 1)}
        >
          <Typography
            color="textPrimary"
            style={{ fontSize: '14px', fontWeight: 600 }}
          >
            {titulo}
          </Typography>
          <DisablingTooltip title="Limpar seleção">
            <IconButtonLimpar
              color="primary"
              onClick={() => {
                if (opcoesSelecionadas.length > 0) {
                  onChange([]);
                }
              }}
            >
              <DeleteForeverRoundedIcon color="primary" />
            </IconButtonLimpar>
          </DisablingTooltip>
        </Box>
        <FormGroup>
          {opcoes.map((opcao) => (
            <FormControlLabel
              key={opcao.name}
              control={
                <Checkbox
                  color="primary"
                  onChange={onCheckboxChange}
                  name={opcao.name}
                  checked={isChecked(opcao.name)}
                />
              }
              label={
                <Typography
                  component="span"
                  style={{
                    fontSize: '14px',
                    fontWeight: 500,
                    color: isChecked(opcao.name)
                      ? theme.palette.primary.main
                      : theme.palette.text.primary,
                  }}
                >
                  {opcao.label}
                </Typography>
              }
            />
          ))}
        </FormGroup>
      </Box>
    </Popover>
  );
};

export default PopoverDropdown;
