import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import { Rubik } from '../../constants/font';

export default withStyles((theme) => ({
  root: {
    color: theme.palette.cores.vermelho[700],
    textTransform: 'none',
    fontFamily: Rubik,
    fontWeight: 500,
    fontSize: '14px',
  },
  outlined: {
    borderColor: theme.palette.cores.vermelho[700],
    backgroundColor: theme.palette.cores.branco,
    '&:hover': {
      backgroundColor: `${theme.palette.cores.vermelho[700]}20`,
    },
  },
}))(Button);
