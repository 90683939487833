import { Box, makeStyles, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import theme from 'theme';
import IconeAvatar from '../../../../assets/icones/IconeAvatar';
import spacingInPixels from '../../../../shared/functions/materialUtils';

export type InformacoesPersonaType = {
  nome: string;
  tipoVeiculo?: string;
  texto: string;
  corFundoContainer?: string;
};

interface InformacoesPersonaProps {
  corFundoContainer?: string;
}

const ContainerInformacoesPersona = withStyles(() => ({
  root: (props: InformacoesPersonaProps) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    backgroundColor: props.corFundoContainer
      ? props.corFundoContainer
      : theme.palette.cores.cinza[200],
    padding: spacingInPixels(theme, 1),
    borderRadius: spacingInPixels(theme, 2),
  }),
}))(Box);

const useStyles = makeStyles(() => ({
  textoNome: {
    fontSize: '14px',
    fontWeight: 500,
    marginLeft: 12,
    color: theme.palette.cores.cinza[900],
  },
  textoViagens: {
    fontSize: '14px',
    fontWeight: 400,
    marginLeft: 12,

    color: theme.palette.cores.cinza[800],
  },
  textoTipoVeiculo: {
    fontSize: '14px',
    fontWeight: 400,
    marginLeft: 12,
    textTransform: 'capitalize',
    color: theme.palette.cores.cinza[800],
  },
}));

const InformacoesPersona = ({
  nome,
  tipoVeiculo,
  texto,
  corFundoContainer = '',
}: InformacoesPersonaType): JSX.Element => {
  const classes = useStyles();
  return (
    <ContainerInformacoesPersona corFundoContainer={corFundoContainer}>
      <IconeAvatar fill={theme.palette.cores.cinza[900]} />
      <Box>
        <Typography className={classes.textoNome}>{nome}</Typography>
        <Typography className={classes.textoTipoVeiculo}>
          {tipoVeiculo?.toLowerCase()}
        </Typography>
        <Typography className={classes.textoViagens}>{texto}</Typography>
      </Box>
    </ContainerInformacoesPersona>
  );
};

InformacoesPersona.defaultProps = {
  corFundoContainer: '',
};

export default InformacoesPersona;
