import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import InputRubik from '../../../shared/components/input/InputRubik';
import EditarValorContrapropostaBadges from '../../../shared/components/modalComum/EditarValorContrapropostaBadges';
import ModalContraproposta from '../../../shared/components/modalComum/ModalComum';
import ModalContrapropostaAcoes from '../../../shared/components/modalComum/ModalComumAcoes';
import { RootState } from '../../../store/reducer';
import TipoValorContraproposta from '../constants/TipoValorContraproposta';
import {
  salvarInformacoesModalContraproposta,
  fazerContraPropostaOperation,
} from '../redux/chatNegociacaoOperation';
import { LIMITE_CARACTERES_TEXTO_LIVRE } from '../../../shared/constants/utilConstants';
import { ModalFazerContrapropostaPropTypes } from '../types/modalTypes';

const ModalFazerContraproposta = ({
  setModalContrapropostaAberto,
  modalContrapropostaAberto,
}: ModalFazerContrapropostaPropTypes): JSX.Element => {
  const dispatch = useDispatch();
  const { negociacao, idNegociacao, idPropostaComFoco } = useSelector(
    ({ informacoesNegociacaoReducer }: RootState) =>
      informacoesNegociacaoReducer,
  );
  const { informacoesModalContraproposta } = useSelector(
    ({ chatNegociacaoReducer }: RootState) => chatNegociacaoReducer,
  );

  const [justificativa, setJustificativa] = useState(
    informacoesModalContraproposta.justificativa,
  );
  const [tipoValor, setTipoValor] = useState<TipoValorContraproposta>(
    informacoesModalContraproposta.tipoValor,
  );
  const [valorProposto, setValorProposto] = useState<number | undefined>(
    informacoesModalContraproposta.valorProposto,
  );
  const [valorInput, setValorInput] = useState<number | undefined>(
    informacoesModalContraproposta.valorInput ||
      negociacao?.valorNovaMeta ||
      negociacao?.valorNegociacao ||
      undefined,
  );

  const fecharModal = () => {
    dispatch(
      salvarInformacoesModalContraproposta(
        justificativa,
        tipoValor,
        valorProposto,
        valorInput,
      ),
    );
    setModalContrapropostaAberto(false);
  };

  const confirmarContraProposta = () => {
    if (!idNegociacao || !idPropostaComFoco || !valorProposto || !justificativa)
      return;

    dispatch(
      fazerContraPropostaOperation(idNegociacao, idPropostaComFoco, {
        valorContraproposta: valorProposto,
        justificativa,
      }),
    );

    fecharModal();
  };

  useEffect(() => {
    setValorInput(
      informacoesModalContraproposta.valorInput ||
        negociacao?.valorNovaMeta ||
        negociacao?.valorNegociacao ||
        undefined,
    );
  }, [
    informacoesModalContraproposta.valorInput,
    negociacao?.valorNegociacao,
    negociacao?.valorNovaMeta,
  ]);

  return (
    <ModalContraproposta
      modalAberto={modalContrapropostaAberto}
      fecharModal={fecharModal}
      titulo="Fazer Contraproposta"
      conteudo={
        <Box>
          <Box>
            <EditarValorContrapropostaBadges
              tipoValor={tipoValor}
              setTipoValor={setTipoValor}
              valorProposto={valorProposto}
              setValorProposto={setValorProposto}
              valorInput={valorInput}
              setValorInput={setValorInput}
              valorFreteTerceiro={negociacao?.valorFreteTerceiro}
              valorFrotaPropria={
                negociacao?.sugestaoValorInicialRobo
                  ? negociacao?.sugestaoValorInicialRobo
                  : negociacao?.valorFrotaPropria
              }
              valorEntregas={negociacao?.carga.entregas}
              valorMercadoria={negociacao?.carga.valor}
              valorPeso={negociacao?.carga.peso}
              valorQuilometragem={negociacao?.carga.quilometragem}
            />
          </Box>
          <Box marginTop="32px">
            <InputRubik
              fullWidth
              multiline
              label="Justifique o valor"
              size="small"
              variant="outlined"
              value={justificativa}
              onChange={(e) => setJustificativa(e.target.value)}
              inputProps={{ maxLength: LIMITE_CARACTERES_TEXTO_LIVRE }}
              rows={4}
            />
          </Box>
        </Box>
      }
      acoes={
        <ModalContrapropostaAcoes
          textoBotaoPrimario="Enviar"
          callbackBotaoPrimario={confirmarContraProposta}
          textoBotaoSecundario="Cancelar"
          callbackBotaoSecundario={fecharModal}
          botaoPrimarioDesativado={
            !valorProposto || !justificativa.trim().length
          }
        />
      }
    />
  );
};

export default ModalFazerContraproposta;
