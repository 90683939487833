import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';

import { Negociacao } from '../api/minhasNegociacoesApiTypes';

import { obterListaMotoristaOrdenada } from '../utils/listaMotoristas';

import DropdownMotoristasEnvolvidos from './DropdownMotoristasEnvolvidos';
import AvatarMotoristaBadge from './AvatarMotoristaBadge';

import AvatarMotoristaNegociacaoConcluida from './AvatarMotoristaNegociacaoConcluida';
import { verificarNegociacaoConcluida } from '../../../shared/constants/NegociacaoStatus';

export type ListaMotoristasEnvolvidosProps = {
  negociacao: Negociacao;
};

const AvatarGroupMotoristas = withStyles((theme) => ({
  root: {
    width: 28,
    height: 28,
    fontSize: 15,
    marginLeft: 2,
    color: theme.palette.cores.cinza[900],
    backgroundColor: theme.palette.cores.cinza[300],
  },
}))(Avatar);

const ListaMotoristasEnvolvidos = ({
  negociacao,
}: ListaMotoristasEnvolvidosProps): JSX.Element => {
  const { motoristasEnvolvidos, status, id: idNegociacao } = negociacao;

  const motoristasOrdenados = obterListaMotoristaOrdenada(motoristasEnvolvidos);
  const negociacaoConcluida = verificarNegociacaoConcluida(status);
  const motoristasSet = new Set();
  const motoristaAtivo = motoristasOrdenados.filter((motorista) => {
    if (!motoristasSet.has(motorista.id) && motorista.usuarioApp === true) {
      motoristasSet.add(motorista.id);
      return true;
    }
    return false;
  });
  return (
    <>
      {negociacaoConcluida
        ? motoristaAtivo
            .slice(0, 3)
            .filter((motorista) => motorista.usuarioApp === true)
            .map((motorista) => (
              <AvatarMotoristaNegociacaoConcluida
                key={`motorista-envolvido-${motorista.id}`}
                motorista={motorista}
                idNegociacao={idNegociacao}
              />
            ))
        : motoristaAtivo
            .slice(0, 3)
            .filter((motorista) => motorista.usuarioApp === true)
            .map((motorista) => (
              <AvatarMotoristaBadge
                key={`motorista-envolvido-${motorista.id}`}
                motorista={motorista}
                idNegociacao={idNegociacao}
              />
            ))}
      {motoristaAtivo.length > 3 && (
        <Badge>
          <AvatarGroupMotoristas>
            <DropdownMotoristasEnvolvidos
              motoristas={motoristaAtivo.slice(3)}
              idNegociacao={idNegociacao}
              negociacaoConcluida={negociacaoConcluida}
            />
          </AvatarGroupMotoristas>
        </Badge>
      )}
    </>
  );
};

export default ListaMotoristasEnvolvidos;
