import { DadosOpcaoIndicador } from 'features/indicadores/constants/Constants';
import { Dispatch } from 'redux';
import {
  atualizarIndicadores,
  resetarIndicadores,
} from './evolucaoDeCargasAction';

export const atualizarIndicadoresSelecionados = (
  indicador1: DadosOpcaoIndicador,
  indicador2: DadosOpcaoIndicador,
) => (dispatch: Dispatch): void => {
  dispatch(
    atualizarIndicadores({
      indicador1,
      indicador2,
    }),
  );
};

export const resetarIndicadoresSelecionados = (dispatch: Dispatch): void => {
  dispatch(resetarIndicadores());
};
