import { makeStyles } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';

export const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.palette.cores.branco,
    padding: spacingInPixels(theme, 4),
    borderRadius: spacingInPixels(theme, 1),
    border: `1px solid ${theme.palette.cores.cinza['400']}80`,
  },
}));
