import { makeStyles } from '@material-ui/core';
import theme from 'theme';

export const useStyles = makeStyles(() => ({
  caixaIcone: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconeBackup: {
    color: theme.palette.cores.azulMartins[100],
    width: '100%',
    height: '100%',
  },
  iconeTask: {
    width: '100%',
    height: '100%',
  },
}));
