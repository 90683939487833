import { createAction } from '@reduxjs/toolkit';
import OrigemCarga from 'shared/constants/OrigemCarga';
import { Carga } from '../../../shared/types/apiTypes';

import { ItemFilial } from '../api/listaCargasApiTypes';

export const adicionarIdCarga = createAction<number>(
  'listaCargasAction/ADICIONAR_ID_CARGA',
);

export const removerIdCarga = createAction<number>(
  'listaCargasAction/REMOVER_ID_CARGA',
);

export const limparDados = createAction<void>('listaCargasAction/LIMPAR_DADOS');

export const atualizarDataInicio = createAction<Date | null>(
  'listaCargasAction/ATUALIZAR_DATA_INICIO',
);

export const atualizarDataFim = createAction<Date | null>(
  'listaCargasAction/ATUALIZAR_DATA_FIM',
);

export const atualizarPesquisa = createAction<string>(
  'listaCargasAction/ATUALIZAR_PESQUISA',
);

export const atualizarFiliaisSelecionadas = createAction<string[]>(
  'listaCargasAction/ATUALIZAR_FILIAIS_SELECIONADAS',
);

export const atualizarFiliais = createAction<ItemFilial[]>(
  'listaCargasAction/ATUALIZAR_FILIAIS',
);

export const atualizarCargas = createAction<{
  cargas: Carga[];
  origem: OrigemCarga;
}>('listaCargasAction/ATUALIZAR_CARGAS');

export const atualizarPagina = createAction<number>(
  'listaCargasAction/ATUALIZAR_PAGINA',
);

export const atualizarQuantidadePaginas = createAction<{
  totalPaginas: number;
  origem: OrigemCarga;
}>('listaCargasAction/ATUALIZAR_QUANTIDADE_PAGINAS');

export const atualizarQuantidadeCargas = createAction<{
  totalItens: number;
  origem: OrigemCarga;
}>('listaCargasAction/ATUALIZAR_QUANTIDADE_CARGAS');

export const atualizarOrigemCarga = createAction<number>(
  'listaCargasAction/ATUALIZAR_ORIGEM_CARGA',
);

export const atualizarOrdenacao = createAction<boolean>(
  'listaCargasAction/ATUALIZAR_ORDENACAO',
);
