import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import CancelRounded from '@material-ui/icons/CancelRounded';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core';

import { spacingInPixels } from '../../../../shared/functions/materialUtils';
import { Carga } from '../../../../shared/types/apiTypes';

import { removerIdCarga } from '../../../listaCargas/redux/listaCargasAction';
import { removerNovaNegociacao } from '../../redux/minhasCargasActions';
import DisablingTooltip from '../../../../shared/components/tooltip/DisablingTooltip';

const TextFieldObservacao = withStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-input': {
      padding: '0px 0px 0px 4px',
      fontSize: '14px',
      height: spacingInPixels(theme, 3),
    },
  },
}))(TextField);

const BotaoRemover = withStyles((theme) => ({
  root: {
    padding: 0,
    marginLeft: spacingInPixels(theme, 3),
  },
}))(IconButton);

type AccordionHeaderInputObservacaoProps = {
  setObservacao: React.Dispatch<React.SetStateAction<string>>;
  carga: Carga | null;
};

const AccordionHeaderInputObservacao = ({
  setObservacao,
  carga,
}: AccordionHeaderInputObservacaoProps): JSX.Element => {
  const dispatch = useDispatch();

  const onClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => {
    event.stopPropagation();
    if (carga) {
      dispatch(removerNovaNegociacao(carga.id));
      dispatch(removerIdCarga(carga.id));
    }
  };
  const [observacaoValue, setObservacaoValue] = useState(
    carga?.observacoes || '',
  );

  const handleChange = (event: { target: { value: string } }) => {
    const newValue = event.target.value.substr(0, 200);
    setObservacaoValue(newValue);
    setObservacao(newValue);
  };

  return (
    <Box display="flex" alignItems="center" height="100%" width="100%">
      <TextFieldObservacao
        fullWidth
        placeholder="Observação"
        variant="outlined"
        value={observacaoValue}
        onChange={handleChange}
        onClick={(event) => {
          event.stopPropagation();
        }}
        inputProps={{ maxLength: 200 }}
      />
      <DisablingTooltip title="Remover carga">
        <BotaoRemover onClick={onClick}>
          <CancelRounded />
        </BotaoRemover>
      </DisablingTooltip>
    </Box>
  );
};

export default AccordionHeaderInputObservacao;
