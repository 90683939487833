import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/reducer';

import { ChatBalaoProps } from '../ChatBalao';
import TypographyChat from '../../TypographyChat';
import ValorContraProposta from '../ValorContraProposta';
import { obterPrimeiroNomeMotorista } from '../../../utils/chatBalao';

const ReinicioNegociacaoNegociador = ({
  mensagem,
}: ChatBalaoProps): JSX.Element => {
  const { chatNomeMotorista } = useSelector(
    (state: RootState) => state.chatNegociacaoReducer,
  );

  return (
    <>
      <TypographyChat>
        Olá {obterPrimeiroNomeMotorista(chatNomeMotorista)}. Queria voltar a
        renegociar a carga. Valor da proposta{' '}
        <ValorContraProposta
          valorContraproposta={mensagem.valorContraproposta}
        />
        .
      </TypographyChat>
      <TypographyChat>
        Você tem interesse em renegociar conosco? Aguardo um retorno.
      </TypographyChat>
    </>
  );
};

export default ReinicioNegociacaoNegociador;
