import React, { useState } from 'react';
import Botao from '../../../../../shared/components/button/BotaoRubik';
import ModalFazerContraproposta from '../../../modals/ModalFazerContraproposta';

export type BotaoContrapropostaProps = {
  className?: string;
};

const BotaoContraproposta = ({
  className,
}: BotaoContrapropostaProps): JSX.Element => {
  const [modalContrapropostaAberto, setModalContrapropostaAberto] = useState(
    false,
  );

  return (
    <>
      <Botao
        className={className}
        variant="outlined"
        color="primary"
        onClick={() => setModalContrapropostaAberto(true)}
      >
        Fazer Contraproposta
      </Botao>
      {modalContrapropostaAberto && (
        <ModalFazerContraproposta
          modalContrapropostaAberto={modalContrapropostaAberto}
          setModalContrapropostaAberto={setModalContrapropostaAberto}
        />
      )}
    </>
  );
};

export default BotaoContraproposta;
