import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, makeStyles, Theme } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import Titulo from 'shared/components/titulo/Titulo';
import EmptyPage from 'shared/components/emptyPage/EmptyPage';
import { ModalPropor } from 'features/chatNegociacao/views/ModalPropor';
import { buscarDadosPermissaoUsuario } from 'features/login/redux/loginOperation';
import spacingInPixels from '../../shared/functions/materialUtils';
import { ModalConfirmacao } from '../chatNegociacao/views/ModalConfirmacao';

import CabecalhoSupervisor from './views/Cabecalho';
import Cabecalho from './views/aprovacaoNegociacao/Cabecalho';
import DetalhesProposta from './views/aprovacaoNegociacao/DetalhesProposta';
import DetalhesCarga from './views/DetalhesCarga';
import IndicadoresMotoristaRota from './views/indicadoresMotoristaRota/IndicadoresMotoristaRota';
import LinhaDoTempo from '../../shared/components/linhaDoTempo/LinhaDoTempo';
import MaisDetalhes from './views/MaisDetalhes';
import {
  aprovarPropostasSupervisor,
  obterDetalhesPropostaSupervisor,
  proporNovaMetaPropostasSupervisor,
  reprovarPropostasSupervisor,
} from './utils/detalhesAprovacaoSupervisor';
import { DetalhesAprovacaoSupervisorResponse } from './api/detalhesAprovacaoSupervisorTypes';
import { ModalReprovacao } from '../chatNegociacao/views/ModalReprovacao';

const useStyles = makeStyles((theme: Theme) => ({
  containerTimelineIndicadores: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '4fr 6fr',
    gridTemplateRows: 'min-content',
    justifyContent: 'space-between',
    gridGap: spacingInPixels(theme, 2),
  },
  containerCircularProgress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: spacingInPixels(theme, 15),
  },
}));

interface RouteParams {
  idProposta: string;
  idNegociacao: string;
}

const DetalhesAprovacaoNegociacaoSupervisor = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<RouteParams>();
  const idProposta = Number(params.idProposta);
  const idNegociacao = Number(params.idNegociacao);

  const [modalPropor, setModalPropor] = useState(false);
  const [modalReprovar, setModalReprovar] = useState(false);
  const [justificativa, setJustificativa] = useState('');

  const [modalConfirmar, setModalConfirmar] = useState(false);
  const [modalConfirmType, setModalConfirmType] = useState<
    'default' | 'success' | 'error' | 'successGreguim'
  >('default');

  const [detalhesPropostaResponse, setDetalhesPropostaResponse] = useState<
    DetalhesAprovacaoSupervisorResponse | undefined
  >();
  const [valorNovaMeta, setValorNovaMeta] = useState<number | undefined>(
    undefined,
  );
  const [carregando, setCarregando] = useState<boolean>(true);

  useEffect(() => {
    buscarDadosPermissaoUsuario()(dispatch);
  }, [dispatch]);

  useEffect(() => {
    const ObterDetalhesProposta = async () => {
      const detalhes = await obterDetalhesPropostaSupervisor(
        idNegociacao,
        idProposta,
        dispatch,
      );
      setDetalhesPropostaResponse(detalhes);

      setCarregando(false);
    };

    ObterDetalhesProposta();
  }, [idNegociacao, idProposta, dispatch, history]);

  function handleOpenModalConfirmar() {
    setModalConfirmType('success');
    setValorNovaMeta(detalhesPropostaResponse?.proposta?.valorProposta || 0);
    setModalConfirmar(true);
  }

  function handleOpenModalConfirmarGreguim() {
    setModalConfirmType('successGreguim');
    setValorNovaMeta(detalhesPropostaResponse?.proposta?.valorProposta || 0);
    setModalConfirmar(true);
  }

  async function handleConfirmModalConfirmar() {
    const navegarPaginaAprovacoesSupervisor = () => history.goBack();

    switch (modalConfirmType) {
      case 'default':
        await proporNovaMetaPropostasSupervisor(
          idNegociacao,
          idProposta,
          {
            justificativa,
            valorNovaMeta: valorNovaMeta || 0,
          },
          dispatch,
          navegarPaginaAprovacoesSupervisor,
        );
        break;
      case 'successGreguim':
        await aprovarPropostasSupervisor(
          idNegociacao,
          idProposta,
          dispatch,
          navegarPaginaAprovacoesSupervisor,
        );
        break;
      case 'success':
        await aprovarPropostasSupervisor(
          idNegociacao,
          idProposta,
          dispatch,
          navegarPaginaAprovacoesSupervisor,
        );
        break;
      case 'error':
        await reprovarPropostasSupervisor(
          idNegociacao,
          idProposta,
          {
            justificativa,
          },
          dispatch,
          navegarPaginaAprovacoesSupervisor,
        );
        break;

      default:
        break;
    }

    setModalConfirmar(false);
  }

  function handleOpenModalPropor() {
    setModalPropor(true);
  }

  function handleOpenModalReprovar() {
    setModalReprovar(true);
  }

  function handleCloseModalReprovar() {
    setJustificativa('');
    setModalReprovar(false);
  }

  async function handleConfirmModalReprovar() {
    setValorNovaMeta(detalhesPropostaResponse?.proposta.valorProposta || 0);
    setModalReprovar(false);
    setModalConfirmType('error');
    setModalConfirmar(true);
  }

  function handleCloseModalPropor() {
    setModalPropor(false);
  }

  async function handleConfirmModalPropor() {
    setValorNovaMeta(valorNovaMeta);
    setModalPropor(false);
    setModalConfirmType('default');

    setModalConfirmar(true);
  }

  const permitirConfirmacao = useMemo(() => !!valorNovaMeta, [valorNovaMeta]);

  return (
    <Box>
      {!detalhesPropostaResponse && !carregando ? (
        <>
          <Box minWidth="300px">
            <Titulo
              titulo="Detalhes do pedido"
              possuiBotaoVoltar
              voltar={() => history.goBack()}
            />
          </Box>
          <EmptyPage
            texto="Ops algo aconteceu, por algum motivo não conseguimos carregar as informações."
            corFundo="transparent"
            iconePreenchido={false}
          />
        </>
      ) : (
        <>
          {detalhesPropostaResponse?.proposta.valorProposta &&
          detalhesPropostaResponse?.negociacao.valorInicialSugeridoRobo !==
            undefined &&
          detalhesPropostaResponse?.proposta.valorProposta ===
            detalhesPropostaResponse?.negociacao.valorInicialSugeridoRobo ? (
            <Cabecalho
              onOpenConfirmar={handleOpenModalConfirmarGreguim}
              valorProposta={detalhesPropostaResponse?.proposta?.valorProposta}
              carregando={carregando}
            />
          ) : (
            <CabecalhoSupervisor
              onOpenConfirmar={handleOpenModalConfirmar}
              onOpenPropor={handleOpenModalPropor}
              onOpenReprovar={handleOpenModalReprovar}
              valorProposta={detalhesPropostaResponse?.proposta?.valorProposta}
              carregando={carregando}
            />
          )}

          <DetalhesCarga
            carga={detalhesPropostaResponse?.carga}
            carregando={carregando}
          />
          <DetalhesProposta
            carga={detalhesPropostaResponse?.carga}
            proposta={detalhesPropostaResponse?.proposta}
            negociacao={detalhesPropostaResponse?.negociacao}
            rota={detalhesPropostaResponse?.rota}
            carregando={carregando}
          />
          <MaisDetalhes
            proposta={detalhesPropostaResponse?.proposta}
            negociacao={detalhesPropostaResponse?.negociacao}
            carga={detalhesPropostaResponse?.carga}
            filial={detalhesPropostaResponse?.filial}
            carregando={carregando}
          />
          <section className={classes.containerTimelineIndicadores}>
            <LinhaDoTempo
              historico={detalhesPropostaResponse?.proposta?.historico}
              valorFrotaPropria={
                detalhesPropostaResponse?.negociacao?.valorFrotaPropria || 0
              }
              carregando={carregando}
            />

            <IndicadoresMotoristaRota
              carga={detalhesPropostaResponse?.carga}
              rota={detalhesPropostaResponse?.rota}
              motorista={detalhesPropostaResponse?.motorista}
              proposta={detalhesPropostaResponse?.proposta}
              carregando={carregando}
              valorSugeridoRobo
            />
          </section>
        </>
      )}
      {detalhesPropostaResponse && (
        <ModalPropor
          modalOpen={modalPropor}
          onCloseModal={handleCloseModalPropor}
          onConfirmModal={handleConfirmModalPropor}
          justificativa={justificativa}
          setJustificativa={setJustificativa}
          valorNovaMeta={valorNovaMeta}
          setValorNovaMeta={setValorNovaMeta}
          carga={detalhesPropostaResponse.carga}
          negociacao={detalhesPropostaResponse.negociacao}
          filial={detalhesPropostaResponse.filial}
          habilitarConfirmacao={permitirConfirmacao}
        />
      )}
      <ModalConfirmacao
        modalOpen={modalConfirmar}
        onCloseModal={() => setModalConfirmar(false)}
        onConfirmModal={handleConfirmModalConfirmar}
        type={modalConfirmType}
        valorApresentar={valorNovaMeta || 0}
      />
      <ModalReprovacao
        modalOpen={modalReprovar}
        onCloseModal={handleCloseModalReprovar}
        onConfirmModal={handleConfirmModalReprovar}
        justificativa={justificativa}
        setJustificativa={setJustificativa}
      />
    </Box>
  );
};

export default DetalhesAprovacaoNegociacaoSupervisor;
