import { Box, Divider } from '@material-ui/core';
import React from 'react';
import CustomSkeleton from 'shared/components/skeleton/CustomSkeleton';
import useStylesDadosMotoristaRota from '../../styles/dadosMotoristaRota';

const SkeletonMotoristaRota = (): JSX.Element => {
  const classes = useStylesDadosMotoristaRota();

  return (
    <Box className={classes.container}>
      <CustomSkeleton
        width="100%"
        height={100}
        borderradius={16}
        key="skeleton-box-esquerda"
      />
      <Divider orientation="vertical" />
      <CustomSkeleton
        width="100%"
        height={100}
        borderradius={16}
        key="skeleton-box-direita"
      />
    </Box>
  );
};

export default SkeletonMotoristaRota;
