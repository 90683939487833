import React from 'react';
import { formatarNumeroParaStringMonetariaComSimbolo } from 'shared/functions/dinheiroUtils';

export type ValorPropostaProps = {
  valorContraproposta?: number;
};

const ValorContraProposta = ({
  valorContraproposta,
}: ValorPropostaProps): JSX.Element => {
  const valorFormatado = formatarNumeroParaStringMonetariaComSimbolo(
    valorContraproposta,
  );

  return (
    <>
      <span style={{ fontWeight: 700 }}>{valorFormatado}</span>
    </>
  );
};

export default ValorContraProposta;
