import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  List,
  ListItem,
  ListItemIcon,
  Typography,
  Box,
} from '@material-ui/core';

import { useSelector } from 'react-redux';

import { obterItemsMenuAcesso, PaginaItemMenu } from './PaginasPortal';
import { RootState } from '../../../store/reducer';
import { useStyles } from './styles/MenuItems';

type MenuItemsProps = {
  aberto: boolean;
};

const MenuItems = ({ aberto: isOpen }: MenuItemsProps): JSX.Element => {
  const { dados } = useSelector((state: RootState) => state.loginReducer);

  const classes = useStyles();
  const location = useLocation();

  const isItemAtivo = (item: PaginaItemMenu) => {
    return location.pathname.includes(item.link);
  };

  const getEstilosTexto = (item: PaginaItemMenu) => {
    return !isItemAtivo(item) && classes.textoInativo;
  };

  const getEstilosRoot = (item: PaginaItemMenu) => {
    return (
      classes.root +
      (isItemAtivo(item) && isOpen ? ` ${classes.rootAtivo}` : '')
    );
  };

  const getEstilosIcone = (item: PaginaItemMenu) => {
    return classes.icone + (isItemAtivo(item) ? ` ${classes.iconeAtivo}` : '');
  };

  const listMenuItems = obterItemsMenuAcesso(
    dados?.usuario.paginasAcesso || [],
  );

  return (
    <List>
      {listMenuItems.map((item) => (
        <Link key={item.texto} to={item.link} className={classes.link}>
          <ListItem button className={getEstilosRoot(item)}>
            <ListItemIcon className={classes.iconeContainer}>
              <Box className={getEstilosIcone(item)}>{item.iconeMenu}</Box>
            </ListItemIcon>
            <Typography className={`${classes.text} ${getEstilosTexto(item)}`}>
              {item.texto}
            </Typography>
          </ListItem>
        </Link>
      ))}
    </List>
  );
};

export default MenuItems;
