import React, { ReactNode } from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';
import IconeQuadradoArredondado from 'assets/icones/IconeQuadradoArredondado';
import CustomTooltip from 'shared/components/tooltip/CustomTooltip';
import { TipoValorIndicador } from 'features/indicadores/constants/Constants';
import { formatarDadoExibicaoDeAcordoComTipo } from 'features/indicadores/utils/evolucaoDeCargas';

interface TabelaCssProps {
  numeroColunas: number;
}

export const useStyles = makeStyles<Theme, TabelaCssProps>((theme) => ({
  container: {
    '& table': {
      width: '100%',
    },
  },
  gridTabela: {
    display: 'grid',
    gridTemplateColumns: ({ numeroColunas }) =>
      `2.5% repeat(${numeroColunas}, ${98 / numeroColunas}%)`,
    textAlign: 'left',
  },
  texto: {
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: spacingInPixels(theme, 1.75),
    lineHeight: spacingInPixels(theme, 1.75),
    letterSpacing: '-0.2px',
    color: theme.palette.cores.cinza[600],
    width: '90%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

interface DadosTabelaEvolucaoDeCargasProps {
  icone: ReactNode;
  valoresIndicador: number[];
  numeroColunas: number;
  keyIndicador: string;
  tipoValor: TipoValorIndicador;
}

const DadosTabelaEvolucaoDeCargas = ({
  icone,
  valoresIndicador,
  numeroColunas,
  keyIndicador,
  tipoValor,
}: DadosTabelaEvolucaoDeCargasProps): JSX.Element => {
  const classes = useStyles({ numeroColunas });

  return (
    <tr className={classes.gridTabela}>
      <td>{icone}</td>
      {valoresIndicador?.map((valor, index) => (
        <CustomTooltip
          key={`${keyIndicador}-${valor}-coluna-${index + 1}`}
          title={formatarDadoExibicaoDeAcordoComTipo(tipoValor, valor)}
          placement="top"
          arrow
        >
          <td className={classes.texto}>
            {formatarDadoExibicaoDeAcordoComTipo(tipoValor, valor, true)}
          </td>
        </CustomTooltip>
      ))}
    </tr>
  );
};

interface TabelaEvolucaoDeCargasProps {
  valoresPeriodo: string[];
  valoresIndicador1: number[];
  valoresIndicador2: number[];
  tipoIndicador1: TipoValorIndicador;
  tipoIndicador2: TipoValorIndicador;
}

const TabelaEvolucaoDeCargas = ({
  valoresPeriodo,
  valoresIndicador1,
  valoresIndicador2,
  tipoIndicador1,
  tipoIndicador2,
}: TabelaEvolucaoDeCargasProps): JSX.Element => {
  const numeroColunas = valoresPeriodo.length;
  const classes = useStyles({ numeroColunas });
  const theme = useTheme();

  return (
    <section className={classes.container}>
      <table>
        <thead>
          <tr className={classes.gridTabela}>
            <th key="cabecalho-legenda"> </th>
            {valoresPeriodo.map((valor) => (
              <CustomTooltip
                key={`cabecalho-${valor}`}
                title={valor}
                placement="top"
                arrow
              >
                <th className={classes.texto}>{valor}</th>
              </CustomTooltip>
            ))}
          </tr>
        </thead>
        <tbody>
          <DadosTabelaEvolucaoDeCargas
            icone={<IconeQuadradoArredondado />}
            numeroColunas={numeroColunas}
            valoresIndicador={valoresIndicador1}
            keyIndicador="indicador1"
            tipoValor={tipoIndicador1}
          />
          <DadosTabelaEvolucaoDeCargas
            icone={
              <IconeQuadradoArredondado
                fill={theme.palette.cores.laranja[900]}
              />
            }
            numeroColunas={numeroColunas}
            valoresIndicador={valoresIndicador2}
            keyIndicador="indicador2"
            tipoValor={tipoIndicador2}
          />
        </tbody>
      </table>
    </section>
  );
};

export default TabelaEvolucaoDeCargas;
