import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  boxCampoTextoLivre: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  iconeBotaoEnviar: {
    padding: '12px 17.5px',
    minWidth: 'unset',
    marginLeft: 18,
  },
}));

export default useStyles;
