import * as React from 'react';
import { Box, makeStyles, SwipeableDrawer } from '@material-ui/core';
import LinhaDoTempo from 'shared/components/linhaDoTempo/LinhaDoTempo';
import { EventoProposta } from 'features/detalhesAprovacaoSupervisor/api/detalhesAprovacaoSupervisorTypes';
import { useSelector } from 'react-redux';
import IconeTimePast from 'assets/icones/IconeTimePast';
import spacingInPixels from 'shared/functions/materialUtils';
import BotaoIcone from 'shared/components/button/BotaoIcone';
import theme from 'theme';
import { RootState } from 'store/reducer';
import {
  filtrarMensagensHistorico,
  mapearMensagemEventoProposta,
} from 'features/chatNegociacao/utils/drawerLinhaDoTempoChatUtils';
import useStyles from '../../styles/chatRodape';

type Anchor = 'left';

const estiloContainer = makeStyles(() => ({
  containerEstilo: {
    width: '438px',
    maxHeight: '100vh',
  },
  texto: {
    fontSize: '20px',
    fontWeight: 700,
    color: theme.palette.cores.azulMartins[700],
    padding: spacingInPixels(theme, 2),
    marginLeft: 12,
    lineHeight: '24px',
  },
}));

export const DrawerLinhaDoTempoChat = (): JSX.Element => {
  const classes = useStyles();

  const { chatMensagens, chatValorFrotaPropria } = useSelector(
    (state: RootState) => state.chatNegociacaoReducer,
  );

  const [historicoMensagens, setHistoricoMensagens] = React.useState<
    EventoProposta[]
  >([]);

  const [estadoDrawer, setEstadoDrawer] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor: Anchor, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    if (open) {
      const msg = filtrarMensagensHistorico(chatMensagens);
      const eventosProposta = mapearMensagemEventoProposta(msg);
      setHistoricoMensagens(eventosProposta);
    }

    setEstadoDrawer({ ...estadoDrawer, [anchor]: open });
  };

  return (
    <div>
      {(['left'] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <BotaoIcone
            descricao="Histórico"
            classe={classes.iconeMaisAcoesChat}
            onClick={toggleDrawer(anchor, true)}
          >
            <IconeTimePast fill={theme.palette.cores.cinza[600]} />
          </BotaoIcone>
          <Box>
            <SwipeableDrawer
              anchor={anchor}
              open={estadoDrawer[anchor]}
              onClose={toggleDrawer(anchor, false)}
              onOpen={toggleDrawer(anchor, true)}
            >
              <LinhaDoTempo
                historico={historicoMensagens}
                valorFrotaPropria={chatValorFrotaPropria || 1}
                estilo={estiloContainer()}
              />
            </SwipeableDrawer>
          </Box>
        </React.Fragment>
      ))}
    </div>
  );
};
