const CAMPO_OBRIGATORIO = 'Campo obrigatório';
const CAMPO_INVALIDO = 'Campo inválido';
const DADOS_SALVOS_SUCESSO = 'Os dados foram salvos com sucesso.';
const DADOS_EXCLUIDOS_SUCESSO = 'Os dados foram excluídos com sucesso.';
const FORMATO_VALOR_INVALIDO = 'Formato do valor inválido.';
const DATA_INVALIDA = 'Data inválida';
const DATA_INICIAL_SUPERIOR_DATA_FINAL =
  'Data inicial não deve ser posterior a data final';
const DATA_FINAL_INFERIOR_DATA_INICIAL =
  'Data final não deve ser anterior a data inicial';
const PESQUISA_NAO_RETORNOU_RESULTADOS = 'Pesquisa não retornou resultados.';
const FECHAR_MENSAGEM_E_TENTAR_NOVAMENTE = 'Fechar mensagem e tentar novamente';
const LISTA_MOTORISTAS_VAZIA = 'Não há motoristas disponíveis para negociação.';
const CADASTRAR_MOTORISTAS =
  'É necessário cadastrar motoristas para esta região.';
const LISTA_NEGOCIACOES_VAZIA = 'Você ainda não iniciou uma negociação.';
const ENVIE_PROPOSTA =
  'Envie uma proposta na lista de disponíveis para negociar.';

export default {
  CAMPO_OBRIGATORIO,
  CAMPO_INVALIDO,
  DADOS_SALVOS_SUCESSO,
  DADOS_EXCLUIDOS_SUCESSO,
  FORMATO_VALOR_INVALIDO,
  DATA_INVALIDA,
  DATA_INICIAL_SUPERIOR_DATA_FINAL,
  DATA_FINAL_INFERIOR_DATA_INICIAL,
  PESQUISA_NAO_RETORNOU_RESULTADOS,
  FECHAR_MENSAGEM_E_TENTAR_NOVAMENTE,
  LISTA_MOTORISTAS_VAZIA,
  CADASTRAR_MOTORISTAS,
  LISTA_NEGOCIACOES_VAZIA,
  ENVIE_PROPOSTA,
};
