import React from 'react';

import { ChatBalaoProps } from '../ChatBalao';
import TypographyChat from '../../TypographyChat';

const TextoLivre = ({ mensagem }: ChatBalaoProps): JSX.Element => {
  return <TypographyChat>{mensagem.texto}</TypographyChat>;
};

export default TextoLivre;
