import { Box } from '@material-ui/core';
import React from 'react';
import CustomSkeleton from 'shared/components/skeleton/CustomSkeleton';
import IconeArrowCircleDown from '../../../../assets/icones/IconeArrowCircleDown';
import IconeArrowCircleUp from '../../../../assets/icones/IconeArrowCircleUp';
import theme from '../../../../theme';
import {
  Proposta,
  Motorista,
  Rota,
} from '../../api/detalhesAprovacaoSupervisorTypes';
import { TypographyPorcentagem, TypographyPorcentagemFlex } from './CorTexto';
import { BoxCardColum, TypographyTextoCentral } from './styles';

export type CardMediaRotaProps = {
  proposta?: Proposta;
  motorista?: Motorista;
  rota?: Rota;
  carregando: boolean;
};

const CardMediaRota = ({
  proposta,
  motorista,
  rota,
  carregando,
}: CardMediaRotaProps): JSX.Element => {
  const CalcularPorcentagemMotorista =
    motorista?.indicadoresMotorista[0]?.valorMedio && proposta
      ? (proposta?.valorProposta /
          motorista.indicadoresMotorista[0].valorMedio -
          1) *
        100
      : 0;

  const CalcularPorcentagemRota =
    rota?.indicadoresRota[0]?.valorMedio && proposta
      ? (proposta?.valorProposta / rota.indicadoresRota[0].valorMedio - 1) * 100
      : 0;

  const CorTextoPorcentagem = (porcentagem: number): string =>
    porcentagem >= 0
      ? `${theme.palette.cores.vermelho[400]}`
      : `${theme.palette.cores.verde[400]}`;

  const handleIconeUpOuDown = (valorReferencia: number) =>
    valorReferencia >= 0 ? (
      <IconeArrowCircleUp fill={CorTextoPorcentagem(valorReferencia)} />
    ) : (
      <IconeArrowCircleDown fill={CorTextoPorcentagem(valorReferencia)} />
    );

  const handleTextoCimaOuBaixo = (valorReferencia: number) =>
    valorReferencia >= 0 ? ' acima' : ' abaixo';

  return (
    <BoxCardColum>
      <Box flexDirection="column" display="flex" width="100%">
        {carregando ? (
          <CustomSkeleton
            width="100%"
            height={32}
            borderradius={16}
            key="skeleton-media-rota"
            style={{ marginBottom: '4px' }}
          />
        ) : (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="start"
            alignItems="center"
          >
            <Box padding="3px ">
              {handleIconeUpOuDown(CalcularPorcentagemRota)}
            </Box>
            <TypographyPorcentagem
              paddingCorTexto="5px 2px"
              corTexto={CorTextoPorcentagem(CalcularPorcentagemRota)}
            >
              {Math.abs(CalcularPorcentagemRota).toLocaleString('pt-BR', {
                maximumFractionDigits: 1,
              })}
              %{handleTextoCimaOuBaixo(CalcularPorcentagemRota)}
            </TypographyPorcentagem>
            <TypographyTextoCentral> da média da rota</TypographyTextoCentral>
          </Box>
        )}
        {carregando ? (
          <CustomSkeleton
            width="100%"
            height={32}
            borderradius={16}
            key="skeleton-media-motorista-rota"
          />
        ) : (
          <Box display="flex" flexDirection="row" justifyContent="start">
            <Box padding="3px ">{}</Box>

            <TypographyTextoCentral>
              <TypographyPorcentagemFlex
                paddingCorTexto="0px 2px 0px 0px"
                corTexto={CorTextoPorcentagem(CalcularPorcentagemMotorista)}
              >
                {Math.abs(CalcularPorcentagemMotorista).toLocaleString(
                  'pt-BR',
                  {
                    maximumFractionDigits: 1,
                  },
                )}
                %{handleTextoCimaOuBaixo(CalcularPorcentagemMotorista)}
              </TypographyPorcentagemFlex>
              {` `}da média do motorista nesta rota
            </TypographyTextoCentral>
          </Box>
        )}
      </Box>
    </BoxCardColum>
  );
};

export default CardMediaRota;
