import React, { useState } from 'react';
import ItemCabecalho from 'shared/components/tabelaDemandas/ItemCabecalho';
import ItemCabecalhoComOrdenacao from 'shared/components/tabelaDemandas/ItemCabecalhoComOrdenacao';
import { ChaveOrdenacaoDetalhesDemandas } from 'features/detalhesDemandasDiaria/constants/Constants';
import { CabecalhoTabelaDetalhesDemandasProps } from 'features/detalhesDemandasDiaria/types/tabelaTypes';

const CabecalhoTabelaDetalhesDemandas = ({
  estiloCabecalho,
  dadosItensCabecalho,
  onClickOrdenacao,
}: CabecalhoTabelaDetalhesDemandasProps): JSX.Element => {
  const [ordenacaoAtual, setOrdenacaoAtual] = useState<
    ChaveOrdenacaoDetalhesDemandas | undefined
  >(undefined);

  const handleClickOrdenacao = (chave: string, ordenacaoCrescente: boolean) => {
    onClickOrdenacao(chave, ordenacaoCrescente);
    setOrdenacaoAtual(chave as ChaveOrdenacaoDetalhesDemandas);
  };

  return (
    <tr className={estiloCabecalho}>
      {dadosItensCabecalho.map((item, index) => {
        const key = `cabecalho-tabela-${index + 1}`;
        return item.possuiOrdenacao ? (
          <ItemCabecalhoComOrdenacao
            item={item}
            onClickOrdenacao={handleClickOrdenacao}
            chaveOrdenacaoAtual={ordenacaoAtual}
            key={key}
          />
        ) : (
          <ItemCabecalho dadosCabecalho={item} key={key} />
        );
      })}
    </tr>
  );
};

export default CabecalhoTabelaDetalhesDemandas;
