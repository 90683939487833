enum OrigemCarga {
  Prod,
  Planilha,
  Planejador,
}

export type OrigemCargaType = 0 | 1 | 2;

export const verificarCargaPlanilha = (
  origem: OrigemCargaType | undefined,
): boolean => OrigemCarga.Planilha === origem;

export const verificarCargaProd = (origem?: OrigemCargaType): boolean =>
  OrigemCarga.Prod === origem;

export const verificarCargaPlanejador = (origem?: OrigemCargaType): boolean =>
  OrigemCarga.Planejador === origem;

export default OrigemCarga;
