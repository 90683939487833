import { PropostaStatusType } from 'shared/constants/PropostaStatus';
import { NegociacaoStatusType } from 'shared/constants/NegociacaoStatus';
import { OrigemCargaType } from 'shared/constants/OrigemCarga';

export enum OrigemNegociacao {
  negociador = 'negociador',
  freteTabelado = 'freteTabelado',
}

export type NegociacaoResponse = {
  negociacao: Negociacao;
};

export type MotoristaNegociacao = {
  id: number;
  nome: string;
  contato: string;
  veiculo: string;
  capacidadeVeiculo: number;
  usuarioApp: boolean;
};

export type PropostaNegociacao = {
  id: number;
  motorista: string;
  idMotorista: number;
  tipoVeiculo: string;
  propostaAtual: number;
  status: PropostaStatusType;
  contrapropostaMotorista?: number;
  contrapropostaNegociador?: number;
  contato: string;
  usuarioApp: boolean;
  listaClientesLiberada: boolean;
  motivoRecusa?: number;
  dataUltimaMensagemProposta?: string;
  novaMetaValorProposta?: number;
};

export type MotoristasResponse = {
  motoristas: MotoristaNegociacao[];
};

export type PropostasResponse = {
  propostas: PropostaNegociacao[];
};

export type Negociacao = {
  status: NegociacaoStatusType;
  urgente: boolean;
  observacoes?: string;
  dataCarregamento: string;
  dataEntregaFinal: string;
  carga: Carga;
  valorFrotaPropria?: number;
  valorFreteTerceiro?: number;
  valorNegociacao?: number;
  valorNovaMeta?: number;
  sugestaoValorInicialRobo?: number;
  origemNegociacao?: OrigemNegociacao;
  idMotoristaIndicado?: number;
  nomeMotoristaIndicado?: string;
};

export type Carga = {
  id: number;
  codigo: string;
  romaneio: string;
  filial: string;
  cidade: string;
  macro: string;
  veiculo: string;
  peso: number;
  volume: number;
  quilometragem: number;
  entregas: number;
  entregasPF: number;
  entregasPJ: number;
  valor: number;
  prazo: string;
  dataSolicitacao: string;
  numeroCidadesEntrega: number;
  origem?: OrigemCargaType;
  tempoParaExpirarNegociacoesEmMinutos?: number;
  isTempoExpirado?: boolean;
  idFilialDestino?: number | undefined;
};

export type ObservacaoNegociacaoRequest = {
  observacoes: string | undefined;
};

export type ValoresRequest = Pick<
  Negociacao,
  'valorNegociacao' | 'valorFrotaPropria' | 'valorFreteTerceiro'
>;

export type AtualizarNegociacaoRequest = {
  negociacaoId?: number;
  motivoRecusa?: number;
  status?: NegociacaoStatusType;
  isNegociacaoCanceladaManualmenteTms?: boolean;
};

export type AtualizarPropostaRequest = {
  motivoRecusa?: number;
  status?: PropostaStatusType;
  propostaNegociador?: number;
  propostaMotorista?: number;
  listaClientesLiberada?: boolean;
};

export type CriarPropostaRequest = {
  idMotorista: number;
  valorGreguimAlterado: number | null;
};
