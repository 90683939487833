import React, { useEffect, useMemo, useState } from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { DemandaColaborador } from './api/listagemNegociadoresApiTypes';
import {
  buscarDadosNegociadores,
  criarPollingDadosNegociadores,
} from '../utils/painelNegociadores';
import ConteudoTabelaNegociadores from './ConteudoTabelaNegociadores';
import SkeletonItemTabelaNegociadores from './SkeletonItemTabelaNegociadores';
import CabecalhoTabelaNegociadores from './CabecalhoTabelaNegociadores';

interface ContainerTabelaNegociadoresProps {
  listaDemandaColaborador: DemandaColaborador[] | undefined;
  setListaDemandaColaborador: React.Dispatch<
    React.SetStateAction<DemandaColaborador[] | undefined>
  >;
}

const useStyles = makeStyles((theme) => ({
  tabelaEstilo: {
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.cores.azul[600],
      borderRadius: '8px',
    },
  },
}));

const ContainerTabelaNegociadores = ({
  listaDemandaColaborador,
  setListaDemandaColaborador,
}: ContainerTabelaNegociadoresProps): JSX.Element => {
  const [carregando, setCarregando] = useState(!listaDemandaColaborador);
  const [referenciaRefresh, setReferenciaRefresh] = useState<number>();
  const estilo = useStyles();

  const itensCabecalho = useMemo(
    () => [
      `Negociador (${listaDemandaColaborador?.length || 0})`,
      'Prioridade de carga',
      'Status de demanda',
      'Negociações p/ hora',
      'Alerta de demandas',
    ],
    [listaDemandaColaborador?.length],
  );

  useEffect(() => {
    if (!listaDemandaColaborador)
      buscarDadosNegociadores(setListaDemandaColaborador, setCarregando);

    setReferenciaRefresh(
      criarPollingDadosNegociadores(setListaDemandaColaborador, setCarregando),
    );
  }, [setListaDemandaColaborador, listaDemandaColaborador]);

  useEffect(() => () => clearInterval(referenciaRefresh), [referenciaRefresh]);

  return (
    <Box className={estilo.tabelaEstilo}>
      <CabecalhoTabelaNegociadores
        listaDemandaColaborador={listaDemandaColaborador}
        setListaDemandaColaborador={setListaDemandaColaborador}
        dadosItensCabecalho={itensCabecalho}
      />

      {carregando ? (
        new Array(5).fill(null).map((_, index) => (
          <Grid item key={`skeleton-item-tabela-negociador-${index + 1}`}>
            <SkeletonItemTabelaNegociadores />
          </Grid>
        ))
      ) : (
        <ConteudoTabelaNegociadores negociadores={listaDemandaColaborador} />
      )}
    </Box>
  );
};

export default ContainerTabelaNegociadores;
