import React from 'react';
import { format } from 'date-fns';

import Box from '@material-ui/core/Box';

export type BoxDataRelogioHoraProps = {
  data: Date;
  fontFamily?: string;
  width?: string;
  justifyContent?: string;
  color?: React.CSSProperties['color'];
  imgMargin?: string;
  fontSize?: string;
  fontWeight?: number;
};

const BoxDataRelogioHora = ({
  data,
  color,
  width,
  justifyContent,
  fontFamily,
  fontSize = '14px',
  imgMargin = '8px',
  fontWeight = 500,
}: BoxDataRelogioHoraProps): JSX.Element => {
  return (
    <Box
      display="flex"
      alignItems="center"
      fontFamily={fontFamily}
      justifyContent={justifyContent}
      width={width}
      fontSize={fontSize}
      lineHeight={fontSize}
      fontWeight={fontWeight}
      color={color}
    >
      {format(data, 'dd/MM')}
      <img
        src="/images/minhasNegociacoes/icone-relogio.svg"
        alt="Relógio"
        height={fontSize}
        style={{
          marginRight: imgMargin,
          marginLeft: imgMargin,
        }}
      />
      {format(data, "HH'h'mm")}
    </Box>
  );
};

export default BoxDataRelogioHora;
