import { createAction } from '@reduxjs/toolkit';
import { EvolucaoDeCargasState } from './evolucaoDeCargasReducer';

export const atualizarIndicadores = createAction<EvolucaoDeCargasState>(
  'atualizarIndicadoresAction/ATUALIZAR_INDICADORES',
);

export const resetarIndicadores = createAction<void>(
  'resetarIndicadoresAction/RESETAR_INDICADORES',
);
