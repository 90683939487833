import { DemandaColaborador } from '../negociadores/api/listagemNegociadoresApiTypes';
import { buscarNegociadores } from '../negociadores/api/listagemNegociadoresManager';

const MilissegundosAtualizacaoDemandas = 60 * 1000;

export const buscarDadosNegociadores = async (
  setListaDemandaColaborador: (
    listaDemandaNegociador: DemandaColaborador[] | undefined,
  ) => void,
  setCarregando: (valor: boolean) => void,
): Promise<void> => {
  try {
    const listaDemandaColaboradorResponse = await buscarNegociadores();
    setListaDemandaColaborador(listaDemandaColaboradorResponse);
  } catch {
    setListaDemandaColaborador(undefined);
  } finally {
    setCarregando(false);
  }
};

export const criarPollingDadosNegociadores = (
  setListaDemandaColaborador: (
    listaDemandaNegociador: DemandaColaborador[] | undefined,
  ) => void,
  setCarregando: (valor: boolean) => void,
): number =>
  Number(
    setInterval(async () => {
      await buscarDadosNegociadores(setListaDemandaColaborador, setCarregando);
    }, MilissegundosAtualizacaoDemandas),
  );
