import { ConexaoAPI } from '../../../shared/classes/conexaoAPI';

import { MotoristaDashboardResponse } from './motoristasVeiculosApiTypes';

export const obterMotoristasVeiculosDashboard = async (
  pagina: string,
  idFilial?: string,
  categoriaVeiculoId?: string,
  ordenarPor?: string,
  nome?: string,
): Promise<MotoristaDashboardResponse> => {
  const urlBase = `${process.env.REACT_APP_URL_API_BASE}/motoristas/dashboard`;
  const params = new URLSearchParams({
    pagina,
    quantidadeItemsPorPagina: '50',
  });

  if (idFilial) {
    params.append('idFilial', idFilial);
  }
  if (categoriaVeiculoId) {
    params.append('categoriaVeiculoId', categoriaVeiculoId);
  }
  if (ordenarPor) {
    params.append('ordenarPor', ordenarPor);
  }
  if (nome) {
    params.append('nome', nome);
  }

  const url = `${urlBase}?${params.toString()}`;

  const conexao = new ConexaoAPI(url, 'get');
  const conectar = conexao.gerarFuncaoConectarRefreshToken<MotoristaDashboardResponse>();
  const resposta = await conectar();
  return resposta.data;
};
