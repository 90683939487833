import { Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';

interface CorPorcentagem {
  corTexto: string;
  paddingCorTexto?: string;
}

export const TypographyPorcentagem = withStyles(() => ({
  root: (props: CorPorcentagem) => ({
    color: props.corTexto,
    fontSize: '17px',
    padding: props.paddingCorTexto,
  }),
}))(Typography);

export const TypographyPorcentagemFlex = withStyles(() => ({
  root: (props: CorPorcentagem) => ({
    color: props.corTexto,
    fontSize: '17px',
    padding: props.paddingCorTexto,
    display: 'contents',
  }),
}))(Typography);
