import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';

import { Box } from '@material-ui/core';
import { RootState } from '../../../../store/reducer';

import ExcluirDialog from '../../../../shared/components/modal/ExcluirDialog';

import { atualizarObservacaoOperation } from '../../redux/informacoesNegociacaoOperation';
import { fecharModalExcluirObservacao } from '../../redux/informacoesNegociacaoAction';

import Accordion from '../../../../shared/components/accordion/Accordion';
import InformacaoHeader from './InformacaoHeader';
import InformacaoDetails from './InformacaoDetails';

const useStyles = makeStyles((theme) => ({
  accordion: {
    backgroundColor: theme.palette.cores.cinza[100],
  },
  accordionExpandido: {
    backgroundColor: theme.palette.cores.branco,
    zIndex: 3,
  },
  mascara: {
    display: 'none',
  },
  mascaraExpandida: {
    display: 'flex',
    width: '100vw',
    height: '100vh',
    backgroundColor: theme.palette.cores.cinza[900],
    opacity: '60%',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 2,
  },
  accordionBefore: {
    '&:before': {
      backgroundColor: 'transparent',
    },
  },
}));

const InformacoesCarga = (): JSX.Element => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    idNegociacao,
    negociacao,
    modalExcluirObservacaoAberto,
  } = useSelector((state: RootState) => state.informacoesNegociacaoReducer);

  const [accordionExpandido, setAccordionExpandido] = useState(false);

  const alterarEstadoAccordion = () => {
    setAccordionExpandido((estadoAnterior) => !estadoAnterior);
  };

  const fecharModal = () => {
    dispatch(fecharModalExcluirObservacao());
  };

  const excluirObservacao = async () => {
    fecharModal();
    await atualizarObservacaoOperation(idNegociacao, {
      observacoes: undefined,
    })(dispatch);
  };

  const estilosAccordion = accordionExpandido
    ? classes.accordionExpandido
    : classes.accordion;

  const estilosMascara = accordionExpandido
    ? classes.mascaraExpandida
    : classes.mascara;

  return (
    (negociacao && (
      <>
        <Box
          onClick={() => setAccordionExpandido(false)}
          className={estilosMascara}
        />
        <Accordion
          className={estilosAccordion}
          expanded={accordionExpandido}
          onChange={alterarEstadoAccordion}
          classes={{ root: classes.accordionBefore }}
        >
          <InformacaoHeader />
          <InformacaoDetails />
        </Accordion>
        <ExcluirDialog
          mensagem="Deseja excluir a observação da negociação?"
          textoCancelar="Cancelar"
          textoConfirmar="Sim, quero excluir!"
          modalAberto={modalExcluirObservacaoAberto}
          onClose={fecharModal}
          confirmar={excluirObservacao}
        />
      </>
    )) || <Skeleton variant="rect" width="100%" height="7vh" />
  );
};

export default InformacoesCarga;
