import { IntervaloTempo } from 'shared/types/apiTypes';
import {
  MilissegundosPorDia,
  MilissegundosPorHora,
  MilissegundosPorMinuto,
  MilissegundosPorSegundo,
} from './dataUtils';

export const formatarDias = (dias: number): string => {
  if (dias < 1) return '';

  if (dias === 1) return `${dias} dia - `;

  return `${dias} dias - `;
};

export const formatarHorario = (horario: number): string =>
  horario >= 10 ? `${horario}` : `0${horario}`;

export const obterIntervaloTempo = (milissegundos: number): IntervaloTempo => {
  let milisseg = milissegundos;

  const dias = Math.floor(milisseg / MilissegundosPorDia);
  milisseg -= dias * MilissegundosPorDia;

  const horas = Math.floor(milisseg / MilissegundosPorHora);
  milisseg -= horas * MilissegundosPorHora;

  const minutos = Math.floor(milisseg / MilissegundosPorMinuto);
  milisseg -= minutos * MilissegundosPorMinuto;

  const segundos = Math.floor(milisseg / MilissegundosPorSegundo);

  return { dias, horas, minutos, segundos };
};

export const transformarIntervaloTempoEmSegundos = (
  intervalo?: IntervaloTempo,
): number => {
  if (!intervalo) return 0;
  const { dias, horas, minutos, segundos } = intervalo;
  const diasEmSegundos = dias * 24 * 60 * 60;
  const horasEmSegundos = horas * 60 * 60;
  const minutosEmSegundos = minutos * 60;
  return diasEmSegundos + horasEmSegundos + minutosEmSegundos + segundos;
};

export const incluirZeroFormatacaoIntervaloTempo = (
  valorAnterior: number,
  valor: number,
  incluirZero: boolean,
  sufixo: 'h ' | 'min',
): string => {
  return valor === 0 && incluirZero && valorAnterior ? `00${sufixo}` : '';
};

export const formatarIntervaloTempo = (
  tempo?: IntervaloTempo,
  incluirZero = false,
): string => {
  if (!tempo) return '--';
  const { dias, horas, minutos } = tempo;
  if (!dias && !horas && !minutos) return '?';
  const diasFormatados = dias ? `${dias}d ` : '';
  const horasFormatadas = horas
    ? `${horas}h `
    : incluirZeroFormatacaoIntervaloTempo(dias, horas, incluirZero, 'h ');
  const minutosFormatados = minutos
    ? `${minutos}min`
    : incluirZeroFormatacaoIntervaloTempo(horas, minutos, incluirZero, 'min');
  return `${diasFormatados}${horasFormatadas}${minutosFormatados}`;
};

export const formatarUnidadeIntervaloTempo = (
  unidadeTempo: [string, number],
): string => {
  const valorUnidadeTempo =
    unidadeTempo[1] < 0 ? unidadeTempo[1] * -1 : unidadeTempo[1];
  switch (unidadeTempo[0]) {
    case 'dias':
      return `${valorUnidadeTempo}d`;
    case 'horas':
      return `${valorUnidadeTempo}h`;
    case 'minutos':
      return `${valorUnidadeTempo}min`;
    default:
      return '--';
  }
};

export const formatarCycleTime = (cycleTime?: IntervaloTempo): string => {
  if (!cycleTime) return '--';

  const { dias, horas, minutos } = cycleTime;

  if (dias !== 0) return `${dias}d ${horas}h`;
  if (horas !== 0) return `${horas}h${minutos}`;
  return `${minutos}min`;
};
