import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';

import { buscarDadosPermissaoUsuario } from 'features/login/redux/loginOperation';
import BoxPagina from '../../../shared/components/box/BoxPagina';
import TituloNumerado from '../../../shared/components/titulo/Titulo';
import { PATH_LISTA_CARGAS } from '../../../shared/constants/pathConstants';

import { RootState } from '../../../store/reducer';

import Header from './header/Header';
import Accordion from './accordion/Accordion';
import { spacingInPixels } from '../../../shared/functions/materialUtils';
import FeedbackSnackbar from '../../../shared/components/snackbar/FeedbackSnackbar';
import BotaoCriarNegociacoes from './BotaoCriarNegociacoes';
import { removerTodasNegociacoes } from '../redux/minhasCargasActions';

const MinhasCargas = (): JSX.Element => {
  const history = useHistory();
  const theme = useTheme();
  const dispatch = useDispatch();

  const [primeiroCarregamento, setPrimeiroCarregamento] = useState(true);
  const [snackbarErroAberto, setSnackbarErroAberto] = useState(false);

  const { idsCargasSelecionadas: idsCargas } = useSelector(
    (reducer: RootState) => reducer.listaCargasReducer,
  );

  useEffect(() => {
    buscarDadosPermissaoUsuario()(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (primeiroCarregamento) {
      dispatch(removerTodasNegociacoes());
      setPrimeiroCarregamento(false);
    }
  }, [dispatch, primeiroCarregamento]);

  useEffect(() => {
    window.onbeforeunload = () => true;

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
    if (idsCargas.size === 0) {
      history.push(PATH_LISTA_CARGAS);
    }
  }, [history, idsCargas.size]);

  return (
    <BoxPagina component="section">
      <TituloNumerado
        titulo="Minhas Cargas"
        quantidade={idsCargas.size}
        possuiBotaoVoltar
        voltar={() => {
          history.push('/lista-cargas');
        }}
      />
      <Box bgcolor={theme.palette.cores.branco}>
        <Header />
        <Box>
          {Array.from(idsCargas).map((idCarga) => (
            <Accordion
              key={idCarga}
              idCarga={idCarga}
              setErro={setSnackbarErroAberto}
            />
          ))}
        </Box>
        <Box height={spacingInPixels(theme, 5)} />
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        fontSize="16px"
        marginTop={spacingInPixels(theme, 3)}
      >
        <BotaoCriarNegociacoes />
      </Box>
      <FeedbackSnackbar
        open={snackbarErroAberto}
        onClose={() => {
          setSnackbarErroAberto(false);
        }}
        severity="error"
        mensagem="Algumas das cargas selecionadas não podem ser negociadas no momento."
      />
    </BoxPagina>
  );
};

export default MinhasCargas;
