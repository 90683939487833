import React, { useCallback } from 'react';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import IconeTelefone from 'assets/icones/IconeTelefone';
import IconeOuro from 'assets/icones/IconeOuro';
import { formatarTelefone } from 'shared/functions/telefoneUtils';

import {
  MotoristaNegociacao,
  PropostaNegociacao,
} from '../../../api/informacoesNegociacaoApiTypes';

import EnviarPropostaButton from './EnviarPropostaButton';

import Texto from './TextoItemLista';
import { RootState } from '../../../../../store/reducer';
import VeiculoInfo from '../components/VeiculoInfo';

type MotoristaGridProps = {
  sequencia: number;
  motorista: MotoristaNegociacao;
};

const useStyles = makeStyles((theme) => ({
  sequencia: {
    color: theme.palette.primary.main,
  },
  linhaGrid: {
    display: 'flex',
    backgroundColor: ({ index }: { index: number }) =>
      index % 2 === 0
        ? theme.palette.cores.branco
        : theme.palette.cores.cinza[100],
    padding: '20px 83px 22.5px 20px',
    border: '1px solid #E0E0E0',
    borderRadius: '4px 4px 0px 0px',
    marginBottom: '4px',
  },
  textoAzul: {
    color: theme.palette.cores.azulMartins[500],
    paddingBottom: '20px',
  },
  texto: {
    marginLeft: 8,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '19px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  nomeVeiculo: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginRight: 8,
  },
}));

const MotoristaGrid = ({
  sequencia,
  motorista,
}: MotoristaGridProps): JSX.Element => {
  const classes = useStyles({ index: sequencia });

  const { negociacao, propostas, propostasAplicativo } = useSelector(
    (state: RootState) => state.informacoesNegociacaoReducer,
  );

  const verificarNegociacaoSemValores = useCallback(() => {
    return !(negociacao?.valorNegociacao && negociacao.valorFrotaPropria);
  }, [negociacao?.valorFrotaPropria, negociacao?.valorNegociacao]);

  const verificarPropostaMotorista = useCallback(
    (listaPropostas: PropostaNegociacao[]) => {
      return listaPropostas
        .map((proposta) => proposta.contato)
        .includes(motorista.contato);
    },
    [motorista.contato],
  );

  const verificarMotoristaTemProposta = (): boolean =>
    verificarPropostaMotorista(propostas) ||
    verificarPropostaMotorista(propostasAplicativo);

  return (
    <Box className={classes.linhaGrid}>
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <Texto className={classes.textoAzul}>
            {motorista.nome.toLowerCase()}
          </Texto>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={4}>
              <VeiculoInfo nomeVeiculo={motorista.veiculo} />
            </Grid>
            <Grid item xs={3}>
              <Box display="flex" alignItems="center" height="100%">
                <IconeOuro />
                <Texto className={classes.texto}>
                  {`${motorista.capacidadeVeiculo} kg`}
                </Texto>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" alignItems="center" height="100%">
                <IconeTelefone />
                <Texto className={classes.texto}>
                  {formatarTelefone(motorista.contato)}
                </Texto>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <EnviarPropostaButton
                motorista={motorista}
                negociacaoSemValores={verificarNegociacaoSemValores()}
                motoristaTemProposta={verificarMotoristaTemProposta()}
                origemFrete={negociacao?.origemNegociacao}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MotoristaGrid;
