import React, { useState } from 'react';
import { Box, makeStyles, Typography, useTheme } from '@material-ui/core';
import { KeyboardArrowDownRounded } from '@material-ui/icons';
import { TipoItemHeaderTabelaDemandasNegociador as TipoItemHeader } from 'features/dashboards/constants/ItensHeaderTabelaDemandasNegociador';
import spacingInPixels from 'shared/functions/materialUtils';
import CustomTooltip from 'shared/components/tooltip/CustomTooltip';

const useStyles = makeStyles((theme) => ({
  titulo: {
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: spacingInPixels(theme, 2),
    lineHeight: spacingInPixels(theme, 4),
    color: theme.palette.cores.cinza[800],
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '90%',
  },
  ordenacao: {
    '&[data-ordenacao]': {
      cursor: 'pointer',
    },
    '&[data-ordenacao]:hover': {
      backgroundColor: theme.palette.cores.cinza[50],
    },
  },
  ordenacaoSelecionada: {
    color: theme.palette.cores.cinza[500],
    '&[data-selecionado=true]': {
      color: theme.palette.cores.azul[500],
    },
    '&[data-crescente=true]': {
      transform: 'rotate(180deg)',
    },
  },
}));

export interface ItemHeaderTabelaDemandasNegociadorProps {
  itemHeader: TipoItemHeader;
  selecionado: boolean;
  setItemSelecionado: React.Dispatch<React.SetStateAction<TipoItemHeader>>;
  className?: string;
}

const ItemHeaderTabelaDemandasNegociador = ({
  itemHeader,
  setItemSelecionado,
  selecionado,
  className,
}: ItemHeaderTabelaDemandasNegociadorProps): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles();
  const [crescente, setCrescente] = useState(itemHeader.crescente);

  const onClickOrdenacao = () => {
    setItemSelecionado({ ...itemHeader, crescente: !crescente });
    setCrescente(!crescente);
  };

  return (
    <Box
      className={`${className} ${classes.ordenacao}`}
      data-ordenacao={itemHeader.ordenacao}
      onClick={itemHeader.ordenacao ? onClickOrdenacao : undefined}
    >
      <>
        {itemHeader?.icone ? (
          <CustomTooltip title={itemHeader.titulo}>
            <div style={{ display: 'flex' }}>
              {React.cloneElement(itemHeader.icone, {
                width: '20px',
                height: '20px',
                fill: theme.palette.cores.cinza[800],
              })}
            </div>
          </CustomTooltip>
        ) : (
          <CustomTooltip title={itemHeader.titulo}>
            <Typography className={classes.titulo}>
              {itemHeader.titulo}
            </Typography>
          </CustomTooltip>
        )}
        {itemHeader.ordenacao && (
          <KeyboardArrowDownRounded
            className={classes.ordenacaoSelecionada}
            data-crescente={crescente}
            data-selecionado={selecionado}
          />
        )}
      </>
    </Box>
  );
};

export default ItemHeaderTabelaDemandasNegociador;
