import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core';

import IconeBalaoChat from 'assets/icones/IconeBalaoChat';
import IconeRelogio from 'assets/icones/IconeRelogio';
import IconeCorretoQuadrado from 'assets/icones/IconeCorretoQuadrado';
import IconeReprovar from 'assets/icones/IconeReprovar';
import IconeExclamacaoOctogono from 'assets/icones/IconeExclamacaoOctogono';

import { StatusDemandaDiariaDashboard } from 'features/detalhesDemandasDiaria/constants/Constants';
import { PATH_DETALHES_DEMANDAS_DIARIA } from 'shared/constants/pathConstants';
import { useHistory } from 'react-router-dom';
import { DemandaDiaria as DemandaDiariaType } from '../../api/demandaDiariaApiTypes';

import SkeletonQuadroBigNumbers from '../skeletons/SkeletonQuadroBigNumbers';
import BigNumberBorda from './BigNumberBorda';

const useStyles = makeStyles(() => ({
  principal: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export type QuadroBigNumbersProps = {
  demandasDiarias?: DemandaDiariaType;
  carregando?: boolean;
};

const QuadroBigNumbers = ({
  demandasDiarias,
  carregando,
}: QuadroBigNumbersProps): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();

  const handleClick = (tipoDemanda: StatusDemandaDiariaDashboard) => {
    history.push(
      PATH_DETALHES_DEMANDAS_DIARIA.replace(':tipoDemanda', tipoDemanda),
    );
  };

  if (carregando) return <SkeletonQuadroBigNumbers />;

  return (
    <section className={classes.principal}>
      <BigNumberBorda
        icone={<IconeBalaoChat width={15} height={15} />}
        cor={theme.palette.cores.azulPiscina[400]}
        numero={demandasDiarias?.emNegociacao}
        descricao="Em Negociação"
        onClick={() => handleClick(StatusDemandaDiariaDashboard.EM_NEGOCIACAO)}
      />

      <BigNumberBorda
        icone={<IconeRelogio transform="scale(-1,1)" width={15} height={15} />}
        cor={theme.palette.cores.mostarda[800]}
        numero={demandasDiarias?.naoIniciada}
        descricao="Não Iniciadas"
        onClick={() => handleClick(StatusDemandaDiariaDashboard.NAO_INICIADAS)}
      />

      <BigNumberBorda
        icone={<IconeCorretoQuadrado width={15} height={15} />}
        cor={theme.palette.cores.verdeClaro[500]}
        numero={demandasDiarias?.concluida}
        descricao="Negociadas"
        onClick={() => handleClick(StatusDemandaDiariaDashboard.NEGOCIADAS)}
      />

      <BigNumberBorda
        icone={<IconeReprovar width={15} height={15} />}
        cor={theme.palette.cores.vermelho[450]}
        numero={demandasDiarias?.cancelada}
        descricao="Canceladas"
        onClick={() => handleClick(StatusDemandaDiariaDashboard.RECUSADAS)}
      />

      <BigNumberBorda
        icone={<IconeExclamacaoOctogono width={15} height={15} />}
        cor={theme.palette.cores.azulPiscina[900]}
        numero={demandasDiarias?.emAprovacao}
        descricao="Aprovação"
        onClick={() => handleClick(StatusDemandaDiariaDashboard.EM_APROVACAO)}
      />
    </section>
  );
};

export default QuadroBigNumbers;
