import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import Titulo from 'shared/components/titulo/Titulo';
import EmptyPage from 'shared/components/emptyPage/EmptyPage';
import { buscarDadosPermissaoUsuario } from 'features/login/redux/loginOperation';
import { ModalPropor } from '../chatNegociacao/views/ModalPropor';
import { ModalConfirmacao } from '../chatNegociacao/views/ModalConfirmacao';
import { ModalReprovacao } from '../chatNegociacao/views/ModalReprovacao';

import Cabecalho from './views/Cabecalho';
import DetalhesCarga from './views/DetalhesCarga';
import DetalhesProposta from './views/DetalhesProposta';
import IndicadoresMotoristaRota from './views/indicadoresMotoristaRota/IndicadoresMotoristaRota';
import LinhaDoTempo from '../../shared/components/linhaDoTempo/LinhaDoTempo';
import MaisDetalhes from './views/MaisDetalhes';
import {
  aprovarPropostasSupervisor,
  proporNovaMetaPropostasSupervisor,
  reprovarPropostasSupervisor,
  obterDetalhesPropostaSupervisor,
} from './utils/detalhesAprovacaoSupervisor';
import { DetalhesAprovacaoSupervisorResponse } from './api/detalhesAprovacaoSupervisorTypes';
import { useStyles } from './styles/DetalhesAprovacaoSupervisor';

interface RouteParams {
  idProposta: string;
  idNegociacao: string;
}

const DetalhesAprovacaoSupervisor = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<RouteParams>();
  const idProposta = Number(params.idProposta);
  const idNegociacao = Number(params.idNegociacao);

  const [modalConfirmar, setModalConfirmar] = useState(false);
  const [modalConfirmType, setModalConfirmType] = useState<
    'default' | 'success' | 'error'
  >('default');

  const [modalPropor, setModalPropor] = useState(false);
  const [modalReprovar, setModalReprovar] = useState(false);
  const [detalhesPropostaResponse, setDetalhesPropostaResponse] = useState<
    DetalhesAprovacaoSupervisorResponse | undefined
  >();
  const [justificativa, setJustificativa] = useState('');
  const [valorNovaMeta, setValorNovaMeta] = useState<number | undefined>(
    undefined,
  );
  const [carregando, setCarregando] = useState<boolean>(true);

  useEffect(() => {
    buscarDadosPermissaoUsuario()(dispatch);
  }, [dispatch]);

  useEffect(() => {
    const ObterDetalhesProposta = async () => {
      const detalhes = await obterDetalhesPropostaSupervisor(
        idNegociacao,
        idProposta,
        dispatch,
      );
      setDetalhesPropostaResponse(detalhes);

      setCarregando(false);
    };

    ObterDetalhesProposta();
  }, [idNegociacao, idProposta, dispatch, history]);

  const permitirConfirmacao = useMemo(() => !!valorNovaMeta, [valorNovaMeta]);

  function handleOpenModalConfirmar() {
    setModalConfirmType('success');
    setValorNovaMeta(detalhesPropostaResponse?.proposta?.valorProposta || 0);
    setModalConfirmar(true);
  }

  async function handleConfirmModalConfirmar() {
    const navegarPaginaAprovacoesSupervisor = () => history.goBack();

    switch (modalConfirmType) {
      case 'default':
        await proporNovaMetaPropostasSupervisor(
          idNegociacao,
          idProposta,
          {
            justificativa,
            valorNovaMeta: valorNovaMeta || 0,
          },
          dispatch,
          navegarPaginaAprovacoesSupervisor,
        );
        break;
      case 'success':
        await aprovarPropostasSupervisor(
          idNegociacao,
          idProposta,
          dispatch,
          navegarPaginaAprovacoesSupervisor,
        );
        break;
      case 'error':
        await reprovarPropostasSupervisor(
          idNegociacao,
          idProposta,
          {
            justificativa,
          },
          dispatch,
          navegarPaginaAprovacoesSupervisor,
        );
        break;

      default:
        break;
    }

    setModalConfirmar(false);
  }

  function handleOpenModalPropor() {
    setModalPropor(true);
  }

  function handleCloseModalPropor() {
    setModalPropor(false);
  }

  async function handleConfirmModalPropor() {
    setValorNovaMeta(valorNovaMeta);
    setModalPropor(false);
    setModalConfirmType('default');

    setModalConfirmar(true);
  }

  function handleOpenModalReprovar() {
    setModalReprovar(true);
  }

  function handleCloseModalReprovar() {
    setJustificativa('');
    setModalReprovar(false);
  }

  async function handleConfirmModalReprovar() {
    setValorNovaMeta(detalhesPropostaResponse?.proposta.valorProposta || 0);
    setModalReprovar(false);
    setModalConfirmType('error');
    setModalConfirmar(true);
  }

  return (
    <Box>
      {!detalhesPropostaResponse && !carregando ? (
        <>
          <Box minWidth="300px">
            <Titulo
              titulo="Detalhes do pedido"
              possuiBotaoVoltar
              voltar={() => history.goBack()}
            />
          </Box>
          <EmptyPage
            texto="Ops algo aconteceu, por algum motivo não conseguimos carregar as informações."
            corFundo="transparent"
            iconePreenchido={false}
          />
        </>
      ) : (
        <>
          <Cabecalho
            onOpenConfirmar={handleOpenModalConfirmar}
            onOpenPropor={handleOpenModalPropor}
            onOpenReprovar={handleOpenModalReprovar}
            valorProposta={detalhesPropostaResponse?.proposta?.valorProposta}
            carregando={carregando}
          />
          <DetalhesCarga
            carga={detalhesPropostaResponse?.carga}
            carregando={carregando}
          />
          <DetalhesProposta
            carga={detalhesPropostaResponse?.carga}
            proposta={detalhesPropostaResponse?.proposta}
            negociacao={detalhesPropostaResponse?.negociacao}
            motorista={detalhesPropostaResponse?.motorista}
            rota={detalhesPropostaResponse?.rota}
            filial={detalhesPropostaResponse?.filial}
            carregando={carregando}
          />
          <MaisDetalhes
            proposta={detalhesPropostaResponse?.proposta}
            negociacao={detalhesPropostaResponse?.negociacao}
            carga={detalhesPropostaResponse?.carga}
            carregando={carregando}
          />
          <section className={classes.containerTimelineIndicadores}>
            <LinhaDoTempo
              historico={detalhesPropostaResponse?.proposta?.historico}
              valorFrotaPropria={
                detalhesPropostaResponse?.negociacao?.valorFrotaPropria || 0
              }
              carregando={carregando}
            />

            <IndicadoresMotoristaRota
              carga={detalhesPropostaResponse?.carga}
              rota={detalhesPropostaResponse?.rota}
              motorista={detalhesPropostaResponse?.motorista}
              proposta={detalhesPropostaResponse?.proposta}
              carregando={carregando}
            />
          </section>
          {detalhesPropostaResponse && (
            <ModalPropor
              modalOpen={modalPropor}
              onCloseModal={handleCloseModalPropor}
              onConfirmModal={handleConfirmModalPropor}
              justificativa={justificativa}
              setJustificativa={setJustificativa}
              valorNovaMeta={valorNovaMeta}
              setValorNovaMeta={setValorNovaMeta}
              carga={detalhesPropostaResponse.carga}
              negociacao={detalhesPropostaResponse.negociacao}
              filial={detalhesPropostaResponse.filial}
              habilitarConfirmacao={permitirConfirmacao}
            />
          )}
        </>
      )}

      <ModalConfirmacao
        modalOpen={modalConfirmar}
        onCloseModal={() => setModalConfirmar(false)}
        onConfirmModal={handleConfirmModalConfirmar}
        type={modalConfirmType}
        valorApresentar={valorNovaMeta || 0}
      />
      <ModalReprovacao
        modalOpen={modalReprovar}
        onCloseModal={handleCloseModalReprovar}
        onConfirmModal={handleConfirmModalReprovar}
        justificativa={justificativa}
        setJustificativa={setJustificativa}
      />
    </Box>
  );
};

export default DetalhesAprovacaoSupervisor;
