import { Dispatch } from 'redux';
import PropostaStatus from 'shared/constants/PropostaStatus';
import TipoMensagemProposta from 'shared/constants/TipoMensagemProposta';
import {
  calcularPorcentagemInteira,
  calcularValorPelaPorcentagem,
} from 'shared/functions/porcentagemUtils';
import { enviarMensagem } from './chatResposta';
import TipoValorContraproposta from '../constants/TipoValorContraproposta';

interface InterfaceBotaoEnviarContraProposta {
  chatIdNegociacao: number | undefined;
  chatIdProposta: number | undefined;
  valor: number | undefined;
  motivo: string | undefined;
  setErro: (erro: boolean) => void;
  setEnviando: (erro: boolean) => void;
  dispatch: Dispatch;
  tipoMensagemProposta: TipoMensagemProposta;
  propostaStatus: PropostaStatus;
}
export const onClickBotaoEnviarContraproposta = async ({
  chatIdNegociacao,
  chatIdProposta,
  valor,
  motivo,
  setErro,
  setEnviando,
  dispatch,
  tipoMensagemProposta,
  propostaStatus,
}: InterfaceBotaoEnviarContraProposta): Promise<void> => {
  if (!valor) {
    setErro(true);
  } else {
    await enviarMensagem({
      chatIdNegociacao,
      chatIdProposta,
      valor,
      motivo,
      motivoRecusa: undefined,
      tipoMensagem: tipoMensagemProposta,
      propostaStatus,
      setEnviando,
      dispatch,
    });
  }
};

export const calcularValorProposto = (
  tipo: TipoValorContraproposta,
  campoDigitado?: number,
  valorReferencia?: number,
): number | undefined => {
  if (
    campoDigitado === undefined ||
    (valorReferencia === undefined &&
      tipo !== TipoValorContraproposta.ValorProposto)
  ) {
    return undefined;
  }
  switch (tipo) {
    case TipoValorContraproposta.PorcentagemFrotaPropria:
    case TipoValorContraproposta.PorcentagemFreteTerceiro:
    case TipoValorContraproposta.PorcentagemMercadoria:
      return calcularValorPelaPorcentagem(
        campoDigitado,
        valorReferencia as number,
      );
    case TipoValorContraproposta.PorEntrega:
    case TipoValorContraproposta.PorKm:
    case TipoValorContraproposta.PorPeso:
      return Math.round(campoDigitado * (valorReferencia as number));
    case TipoValorContraproposta.ValorProposto:
    default:
      return campoDigitado;
  }
};

export const validarCampoValorProposto = (
  setMensagemErro: (mensagem: string) => void,
  setErroInput?: (erro: boolean) => void,
  valorProposto?: number,
  valorNovaMeta?: number,
  valorFrotaPropria?: number,
): void => {
  const prefixoMensagem = 'O valor precisa ser superior a';
  let sufixoMensagem = '';
  let valorInvalido = false;
  if (valorNovaMeta && valorProposto) {
    valorInvalido = valorProposto === 0 || valorProposto <= valorNovaMeta;
    sufixoMensagem = 'nova meta';
  } else {
    const novaPorcentagemFrotaPropria = calcularPorcentagemInteira(
      valorProposto || 0,
      valorFrotaPropria || 0,
    );
    valorInvalido =
      (Boolean(valorProposto) || valorProposto === 0) &&
      novaPorcentagemFrotaPropria <= 85;
    sufixoMensagem = '85% Target';
  }
  setErroInput?.(valorInvalido);
  setMensagemErro(`${prefixoMensagem} ${sufixoMensagem}`);
};
