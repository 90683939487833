import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';
import IconeCalendario from 'assets/icones/IconeCalendario';
import SelectCustomizado from './SelectCustomizado';
import { OpcaoSelectCustomizado } from '../types/PainelIndicadoresTypes';
import { PeriodoFiltroIndicadores } from '../constants/Constants';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    gap: spacingInPixels(theme, 1.5),
  },
  ano: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: spacingInPixels(theme, 2),
    lineHeight: spacingInPixels(theme, 3.5),
    color: theme.palette.cores.cinza[600],
    alignSelf: 'center',
  },
}));

interface SeletorPeriodoProps {
  onSelectPeriodo: (periodo: string) => void;
}

const SeletorPeriodo = ({
  onSelectPeriodo,
}: SeletorPeriodoProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();

  const opcoesPeriodo: OpcaoSelectCustomizado[] = [
    {
      descricao: 'Dia',
      valor: PeriodoFiltroIndicadores.DAY,
      desabilitado: false,
    },
    {
      descricao: 'Semana',
      valor: PeriodoFiltroIndicadores.WEEK,
      desabilitado: false,
    },
    {
      descricao: 'Mês',
      valor: PeriodoFiltroIndicadores.MONTH,
      desabilitado: false,
    },
    {
      descricao: 'Bimestre',
      valor: PeriodoFiltroIndicadores.BIMESTRE,
      desabilitado: false,
    },
    {
      descricao: 'Trimestre',
      valor: PeriodoFiltroIndicadores.TRIMESTER,
      desabilitado: false,
    },
    {
      descricao: 'Ano',
      valor: PeriodoFiltroIndicadores.YEAR,
      desabilitado: false,
    },
  ];

  return (
    <section className={classes.container}>
      <span className={classes.ano}>{new Date().getFullYear()}</span>
      <SelectCustomizado
        opcoes={opcoesPeriodo}
        valorInicial={PeriodoFiltroIndicadores.WEEK}
        icone={
          <IconeCalendario
            fill={theme.palette.cores.azulMartins[500]}
            width={16}
            height={16}
          />
        }
        onChangeEvent={onSelectPeriodo}
      />
    </section>
  );
};

export default SeletorPeriodo;
