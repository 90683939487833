import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { formatarNumeroParaStringMonetariaComSimbolo } from '../../../../../shared/functions/dinheiroUtils';
import spacingInPixels from '../../../../../shared/functions/materialUtils';

export type ValorAprovadoProps = {
  valorAprovado?: number;
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: spacingInPixels(theme, 3),
  },
  descricao: {
    fontSize: ' 12px',
    fontWeight: 400,
  },
  valorAprovado: {
    fontSize: ' 14px',
    fontWeight: 700,
    color: theme.palette.cores.verde[700],
  },
}));

const ValorAprovado = ({ valorAprovado }: ValorAprovadoProps): JSX.Element => {
  const classes = useStyles();
  return (
    <article className={classes.container}>
      <Typography className={classes.descricao}>Valor aprovado</Typography>
      <Typography className={classes.valorAprovado}>
        {formatarNumeroParaStringMonetariaComSimbolo(valorAprovado)}
      </Typography>
    </article>
  );
};

export default ValorAprovado;
