import { useTheme } from '@material-ui/core/styles';
import * as React from 'react';
import { SVGProps } from 'react';

const IconeCasa = ({
  fill,
  width,
  height,
  ...outros
}: SVGProps<SVGSVGElement>): JSX.Element => {
  const theme = useTheme();

  return (
    <svg
      width={width || 18}
      height={height || 21}
      viewBox="0 0 18 21"
      fill="none"
      {...outros}
    >
      <path
        d="m17 7-6-5.26a3 3 0 0 0-4 0L1 7a3 3 0 0 0-1 2.26V18a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V9.25A3 3 0 0 0 17 7Zm-6 12H7v-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v5Zm5-1a1 1 0 0 1-1 1h-2v-5a3 3 0 0 0-3-3H8a3 3 0 0 0-3 3v5H3a1 1 0 0 1-1-1V9.25a1 1 0 0 1 .34-.75l6-5.25a1 1 0 0 1 1.32 0l6 5.25a1 1 0 0 1 .34.75V18Z"
        fill={fill || theme.palette.cores.azulMartins[500]}
      />
    </svg>
  );
};

export default IconeCasa;
