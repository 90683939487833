import React, { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core';
import IconeBalaoChat from 'assets/icones/IconeBalaoChat';
import IconeRelogio from 'assets/icones/IconeRelogio';
import IconeCorretoQuadrado from 'assets/icones/IconeCorretoQuadrado';
import IconeReprovar from 'assets/icones/IconeReprovar';
import IconeExclamacaoOctogono from 'assets/icones/IconeExclamacaoOctogono';
import CustomTooltip from 'shared/components/tooltip/CustomTooltip';
import { CelulaStatusDemandaProps } from 'features/detalhesDemandasDiaria/types/tabelaTypes';
import { StatusDemandaEnum } from 'features/detalhesDemandasDiaria/api/detalhesDemandasDiariaApiTypes';
import { useStyles } from '../../styles/styleCelulaStatusDemanda';

const CelulaStatusDemanda = ({
  status,
}: CelulaStatusDemandaProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();

  const [descricao, setDescricao] = useState('');
  const [icone, setIcone] = useState<JSX.Element | null>(null);
  const [corFonte, setCorFonte] = useState(theme.palette.cores.cinza[400]);
  const [corBackground, setCorBackground] = useState(
    theme.palette.cores.cinza[200],
  );

  useEffect(() => {
    switch (status) {
      case StatusDemandaEnum.Concluida:
        setCorFonte(theme.palette.cores.verdeClaro[500]);
        setCorBackground(theme.palette.cores.verdeClaro[100]);
        setDescricao('Negociada');
        setIcone(
          <IconeCorretoQuadrado
            width={13}
            height={13}
            fill={theme.palette.cores.verdeClaro[500]}
          />,
        );
        break;
      case StatusDemandaEnum.NaoIniciada:
        setCorFonte(theme.palette.cores.mostarda[800]);
        setCorBackground(theme.palette.cores.laranja[100]);
        setDescricao('Não iniciada');
        setIcone(
          <IconeRelogio
            transform="scale(-1,1)"
            width={13}
            height={13}
            fill={theme.palette.cores.mostarda[800]}
          />,
        );
        break;
      case StatusDemandaEnum.Cancelada:
        setCorFonte(theme.palette.cores.vermelho[450]);
        setCorBackground(theme.palette.cores.vermelho[100]);
        setDescricao('Recusada');
        setIcone(
          <IconeReprovar
            width={13}
            height={13}
            fill={theme.palette.cores.vermelho[450]}
          />,
        );
        break;
      case StatusDemandaEnum.EmNegociacao:
        setCorFonte(theme.palette.cores.azulPiscina[400]);
        setCorBackground(theme.palette.cores.azulPiscina[50]);
        setDescricao('Em negociação');
        setIcone(<IconeBalaoChat width={13} height={13} />);
        break;
      case StatusDemandaEnum.EmAprovacao:
        setCorFonte(theme.palette.cores.cinza[700]);
        setCorBackground(theme.palette.cores.cinza[300]);
        setDescricao('Aprovação');
        setIcone(<IconeExclamacaoOctogono width={13} height={13} />);
        break;
      default:
        setCorFonte(theme.palette.cores.cinza[500]);
        setCorBackground(theme.palette.cores.cinza[300]);
        setDescricao('--');
        setIcone(null);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <td>
      <CustomTooltip title={descricao}>
        {icone ? (
          <div
            className={classes.container}
            style={{ backgroundColor: corBackground }}
          >
            {icone}
            <span className={classes.fonte} style={{ color: corFonte }}>
              {descricao}
            </span>
          </div>
        ) : (
          <span className={classes.semStatus}>{descricao}</span>
        )}
      </CustomTooltip>
    </td>
  );
};

export default CelulaStatusDemanda;
