import { makeStyles } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacingInPixels(theme, 4),
    marginTop: spacingInPixels(theme, 4),
  },
  containerNotificacoes: {
    '& > *': {
      marginBottom: spacingInPixels(theme, 2),
    },
  },
  textoNotificacoes: {
    fontWeight: 500,
  },
  containerAgrupamento: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: spacingInPixels(theme, 8),
  },
  boxSecaoConfig: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacingInPixels(theme, 2),
  },
  boxFitContent: {
    maxWidth: 'fit-content',
    padding: spacingInPixels(theme, 2),
    backgroundColor: theme.palette.cores.cinza[50],
    minWidth: '100%',
    borderRadius: spacingInPixels(theme, 2),
  },
  buttonInside: {
    color: theme.palette.cores.azul[600],
  },
  buttonOutside: {
    backgroundColor: theme.palette.cores.azul[600],

    '&:hover': {
      backgroundColor: theme.palette.cores.azul[600],
      filter: 'brightness(0.9)',
    },
  },
  titulo: {
    color: theme.palette.cores.cinza[800],
  },
}));
