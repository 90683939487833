import { makeStyles } from '@material-ui/core';
import spacingInPixels from '../../../shared/functions/materialUtils';

export const useStyles = makeStyles((theme) => ({
  boxResposta: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  boxRespostaBotao: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  boxRespostaInterno: {
    width: '95%',
    borderRadius: '5px',
    background: theme.palette.cores.cinza[50],
    border: `1px solid ${theme.palette.cores.cinza[400]}`,
    margin: `${spacingInPixels(theme, 1)} ${spacingInPixels(theme, 0)}`,
    padding: `${spacingInPixels(theme, 2)} ${spacingInPixels(theme, 3)}`,
    textAlign: 'center',
  },
  boxReabrirNegociacao: {
    width: '95%',
    borderRadius: '5px',
    background: theme.palette.cores.cinza[100],
    border: `1px solid ${theme.palette.cores.cinza[400]}`,
    margin: `${spacingInPixels(theme, 1)} ${spacingInPixels(theme, 0)}`,
    padding: `${spacingInPixels(theme, 2)} ${spacingInPixels(theme, 3)}`,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  boxReabrirNegociacaoContraProposta: {
    width: '95%',
    borderRadius: '5px',
    background: theme.palette.cores.cinza[50],
    border: `1px solid ${theme.palette.cores.cinza[400]}`,
    margin: `${spacingInPixels(theme, 1)} ${spacingInPixels(theme, 0)}`,
    padding: `${spacingInPixels(theme, 2)} ${spacingInPixels(theme, 3)}`,
    textAlign: 'center',
  },
  respostaRecusa: {
    color: theme.palette.cores.vermelho[600],
    fontSize: '14px',
  },
  motivoRecusa: {
    color: theme.palette.cores.vermelho[600],
    fontWeight: 600,
    fontSize: '16px',
    padding: '4px',
  },
  boxAceite: {
    backgroundColor: `${theme.palette.cores.verde[200]}40`,
    borderColor: `${theme.palette.cores.verde[700]}90`,
    color: `${theme.palette.cores.verde[700]}`,
  },
  boxRecusa: {
    backgroundColor: `${theme.palette.cores.vermelho[200]}40`,
    borderColor: `${theme.palette.cores.vermelho[700]}90`,
  },
  boxRespostaFreteTabelado: {
    position: 'absolute',
    bottom: '20px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  boxAceiteFreteTabelado: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '70px',
    backgroundColor: `${theme.palette.cores.verde[200]}40`,
    borderColor: `${theme.palette.cores.verde[700]}90`,
    color: `${theme.palette.cores.verde[700]}`,
  },
  botaoAvaliacao: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '95%',
    fontSize: '16px',
    borderRadius: '25px',
    background: theme.palette.cores.azulMartins[500],
    border: `1px solid ${theme.palette.cores.cinza[400]}`,
    margin: `${spacingInPixels(theme, 1)} ${spacingInPixels(theme, 0)}`,
    padding: `${spacingInPixels(theme, 2)} ${spacingInPixels(theme, 3)}`,
    textAlign: 'center',
    color: 'white',
    height: '50px',
    '&:hover': {
      backgroundColor: theme.palette.cores.azulMartins[700],
    },
  },
}));

export default useStyles;
