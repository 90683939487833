import React from 'react';

import ItensHeaderTabelaDemandasNegociador, {
  TipoItemHeaderTabelaDemandasNegociador as TipoItemHeader,
} from 'features/dashboards/constants/ItensHeaderTabelaDemandasNegociador';

import ItensHeaderTabelaDemandasNegociadorAutomatizadas, {
  TipoItemHeaderTabelaDemandasNegociadorAutomatizadas as TipoItemHeaderAutomatizadas,
} from 'features/dashboards/constants/ItensHeaderTabelaDemandasNegociadorAutomatizadas';

import gridStyles from 'features/dashboards/styles/gridTabelaDemandasNegociadorStyle';
import ItemHeaderTabelaDemandasNegociador from './ItemHeaderTabelaDemandasNegociador';

export interface HeaderTabelaDemandasNegociadorProps {
  itemSelecionado: TipoItemHeader | TipoItemHeaderAutomatizadas;
  setItemSelecionado: React.Dispatch<React.SetStateAction<TipoItemHeader>>;
  isAutomatizado: boolean;
}

const HeaderTabelaDemandasNegociador = ({
  itemSelecionado,
  setItemSelecionado,
  isAutomatizado,
}: HeaderTabelaDemandasNegociadorProps): JSX.Element => {
  const estilosGrid = gridStyles({ isAutomatizadoStyle: isAutomatizado });

  const renderTabelaDemandas = () => {
    if (isAutomatizado) {
      return ItensHeaderTabelaDemandasNegociadorAutomatizadas;
    }
    return ItensHeaderTabelaDemandasNegociador;
  };

  return (
    <header className={estilosGrid.gridContainer}>
      {renderTabelaDemandas().map((itemHeader) => (
        <ItemHeaderTabelaDemandasNegociador
          itemHeader={itemHeader}
          setItemSelecionado={setItemSelecionado}
          selecionado={itemSelecionado.titulo === itemHeader.titulo}
          className={estilosGrid.gridItem}
          key={`item-header-tabela-demandas-negociador-${itemHeader.titulo}`}
        />
      ))}
    </header>
  );
};

export default HeaderTabelaDemandasNegociador;
