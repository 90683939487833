import React from 'react';

import ArrowForward from '@material-ui/icons/ArrowForward';
import { makeStyles } from '@material-ui/core/styles';

import { spacingInPixels } from '../../functions/materialUtils';

import DisablingTooltip from '../tooltip/DisablingTooltip';

import ButtonTextTransformNone from './ButtonTextTransformNone';

export type ConfirmarDialogButtonProps = {
  textoBotao: string;
  onClick: () => void;
  disabled: boolean;
};

const useStyles = makeStyles((theme) => ({
  botao: {
    height: spacingInPixels(theme, 6),
    padding: theme.spacing(0, 3, 0, 3),
    borderRadius: '25px',
  },
}));

const ConfirmarDialogButton = ({
  textoBotao,
  onClick,
  disabled,
}: ConfirmarDialogButtonProps): JSX.Element => {
  const classes = useStyles();

  return (
    <DisablingTooltip title={textoBotao}>
      <ButtonTextTransformNone
        disabled={disabled}
        variant="contained"
        color="primary"
        onClick={onClick}
        className={classes.botao}
        endIcon={<ArrowForward />}
      >
        {textoBotao}
      </ButtonTextTransformNone>
    </DisablingTooltip>
  );
};

export default ConfirmarDialogButton;
