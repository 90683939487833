import React from 'react';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import spacingInPixels from '../../../../shared/functions/materialUtils';
import msgConstants from '../../../../shared/constants/msgConstants';

const useStyles = makeStyles((theme) => ({
  mensagemPesquisaNaoEncontrada: {
    display: 'flex',
    height: spacingInPixels(theme, 4),
    alignItems: 'center',
    justifyContent: 'center',
  },
  texto: {
    color: theme.palette.cores.cinza[500],
    fontSize: 18,
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const MensagemPesquisaSemResultados = (): JSX.Element => {
  const classes = useStyles();

  return (
    <Box className={classes.mensagemPesquisaNaoEncontrada}>
      <Typography className={classes.texto}>
        {msgConstants.PESQUISA_NAO_RETORNOU_RESULTADOS}
      </Typography>
    </Box>
  );
};

export default MensagemPesquisaSemResultados;
