import React from 'react';
import { ChipRolesProps } from 'features/configuracoes/types';
import { ContainerChip } from '../../styles';
import ChipRoleUsuario from '../../components/ChipRoleUsuario';

const ChipRoles = ({
  negociadorAtivo,
  supervisorAtivo,
  ativo,
  setNegociadorAtivo,
  setSupervisorAtivo,
  configurarAcessos,
}: ChipRolesProps): JSX.Element => {
  return (
    <ContainerChip>
      <ChipRoleUsuario
        titulo="Negociador"
        ativo={negociadorAtivo}
        onClick={setNegociadorAtivo}
      />

      <ChipRoleUsuario
        titulo="Supervisor"
        ativo={supervisorAtivo}
        onClick={setSupervisorAtivo}
      />

      <ChipRoleUsuario
        titulo="Ativo"
        ativo={ativo}
        onClick={configurarAcessos}
      />
    </ContainerChip>
  );
};

export default ChipRoles;
