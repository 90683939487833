import { createReducer } from '@reduxjs/toolkit';
import NegociacaoStatus from 'shared/constants/NegociacaoStatus';

import { atualizarFiltroStatusAutomatizados } from './negociacoesAutomatizadasAction';

export type negociacoesAutomatizadasState = {
  statusSelecionados: Set<string>;
};

export const estadoInicial: Readonly<negociacoesAutomatizadasState> = {
  statusSelecionados: new Set([
    NegociacaoStatus.NovaNegociacao.toString(),
    NegociacaoStatus.EmAndamento.toString(),
    NegociacaoStatus.AguardarGestao.toString(),
    NegociacaoStatus.Concluida.toString(),
  ]),
};

const negociacoesAutomatizadasReducer = createReducer(
  estadoInicial,
  (builder) => {
    builder
      .addCase(atualizarFiltroStatusAutomatizados, (state, action) => ({
        ...state,
        statusSelecionados: new Set(action.payload),
      }))
      .addDefaultCase((state) => state);
  },
);

export default negociacoesAutomatizadasReducer;
