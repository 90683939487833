import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Drawer,
  IconButton,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { buscarColaboradoresNegociadores } from 'features/configuracoes/utils/configuracoes';
import { useDispatch } from 'react-redux';
import { ColaboradoresNegociadores } from 'features/configuracoes/api/configuracoesApiTypes';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';

import CloseIcon from '@material-ui/icons/Close';
import { Cancel } from '@material-ui/icons';

export type DrawerDemandasProps = {
  openDrawer: boolean;
  handleCloseDrawer: () => void;
  handleLimparCampos: () => void;
  selecionarNegociador?: (selectedOptions: ColaboradoresNegociadores[]) => void;
  demandasNegociador: () => void;
  resetarOpcoesNegociadores: boolean;
  loadingDemanda: boolean;
};

const useStyles = makeStyles(() => ({
  containerAutocomplete: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '144px',
  },
  customPopupButton: {
    animation: 'none !important',
    transform: 'none !important',
    transition: 'none !important',
    '&.MuiAutocomplete-popupIndicator': {
      transform: 'none',
    },
  },
  text: {
    fontSize: '24px',
    paddingRight: '33px',
    paddingLeft: '54px',
  },
  boxTransferirCargas: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  autoComplete: {
    width: '460px',
  },
  buttonTransferencia: {
    width: '213px',
    height: '40px',
    borderRadius: '100px',
    fontSize: '16px',
    fontWeight: 400,
    textTransform: 'none',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:disabled': {
      backgroundColor: 'blue',
      color: 'white',
      opacity: 0.3,
    },
  },
  buttonCancelar: {
    width: '170px',
    height: '40px',
    borderRadius: '100px',
    fontSize: '16px',
    fontWeight: 400,
    textTransform: 'none',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  drawerDocked: {
    boxShadow: ' 0px -6px 40px 0px #00000040',
  },
  iconX: {
    marginRight: '27px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const DrawerDemandas = ({
  openDrawer,
  handleLimparCampos,
  selecionarNegociador,
  demandasNegociador,
  resetarOpcoesNegociadores,
  loadingDemanda,
}: DrawerDemandasProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [
    colaboradores,
    setColaboradores,
  ] = useState<ColaboradoresNegociadores>();
  const [valorIdColaborador, setValorIdColaborador] = useState<
    number | undefined
  >();
  const [resetKey, setResetKey] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState<
    ColaboradoresNegociadores[]
  >([]);

  useEffect(() => {
    buscarColaboradoresNegociadores(setColaboradores, dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (resetarOpcoesNegociadores) {
      setSelectedOptions([]);
    }
  }, [resetarOpcoesNegociadores]);

  const handleAutocompleteChange = (
    event: React.ChangeEvent<unknown>,
    newValue: ColaboradoresNegociadores[] | [],
  ) => {
    if (Array.isArray(newValue) && newValue.length > 0) {
      const recentItem = newValue[newValue.length - 1];
      const { id } = recentItem;
      setValorIdColaborador(id);
      setSelectedOptions([recentItem]);
      if (selecionarNegociador) {
        selecionarNegociador([recentItem]);
      }
    } else {
      setSelectedOptions([]);
      setValorIdColaborador(undefined);
    }
  };

  const limparFecharDrawer = () => {
    setSelectedOptions([]);
    setValorIdColaborador(undefined);
    setResetKey((prevKey) => prevKey + 1);
    handleLimparCampos();
  };

  const handleDelete = () => {
    setSelectedOptions([]);
    setValorIdColaborador(undefined);
  };

  return (
    <Drawer
      anchor="bottom"
      variant="persistent"
      open={openDrawer}
      classes={{
        paperAnchorDockedBottom: classes.drawerDocked,
      }}
      BackdropProps={{ invisible: true }}
      ModalProps={{
        keepMounted: true,
        disableBackdropClick: true,
      }}
    >
      <Box className={classes.containerAutocomplete}>
        <Box className={classes.boxTransferirCargas}>
          <Typography className={classes.text}>Qual negociador?</Typography>
          <Autocomplete
            multiple
            key={resetKey}
            onChange={handleAutocompleteChange}
            value={selectedOptions}
            closeIcon={false}
            popupIcon={false}
            className={classes.autoComplete}
            options={Array.isArray(colaboradores) ? colaboradores : []}
            getOptionLabel={(colaborador) =>
              colaborador.nome
                ? colaborador.nome
                    .toLowerCase()
                    .split(' ')
                    .map(
                      (texto: string) =>
                        texto.charAt(0).toUpperCase() + texto.slice(1),
                    )
                    .join(' ')
                : ''
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Selecione o negociador"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  style: { paddingRight: 0, top: '-4px', caretColor: 'white' },
                  endAdornment: (
                    <IconButton>
                      <SearchRoundedIcon />
                    </IconButton>
                  ),
                }}
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={option.idColaborador}
                  label={option.nome}
                  {...getTagProps({ index })}
                  onDelete={() => {
                    handleDelete();
                  }}
                  deleteIcon={<Cancel />}
                />
              ))
            }
          />
        </Box>
        <Box display="flex" gridGap="15px" marginRight="100px">
          <Button
            className={classes.buttonCancelar}
            variant="contained"
            color="default"
            onClick={limparFecharDrawer}
          >
            Cancelar
          </Button>
          <Button
            className={classes.buttonTransferencia}
            variant="contained"
            color="primary"
            disabled={!valorIdColaborador}
            onClick={demandasNegociador}
          >
            {loadingDemanda ? (
              <CircularProgress color="inherit" size={24} />
            ) : (
              'Ver demandas'
            )}
          </Button>
        </Box>
        <Box className={classes.iconX}>
          <CloseIcon onClick={limparFecharDrawer} color="disabled" />
        </Box>
      </Box>
    </Drawer>
  );
};

export default DrawerDemandas;
