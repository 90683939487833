import React from 'react';
import BoxPagina from '../../shared/components/box/BoxPagina';
import FeatureAprovacoesSupervisor from '../../features/aprovacoesSupervisor';

const AprovacoesSupervisor = (): JSX.Element => {
  return (
    <BoxPagina component="section">
      <FeatureAprovacoesSupervisor />
    </BoxPagina>
  );
};

export default AprovacoesSupervisor;
