import { Skeleton } from '@material-ui/lab';
import gridStyles from 'features/demandasAndamento/styles/gridTabelaDemandasAndamentoStyle';
import React from 'react';

const SkeletonTabelaDemandasAndamento = (): JSX.Element => {
  const estilosGrid = gridStyles();
  const linhas = 5;
  const colunas = 18;
  return (
    <section className={estilosGrid.gridContainer}>
      {new Array(linhas * colunas).fill(null).map((_, index) => (
        <Skeleton
          width="90%"
          key={`skeleton-demanda-negociacao-${index + 1}`}
          className={estilosGrid.gridItem}
        />
      ))}
    </section>
  );
};

export default SkeletonTabelaDemandasAndamento;
