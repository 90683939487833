import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconeNotificacao from 'assets/icones/IconeNotificacao';
import { RootState } from '../../../store/reducer';

import {
  fecharSnackbar,
  fecharSnackbarNotificacao,
} from './redux/snackbarAction';

import FeedbackSnackbar from './FeedbackSnackbar';
import NotificacaoSnackbar from './NotificacaoSnackbar';

const SnackbarLateral = (): JSX.Element => {
  const {
    mensagem,
    tipo,
    aberto,
    tempoOcultacao,
    tituloFechar,
    notificacaoSnackBar,
    quantidade,
    onClose,
    alturaTela,
  } = useSelector((state: RootState) => state.snackbarReducer);

  const dispatch = useDispatch();

  const [exibirNotificacao, setExibirNotificacao] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setExibirNotificacao(true);
    }, 6000);

    return () => clearTimeout(timer);
  }, []);

  if (notificacaoSnackBar && quantidade && exibirNotificacao) {
    return (
      <NotificacaoSnackbar
        open={aberto}
        titulo={`Você tem (${quantidade}) nova negociação transferida`}
        mensagem={mensagem}
        icone={<IconeNotificacao />}
        nameButton="Entendi"
        autoHideDuration={tempoOcultacao}
        onClose={() => {
          dispatch(fecharSnackbarNotificacao());
          if (onClose) {
            onClose();
          }
        }}
      />
    );
  }

  return (
    <FeedbackSnackbar
      severity={tipo}
      open={aberto}
      autoHideDuration={tempoOcultacao}
      closeTitle={tituloFechar}
      onClose={() => {
        dispatch(fecharSnackbar());
        if (onClose) {
          onClose();
        }
      }}
      mensagem={mensagem}
      alturaTela={alturaTela}
    />
  );
};

export default SnackbarLateral;
