import * as React from 'react';
import { SVGProps } from 'react';

const IconeChat = ({
  width,
  height,
  ...outros
}: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width={width || 74}
      height={height || 74}
      viewBox="0 0 74 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...outros}
    >
      <path
        d="M58.5833 6.16666H15.4166C12.9634 6.16666 10.6106 7.14122 8.87589 8.87593C7.14118 10.6106 6.16663 12.9634 6.16663 15.4167V46.25C6.16663 48.7032 7.14118 51.056 8.87589 52.7907C10.6106 54.5254 12.9634 55.5 15.4166 55.5H51.1525L62.5608 66.9392C62.8489 67.2249 63.1906 67.451 63.5662 67.6045C63.9419 67.7579 64.3442 67.8357 64.75 67.8333C65.1544 67.8438 65.5557 67.7593 65.9216 67.5867C66.4847 67.3554 66.9667 66.9625 67.3069 66.4577C67.6471 65.9529 67.8303 65.3587 67.8333 64.75V15.4167C67.8333 12.9634 66.8587 10.6106 65.124 8.87593C63.3893 7.14122 61.0365 6.16666 58.5833 6.16666ZM61.6666 57.3192L54.6058 50.2275C54.3177 49.9417 53.976 49.7156 53.6003 49.5622C53.2247 49.4088 52.8224 49.331 52.4166 49.3333H15.4166C14.5989 49.3333 13.8146 49.0085 13.2364 48.4302C12.6581 47.852 12.3333 47.0677 12.3333 46.25V15.4167C12.3333 14.5989 12.6581 13.8147 13.2364 13.2364C13.8146 12.6582 14.5989 12.3333 15.4166 12.3333H58.5833C59.401 12.3333 60.1853 12.6582 60.7635 13.2364C61.3418 13.8147 61.6666 14.5989 61.6666 15.4167V57.3192Z"
        fill="#BDBDBD"
      />
    </svg>
  );
};

export default IconeChat;
