import React from 'react';
import BoxPagina from 'shared/components/box/BoxPagina';
import Detalhes from 'features/detalhesAprovacaoSupervisor/DetalhesAprovacaoSupervisor';

const DetalhesAprovacaoSupervisor = (): JSX.Element => {
  return (
    <BoxPagina component="section">
      <Detalhes />
    </BoxPagina>
  );
};

export default DetalhesAprovacaoSupervisor;
