import { ConexaoAPI } from '../../../shared/classes/conexaoAPI';

import { ObterNotificacoesResponseType } from '../types/notificacoes';

const URL_NOTIFICACOES = `${process.env.REACT_APP_URL_API_BASE}/notificacoes`;

export const obterNotificacoes = async (
  pagina: number,
  isLido: boolean,
): Promise<ObterNotificacoesResponseType> => {
  const URL = `${URL_NOTIFICACOES}?pagina=${pagina}&isLido=${isLido}`;
  const conexao = new ConexaoAPI(URL, 'get');
  const conectar = conexao.gerarFuncaoConectarRefreshToken<ObterNotificacoesResponseType>();
  const resposta = await conectar();
  return resposta.data;
};

export const atualizarNotificacao = async (
  idNotificacao: number,
): Promise<void> => {
  const URL = `${URL_NOTIFICACOES}/${idNotificacao}`;
  const conexao = new ConexaoAPI(URL, 'patch');
  conexao.adicionarBody({
    visualizada: true,
  });
  const conectar = conexao.gerarFuncaoConectarRefreshToken<void>();
  const resposta = await conectar();
  return resposta.data;
};

export const limparNotificacao = async (): Promise<void> => {
  const URL = `${URL_NOTIFICACOES}`;
  const conexao = new ConexaoAPI(URL, 'patch');
  const conectar = conexao.gerarFuncaoConectarRefreshToken<void>();
  const resposta = await conectar();
  return resposta.data;
};
