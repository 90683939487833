import { Box, makeStyles, Typography } from '@material-ui/core';
import { ItemHeaderTabelaDemandasAndamento as ItemHeaderType } from 'features/demandasAndamento/constants/itensHeaderTabelaDemandasAndamento';
import React, { useState } from 'react';
import { KeyboardArrowDownRounded } from '@material-ui/icons';
import CustomTooltip from 'shared/components/tooltip/CustomTooltip';

const useStyles = makeStyles((theme) => ({
  titulo: {
    fontSize: 16,
    fontWeight: 500,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '90%',
  },
  subtitulo: {
    fontSize: 12,
    fontWeight: 500,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '10%',
  },
  ordenacao: {
    '&[data-ordenacao]': {
      cursor: 'pointer',
    },
    '&[data-ordenacao]:hover': {
      backgroundColor: theme.palette.cores.cinza[50],
    },
  },
  iconeSelecionado: {
    color: theme.palette.cores.cinza[500],
    '&[data-selecionado=true]': {
      color: theme.palette.cores.azul[500],
    },
    '&[data-crescente=true]': {
      transform: 'rotate(180deg)',
    },
  },
}));

export interface ItemHeaderTabelaDemandasAndamentoProps {
  itemHeader: ItemHeaderType;
  selecionado: boolean;
  setItemSelecionado: React.Dispatch<React.SetStateAction<ItemHeaderType>>;
  className?: string;
}

const ItemHeaderTabelaDemandasAndamento = ({
  itemHeader,
  setItemSelecionado,
  selecionado,
  className,
}: ItemHeaderTabelaDemandasAndamentoProps): JSX.Element => {
  const classes = useStyles();
  const [crescente, setCrescente] = useState(itemHeader.crescente);

  return (
    <CustomTooltip title={itemHeader.titulo}>
      <Box
        className={`${className} ${classes.ordenacao}`}
        data-ordenacao={itemHeader.ordenacao}
        onClick={() => {
          setItemSelecionado({ ...itemHeader, crescente: !crescente });
          setCrescente(!crescente);
        }}
        title={`${itemHeader.titulo} ${itemHeader?.subtitulo || ''}`}
      >
        <Typography className={classes.titulo}>{itemHeader.titulo}</Typography>

        {itemHeader.subtitulo && (
          <Typography className={classes.subtitulo}>
            {itemHeader.subtitulo}
          </Typography>
        )}
        {itemHeader.ordenacao && (
          <KeyboardArrowDownRounded
            className={classes.iconeSelecionado}
            data-crescente={crescente}
            data-selecionado={selecionado}
          />
        )}
      </Box>
    </CustomTooltip>
  );
};

export default ItemHeaderTabelaDemandasAndamento;
