import { Dispatch } from 'redux';
import PropostaStatus from '../../../shared/constants/PropostaStatus';
import TipoMensagemProposta from '../../../shared/constants/TipoMensagemProposta';
import { enviarMensagem } from './chatResposta';

export const onClickBotaoEnviarRecusa = async (
  chatIdNegociacao: number | undefined,
  chatIdProposta: number | undefined,
  texto: string | undefined,
  valor: number | undefined,
  motivoRecusa: number | undefined,
  setEnviando: (erro: boolean) => void,
  dispatch: Dispatch,
): Promise<void> => {
  await enviarMensagem({
    chatIdNegociacao,
    chatIdProposta,
    valor,
    motivo: texto,
    motivoRecusa,
    tipoMensagem: TipoMensagemProposta.Recusa,
    propostaStatus: PropostaStatus.RECUSA_NEGOCIADOR,
    setEnviando,
    dispatch,
  });
};
