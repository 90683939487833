/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useRef, useLayoutEffect, useMemo } from 'react';

const useDebounce = <T extends unknown[], U>(
  callback: (...args: T) => PromiseLike<U> | U,
  delay: number,
) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const callbackRef = useRef(callback);

  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  return useMemo(() => {
    return (...args: T) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        callbackRef.current(...args);
      }, delay);
    };
  }, [delay]);
};

export default useDebounce;
