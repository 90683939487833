import { Box, makeStyles, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import IconeLocalizacao from '../../../assets/icones/IconeLocalizacao';
import IconePin from '../../../assets/icones/IconePin';
import IconeSeta from '../../../assets/icones/IconeSeta';
import spacingInPixels from '../../../shared/functions/materialUtils';

export type InformacoesRotaType = {
  cidadePrincipal?: string;
  filialDestino?: string;
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.palette.cores.branco,
    padding: spacingInPixels(theme, 2),
  },
  containerFiliais: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  containerTrajeto: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textoFiliais: {
    fontSize: '12px',
    fontWeight: 400,
    color: theme.palette.cores.cinza[900],
  },
  circulo: {
    backgroundColor: theme.palette.cores.azulMartins[700],
    width: spacingInPixels(theme, 1.5),
    height: spacingInPixels(theme, 1.5),
    borderRadius: spacingInPixels(theme, 2),
    position: 'relative',
  },
  pontilhado: {
    flex: 1,
    height: 0,
    botderBottomWidth: spacingInPixels(theme, 0.5),
    borderBottomColor: theme.palette.cores.azulMartins[700],
    borderBottomStyle: 'dotted',
  },
  icone: {
    position: 'absolute',
    top: `-${spacingInPixels(theme, 3)}`,
    left: `-${spacingInPixels(theme, 0.5)}`,
  },
}));

const InformacoesRota = ({
  cidadePrincipal = '-',
  filialDestino = '-',
}: InformacoesRotaType): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Box className={classes.container}>
      <Box className={classes.containerTrajeto}>
        <span className={classes.circulo}>
          <IconeLocalizacao
            className={classes.icone}
            width={spacingInPixels(theme, 2.4)}
            height={spacingInPixels(theme, 2.4)}
          />
        </span>
        <span className={classes.pontilhado} />
        <span className={classes.circulo}>
          <IconePin
            className={classes.icone}
            width={spacingInPixels(theme, 2.4)}
            height={spacingInPixels(theme, 2.4)}
          />
        </span>
      </Box>

      <Box className={classes.containerFiliais}>
        <Typography className={classes.textoFiliais}>
          {filialDestino}
        </Typography>
        <IconeSeta />
        <Typography className={classes.textoFiliais}>
          {cidadePrincipal}
        </Typography>
      </Box>
    </Box>
  );
};

export default InformacoesRota;
