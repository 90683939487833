import { DadoSkeleton } from 'shared/components/skeleton/CustomSkeleton';

export const dadosSkeleton: DadoSkeleton[] = [
  { borderRadius: 50, width: 20 },
  { borderRadius: 4, width: '80%' },
  { borderRadius: 4, width: '90%' },
  { borderRadius: 4, width: '90%' },
  { borderRadius: 4, width: '60%' },
  { borderRadius: 4, width: '90%' },
  { borderRadius: 4, width: '90%' },
  { borderRadius: 4, width: '70%' },
  { borderRadius: 4, width: '60%' },
  { borderRadius: 4, width: '70%' },
  { borderRadius: 4, width: '90%' },
];
