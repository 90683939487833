import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import IconeAlarmFreteTabelado from 'assets/icones/IconeAlarmFreteTabelado';
import { QueryBuilder } from '@material-ui/icons';
import { useStyles } from './styles';

export type TemporizadorProps = {
  tempo?: number;
  tempoData?: string;
  validacao?: boolean;
  tabelaFreteTabelado?: boolean;
};
const Temporizador = ({
  tempo,
  tempoData,
  validacao,
  tabelaFreteTabelado,
}: TemporizadorProps): JSX.Element => {
  const styles = useStyles();

  const [tempoRestante, setTempoRestante] = useState<number | null>(null);
  const [validacaoCor, setValidacaoCor] = useState<boolean | null>();

  useEffect(() => {
    const dataAtual = new Date();
    if (tempoData) {
      const dataCarregamento = new Date(tempoData);

      if (tempo !== undefined) {
        const diferencaEmMinutos = Math.floor(
          (dataAtual.getTime() - dataCarregamento.getTime()) / 60000,
        );

        if (diferencaEmMinutos < tempo) {
          const minutosRestantes = tempo - diferencaEmMinutos;

          if (minutosRestantes <= 60) {
            setValidacaoCor(true);
          }
        }
      }
    }
  }, [tempo, tempoData]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (tempoData && tempo) {
      const dataInicio = new Date(tempoData);
      dataInicio.setMinutes(dataInicio.getMinutes() + tempo);

      const interval = setInterval(() => {
        const agora = new Date();
        const diferenca = dataInicio.getTime() - agora.getTime();

        if (diferenca <= 0) {
          clearInterval(interval);
          setTempoRestante(0);
        } else {
          const minutos = Math.floor(diferenca / 60000);
          const segundos = Math.floor((diferenca % 60000) / 1000);
          setTempoRestante(minutos * 60 + segundos);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [tempo, tempoData]);

  const formatoTempo = () => {
    if (tempoRestante !== null) {
      const horas = Math.floor(tempoRestante / 3600);
      const minutos = Math.floor((tempoRestante % 3600) / 60);
      return `${horas
        .toString()
        .padStart(2, '0')}:${minutos.toString().padStart(2, '0')}`;
    }
    return '00:00';
  };

  return (
    <>
      {tabelaFreteTabelado ? (
        <Box className={styles.boxContentTemporizadorInvisivel}>
          <QueryBuilder
            style={{
              fontSize: '15px',
              marginRight: '5px',
              color: '#F44336',
            }}
          />
          <Typography className={styles.textInvisivel}>
            {formatoTempo()}
          </Typography>
        </Box>
      ) : (
        <Box
          className={
            validacao || validacaoCor
              ? styles.boxTemporizadorVermelho
              : styles.boxTemporizador
          }
        >
          <Box className={styles.boxContentTemporizador}>
            <IconeAlarmFreteTabelado style={{ marginRight: '5px' }} />
            <Typography className={styles.textTemporizador}>
              {formatoTempo()}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Temporizador;
