enum RemetenteMensagemProposta {
  Motorista = 0,
  Negociador = 1,
  Supervisor = 2,
}

export type RemetenteMensagemPropostaType = RemetenteMensagemProposta;

export const verificarRemetenteNegociador = (
  rementente: RemetenteMensagemPropostaType | undefined,
): boolean => rementente === RemetenteMensagemProposta.Negociador;

export const verificarRemetenteSupervisor = (
  rementente: RemetenteMensagemPropostaType | undefined,
): boolean => rementente === RemetenteMensagemProposta.Supervisor;

export default RemetenteMensagemProposta;
