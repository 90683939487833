import { Dispatch } from 'redux';

import { atualizarNotificacao } from '../api/notificacoesManager';
import { NotificacaoType } from '../types/notificacoes';
import { fecharNotificacoes } from './notificacoes';

export const onClickNotificacao = async (
  notificacao: NotificacaoType,
  push: (link: string) => void,
  dispatch: Dispatch,
): Promise<void> => {
  if (!notificacao.visualizada) {
    await atualizarNotificacao(notificacao.idNotificacao);
  }
  fecharNotificacoes(dispatch);
  push(notificacao.link);
};
