import { ConexaoAPI } from 'shared/classes/conexaoAPI';
import {
  DemandaColaborador,
  DemandasColaboradorResponse,
  DemandasNegociadorResponse,
} from './listagemNegociadoresApiTypes';

const obterUrlBuscarListagemNegociadores = (): string =>
  `${process.env.REACT_APP_URL_API_BASE}/dashboards/negociadores/demanda-diaria`;

const obterUrlBuscarListagemDemandasNegociador = (
  idNegociador: number,
): string =>
  `${process.env.REACT_APP_URL_API_BASE}/dashboards/negociadores/${idNegociador}/demanda-diaria`;

export const obterListagemNegociador = async (): Promise<
  DemandaColaborador[]
> => {
  const url = obterUrlBuscarListagemNegociadores();
  const conexaoApi = new ConexaoAPI(url, 'get');
  const conectar = conexaoApi.gerarFuncaoConectar<DemandasColaboradorResponse>();
  const response = await conectar();
  return response?.data?.negociadores;
};

export const obterDemandasNegociador = async (
  idNegociador: number,
): Promise<DemandasNegociadorResponse> => {
  const url = obterUrlBuscarListagemDemandasNegociador(idNegociador);
  const conexaoApi = new ConexaoAPI(url, 'get');
  const conectar = conexaoApi.gerarFuncaoConectar<DemandasNegociadorResponse>();
  const response = await conectar();
  return response?.data;
};
