import { PostPrecificacaoResponse } from '../types/TypepostPrecificacao';
import {
  postCargasService,
  postPrecificacaoService,
} from './uploadSimuladorService';

export const postCargas = async (body: File): Promise<void> =>
  postCargasService(body);

export const postPrecificacao = async (
  body: File,
): Promise<PostPrecificacaoResponse> => postPrecificacaoService(body);

export default { postCargas, postPrecificacao };
