import { EventoProposta } from 'features/detalhesAprovacaoSupervisor/api/detalhesAprovacaoSupervisorTypes';
import TipoMensagemProposta from 'shared/constants/TipoMensagemProposta';
import { Mensagem } from '../api/chatNegociacaoApiTypes';

export const filtrarMensagensHistorico = (mensagens: Mensagem[]): Mensagem[] =>
  mensagens.filter((mensagem) =>
    [
      TipoMensagemProposta.Inicial,
      TipoMensagemProposta.Aceite,
      TipoMensagemProposta.Contraproposta,
      TipoMensagemProposta.AvaliacaoSupervisor,
      TipoMensagemProposta.AceiteSupervisor,
      TipoMensagemProposta.RecusaSupervisor,
      TipoMensagemProposta.NovaMetaSupervisor,
      TipoMensagemProposta.AprovacaoConclusaoSupervisor,
    ].includes(mensagem.tipo),
  );

export const mapearMensagemEventoProposta = (
  mensagens: Mensagem[],
): EventoProposta[] => {
  return mensagens.map(
    (mensagem) =>
      ({
        idMensagemProposta: mensagem.id || 0,
        tipoMensagemProposta: mensagem.tipo,
        remetente: mensagem.remetente,
        valorContraproposta: mensagem.valorContraproposta || 0,
        dataEnvio: mensagem.dataEnvio,
      } as EventoProposta),
  );
};
