import React from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { AutocompletePropostaFilialProps } from 'features/configuracoes/types/percentualPropostaFilial';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import theme from 'theme';

const AutocompletePropostaFilial = ({
  filiais,
  classe,
  setFilialAlvo,
}: AutocompletePropostaFilialProps): JSX.Element => {
  return (
    <>
      <Autocomplete
        options={filiais}
        getOptionLabel={(option) => `${option.descricao}`}
        onChange={(_event, value) => (value ? setFilialAlvo(value) : null)}
        className={classe}
        popupIcon={
          <InputAdornment position="start">
            <SearchRoundedIcon
              style={{ color: theme.palette.cores.cinza[500] }}
            />
          </InputAdornment>
        }
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label="Buscar CDA ou CDO"
              variant="outlined"
            />
          );
        }}
      />
    </>
  );
};

export default AutocompletePropostaFilial;
