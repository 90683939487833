import React from 'react';
import TipoMensagemProposta from '../../../../../shared/constants/TipoMensagemProposta';
import { ChatBalaoProps } from '../ChatBalao';
import TextoLivre from '../MensagemComum/TextoLivre';
import AceiteNegociador from './AceiteNegociador';
import ContrapropostaNegociador from './ContrapropostaNegociador';
import MensagemArquivo from './MensagemArquivo';
import MensagemInicial from './MensagemInicial';
import MensagemListaClientes from './MensagemListaClientes';
import MensagemRecusa from './MensagemRecusa';
import ReinicioNegociacaoNegociador from './ReinicioNegociacaoNegociador';

const MensagemNegociador = ({ mensagem }: ChatBalaoProps): JSX.Element => {
  switch (mensagem.tipo) {
    case TipoMensagemProposta.Inicial:
      return <MensagemInicial mensagem={mensagem} />;
    case TipoMensagemProposta.Aceite:
      return <AceiteNegociador mensagem={mensagem} />;
    case TipoMensagemProposta.TextoLivre:
      return <TextoLivre mensagem={mensagem} />;
    case TipoMensagemProposta.Recusa:
      return <MensagemRecusa motivoRecusa={mensagem.motivoRecusa} />;
    case TipoMensagemProposta.Arquivo:
      return <MensagemArquivo mensagem={mensagem} />;
    case TipoMensagemProposta.ReinicioNegociacao:
      return <ReinicioNegociacaoNegociador mensagem={mensagem} />;
    case TipoMensagemProposta.LiberacaoListaClientes:
    case TipoMensagemProposta.BloqueioListaClientes:
      return <MensagemListaClientes mensagem={mensagem} />;
    case TipoMensagemProposta.Contraproposta:
      return <ContrapropostaNegociador mensagem={mensagem} />;
    case TipoMensagemProposta.AvaliacaoSupervisor:
    default:
      return <></>;
  }
};

export default MensagemNegociador;
