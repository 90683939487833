import { ConexaoAPI } from '../../../shared/classes/conexaoAPI';

import { ResponseCargas, ResponseFiliais } from './listaCargasApiTypes';

export const obterCargas = async (
  queryString: string,
): Promise<ResponseCargas> => {
  const URL = `${process.env.REACT_APP_URL_API_BASE}/cargas${queryString}`;
  const conexao = new ConexaoAPI(URL, 'get');
  const conectar = conexao.gerarFuncaoConectarRefreshToken<ResponseCargas>();
  const resposta = await conectar();
  return resposta.data;
};

export const obterFiliais = async (): Promise<ResponseFiliais> => {
  const URL = `${process.env.REACT_APP_URL_API_BASE}/filiais`;
  const conexao = new ConexaoAPI(URL, 'get');
  const conectar = conexao.gerarFuncaoConectarRefreshToken<ResponseFiliais>();
  const resposta = await conectar();
  return resposta.data;
};
