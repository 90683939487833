import { Box } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import TipoValorContraproposta from '../../../features/chatNegociacao/constants/TipoValorContraproposta';
import {
  calcularValorProposto,
  validarCampoValorProposto,
} from '../../../features/chatNegociacao/utils/dadosContraproposta';
import InputValorContraproposta from '../../../features/chatNegociacao/views/ChatResposta/RespostaContraproposta/DadosContraproposta/InputValorContraproposta/InputValorContraproposta';
import SelectTipoValorContraproposta from '../../../features/chatNegociacao/views/ChatResposta/RespostaContraproposta/DadosContraproposta/InputValorContraproposta/SelectValorContraproposta';
import BadgesValor from '../badgesValor/BadgesValor';

export type EditarValorContrapropostaBadgesProps = {
  tipoValor: TipoValorContraproposta;
  setTipoValor: (e: number) => void;
  valorProposto: number | undefined;
  setValorProposto: (valor: number | undefined) => void;
  valorInput: number | undefined;
  setValorInput: (valor: number | undefined) => void;
  valorFreteTerceiro?: number;
  valorFrotaPropria?: number;
  valorEntregas?: number;
  valorMercadoria?: number;
  valorPeso?: number;
  valorQuilometragem?: number;
  erroInput?: boolean;
  setErroInput?: (invalido: boolean) => void;
  valorNovaMeta?: number;
};

const EditarValorContrapropostaBadges = ({
  tipoValor,
  setTipoValor,
  valorProposto,
  setValorProposto,
  valorInput,
  setValorInput,
  valorFreteTerceiro,
  valorFrotaPropria,
  valorEntregas,
  valorMercadoria,
  valorPeso,
  valorQuilometragem,
  erroInput,
  setErroInput,
  valorNovaMeta,
}: EditarValorContrapropostaBadgesProps): JSX.Element => {
  const [mensagemErro, setMensagemErro] = useState('');
  const [saveInicialValorInput, setSaveInicialValorInput] = useState<number>(
    valorInput || 0,
  );

  const obterValorReferente = useCallback(
    (tipo: TipoValorContraproposta): number | undefined => {
      switch (tipo) {
        case TipoValorContraproposta.PorcentagemFrotaPropria:
          return valorFrotaPropria;
        case TipoValorContraproposta.PorcentagemFreteTerceiro:
          return valorFreteTerceiro;
        case TipoValorContraproposta.PorcentagemMercadoria:
          return valorMercadoria;
        case TipoValorContraproposta.PorEntrega:
          return valorEntregas;
        case TipoValorContraproposta.PorKm:
          return valorQuilometragem;
        case TipoValorContraproposta.PorPeso:
          return valorPeso;
        case TipoValorContraproposta.ValorProposto:
        default:
          return 0;
      }
    },
    [
      valorEntregas,
      valorFreteTerceiro,
      valorFrotaPropria,
      valorMercadoria,
      valorPeso,
      valorQuilometragem,
    ],
  );

  const handleValidacaoInputValor = useCallback(
    (valor?: number) => {
      validarCampoValorProposto(
        setMensagemErro,
        setErroInput,
        valor,
        valorNovaMeta,
        valorFrotaPropria,
      );
    },
    [setErroInput, valorFrotaPropria, valorNovaMeta],
  );

  const setValorSemValidacao = useCallback(
    (novoValor: TipoValorContraproposta) => {
      setValorInput(novoValor);
      const novoValorProposto = calcularValorProposto(
        tipoValor,
        novoValor,
        obterValorReferente(tipoValor),
      );
      setValorProposto(novoValorProposto);
    },
    [obterValorReferente, setValorInput, setValorProposto, tipoValor],
  );

  const handleChangeInput = (novoValor: TipoValorContraproposta) => {
    setValorSemValidacao(novoValor);
    handleValidacaoInputValor(novoValor);
    if (tipoValor === TipoValorContraproposta.ValorProposto) {
      setSaveInicialValorInput(novoValor);
    }
  };

  const handleChangeTipo = useCallback(
    (novoTipo: TipoValorContraproposta) => {
      setTipoValor(novoTipo);
      if (novoTipo === TipoValorContraproposta.ValorProposto) {
        setValorInput(saveInicialValorInput);
      } else {
        setValorInput(0);
      }
    },
    [saveInicialValorInput, setTipoValor, setValorInput],
  );

  useEffect(() => {
    setValorSemValidacao(valorInput || 0);
  }, [setValorSemValidacao, valorInput]);

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <SelectTipoValorContraproposta
          value={tipoValor}
          onChange={handleChangeTipo}
        />
        <InputValorContraproposta
          tipo={tipoValor}
          valorInput={valorInput}
          handleInput={handleChangeInput}
          erro={erroInput}
          mensagemErro={mensagemErro}
        />
      </Box>
      <Box marginTop={2}>
        <BadgesValor
          valor={valorProposto}
          tipoValor={tipoValor}
          valorFreteTerceiro={valorFreteTerceiro || 0}
          valorFrotaPropria={valorFrotaPropria || 0}
          valorEntregas={valorEntregas}
          valorMercadoria={valorMercadoria}
          valorPeso={valorPeso}
          valorQuilometragem={valorQuilometragem}
          justificar="flex-start"
          condensado
        />
      </Box>
    </>
  );
};

export default EditarValorContrapropostaBadges;
