import React from 'react';

import { makeStyles } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { ChatBalaoProps } from '../ChatBalao';
import { converterDataApiParaDataChat } from '../../../utils/dataHora';
import { formatarNumeroParaStringMonetariaComSimbolo } from '../../../../../shared/functions/dinheiroUtils';
import ChatBalaoInformativo from '../../../components/ChatBalaoInformativo';
import TypographyChat from '../../TypographyChat';
import spacingInPixels from '../../../../../shared/functions/materialUtils';
import NomeGestor from './NomeGestor';

const useStyles = makeStyles((theme) => ({
  container: {
    width: 360,
    padding: spacingInPixels(theme, 1),
  },
  textoValor: {
    fontSize: '16px',
    fontWeight: 600,
    color: theme.palette.cores.azulMartins[700],
  },
  textoNegrito: {
    fontWeight: 600,
  },
  icone: {
    color: theme.palette.cores.amarelo[800],
  },
}));

const NovaMetaSupervisor = ({ mensagem }: ChatBalaoProps): JSX.Element => {
  const classes = useStyles();
  return (
    <>
      <ChatBalaoInformativo
        className={classes.container}
        icone={<InfoOutlined className={classes.icone} />}
      >
        <TypographyChat>
          O valor máximo aprovado foi de{' '}
          <span className={classes.textoValor}>
            {formatarNumeroParaStringMonetariaComSimbolo(
              mensagem.valorContraproposta,
            )}{' '}
          </span>
          às {converterDataApiParaDataChat(mensagem.dataEnvio)}.
        </TypographyChat>
        <TypographyChat>
          <span className={classes.textoNegrito}>Motivo: </span>
          {mensagem.texto || '-'}
        </TypographyChat>

        <NomeGestor nome={mensagem.nomeGestorAprovacao} />
      </ChatBalaoInformativo>
    </>
  );
};

export default NovaMetaSupervisor;
