import { Dispatch } from 'redux';
import { UsuarioInfo } from '../api/loginApiTypes';

import * as loginManager from '../api/loginManager';

import {
  atualizarPaginasAcesso,
  atualizarDadosUsuario,
  removerDadosUsuario,
} from './loginAction';

export const buscarDadosPermissaoUsuario = () => async (
  dispatch: Dispatch,
): Promise<void> => {
  await loginManager
    .buscarDadosPermissaoUsuario()
    .then((dadosPermissaoUsuario) => {
      dispatch(atualizarPaginasAcesso(dadosPermissaoUsuario));
    })
    .catch(() => {
      dispatch(atualizarPaginasAcesso(null));
    });
};

export const buscarDadosUsuario = () => async (
  dispatch: Dispatch,
): Promise<void> => {
  await loginManager
    .buscarDadosUsuario()
    .then((dadosUsuario) => {
      dispatch(atualizarDadosUsuario(dadosUsuario));
    })
    .catch(() => {
      dispatch(atualizarDadosUsuario(null));
    });
};

export const fazerLogout = () => async (dispatch: Dispatch): Promise<void> => {
  await loginManager.fazerLogout();
  dispatch(removerDadosUsuario());
};

export const fazerLogin = (usuarioInfo: UsuarioInfo) => async (
  dispatch: Dispatch,
): Promise<void> => {
  await loginManager
    .fazerLogin(usuarioInfo)
    .then(async () => buscarDadosUsuario()(dispatch));
};
