import IconeCaixa from 'assets/icones/IconeCaixa';
import IconeExclamacaoOctogono from 'assets/icones/IconeExclamacaoOctogono';
import IconeRelogio from 'assets/icones/IconeRelogio';
import React from 'react';
import { DadosCabecalho } from 'shared/components/tabelaDemandas/types/TabelaTypes';
import theme from 'theme';
import { ChaveOrdenacaoDetalhesDemandas } from './Constants';

const ItensHeaderDemandasAndamentoEPendentes: DadosCabecalho[] = [
  { conteudo: '', descricao: '' },
  { conteudo: 'SLA', descricao: 'SLA' },
  {
    conteudo: 'Negociador',
    descricao: 'Negociador',
    chave: ChaveOrdenacaoDetalhesDemandas.NEGOCIADOR,
  },
  {
    conteudo: 'Filial',
    descricao: 'Filial',
    chave: ChaveOrdenacaoDetalhesDemandas.FILIAL,
  },
  {
    conteudo: (
      <IconeCaixa
        width={18}
        height={18}
        fill={theme.palette.cores.cinza[800]}
      />
    ),
    descricao: 'Número de entregas',
    chave: ChaveOrdenacaoDetalhesDemandas.NUMERO_ENTREGAS,
  },
  { conteudo: 'Última proposta', descricao: 'Última proposta' },
  { conteudo: 'Alvo', descricao: 'Alvo' },
  {
    conteudo: (
      <IconeRelogio
        width={18}
        height={18}
        fill={theme.palette.cores.cinza[800]}
      />
    ),
    descricao: 'Cycle time',
  },
  {
    conteudo: (
      <IconeExclamacaoOctogono
        width={18}
        height={18}
        fill={theme.palette.cores.cinza[800]}
      />
    ),
    descricao: 'Número de pedidos de aprovação',
  },
  { conteudo: 'Cidade', descricao: 'Cidade' },
  { conteudo: 'Romaneio', descricao: 'Romaneio' },
];

export default ItensHeaderDemandasAndamentoEPendentes;
