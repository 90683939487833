import React, { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
  makeStyles,
} from '@material-ui/core';
import IconeSincronizar from 'assets/icones/IconeSincronizar';
import { transferirNegociacoes } from 'features/negociacoesAutomatizadas/api/negociacoesAutomatizadasService';
import { useDispatch } from 'react-redux';
import {
  abrirSnackbarErro,
  abrirSnackbarSucesso,
} from 'shared/components/snackbar/redux/snackbarAction';
import { ColaboradoresNegociadores } from 'features/configuracoes/api/configuracoesApiTypes';

const useStyles = makeStyles(() => ({
  containerBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '570px',
    height: '390px',
    boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    padding: '16px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontSize: '32px',
    fontWeight: 400,
    marginTop: '45px',
    marginBottom: '10px',
  },
  subTitle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 400,
    color: 'black',
  },
  negociadores: {
    backgroundColor: '#EEEEEE',
    padding: '6px 12px',
    borderRadius: '100px',
    fontWeight: 400,
    width: '170px',
    height: '37px',
  },
  buttonCancelar: {
    width: '137px',
    height: '40px',
    borderRadius: '100px',
    fontSize: '16px',
    fontWeight: 500,
    textTransform: 'none',
    boxShadow: 'none',
    border: 'none',
    color: '#616161',
    marginRight: '21px',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#fff',
    },
  },
  buttonConfirmar: {
    width: '229px',
    height: '40px',
    borderRadius: '100px',
    fontSize: '16px',
    fontWeight: 400,
    textTransform: 'none',
    boxShadow: 'none',
    backgroundColor: '#3EB96F',
    color: '#fff',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#3EB96F',
    },
  },
}));

interface TruncateTextProps {
  text: string;
  maxLength: number;
}

interface ModalTransferenciaNegociacaoProps {
  openModal: boolean;
  fecharModal: () => void;
  negociadorSelecionado: ColaboradoresNegociadores[];
  negociadorTransferencia: ColaboradoresNegociadores[];
  negociacoesIdTransferencia: { [key: number]: boolean };
  finalizarTransferencia: () => Promise<void>;
}

const ModalTransferenciaNegociacao = ({
  openModal,
  fecharModal,
  negociadorSelecionado,
  negociadorTransferencia,
  negociacoesIdTransferencia,
  finalizarTransferencia,
}: ModalTransferenciaNegociacaoProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const TruncateText = ({ text, maxLength }: TruncateTextProps) => {
    if (text.length <= maxLength) {
      return text;
    }

    const truncatedText = `${text.slice(0, maxLength)}...`;
    const isAllUpperCase = truncatedText === truncatedText.toUpperCase();
    const formattedText = isAllUpperCase
      ? truncatedText
          .toLowerCase()
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ')
      : truncatedText;

    return formattedText;
  };

  const dados: number[] = [];

  Object.keys(negociacoesIdTransferencia).forEach((index): void => {
    if (negociacoesIdTransferencia[Number(index)] === true) {
      dados.push(Number(index));
    }
  });

  const handleTranferenciaColaborador = async () => {
    try {
      setLoading(true);
      await Promise.all(
        dados.map(async (dadoId) => {
          return transferirNegociacoes(dadoId, negociadorTransferencia[0].id);
        }),
      );
      await finalizarTransferencia();
      fecharModal();
      setLoading(false);
      dispatch(
        abrirSnackbarSucesso({
          mensagem: 'Negociação transferida com sucesso',
          alturaTela: '110px',
        }),
      );
    } catch (e) {
      setLoading(false);
      dispatch(
        abrirSnackbarErro({
          mensagem: `${e}`,
        }),
      );
    }
  };

  return (
    <Modal open={openModal}>
      <Box className={classes.containerBox}>
        <Typography className={classes.title}>Atenção</Typography>
        <Typography className={classes.subTitle}>
          Esta ação não pode ser desfeita. Você está prestes a
          <span> transferir negociações para outro negociador</span>
        </Typography>
        <Box display="flex" marginTop="35px" alignItems="center">
          {negociadorSelecionado && negociadorSelecionado.length > 0 && (
            <Typography className={classes.negociadores}>
              {TruncateText({
                text: `${negociadorSelecionado[0]?.nome}`,
                maxLength: 15,
              })}
            </Typography>
          )}
          <IconeSincronizar style={{ margin: '0 20px' }} />
          {negociadorTransferencia && negociadorTransferencia.length > 0 && (
            <Typography className={classes.negociadores}>
              {TruncateText({
                text: `${negociadorTransferencia[0]?.nome}`,
                maxLength: 15,
              })}
            </Typography>
          )}
        </Box>
        <Box display="flex" marginTop="80px" alignItems="center">
          <Button
            onClick={fecharModal}
            className={classes.buttonCancelar}
            variant="outlined"
          >
            Cancelar
          </Button>
          <Button
            onClick={handleTranferenciaColaborador}
            className={classes.buttonConfirmar}
            variant="contained"
            disabled={loading}
          >
            {loading ? (
              <CircularProgress color="inherit" size={24} />
            ) : (
              'Confirmar transferência'
            )}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalTransferenciaNegociacao;
