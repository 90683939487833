import React from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import IconeSeta from '../../../../assets/icones/IconeSeta';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'inline-block',
    paddingLeft: '3rem',
    paddingTop: '10px',
  },
  iconeSetaBaixo: {
    transform: 'rotate(90deg)',
    '& + span': {
      color: theme.palette.cores.mostarda[800],
    },
    verticalAlign: 'middle',
  },
  iconeSetaParaCima: {
    transform: 'rotate(-90deg)',
    '& + span': {
      color: theme.palette.cores.verde[500],
    },
  },
  fonte: {
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '32px',
    marginLeft: '4px',
  },
}));

interface NegociacaoPorHoraProps {
  valor: number;
}

const NegociacaoPorHora = ({ valor }: NegociacaoPorHoraProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();

  const acimaValorCorte = valor > 2;

  const corIconeSeta = acimaValorCorte
    ? theme.palette.cores.verde[500]
    : theme.palette.cores.mostarda[800];

  return (
    <div className={classes.root}>
      <IconeSeta
        width={15}
        height={15}
        fill={corIconeSeta}
        className={
          acimaValorCorte ? classes.iconeSetaParaCima : classes.iconeSetaBaixo
        }
      />
      <span className={classes.fonte}>{valor}</span>
    </div>
  );
};

export default NegociacaoPorHora;
