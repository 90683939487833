import React from 'react';

import Box from '@material-ui/core/Box';

import Skeleton from '@material-ui/lab/Skeleton';

const SkeletonListaMotoristas = (): JSX.Element => {
  return (
    <>
      <Box paddingY="10px" display="flex" flexDirection="column" width="90%">
        <Skeleton width="60%" height="30px" />

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
        >
          <Skeleton width="15%" height="30px" />
          <Skeleton width="15%" height="30px" />
          <Skeleton width="15%" height="30px" />
          <Skeleton width="15%" height="30px" />
        </Box>
      </Box>

      <Box paddingY="10px" display="flex" flexDirection="column" width="90%">
        <Skeleton width="80%" height="30px" />

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
        >
          <Skeleton width="20%" height="30px" />
          <Skeleton width="20%" height="30px" />
          <Skeleton width="20%" height="30px" />
          <Skeleton width="20%" height="30px" />
        </Box>
      </Box>

      <Box paddingY="10px" display="flex" flexDirection="column" width="90%">
        <Skeleton width="40%" height="30px" />

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
        >
          <Skeleton width="15%" height="30px" />
          <Skeleton width="15%" height="30px" />
          <Skeleton width="15%" height="30px" />
          <Skeleton width="15%  " height="30px" />
        </Box>
      </Box>
    </>
  );
};

export default SkeletonListaMotoristas;
