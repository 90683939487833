import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PainelPadrao from 'features/indicadores/components/PainelPadrao';
import { NegociacaoAtendimento } from 'features/indicadores/api/indicadoresApiTypes';
import { useLoading } from 'shared/customHooks/useLoading';
import { PeriodoFiltroIndicadores } from 'features/indicadores/constants/Constants';
import SkeletonContainerGenerico from 'features/indicadores/components/SkeletonContainerGenerico';
import { buscarDadosAtendimentos } from 'features/indicadores/utils/atendimento';
import ConteudoAtendimento from 'features/indicadores/views/conteudoGeral/atendimento/ConteudoAtendimento';

const ContainerAtendimento = (): JSX.Element => {
  const dispatch = useDispatch();

  const [indicadoresAtendimento, setIndicadoresAtendimento] = useState<
    NegociacaoAtendimento[]
  >([]);
  const [erro, setErro] = useState(false);
  const [periodoAtual, setPeriodoAtual] = useState(
    PeriodoFiltroIndicadores.WEEK,
  );
  const [valoresPeriodo, setValoresPeriodo] = useState<string[]>([]);

  const handleChangePeriodo = (periodo: string | number) => {
    setPeriodoAtual(periodo as PeriodoFiltroIndicadores);
  };

  const buscaDemandasCallback = useCallback(
    async () =>
      buscarDadosAtendimentos(
        setIndicadoresAtendimento,
        setValoresPeriodo,
        setErro,
        periodoAtual,
      ),
    [periodoAtual],
  );

  const [carregando, buscarDemandasLoading] = useLoading(buscaDemandasCallback);

  const handleClickTentarNovamente = () => {
    buscarDemandasLoading();
  };

  useEffect(() => {
    buscarDemandasLoading();
  }, [dispatch, buscarDemandasLoading]);

  return (
    <section>
      <PainelPadrao titulo="Atendimento" onChangePeriodo={handleChangePeriodo}>
        {carregando ? (
          <SkeletonContainerGenerico
            apresentarPrimeiraBarra={false}
            apresentarFooterBarras
          />
        ) : (
          <ConteudoAtendimento
            erro={erro}
            onClickTentarNovamente={handleClickTentarNovamente}
            indicadoresAtendimento={indicadoresAtendimento}
            valoresPeriodo={valoresPeriodo}
          />
        )}
      </PainelPadrao>
    </section>
  );
};

export default ContainerAtendimento;
