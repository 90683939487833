import { obterApenasNumeros } from 'shared/functions/stringUtils';
import { IntervaloTempo } from 'shared/types/apiTypes';

export const converterStringParaIntervalo = (
  valor: string,
): IntervaloTempo | undefined => {
  const numeros = obterApenasNumeros(valor);

  if (numeros.length > 4 && numeros.substring(0, 1) !== '0')
    throw new Error('limite máximo de caracteres excedido');

  const minutos = numeros.substring(numeros.length - 2);
  const horas = numeros.substring(numeros.length - 4, numeros.length - 2);

  return {
    dias: 0,
    horas: Number(horas),
    minutos: Number(minutos),
    segundos: 0,
  };
};

const obterStringComZero = (numero: number): string => {
  const numeroString = numero.toString();
  return numeroString.length > 1 ? numeroString : `0${numeroString}`;
};

export const converterIntervaloParaString = ({
  dias,
  horas,
  minutos,
}: IntervaloTempo): string => {
  const quantidadeHorasPorDia = dias * 24;
  const quantidadeHorasTotal = quantidadeHorasPorDia + horas;

  return `${obterStringComZero(quantidadeHorasTotal)}h ${obterStringComZero(
    minutos,
  )}min`;
};

export const validarInputIntervalo = (
  intervalo: IntervaloTempo | undefined,
): boolean => Boolean(intervalo?.horas || intervalo?.minutos);

export const validarInputHorasVencimento = (
  intervalo: IntervaloTempo | undefined,
): boolean => {
  const quantidadeHoras =
    (intervalo?.horas || 0) + (intervalo?.minutos || 0) / 60;
  return validarInputIntervalo(intervalo) && quantidadeHoras < 12;
};
export const obterMensagemErroHorasVencimento = (
  intervalo: IntervaloTempo | undefined,
): string | undefined => {
  if (!validarInputIntervalo(intervalo)) return 'Campo obrigatório';

  if (!validarInputHorasVencimento(intervalo))
    return 'Deve ser menor do que 12h';

  return undefined;
};

export function processarHoras(horario: string): string[] | null {
  const horasNumeros = horario.replace(/\D/g, '');
  const horasLimitadas = horasNumeros.slice(0, 4);
  const partes = horasLimitadas.match(/.{1,2}/g);

  return partes || null;
}

export function removeAccents(str: string): string {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function converterFormatoHorario(horario: string): string {
  const [hora, minuto] = horario.split('h').map((part) => part.trim());

  const horaFormatada = hora.padStart(2, '0');
  const minutoFormatado = minuto.padStart(2, '0');

  return `${horaFormatada}:${minutoFormatado}:00`;
}
