export enum OpcoesAbasPaginaIndicadores {
  GERAL,
  NEGOCIADORES,
  FILIAL,
}

export enum PeriodoFiltroIndicadores {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  TRIMESTER = 'TRIMESTER',
  BIMESTRE = 'BIMESTRE',
  YEAR = 'YEAR',
}

export enum TipoValorIndicador {
  MONETARIO,
  PORCENTAGEM,
  UNIDADE,
  DISTANCIA,
  PESO,
}

export type DadosOpcaoIndicador = {
  descricao: string;
  chave: string;
  tipoValor: TipoValorIndicador;
};

export type ChaveIndicador =
  | 'despesaFrete'
  | 'faturamento'
  | 'ticketMedio'
  | 'percentualRelativoBruto'
  | 'valorAgregado'
  | 'valorPorEntrega'
  | 'valorPorPeso'
  | 'valorPorDistancia'
  | 'quantidadeEntrega'
  | 'peso'
  | 'distancia'
  | 'quantidadeViagens'
  | 'pesoPorEntrega';

export const opcoesIndicadores: Record<string, DadosOpcaoIndicador> = {
  despesaFrete: {
    descricao: 'Despesa de frete',
    chave: 'despesaFrete',
    tipoValor: TipoValorIndicador.MONETARIO,
  },
  faturamento: {
    descricao: 'Faturamento',
    chave: 'faturamento',
    tipoValor: TipoValorIndicador.MONETARIO,
  },
  ticketMedio: {
    descricao: 'Ticket médio',
    chave: 'ticketMedio',
    tipoValor: TipoValorIndicador.MONETARIO,
  },
  percentualRelativoBruto: {
    descricao: '% relativo bruto',
    chave: 'percentualRelativoBruto',
    tipoValor: TipoValorIndicador.PORCENTAGEM,
  },
  valorAgregado: {
    descricao: 'Valor agregado',
    chave: 'valorAgregado',
    tipoValor: TipoValorIndicador.MONETARIO,
  },
  valorPorEntrega: {
    descricao: 'Reais por entrega',
    chave: 'valorPorEntrega',
    tipoValor: TipoValorIndicador.MONETARIO,
  },
  valorPorPeso: {
    descricao: 'Reais por Kg',
    chave: 'valorPorPeso',
    tipoValor: TipoValorIndicador.MONETARIO,
  },
  valorPorDistancia: {
    descricao: 'Reais por Km',
    chave: 'valorPorDistancia',
    tipoValor: TipoValorIndicador.MONETARIO,
  },
  quantidadeEntrega: {
    descricao: 'Qnt de entregas',
    chave: 'quantidadeEntrega',
    tipoValor: TipoValorIndicador.UNIDADE,
  },
  peso: {
    descricao: 'Peso Kg',
    chave: 'peso',
    tipoValor: TipoValorIndicador.PESO,
  },
  distancia: {
    descricao: 'Distância Km',
    chave: 'distancia',
    tipoValor: TipoValorIndicador.DISTANCIA,
  },
  quantidadeViagens: {
    descricao: 'Qtd de viagens',
    chave: 'quantidadeViagens',
    tipoValor: TipoValorIndicador.UNIDADE,
  },
  pesoPorEntrega: {
    descricao: 'Peso por entrega',
    chave: 'pesoPorEntrega',
    tipoValor: TipoValorIndicador.PESO,
  },
};

export const mensagemErro =
  'Ops algo aconteceu, por algum motivo não conseguimos trazer as informações';
export const mensagemListaVazia =
  'Ops, parece que não existe informações para esse período';
