import { ConexaoAPI } from '../../../shared/classes/conexaoAPI';

import { DetalhesCargaResponse } from './detalhesCargaApiTypes';

const URL_BASE = process.env.REACT_APP_URL_API_BASE;

export const buscar = async (
  codigoCarga: string,
): Promise<DetalhesCargaResponse> => {
  const URL_BUSCAR_DADOS_CARGA = `${URL_BASE}/cargas/${codigoCarga}/entregas`;
  const conexao = new ConexaoAPI(URL_BUSCAR_DADOS_CARGA, 'get');
  const conectar = conexao.gerarFuncaoConectarRefreshToken<DetalhesCargaResponse>();
  const resposta = await conectar();
  return resposta.data;
};

export default buscar;
