import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, makeStyles } from '@material-ui/core';

import { Mensagem } from '../../../api/chatNegociacaoApiTypes';
import { RootState } from '../../../../../store/reducer';
import BotaoIconeDownload from '../../../../../shared/components/button/BotaoIconeDownload';
import { caminhoIconeImagemDownload } from '../../../../../shared/constants/CaminhoRecursos';
import { fazerDownloadImagemOperation } from '../../../redux/chatNegociacaoOperation';

type DownloadImagemChatProps = {
  mensagem: Mensagem;
};

const useStyles = makeStyles(() => ({
  container: {
    background:
      'radial-gradient(65.33% 65.33% at 50% 50%, rgba(204, 204, 204, 0) 0%, rgba(255, 255, 255, 0.7) 100%)',
    boxShadow: 'inset 0px 0px 24px 4px rgba(0, 0, 0, 0.08)',
    borderRadius: 4,
    height: 176,
    width: '100%',
  },
}));

const DownloadImagemChat = ({
  mensagem,
}: DownloadImagemChatProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const { chatIdNegociacao, chatIdProposta } = useSelector(
    (state: RootState) => state.chatNegociacaoReducer,
  );

  const baixarImagem = async () => {
    if (
      !chatIdNegociacao ||
      !chatIdProposta ||
      !mensagem.anexos ||
      !mensagem.id
    )
      return;

    setLoading(true);

    dispatch(
      fazerDownloadImagemOperation(
        chatIdNegociacao,
        chatIdProposta,
        mensagem.id,
        mensagem.anexos[0],
        () => setLoading(false),
      ),
    );
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={classes.container}
    >
      <BotaoIconeDownload
        onClick={baixarImagem}
        caminhoIcone={caminhoIconeImagemDownload}
        carregando={loading}
      />
    </Box>
  );
};

export default DownloadImagemChat;
