import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { useTheme } from '@material-ui/core/styles';

import { OrigemNegociacao } from 'features/informacoesNegociacao/api/informacoesNegociacaoApiTypes';
import { RootState } from '../../../../store/reducer';

import Button from '../../../../shared/components/button/ButtonTextTransformNone';
import spacingInPixels from '../../../../shared/functions/materialUtils';
import DisablingTooltip from '../../../../shared/components/tooltip/DisablingTooltip';
import { verificarCargaPlanilha } from '../../../../shared/constants/OrigemCarga';

const ButtonDetalhesCarga = (): JSX.Element => {
  const theme = useTheme();
  const history = useHistory();

  const { idNegociacao, negociacao } = useSelector(
    (state: RootState) => state.informacoesNegociacaoReducer,
  );

  const { ativandoAmbienteAssistido } = useSelector(
    (state: RootState) => state.minhasNegociacoesReducer,
  );

  const [botaoDesabilitado, setBotaoDesabilitado] = useState(true);

  useEffect(() => {
    setBotaoDesabilitado(verificarCargaPlanilha(negociacao?.carga.origem));
  }, [negociacao?.carga.origem]);

  const irParaDetalhesCarga = () => {
    if (negociacao?.origemNegociacao === OrigemNegociacao.freteTabelado) {
      history.push(
        `/negociacoes-automatizada/${idNegociacao}/carga/${negociacao?.carga.id}`,
      );
    } else {
      history.push(
        `/minhas-negociacoes/${idNegociacao}/carga/${negociacao?.carga.id}`,
      );
    }
  };

  return (
    <DisablingTooltip
      title="Detalhes da carga"
      disabled={botaoDesabilitado || ativandoAmbienteAssistido}
    >
      <Button
        aria-label="Detalhes da carga"
        disabled={botaoDesabilitado || ativandoAmbienteAssistido}
        variant="contained"
        color="primary"
        startIcon={<VisibilityOutlinedIcon />}
        onClick={irParaDetalhesCarga}
        style={{
          height: spacingInPixels(theme, 5),
          padding: theme.spacing(1, 3, 1, 3),
          borderRadius: spacingInPixels(theme, 3),
        }}
      >
        Detalhes da carga
      </Button>
    </DisablingTooltip>
  );
};

export default ButtonDetalhesCarga;
