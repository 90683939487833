import React from 'react';

import Box from '@material-ui/core/Box';

import Skeleton from '@material-ui/lab/Skeleton';

const SkeletonNegociador = (): JSX.Element => {
  return (
    <Box
      display="flex"
      alignItems="start"
      paddingLeft="35px"
      paddingTop="10px"
      paddingBottom="10px"
      justifyContent="flex-end"
    >
      <Skeleton variant="rect" width="320px" height="80px" />
      <Box paddingLeft="15px">
        <Skeleton variant="circle" width="33px" height="33px" />
        <Skeleton width="33px" height="12px" />
      </Box>
    </Box>
  );
};

export default SkeletonNegociador;
