export const formatarTelefone = (numero: string): string => {
  const contato = numero.replace(/\D/g, '');
  switch (contato.length) {
    case 11:
      return contato.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    case 10:
      return contato.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    default:
      return contato;
  }
};
