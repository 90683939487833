import * as React from 'react';
import { SVGProps } from 'react';
import { useTheme } from '@material-ui/core/styles';

const IconeQuadradoArredondado = ({
  fill,
  ...outros
}: SVGProps<SVGSVGElement>): JSX.Element => {
  const theme = useTheme();
  return (
    <svg
      width={16}
      height={14}
      fill={fill || 'none'}
      viewBox="0 0 8 8"
      {...outros}
    >
      <rect
        x={0.5}
        width={8}
        height={8}
        rx={2}
        fill={fill || theme.palette.cores.azulMartins[500]}
      />
    </svg>
  );
};

export default IconeQuadradoArredondado;
