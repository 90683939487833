import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import BotaoRubik from '../button/BotaoRubik';

export type ModalComumAcoesProps = {
  textoBotaoPrimario: string;
  callbackBotaoPrimario: () => void;
  botaoPrimarioDesativado: boolean;
  textoBotaoSecundario?: string;
  callbackBotaoSecundario?: () => void;
};

const useStyles = makeStyles(() => ({
  botao: {
    fontSize: 16,
  },
  botaoCancelar: {
    width: 168,
  },
  botaoEnviar: {
    width: 216,
  },
}));

const ModalComumAcoes = ({
  callbackBotaoPrimario,
  textoBotaoPrimario,
  botaoPrimarioDesativado,
  callbackBotaoSecundario,
  textoBotaoSecundario,
}: ModalComumAcoesProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      justifyContent="space-evenly"
      width="100%"
      marginBottom="16px"
    >
      {textoBotaoPrimario && callbackBotaoSecundario && (
        <BotaoRubik
          onClick={callbackBotaoSecundario}
          variant="outlined"
          color="primary"
          className={[classes.botao, classes.botaoCancelar].join(' ')}
        >
          {textoBotaoSecundario}
        </BotaoRubik>
      )}
      <BotaoRubik
        onClick={callbackBotaoPrimario}
        variant="contained"
        color="primary"
        className={[classes.botao, classes.botaoEnviar].join(' ')}
        disabled={botaoPrimarioDesativado}
      >
        {textoBotaoPrimario}
      </BotaoRubik>
    </Box>
  );
};

export default ModalComumAcoes;
