import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';

import { Rubik } from 'shared/constants/font';
import { RootState } from 'store/reducer';

import ConfirmarDialogButton from 'shared/components/button/ConfirmarDialogButton';
import spacingInPixels from 'shared/functions/materialUtils';
import DialogTitle from 'shared/components/modal/DialogTitle';
import Dialog from 'shared/components/modal/Dialog';
import { formatarNumeroParaStringMonetariaComSimbolo as formatarValor } from 'shared/functions/dinheiroUtils';

import { TextField } from '@material-ui/core';
import { calcularPorcentagemInteira } from 'shared/functions/porcentagemUtils';
import InfoFrete from '../valoresCard/InfoFrete';

import TipoProposta from '../../types/TipoProposta';

import { fecharModalEnviarProposta } from './redux/motoristasCardAction';
import SugestaoRoboCard from '../../../chatNegociacao/components/SugestaoRoboCard';

interface StyleCustomProps {
  propostaComGreguim: boolean;
}

const useStyles = makeStyles<Theme, StyleCustomProps>((theme) => ({
  espacamento: {
    marginTop: spacingInPixels(theme, 2),
  },
  dialogActions: {
    padding: 0,
    margin: 0,
  },
  segundaMensagem: {
    marginBottom: spacingInPixels(theme, 2),
    marginTop: spacingInPixels(theme, 1),
  },
  textoInicial: {
    font: Rubik,
    fontSize: '20px',
    fontWeight: 400,
    color: theme.palette.cores.cinza[700],
  },
  nomeMotorista: {
    font: Rubik,
    fontSize: '20px',
    fontWeight: 700,
    color: theme.palette.cores.cinza[700],
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  espacamentoBoxInicial: {
    marginBottom: '32px',
  },
  textoProposta: {
    font: Rubik,
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 400,
    color: theme.palette.cores.cinza[700],
  },
  textoValorProposta: {
    font: Rubik,
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: 600,
    color: ({ propostaComGreguim }) =>
      propostaComGreguim
        ? theme.palette.cores.vermelho[500]
        : theme.palette.cores.azulMartins[700],
    textDecoration: ({ propostaComGreguim }) =>
      propostaComGreguim ? 'line-through' : 'auto',
  },
  textoValorPropostaRobo: {
    font: Rubik,
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: 600,
    color: theme.palette.cores.verde[700],
  },
  boxValorPropostaRobo: {
    background: theme.palette.cores.amarelo[100],
    height: '40px',
    width: '188px',
    borderRadius: '10px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    marginLeft: '8px',
  },
}));

const mensagensPorTipoProposta = {
  [TipoProposta.PROPOSTA_INICIAL]: {
    inicioMensagem: 'Você deseja realmente enviar essa proposta para ',
    finalMensagem: '?',
    segundaMensagem: '',
    mensagemValor: 'Valor da proposta: ',
    textoConfirmar: 'Enviar Proposta',
  },
  [TipoProposta.CONTRAPROPOSTA]: {
    inicioMensagem: 'Você deseja realmente enviar essa contraproposta para ',
    finalMensagem: '?',
    segundaMensagem: '',
    mensagemValor: 'Valor da contraproposta: ',
    textoConfirmar: 'Enviar Contraproposta',
  },
  [TipoProposta.PROPOSTA_FINAL]: {
    inicioMensagem: 'Você deseja realmente enviar essa proposta final para ',
    finalMensagem: '?',
    segundaMensagem: '',
    mensagemValor: 'Valor da proposta final: ',
    textoConfirmar: 'Enviar Proposta Final',
  },
  [TipoProposta.RETOMAR_PROPOSTA]: {
    inicioMensagem: 'A negociação com o motorista ',
    segundaMensagem: 'Você deseja retomar a proposta com o motorista?',
    finalMensagem: ' está recusada.',
    mensagemValor: 'Valor da proposta: ',
    textoConfirmar: 'Retomar Proposta',
  },
};

export type EnviarPropostaDialogProps = {
  porcentagemFrotaPropria: (temGreguim: boolean) => number;
  porcentagemFreteTerceiro: (temGreguim: boolean) => number;
  setValorAlgoritmo: (valor: number) => void;
  valorProposta: number;
  valorPropostaRobo: number;
  confirmar: () => void;
};

const EnviarPropostaDialog = ({
  setValorAlgoritmo,
  porcentagemFrotaPropria,
  porcentagemFreteTerceiro,
  valorProposta,
  valorPropostaRobo,
  confirmar,
}: EnviarPropostaDialogProps): JSX.Element => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [value, setValue] = useState('');
  const [salvarValorSemFormater, setSalvarValorSemFormatar] = useState('');

  const { modalEnviarPropostaAberto, propostaAEnviar } = useSelector(
    ({ motoristasCardReducer }: RootState) => motoristasCardReducer,
  );

  const { negociacao } = useSelector(
    ({ informacoesNegociacaoReducer }: RootState) =>
      informacoesNegociacaoReducer,
  );

  const propostaComGreguim = !!negociacao?.sugestaoValorInicialRobo;

  const classes = useStyles({ propostaComGreguim });

  const nomeMotorista = propostaAEnviar?.proposta
    ? propostaAEnviar?.proposta?.motorista
    : propostaAEnviar?.motorista?.nome;

  const fecharModal = () => dispatch(fecharModalEnviarProposta());
  const tipoProposta =
    propostaAEnviar?.tipoProposta || TipoProposta.PROPOSTA_INICIAL;
  const mensagens = mensagensPorTipoProposta[tipoProposta];

  const aoConfirmarProposta = () => {
    confirmar();
    fecharModal();
  };

  const formatarNumeroParaStringMonetariaComSimbolo = (val: string): string => {
    if (!val) return '';

    const cleanVal = val.replace(/\D/g, '');
    const intValue = parseInt(cleanVal, 10);

    // eslint-disable-next-line no-restricted-globals
    if (isNaN(intValue)) return '';

    const formattedValue = (intValue / 100).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    return formattedValue;
  };

  const formatarNumeroParaStringMonetariaSemSimbolo = (val: string): string => {
    if (!val) return '';

    // Remove todos os caracteres não numéricos, incluindo pontos e vírgulas
    const cleanVal = val.replace(/\D/g, '');

    // Converte o valor limpo para inteiro
    const intValue = parseInt(cleanVal, 10);

    // Verifica se o valor convertido é um número válido
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(intValue)) return '';

    // Retorna o valor formatado como uma string sem símbolos, pontos ou vírgulas
    return intValue.toString();
  };

  const parseCurrencyStringToNumber = (val: string): number => {
    const cleanVal = val.replace(/[^\d,]/g, '').replace(',', '.');
    return parseFloat(cleanVal);
  };

  useEffect(() => {
    const valorFormatado = formatarNumeroParaStringMonetariaComSimbolo(
      String(valorPropostaRobo),
    );
    setSalvarValorSemFormatar(String(valorPropostaRobo));
    setValue(valorFormatado);
  }, [valorPropostaRobo]);

  useEffect(() => {
    const valorNumerico = formatarNumeroParaStringMonetariaSemSimbolo(value);
    setValorAlgoritmo(Number(valorNumerico));
  }, [setValorAlgoritmo, value]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const rawValue = event.target.value;
    const valorSemSimbolo = formatarNumeroParaStringMonetariaSemSimbolo(
      rawValue,
    );
    const valorNumerico = parseCurrencyStringToNumber(valorSemSimbolo);

    if (valorNumerico > valorPropostaRobo) {
      return;
    }

    const valorFormatado = formatarNumeroParaStringMonetariaComSimbolo(
      valorSemSimbolo,
    );

    setSalvarValorSemFormatar(valorSemSimbolo);

    if (valorNumerico === valorPropostaRobo) {
      setValue(valorFormatado);
    } else {
      setValue(valorFormatado);
    }
  };

  return (
    <Dialog open={modalEnviarPropostaAberto} keepMounted onClose={fecharModal}>
      <DialogTitle onClose={fecharModal} />
      <DialogContent>
        <Box>
          <Box className={classes.espacamentoBoxInicial}>
            <span className={classes.textoInicial}>
              {mensagens.inicioMensagem}
              <span className={classes.nomeMotorista}>{nomeMotorista}</span>
              {mensagens.finalMensagem}
            </span>
            {!!mensagens.segundaMensagem && (
              <span className={classes.segundaMensagem}>
                {mensagens.segundaMensagem}
              </span>
            )}
          </Box>
          {propostaComGreguim && <SugestaoRoboCard />}
          <Box alignItems="center" display="flex">
            <div className={classes.textoProposta}>
              {mensagens.mensagemValor}
              <span className={classes.textoValorProposta}>
                {formatarValor(valorProposta)}
              </span>
            </div>
            {propostaComGreguim && (
              <TextField
                label="Valor"
                value={value}
                onChange={handleChange}
                variant="outlined"
                size="small"
                inputProps={{
                  inputMode: 'numeric',
                }}
                style={{ marginLeft: '20px' }}
              />
              // <div className={classes.boxValorPropostaRobo}>
              //   <span className={classes.textoProposta}>
              //     Por
              //     <span className={classes.textoValorPropostaRobo}>
              //       {` ${formatarValor(valorPropostaRobo)}`}
              //     </span>
              //   </span>
              // </div>
            )}
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className={classes.espacamento}
        >
          <Box display="flex">
            <InfoFrete
              abreviacao="Target"
              porcentagem={
                valorPropostaRobo
                  ? `${calcularPorcentagemInteira(
                      Number(salvarValorSemFormater),
                      valorPropostaRobo,
                    )}%`
                  : `${porcentagemFrotaPropria(
                      propostaComGreguim &&
                        tipoProposta === TipoProposta.PROPOSTA_INICIAL,
                    )}%`
              }
              marginRight={spacingInPixels(theme, 3)}
            />
            <InfoFrete
              abreviacao="FT"
              porcentagem={
                valorPropostaRobo
                  ? `${calcularPorcentagemInteira(
                      Number(salvarValorSemFormater),
                      negociacao?.valorFreteTerceiro,
                    )}%`
                  : `${porcentagemFreteTerceiro(
                      propostaComGreguim &&
                        tipoProposta === TipoProposta.PROPOSTA_INICIAL,
                    )}%`
              }
            />
          </Box>
          <Box>
            <DialogActions className={classes.dialogActions}>
              <ConfirmarDialogButton
                textoBotao={mensagens.textoConfirmar}
                onClick={aoConfirmarProposta}
                disabled={false}
              />
            </DialogActions>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default EnviarPropostaDialog;
