import { createAction } from '@reduxjs/toolkit';

export const limparDados = createAction<void>(
  'acompanhamentoNegociacoesAction/LIMPAR_DADOS',
);

export const atualizarDataInicio = createAction<Date | null>(
  'acompanhamentoNegociacoesAction/ATUALIZAR_DATA_INICIO',
);

export const atualizarDataFim = createAction<Date | null>(
  'acompanhamentoNegociacoesAction/ATUALIZAR_DATA_FIM',
);
