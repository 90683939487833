import { makeStyles } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';

export const useStyles = makeStyles((theme) => ({
  centerPadding: {
    paddingBottom: spacingInPixels(theme, 3),
    paddingTop: spacingInPixels(theme, 3),
    backgroundColor: theme.palette.cores.branco,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    padding: `0 ${spacingInPixels(theme, 2)}`,
  },
  tituloCenter: {
    backgroundColor: theme.palette.cores.cinza[100],
    padding: `${spacingInPixels(theme, 7)} 0`,

    '& h3': {
      maxWidth: 1200,
      margin: 'auto',
      fontSize: spacingInPixels(theme, 4),
      padding: `0 ${spacingInPixels(theme, 2)}`,
    },
  },
  conteudoCentralizado: {
    maxWidth: 1200,
    margin: 'auto',
  },
}));
