import { Slider } from '@material-ui/core';
import React from 'react';
import { SliderEstilizavelProps } from './types';

const SliderEstilizavel = ({
  valor,
  sliderMarks,
  classe,
  setValor,
}: SliderEstilizavelProps): JSX.Element => {
  return (
    <Slider
      value={valor}
      aria-label="Temperature"
      defaultValue={30}
      valueLabelDisplay="auto"
      className={classe}
      step={10}
      min={0}
      max={200}
      marks={sliderMarks}
      onChange={(event, value) =>
        setValor(Array.isArray(value) ? value[0] : value)
      }
      getAriaValueText={() => `${valor}%`}
      valueLabelFormat={() => `${valor}%`}
    />
  );
};

export default SliderEstilizavel;
