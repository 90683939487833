import { Dispatch } from 'redux';

import {
  abrirSnackbarAviso,
  abrirSnackbarErro,
  abrirSnackbarSucesso,
} from 'shared/components/snackbar/redux/snackbarAction';
import msgConstants from 'shared/constants/msgConstants';

import * as informacoesNegociacaoManager from '../api/informacoesNegociacaoManager';
import {
  ObservacaoNegociacaoRequest,
  ValoresRequest,
} from '../api/informacoesNegociacaoApiTypes';

import { NEGOCIACAO_CANCELADA_COM_SUCESSO } from '../constants/mensagensInformacoesNegociacao';

import { fecharModaisRecusarProposta } from '../views/agregadosCard/redux/motoristasCardAction';

import {
  atualizarNegociacao,
  atualizarObservacao,
  atualizarPropostas,
  atualizarMotoristas,
  atualizarIdNegociacao,
  atualizarValores,
} from './informacoesNegociacaoAction';

export const atualizarNegociacaoOperation = (
  idNegociacao: number,
  idUsuarioMaster?: number | null,
) => async (dispatch: Dispatch): Promise<void> => {
  await informacoesNegociacaoManager
    .buscarNegociacao(idNegociacao, idUsuarioMaster)
    .then((negociacaoResponse) => {
      dispatch(atualizarIdNegociacao(idNegociacao));
      dispatch(atualizarNegociacao(negociacaoResponse));
    })
    .catch((error) => {
      dispatch(abrirSnackbarErro({ mensagem: error.message }));
    });
};

export const atualizarPropostasOperation = (
  idNegociacao: number,
  idUsuarioMaster?: number | null,
) => async (dispatch: Dispatch): Promise<void> => {
  await informacoesNegociacaoManager
    .buscarPropostas(idNegociacao, idUsuarioMaster)
    .then((propostasResponse) => {
      dispatch(atualizarPropostas(propostasResponse.propostas));
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error);
    });
};

export const atualizarValoresOperation = (
  idNegociacao: number,
  valores: ValoresRequest,
) => async (dispatch: Dispatch): Promise<void> => {
  await informacoesNegociacaoManager.salvarValores(idNegociacao, valores);
  dispatch(atualizarValores(valores));
};

const tratarSucessoCancelarNegociacao = (idNegociacao: number) => async (
  dispatch: Dispatch,
) => {
  dispatch(
    abrirSnackbarSucesso({
      mensagem: NEGOCIACAO_CANCELADA_COM_SUCESSO,
    }),
  );
  await atualizarNegociacaoOperation(idNegociacao)(dispatch);
  await atualizarPropostasOperation(idNegociacao)(dispatch);
};

export const cancelarNegociacaoOperation = (
  idNegociacao: number,
  motivoRecusa: number,
) => async (dispatch: Dispatch): Promise<void> => {
  await informacoesNegociacaoManager
    .cancelarNegociacao(idNegociacao, motivoRecusa, false)
    .then(async () => {
      await tratarSucessoCancelarNegociacao(idNegociacao)(dispatch);
    })
    .catch((error) => {
      dispatch(abrirSnackbarErro({ mensagem: error.message }));
    });
};

export const cancelarNegociacaoAutomatizadoOperation = (
  idNegociacao: number,
  motivoRecusa: number,
  isNegociacaoCanceladaManualmenteTms: boolean,
) => (dispatch: Dispatch): Promise<number | string | void> =>
  informacoesNegociacaoManager
    .cancelarNegociacao(
      idNegociacao,
      motivoRecusa,
      isNegociacaoCanceladaManualmenteTms,
    )
    .then((result: number) => {
      dispatch(
        abrirSnackbarSucesso({
          mensagem: NEGOCIACAO_CANCELADA_COM_SUCESSO,
        }),
      );
      return result;
    })
    .catch(() => {
      dispatch(
        abrirSnackbarAviso({
          mensagem: 'Verifique se a negociação foi cancelada no TMS.',
        }),
      );
      return 500;
    });

export const cancelarNegociacaoPorReatribuicaoOperation = (
  idNegociacao: number,
  motivoRecusa: number,
) => async (dispatch: Dispatch): Promise<void> => {
  await informacoesNegociacaoManager
    .cancelarNegociacao(idNegociacao, motivoRecusa, false)
    .then(async () => {
      dispatch(fecharModaisRecusarProposta());
      await tratarSucessoCancelarNegociacao(idNegociacao)(dispatch);
    })
    .catch((error) => {
      dispatch(abrirSnackbarErro({ mensagem: error.message }));
    });
};

export const atualizarObservacaoOperation = (
  idNegociacao: number,
  observacao: ObservacaoNegociacaoRequest,
) => async (dispatch: Dispatch): Promise<void> => {
  await informacoesNegociacaoManager
    .salvarObservacao(idNegociacao, observacao)
    .then(() => {
      dispatch(atualizarObservacao(observacao.observacoes));
      dispatch(
        abrirSnackbarSucesso({
          mensagem:
            observacao.observacoes === undefined
              ? msgConstants.DADOS_EXCLUIDOS_SUCESSO
              : msgConstants.DADOS_SALVOS_SUCESSO,
        }),
      );
    })
    .catch((error) => {
      dispatch(abrirSnackbarErro({ mensagem: error.message }));
    });
};

export const atualizarMotoristasOperation = (
  idFilial: number | null,
  freteTabelado: boolean | null,
) => async (dispatch: Dispatch): Promise<void> => {
  await informacoesNegociacaoManager
    .buscarMotoristas(idFilial, freteTabelado)
    .then((motoristasResponse) => {
      dispatch(atualizarMotoristas(motoristasResponse.motoristas));
    })
    .catch((error) => {
      // dispatch(abrirSnackbarErro({ mensagem: error.message }));
      // eslint-disable-next-line no-console
      console.log(error);
    });
};
