import { useTheme } from '@material-ui/core';
import * as React from 'react';
import { SVGProps } from 'react';

const IconeCaixa = ({
  fill,
  width,
  height,
  ...outros
}: SVGProps<SVGSVGElement>): JSX.Element => {
  const theme = useTheme();

  return (
    <svg
      width={width || 10}
      height={height || 12}
      viewBox="0 0 10 12"
      fill="none"
      {...outros}
    >
      <path
        d="M9.852 3.387a.11.11 0 0 1 0-.047.1.1 0 0 1 0-.04v-.053l-.035-.088a.28.28 0 0 0-.052-.064l-.053-.047h-.029L7.385 1.596 5.215.254a.496.496 0 0 0-.17-.087H5a.478.478 0 0 0-.158 0h-.058a.66.66 0 0 0-.193.076L.233 2.955l-.053.04-.052.048-.058.04-.03.035-.035.088v.087a.403.403 0 0 0 0 .117v5.093a.583.583 0 0 0 .275.495l4.375 2.707.087.035h.047a.502.502 0 0 0 .303 0h.047l.087-.035 4.34-2.666a.583.583 0 0 0 .274-.496V3.451s.012-.041.012-.064ZM4.9 1.433l1.038.641-3.26 2.019-1.045-.642L4.9 1.433Zm-.584 8.75-3.208-1.96V4.495l3.208 1.983v3.704ZM4.9 5.451 3.785 4.78l3.261-2.024 1.12.694-3.266 2ZM8.69 8.205 5.483 10.2V6.478l3.208-1.983v3.71Z"
        fill={fill || theme.palette.cores.azul[500]}
      />
    </svg>
  );
};

export default IconeCaixa;
