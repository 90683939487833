import { createReducer } from '@reduxjs/toolkit';

import {
  atualizarValorFrotaPropria,
  atualizarValorFreteTerceiro,
  atualizarValorNegociacao,
  atualizarTodosValores,
  limparDados,
} from './valoresCardAction';

export type ValoresCardState = {
  valorFrotaPropria?: number;
  valorFreteTerceiro?: number;
  valorNegociacao?: number;
  valoresNegociacaoCadastrados: boolean;
};

export type ValoresNegociacao = Omit<
  ValoresCardState,
  'valoresNegociacaoCadastrados'
>;

export const estadoInicial: Readonly<ValoresCardState> = {
  valorFrotaPropria: undefined,
  valorFreteTerceiro: undefined,
  valorNegociacao: undefined,
  valoresNegociacaoCadastrados: false,
};

const validarValoresNegociacaoCadastrados = (
  valorNegociacao: number | undefined,
  valorFrotaPropria: number | undefined,
) => Boolean(valorNegociacao) && Boolean(valorFrotaPropria);

const valoresCardReducer = createReducer(estadoInicial, (builder) => {
  builder
    .addCase(atualizarValorFrotaPropria, (state, action) => ({
      ...state,
      valorFrotaPropria: action.payload,
    }))
    .addCase(atualizarValorFreteTerceiro, (state, action) => ({
      ...state,
      valorFreteTerceiro: action.payload,
    }))
    .addCase(atualizarValorNegociacao, (state, action) => ({
      ...state,
      valorNegociacao: action.payload,
    }))
    .addCase(atualizarTodosValores, (state, action) => ({
      ...state,
      ...action.payload,
      valoresNegociacaoCadastrados: validarValoresNegociacaoCadastrados(
        action.payload.valorNegociacao,
        action.payload.valorFrotaPropria,
      ),
    }))
    .addCase(limparDados, () => estadoInicial)
    .addDefaultCase((state) => state);
});

export default valoresCardReducer;
