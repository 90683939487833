import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

export type ListaVaziaProps = {
  icone?: JSX.Element;
  texto: string;
};

const useStyles = makeStyles(() => ({
  texto: {
    width: 392,
    textAlign: 'center',
    fontWeight: 400,
    fontSize: 20,
    marginTop: 16,
  },
}));

const ListaVazia = ({ icone, texto }: ListaVaziaProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      height="100%"
      width="100%"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      marginTop="64px"
    >
      {icone}
      <Typography className={classes.texto}>{texto}</Typography>
    </Box>
  );
};

export default ListaVazia;
