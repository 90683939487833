import React, { createRef, ReactNode, useEffect, useState } from 'react';

import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core';

import {
  converterStringMonetariaParaNumero,
  formatarNumeroParaStringMonetaria,
} from '../../functions/dinheiroUtils';
import TextField from './TextField';

const RealAdornment = withStyles((theme) => ({
  root: {
    '& p': {
      color: theme.palette.text.primary,
    },
  },
}))(InputAdornment);

export type InputMonetarioProps = {
  id: string;
  value: number | undefined;
  onChange: (novoValor: number | undefined) => void;
  placeholder?: string;
  label?: string;
  error?: boolean;
  helperText?: string;
  className?: string;
  size?: 'medium' | 'small';
  fullWidth?: boolean;
  disabled?: boolean;
  endAdornment?: ReactNode;
};

const InputMonetario = ({
  id,
  value,
  onChange,
  placeholder,
  label,
  error,
  helperText,
  className,
  size,
  fullWidth,
  endAdornment,
  disabled,
}: InputMonetarioProps): JSX.Element => {
  const inputRef = createRef<HTMLInputElement>();

  const [valorInput, setValorInput] = useState<string>('');

  useEffect(() => {
    setValorInput(
      value === undefined ? '' : formatarNumeroParaStringMonetaria(value),
    );
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const novoValor = event.target.value;
    const novoValorNumerico =
      novoValor === '0,0'
        ? undefined
        : converterStringMonetariaParaNumero(novoValor);
    onChange(novoValorNumerico);
  };

  return (
    <TextField
      autoComplete="off"
      disabled={disabled}
      fullWidth={fullWidth}
      variant="outlined"
      error={error}
      label={label}
      placeholder={placeholder}
      helperText={helperText}
      value={valorInput}
      onChange={handleChange}
      className={className}
      size={size}
      InputProps={{
        id,
        endAdornment,
        ref: inputRef,
        inputProps: { maxLength: 15 },
        startAdornment: valorInput !== '' && (
          <RealAdornment position="start">R$</RealAdornment>
        ),
      }}
      InputLabelProps={{
        htmlFor: id,
      }}
    />
  );
};

InputMonetario.defaultProps = {
  placeholder: undefined,
  label: undefined,
  helperText: undefined,
  error: false,
  className: undefined,
  size: undefined,
};

export default InputMonetario;
