import { Dispatch } from 'redux';
import { NegociacaoFilial } from '../filiais/api/listagemFiliaisApiTypes';
import { Demanda } from '../negociadores/api/listagemNegociadoresApiTypes';
import {
  buscarDadosNegociacoesFilial,
  limparDadosNegociacoesFilial,
} from '../redux/detalhesFilial/detalhesFilialOperation';

export const adapterNegociacaoParaDemanda = (
  nomeFilial: string,
  negociacoes?: NegociacaoFilial[],
): Demanda[] =>
  negociacoes?.map(({ romaneio, cidade, prioridadeCarga, sla }) => ({
    romaneio,
    nomeFilial,
    sla,
    prioridadeCarga,
    nomeCidade: cidade,
    categorias: [],
    status: undefined,
  })) || [];

export const buscarDetalhesFilial = (
  dispatch: Dispatch,
  setCarregando: (valor: boolean) => void,
  idFilial?: number,
  nomeFilial?: string,
): void => {
  buscarDadosNegociacoesFilial(setCarregando, idFilial, nomeFilial)(dispatch);
};

export const limparDadosDetalhesFilial = (dispatch: Dispatch): void => {
  limparDadosNegociacoesFilial(dispatch);
};
