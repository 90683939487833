import { Dispatch } from 'redux';
import {
  abrirSnackbarSucesso,
  abrirSnackbarErro,
} from 'shared/components/snackbar/redux/snackbarAction';
import { aceitarPropostaMotorista } from 'features/chatNegociacao/api/chatNegociacaoManager';
import * as manager from '../api/detalhesAprovacaoSupervisorManager';
import { DetalhesAprovacaoSupervisorResponse } from '../api/detalhesAprovacaoSupervisorTypes';
import { removerAprovacaoSupervisor } from '../../aprovacoesSupervisor/redux/aprovacoesSupervisorAction';

const tratarSuccesso = (
  mensagem: string,
  historyPush: () => void,
  idProposta: number,
) => (dispatch: Dispatch) => {
  historyPush();
  dispatch(abrirSnackbarSucesso({ mensagem }));
  dispatch(removerAprovacaoSupervisor(idProposta));
};

const tratarErro = (error: Error) => (dispatch: Dispatch) => {
  dispatch(abrirSnackbarErro({ mensagem: error.message }));
};

export const aprovarPropostasSupervisor = async (
  idNegociacao: number,
  idProposta: number,
  dispatch: Dispatch,
  historyPush: () => void,
): Promise<void> => {
  await manager
    .aprovarPropostaSupervisor(idNegociacao, idProposta)
    .then(() =>
      tratarSuccesso(
        'Proposta aprovada com sucesso!',
        historyPush,
        idProposta,
      )(dispatch),
    )
    .catch((error: Error) => {
      tratarErro(error)(dispatch);
    });
};

export const proporNovaMetaPropostasSupervisor = async (
  idNegociacao: number,
  idProposta: number,
  body: { valorNovaMeta: number; justificativa: string },
  dispatch: Dispatch,
  historyPush: () => void,
): Promise<void> => {
  await manager
    .proporNovaMetaPropostaSupervisor(idNegociacao, idProposta, body)
    .then(() =>
      tratarSuccesso(
        'Nova meta enviada com sucesso!',
        historyPush,
        idProposta,
      )(dispatch),
    )
    .catch((error: Error) => {
      tratarErro(error)(dispatch);
    });
};

export const reprovarPropostasSupervisor = async (
  idNegociacao: number,
  idProposta: number,
  body: { justificativa: string },
  dispatch: Dispatch,
  historyPush: () => void,
): Promise<void> => {
  await manager
    .reprovarPropostaSupervisor(idNegociacao, idProposta, body)
    .then(() =>
      tratarSuccesso(
        'Proposta reprovada com sucesso!',
        historyPush,
        idProposta,
      )(dispatch),
    )
    .catch((error: Error) => {
      tratarErro(error)(dispatch);
    });
};

export const aprovarNegociacaoSupervisor = async (
  idProposta: number,
  dispatch: Dispatch,
  historyPush: () => void,
): Promise<void> => {
  await aceitarPropostaMotorista(idProposta)
    .then(() =>
      tratarSuccesso(
        'Negociação aprovada com sucesso!',
        historyPush,
        idProposta,
      )(dispatch),
    )
    .catch((error: Error) => {
      tratarErro(error)(dispatch);
    });
};

export const obterDetalhesPropostaSupervisor = async (
  idNegociacao: number,
  idProposta: number,
  dispatch: Dispatch,
): Promise<DetalhesAprovacaoSupervisorResponse | undefined> =>
  manager
    .obterDetalhesPropostaSupervisor(idNegociacao, idProposta)
    .then((response) => response)
    .catch((error: Error) => {
      tratarErro(error)(dispatch);
      return undefined;
    });

export const formatarDataHora = (dataHora?: string): string => {
  if (!dataHora) return '-';
  const [data, hora] = dataHora.split(' ');
  const [ano, mes, dia] = data.split('-');
  return `${dia}/${mes}/${ano} ${hora}`;
};
