import { useTheme } from '@material-ui/core';
import * as React from 'react';
import { SVGProps } from 'react';

const IconeUsuario = ({
  fill,
  ...outros
}: SVGProps<SVGSVGElement>): JSX.Element => {
  const theme = useTheme();
  return (
    <svg width="1em" height="1em" fill="none" {...outros}>
      <path
        d="M6.855 6.355a3 3 0 1 0-3.71 0 5 5 0 0 0-3.11 4.09.503.503 0 1 0 1 .11 4 4 0 0 1 7.95 0 .5.5 0 0 0 .5.445h.055a.5.5 0 0 0 .44-.55 5 5 0 0 0-3.125-4.095ZM5 6a2 2 0 1 1 0-4 2 2 0 0 1 0 4Z"
        fill={fill || theme.palette.cores.cinza[50]}
      />
    </svg>
  );
};

export default IconeUsuario;
