import React from 'react';
import { useSelector } from 'react-redux';

import { Box } from '@material-ui/core';

import IconeCasa from 'assets/icones/IconeCasa';
import theme from 'theme';
import IconePapel from 'assets/icones/IconePapel';
import IconeLocalizacao from 'assets/icones/IconeLocalizacao';
import IconePeso from 'assets/icones/IconePeso';
import IconeCaixa from 'assets/icones/IconeCaixa';
import IconeVelocimetro from 'assets/icones/IconeVelocimetro';
import IconeDropBox from 'assets/icones/IconeDropBox';
import InformacaoItem from '../../../../shared/components/item/InformacaoItem';
import { RootState } from '../../../../store/reducer';

import { Carga } from '../../api/informacoesNegociacaoApiTypes';

const InformacaoHeaderCarga = (): JSX.Element => {
  const { negociacao } = useSelector(
    (state: RootState) => state.informacoesNegociacaoReducer,
  );

  const obterTextoQuantidadeEntregas = (carga: Carga): string =>
    `${carga.entregas} ( ${carga.entregasPJ} PJ / ${carga.entregasPF} PF)`;

  return (
    <>
      {negociacao && (
        <Box display="flex" justifyContent="space-between" width="100%">
          <InformacaoItem
            icone={
              <IconePapel
                fill={theme.palette.cores.azulMartins[500]}
                width="20px"
                height="20px"
              />
            }
            informacao={negociacao.carga.romaneio}
            altIcone="Romaneio"
            titleIcone="Romaneio"
          />

          <InformacaoItem
            icone={
              <IconeCasa
                fill={theme.palette.cores.azulMartins[500]}
                width="20px"
                height="20px"
              />
            }
            informacao={negociacao.carga.filial}
            altIcone="Filial"
            titleIcone="Filial"
          />

          <InformacaoItem
            icone={
              <IconeLocalizacao
                fill={theme.palette.cores.azulMartins[500]}
                width="20px"
                height="20px"
              />
            }
            informacao={negociacao.carga.cidade}
            altIcone="Cidade"
            titleIcone="Cidade principal de destino"
          />

          <InformacaoItem
            icone={
              <IconePeso
                fill={theme.palette.cores.azulMartins[500]}
                width="20px"
                height="20px"
              />
            }
            informacao={`${negociacao.carga.peso.toLocaleString()} kg`}
            altIcone="Peso"
            titleIcone="Peso"
          />

          <InformacaoItem
            icone={
              <IconeCaixa
                fill={theme.palette.cores.azulMartins[500]}
                width="20px"
                height="20px"
              />
            }
            informacao={`${negociacao.carga.volume.toLocaleString()} m³`}
            altIcone="Volume"
            titleIcone="Volume"
          />

          <InformacaoItem
            icone={
              <IconeVelocimetro
                fill={theme.palette.cores.azulMartins[500]}
                width="20px"
                height="20px"
              />
            }
            informacao={`${negociacao.carga.quilometragem.toLocaleString()} km`}
            altIcone="Quilometragem"
            titleIcone="Quilometragem"
          />

          <InformacaoItem
            icone={
              <IconeDropBox
                fill={theme.palette.cores.azulMartins[500]}
                width="20px"
                height="20px"
              />
            }
            informacao={obterTextoQuantidadeEntregas(negociacao.carga)}
            altIcone="Entregas"
            titleIcone="Número de entregas"
          />
        </Box>
      )}
    </>
  );
};

export default InformacaoHeaderCarga;
