import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import {
  MotivoRecusaType,
  obterMotivosRecusaSelecionaveis,
  obterMotivosRecusaSelecionaveisAutomatizado,
} from '../../constants/MotivoRecusa';
import Select, { SelectProps } from './Select';

type SelectRecusaProps = Omit<SelectProps, 'children'>;

const SelectMotivoRecusa = ({
  id,
  label,
  value,
  error,
  mensagemErro,
  onChange,
  onBlur,
  isAutomatizado,
}: SelectRecusaProps): JSX.Element => {
  const motivoRecusaList = (motivos: MotivoRecusaType[]) =>
    motivos.map((motivo: MotivoRecusaType) => (
      <MenuItem key={`motivo-recusa-${motivo.id}`} value={motivo.id}>
        {motivo.descricao}
      </MenuItem>
    ));

  return (
    <Select
      value={value}
      onChange={onChange}
      label={label}
      onBlur={onBlur}
      error={error}
      mensagemErro={mensagemErro}
      id={id}
      size="small"
    >
      {isAutomatizado
        ? motivoRecusaList(obterMotivosRecusaSelecionaveisAutomatizado() || [])
        : motivoRecusaList(obterMotivosRecusaSelecionaveis() || [])}
    </Select>
  );
};

export default SelectMotivoRecusa;
