import { ConexaoAPI } from 'shared/classes/conexaoAPI';
import {
  DetalhesDemandaAndamento,
  DetalhesDemandaConcluida,
  DetalhesDemandasConcluidasResponse,
  DetalhesDemandaNaoIniciada,
  DetalhesDemandasAndamentoResponse,
  DetalhesTotalDemandasDoDiaResponse,
  DetalhesDemandasNaoIniciadasResponse,
  DetalhesTotalDemandasDoDia,
  DetalhesNegociacaoRecusada,
  DetalhesNegociacoesRecusadasResponse,
  StatusDemandaEnum,
  DetalhesDemandaEmAprovacao,
  DetalhesDemandasEmAprovacaoResponse,
} from './detalhesDemandasDiariaApiTypes';

const obterUrlBuscarDetalhesDemandasDiaria = (): string =>
  `${process.env.REACT_APP_URL_API_BASE}/negociacoes/detalhes-demanda-diaria`;

const obterUrlBuscarDetalhesNegociacoesPorStatus = (
  status: StatusDemandaEnum,
): string =>
  `${process.env.REACT_APP_URL_API_BASE}/negociacoes/detalhes?status=${status}`;

const obterUrlInserirTarifarioDemandaConcluida = (
  idNegociacao: number,
): string =>
  `${process.env.REACT_APP_URL_API_BASE}/negociacoes/${idNegociacao}/tarifario`;

export const obterDetalhesDemandasDiaria = async (): Promise<
  DetalhesTotalDemandasDoDia[]
> => {
  const url = obterUrlBuscarDetalhesDemandasDiaria();
  const conexaoApi = new ConexaoAPI(url, 'get');
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<DetalhesTotalDemandasDoDiaResponse>();
  const response = await conectar();
  return response?.data?.demandasDiaria;
};

export const obterDetalhesDemandasEmAprovacao = async (): Promise<
  DetalhesDemandaEmAprovacao[]
> => {
  const url = obterUrlBuscarDetalhesNegociacoesPorStatus(
    StatusDemandaEnum.EmAprovacao,
  );
  const conexaoApi = new ConexaoAPI(url, 'get');
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<DetalhesDemandasEmAprovacaoResponse>();
  const response = await conectar();
  return response?.data?.negociacoes;
};

export const obterDetalhesDemandasAndamento = async (): Promise<
  DetalhesDemandaAndamento[]
> => {
  const url = obterUrlBuscarDetalhesNegociacoesPorStatus(
    StatusDemandaEnum.EmNegociacao,
  );
  const conexaoApi = new ConexaoAPI(url, 'get');
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<DetalhesDemandasAndamentoResponse>();
  const response = await conectar();
  return response?.data?.negociacoes;
};

export const obterDetalhesDemandasNaoIniciadas = async (): Promise<
  DetalhesDemandaNaoIniciada[]
> => {
  const url = obterUrlBuscarDetalhesNegociacoesPorStatus(
    StatusDemandaEnum.NaoIniciada,
  );
  const conexaoApi = new ConexaoAPI(url, 'get');
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<DetalhesDemandasNaoIniciadasResponse>();
  const response = await conectar();
  return response?.data?.negociacoes;
};

export const obterDetalhesNegociacoesRecusadas = async (): Promise<
  DetalhesNegociacaoRecusada[]
> => {
  const url = obterUrlBuscarDetalhesNegociacoesPorStatus(
    StatusDemandaEnum.Cancelada,
  );
  const conexaoApi = new ConexaoAPI(url, 'get');
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<DetalhesNegociacoesRecusadasResponse>();
  const response = await conectar();
  return response?.data?.negociacoes;
};

export const obterDetalhesDemandasConcluidas = async (): Promise<
  DetalhesDemandaConcluida[]
> => {
  const url = obterUrlBuscarDetalhesNegociacoesPorStatus(
    StatusDemandaEnum.Concluida,
  );
  const conexaoApi = new ConexaoAPI(url, 'get');
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<DetalhesDemandasConcluidasResponse>();
  const response = await conectar();
  return response?.data?.negociacoes;
};

export const obterDetalhesDemandasTarfifarioNaoAtualizadas = async (
  pagina?: number,
): Promise<DetalhesDemandasConcluidasResponse> => {
  const urlBase = `${process.env.REACT_APP_URL_API_BASE}/negociacoes/detalhes-tarifarios-nao-cadastrados`;
  const url = pagina ? `${urlBase}?pagina=${pagina}` : urlBase;
  const conexaoApi = new ConexaoAPI(url, 'get');
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<DetalhesDemandasConcluidasResponse>();
  const response = await conectar();

  return {
    negociacoes: response?.data?.negociacoes ?? [],
    totalPaginas: response?.data?.totalPaginas ?? 0,
  };
};

export const inserirTarifarioDemandaConcluida = async (
  idNegociacao: number,
): Promise<void> => {
  const url = obterUrlInserirTarifarioDemandaConcluida(idNegociacao);
  const conexaoApi = new ConexaoAPI(url, 'patch');
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<void>();
  await conectar();
};
