import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import FiltroData from '../../../shared/components/filters/FiltroData';

import { RootState } from '../../../store/reducer';

import {
  atualizarDataFim,
  atualizarDataInicio,
} from '../redux/acompanhamentoNegociacoesAction';

const TituloAcompanhamentoNegociacoes = (): JSX.Element => {
  const dispatch = useDispatch();

  const { dataFim, dataInicio } = useSelector(
    (reducer: RootState) => reducer.acompanhamentoNegociacoesReducer,
  );

  const onDataInicioChange = (valor: Date | null) => {
    dispatch(atualizarDataInicio(valor));
  };

  const onDataFimChange = (valor: Date | null) => {
    dispatch(atualizarDataFim(valor));
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-end">
      <FiltroData
        texto="Data"
        titulo="Data de criação"
        dataFim={dataFim}
        dataInicio={dataInicio}
        onDataInicioChange={onDataInicioChange}
        onDataFimChange={onDataFimChange}
      />
    </Box>
  );
};

export default TituloAcompanhamentoNegociacoes;
