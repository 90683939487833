import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import HeaderAlternativo from 'shared/components/headerAlternativo/HeaderAlternativo';
import { CamposDoHeaderAlternativo } from 'shared/components/menu/PaginasPortal';
import { fazerLogout as fazerLogoutOperation } from '../../features/login/redux/loginOperation';

import { PATH_LOGIN } from '../constants/pathConstants';

import Header from '../components/header';
import Background from '../components/background/DefaultBackground';
import Notificacoes from '../../features/notificacoes';

import MenuLateral from '../components/menu/MenuLateral';

import PaginaNaoEncontrada from '../../features/pagina-nao-encontrada';
import ExampansivoLateral from './ExampansivoLateral';

export type EstruturaPaginaProps = {
  component: JSX.Element;
  possuiAcessoPagina?: boolean;
  headerAlternativo?: CamposDoHeaderAlternativo[];
};

const EstruturaPagina = ({
  component,
  possuiAcessoPagina,
  headerAlternativo,
}: EstruturaPaginaProps): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [menuAberto, setMenuAberto] = useState(true);
  const [hover, setHover] = useState(false);

  const toggleMenu = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setMenuAberto(!menuAberto);
  };

  const fazerLogout = async () => {
    await fazerLogoutOperation()(dispatch).then(() => {
      history.push(PATH_LOGIN);
    });
  };

  const usarExpansorLater = (condicao: boolean) =>
    !condicao ? (
      <ExampansivoLateral isOpen={menuAberto} hover={hover}>
        {component}
      </ExampansivoLateral>
    ) : (
      <>{component}</>
    );

  return (
    <>
      {headerAlternativo ? (
        <HeaderAlternativo campos={headerAlternativo} />
      ) : (
        <>
          <Header toggleMenu={toggleMenu} fazerLogout={fazerLogout} />
          <MenuLateral isOpen={menuAberto} hover={hover} setHover={setHover} />
        </>
      )}

      <Background />

      {possuiAcessoPagina ? (
        usarExpansorLater(!!headerAlternativo)
      ) : (
        <PaginaNaoEncontrada />
      )}
      <Notificacoes altura={!!headerAlternativo} />
    </>
  );
};

export default EstruturaPagina;
