import { createReducer } from '@reduxjs/toolkit';

import { TipoIconeSnackbar } from '../IconeFeedback';

import {
  abrirSnackbarAviso,
  abrirSnackbarErro,
  abrirSnackbarNotificacao,
  abrirSnackbarSucesso,
  fecharSnackbar,
  fecharSnackbarNotificacao,
} from './snackbarAction';

export type SnackbarState = {
  tipo: TipoIconeSnackbar;
  aberto: boolean;
  mensagem: string;
  tituloFechar?: string;
  tempoOcultacao?: number;
  notificacaoSnackBar?: boolean;
  quantidade?: number;
  onClose?: () => void;
  alturaTela?: string;
};

export type SnackbarControle = Omit<SnackbarState, 'tipo' | 'aberto'>;

export const estadoInicial: Readonly<SnackbarState> = {
  tipo: 'error',
  mensagem: '',
  aberto: false,
  notificacaoSnackBar: false,
};

const negociacaoReducer = createReducer(estadoInicial, (builder) => {
  builder
    .addCase(abrirSnackbarAviso, (state, action) => ({
      ...state,
      ...action.payload,
      tipo: 'warning',
      aberto: true,
    }))
    .addCase(abrirSnackbarErro, (state, action) => ({
      ...state,
      ...action.payload,
      tipo: 'error',
      aberto: true,
    }))
    .addCase(abrirSnackbarSucesso, (state, action) => ({
      ...state,
      ...action.payload,
      tipo: 'success',
      aberto: true,
    }))
    .addCase(fecharSnackbar, (state) => ({
      ...state,
      aberto: false,
      onClose: undefined,
      tituloFechar: undefined,
      tempoOcultacao: undefined,
    }))
    .addCase(abrirSnackbarNotificacao, (state, action) => ({
      ...state,
      ...action.payload,
      tempoOcultacao: undefined,
      notificacaoSnackBar: true,
      aberto: true,
    }))
    .addCase(fecharSnackbarNotificacao, (state) => ({
      ...state,
      tempoOcultacao: undefined,
      notificacaoSnackBar: false,
      aberto: false,
    }))
    .addDefaultCase((state) => state);
});

export default negociacaoReducer;
