import React, { ReactNode } from 'react';

import Box from '@material-ui/core/Box';
import Zoom from '@material-ui/core/Zoom';

export type ZoomBoxProps = {
  visivel?: boolean;
  children: ReactNode;
};

const ZoomBox = ({ visivel, children }: ZoomBoxProps): JSX.Element => (
  <Zoom in={visivel} timeout={250}>
    <Box>{visivel ? children : <></>}</Box>
  </Zoom>
);

export default ZoomBox;
