import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { useLoading } from 'shared/customHooks/useLoading';
import FiltroChip from 'shared/components/filters/chip/FiltroChip';
import { PATH_DETALHES_DEMANDAS_DIARIA } from 'shared/constants/pathConstants';
import ContainerDemandasPendentes from './demandasPendentes/ContainerDemandasPendentes';
import { StatusDemandaDiariaDashboard } from '../constants/Constants';
import { DetalhesDemandaDiaria } from '../api/detalhesDemandasDiariaApiTypes';
import {
  buscarDetalhesDemandas,
  filtrarDemandasPendentes,
} from '../utils/detalhesDemandas';
import ContainerDemandasDoDia from './demandasDoDia/ContainerDemandasDoDia';
import { useStyles } from '../styles/styleContainerDetalhes';
import { RouteParams } from '../types/containerTypes';

const ContainerDemandasTotaisPendentes = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams<RouteParams>();
  const dispatch = useDispatch();

  const [demandas, setDemandas] = useState<DetalhesDemandaDiaria[]>([]);
  const [erro, setErro] = useState(false);
  const [filtroAtual, setFiltroAtual] = useState('');

  const buscaDemandasCallback = useCallback(
    async () => buscarDetalhesDemandas(setDemandas, setErro)(dispatch),
    [setDemandas, dispatch],
  );

  const [carregando, buscarDemandasLoading] = useLoading(buscaDemandasCallback);

  const filtros = useMemo(
    () => [
      { descricao: 'Demandas do dia', pathName: 'demandas-do-dia' },
      { descricao: 'Demandas pendentes', pathName: 'pendentes' },
    ],
    [],
  );

  const handleTrocaFiltro = (descricaoFiltro: string) => {
    const filtroSelecionado =
      filtros.find((filtro) => filtro.descricao === descricaoFiltro) ||
      filtros[0];
    history.replace(
      PATH_DETALHES_DEMANDAS_DIARIA.replace(
        ':tipoDemanda',
        filtroSelecionado?.pathName,
      ),
    );
    setFiltroAtual(descricaoFiltro);
  };

  const handleFiltroDemandasPendentes = (): DetalhesDemandaDiaria[] => {
    const dataBase = new Date();
    dataBase.setHours(0, 0, 0, 0);
    return filtrarDemandasPendentes(demandas, dataBase);
  };

  useMemo(() => {
    const filtroSelecionado =
      filtros.find((filtro) => filtro.pathName === params.tipoDemanda) ||
      filtros[0];
    setFiltroAtual(filtroSelecionado.descricao);
  }, [filtros, params.tipoDemanda]);

  useEffect(() => {
    buscarDemandasLoading();
  }, [dispatch, buscarDemandasLoading]);

  return (
    <section className={classes.container}>
      <FiltroChip
        carregando={carregando}
        filtroAtual={filtroAtual}
        filtros={filtros.map((filtro) => filtro.descricao)}
        quantidade={2}
        onClickFiltro={handleTrocaFiltro}
      />
      {params.tipoDemanda === StatusDemandaDiariaDashboard.DEMANDAS_DO_DIA ? (
        <ContainerDemandasDoDia
          demandas={demandas}
          erro={erro}
          carregando={carregando}
        />
      ) : (
        <ContainerDemandasPendentes
          demandas={handleFiltroDemandasPendentes()}
          erro={erro}
          carregando={carregando}
        />
      )}
    </section>
  );
};

export default ContainerDemandasTotaisPendentes;
