import React from 'react';
import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import spacingInPixels from '../../../../../shared/functions/materialUtils';

const useStyles = makeStyles((theme: Theme) => ({
  containerMetricas: {
    display: 'flex',
    alignItems: 'baseline',
    gap: spacingInPixels(theme, 1.5),
  },
  tituloMetrica: {
    fontWeight: 700,
    fontSize: spacingInPixels(theme, 1.5),
    lineHeight: spacingInPixels(theme, 2.5),
    letterSpacing: '0.4px',
    color: theme.palette.cores.cinza[600],
  },
  conteudoMetrica: {
    fontWeight: 500,
    fontSize: spacingInPixels(theme, 2),
    lineHeight: spacingInPixels(theme, 4),
    color: theme.palette.cores.cinza[800],
  },
}));

export interface MetricasNegociadorProps {
  quantidadeNegociacoesPorHora?: number;
}

const MetricasNegociador = ({
  quantidadeNegociacoesPorHora,
}: MetricasNegociadorProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item md={6} lg={6} className={classes.containerMetricas}>
        <Typography className={classes.tituloMetrica}>
          Média de horas trabalhadas (por dia)
        </Typography>
        <Typography className={classes.conteudoMetrica}>7h20min</Typography>
      </Grid>
      <Grid item md={6} lg={6} className={classes.containerMetricas}>
        <Typography className={classes.tituloMetrica}>
          Média de negociações feitas (por hora)
        </Typography>
        <Typography className={classes.conteudoMetrica}>
          {`${quantidadeNegociacoesPorHora || 0} 
          ${quantidadeNegociacoesPorHora === 1 ? 'negociação' : 'negociações'}`}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default MetricasNegociador;
