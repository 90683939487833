import React, { useCallback, useEffect, useState } from 'react';
import { DetalhesDemandaDiaria } from 'features/detalhesDemandasDiaria/api/detalhesDemandasDiariaApiTypes';
import { titulosPagina } from 'features/detalhesDemandasDiaria/constants/TitulosPagina';
import TituloPagina from 'features/detalhesDemandasDiaria/components/TituloPagina';
import { useDispatch } from 'react-redux';
import { useLoading } from 'shared/customHooks/useLoading';
import { buscarDetalhesDemandasConcluidasEnaoAtualizadas } from 'features/detalhesDemandasDiaria/utils/detalhesDemandas';
import { Pagination } from '@material-ui/lab';
import TabelaDemandasConcluidas from './TabelaDemandasConcluidas';
import { useStyles } from '../../styles/styleContainerDetalhes';
import TabsDados from './TabsDados';

const ContainerDemandasConcluidas = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [demandas, setDemandas] = useState<DetalhesDemandaDiaria[]>([]);
  const [
    demandasTarfifarioNaoAtualizadas,
    setDemandasTarfifarioNaoAtualizadas,
  ] = useState<DetalhesDemandaDiaria[]>([]);
  const [erro, setErro] = useState<boolean>(false);
  const [controleDados, setControleDados] = useState<boolean>(true);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);

  const buscaDemandasCallback = useCallback(
    async () =>
      buscarDetalhesDemandasConcluidasEnaoAtualizadas(
        setDemandas,
        setErro,
        true,
      )(dispatch),
    [setDemandas, dispatch],
  );

  const buscasetDemandasTarfifarioNaoAtualizadasCallback = useCallback(async () => {
    setLoading(true);
    buscarDetalhesDemandasConcluidasEnaoAtualizadas(
      setDemandasTarfifarioNaoAtualizadas,
      setErro,
      false,
      paginaAtual,
      setTotalPage,
      setLoading,
    )(dispatch);
  }, [
    setDemandasTarfifarioNaoAtualizadas,
    setErro,
    paginaAtual,
    setTotalPage,
    dispatch,
  ]);

  const [carregando, buscarDemandasLoading, firstTime] = useLoading(
    controleDados
      ? buscaDemandasCallback
      : buscasetDemandasTarfifarioNaoAtualizadasCallback,
  );

  useEffect(() => {
    buscarDemandasLoading();
  }, [dispatch, buscarDemandasLoading, controleDados]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPaginaAtual(value);
  };

  return (
    <section className={classes.container}>
      <TituloPagina
        tituloPaginaConfig={titulosPagina.demandasNegociadas}
        quantidadeItens={
          controleDados
            ? demandas.length
            : demandasTarfifarioNaoAtualizadas.length
        }
        carregando={carregando && firstTime}
      />
      <TabsDados
        aba={controleDados}
        setAba={setControleDados}
        carregando={carregando && firstTime}
      />
      <TabelaDemandasConcluidas
        carregando={controleDados ? carregando : loading}
        demandas={controleDados ? demandas : demandasTarfifarioNaoAtualizadas}
        erro={erro}
      />
      {!controleDados && (
        <Pagination
          count={totalPage}
          page={paginaAtual}
          onChange={handlePageChange}
          shape="rounded"
          variant="outlined"
          color="primary"
        />
      )}
    </section>
  );
};

export default ContainerDemandasConcluidas;
