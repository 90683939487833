import React from 'react';

import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { EntregaBairro } from '../api/detalhesCargaApiTypes';
import formatarZerosIniciais from '../../../shared/functions/numberUtils';

type ListaEntregaBairrosProps = {
  entrega: EntregaBairro;
  valoresRepetidos: boolean;
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  info: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '1.8',
  },
  tipoPessoa: {
    marginLeft: '5px',
    color: 'gray',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '1.8',
  },
  timeLine: {
    marginRight: '-17px',
    marginLeft: '-45px',
    marginBottom: '-25px',
    marginTop: '-30px',
    height: '80px',
  },
  timelineDot: {
    marginLeft: '2px',
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    backgroundColor: 'grey',
    transform: 'translateX(-50%)',
  },
  dotTimeLine: {
    marginTop: '5px',
    marginBottom: '5px',
    boxShadow: 'none',
  },
});

const ListaEntregaBairros = ({
  entrega,
  valoresRepetidos,
}: ListaEntregaBairrosProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={2} className={classes.container}>
        <Box className={classes.info}>
          {valoresRepetidos ? (
            <Typography className={classes.tipoPessoa}>
              {formatarZerosIniciais(entrega.sequencia, 2)}
              {entrega.tipoPessoa && ` (${entrega.tipoPessoa})`}
            </Typography>
          ) : (
            <Box className={classes.timeLine}>
              <Timeline>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineConnector style={{ maxHeight: '20px' }} />
                    <TimelineDot className={classes.dotTimeLine} />
                  </TimelineSeparator>
                </TimelineItem>
              </Timeline>
            </Box>
          )}
        </Box>
      </Grid>
      <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>
        <Typography className={classes.info}>{entrega.bairro}</Typography>
      </Grid>
      <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>
        <Typography className={classes.info}>{entrega.cliente}</Typography>
      </Grid>
      <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
        <Typography className={classes.info}>
          {entrega.peso.toLocaleString('pt-br')}
        </Typography>
      </Grid>
      <Grid item xs={1} style={{ display: 'flex', alignItems: 'center' }}>
        <Typography className={classes.info}>
          {entrega.volume.toLocaleString('pt-br')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ListaEntregaBairros;
