import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';

import {
  ButtonBase,
  Divider,
  Menu,
  MenuItem,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons';
import { spacingInPixels } from '../../../functions/materialUtils';

import Avatar from './Avatar';
import { RootState } from '../../../../store/reducer';
import BotaoNotificacoes from './BotaoNotificacoes';
import { caminhoImgAvatar } from '../../../constants/CaminhoRecursos';
import theme from '../../../../theme';

type ControleUsuarioProps = {
  fazerLogout: () => Promise<void>;
};

const useStyles = makeStyles(() => ({
  MenuNavBar: {
    minWidth: '215px',
    minHeight: '165px',
    marginLeft: '10px',
    marginTop: '26px',
  },
  menuItem: {
    paddingLeft: '16px',
    paddingTop: '16px',
    color: theme.palette.cores.vermelho[500],
  },
  avatarName: {
    background: theme.palette.cores.azulMartins[500],
    width: 34,
    height: 34,
    fontSize: '16px',
    cursor: 'pointer',
    border: '3px solid #EEEEEE',
  },
  avatarNameUser: {
    cursor: 'pointer',
  },
  typographyName: {
    width: '190px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    padding: '10px 15px 50px',
    fontWeight: 700,
  },
  buttonBaseLogout: {
    fontWeight: 400,
    fontSize: '14px',
    paddingLeft: '8px',
  },
}));

const ControleUsuario = ({
  fazerLogout,
}: ControleUsuarioProps): JSX.Element => {
  const { dados } = useSelector((reducer: RootState) => reducer.loginReducer);
  const [openNavBar, setOpenNavBar] = useState<null | HTMLElement>(null);
  const classes = useStyles();

  function iniciaisNomes(nome: string) {
    if (nome.trim() === '') {
      return '';
    }

    const split = nome.split(' ', 2);
    const iniciais = split.map((splitNew) => splitNew[0].toUpperCase());

    return iniciais.join('');
  }

  const handleClose = () => {
    setOpenNavBar(null);
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenNavBar(event.currentTarget);
  };

  const nomeColaborador = dados?.usuario.dadosPermissao.nome || '';
  const splitNome = iniciaisNomes(nomeColaborador);

  const possuiImagem = dados != null && dados.usuario.imagem !== '';

  const themeMui = useTheme();
  return (
    <Box display="flex" alignItems="center" height="100%">
      <Box marginRight="8px">
        <BotaoNotificacoes />
      </Box>
      <Box
        display="flex"
        alignItems="center"
        height="100%"
        marginRight={spacingInPixels(themeMui, 2)}
      >
        {possuiImagem ? (
          <Avatar
            alt="Usuário"
            src={possuiImagem ? `${dados?.usuario.imagem}` : caminhoImgAvatar}
            onClick={handleOpenMenu}
            className={classes.avatarNameUser}
          />
        ) : (
          <Avatar
            aria-controls="basic-menu"
            onClick={handleOpenMenu}
            className={classes.avatarName}
          >
            {splitNome}
          </Avatar>
        )}
      </Box>
      <Menu
        id="basic-menu"
        open={Boolean(openNavBar)}
        anchorEl={openNavBar}
        onClose={handleClose}
        className={classes.MenuNavBar}
        PaperProps={{
          style: {
            borderRadius: '10px',
            border: '1px solid #E1E1E1',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          },
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box>
          <Typography className={classes.typographyName}>
            <span style={{ fontWeight: 400 }}>Olá</span> {nomeColaborador}
          </Typography>
        </Box>
        <Divider />
        <Box onClick={fazerLogout}>
          <MenuItem className={classes.menuItem}>
            <ExitToApp />
            <ButtonBase className={classes.buttonBaseLogout}>Sair</ButtonBase>
          </MenuItem>
        </Box>
      </Menu>
    </Box>
  );
};

export default ControleUsuario;
