import { createReducer } from '@reduxjs/toolkit';
import { NovaNegociacao } from '../api/minhasCargasApiTypes';

import {
  atualizarNovaNegociacao,
  removerNovaNegociacao,
  removerTodasNegociacoes,
} from './minhasCargasActions';

export type MinhasCargasState = {
  novasNegociacoes: NovaNegociacao[];
};

const estadoInicial: Readonly<MinhasCargasState> = {
  novasNegociacoes: [],
};

const minhasCargasReducer = createReducer(estadoInicial, (builder) => {
  builder
    .addCase(atualizarNovaNegociacao, (state, action) => {
      const novaNegociacao = action.payload;
      const novasNegociacoes = state.novasNegociacoes.filter(
        (n) => n.idCarga !== novaNegociacao.idCarga,
      );
      novasNegociacoes.push(novaNegociacao);

      return {
        novasNegociacoes,
      };
    })
    .addCase(removerNovaNegociacao, (state, action) => {
      const idNegociacao = action.payload;
      const novasNegociacoes = state.novasNegociacoes.filter(
        (n) => n.idCarga !== idNegociacao,
      );

      return {
        novasNegociacoes,
      };
    })
    .addCase(removerTodasNegociacoes, () => estadoInicial)
    .addDefaultCase((state) => state);
});

export default minhasCargasReducer;
