import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { ArrowUpward } from '@material-ui/icons';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import spacingInPixels from 'shared/functions/materialUtils';
import CustomTooltip from 'shared/components/tooltip/CustomTooltip';
import { estilosTabelaNegociadores } from './styles/estilosTabelaNegociadores';
import { DemandaColaborador } from './api/listagemNegociadoresApiTypes';

export const useStyles = makeStyles((theme: Theme) => ({
  cabecalho: {
    '& span:first-child': {
      paddingLeft: spacingInPixels(theme, 1.5),
    },
  },
}));

interface CabecalhoTabelaNegociadoresProps {
  dadosItensCabecalho: string[];
  setListaDemandaColaborador: (
    listaDemandaNegociador: DemandaColaborador[] | undefined,
  ) => void;
  listaDemandaColaborador: DemandaColaborador[] | undefined;
}

const CabecalhoTabelaNegociadores = ({
  dadosItensCabecalho,
  setListaDemandaColaborador,
  listaDemandaColaborador,
}: CabecalhoTabelaNegociadoresProps): JSX.Element => {
  const {
    gridTabela,
    fonteCabecalho,
    botaoDirecao,
  } = estilosTabelaNegociadores();
  const classes = useStyles();
  const [ordenacao, setOrdenacao] = useState(false);

  const handleOrdenacao = (action: boolean) => {
    if (listaDemandaColaborador) {
      setOrdenacao((state: boolean) => !state);

      const listaOrdenada = [...listaDemandaColaborador];

      if (action) {
        listaOrdenada.sort((a, b) => b.demandas.total - a.demandas.total);
      } else {
        listaOrdenada.sort((a, b) => a.demandas.total - b.demandas.total);
      }

      setListaDemandaColaborador(listaOrdenada);
    }
  };

  return (
    <div className={`${gridTabela} ${classes.cabecalho}`}>
      <CustomTooltip title="Demandas do dia">
        {!ordenacao ? (
          <ArrowUpward
            onClick={() => handleOrdenacao(ordenacao)}
            className={botaoDirecao}
          />
        ) : (
          <ArrowDownwardIcon
            onClick={() => handleOrdenacao(ordenacao)}
            className={botaoDirecao}
          />
        )}
      </CustomTooltip>

      {dadosItensCabecalho.map((item, index) => {
        const key = `cabecalho-tabela-${index + 1}`;
        return (
          <span key={key} className={fonteCabecalho}>
            {item}
          </span>
        );
      })}
    </div>
  );
};

export default CabecalhoTabelaNegociadores;
