import { createAction } from '@reduxjs/toolkit';
import { ObterNotificacoesResponseType } from '../types/notificacoes';

export const abrirNotificacoes = createAction<void>(
  'notificacoesAction/ABRIR_NOTIFICACOES',
);

export const atualizarNotificacoes = createAction<ObterNotificacoesResponseType>(
  'notificacoesAction/ATUALIZAR_NOTIFICACOES',
);

export const fecharNotificacoes = createAction<void>(
  'notificacoesAction/FECHAR_NOTIFICACOES',
);

export const atualizarRecebeuNovasNotificacoes = createAction<void>(
  'notificacoesAction/ATUALIZAR_RECEBEU_NOVAS_NOTIFICACOES',
);
