import { Dispatch } from 'redux';
import {
  abrirSnackbarAviso,
  abrirSnackbarErro,
  abrirSnackbarSucesso,
} from 'shared/components/snackbar/redux/snackbarAction';
import PropostaStatus from 'shared/constants/PropostaStatus';
import TipoMensagemProposta, {
  TipoMensagemPropostaType,
} from 'shared/constants/TipoMensagemProposta';
import {
  atualizarNegociacaoOperation,
  atualizarPropostasOperation,
} from '../../informacoesNegociacao/redux/informacoesNegociacaoOperation';
import { AtualizarLiberacaoListaClientesRequest } from '../api/chatNegociacaoApiTypes';
import {
  aceitarPropostaMotorista,
  aceitarPropostaMotoristaEPedirAprovacao,
  atualizarLiberacaoListaClientes,
  enviarMensagem,
  fazerContraProposta,
  fazerDownloadImagem,
  obterHistoricoMensagens,
  obterHistoricoMensagensFreteTabelado,
  pedirAprovacaoSupervisor,
} from '../api/chatNegociacaoManager';
import {
  LISTA_CLIENTES_DESATIVADA_SUCESSO,
  LISTA_CLIENTES_LIBERADA_SUCESSO,
} from '../constants/chatNegociacao';
import TipoValorContraproposta from '../constants/TipoValorContraproposta';
import {
  adicionarMensagem,
  atualizarListaClientesLiberada,
  atualizarMensagens,
  adicionarImagemChat,
  informacoesModalContraproposta,
} from './chatNegociacaoAction';

const tratarErro = (error: Error) => (dispatch: Dispatch) => {
  dispatch(abrirSnackbarErro({ mensagem: error.message }));
};

export const atualizarMensagensOperation = (
  idNegociacao: number | undefined,
  idProposta: number | undefined,
  motivoRecusa: number | undefined,
  idUsuarioMaster?: number | null,
) => async (dispatch: Dispatch): Promise<void> => {
  const chatIdNegociacao = idNegociacao as number;
  const chatIdProposta = idProposta as number;

  await obterHistoricoMensagens(
    chatIdNegociacao,
    chatIdProposta,
    idUsuarioMaster,
  )
    .then((resposta) => {
      const { mensagens } = resposta;
      dispatch(
        atualizarMensagens({
          chatIdNegociacao,
          chatIdProposta,
          mensagens,
          chatMotivoRecusa: motivoRecusa,
        }),
      );
    })
    .catch((error) => {
      tratarErro(error)(dispatch);
    });
};

export const atualizarMensagensOperationFreteTabelado = (
  idNegociacao: number | undefined,
  idProposta: number | undefined,
  motivoRecusa: number | undefined,
) => async (dispatch: Dispatch): Promise<void> => {
  const chatIdNegociacao = idNegociacao as number;
  const chatIdProposta = idProposta as number;

  await obterHistoricoMensagensFreteTabelado(chatIdNegociacao, chatIdProposta)
    .then((resposta) => {
      const { mensagens } = resposta;
      dispatch(
        atualizarMensagens({
          chatIdNegociacao,
          chatIdProposta,
          mensagens,
          chatMotivoRecusa: motivoRecusa,
        }),
      );
    })
    .catch((error) => {
      tratarErro(error)(dispatch);
    });
};

interface EnviarMensagemOperationInterface {
  idNegociacao: number | undefined;
  idProposta: number | undefined;
  valorContraproposta: number | undefined;
  texto: string | undefined;
  motivoRecusa: number | undefined;
  tipo: TipoMensagemPropostaType;
  propostaStatus: PropostaStatus;
  arquivo?: File;
}

export const enviarMensagemOperation = ({
  idNegociacao,
  idProposta,
  valorContraproposta,
  texto,
  motivoRecusa,
  tipo,
  propostaStatus,
  arquivo,
}: EnviarMensagemOperationInterface) => async (
  dispatch: Dispatch,
): Promise<boolean> => {
  const chatIdNegociacao = idNegociacao as number;
  const chatIdProposta = idProposta as number;

  return enviarMensagem(chatIdNegociacao, chatIdProposta, {
    tipo,
    texto,
    valorContraproposta,
    motivoRecusa,
    arquivo,
  })
    .then(async (mensagem) => {
      dispatch(
        adicionarMensagem({
          chatIdNegociacao,
          chatIdProposta,
          mensagem,
          chatPropostaStatus: propostaStatus,
          chatMotivoRecusa: motivoRecusa,
        }),
      );
      if (arquivo && mensagem.anexos?.length) {
        dispatch(
          adicionarImagemChat({
            id: mensagem.anexos[0],
            arquivo,
          }),
        );
      }
      await atualizarPropostasOperation(chatIdNegociacao)(dispatch);
      await atualizarNegociacaoOperation(chatIdNegociacao)(dispatch);
      return true;
    })
    .catch((error) => {
      tratarErro(error)(dispatch);
      return false;
    });
};

export const atualizarListaClientesLiberadaOperation = (
  idNegociacao: number | undefined,
  idProposta: number | undefined,
  liberarListaClientes: boolean,
) => async (dispatch: Dispatch): Promise<void> => {
  const chatIdNegociacao = idNegociacao as number;
  const chatIdProposta = idProposta as number;
  const request: Readonly<AtualizarLiberacaoListaClientesRequest> = {
    liberarListaClientes,
  };

  await atualizarLiberacaoListaClientes(
    chatIdNegociacao,
    chatIdProposta,
    request,
  )
    .then(() => {
      const mensagem = liberarListaClientes
        ? LISTA_CLIENTES_LIBERADA_SUCESSO
        : LISTA_CLIENTES_DESATIVADA_SUCESSO;
      dispatch(atualizarListaClientesLiberada(liberarListaClientes));
      dispatch(abrirSnackbarSucesso({ mensagem }));
    })
    .catch((error) => {
      tratarErro(error)(dispatch);
    });
};

export const fazerDownloadImagemOperation = (
  idNegociacao: number,
  idProposta: number,
  idMensagem: number,
  chaveAnexo: string,
  finalizarLoading: () => void,
) => async (dispatch: Dispatch): Promise<void> => {
  await fazerDownloadImagem(idNegociacao, idProposta, idMensagem, chaveAnexo)
    .then((imagem) => {
      dispatch(
        adicionarImagemChat({
          id: chaveAnexo,
          arquivo: new File([imagem], chaveAnexo),
        }),
      );
    })
    .catch((error) => {
      tratarErro(error)(dispatch);
    })
    .finally(() => {
      finalizarLoading();
    });
};

const addMensagem = (
  dispatch: Dispatch,
  idNegociacao: number,
  idProposta: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => async (mensagem: any) => {
  dispatch(
    adicionarMensagem({
      chatIdNegociacao: idNegociacao,
      chatIdProposta: idProposta,
      mensagem,
      chatPropostaStatus: PropostaStatus.AGUARDANDO,
    }),
  );
  await atualizarPropostasOperation(idNegociacao)(dispatch);
  await atualizarNegociacaoOperation(idNegociacao)(dispatch);
};

export const fazerContraPropostaOperation = (
  idNegociacao: number,
  idProposta: number,
  body: { valorContraproposta: number; justificativa: string },
) => async (dispatch: Dispatch): Promise<void> => {
  await fazerContraProposta(idNegociacao, idProposta, body)
    .then(addMensagem(dispatch, idNegociacao, idProposta))
    .catch(() => {
      dispatch(
        abrirSnackbarAviso({
          mensagem:
            'Não é possivel fazer contraproposta maior que o valor aprovado.',
        }),
      );
    });
};

export const reabrirNegociacaoOperation = (
  idNegociacao: number,
  idProposta: number,
  body: { valor: number; justificativa: string },
) => async (dispatch: Dispatch): Promise<void> => {
  await enviarMensagem(idNegociacao, idProposta, {
    tipo: TipoMensagemProposta.ReinicioNegociacao,
    texto: body.justificativa,
    valorContraproposta: body.valor,
  })
    .then(addMensagem(dispatch, idNegociacao, idProposta))
    .catch((e) => {
      tratarErro(e)(dispatch);
    });
};

export const pedirAprovacaoSupervisorOperation = (
  idNegociacao: number,
  idProposta: number,
  body: { valorContraproposta: number; justificativa: string },
) => async (dispatch: Dispatch): Promise<void> => {
  await pedirAprovacaoSupervisor(idNegociacao, idProposta, body)
    .then(async (mensagem) => {
      dispatch(
        adicionarMensagem({
          chatIdNegociacao: idNegociacao,
          chatIdProposta: idProposta,
          mensagem,
          chatPropostaStatus: PropostaStatus.PENDENTE_SUPERVISOR,
        }),
      );
      await atualizarPropostasOperation(idNegociacao)(dispatch);
      await atualizarNegociacaoOperation(idNegociacao)(dispatch);
    })
    .catch((e) => {
      tratarErro(e)(dispatch);
    });
};

interface AceitarPropostaOperationInterface {
  idNegociacao: number | undefined;
  idProposta: number | undefined;
}

export const aceitarPropostaOperation = ({
  idProposta,
  idNegociacao,
}: AceitarPropostaOperationInterface) => async (
  dispatch: Dispatch,
): Promise<boolean> => {
  const chatIdProposta = idProposta || 0;
  const chatIdNegociacao = idNegociacao || 0;

  return aceitarPropostaMotorista(chatIdProposta)
    .then(async (mensagem) => {
      dispatch(
        adicionarMensagem({
          chatIdNegociacao,
          chatIdProposta,
          mensagem,
          chatPropostaStatus: PropostaStatus.CONCLUIDA,
        }),
      );

      await atualizarPropostasOperation(chatIdNegociacao)(dispatch);
      await atualizarNegociacaoOperation(chatIdNegociacao)(dispatch);
      return true;
    })
    .catch((error) => {
      tratarErro(error)(dispatch);
      return false;
    });
};

export const aceitarPropostaGreguimOperation = ({
  idProposta,
  idNegociacao,
}: AceitarPropostaOperationInterface) => async (
  dispatch: Dispatch,
): Promise<boolean> => {
  const chatIdProposta = idProposta || 0;
  const chatIdNegociacao = idNegociacao || 0;

  return aceitarPropostaMotoristaEPedirAprovacao(chatIdProposta)
    .then(async (mensagem) => {
      dispatch(
        adicionarMensagem({
          chatIdNegociacao,
          chatIdProposta,
          mensagem,
          chatPropostaStatus: PropostaStatus.AGUARDANDO_ACEITE_SUPERVISOR,
        }),
      );

      await atualizarPropostasOperation(chatIdNegociacao)(dispatch);
      await atualizarNegociacaoOperation(chatIdNegociacao)(dispatch);
      return true;
    })
    .catch((error) => {
      tratarErro(error)(dispatch);
      return false;
    });
};

export const salvarInformacoesModalContraproposta = (
  justificativa: string,
  tipoValor: TipoValorContraproposta,
  valorProposto: number | undefined,
  valorInput: number | undefined,
) => (dispatch: Dispatch): void => {
  dispatch(
    informacoesModalContraproposta({
      justificativa,
      tipoValor,
      valorProposto,
      valorInput,
    }),
  );
};
