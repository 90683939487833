import React from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import { makeStyles } from '@material-ui/core/styles';

import { useSelector } from 'react-redux';

import { Rubik } from 'shared/constants/font';
import BadgesValor from 'shared/components/badgesValor/BadgesValor';
import TipoValorContraproposta from 'features/chatNegociacao/constants/TipoValorContraproposta';
import Dialog from 'shared/components/modal/Dialog';
import DialogTitle from 'shared/components/modal/DialogTitle';
import { RootState } from 'store/reducer';
import SugestaoRoboCard from './SugestaoRoboCard';

type DialogMetricasSugestaoRoboProps = {
  modalAberto: boolean;
  fecharModal: () => void;
};

const useStyles = makeStyles((theme) => ({
  botaoSalvar: {
    borderRadius: 24,
    backgroundColor: theme.palette.cores.azulMartins[500],
    color: theme.palette.cores.branco,
    padding: theme.spacing(1, 2),
    textTransform: 'capitalize',
  },
  titulo: {
    font: Rubik,
    fontSize: '20px',
    fontWeight: 400,
    color: theme.palette.cores.cinza[700],
    marginBottom: '32px',
    display: 'block',
  },
  containerBadges: {
    width: '500px',
  },
  dialogActions: {
    justifyContent: 'flex-start',
  },
}));

const DialogMetricasSugestaoRobo = ({
  modalAberto,
  fecharModal,
}: DialogMetricasSugestaoRoboProps): JSX.Element => {
  const classes = useStyles();

  const { negociacao } = useSelector(
    ({ informacoesNegociacaoReducer }: RootState) =>
      informacoesNegociacaoReducer,
  );

  return (
    <Dialog open={modalAberto} onClose={fecharModal}>
      <DialogTitle onClose={fecharModal} />
      <DialogContent>
        <span className={classes.titulo}>Ver sugestão para negociar</span>
        <SugestaoRoboCard />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <div className={classes.containerBadges}>
          <BadgesValor
            valor={negociacao?.sugestaoValorInicialRobo}
            tipoValor={TipoValorContraproposta.ValorProposto}
            valorFreteTerceiro={negociacao?.valorFreteTerceiro || 0}
            valorFrotaPropria={
              // eslint-disable-next-line no-nested-ternary
              negociacao?.sugestaoValorInicialRobo
                ? negociacao?.sugestaoValorInicialRobo
                : negociacao?.valorFrotaPropria
                ? negociacao?.valorFrotaPropria
                : 0
            }
            valorEntregas={negociacao?.carga.entregas || 0}
            valorMercadoria={negociacao?.carga.valor || 0}
            valorPeso={negociacao?.carga.peso || 0}
            valorQuilometragem={negociacao?.carga.quilometragem || 0}
            justificar="flex-start"
            condensado
          />
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default DialogMetricasSugestaoRobo;
