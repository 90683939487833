import { Dispatch } from 'redux';
import { abrirSnackbarErro } from 'shared/components/snackbar/redux/snackbarAction';
import { obterDemandaDiaria } from '../api/demandaDiariaManager';
import { DemandaDiaria as DemandaDiariaType } from '../api/demandaDiariaApiTypes';

const MilissegundosAtualizacaoDemandas = 60 * 1000;

const tratarErro = (error: Error) => (dispatch: Dispatch) => {
  dispatch(abrirSnackbarErro({ mensagem: error.message }));
};

export const buscarDemandaDiaria = async (
  setDemandasDiarias: (dadosPermissao: DemandaDiariaType) => void,
  dispatch: Dispatch,
): Promise<void> => {
  await obterDemandaDiaria()
    .then((resposta) => {
      setDemandasDiarias(resposta);
    })
    .catch((error) => {
      tratarErro(error)(dispatch);
    });
};

export const criarPollingDemandaDiaria = (
  setDemandasDiarias: (dadosPermissao: DemandaDiariaType) => void,
  dispatch: Dispatch,
): number =>
  Number(
    setInterval(async () => {
      await buscarDemandaDiaria(setDemandasDiarias, dispatch);
    }, MilissegundosAtualizacaoDemandas),
  );
