import {
  verificarExtensaoArquivo,
  verificarTamanhoArquivo,
} from '../../../shared/functions/validacaoArquivoUtils';
import {
  TAMANHO_MAXIMO_IMAGEM_BYTES,
  TIPOS_IMAGEM_ACEITOS,
} from '../constants/validacaoArquivoConstantes';

export const MENSAGEM_EXTENSAO_INVALIDA =
  'A extensão da imagem é inválida. É possível enviar imagens nas extensões PNG, JPG e JPEG.';

export const MENSAGEM_TAMANHO_INVALIDO =
  'Não é possível enviar imagens acima de 4MB.';

export const verificarExtensaoImagem = (tipoArquivo: string): boolean =>
  verificarExtensaoArquivo(tipoArquivo, TIPOS_IMAGEM_ACEITOS);

export const verificarTamanhoImagem = (tamanhoArquivo: number): boolean =>
  verificarTamanhoArquivo(tamanhoArquivo, TAMANHO_MAXIMO_IMAGEM_BYTES);
