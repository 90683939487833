import React from 'react';
import TipoMensagemProposta from '../../../../../shared/constants/TipoMensagemProposta';
import { ChatBalaoProps } from '../ChatBalao';
import AceiteSupervisor from './AceiteSupervisor';
import NovaMetaSupervisor from './NovaMetaSupervisor';
import RecusaSupervisor from './RecusaSupervisor';

const MensagemSupervisor = ({ mensagem }: ChatBalaoProps): JSX.Element => {
  switch (mensagem.tipo) {
    case TipoMensagemProposta.AceiteSupervisor:
    case TipoMensagemProposta.Aceite:
      return <AceiteSupervisor mensagem={mensagem} />;
    case TipoMensagemProposta.RecusaSupervisor:
      return <RecusaSupervisor mensagem={mensagem} />;
    case TipoMensagemProposta.NovaMetaSupervisor:
    default:
      return <NovaMetaSupervisor mensagem={mensagem} />;
  }
};

export default MensagemSupervisor;
