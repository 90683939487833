import React from 'react';
import ArrowBackRounded from '@material-ui/icons/ArrowBackRounded';
import BotaoIcone from 'shared/components/button/BotaoIcone';
import { useHistory } from 'react-router-dom';
import IconePredioCirculo from 'assets/icones/IconePredioCirculo';
import { makeStyles, Typography, useTheme } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';
import BotaoRubik from 'shared/components/button/BotaoRubik';
import { useLoading } from 'shared/customHooks/useLoading';
import { useDispatch } from 'react-redux';
import { exportarPlanilhaDemandasAndamento } from '../utils/demandasAndamento';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '2220px',
  },
  containerTitulo: {
    display: 'flex',
    alignItems: 'center',
    gap: spacingInPixels(theme, 2),
  },
  titulo: {
    color: theme.palette.cores.cinza[800],
    fontWeight: 400,
  },
  botaoExportar: {
    backgroundColor: theme.palette.cores.azulMartins[500],
    color: theme.palette.cores.branco,
    width: 'fit-content',
    padding: `${spacingInPixels(theme, 0.5)} ${spacingInPixels(theme, 3)} `,
    '&:hover': {
      backgroundColor: theme.palette.cores.azulMartins[700],
    },
    '&[data-desabilitado=true]': {
      backgroundColor: theme.palette.cores.cinza[500],
      color: theme.palette.cores.branco,
    },
  },
}));

export interface HeaderDemandasAndamentoProps {
  existemDemandas: boolean;
}

const HeaderDemandasAndamento = ({
  existemDemandas,
}: HeaderDemandasAndamentoProps): JSX.Element => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();

  const [carregandoPlanilha, exportarPlanilha] = useLoading(async () =>
    exportarPlanilhaDemandasAndamento()(dispatch),
  );

  return (
    <header className={classes.header}>
      <section className={classes.containerTitulo}>
        <BotaoIcone
          onClick={() => history.goBack()}
          descricao="Voltar ao Painel de Gestão"
        >
          <ArrowBackRounded />
        </BotaoIcone>
        <IconePredioCirculo
          fillCirculo={theme.palette.cores.branco}
          width={44}
          height={44}
        />
        <Typography className={classes.titulo} variant="h4">
          Todas as filiais
        </Typography>
      </section>
      <BotaoRubik
        data-desabilitado={!existemDemandas || carregandoPlanilha}
        disabled={!existemDemandas || carregandoPlanilha}
        className={classes.botaoExportar}
        onClick={exportarPlanilha}
      >
        Exportar
      </BotaoRubik>
    </header>
  );
};

export default HeaderDemandasAndamento;
