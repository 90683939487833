import { Box, makeStyles, Typography } from '@material-ui/core';
import React, { ReactNode } from 'react';
import spacingInPixels from '../../../../shared/functions/materialUtils';

export type BadgeValoresRelacionadosProps = {
  children: ReactNode;
};

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.cores.cinza[300],
    borderRadius: spacingInPixels(theme, 2),
    padding: `${spacingInPixels(theme, 0.5)} ${spacingInPixels(theme, 1)}`,
  },
  texto: {
    fontSize: '12px',
    fontWeight: 400,
    color: theme.palette.cores.cinza[900],
  },
}));

const BadgeValoresRelacionados = ({
  children,
}: BadgeValoresRelacionadosProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Typography className={classes.texto}>{children}</Typography>
    </Box>
  );
};

export default BadgeValoresRelacionados;
