import { Dispatch } from 'redux';
import {
  abrirSnackbarErro,
  abrirSnackbarSucesso,
} from 'shared/components/snackbar/redux/snackbarAction';
import {
  formatarNumeroParaStringMonetariaComSimbolo,
  formatarValorMonetarioSimplificado,
} from 'shared/functions/dinheiroUtils';
import { converterValorEmDecimal } from 'shared/functions/numberUtils';
import { tratarValorPorcentagem } from 'shared/functions/porcentagemUtils';
import { IndicadorNegociacao } from '../api/indicadoresApiTypes';
import {
  gerarPlanilhaIndicadoresPorPeriodo,
  obterIndicadoresPorPeriodo,
} from '../api/indicadoresManager';
import {
  DadosOpcaoIndicador,
  PeriodoFiltroIndicadores,
  TipoValorIndicador,
} from '../constants/Constants';
import { getValoresPeriodo } from './evolucaoDeCargas';

const tratarErro = (mensagem: string) => (dispatch: Dispatch) => {
  dispatch(abrirSnackbarErro({ mensagem }));
};

export const formatarValorExibicaoIndicadorDeAcordoComTipo = (
  tipoValor: TipoValorIndicador,
  dado?: number,
  simplificarValorMonetario = false,
): string => {
  if (!dado) return '--';
  switch (tipoValor) {
    case TipoValorIndicador.PORCENTAGEM:
      return tratarValorPorcentagem(converterValorEmDecimal(dado));
    case TipoValorIndicador.MONETARIO:
      return simplificarValorMonetario
        ? formatarValorMonetarioSimplificado(converterValorEmDecimal(dado))
        : formatarNumeroParaStringMonetariaComSimbolo(dado);
    case TipoValorIndicador.DISTANCIA:
    case TipoValorIndicador.PESO:
      return converterValorEmDecimal(dado).toLocaleString('pt-BR');
    case TipoValorIndicador.UNIDADE:
    default:
      return dado.toLocaleString('pt-BR');
  }
};

export const getValoresIndicador = (
  indicador: DadosOpcaoIndicador,
  dadosIndicadores: IndicadorNegociacao[],
): number[] => {
  return dadosIndicadores.map(
    (dadosIndicador) =>
      Object.entries(dadosIndicador).find(
        (dadoIndicador) => dadoIndicador?.[0] === indicador.chave,
      )?.[1] as number,
  );
};

export const buscarDadosIndicadoresMacros = async (
  setDadosIndicadores: (indicadores: IndicadorNegociacao[]) => void,
  setValoresPeriodo: (periodo: string[]) => void,
  setErro: (erro: boolean) => void,
  periodo: PeriodoFiltroIndicadores,
): Promise<void> =>
  obterIndicadoresPorPeriodo(periodo)
    .then((response) => {
      setDadosIndicadores(response);
      setValoresPeriodo(getValoresPeriodo(response));
      setErro(false);
    })
    .catch(() => {
      setErro(true);
    });

export const gerarPlanilhaIndicadoresMacros = (
  periodo: PeriodoFiltroIndicadores,
) => async (dispatch: Dispatch): Promise<void> =>
  gerarPlanilhaIndicadoresPorPeriodo(periodo)
    .then(() => {
      dispatch(
        abrirSnackbarSucesso({
          mensagem: 'Indicadores exportados com sucesso!',
        }),
      );
    })
    .catch(() =>
      tratarErro('Não foi possível exportar os indicadores!')(dispatch),
    );
