import * as chatNegociacaoService from './chatNegociacaoService';
import {
  HistoricoMensagensResponse,
  EnvioMensagemRequest,
  Mensagem,
  AtualizarLiberacaoListaClientesRequest,
  AvaliarAgregadoRequest,
} from './chatNegociacaoApiTypes';
import { converterRequestParaFormData } from '../utils/chatRequestUtils';

export const obterHistoricoMensagens = async (
  idNegociacao: number,
  idProposta: number,
  idUsuarioMaster?: number | null,
): Promise<HistoricoMensagensResponse> =>
  chatNegociacaoService.obterHistoricoMensagens(
    idNegociacao,
    idProposta,
    idUsuarioMaster,
  );

export const obterHistoricoMensagensFreteTabelado = async (
  idNegociacao: number,
  idProposta: number,
): Promise<HistoricoMensagensResponse> =>
  chatNegociacaoService.obterHistoricoMensagensFreteTabelado(
    idNegociacao,
    idProposta,
  );

export const enviarMensagem = async (
  idNegociacao: number,
  idProposta: number,
  request: EnvioMensagemRequest,
): Promise<Mensagem> => {
  const formRequest = converterRequestParaFormData(request);

  return chatNegociacaoService.enviarMensagem(
    idNegociacao,
    idProposta,
    formRequest,
  );
};

export const fazerDownloadImagem = async (
  idNegociacao: number,
  idProposta: number,
  idMensagem: number,
  chaveAnexo: string,
): Promise<Blob> => {
  return chatNegociacaoService.fazerDownloadImagem(
    idNegociacao,
    idProposta,
    idMensagem,
    chaveAnexo,
  );
};

export const fazerContraProposta = async (
  idNegociacao: number,
  idProposta: number,
  body: { valorContraproposta: number; justificativa: string },
): Promise<Mensagem> => {
  return chatNegociacaoService.fazerContraProposta(
    idNegociacao,
    idProposta,
    body,
  );
};

export const pedirAprovacaoSupervisor = async (
  idNegociacao: number,
  idProposta: number,
  body: { valorContraproposta: number; justificativa: string },
): Promise<Mensagem> => {
  return chatNegociacaoService.pedirAprovacaoSupervisor(
    idNegociacao,
    idProposta,
    body,
  );
};

export const atualizarLiberacaoListaClientes = async (
  idNegociacao: number,
  idProposta: number,
  body: AtualizarLiberacaoListaClientesRequest,
): Promise<void> => {
  return chatNegociacaoService.atualizarLiberacaoListaClientes(
    idNegociacao,
    idProposta,
    body,
  );
};

export const aceitarPropostaMotorista = async (
  idProposta: number,
): Promise<Mensagem> => {
  return chatNegociacaoService.aceitarPropostaMotorista(idProposta);
};

export const aceitarPropostaMotoristaEPedirAprovacao = async (
  idProposta: number,
): Promise<Mensagem> => {
  return chatNegociacaoService.aceitarPropostaMotoristaEPedirAprovacao(
    idProposta,
  );
};

export const salvarAvaliacaoAgregado = async (
  request: AvaliarAgregadoRequest,
): Promise<void> => {
  return chatNegociacaoService.salvarAvaliacaoAgregado(request);
};
