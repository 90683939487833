/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import EntregaCidadeHeaderAccordion from './EntregaCidadeHeaderAccordion';
import ListaEntregaBairros from './ListaEntregaBairros';
import Accordion from '../../../shared/components/accordion/Accordion';
import AccordionDetails from '../../../shared/components/accordion/AccordionDetails';
import { EntregaBairro, EntregaCidade } from '../api/detalhesCargaApiTypes';

type EntregaCidadeAccordionProps = {
  entrega: EntregaCidade;
  expandirExterno: boolean;
};

const useStyles = makeStyles((theme) => ({
  linha: {
    borderTop: '1px solid',
    borderTopColor: theme.palette.cores.cinza[500],
    padding: theme.spacing(0, 2, 0, 6.5),
  },
  linhaRepetidas: {
    padding: theme.spacing(0, 2, 0, 6.5),
    marginBottom: '-15px',
  },
  containerLinha: {
    padding: 0,
  },
  linhaSemPrimeiro: {
    marginRight: '6px',
    padding: theme.spacing(0, 2, 0, 6.5),
    '&:not(:first-of-type)': {
      borderTop: `1px solid ${theme.palette.cores.cinza[500]}`,
      padding: theme.spacing(0, 2, 0, 5.9),
      margin: theme.spacing(0.5),
    },
  },
}));

const EntregaCidadeAccordion = ({
  entrega,
  expandirExterno,
}: EntregaCidadeAccordionProps): JSX.Element => {
  const [expandido, setExpandido] = useState(expandirExterno);
  const classes = useStyles();

  useEffect(() => {
    setExpandido(expandirExterno);
  }, [expandirExterno]);

  const expandirAccordion = () => {
    setExpandido(!expandido);
  };

  const bairrosFiltrados: EntregaBairro[] = entrega.bairros.reduce(
    (acc: EntregaBairro[], curr) => {
      if (!acc.some((item) => item.sequencia === curr.sequencia)) {
        acc.push(curr);
      }
      return acc;
    },
    [],
  );

  const somandoSequenciaBairros: EntregaBairro[] = entrega.bairros.reduce(
    (acc: EntregaBairro[], curr) => {
      if (!acc.some((item) => item.sequencia === curr.sequencia)) {
        acc.push(curr);
      }
      return acc;
    },
    [],
  );

  const countPJ = somandoSequenciaBairros.reduce((contador, bairro) => {
    if (bairro.tipoPessoa === 'PJ') {
      return contador + 1;
    }
    return contador;
  }, 0);

  const countPF = entrega.bairros.reduce((contador, bairro) => {
    if (bairro.tipoPessoa === 'PF') {
      return contador + 1;
    }
    return contador;
  }, 0);

  return (
    <Accordion expanded={expandido} onChange={expandirAccordion}>
      <EntregaCidadeHeaderAccordion
        entregas={expandido ? 0 : bairrosFiltrados.length}
        cidade={entrega.cidade.toUpperCase()}
        peso={entrega.pesoTotal}
        volume={entrega.volumeTotal}
        bairroPF={countPF}
        bairroPJ={countPJ}
      />
      <AccordionDetails className={classes.containerLinha}>
        {entrega.bairros.map((bairro, index) => {
          const valoresRepetidos =
            index === 0 ||
            bairro.sequencia !== entrega.bairros[index - 1]?.sequencia;

          return (
            <Box
              key={`bairro-${index}`}
              className={
                valoresRepetidos
                  ? classes.linhaSemPrimeiro
                  : classes.linhaRepetidas
              }
            >
              <ListaEntregaBairros
                entrega={bairro}
                valoresRepetidos={valoresRepetidos}
              />
            </Box>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};

export default EntregaCidadeAccordion;
