import {
  CargaResponse,
  CriarNegociacoesRequest,
  MotoristasResponse,
  CriarNegociacoesResponse,
} from './minhasCargasApiTypes';
import * as minhasCargasService from './minhasCargasService';

export const obterCarga = async (idCarga: number): Promise<CargaResponse> =>
  minhasCargasService.obterCarga(idCarga);

export const obterMotoristas = async (
  idFilial: number,
): Promise<MotoristasResponse> => minhasCargasService.obterMotoristas(idFilial);

export const criarNegociacoes = async (
  body: CriarNegociacoesRequest,
): Promise<CriarNegociacoesResponse[]> =>
  minhasCargasService.criarNegociacoes(body);

export const obterCargaFreteTabelado = async (
  idCarga: number,
): Promise<CargaResponse> =>
  minhasCargasService.obterCargaFreteTabelado(idCarga);
