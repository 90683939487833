import Toolbar from '@material-ui/core/Toolbar';
import { Theme, withStyles } from '@material-ui/core/styles';

export default withStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.cores.branco,
    height: '40px',
  },
  gutters: {
    padding: '0 24px',
  },
}))(Toolbar);
