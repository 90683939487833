import { Box, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useStyles } from 'features/configuracoes/styles/percentualPropostaFilial';
import IconeSorrisoTriste from 'assets/icones/IconeSorrisoTriste';
import { useDispatch, useSelector } from 'react-redux';
import { atualizarFiliaisOperation } from 'features/listaCargas/redux/listaCargasOperations';
import { RootState } from 'store/reducer';
import { ItemFilial } from 'features/listaCargas/api/listaCargasApiTypes';
import useMutationObserver from 'shared/customHooks/useMutationObserver';
import { salvarParametrosFilial } from 'features/configuracoes/utils/configuracoes';
import AutocompletePropostaFilial from './AutocompletePropostaFilial';
import ConfiguracoesPercentualProposta from './ConfiguracoesPercentualProposta';
import BotoesConfig from '../BotoesConfig';

const PercentualPropostaFilial = (): JSX.Element => {
  const classes = useStyles({});
  const dispatch = useDispatch();

  const {
    values,
    initial,
    setValues,
    setInitial,
    isChanged,
  } = useMutationObserver<ItemFilial>({} as ItemFilial);

  const { filiais } = useSelector(
    (reducer: RootState) => reducer.listaCargasReducer,
  );

  const handleSelecionarNovoAlvo = (value: ItemFilial) => {
    const updateOsDois = () => ({
      ...value,
      percentualFrotaPropria: value.percentualFrotaPropria * 100,
    });

    setInitial(updateOsDois);
    setValues(updateOsDois);
  };

  const handleLimparAlteracoes = () => {
    setValues(initial);
  };

  const handleSalvar = () => {
    salvarParametrosFilial(values, dispatch);
    setInitial(values);
  };

  const campoVazio = () => Object.keys(values).length === 0;

  useEffect(() => {
    atualizarFiliaisOperation()(dispatch);
  }, [dispatch]);

  return (
    <Box className={classes.container}>
      <AutocompletePropostaFilial
        filiais={filiais}
        classe={classes.autocomplete}
        setFilialAlvo={handleSelecionarNovoAlvo}
      />

      {!campoVazio() && (
        <>
          {values.integracaoPlanejadorAtivo ? (
            <>
              <ConfiguracoesPercentualProposta
                filialAlvo={values}
                setFilialAlvo={setValues}
              />
              <BotoesConfig
                disabledLimpar={!isChanged}
                disabledSalvar={!isChanged}
                limparFuncao={handleLimparAlteracoes}
                salvarFuncao={handleSalvar}
              />
            </>
          ) : (
            <Box className={classes.boxSorriso}>
              <IconeSorrisoTriste />
              <Typography variant="body1">
                Desculpe, essa Filial ainda não está integrada com o planejador
                local, por esse motivo não conseguimos configurar sua oferta
                inicial. Aguarde!
              </Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default PercentualPropostaFilial;
