import { createReducer } from '@reduxjs/toolkit';
import NegociacaoStatus from 'shared/constants/NegociacaoStatus';

import { ColaboradoresNegociadores } from 'features/configuracoes/api/configuracoesApiTypes';
import {
  atualizarFiltroStatus,
  atualizarFiltroFilial,
  limparDadosMinhasNegociacoes,
  atualizarNegociacoesUsuarioMaster,
  ativarAmbienteAssistido,
  atualizarDadosColaboradorAssistido,
  dispararBuscaNegociacaoColaboradores,
} from './minhasNegociacoesAction';
import { Negociacao } from '../api/minhasNegociacoesApiTypes';

export type MinhasNegociacoesState = {
  statusSelecionados: Set<string>;
  filiaisSelecionadas: Set<string>;
  negociacoesUsuarioMaster: Negociacao[];
  ativandoAmbienteAssistido: boolean;
  colaboradorAssistido: ColaboradoresNegociadores[] | [];
  buscaColaboradores: boolean;
};

export const estadoInicial: Readonly<MinhasNegociacoesState> = {
  statusSelecionados: new Set([
    NegociacaoStatus.NovaNegociacao.toString(),
    NegociacaoStatus.EmAndamento.toString(),
    NegociacaoStatus.AguardarGestao.toString(),
  ]),
  filiaisSelecionadas: new Set(),
  negociacoesUsuarioMaster: [],
  ativandoAmbienteAssistido: false,
  colaboradorAssistido: [],
  buscaColaboradores: false,
};

const minhasNegociacoesReducer = createReducer(estadoInicial, (builder) => {
  builder
    .addCase(atualizarFiltroStatus, (state, action) => ({
      ...state,
      statusSelecionados: new Set(action.payload),
    }))
    .addCase(atualizarFiltroFilial, (state, action) => ({
      ...state,
      filiaisSelecionadas: new Set(action.payload),
    }))
    .addCase(limparDadosMinhasNegociacoes, () => estadoInicial)
    .addCase(atualizarNegociacoesUsuarioMaster, (state, action) => ({
      ...state,
      negociacoesUsuarioMaster: action.payload,
    }))
    .addCase(ativarAmbienteAssistido, (state, action) => ({
      ...state,
      ativandoAmbienteAssistido: action.payload,
    }))
    .addCase(atualizarDadosColaboradorAssistido, (state, action) => ({
      ...state,
      colaboradorAssistido: action.payload,
    }))
    .addCase(dispararBuscaNegociacaoColaboradores, (state, action) => ({
      ...state,
      buscaColaboradores: action.payload,
    }))
    .addDefaultCase((state) => state);
});

export default minhasNegociacoesReducer;
