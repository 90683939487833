import React from 'react';
import { useStyles } from 'features/configuracoes/styles/styleEsquemaColaborador';
import { Box, Typography } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { listaColaboradorNegociador } from 'features/configuracoes/api/configuracoesApiTypes';

interface PropsListaColaboradoresConfigurados {
  listaColaboradoresNegociadores: listaColaboradorNegociador[] | undefined;
  selecionarColaboradorEdit: (id: number, nome: string) => void;
}

const ListaColaboradoresConfigurados = ({
  listaColaboradoresNegociadores,
  selecionarColaboradorEdit,
}: PropsListaColaboradoresConfigurados): JSX.Element => {
  const classes = useStyles();

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Box display="flex" gridGap="5px">
        <Typography className={classes.tituloInput}>
          Esquema de colaboradores configurados:
        </Typography>
        {listaColaboradoresNegociadores &&
          listaColaboradoresNegociadores?.length > 0 && (
            <CheckCircleOutlineIcon className={classes.iconCheck} />
          )}
      </Box>
      {listaColaboradoresNegociadores &&
      listaColaboradoresNegociadores.length > 0 ? (
        <Box className={classes.containerChips}>
          {listaColaboradoresNegociadores.map((item) => (
            <Box
              key={item.colaborador.idColaborador}
              onClick={() =>
                selecionarColaboradorEdit(
                  item.colaborador.idColaborador,
                  item.colaborador.nome,
                )
              }
              className={classes.chipFilial}
            >
              <Typography className={classes.textoChipFilial}>
                {item.colaborador.nome}
              </Typography>
            </Box>
          ))}
        </Box>
      ) : (
        <Box className={classes.containerListFiliais}>
          <BlockIcon
            color="disabled"
            style={{ width: '100px', height: '100px' }}
          />
          <Typography className={classes.tituloBlock}>
            Ainda não foi configurado nenhum esquema de horário
          </Typography>
        </Box>
      )}
    </div>
  );
};

export default ListaColaboradoresConfigurados;
