import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import { Button } from '@material-ui/core';
import DisablingTooltip from 'shared/components/tooltip/DisablingTooltip';
import IconeEnviar from 'assets/icones/IconeEnviar';
import { verificarSeEhArquivo } from 'shared/functions/validacaoArquivoUtils';
import TextField from 'shared/components/input/TextField';
import TipoMensagemProposta from 'shared/constants/TipoMensagemProposta';
import { abrirSnackbarErro } from 'shared/components/snackbar/redux/snackbarAction';
import TypographyContadorCaracteres from './ChatSkeleton/TypographyContadorCaracteres';

import { RootState } from '../../../store/reducer';
import {
  enviarMensagemDesabilitado,
  onClickBotaoEnviarTextoLivre,
  verificarArquivoVazio,
  verificarConteudoVazio,
  verificarTextoVazio,
} from '../utils/chatCampoTextoLivre';
import useStyles from '../styles/chatCampoTextoLivre';
import {
  MENSAGEM_EXTENSAO_INVALIDA,
  MENSAGEM_TAMANHO_INVALIDO,
  verificarExtensaoImagem,
  verificarTamanhoImagem,
} from '../utils/validacaoImagemUtils';

type ChatCampoTextoLivreProps = {
  chatImagem: File | undefined;
  setChatImagem: React.Dispatch<React.SetStateAction<File | undefined>>;
};

const stringVazia = '';
const chaveEnter = 'Enter';
const limiteCaracteresTextoLivre = 1000;

const ChatCampoTextoLivre = ({
  chatImagem,
  setChatImagem,
}: ChatCampoTextoLivreProps): JSX.Element => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [valorTexto, setvalorTexto] = useState(stringVazia);
  const [enviando, setEnviando] = useState(false);

  const {
    chatIdNegociacao,
    chatIdProposta,
    mensagemReferencia,
    chatExibicaoRespostaNegociador,
    chatPropostaStatus,
  } = useSelector((state: RootState) => state.chatNegociacaoReducer);

  const dispararSnackbarErro = (erro: string) => {
    dispatch(
      abrirSnackbarErro({
        mensagem: erro,
      }),
    );
  };

  const obterArquivo = (dadosTransferencia: DataTransfer): File | undefined => {
    let file;

    Array.from(dadosTransferencia.items).forEach((item): void => {
      if (verificarSeEhArquivo(item.kind?.toString()))
        file = item.getAsFile() as File;
    });
    return file;
  };

  const validarArquivoImagem = (arquivo: File | undefined): boolean => {
    if (!arquivo) {
      return false;
    }

    if (!verificarExtensaoImagem(arquivo.type)) {
      dispararSnackbarErro(MENSAGEM_EXTENSAO_INVALIDA);
      return false;
    }

    if (!verificarTamanhoImagem(arquivo.size)) {
      dispararSnackbarErro(MENSAGEM_TAMANHO_INVALIDO);
      return false;
    }

    return true;
  };

  const onPasteImagemChat = async (e: React.ClipboardEvent) => {
    const dadosTransferencia = e.clipboardData;

    if (!dadosTransferencia) return;
    const arquivo = obterArquivo(dadosTransferencia);

    if (!validarArquivoImagem(arquivo)) return;

    e.preventDefault();

    setChatImagem(arquivo);
  };

  const handleTexto = (evento: React.ChangeEvent<HTMLInputElement>) => {
    setvalorTexto(evento.target.value);
  };

  const enviarMensagem = async (
    tipoMensagem: TipoMensagemProposta,
    imagem?: File,
  ): Promise<void> => {
    await onClickBotaoEnviarTextoLivre({
      chatIdNegociacao,
      chatIdProposta,
      valor: undefined,
      motivo: valorTexto,
      propostaStatus: chatPropostaStatus,
      tipoMensagem,
      setEnviando,
      dispatch,
      arquivo: imagem,
    });
  };

  const enviarTextoLivre = async (): Promise<void> => {
    if (!verificarTextoVazio(valorTexto)) {
      setvalorTexto(stringVazia);

      await enviarMensagem(TipoMensagemProposta.TextoLivre);
    }
    setEnviando(false);
  };

  const enviarMensagemAnexo = async (): Promise<void> => {
    if (!verificarArquivoVazio(chatImagem)) {
      setvalorTexto(stringVazia);

      const imagem = chatImagem;

      await Promise.all([
        enviarMensagem(TipoMensagemProposta.Arquivo, imagem),
        setChatImagem(undefined),
      ]);
    }
    setEnviando(false);
  };

  const onClickBotaoEnviar = async (): Promise<void> => {
    if (chatImagem) await enviarMensagemAnexo();
    else await enviarTextoLivre();
  };

  const onKeyPressEnter = async (chave: string): Promise<void> => {
    if (chave === chaveEnter) {
      await onClickBotaoEnviar();
    }
  };

  const verificarBotaoDesabilitado = (): boolean =>
    enviarMensagemDesabilitado(
      chatExibicaoRespostaNegociador,
      mensagemReferencia?.tipo,
    ) ||
    enviando ||
    verificarConteudoVazio(valorTexto, chatImagem);

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box className={classes.boxCampoTextoLivre} onPaste={onPasteImagemChat}>
        <TextField
          id="outlined-basic"
          label="Digite sua mensagem aqui"
          variant="outlined"
          fullWidth
          value={valorTexto}
          onChange={handleTexto}
          onKeyPress={(evento) => onKeyPressEnter(evento.key)}
          disabled={enviarMensagemDesabilitado(
            chatExibicaoRespostaNegociador,
            mensagemReferencia?.tipo,
          )}
          inputProps={{ maxLength: limiteCaracteresTextoLivre }}
          size="small"
        />
        <DisablingTooltip
          disabled={verificarBotaoDesabilitado()}
          title="Enviar"
        >
          <Button
            aria-label="Enviar Planilha"
            variant="contained"
            color="primary"
            onClick={onClickBotaoEnviar}
            className={classes.iconeBotaoEnviar}
            type="submit"
            disabled={verificarBotaoDesabilitado()}
          >
            <IconeEnviar />
          </Button>
        </DisablingTooltip>
      </Box>
      <Box paddingTop={0.5} marginRight={6.5}>
        <TypographyContadorCaracteres
          limiteCaracteres={limiteCaracteresTextoLivre}
          valorTexto={valorTexto}
        />
      </Box>
    </Box>
  );
};

export default ChatCampoTextoLivre;
