import * as React from 'react';
import { GridColDef } from '@material-ui/data-grid';
import { Menu, MenuItem, IconButton } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';

interface CustomDataGridHeaderProps {
  columns: GridColDef[];
  onColumnVisibilityChange: (params: unknown) => void;
  hiddenColumns: string[];
}
const MenuColumn = ({
  columns,
  hiddenColumns,
  onColumnVisibilityChange,
}: CustomDataGridHeaderProps): JSX.Element => {
  const filteredColumns = columns.filter(
    (column) => column.field !== 'componente' && column.field !== 'acoes',
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleColumnToggle = (field: string, visible: boolean) => {
    const isVisible = !visible;
    onColumnVisibilityChange({ field, isVisible });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
          fontSize: '14px',
          color: '#616161',
        }}
      >
        Exibir colunas
      </span>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="long-menu"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        style={{ margin: '40px 0 0 -75px' }}
      >
        {filteredColumns.map((column) => (
          <div key={column.field} style={{ marginRight: 16 }}>
            <MenuItem
              key={column.field}
              onClick={() =>
                handleColumnToggle(
                  column.field,
                  !hiddenColumns.includes(column.field),
                )
              }
            >
              <input
                type="checkbox"
                checked={!hiddenColumns.includes(column.field)}
                onChange={(e) =>
                  handleColumnToggle(column.field, !e.target.checked)
                }
              />
              {column.headerName}
            </MenuItem>
          </div>
        ))}
      </Menu>
    </div>
  );
};

export default MenuColumn;
