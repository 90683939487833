import { makeStyles } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';

export const useStyles = makeStyles((theme) => ({
  accordion: {
    background: theme.palette.cores.cinza[50],
    borderRadius: spacingInPixels(theme, 2),
  },
  titulo: {
    color: theme.palette.cores.cinza[800],
  },
  raizAccordion: {
    '&:before': {
      backgroundColor: 'transparent',
    },
    '&.MuiAccordion-rounded': {
      borderRadius: spacingInPixels(theme, 2),
    },
  },
  iconeAccordion: {
    color: theme.palette.cores.cinza[700],
    scale: 1.5,
  },
}));
