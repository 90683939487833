import React, { useCallback, useEffect, useState } from 'react';
import { DetalhesDemandaDiaria } from 'features/detalhesDemandasDiaria/api/detalhesDemandasDiariaApiTypes';
import { titulosPagina } from 'features/detalhesDemandasDiaria/constants/TitulosPagina';
import TituloPagina from 'features/detalhesDemandasDiaria/components/TituloPagina';
import { useDispatch } from 'react-redux';
import { useLoading } from 'shared/customHooks/useLoading';
import { buscarDetalhesDemandasEmAprovacao } from 'features/detalhesDemandasDiaria/utils/detalhesDemandas';
import { ChaveOrdenacaoDetalhesDemandas } from 'features/detalhesDemandasDiaria/constants/Constants';
import TabelaDemandasPendentes from '../demandasPendentes/TabelaDemandasPendentes';
import { useStyles } from '../../styles/styleContainerDetalhes';

const ContainerDemandasEmAprovacao = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [demandas, setDemandas] = useState<DetalhesDemandaDiaria[]>([]);
  const [erro, setErro] = useState<boolean>(false);

  const buscaDemandasCallback = useCallback(
    async () =>
      buscarDetalhesDemandasEmAprovacao(setDemandas, setErro)(dispatch),
    [setDemandas, dispatch],
  );

  const [carregando, buscarDemandasLoading] = useLoading(buscaDemandasCallback);

  useEffect(() => {
    buscarDemandasLoading();
  }, [dispatch, buscarDemandasLoading]);

  return (
    <section className={classes.container}>
      <TituloPagina
        tituloPaginaConfig={titulosPagina.demandasEmAprovacao}
        quantidadeItens={demandas.length}
        carregando={carregando}
      />
      <TabelaDemandasPendentes
        carregando={carregando}
        demandas={demandas}
        erro={erro}
        chavesOrdenacao={[
          ChaveOrdenacaoDetalhesDemandas.NEGOCIADOR,
          ChaveOrdenacaoDetalhesDemandas.FILIAL,
          ChaveOrdenacaoDetalhesDemandas.NUMERO_ENTREGAS,
        ]}
      />
    </section>
  );
};

export default ContainerDemandasEmAprovacao;
