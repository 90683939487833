import { obterPorcentagemFormatada } from '../../../../../shared/functions/numberUtils';

export const calcularPorcentagem = (
  numerador: number | undefined,
  denominador: number | undefined,
): string => {
  if (!numerador || numerador <= 0 || !denominador || denominador <= 0) {
    return '-';
  }

  return obterPorcentagemFormatada(numerador, denominador);
};
