import React, { useMemo, useState } from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { PrioridadeCargaEnum } from '../constants/Types';
import CustomTooltip from '../../../shared/components/tooltip/CustomTooltip';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'start',
    '& > *': {
      margin: `6px 2px`,
    },
    '& > div': {
      minWidth: '38px',
      height: '26px',
      borderRadius: '14px',
    },
    span: {
      paddingfLeft: '10px',
      paddingRight: '10px',
    },
  },
  maxima: {
    backgroundColor: theme.palette.cores.vermelho[100],
    color: theme.palette.cores.vermelho[500],
  },
  media: {
    backgroundColor: theme.palette.cores.laranja[100],
    color: theme.palette.cores.mostarda[800],
  },
  baixa: {
    backgroundColor: theme.palette.cores.verdeClaro[100],
    color: theme.palette.cores.verde[700],
  },
}));

interface ChipPrioridadeCargaProps {
  valor: number;
  prioridade: PrioridadeCargaEnum;
}

const ChipPrioridadeCarga = ({
  valor,
  prioridade,
}: ChipPrioridadeCargaProps): JSX.Element => {
  const classes = useStyles();
  const [tooltip, setTooltip] = useState<string>('');

  const estilo = useMemo(() => {
    switch (prioridade) {
      case PrioridadeCargaEnum.media:
        setTooltip('média');
        return classes.media;
      case PrioridadeCargaEnum.baixa:
        setTooltip('baixa');
        return classes.baixa;
      case PrioridadeCargaEnum.maxima:
      default:
        setTooltip('máxima');
        return classes.maxima;
    }
  }, [classes.baixa, classes.maxima, classes.media, prioridade]);

  return (
    <CustomTooltip
      title={`Prioridade ${tooltip}`}
      placement="top"
      fontSize={14}
      arrow
    >
      <div className={classes.root}>
        {valor > 0 && (
          <Chip label={valor > 99 ? '99+' : valor} className={estilo} />
        )}
      </div>
    </CustomTooltip>
  );
};

export default ChipPrioridadeCarga;
