import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import { formatarNumeroParaStringMonetariaComSimbolo } from '../../../../shared/functions/dinheiroUtils';

import spacingInPixels from '../../../../shared/functions/materialUtils';

import { Indicador } from '../../api/detalhesAprovacaoSupervisorTypes';
import BadgeMedia from './BadgeMedia';
import BadgeValoresRelacionados from './BadgeValoresRelacionados';
import TextoViagensRota from './TextoViagensRota';

export type BadgesValorMediaProps = {
  indicador?: Indicador;
  valorProposta: number;
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3,1fr)',
    gridTemplateRows: 'repeat(3,min-content)',
    gridGap: spacingInPixels(theme, 1),

    '& :first-child': {
      gridRow: 'span 2',
    },
    '& :last-child': {
      gridColumn: 'span 3',
    },
  },
}));

const BadgesValorMedia = ({
  indicador,
  valorProposta,
}: BadgesValorMediaProps): JSX.Element => {
  const classes = useStyles();

  const percentualMercadoria = indicador?.valorPercentualMercadoria
    ? indicador?.valorPercentualMercadoria?.toLocaleString('pt-br')
    : '-';

  return (
    <Box className={classes.container}>
      <BadgeMedia
        valorMedio={indicador?.valorMedio}
        diasCalculo={indicador?.diasCalculo}
        valorProposta={valorProposta}
      />
      <BadgeValoresRelacionados>
        {`${formatarNumeroParaStringMonetariaComSimbolo(
          indicador?.valorMedioPorEntrega,
        )}/entrega`}
      </BadgeValoresRelacionados>
      <BadgeValoresRelacionados>
        {`${formatarNumeroParaStringMonetariaComSimbolo(
          indicador?.valorMedioPorKg,
        )}/kg`}
      </BadgeValoresRelacionados>
      <BadgeValoresRelacionados>
        {`${formatarNumeroParaStringMonetariaComSimbolo(
          indicador?.valorMedioPorKm,
        )}/km`}
      </BadgeValoresRelacionados>
      <BadgeValoresRelacionados>
        {percentualMercadoria}% mercadoria
      </BadgeValoresRelacionados>
      <TextoViagensRota
        quantidadeViagens={indicador?.quantidadeViagens}
        diasCalculo={indicador?.diasCalculo}
      />
    </Box>
  );
};

export default BadgesValorMedia;
