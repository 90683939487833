import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import BoxScroll from 'shared/components/box/BoxScroll';
import msgConstants from 'shared/constants/msgConstants';
import { OrigemNegociacao } from 'features/informacoesNegociacao/api/informacoesNegociacaoApiTypes';
import { RootState } from '../../../../../store/reducer';

import PropostaGrid from './PropostaGrid';
import { filtrarPropostas } from './utils/proposta';
import PropostaAplicativoCard from './PropostaAplicativoCard';
import MensagemListaVazia from '../MensagemListaVazia';

const useStyles = makeStyles(() => ({
  propostasContainer: {
    overflow: 'auto',
    maxHeight: 'calc(100vh - 324px)',
    height: 'calc(100vh - 324px)',
  },
  propostasContainerFreteTabelado: {
    overflow: 'auto',
    maxHeight: 'calc(100vh - 260px)',
    height: 'calc(100vh - 260px)',
  },
}));

const PropostasTab = (): JSX.Element => {
  const { propostas, propostasAplicativo } = useSelector(
    (state: RootState) => state.informacoesNegociacaoReducer,
  );

  const { negociacao } = useSelector(
    (state: RootState) => state.informacoesNegociacaoReducer,
  );

  const { pesquisa } = useSelector(
    (state: RootState) => state.motoristasCardReducer,
  );

  const propostasFiltradas = useMemo(
    () => filtrarPropostas(propostas, pesquisa),
    [pesquisa, propostas],
  );

  const propostasFiltradasAplicativo = useMemo(
    () => filtrarPropostas(propostasAplicativo, pesquisa),

    [pesquisa, propostasAplicativo],
  );
  const existemPropostas = () =>
    propostasFiltradasAplicativo?.length > 0 || propostasFiltradas?.length > 0;

  const classes = useStyles({
    listaVazia: !existemPropostas(),
  });

  return (
    <BoxScroll>
      <Box
        className={
          negociacao?.origemNegociacao === OrigemNegociacao.negociador
            ? classes.propostasContainer
            : classes.propostasContainerFreteTabelado
        }
      >
        {!existemPropostas() && (
          <MensagemListaVazia titulo={msgConstants.LISTA_NEGOCIACOES_VAZIA} />
        )}
        {propostasFiltradasAplicativo.map((proposta, index) => (
          <PropostaAplicativoCard
            key={proposta.id}
            sequencia={index + 1}
            proposta={proposta}
          />
        ))}
        {propostasFiltradas.map((proposta, index) => (
          <PropostaGrid
            key={proposta.id + propostasFiltradasAplicativo.length}
            sequencia={index + propostasFiltradasAplicativo.length + 1}
            proposta={proposta}
          />
        ))}
      </Box>
    </BoxScroll>
  );
};

export default PropostasTab;
