import React from 'react';
import { Grid } from '@material-ui/core';
import { Demanda } from 'features/dashboards/negociadores/api/listagemNegociadoresApiTypes';
import ConteudoTabelaNegociacao from './ConteudoTabelaNegociacao';
import HeaderTabelaNegociacao from './HeaderTabelaNegociacao';

export interface TabelaNegociacoesProps {
  carregando: boolean;
  mensagemErro: string;
  demandas?: Demanda[];
  estilo?: React.CSSProperties;
}

const TabelaNegociacoes = ({
  carregando,
  mensagemErro,
  demandas,
  estilo,
}: TabelaNegociacoesProps): JSX.Element => {
  const itensHeader = ['SLA', 'Romaneio', 'Filial', 'Cidade'];
  return (
    <Grid container direction="column">
      <Grid item>
        <HeaderTabelaNegociacao itensHeader={itensHeader} />
      </Grid>
      <Grid item style={estilo}>
        <ConteudoTabelaNegociacao
          carregando={carregando}
          demandas={demandas}
          mensagemErro={mensagemErro}
        />
      </Grid>
    </Grid>
  );
};

export default TabelaNegociacoes;
