import { Paper } from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';

import { spacingInPixels } from '../../functions/materialUtils';

export default withStyles((theme: Theme) => ({
  root: {
    padding: spacingInPixels(theme, 3),
  },
}))(Paper);
