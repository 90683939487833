import spacingInPixels from 'shared/functions/materialUtils';
import { makeStyles } from '@material-ui/core';
import { Rubik } from '../../../constants/font';

export const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: '14px',
    fontFamily: Rubik,
    fontWeight: 500,
  },
  textoInativo: {
    fontWeight: 400,
  },
  root: {
    '&.MuiListItem-gutters': {
      paddingLeft: 0,
      height: spacingInPixels(theme, 5),
      marginTop: spacingInPixels(theme, 1),
      marginBottom: spacingInPixels(theme, 1),
      width: spacingInPixels(theme, 38),
      borderTopRightRadius: spacingInPixels(theme, 5),
      borderBottomRightRadius: spacingInPixels(theme, 5),
      '&:hover': {
        background: theme.palette.cores.cinza[200],
      },
    },
  },
  rootAtivo: {
    '&.MuiListItem-gutters': {
      background: theme.palette.cores.cinza[300],

      '&:hover': {
        background: theme.palette.cores.cinza[300],
      },
    },
  },

  link: {
    color: theme.palette.grey[800],
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  iconeContainer: {
    '&.MuiListItemIcon-root': {
      width: spacingInPixels(theme, 7),
      height: spacingInPixels(theme, 5),
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  icone: {
    width: spacingInPixels(theme, 5),
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconeAtivo: {
    background: theme.palette.cores.cinza[300],
    borderRadius: spacingInPixels(theme, 5),
  },
}));
