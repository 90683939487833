import { ConexaoAPI } from '../../../shared/classes/conexaoAPI';
import { PropostasSupervisorAgrupadas } from './aprovacoesSupervisorApiTypes';

const obterUrlBuscarPropostasSupervisor = (): string =>
  `${process.env.REACT_APP_URL_API_BASE}/negociacoes/propostas/supervisor`;

export const obterPropostasSupervisor = async (): Promise<PropostasSupervisorAgrupadas> => {
  const url = obterUrlBuscarPropostasSupervisor();
  const conexaoApi = new ConexaoAPI(url, 'get');
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<PropostasSupervisorAgrupadas>();
  const response = await conectar();

  return response.data;
};
