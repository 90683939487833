import React from 'react';

import CloseRounded from '@material-ui/icons/CloseRounded';

import EstadoAtualProposta, {
  EstadoAtualPropostaType,
} from '../../../features/minhasNegociacoes/constants/EstadoAtualProposta';
import IconeConversa from '../../../assets/icones/IconeConversa';
import IconTooltipStatusMotorista from './BackgroundIconStatusMotorista';
import IconeCheck from '../../../assets/icones/IconeCheck';
import theme from '../../../theme';
import IconeRelogio from '../../../assets/icones/IconeRelogio';
import IconePolegar from '../../../assets/icones/IconePolegar';

export type IconStatusMotoristaProps = {
  codigo: EstadoAtualPropostaType;
};

type IconStatus = {
  codigo: EstadoAtualPropostaType;
  componente: JSX.Element;
};

const IconStatusMotorista = ({
  codigo,
}: IconStatusMotoristaProps): JSX.Element => {
  const CONTRAPROPOSTA: IconStatus = {
    codigo: EstadoAtualProposta.PENDENTE_NEGOCIADOR,
    componente: (
      <IconTooltipStatusMotorista
        icon={<IconeConversa />}
        title="Enviou contraproposta"
      />
    ),
  };

  const PROPOSTA_ACEITA: IconStatus = {
    codigo: EstadoAtualProposta.CONCLUIDA,
    componente: (
      <IconTooltipStatusMotorista
        icon={<IconeCheck fill={theme.palette.cores.branco} width="13px" />}
        title="Aceitou proposta"
        backgroundColor={theme.palette.cores.verde[400]}
      />
    ),
  };

  const RECUSADA: IconStatus = {
    codigo: EstadoAtualProposta.RECUSADA,
    componente: (
      <IconTooltipStatusMotorista
        icon={
          <CloseRounded
            style={{
              color: theme.palette.cores.branco,
              width: '13px',
              height: '13px',
            }}
          />
        }
        title="Não aceitou a negociação"
        backgroundColor={theme.palette.cores.vermelho[400]}
      />
    ),
  };

  const ACEITE_MOTORISTA: IconStatus = {
    codigo: EstadoAtualProposta.ACEITE_MOTORISTA,
    componente: (
      <IconTooltipStatusMotorista
        icon={<IconeCheck fill={theme.palette.cores.branco} width="13px" />}
        title="Aceite do agregado"
        backgroundColor={theme.palette.cores.verde[400]}
      />
    ),
  };

  const PENDENTE_SUPERVISOR: IconStatus = {
    codigo: EstadoAtualProposta.PENDENTE_SUPERVISOR,
    componente: (
      <IconTooltipStatusMotorista
        icon={<IconeRelogio width="12px" />}
        title="Aguardando gestor"
        backgroundColor={theme.palette.cores.amarelo[400]}
      />
    ),
  };

  const RESPOSTA_SUPERVISOR: IconStatus = {
    codigo: EstadoAtualProposta.RESPOSTA_SUPERVISOR,
    componente: (
      <IconTooltipStatusMotorista
        icon={
          <IconePolegar width="12px" fill={theme.palette.cores.cinza[900]} />
        }
        title="Avaliado pelo gestor"
        backgroundColor={theme.palette.cores.verde[400]}
      />
    ),
  };

  return (
    Array.of(
      PROPOSTA_ACEITA,
      CONTRAPROPOSTA,
      RECUSADA,
      ACEITE_MOTORISTA,
      RESPOSTA_SUPERVISOR,
      PENDENTE_SUPERVISOR,
    ).find((status) => status.codigo === codigo)?.componente || <></>
  );
};

export default IconStatusMotorista;
