import { DadosUsuarioResponse } from '../../login/api/loginApiTypes';

export const obterPrimeiroNomeMotorista = (
  nomeCompleto: string | undefined,
): string =>
  nomeCompleto === undefined ? '' : nomeCompleto.trim().split(' ')[0];

export const obterNomeUsuario = (
  dadosUsuario: DadosUsuarioResponse | null,
): string => obterPrimeiroNomeMotorista(dadosUsuario?.usuario.nome);
