import React, { useCallback, useEffect, useState } from 'react';
import { DetalhesDemandaDiaria } from 'features/detalhesDemandasDiaria/api/detalhesDemandasDiariaApiTypes';
import TituloPagina from 'features/detalhesDemandasDiaria/components/TituloPagina';
import { titulosPagina } from 'features/detalhesDemandasDiaria/constants/TitulosPagina';
import { buscarDetalhesDemandasNaoIniciadas } from 'features/detalhesDemandasDiaria/utils/detalhesDemandas';

import { useDispatch } from 'react-redux';
import { useLoading } from 'shared/customHooks/useLoading';

import TabelaDemandasNaoIniciadas from './TabelaDemandasNaoIniciadas';
import { useStyles } from '../../styles/styleContainerDetalhes';

const ContainerDemandasNaoIniciadas = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [demandas, setDemandas] = useState<DetalhesDemandaDiaria[]>([]);
  const [erro, setErro] = useState<boolean>(false);

  const buscaDemandasCallback = useCallback(
    async () =>
      buscarDetalhesDemandasNaoIniciadas(setDemandas, setErro)(dispatch),
    [setDemandas, dispatch],
  );

  const [carregando, buscarDemandasLoading] = useLoading(buscaDemandasCallback);

  useEffect(() => {
    buscarDemandasLoading();
  }, [dispatch, buscarDemandasLoading]);

  return (
    <section className={classes.container}>
      <TituloPagina
        tituloPaginaConfig={titulosPagina.demandasNaoIniciadas}
        quantidadeItens={demandas.length}
        carregando={carregando}
      />
      <TabelaDemandasNaoIniciadas
        carregando={carregando}
        demandas={demandas}
        erro={erro}
      />
    </section>
  );
};

export default ContainerDemandasNaoIniciadas;
