import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { buscarDadosPermissaoUsuario } from 'features/login/redux/loginOperation';
import BoxPagina from '../../../shared/components/box/BoxPagina';
import ConfiguracaoAba from '../constants/configuracaoAbaSwitch';
import { spacingInPixels } from '../../../shared/functions/materialUtils';
import CabecalhoNegociacaoAutomatizadas from './CabecalhoNegociacaoAutomatizadas';
import TabelaNegociacoesAutomatizadas from './TabelaNegociacoesAutomatizadas';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: spacingInPixels(theme, 6),
  },
}));

const NegociacaoAutomatizadas = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selecionarAba, setSelecionarAba] = useState<ConfiguracaoAba>(
    ConfiguracaoAba.freteTabelado,
  );

  useEffect(() => {
    buscarDadosPermissaoUsuario()(dispatch);
  }, [dispatch]);

  return (
    <BoxPagina component="section" className={classes.content}>
      <CabecalhoNegociacaoAutomatizadas
        selecionarAba={selecionarAba}
        setSelecionarAba={setSelecionarAba}
      />
      {selecionarAba === ConfiguracaoAba.freteTabelado && (
        <TabelaNegociacoesAutomatizadas />
      )}
    </BoxPagina>
  );
};

export default NegociacaoAutomatizadas;
