import React from 'react';
import { Carga } from '../api/detalhesAprovacaoSupervisorTypes';
import CardDetalhesCarga from '../components/DetalhesCarga/CardDetalhesCarga';

export type DetalhesCargaProps = {
  carga?: Carga;
  carregando: boolean;
};

const DetalhesCarga = ({
  carga,
  carregando,
}: DetalhesCargaProps): JSX.Element => {
  const QuantidadeTotalEntregas =
    (carga?.quantidadeEntregasPF || 0) + (carga?.quantidadeEntregasPJ || 0);

  const QuantidadeEntregasPFePJ = `${carga?.quantidadeEntregasPJ} PJ/ ${carga?.quantidadeEntregasPF} PF`;

  return (
    <>
      <CardDetalhesCarga
        cidadePrincipal={carga?.cidadePrincipal}
        romaneio={carga?.romaneio}
        valorMercadoria={carga?.valorMercadoria}
        pesoTotal={carga?.peso}
        volumeTotal={carga?.volume}
        distancia={carga?.distancia}
        totalEntregas={QuantidadeTotalEntregas}
        entregasPFePJ={QuantidadeEntregasPFePJ}
        carregando={carregando}
      />
    </>
  );
};

export default DetalhesCarga;
