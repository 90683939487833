import React from 'react';
import { DialogContent, Dialog, Box, makeStyles } from '@material-ui/core';
import DialogTitle from '../../../../../shared/components/modal/DialogTitle';

type ModalZoomImagemChatProps = {
  modalAberto: boolean;
  setModalAberto: (modalAberto: boolean) => void;
  urlImagem: string;
};

const useStyles = makeStyles(() => ({
  paper: {
    maxWidth: '90vw',
    maxHeight: '100vh',
  },
  imagem: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));

const ModalZoomImagemChat = ({
  modalAberto,
  setModalAberto,
  urlImagem,
}: ModalZoomImagemChatProps): JSX.Element => {
  const classes = useStyles();

  const fecharModal = () => {
    setModalAberto(false);
  };

  return (
    <Dialog
      open={modalAberto}
      onClose={fecharModal}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle onClose={fecharModal} />
      <DialogContent>
        <Box display="flex" justifyContent="center" alignItems="center">
          <img
            src={urlImagem}
            alt="zoom imagem baixada"
            className={classes.imagem}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ModalZoomImagemChat;
