import { createReducer } from '@reduxjs/toolkit';

import { DadosUsuarioResponse } from '../api/loginApiTypes';

import {
  atualizarDadosUsuario,
  atualizarPaginasAcesso,
  removerDadosUsuario,
} from './loginAction';

export type LoginState = {
  dados: DadosUsuarioResponse | null;
  deslogado: boolean;
};

const estadoInicial: Readonly<LoginState> = {
  dados: null,
  deslogado: false,
};

const loginReducer = createReducer(estadoInicial, (builder) => {
  builder
    .addCase(atualizarDadosUsuario, (_state, action) => ({
      dados: action.payload,
      deslogado: false,
    }))
    .addCase(removerDadosUsuario, () => ({
      dados: null,
      deslogado: true,
    }))
    .addCase(atualizarPaginasAcesso, (state, action) => {
      const novasPaginasAcesso = action.payload;
      if (state.dados) {
        return {
          ...state,
          dados: {
            ...state.dados,
            usuario: {
              ...state.dados.usuario,
              paginasAcesso:
                novasPaginasAcesso !== null
                  ? novasPaginasAcesso
                  : state.dados.usuario.paginasAcesso,
            },
          },
        };
      }
      return state;
    })
    .addDefaultCase((state) => state);
});

export default loginReducer;
