import IconeEscudoAlerta from 'assets/icones/IconeEscudoAlerta';
import IconeEscudoCerto from 'assets/icones/IconeEscudoCerto';
import IconeEscudoCortado from 'assets/icones/IconeEscudoCortado';
import React from 'react';
import theme from 'theme';

interface TarifarioProps {
  title: string;
  backgroundColor: string;
  children: JSX.Element;
}

export const DicionarioTarifario: Record<number, TarifarioProps> = {
  0: {
    title: 'Tarifário não atualizado TMS',
    backgroundColor: theme.palette.cores.vermelho[100] ?? '#ffff',
    children: <IconeEscudoCortado />,
  },

  1: {
    title: 'Tarifário atualizado TMS',
    backgroundColor: theme.palette.cores.verdeClaro[100] ?? '#ffff',
    children: <IconeEscudoCerto />,
  },
  2: {
    title: 'Tarifário aguardando integração TMS',
    backgroundColor: theme.palette.cores.amarelo[100] ?? '#ffff',
    children: <IconeEscudoAlerta />,
  },
};
