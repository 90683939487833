import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import CustomTooltip from 'shared/components/tooltip/CustomTooltip';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '3fr 3.5fr 2.5fr 2.5fr',
    paddingBottom: spacingInPixels(theme, 1),
  },
  header: {
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: spacingInPixels(theme, 2),
    lineHeight: spacingInPixels(theme, 4),
    color: theme.palette.cores.cinza[800],
  },
}));

export interface HeaderTabelaNegociacaoProps {
  itensHeader: string[];
  estilo?: ClassNameMap<'container'>;
}

const HeaderTabelaNegociacao = ({
  itensHeader,
  estilo,
}: HeaderTabelaNegociacaoProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={estilo?.container || classes.container}>
      {itensHeader.map((item) => (
        <CustomTooltip title={item}>
          <div className={classes.header} key={`cabecalho-tabela-${item}`}>
            {item}
          </div>
        </CustomTooltip>
      ))}
    </div>
  );
};

export default HeaderTabelaNegociacao;
