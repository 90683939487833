import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import { spacingInPixels } from 'shared/functions/materialUtils';
import PropostaStatus, {
  PropostaStatusType,
} from 'shared/constants/PropostaStatus';
import { Rubik } from 'shared/constants/font';

export type StatusProposta = {
  codigo: PropostaStatusType | undefined;
  texto: string;
  componente: JSX.Element;
};

export type BoxStatusPropostaProps = {
  codigoStatus: PropostaStatusType;
};

const BoxStatus = withStyles((theme) => ({
  root: {
    height: spacingInPixels(theme, 3),
    minWidth: spacingInPixels(theme, 16),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    padding: '0px 10px',
  },
}))(Box);

const Texto = withStyles((theme) => ({
  root: {
    fontSize: '16px',
    fontFamily: Rubik,
    fontWeight: 400,
    color: theme.palette.cores.cinza[900],
  },
}))(Typography);

const ACEITE_MOTORISTA: StatusProposta = {
  codigo: PropostaStatus.ACEITE_MOTORISTA,
  texto: 'Aceite do agregado',
  componente: (
    <BoxStatus bgcolor="cores.azulPiscina.100">
      <Texto>Aceite do agregado</Texto>
    </BoxStatus>
  ),
};

const CONTRAPROPOSTA: StatusProposta = {
  codigo: PropostaStatus.CONTRAPROPOSTA,
  texto: 'Contraproposta',
  componente: (
    <BoxStatus bgcolor="cores.mostarda.400">
      <Texto>Contraproposta</Texto>
    </BoxStatus>
  ),
};

const ACEITOU_PROPOSTA: StatusProposta = {
  codigo: PropostaStatus.CONCLUIDA,
  texto: 'Concluida',
  componente: (
    <BoxStatus bgcolor="cores.verdeClaro.300">
      <Texto>Concluída</Texto>
    </BoxStatus>
  ),
};

const RECUSADA: StatusProposta = {
  codigo: PropostaStatus.RECUSA_NEGOCIADOR,
  texto: 'Recusada Negociador',
  componente: (
    <BoxStatus bgcolor="cores.vermelho.200">
      <Texto>Recusada</Texto>
    </BoxStatus>
  ),
};

const AGUARDANDO: StatusProposta = {
  codigo: PropostaStatus.AGUARDANDO,
  texto: 'Aguardando',
  componente: (
    <BoxStatus bgcolor="cores.azul.200">
      <Texto>Aguardando</Texto>
    </BoxStatus>
  ),
};

const PENDENTE_SUPERVISOR: StatusProposta = {
  codigo: PropostaStatus.PENDENTE_SUPERVISOR,
  texto: 'Aguardando Supervisor',
  componente: (
    <BoxStatus bgcolor="cores.azul.200">
      <Texto>Aprovação Sup.</Texto>
    </BoxStatus>
  ),
};

const AGUARDANDO_ACEITE_SUPERVISOR: StatusProposta = {
  codigo: PropostaStatus.AGUARDANDO_ACEITE_SUPERVISOR,
  texto: 'Aguardando Aceite Supervisor',
  componente: (
    <BoxStatus bgcolor="cores.azul.200">
      <Texto>Aceite Sup.</Texto>
    </BoxStatus>
  ),
};

const RECUSA_MOTORISTA: StatusProposta = {
  codigo: PropostaStatus.RECUSA_MOTORISTA,
  texto: 'Recusa do agregado',
  componente: (
    <BoxStatus bgcolor="cores.vermelho.200">
      <Texto>Recusa do agregado</Texto>
    </BoxStatus>
  ),
};

const INDISPONIVEL: StatusProposta = {
  codigo: PropostaStatus.Indisponivel,
  texto: 'Indisponivel',
  componente: (
    <BoxStatus bgcolor="cores.vermelho.200">
      <Texto>Cancelada</Texto>
    </BoxStatus>
  ),
};

export const StatusPropostaConstants: StatusProposta[] = [
  ACEITOU_PROPOSTA,
  ACEITE_MOTORISTA,
  AGUARDANDO,
  CONTRAPROPOSTA,
  RECUSADA,
  PENDENTE_SUPERVISOR,
  AGUARDANDO_ACEITE_SUPERVISOR,
  INDISPONIVEL,
  RECUSA_MOTORISTA,
];

const BoxStatusProposta = ({
  codigoStatus,
}: BoxStatusPropostaProps): JSX.Element => {
  const statusProposta = StatusPropostaConstants.find(
    (status) => codigoStatus === status.codigo,
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      {statusProposta?.componente ??
        (statusProposta?.codigo === PropostaStatus.Indisponivel &&
          INDISPONIVEL.componente)}
    </Box>
  );
};

export default BoxStatusProposta;
