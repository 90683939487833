import { obterAlertasPorPeriodo } from 'features/indicadores/api/indicadoresManager';
import { IndicadoresAlertaResponse } from '../api/indicadoresApiTypes';
import { PeriodoFiltroIndicadores } from '../constants/Constants';

export const buscarDadosIndicadoresAlerta = async (
  setDadosIndicadores: (indicadores: IndicadoresAlertaResponse) => void,
  setErro: (erro: boolean) => void,
  periodo: PeriodoFiltroIndicadores,
): Promise<void> =>
  obterAlertasPorPeriodo(periodo)
    .then((response) => {
      setDadosIndicadores(response);
      setErro(false);
    })
    .catch(() => {
      setErro(true);
    });
