import {
  DemandaColaborador,
  DemandasNegociadorResponse,
} from './listagemNegociadoresApiTypes';
import * as listagemNegociadoresService from './listagemNegociadoresService';

export const buscarNegociadores = async (): Promise<DemandaColaborador[]> =>
  listagemNegociadoresService.obterListagemNegociador();

export const buscarDemandasNegociador = async (
  idNegociador: number,
): Promise<DemandasNegociadorResponse> =>
  listagemNegociadoresService.obterDemandasNegociador(idNegociador);
