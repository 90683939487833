import { useTheme } from '@material-ui/core';
import * as React from 'react';
import { SVGProps } from 'react';

const IconeLocalizacao = ({
  fill,
  width,
  height,
  ...outros
}: SVGProps<SVGSVGElement>): JSX.Element => {
  const theme = useTheme();
  return (
    <svg
      width={width || 12}
      height={height || 14}
      viewBox="0 0 12 14"
      fill="none"
      {...outros}
    >
      <path
        d="M6 .333A5.333 5.333 0 0 0 .667 5.667c0 3.6 4.7 7.667 4.9 7.84a.667.667 0 0 0 .866 0c.234-.173 4.9-4.24 4.9-7.84A5.333 5.333 0 0 0 6 .333ZM6 12.1c-1.42-1.333-4-4.206-4-6.433a4 4 0 0 1 8 0c0 2.227-2.58 5.107-4 6.433ZM6 3a2.667 2.667 0 1 0 0 5.333A2.667 2.667 0 0 0 6 3Zm0 4a1.333 1.333 0 1 1 0-2.667A1.333 1.333 0 0 1 6 7Z"
        fill={fill || theme.palette.cores.cinza[700]}
      />
    </svg>
  );
};

export default IconeLocalizacao;
