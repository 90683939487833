import React from 'react';
import { Grid } from '@material-ui/core';
import { MetricasFilial as MetricasFilialType } from 'features/dashboards/filiais/api/listagemFiliaisApiTypes';
import { formatarNumeroParaStringMonetariaComSimbolo } from 'shared/functions/dinheiroUtils';
import { formatarDecimalComPontoParaStringComVirgula } from 'shared/functions/numberUtils';
import DetalhesMetricas from '../DetalhesMetricas';

export interface MetricasFilialProps {
  carregando: boolean;
  metricas?: MetricasFilialType;
}

const MetricasFilial = ({
  carregando,
  metricas,
}: MetricasFilialProps): JSX.Element => {
  const labelMediaSaving = `${
    metricas?.savingCustoPorNegociacao ||
    metricas?.savingCustoPorNegociacao === 0
      ? formatarDecimalComPontoParaStringComVirgula(
          metricas?.savingCustoPorNegociacao,
        )
      : '-'
  }%`;

  const labelEntregas = `${
    metricas?.mediaEntregasPorViagem?.toString() || '-'
  } ${metricas?.mediaEntregasPorViagem !== 1 ? 'entregas' : 'entrega'}`;

  return (
    <Grid container wrap="nowrap" justify="space-between">
      <Grid item>
        <DetalhesMetricas
          carregando={carregando}
          conteudo={labelMediaSaving}
          titulo="Média de Saving e custo por negociação"
        />
      </Grid>
      <Grid item md={2}>
        <DetalhesMetricas
          carregando={carregando}
          conteudo={formatarNumeroParaStringMonetariaComSimbolo(
            metricas?.valorAtualPorEntrega,
          )}
          titulo="Valor por entrega"
        />
      </Grid>
      <Grid item>
        <DetalhesMetricas
          carregando={carregando}
          conteudo={formatarNumeroParaStringMonetariaComSimbolo(
            metricas?.mediaValorPorKg,
          )}
          titulo="Média de Valor por KG"
        />
      </Grid>
      <Grid item>
        <DetalhesMetricas
          carregando={carregando}
          conteudo={labelEntregas}
          titulo="Média de entregas por viagem"
        />
      </Grid>
    </Grid>
  );
};

export default MetricasFilial;
