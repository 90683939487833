import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import IconeInformacaoChat from '../../../../assets/icones/IconeInformacaoChat';
import spacingInPixels from '../../../../shared/functions/materialUtils';

export type JustificativaType = {
  texto: string;
  valorSugeridoRobo?: boolean;
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%',
    backgroundColor: theme.palette.cores.cinza[200],
    paddingTop: spacingInPixels(theme, 3),
    paddingBottom: spacingInPixels(theme, 3),
    paddingleft: spacingInPixels(theme, 2),
    paddingRight: spacingInPixels(theme, 2),
    borderRadius: spacingInPixels(theme, 2),
  },
  textoIndicador: {
    fontSize: '16px',
    fontWeight: 400,
    color: theme.palette.cores.cinza[900],
    width: '90%',
  },
}));

const Justificativa = ({
  texto,
  valorSugeridoRobo,
}: JustificativaType): JSX.Element => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <IconeInformacaoChat />
      {valorSugeridoRobo ? (
        <Typography className={classes.textoIndicador}>
          {texto !== '-' ? (
            texto
          ) : (
            <span>
              Valor sugerido pelo <b>algoritmo</b>
            </span>
          )}
        </Typography>
      ) : (
        <Typography className={classes.textoIndicador}>{texto}</Typography>
      )}
    </Box>
  );
};

export default Justificativa;
