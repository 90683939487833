import { useTheme } from '@material-ui/core/styles';
import * as React from 'react';
import { SVGProps } from 'react';

const IconePapel = ({
  fill,
  ...outros
}: SVGProps<SVGSVGElement>): JSX.Element => {
  const theme = useTheme();

  return (
    <svg
      width={20}
      height={16}
      fill="none"
      {...outros}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 6.94a1.307 1.307 0 0 0-.06-.27v-.09a1.07 1.07 0 0 0-.19-.28l-6-6a1.07 1.07 0 0 0-.28-.19h-.1a1.1 1.1 0 0 0-.31-.11H3a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V6.94Zm-6-3.53L16.59 6H15a1 1 0 0 1-1-1V3.41ZM18 13a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h9v3a3 3 0 0 0 3 3h3v5Z"
        fill={fill || theme.palette.cores.azulMartins[500]}
      />
    </svg>
  );
};
export default IconePapel;
