import { makeStyles } from '@material-ui/core';
import React from 'react';

import spacingInPixels from '../../functions/materialUtils';
import CustomSkeleton from '../skeleton/CustomSkeleton';

export const useEstiloLinhaDoTempo = makeStyles((theme) => ({
  container: {
    padding: `${spacingInPixels(theme, 2)} ${spacingInPixels(
      theme,
      3,
    )} ${spacingInPixels(theme, 1)}`,
  },

  containerBadges: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: spacingInPixels(theme, 2),
  },

  containerLinhaTempo: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacingInPixels(theme, 1.5),
    marginTop: spacingInPixels(theme, 1.25),
  },
}));

const SkeletonLinhaDoTempo = (): JSX.Element => {
  const classes = useEstiloLinhaDoTempo();

  return (
    <div className={classes.container}>
      <div className={classes.containerLinhaTempo}>
        {new Array(4).fill(null).map((_, index) => (
          <CustomSkeleton
            width="100%"
            height={58}
            borderradius={16}
            key={`skeleton-item-linha-tempo-${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
};

export default SkeletonLinhaDoTempo;
