import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  titulo: {
    fontSize: 18,
  },
}));

const Titulo = (): JSX.Element => {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center" height="100%">
      <Typography variant="h5" color="primary" className={classes.titulo}>
        GESTÃO DE CARGAS DE AGREGADOS
      </Typography>
    </Box>
  );
};

export default Titulo;
