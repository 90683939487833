import React, { ReactNode } from 'react';
import { EstiloBotaoOutlineIconeTexto, LabelBotao } from './styles';

interface BotaoOutlineIconeTextoProps {
  color?: string;
  children: ReactNode;
  icon: React.ReactElement;
  onClick?: () => void;
}

const BotaoOutlineIconeTexto = ({
  color = '',
  children,
  icon,
  onClick,
}: BotaoOutlineIconeTextoProps): JSX.Element => {
  return (
    <EstiloBotaoOutlineIconeTexto
      corBorda={color}
      corTexto={color}
      onClick={onClick}
    >
      {React.cloneElement(icon, {
        fill: color,
      })}
      <LabelBotao> {children} </LabelBotao>
    </EstiloBotaoOutlineIconeTexto>
  );
};

BotaoOutlineIconeTexto.defaultProps = {
  color: '',
  onClick: () => null,
};

export default BotaoOutlineIconeTexto;
