import { OpcaoSwitchAbaType } from 'shared/components/aba/SwitchAba';

enum AbasConfiguracoes {
  Perfil,
  Sistema,
}

export const OpcoesAbasConfiguracoes: OpcaoSwitchAbaType[] = [
  {
    id: AbasConfiguracoes.Perfil,
    valor: 'Perfil',
    conteudoBadge: null,
  },
  {
    id: AbasConfiguracoes.Sistema,
    valor: 'Sistema',
    conteudoBadge: null,
  },
];

export default AbasConfiguracoes;
