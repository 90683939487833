import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, useTheme } from '@material-ui/core';
import { ArrowBackRounded } from '@material-ui/icons';
import BotaoIcone from 'shared/components/button/BotaoIcone';
import IconePreenchido from 'shared/components/icons/IconePreenchido';
import CustomSkeleton from 'shared/components/skeleton/CustomSkeleton';
import { StatusDemandaDiariaDashboard } from '../constants/Constants';
import { useStyles } from '../styles/tituloPagina';
import { TituloPaginaProps } from '../types/tituloPagina';

const TituloPagina = ({
  tituloPaginaConfig,
  quantidadeItens,
  carregando,
}: TituloPaginaProps): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const { descricao, icone, status } = tituloPaginaConfig;

  const corFonte =
    status === StatusDemandaDiariaDashboard.PENDENTES
      ? theme.palette.cores.laranjaForte[700]
      : theme.palette.cores.azulMartins[500];

  const corIcone = useMemo(() => {
    switch (status) {
      case StatusDemandaDiariaDashboard.DEMANDAS_DO_DIA:
        return theme.palette.cores.azul[500];
      case StatusDemandaDiariaDashboard.PENDENTES:
        return theme.palette.cores.laranjaForte[700];
      case StatusDemandaDiariaDashboard.EM_NEGOCIACAO:
        return theme.palette.cores.azulPiscina[400];
      case StatusDemandaDiariaDashboard.RECUSADAS:
        return theme.palette.cores.vermelho[450];
      case StatusDemandaDiariaDashboard.NAO_INICIADAS:
        return theme.palette.cores.mostarda[800];
      case StatusDemandaDiariaDashboard.EM_APROVACAO:
        return theme.palette.cores.azulPiscina[900];
      case StatusDemandaDiariaDashboard.NEGOCIADAS:
        return theme.palette.cores.verdeClaro[500];
      default:
        return theme.palette.cores.azulMartins[500];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  if (carregando)
    return <CustomSkeleton borderradius={4} height={48} width="30%" />;

  return (
    <section className={classes.container}>
      <Box className={classes.boxFlex}>
        <BotaoIcone
          onClick={() => history.goBack()}
          descricao="Voltar ao Painel de Gestão"
        >
          <ArrowBackRounded />
        </BotaoIcone>
        <IconePreenchido
          icone={icone}
          cor={corIcone}
          className={classes.iconeTitulo}
        />
        <h1 className={classes.titulo} style={{ color: corFonte }}>
          {descricao} ({quantidadeItens})
        </h1>
      </Box>
    </section>
  );
};

export default TituloPagina;
