import { Box } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import spacingInPixels from '../../functions/materialUtils';

export default withStyles((theme) => ({
  root: {
    backgroundColor: '#006CFF10',
    marginLeft: spacingInPixels(theme, 1),
    padding: theme.spacing(1, 2),
    borderRadius: spacingInPixels(theme, 1),
    '& span': {
      fontSize: '22px',
      lineHeight: spacingInPixels(theme, 3),
      fontWeight: 500,
    },
  },
}))(Box);
