import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import { IndicadoresAlertaResponse } from 'features/indicadores/api/indicadoresApiTypes';
import ConteudoErro from 'features/indicadores/components/ConteudoErro';
import { mensagemListaVazia } from 'features/indicadores/constants/Constants';
import { Bar } from 'react-chartjs-2';
import SkeletonContainerAlerta from './SkeletonContainerAlerta';

export const useStyles = makeStyles(() => ({
  container: {
    minHeight: 300,
    width: '100%',

    '& > canvas': {
      maxHeight: 300,
    },
  },
}));

interface GraficoVencimentoProps {
  indicadoresAlerta: IndicadoresAlertaResponse;
  carregando: boolean;
}

const GraficoVencimento = ({
  indicadoresAlerta,
  carregando,
}: GraficoVencimentoProps): JSX.Element => {
  const classes = useStyles();
  const listaCargaVazia = !indicadoresAlerta.alertaCarga.length;
  const theme = useTheme();

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
      },
    },
  };

  const data = {
    labels: indicadoresAlerta.alertaCarga.map(
      (indicadores) => indicadores.periodo,
    ),
    datasets: [
      {
        data: indicadoresAlerta.alertaCarga.map(
          (indicadores) => indicadores.quantidade || 0,
        ),
        borderColor: theme.palette.cores.azulMartins[400],
        backgroundColor: theme.palette.cores.azulMartins[400],
        borderRadius: 12,
      },
    ],
  };

  return (
    <section className={classes.container}>
      {(() => {
        if (carregando) return <SkeletonContainerAlerta />;
        if (listaCargaVazia)
          return <ConteudoErro mensagem={mensagemListaVazia} />;
        return (
          <div className={classes.container}>
            <Bar options={options} data={data} />{' '}
          </div>
        );
      })()}
    </section>
  );
};

export default GraficoVencimento;
