import React from 'react';
import { Box, Typography, Tooltip } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { spacingInPixels } from '../../functions/materialUtils';

type LabelHeaderAccordionProps = {
  icone?: string;
  titulo: string;
  estilo?: {
    tituloCor?: string;
    fontWeight?: number;
  };
};

const useStyles = makeStyles((theme) => ({
  titulo: {
    fontSize: '12px',
    color: theme.palette.primary.main,
  },
  icone: {
    height: '20px',
  },
}));

const LabelHeaderAccordion = (
  props: LabelHeaderAccordionProps,
): JSX.Element => {
  const { icone, titulo, estilo } = props;
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box display="flex">
      {icone && (
        <Box
          style={{
            marginRight: spacingInPixels(theme, 1),
          }}
        >
          <Tooltip title={titulo}>
            <img src={icone} alt={titulo} className={classes.icone} />
          </Tooltip>
        </Box>
      )}
      <Box display="flex" flexDirection="column">
        <Typography
          variant="h6"
          className={classes.titulo}
          style={{ color: estilo?.tituloCor, fontWeight: estilo?.fontWeight }}
        >
          {titulo}
        </Typography>
      </Box>
    </Box>
  );
};

LabelHeaderAccordion.defaultProps = {
  icone: undefined,
  estilo: {
    tituloCor: undefined,
    fontWeight: undefined,
  },
};

export default LabelHeaderAccordion;
