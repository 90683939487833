import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { RootState } from 'store/reducer';
import { useSelector } from 'react-redux';
import Paper from '../../../shared/components/paper/Paper';
import BoxPagina from '../../../shared/components/box/BoxPagina';
import FeedbackSnackbar from '../../../shared/components/snackbar/FeedbackSnackbar';

import { buscar as buscarCargas } from '../api/detalhesCargaManager';
import { buscarNegociacao } from '../../informacoesNegociacao/api/informacoesNegociacaoManager';
import {
  Negociacao,
  OrigemNegociacao,
} from '../../informacoesNegociacao/api/informacoesNegociacaoApiTypes';
import { DetalhesCargaResponse } from '../api/detalhesCargaApiTypes';

import Cabecalho from './Cabecalho';
import InformacoesGeraisCarga from './InformacoesGeraisCarga';
import LocalidadesAccordion from './LocalidadesAccordion';

interface RouteParams {
  idNegociacao: string;
  idCarga: string;
}

const DetalhesCarga = (): JSX.Element => {
  const history = useHistory();
  const params = useParams<RouteParams>();
  const { negociacao: negociacaoFreteTabelado } = useSelector(
    (state: RootState) => state.informacoesNegociacaoReducer,
  );

  const [carga, setCarga] = useState<DetalhesCargaResponse | undefined>(
    undefined,
  );
  const [snackbarErroAberto, setSnackbarErroAberto] = useState(false);
  const [snackbarErroMensagem, setSnackbarErroMensagem] = useState('');
  const [negociacao, setNegociacao] = useState<Negociacao | undefined>(
    undefined,
  );

  const setSnackBarEstados = (mensagem: string) => {
    setSnackbarErroAberto(true);
    setSnackbarErroMensagem(mensagem);
  };

  useEffect(() => {
    const buscar = async () => {
      await buscarCargas(params.idCarga)
        .then((resposta) => setCarga(resposta))
        .catch((e: Error) => setSnackBarEstados(e.message));
    };

    if (!carga) {
      buscar();
    }
  }, [carga, params.idCarga]);

  useEffect(() => {
    const buscar = async () => {
      if (
        negociacaoFreteTabelado?.origemNegociacao ===
        OrigemNegociacao.freteTabelado
      ) {
        await buscarNegociacao(Number(params.idNegociacao))
          .then((resposta) => setNegociacao(resposta))
          .catch(() => setNegociacao(undefined));
        return;
      }
      if (
        negociacaoFreteTabelado?.origemNegociacao ===
        OrigemNegociacao.negociador
      ) {
        await buscarNegociacao(Number(params.idNegociacao))
          .then((resposta) => setNegociacao(resposta))
          .catch(() => setNegociacao(undefined));
      }
    };

    if (!negociacao) {
      buscar();
    }
  }, [
    negociacao,
    negociacaoFreteTabelado?.origemNegociacao,
    params.idNegociacao,
  ]);

  const handleLinkClick = () => {
    if (negociacao?.origemNegociacao === OrigemNegociacao.freteTabelado) {
      const estadoPersonalizado = {
        origemFreteTabelado: true,
        idFilial: negociacao?.carga.idFilialDestino,
      };
      history.push({
        pathname: `/negociacoes-automatizada/${params.idNegociacao}`,
        state: estadoPersonalizado,
      });
      return;
    }

    history.push(`/minhas-negociacoes/${params.idNegociacao}`, {
      origemFreteTabelado: false,
      idFilial: negociacao?.carga.idFilialDestino,
    });
  };

  return (
    <BoxPagina component="section">
      <Cabecalho
        titulo="Negociação > Detalhes da carga"
        voltar={handleLinkClick}
        romaneio={negociacao?.carga.romaneio}
        status={negociacao?.status}
        urgente={negociacao?.urgente}
      />
      {carga && negociacao && (
        <>
          <InformacoesGeraisCarga
            filial={negociacao.carga.filial}
            destino={negociacao.carga.cidade}
            dataCarregamento={negociacao.dataCarregamento}
            distancia={negociacao.carga.quilometragem}
            entregas={negociacao.carga.entregas}
            volumeTotal={negociacao.carga.volume}
            pesoTotal={negociacao.carga.peso}
            cargaPJ={negociacao?.carga.entregasPJ}
            cargaPF={negociacao?.carga.entregasPF}
          />
          <Paper>
            <LocalidadesAccordion
              entregas={carga.entregas}
              quantidadeEntregas={negociacao?.carga.entregas}
            />
          </Paper>
        </>
      )}

      <FeedbackSnackbar
        open={snackbarErroAberto}
        onClose={() => {
          setSnackbarErroAberto(false);
        }}
        severity="error"
        mensagem={snackbarErroMensagem}
      />
    </BoxPagina>
  );
};

export default DetalhesCarga;
