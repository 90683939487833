import { useTheme } from '@material-ui/core/styles';
import * as React from 'react';
import { SVGProps } from 'react';

const IconeBalaoChat = ({
  fill,
  width,
  height,
  ...outros
}: SVGProps<SVGSVGElement>): JSX.Element => {
  const theme = useTheme();

  return (
    <svg
      width={width || 12}
      height={height || 12}
      viewBox="0 0 12 12"
      fill="none"
      {...outros}
    >
      <path
        d="M8.816 3.083H2.983a.583.583 0 0 0 0 1.167h5.833a.583.583 0 0 0 0-1.167Zm0 2.334H2.983a.583.583 0 1 0 0 1.166h5.833a.583.583 0 0 0 0-1.166ZM9.983.167H1.816a1.75 1.75 0 0 0-1.75 1.75V7.75a1.75 1.75 0 0 0 1.75 1.75h6.761l2.159 2.164a.582.582 0 0 0 .897-.091.584.584 0 0 0 .1-.323V1.917a1.75 1.75 0 0 0-1.75-1.75Zm.583 9.677L9.23 8.502a.583.583 0 0 0-.414-.169h-7a.583.583 0 0 1-.583-.583V1.917a.583.583 0 0 1 .583-.584h8.167a.583.583 0 0 1 .583.584v7.927Z"
        fill={fill || theme.palette.cores.azulPiscina[400]}
      />
    </svg>
  );
};

export default IconeBalaoChat;
