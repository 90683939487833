import * as React from 'react';
import { SVGProps } from 'react';

const IconeSincronizar = ({
  fill,
  ...outros
}: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg width="20" height="14" viewBox="0 0 20 14" fill="none" {...outros}>
      <path
        d="M19.7099 4.28994L15.7099 0.289941C15.6167 0.196703 15.506 0.122742 15.3841 0.0722818C15.2623 0.0218215 15.1318 -0.00415039 14.9999 -0.00415039C14.7336 -0.00415039 14.4782 0.101638 14.2899 0.289941C14.1016 0.478245 13.9958 0.73364 13.9958 0.999941C13.9958 1.26624 14.1016 1.52164 14.2899 1.70994L16.5899 3.99994H4.9999C4.73468 3.99994 4.48033 4.1053 4.29279 4.29283C4.10525 4.48037 3.9999 4.73472 3.9999 4.99994C3.9999 5.26516 4.10525 5.51951 4.29279 5.70705C4.48033 5.89458 4.73468 5.99994 4.9999 5.99994H18.9999C19.1973 5.99896 19.39 5.93955 19.5538 5.82922C19.7175 5.71889 19.8449 5.56256 19.9199 5.37994C19.9965 5.19783 20.0174 4.99712 19.98 4.80313C19.9426 4.60914 19.8487 4.43057 19.7099 4.28994ZM14.9999 7.99994H0.999895C0.80247 8.00093 0.609754 8.06033 0.446034 8.17066C0.282313 8.281 0.154915 8.43732 0.0798953 8.61994C0.00331588 8.80205 -0.0176068 9.00276 0.0197673 9.19675C0.0571415 9.39074 0.151138 9.56932 0.289895 9.70994L4.2899 13.7099C4.38286 13.8037 4.49346 13.8781 4.61532 13.9288C4.73718 13.9796 4.86788 14.0057 4.9999 14.0057C5.13191 14.0057 5.26261 13.9796 5.38447 13.9288C5.50633 13.8781 5.61693 13.8037 5.7099 13.7099C5.80362 13.617 5.87802 13.5064 5.92879 13.3845C5.97956 13.2627 6.00569 13.132 6.00569 12.9999C6.00569 12.8679 5.97956 12.7372 5.92879 12.6154C5.87802 12.4935 5.80362 12.3829 5.7099 12.2899L3.4099 9.99994H14.9999C15.2651 9.99994 15.5195 9.89458 15.707 9.70705C15.8945 9.51951 15.9999 9.26516 15.9999 8.99994C15.9999 8.73472 15.8945 8.48037 15.707 8.29284C15.5195 8.1053 15.2651 7.99994 14.9999 7.99994Z"
        fill={fill || '#2962FF'}
      />
    </svg>
  );
};

export default IconeSincronizar;
