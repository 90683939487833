import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';
import { SkeletonFiltro, StyledChip } from './ComponentesFiltroChip';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    gap: spacingInPixels(theme, 1),
    overflowX: 'auto',
    paddingBottom: spacingInPixels(theme, 0.5),
    height: 'inherit',
    '& [data-ativo=true] div': {
      color: theme.palette.cores.branco,
      backgroundColor: theme.palette.cores.azul[600],
    },
    '&::-webkit-scrollbar': {
      height: spacingInPixels(theme, 0.75),
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      background: `${theme.palette.cores.cinza[500]}60`,
      borderRadius: spacingInPixels(theme, 1),
    },
  },
}));

export interface OpcaoFiltro {
  descricao: string;
  chave: string | number;
  desabilitado?: boolean;
}

export interface FiltroChipCompletoProps {
  carregando?: boolean;
  filtroAtual: string | number;
  filtros: OpcaoFiltro[];
  quantidade?: number;
  onClickFiltro: (filtroSelecionado: OpcaoFiltro) => void;
}

const FiltroChipCompleto = ({
  carregando,
  filtroAtual,
  filtros,
  quantidade,
  onClickFiltro,
}: FiltroChipCompletoProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {carregando ? (
        <SkeletonFiltro quantidade={quantidade || 0} />
      ) : (
        filtros?.map((filtro) => (
          <div
            data-ativo={filtro.chave === filtroAtual}
            key={`filtro-${filtro.chave}`}
          >
            <StyledChip
              label={`${filtro.descricao}`}
              onClick={() => onClickFiltro(filtro)}
              disabled={filtro.desabilitado}
            />
          </div>
        ))
      )}
    </div>
  );
};

export default FiltroChipCompleto;
