import React from 'react';

import { Tooltip } from '@material-ui/core';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';

import { useTheme } from '@material-ui/core/styles';
import IconeCheck from '../../../assets/icones/IconeCheck';

export type TipoIconeSnackbar = 'error' | 'warning' | 'success';

export type IconeFeedbackProps = {
  tipo: TipoIconeSnackbar;
};

type Icone = {
  tipo: TipoIconeSnackbar;
  componente: JSX.Element;
};

const IconeFeedback = (props: IconeFeedbackProps): JSX.Element => {
  const theme = useTheme();
  const { tipo } = props;

  const ERRO: Icone = {
    tipo: 'error',
    componente: (
      <Tooltip title="Erro">
        <CancelRoundedIcon style={{ color: theme.palette.cores.branco }} />
      </Tooltip>
    ),
  };

  const AVISO: Icone = {
    tipo: 'warning',
    componente: (
      <Tooltip title="Alerta">
        <ErrorRoundedIcon style={{ color: theme.palette.warning.main }} />
      </Tooltip>
    ),
  };

  const SUCESSO: Icone = {
    tipo: 'success',
    componente: (
      <Tooltip title="Sucesso">
        <IconeCheck fill={theme.palette.cores.branco} />
      </Tooltip>
    ),
  };

  return (
    Array.of(ERRO, AVISO, SUCESSO).find((icone) => icone.tipo === tipo)
      ?.componente || <></>
  );
};

export default IconeFeedback;
