import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';
import CustomSkeleton, {
  DadoSkeleton,
} from 'shared/components/skeleton/CustomSkeleton';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '2fr 3.5fr 2.5fr 1.5fr 2fr',
    padding: `${spacingInPixels(theme, 0.5)} 0`,
    height: spacingInPixels(theme, 4),
    '& :nth-child(4)': {
      marginLeft: '1.5em',
    },
  },
}));

const SkeletonConteudoTabelaNegociacao = (): JSX.Element => {
  const classes = useStyles();

  const dadosSkeletons: DadoSkeleton[] = [
    { width: '50%', borderRadius: 4 },
    { width: '70%', borderRadius: 4 },
    { width: '60%', borderRadius: 4 },
    { width: '40%', borderRadius: 15 },
    { width: '50%', borderRadius: 4 },
  ];

  return (
    <div className={classes.container}>
      {dadosSkeletons.map((skeleton, index) => (
        <CustomSkeleton
          width={skeleton.width}
          height={24}
          borderradius={skeleton.borderRadius}
          key={`skeleton-demanda-coluna-${index + 1}`}
        />
      ))}
    </div>
  );
};

export default SkeletonConteudoTabelaNegociacao;
