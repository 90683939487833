import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import PaginaNaoEncontrada from 'features/pagina-nao-encontrada';
import { buscarDadosPermissaoUsuario } from 'features/login/redux/loginOperation';
import { useDispatch } from 'react-redux';
import { StatusDemandaDiariaDashboard } from '../constants/Constants';
import ContainerDemandasTotaisPendentes from './ContainerDemandasTotaisPendentes';
import ContainerDemandasAndamento from './demandasAndamento/ContainerDemandasAndamento';
import ContainerDemandasNaoIniciadas from './demandasNaoIniciadas/ContainerDemandasNaoIniciadas';
import ContainerNegociacoesRecusadas from './negociacoesRecusadas/ContainerNegociacoesRecusadas';
import ContainerDemandasConcluidas from './demandasConcluidas/ContainerDemandasConcluidas';
import ContainerDemandasEmAprovacao from './demandasEmAprovacao/ContainerDemandasEmAprovacao';
import { RouteParams } from '../types/containerTypes';

const DetalhesDemandasDiaria = (): JSX.Element => {
  const params = useParams<RouteParams>();
  const dispatch = useDispatch();

  useEffect(() => {
    buscarDadosPermissaoUsuario()(dispatch);
  }, [dispatch]);

  switch (params.tipoDemanda) {
    case StatusDemandaDiariaDashboard.DEMANDAS_DO_DIA:
    case StatusDemandaDiariaDashboard.PENDENTES:
      return <ContainerDemandasTotaisPendentes />;
    case StatusDemandaDiariaDashboard.EM_NEGOCIACAO:
      return <ContainerDemandasAndamento />;
    case StatusDemandaDiariaDashboard.NAO_INICIADAS:
      return <ContainerDemandasNaoIniciadas />;
    case StatusDemandaDiariaDashboard.NEGOCIADAS:
      return <ContainerDemandasConcluidas />;
    case StatusDemandaDiariaDashboard.RECUSADAS:
      return <ContainerNegociacoesRecusadas />;
    case StatusDemandaDiariaDashboard.EM_APROVACAO:
      return <ContainerDemandasEmAprovacao />;
    default:
      return <PaginaNaoEncontrada />;
  }
};

export default DetalhesDemandasDiaria;
