import { createAction } from '@reduxjs/toolkit';

import { NovaNegociacao } from '../api/minhasCargasApiTypes';

export const atualizarNovaNegociacao = createAction<NovaNegociacao>(
  'minhasCargasActions/ATUALIZAR_NOVA_NEGOCIACAO',
);

export const removerNovaNegociacao = createAction<number>(
  'minhasCargasActions/REMOVER_NOVA_NEGOCIACAO',
);

export const removerTodasNegociacoes = createAction(
  'minhasCargasActions/REMOVER_TODAS_NEGOCIACOES',
);
