import React from 'react';
import PaginaPoliticaDePrivacidade from 'features/politicaDePrivacidade';
import { Box } from '@material-ui/core';

const PoliticaDePrivacidade = (): JSX.Element => {
  return (
    <Box component="section">
      <PaginaPoliticaDePrivacidade />
    </Box>
  );
};

export default PoliticaDePrivacidade;
