import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import InputRubik from '../../../shared/components/input/InputRubik';
import EditarValorContrapropostaBadges from '../../../shared/components/modalComum/EditarValorContrapropostaBadges';
import ModalContraproposta from '../../../shared/components/modalComum/ModalComum';
import ModalContrapropostaAcoes from '../../../shared/components/modalComum/ModalComumAcoes';
import { RootState } from '../../../store/reducer';
import TipoValorContraproposta from '../constants/TipoValorContraproposta';
import { reabrirNegociacaoOperation } from '../redux/chatNegociacaoOperation';
import { LIMITE_CARACTERES_TEXTO_LIVRE } from '../../../shared/constants/utilConstants';
import { ModalFazerContrapropostaPropTypes } from '../types/modalTypes';

const ModalReabrirNegociacao = ({
  setModalContrapropostaAberto,
  modalContrapropostaAberto,
}: ModalFazerContrapropostaPropTypes): JSX.Element => {
  const dispatch = useDispatch();
  const { negociacao, idNegociacao, idPropostaComFoco } = useSelector(
    (state: RootState) => state.informacoesNegociacaoReducer,
  );

  const [justificativa, setJustificativa] = useState('');
  const [tipoValor, setTipoValor] = useState<TipoValorContraproposta>(
    TipoValorContraproposta.ValorProposto,
  );
  const [valor, setValor] = useState<number | undefined>(undefined);
  const [valorInput, setValorInput] = useState<number | undefined>(undefined);

  const fecharModal = () => {
    setModalContrapropostaAberto(false);
    setJustificativa('');
    setTipoValor(TipoValorContraproposta.ValorProposto);
    setValor(undefined);
  };
  const confirmarReaberturaNegociacao = () => {
    if (!idNegociacao || !idPropostaComFoco || !valor || !justificativa) return;

    dispatch(
      reabrirNegociacaoOperation(idNegociacao, idPropostaComFoco, {
        valor,
        justificativa,
      }),
    );
    fecharModal();
  };

  return (
    <ModalContraproposta
      modalAberto={modalContrapropostaAberto}
      fecharModal={fecharModal}
      titulo="Reabrirrr Negociação"
      conteudo={
        <Box>
          <Box>
            <EditarValorContrapropostaBadges
              tipoValor={tipoValor}
              setTipoValor={setTipoValor}
              valorProposto={valor}
              setValorProposto={setValor}
              valorInput={valorInput}
              setValorInput={setValorInput}
              valorFreteTerceiro={negociacao?.valorFreteTerceiro}
              valorFrotaPropria={
                negociacao?.sugestaoValorInicialRobo
                  ? negociacao?.sugestaoValorInicialRobo
                  : negociacao?.valorFrotaPropria
              }
              valorEntregas={negociacao?.carga.entregas}
              valorMercadoria={negociacao?.carga.valor}
              valorPeso={negociacao?.carga.peso}
              valorQuilometragem={negociacao?.carga.quilometragem}
            />
          </Box>
          <Box marginTop="32px">
            <InputRubik
              fullWidth
              multiline
              label="Justifique o valor"
              size="small"
              variant="outlined"
              value={justificativa}
              onChange={(e) => setJustificativa(e.target.value)}
              inputProps={{ maxLength: LIMITE_CARACTERES_TEXTO_LIVRE }}
              rows={4}
            />
          </Box>
        </Box>
      }
      acoes={
        <ModalContrapropostaAcoes
          textoBotaoPrimario="Enviar"
          callbackBotaoPrimario={confirmarReaberturaNegociacao}
          textoBotaoSecundario="Cancelar"
          callbackBotaoSecundario={fecharModal}
          botaoPrimarioDesativado={!valor || !justificativa.trim().length}
        />
      }
    />
  );
};

export default ModalReabrirNegociacao;
