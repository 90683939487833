import { Dispatch } from 'redux';
import StatusDemandaEnum from 'shared/constants/StatusDemandaEnum';
import {
  formatarDataBrasileira,
  formatarHoraMinuto,
} from '../../../shared/functions/dataUtils';
import { descricaoFiltroNotificacao } from '../constants/Constants';
import {
  NegociacaoTipoAlerta,
  Demanda,
} from '../negociadores/api/listagemNegociadoresApiTypes';
import { buscarDadosDemandasNegociador } from '../redux/listagemDemandasNegociador/listagemDemandasNegociadorOperation';

export enum AbaSelecionada {
  'Negociador',
  'Notificacoes',
}

export const ordenarDemandasPorPrioridadeCarga = (
  demandas: Demanda[],
): Demanda[] =>
  [...demandas].sort(
    (a, b) => new Date(a.sla).getTime() - new Date(b.sla).getTime(),
  );

export const separarDemandasPorFilial = (
  filiais: string[],
  demandas: Demanda[],
): Map<string, Demanda[]> => {
  const mapListaDemandasFiltradasPorFilial = new Map();
  mapListaDemandasFiltradasPorFilial.set(
    `Todas as filiais (${demandas.length})`,
    ordenarDemandasPorPrioridadeCarga(demandas),
  );
  filiais.forEach((nomeFilial) => {
    const demandasFilial = demandas.filter(
      (demanda) => demanda.nomeFilial === nomeFilial,
    );
    mapListaDemandasFiltradasPorFilial.set(
      `${nomeFilial} (${demandasFilial.length})`,
      ordenarDemandasPorPrioridadeCarga(demandasFilial),
    );
  });

  return mapListaDemandasFiltradasPorFilial;
};

export const separarFiliaisDistintas = (filiais: string[]): string[] => {
  const onlyUnique = (value: string, index: number, self: string[]) =>
    self.indexOf(value) === index;

  return filiais.filter((filial, index, self) =>
    onlyUnique(filial, index, self),
  );
};

export const filtrarDemandasPorCategoria = (
  demandas: Demanda[],
  categoria: NegociacaoTipoAlerta,
): Demanda[] =>
  ordenarDemandasPorPrioridadeCarga(
    demandas.filter(
      (demanda) =>
        demanda.categorias.some(
          (categoriaDemanda) => categoriaDemanda === categoria,
        ) &&
        ![StatusDemandaEnum.Concluida, StatusDemandaEnum.Cancelada].some(
          (status) => status === demanda.status,
        ),
    ),
  );

export const separarDemandasComNotificacaoPorCategoria = (
  demandas: Demanda[],
  tempoFiltroSemVisualizacao: string,
  tempoFiltroSemAprovacao: string,
): Map<string, Demanda[]> =>
  new Map()
    .set(
      descricaoFiltroNotificacao.todas,
      ordenarDemandasPorPrioridadeCarga(demandas),
    )
    .set(
      `${descricaoFiltroNotificacao.naoVista} +${tempoFiltroSemVisualizacao}`,
      filtrarDemandasPorCategoria(demandas, NegociacaoTipoAlerta.naoVista),
    )
    .set(
      `${descricaoFiltroNotificacao.semAprovacao} +${tempoFiltroSemAprovacao}`,
      filtrarDemandasPorCategoria(demandas, NegociacaoTipoAlerta.semAprovacao),
    )
    .set(
      descricaoFiltroNotificacao.alertaVencimento,
      filtrarDemandasPorCategoria(
        demandas,
        NegociacaoTipoAlerta.alertaVencimento,
      ),
    );

export const selecionarAbaBaseadoNaQuantidadeAlertas = (
  quantidadeAlertas: number,
): AbaSelecionada =>
  quantidadeAlertas ? AbaSelecionada.Notificacoes : AbaSelecionada.Negociador;

export const formatarDataHoraSLA = (data: string): string => {
  const diaMesAno = formatarDataBrasileira(new Date(data));
  const horaMinuto = formatarHoraMinuto(new Date(data));
  return `${diaMesAno} - ${horaMinuto}`;
};

export const buscarDemandasNegociador = (
  dispatch: Dispatch,
  setCarregando: (carregando: boolean) => void,
  idNegociador?: number,
): void => {
  buscarDadosDemandasNegociador(setCarregando, idNegociador)(dispatch);
};
