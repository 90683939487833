import { createReducer } from '@reduxjs/toolkit';

import OrigemCarga from 'shared/constants/OrigemCarga';
import { verificarCargaPlanilha } from '../../../shared/constants/OrigemCarga';
import { Carga } from '../../../shared/types/apiTypes';
import { ItemFilial } from '../api/listaCargasApiTypes';

import {
  adicionarIdCarga,
  removerIdCarga,
  limparDados,
  atualizarDataFim,
  atualizarDataInicio,
  atualizarFiliaisSelecionadas,
  atualizarPesquisa,
  atualizarCargas,
  atualizarPagina,
  atualizarQuantidadePaginas,
  atualizarQuantidadeCargas,
  atualizarFiliais,
  atualizarOrigemCarga,
  atualizarOrdenacao,
} from './listaCargasAction';

type CargaPaginaType = {
  cargas: Carga[];
  pagina: number;
  quantidadePaginas: number;
  quantidadeCargas: number;
};

export type ListaCargasState = {
  idsCargasSelecionadas: Set<number>;
  pesquisa: string;
  dataInicio: Date | null;
  dataFim: Date | null;
  filiaisSelecionadas: string[];
  filiais: Readonly<ItemFilial>[];
  stateOrigemCarga: OrigemCarga;
  cargasPlanilha: CargaPaginaType;
  cargasPlanejador: CargaPaginaType;
  ordenacao: boolean;
};

export const estadoInicial: Readonly<ListaCargasState> = {
  idsCargasSelecionadas: new Set(),
  pesquisa: '',
  dataInicio: null,
  dataFim: null,
  filiaisSelecionadas: [],
  filiais: [],
  stateOrigemCarga: OrigemCarga.Planilha,
  cargasPlanilha: {
    cargas: [],
    pagina: 1,
    quantidadePaginas: 1,
    quantidadeCargas: 0,
  },
  cargasPlanejador: {
    cargas: [],
    pagina: 1,
    quantidadePaginas: 1,
    quantidadeCargas: 0,
  },
  ordenacao: false,
};

const listaCargasReducer = createReducer(estadoInicial, (builder) => {
  builder
    .addCase(atualizarCargas, (state, action) => {
      if (verificarCargaPlanilha(action.payload.origem)) {
        return {
          ...state,
          cargasPlanilha: {
            ...state.cargasPlanilha,
            cargas: action.payload.cargas,
          },
        };
      }

      return {
        ...state,
        cargasPlanejador: {
          ...state.cargasPlanejador,
          cargas: action.payload.cargas,
        },
      };
    })
    .addCase(atualizarDataFim, (state, action) => ({
      ...state,
      dataFim: action.payload,
      cargasPlanilha: {
        ...state.cargasPlanilha,
        pagina: 1,
      },
      cargasPlanejador: {
        ...state.cargasPlanejador,
        pagina: 1,
      },
    }))
    .addCase(atualizarDataInicio, (state, action) => ({
      ...state,
      cargasPlanilha: {
        ...state.cargasPlanilha,
        pagina: 1,
      },
      cargasPlanejador: {
        ...state.cargasPlanejador,
        pagina: 1,
      },
      dataInicio: action.payload,
    }))
    .addCase(atualizarFiliaisSelecionadas, (state, action) => ({
      ...state,
      cargasPlanilha: {
        ...state.cargasPlanilha,
        pagina: 1,
      },
      cargasPlanejador: {
        ...state.cargasPlanejador,
        pagina: 1,
      },
      filiaisSelecionadas: action.payload,
    }))
    .addCase(atualizarPesquisa, (state, action) => ({
      ...state,
      cargasPlanilha: {
        ...state.cargasPlanilha,
        pagina: 1,
      },
      cargasPlanejador: {
        ...state.cargasPlanejador,
        pagina: 1,
      },
      pesquisa: action.payload,
    }))
    .addCase(atualizarFiliais, (state, action) => ({
      ...state,
      filiais: action.payload,
    }))
    .addCase(atualizarPagina, (state, action) => {
      if (verificarCargaPlanilha(state.stateOrigemCarga)) {
        return {
          ...state,
          cargasPlanilha: {
            ...state.cargasPlanilha,
            pagina: action.payload,
          },
        };
      }
      return {
        ...state,
        cargasPlanejador: {
          ...state.cargasPlanejador,
          pagina: action.payload,
        },
      };
    })
    .addCase(atualizarQuantidadePaginas, (state, action) => {
      if (verificarCargaPlanilha(action.payload.origem)) {
        return {
          ...state,
          cargasPlanilha: {
            ...state.cargasPlanilha,
            pagina:
              state.cargasPlanilha.pagina > action.payload.totalPaginas
                ? 1
                : state.cargasPlanilha.pagina,
            quantidadePaginas: action.payload.totalPaginas,
          },
        };
      }

      return {
        ...state,
        cargasPlanejador: {
          ...state.cargasPlanejador,
          pagina:
            state.cargasPlanejador.pagina > action.payload.totalPaginas
              ? 1
              : state.cargasPlanejador.pagina,
          quantidadePaginas: action.payload.totalPaginas,
        },
      };
    })
    .addCase(atualizarQuantidadeCargas, (state, action) => {
      if (verificarCargaPlanilha(action.payload.origem)) {
        return {
          ...state,
          cargasPlanilha: {
            ...state.cargasPlanilha,
            quantidadeCargas: action.payload.totalItens,
          },
        };
      }

      return {
        ...state,
        cargasPlanejador: {
          ...state.cargasPlanejador,
          quantidadeCargas: action.payload.totalItens,
        },
      };
    })
    .addCase(atualizarOrigemCarga, (state, action) => ({
      ...state,
      stateOrigemCarga: action.payload,
    }))
    .addCase(adicionarIdCarga, (state, action) => {
      const novoSetIdsCarga = new Set(state.idsCargasSelecionadas);
      novoSetIdsCarga.add(action.payload);
      return {
        ...state,
        idsCargasSelecionadas: novoSetIdsCarga,
      };
    })
    .addCase(removerIdCarga, (state, action) => {
      const novoSetIdsCarga = new Set(state.idsCargasSelecionadas);
      novoSetIdsCarga.delete(action.payload);
      return {
        ...state,
        idsCargasSelecionadas: novoSetIdsCarga,
      };
    })
    .addCase(atualizarOrdenacao, (state, action) => ({
      ...state,
      cargasPlanilha: {
        ...state.cargasPlanilha,
        pagina: 1,
      },
      cargasPlanejador: {
        ...state.cargasPlanejador,
        pagina: 1,
      },
      ordenacao: action.payload,
    }))
    .addCase(limparDados, () => estadoInicial)
    .addDefaultCase((state) => state);
});

export default listaCargasReducer;
