import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CircularProgress, makeStyles } from '@material-ui/core';
import PainelPadrao from 'features/indicadores/components/PainelPadrao';
import { IndicadorNegociacao } from 'features/indicadores/api/indicadoresApiTypes';
import { useLoading } from 'shared/customHooks/useLoading';
import {
  buscarDadosIndicadoresMacros,
  gerarPlanilhaIndicadoresMacros,
} from 'features/indicadores/utils/indicadoresMacros';
import { PeriodoFiltroIndicadores } from 'features/indicadores/constants/Constants';
import { StyledChip } from 'shared/components/filters/chip/ComponentesFiltroChip';
import spacingInPixels from 'shared/functions/materialUtils';
import ConteudoIndicadoresMacros from './ConteudoIndicadoresMacros';
import SkeletonTabelaIndicadoresMacros from './SkeletonTabelaIndicadoresMacros';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacingInPixels(theme, 2.5),
    '& div.MuiChip-root': {
      width: 80,
      color: theme.palette.cores.branco,
      backgroundColor: theme.palette.cores.azul[600],
      alignSelf: 'flex-end',
      marginRight: spacingInPixels(theme, 2),
    },
    '& div.MuiChip-root:hover': {
      backgroundColor: `${theme.palette.cores.azul[600]}CC`,
    },
    '& div.MuiCircularProgress-root': {
      display: 'flex',
      alignContent: 'center',
    },
  },
}));

const ContainerIndicadoresMacros = (): JSX.Element => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [dadosIndicadores, setDadosIndicadores] = useState<
    IndicadorNegociacao[]
  >([]);
  const [erro, setErro] = useState(false);
  const [peridoAtual, setPeridoAtual] = useState(PeriodoFiltroIndicadores.WEEK);
  const [valoresPeriodo, setValoresPeriodo] = useState<string[]>([]);

  const handleChangePeriodo = (periodo: string | number) => {
    setPeridoAtual(periodo as PeriodoFiltroIndicadores);
  };

  const [carregandoPlanilha, gerarPlanilha] = useLoading(async () =>
    gerarPlanilhaIndicadoresMacros(peridoAtual)(dispatch),
  );

  const labelBotaoChip = useMemo(
    () =>
      carregandoPlanilha ? (
        <CircularProgress color="inherit" size={12} />
      ) : (
        'Exportar'
      ),
    [carregandoPlanilha],
  );

  const buscaDemandasCallback = useCallback(
    async () =>
      buscarDadosIndicadoresMacros(
        setDadosIndicadores,
        setValoresPeriodo,
        setErro,
        peridoAtual,
      ),
    [peridoAtual],
  );

  const [carregando, buscarDemandasLoading] = useLoading(buscaDemandasCallback);

  const handleClickTentarNovamente = () => {
    buscarDemandasLoading();
  };

  useEffect(() => {
    buscarDemandasLoading();
  }, [dispatch, buscarDemandasLoading]);

  return (
    <section>
      <PainelPadrao titulo="Indicadores" onChangePeriodo={handleChangePeriodo}>
        {carregando ? (
          <SkeletonTabelaIndicadoresMacros />
        ) : (
          <section className={classes.container}>
            <ConteudoIndicadoresMacros
              erro={erro}
              onClickTentarNovamente={handleClickTentarNovamente}
              dadosIndicadores={dadosIndicadores}
              valoresPeriodo={valoresPeriodo}
            />
            {!erro && (
              <StyledChip
                label={labelBotaoChip}
                onClick={gerarPlanilha}
                disabled={carregandoPlanilha}
              />
            )}
          </section>
        )}
      </PainelPadrao>
    </section>
  );
};

export default ContainerIndicadoresMacros;
