import { Dispatch } from 'redux';
import { obterPaginaNotificacoes } from '../redux/notificacoesOperation';

export const onClickVejaMais = async (
  paginaAtual: number,
  setCarregando: (c: boolean) => void,
  isLido: boolean,
  dispatch: Dispatch,
): Promise<void> => {
  setCarregando(true);
  await obterPaginaNotificacoes(paginaAtual + 1, isLido)(dispatch);
  setCarregando(false);
};
