import { makeStyles } from '@material-ui/core';
import spacingInPixels from 'shared/functions/materialUtils';

export const useStyles = makeStyles((theme) => ({
  containerRodape: {
    marginTop: spacingInPixels(theme, 3),
    display: 'flex',
    justifyContent: 'space-between',
  },
  paginacao: {
    '& .MuiPagination-ul': {
      '& li': {
        marginTop: '6px',
      },
    },
    '& .MuiPaginationItem-textPrimary.Mui-selected': {
      backgroundColor: '#2b52dd',
      fontSize: '1.1rem',
      boxShadow:
        '0px 5px 14px 0px #0000001F, 0px 9px 10px 0px #00000024, 0px 5px 5px 0px #00000033',
    },
    '& .MuiPaginationItem-textPrimary:not(.Mui-selected)': {
      fontSize: '1.1rem',
      backgroundColor: theme.palette.cores.branco,
      boxShadow:
        '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    },
  },
  botaoCargas: {
    width: '20%',
    minWidth: spacingInPixels(theme, 25),
    maxWidth: spacingInPixels(theme, 50),
    height: spacingInPixels(theme, 6),
    fontSize: '16px',
    float: 'right',

    borderRadius: 24,
    color: theme.palette.cores.branco,
    background: '#2b52dd',
    boxShadow: 'none',

    '&:hover': {
      background: '#2b52dd',
      filter: 'brightness(0.8)',
    },
  },
}));
