import { useTheme } from '@material-ui/core/styles';
import * as React from 'react';
import { SVGProps } from 'react';

const IconePararCirculo = ({
  fill,
  ...outros
}: SVGProps<SVGSVGElement>): JSX.Element => {
  const theme = useTheme();
  return (
    <svg width={20} height={20} fill="none" {...outros}>
      <path
        d="M13 6H7a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1Zm-1 6H8V8h4v4ZM10 0a10 10 0 1 0 0 20 10 10 0 0 0 0-20Zm0 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16Z"
        fill={fill || theme.palette.cores.cinza[900]}
      />
    </svg>
  );
};

export default IconePararCirculo;
