import { makeStyles } from '@material-ui/core';
import { NegociacaoTipoAlerta } from 'features/dashboards/negociadores/api/listagemNegociadoresApiTypes';
import {
  DemandaAlerta,
  DemandaDiaria,
} from 'features/demandaDiaria/api/demandaDiariaApiTypes';
import {
  buscarDemandasAlertas,
  definirFiltros,
  filtrarDemandasPorAlerta,
  FiltroAlerta,
} from 'features/demandaDiaria/utils/demandaDiariaAlertas';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import FiltroChip from 'shared/components/filters/chip/FiltroChip';
import CustomSkeleton from 'shared/components/skeleton/CustomSkeleton';
import { useLoading } from 'shared/customHooks/useLoading';
import spacingInPixels from 'shared/functions/materialUtils';
import TabelaDemandas from './TabelaDemandas';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacingInPixels(theme, 4),
  },
  titulo: {
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: spacingInPixels(theme, 4),
    lineHeight: spacingInPixels(theme, 2.5),
    letterSpacing: '0.4px',
    color: theme.palette.cores.azulMartins[700],
  },
}));

export interface ConteudoModalDetalhesProps {
  demandasDiarias?: DemandaDiaria;
  categoriaAtual: NegociacaoTipoAlerta;
}

const ConteudoModalDetalhes = ({
  demandasDiarias,
  categoriaAtual,
}: ConteudoModalDetalhesProps): JSX.Element => {
  const classes = useStyles();

  const [filtroAtual, setFiltroAtual] = useState<FiltroAlerta>();
  const [filtros, setFiltros] = useState<FiltroAlerta[]>([]);
  const [demandasAlertas, setDemandasAlertas] = useState<DemandaAlerta[]>([]);
  const [demandasAlertasFiltradas, setDemandasAlertasFiltradas] = useState<
    DemandaAlerta[]
  >([]);

  const handleFiltroDemandas = (filtroClicado: string) => {
    const filtroSelecionado = filtros.find(
      (filtro) => filtro.descricao === filtroClicado,
    );
    setFiltroAtual(filtroSelecionado);
    filtrarDemandasPorAlerta(
      demandasAlertas,
      filtroSelecionado?.tipo || NegociacaoTipoAlerta.naoVista,
      setDemandasAlertasFiltradas,
    );
  };

  const buscar = useCallback(
    async () =>
      buscarDemandasAlertas(
        setDemandasAlertas,
        setDemandasAlertasFiltradas,
        categoriaAtual,
      ),
    [categoriaAtual],
  );

  const [carregando, buscarDemandas] = useLoading(buscar);

  useMemo(() => {
    setFiltros(definirFiltros(setFiltroAtual, categoriaAtual, demandasDiarias));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriaAtual]);

  useEffect(() => {
    buscarDemandas();
  }, [buscarDemandas]);

  return (
    <section className={classes.container}>
      <section>
        <FiltroChip
          carregando={carregando}
          quantidade={3}
          filtros={[...filtros].map((filtro) => filtro.descricao)}
          filtroAtual={filtroAtual?.descricao || ''}
          onClickFiltro={handleFiltroDemandas}
        />
      </section>
      <section>
        {carregando ? (
          <CustomSkeleton height={38} width="35%" borderradius={4} />
        ) : (
          <span className={classes.titulo}>{filtroAtual?.descricao}</span>
        )}
      </section>
      <section>
        <TabelaDemandas
          carregando={carregando}
          demandas={demandasAlertasFiltradas}
          categoriaAtual={filtroAtual?.tipo}
        />
      </section>
    </section>
  );
};

export default ConteudoModalDetalhes;
