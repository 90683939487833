import React from 'react';
import IconeRelogio from 'assets/icones/IconeRelogio';
import theme from 'theme';
import CustomTooltip from '../tooltip/CustomTooltip';
import estilosTabela from './styles/estilosTabela';

export interface CelulaConteudoTextoProps {
  conteudo?: Date;
}

const CelulaConteudoDataHora = ({
  conteudo,
}: CelulaConteudoTextoProps): JSX.Element => {
  const { fonteConteudo, tdCenter } = estilosTabela();

  const valorDataCorrigido = (valor: number | undefined, mes: boolean) => {
    const aux = mes ? 1 + (valor || 0) : valor || 0;

    if (aux < 10) {
      return `0${aux}`;
    }

    return aux;
  };

  const conteudoValidadoMes = conteudo
    ? `${valorDataCorrigido(conteudo?.getDate(), false)}/${valorDataCorrigido(
        conteudo?.getMonth(),
        true,
      )}  `
    : '--/-';

  const conteudoValidadoHora = conteudo
    ? `${valorDataCorrigido(conteudo.getHours(), false)}h ${valorDataCorrigido(
        conteudo.getMinutes(),
        false,
      )}`
    : '--:--';

  return (
    <CustomTooltip
      title={
        conteudo
          ? `${conteudoValidadoMes} - ${conteudoValidadoHora}`
          : 'Indisponível'
      }
    >
      <td className={`${fonteConteudo} ${tdCenter}`}>
        {conteudoValidadoMes}-{' '}
        <IconeRelogio
          width={16}
          height={16}
          fill={theme.palette.cores.verdeClaro[500]}
        />
        {conteudoValidadoHora}
      </td>
    </CustomTooltip>
  );
};

export default CelulaConteudoDataHora;
