import { useTheme } from '@material-ui/core';
import * as React from 'react';
import { SVGProps } from 'react';

const IconeRelogio = ({
  fill,
  width,
  height,

  ...outros
}: SVGProps<SVGSVGElement>): JSX.Element => {
  const theme = useTheme();
  return (
    <svg
      width={width || 20}
      height={height || 20}
      fill="none"
      viewBox="0 0 20 20"
      {...outros}
    >
      <path
        d="M10 0a10 10 0 1 0 0 20 10 10 0 0 0 0-20Zm0 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16Zm3.1-7.37L11 9.42V5a1 1 0 0 0-2 0v5.12a.65.65 0 0 0 .05.2c.02.06.047.116.08.17.027.057.06.11.1.16l.16.13.09.09 2.6 1.5a1 1 0 0 0 .5.13 1 1 0 0 0 .5-1.87h.02Z"
        fill={fill || theme.palette.cores.cinza[900]}
      />
    </svg>
  );
};

export default IconeRelogio;
