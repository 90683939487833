import React from 'react';
import { obterMotivoRecusa } from '../../../../../shared/constants/MotivoRecusa';

import TypographyChat from '../../TypographyChat';

type MensagemRecusaProps = {
  motivoRecusa: number | undefined;
};

const MensagemRecusa = ({ motivoRecusa }: MensagemRecusaProps): JSX.Element => {
  const textoMotivoRecusa = 'Motivo recusa:';
  const descricaoMotivoRecusa = `${obterMotivoRecusa(motivoRecusa)}]`;
  return (
    <TypographyChat>
      Essa carga não está mais disponível, a Slim irá entrar em contato em
      futuras negociações. Obrigado!
      <TypographyChat>
        [<b>{textoMotivoRecusa}</b> {descricaoMotivoRecusa}
      </TypographyChat>
    </TypographyChat>
  );
};

export default MensagemRecusa;
